import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Left = styled(Column)`
  flex: 5;
`;

export const Right = styled(Column)`
  flex: 4;
  background-image: url('/login-bk.svg'),
    linear-gradient(32deg, rgb(236, 233, 254) 0%, rgb(232, 237, 255) 100%);
`;

export const Logo = styled.div`
  position: fixed;
  top: 20px;
  left: 30px;
`;
