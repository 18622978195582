export type CancelIconProps = {
  color?: string;
};

export function CancelIcon(props: CancelIconProps) {
  const { color } = props;

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none' viewBox='0 0 23 22'>
      <path
        fill={`${color || '#676465'}`}
        d='M17 6.608L15.892 5.5 11.5 9.892 7.108 5.5 6 6.608 10.392 11 6 15.392 7.108 16.5l4.392-4.392 4.392 4.392L17 15.392 12.608 11 17 6.608z'
      />
    </svg>
  );
}
