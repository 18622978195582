import classnames from 'classnames';
import { useLocalStorage, useMeasure } from 'react-use';
import { InboxProvider } from './AskEditor.context';
import { EmptyBar, Wrapper } from './AskEditor.style';
import { Verb } from './AskEditor.types';
import { AskBottomBar } from './_parts/AskBottomBar/AskBottomBar';
import { AskContent } from './_parts/AskContent/AskContent';
import { AskTopBar } from './_parts/AskTopBar/AskTopBar';
import { IDocumentQuestion } from '@ai21/studio-store';
import { useEffect } from 'react';

export type AskEditorProps = {
  items: IDocumentQuestion[];
  open?: boolean;
  demo?: boolean;
  ctaColor?: string;
  ctaBkColor?: string;
  callbacks: {
    onAction: (verb: Verb, params?: Json) => void;
    onPrompt: (params?: Json) => void;
    onClose: () => void;
    askAllLibrary: () => void;
  };
  emptyStateMessage?: string;
  exampleQuestions?: string[];
  fileName?: string;
};

export function AskEditorInner(props: AskEditorProps) {
  const { items, open, demo, ctaColor, ctaBkColor, fileName, exampleQuestions, callbacks } = props;
  const [isFullscreen, setIsFullscreen] = useLocalStorage('docLibrary_drawer_fullscreen', false);
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  useEffect(() => {
    const element = document.querySelector('.AskEditor-wrapper');

    if (!element || !demo) {
      return;
    }

    // scroll window with animation

    setTimeout(() => {
      window.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth',
      });
    });
  }, [height]);

  function renderLoadingState() {
    return (
      <>
        <EmptyBar />
        <EmptyBar />
      </>
    );
  }

  function renderInner() {
    if (!open) {
      return renderLoadingState();
    }

    return (
      <>
        <AskTopBar
          isFullscreen={isFullscreen}
          onToggleFullScreen={setIsFullscreen}
          fileName={fileName}
        />
        <AskContent
          items={items}
          callbacks={callbacks}
          fileName={fileName}
          exampleQuestions={exampleQuestions}
        />
        <AskBottomBar ctaColor={ctaColor} ctaBkColor={ctaBkColor} />
      </>
    );
  }

  const className = classnames('AskEditor-wrapper', {
    fullscreen: isFullscreen,
    demo,
  });

  return (
    <Wrapper ref={ref} className={className} data-testid='AskEditor-wrapper'>
      {renderInner()}
    </Wrapper>
  );
}

export function AskEditor(props: AskEditorProps) {
  return (
    <InboxProvider {...props}>
      <AskEditorInner {...props} />
    </InboxProvider>
  );
}

export default AskEditor;
