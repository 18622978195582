import { fireRequest } from './axios';
import { PayloadFeedback } from './types';

export const send = (payload: PayloadFeedback) => {
  return fireRequest<Json>({
    path: '/experimental/feedback',
    method: 'post',
    data: {
      requestId: payload.requestId,
      feedbackScore: payload.score,
      feedbackReason: payload.reasons,
      model: payload.model,
    },
    endpoint: 'feedback.send',
  });
};

export const feedback = {
  send,
};
