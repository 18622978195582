import { APIType, MAP_MODELS_TO_J2_MODELS } from '../../constants/constants';
import {
  CompletionFailureReason,
  CompletionResponse,
  ControllerParams,
  DEFAULT_CONTROLLER_PARAMS,
  DEFAULT_PENALTY,
  TokenClickedParams,
} from '../../data-types/Completion';
import {
  CompletionPresetParams,
  CustomPreset,
  Preset,
  PresetLabel,
} from '../../data-types/PresetParams';
import { SnackbarSeverity } from '../../data-types/Snackbar';
import {
  API_SELECTED,
  CLEAR_COMPLETION_RESPONSE,
  CUSTOM_PRESET_SELECTED,
  FETCH_COMPLETION_CANCELED,
  FETCH_COMPLETION_FAILED,
  FETCH_COMPLETION_STARTED,
  FETCH_COMPLETION_SUCCESS,
  FETCH_TOKENIZE_STARTED,
  FETCH_TOKENIZE_SUCCESS,
  GET_PROMPT_SHARE_FAILED,
  GET_PROMPT_SHARE_STARTED,
  GET_PROMPT_SHARE_SUCCESS,
  PLAYGROUND_CURSOR_MOVE,
  POST_PROMPT_SHARE_FAILED,
  POST_PROMPT_SHARE_STARTED,
  POST_PROMPT_SHARE_SUCCESS,
  PRESET_CLICKED,
  PRESET_SELECTED,
  PROMPT_SHARE_CLOSED,
  PlaygroundActionTypes,
  SET_COMPLETION_IN_PROGRESS,
  SET_CONTROLLER_PARAMS,
  SET_DEFAULT_COMPLETION_PRESET,
  SET_PLAYGROUND_TO_DEFAULT_STATE,
  SET_PRESET_TO_DELETE,
  SET_REWRITE_PARAMS,
  SET_SHOW_SAVE_PRESET_DIALOG,
  SET_SHOW_SAVE_PRESET_ON_EXIT_DIALOG,
  SET_TOKEN_CLICKED,
} from '../actionTypes/playgroundActionTypes';
import {
  CLEAR_SNACKBAR_MESSAGE,
  DELETE_PRESET_SUCCESS,
  GET_PRESETS_SUCCESS,
  POST_PRESET_SUCCESS,
  PresetActionTypes,
} from '../actionTypes/presetActionTypes';

export interface PlaygroundState {
  completionResponse: CompletionResponse | undefined;
  completionInProgress: boolean;
  completionAnimationInProgress: boolean;
  postPromptShareInProgress: boolean;
  getPromptShareInProgress: boolean;
  promptShareUrl: string | null;
  completionCanceled: boolean;
  completionFailureReason: CompletionFailureReason | null;
  tokenizationInProgress: boolean;
  currentTokenCount: number;
  cursorPosition: number | null;
  controllerParams: ControllerParams;
  performTypingAnimation: boolean;
  tokenClickedParams: TokenClickedParams;
  snackbarMessage: string | undefined;
  snackbarSeverity: SnackbarSeverity;
  promptShareText: string | null;
  latestInteractionId: string | null;
  getPromptShareParams: ControllerParams | null;
  presetsEndpointResponse: CustomPreset[] | null;
  selectedPresetName: string;
  currentPreset: Preset;
  currentApi: APIType;
  showSavePresetDialog: boolean;
  showSavePresetOnExitDialog: boolean;
  nextPreset: Preset | null;
  presetToDelete: string;
}

export const DEFAULT_COMPLETION_PRESET: Preset = {
  name: 'Untitled',
  text: '',
  apiType: APIType.COMPLETION,
  primaryLabel: PresetLabel.MY_PRESETS,
  secondaryLabels: [],
  params: {
    maxTokens: DEFAULT_CONTROLLER_PARAMS.MAX_TOKENS,
    epoch: DEFAULT_CONTROLLER_PARAMS.EPOCH,
    temperature: DEFAULT_CONTROLLER_PARAMS.TEMPERATURE,
    topP: DEFAULT_CONTROLLER_PARAMS.TOP_P,
    stopSequences: DEFAULT_CONTROLLER_PARAMS.STOP_SEQ,
    model: DEFAULT_CONTROLLER_PARAMS.MODEL,
    topKReturn: 0,
    presencePenalty: DEFAULT_PENALTY,
    countPenalty: DEFAULT_PENALTY,
    frequencyPenalty: DEFAULT_PENALTY,
  },
};

const initialState = {
  completionResponse: undefined,
  completionInProgress: false,
  completionAnimationInProgress: false,
  postPromptShareInProgress: false,
  getPromptShareInProgress: false,
  completionCanceled: false,
  completionFailureReason: null,
  tokenizationInProgress: false,
  currentTokenCount: 0,
  cursorPosition: null,
  promptShareText: null,
  promptShareUrl: null,
  latestInteractionId: null,
  controllerParams: { ...DEFAULT_COMPLETION_PRESET.params },
  performTypingAnimation: true,
  getPromptShareParams: null,
  tokenClickedParams: {
    clicked: false,
    token: null,
    renderAt: null,
  },
  presetsEndpointResponse: <CustomPreset[]>[],
  snackbarMessage: undefined,
  snackbarSeverity: SnackbarSeverity.INFO,
  selectedPresetName: DEFAULT_COMPLETION_PRESET.name,
  currentPreset: DEFAULT_COMPLETION_PRESET,
  currentApi: APIType.COMPLETION,
  showSavePresetDialog: false,
  showSavePresetOnExitDialog: false,
  nextPreset: null,
  presetToDelete: '',
};

export function reducer(state = initialState, action: PlaygroundActionTypes | PresetActionTypes) {
  switch (action.type) {
    case API_SELECTED:
      if (
        action.selectedApi === APIType.COMPLETION &&
        state.currentPreset.apiType !== APIType.COMPLETION
      ) {
        return {
          ...state,
          currentApi: action.selectedApi,
          currentPreset: DEFAULT_COMPLETION_PRESET,
          controllerParams: {
            ...DEFAULT_COMPLETION_PRESET.params,
            presencePenalty: DEFAULT_PENALTY,
            countPenalty: DEFAULT_PENALTY,
            frequencyPenalty: DEFAULT_PENALTY,
          },
        };
      }
      return { ...state, currentApi: action.selectedApi };
    case SET_DEFAULT_COMPLETION_PRESET:
      return {
        ...state,
        currentPreset: DEFAULT_COMPLETION_PRESET,
        controllerParams: { ...DEFAULT_COMPLETION_PRESET.params },
      };
    case SET_PLAYGROUND_TO_DEFAULT_STATE:
      return {
        ...initialState,
        presetsEndpointResponse: state.presetsEndpointResponse,
      };
    case SET_REWRITE_PARAMS:
      return {
        ...state,
        currentPreset: {
          ...state.currentPreset,
          params: { ...state.currentPreset.params, ...action.delta },
        },
      };

    case FETCH_COMPLETION_SUCCESS:
      if (state.completionCanceled) {
        return { ...state };
      }
      return {
        ...state,
        completionResponse: action.completionResponse,
        completionAnimationInProgress: true,
        completionInProgress: true,
        completionFailureReason: null,
        latestInteractionId: action.completionResponse.id,
      };
    case FETCH_COMPLETION_STARTED:
      return {
        ...state,
        completionInProgress: true,
        completionCanceled: false,
        completionFailureReason: null,
      };
    case FETCH_COMPLETION_FAILED:
      return {
        ...state,
        completionInProgress: false,
        completionFailureReason: action.reason,
        completionCanceled: false,
      };
    case FETCH_COMPLETION_CANCELED:
      return {
        ...state,
        completionInProgress: false,
        completionCanceled: true,
        completionFailureReason: null,
      };
    case SET_COMPLETION_IN_PROGRESS:
      return {
        ...state,
        completionInProgress: action.inProgress,
        completionAnimationInProgress: action.inProgress,
      };
    case FETCH_TOKENIZE_STARTED:
      return { ...state, tokenizationInProgress: true };
    case FETCH_TOKENIZE_SUCCESS:
      return { ...state, tokenizationInProgress: false, currentTokenCount: action.numTokens };
    case CLEAR_COMPLETION_RESPONSE:
      return { ...state, completionResponse: null };
    case PRESET_SELECTED:
    case CUSTOM_PRESET_SELECTED:
      return {
        ...state,
        currentPreset: action.preset,
        controllerParams: {
          ...action.preset.params,
          model:
            MAP_MODELS_TO_J2_MODELS[(action.preset.params as CompletionPresetParams).model || ''] ||
            (action.preset.params as CompletionPresetParams).model,
          presencePenalty:
            (action.preset.params as CompletionPresetParams).presencePenalty || DEFAULT_PENALTY,
          countPenalty:
            (action.preset.params as CompletionPresetParams).countPenalty || DEFAULT_PENALTY,
          frequencyPenalty:
            (action.preset.params as CompletionPresetParams).frequencyPenalty || DEFAULT_PENALTY,
        },
        selectedPresetName: action.preset.name,
      };
    case SET_CONTROLLER_PARAMS: {
      let model = action.delta.model
        ? MAP_MODELS_TO_J2_MODELS[action.delta?.model]
        : (state.controllerParams as CompletionPresetParams).model;
      model = model || action.delta?.model;
      return { ...state, controllerParams: { ...state.controllerParams, ...action.delta, model } };
    }
    case SET_TOKEN_CLICKED:
      return { ...state, tokenClickedParams: { ...action.tokenClickedParams } };
    case PLAYGROUND_CURSOR_MOVE:
      return { ...state, cursorPosition: action.position };
    case PROMPT_SHARE_CLOSED:
      return { ...state, postPromptShareInProgress: false, promptShareUrl: null };
    case POST_PROMPT_SHARE_STARTED:
      return { ...state, postPromptShareInProgress: true, promptShareUrl: null };
    case POST_PROMPT_SHARE_SUCCESS: {
      return {
        ...state,
        postPromptShareInProgress: false,
        promptShareUrl: `${window.__env__.VITE_APP_SERVING_URL || ''}/home/complete?promptShare=${
          action.promptShareId
        }`,
      };
    }
    case POST_PROMPT_SHARE_FAILED:
      return { ...state, postPromptShareInProgress: false, promptShareUrl: null };
    case GET_PROMPT_SHARE_STARTED:
      return { ...state, getPromptShareInProgress: true };
    case GET_PROMPT_SHARE_SUCCESS:
      return {
        ...state,
        getPromptShareInProgress: false,
        controllerParams: { ...state.controllerParams, ...action.params },
        currentPreset: { ...state.currentPreset, params: action.params },
        promptShareText: action.text,
        showAlternativeTokens: action.params.topKReturn ? action.params.topKReturn > 0 : 0,
      };
    case GET_PROMPT_SHARE_FAILED:
      return { ...state, getPromptShareInProgress: false };
    case GET_PRESETS_SUCCESS:
      action.payload.forEach((p) => {
        p.primaryLabel = PresetLabel.MY_PRESETS;
      });
      return { ...state, presetsEndpointResponse: action.payload };
    case POST_PRESET_SUCCESS:
      return {
        ...state,
        presetsEndpointResponse: [
          { ...action.payload, primaryLabel: PresetLabel.MY_PRESETS },
          ...state.presetsEndpointResponse,
        ],
        snackbarSeverity: SnackbarSeverity.SUCCESS,
        snackbarMessage: 'Preset saved successfully',
        currentPreset: action.payload,
        selectedPresetName: action.payload.name,
      };
    case DELETE_PRESET_SUCCESS:
      return {
        ...state,
        presetsEndpointResponse: state.presetsEndpointResponse.filter(
          (preset) => preset.name !== action.name
        ),
        snackbarSeverity: SnackbarSeverity.SUCCESS,
        snackbarMessage: 'Preset deleted successfully',
      };
    case CLEAR_SNACKBAR_MESSAGE:
      return {
        ...state,
        snackbarSeverity: SnackbarSeverity.INFO,
        snackbarMessage: undefined,
      };
    case SET_SHOW_SAVE_PRESET_DIALOG:
      return {
        ...state,
        showSavePresetDialog: action.showSavePresetDialog,
      };
    case SET_SHOW_SAVE_PRESET_ON_EXIT_DIALOG:
      return {
        ...state,
        showSavePresetOnExitDialog: action.showSavePresetOnExitDialog,
      };
    case PRESET_CLICKED:
      return {
        ...state,
        nextPreset: action.nextPreset,
      };
    case SET_PRESET_TO_DELETE:
      return {
        ...state,
        presetToDelete: action.presetToDelete,
      };
    default:
      return state;
  }
}
