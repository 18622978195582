import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

export const Tokens = styled.div``;

export const Token = styled.div`
  margin: 1px;
  padding: 1px 4px;
  float: left;
  position: relative;
  min-height: 17px;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Brk = styled.div`
  clear: left;
`;

export const Alternatives = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 10px;
`;

export const Alternative = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1px;

  &.current {
    color: gold;
    font-weight: bold;
  }
`;

export const AltText = styled.div`
  margin-right: 8px;
`;

export const AltPercent = styled.div``;
