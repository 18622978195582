export function SpecializedApiIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='8' transform='matrix(1 0 0 -1 0 52)' fill='#FFCCDE' />
      <rect
        width='52'
        height='52'
        rx='8'
        transform='matrix(1 0 0 -1 0 52)'
        fill='url(#paint0_linear_1874_234)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26 14.1895L40.0592 22.0001L35.4448 24.5636C36.3451 27.2462 36.5675 30.115 36.0855 32.9125L35.956 33.6644L35.1966 33.7381C32.027 34.0457 29.0386 35.3584 26.6677 37.4846L26 38.0833L25.3324 37.4846C24.2295 36.4955 22.993 35.6824 21.6667 35.0647V36.6667C21.6667 37.219 21.219 37.6667 20.6667 37.6667C20.1144 37.6667 19.6667 37.219 19.6667 36.6667V34.3023C18.7384 34.0228 17.7801 33.8328 16.8047 33.7381L16.0455 33.6643L15.9159 32.9126C15.4337 30.1153 15.6559 27.2466 16.5563 24.5642L11.9409 22.0001L26 14.1895ZM18.3385 25.5543C17.7117 27.5857 17.5205 29.7284 17.7791 31.8417C18.417 31.9337 19.0472 32.0618 19.6667 32.2247V26.6667C19.6667 26.5485 19.6876 26.4332 19.7267 26.3255L18.3385 25.5543ZM21.6667 27.4033V32.886C23.2228 33.5103 24.6844 34.3649 26.0001 35.4273C28.3681 33.5153 31.2087 32.2761 34.2222 31.8416C34.4807 29.7283 34.2894 27.5855 33.6627 25.5539L26.4857 29.5409L26 29.8107L25.5144 29.5409L21.6667 27.4033ZM26.0001 27.5228L35.9409 22.0001L26 16.4774L16.0592 22.0001L17.2758 22.676L21.5337 25.0412L25.5144 22.8299C25.9972 22.5617 26.606 22.7357 26.8742 23.2185C27.1424 23.7013 26.9684 24.3101 26.4857 24.5783L23.5928 26.1853L26.0001 27.5228Z'
        fill='#1D1E22'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1874_234'
          x1='52'
          y1='2.95043e-06'
          x2='2.5'
          y2='52'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.5' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
}
