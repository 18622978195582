import { api } from '@ai21/studio-api';
import { actions, selectors } from '@ai21/studio-store';
import { prompt, toast } from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, delay, put, select, takeEvery } from 'saga-ts';
import EpochSelectionContainer from '../components/EpochSelection/EpochSelection.container';
import { Json } from '../types';
import { SET_CONTROLLER_PARAMS } from '../redux/actionTypes/playgroundActionTypes';
import { navigate } from './saga.navigation';
import { WebRoutes } from '../bootstrap/Web.routes';

type Verb =
  | 'getEpochs' //
  | 'changeDefaultEpoch'
  | 'setAsDefault'
  | 'playground'
  | 'epochSelection';

type ActionEpochs = {
  type: 'EPOCHS';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  getEpochs: getEpochs,
  changeDefaultEpoch: changeDefaultEpoch,
  epochSelection: epochSelection,
  setAsDefault: setAsDefault,
  playground: playgroundWithEpoch,
};

function* epochSelection(action: ActionEpochs) {
  const { id } = action;

  const { value, didCancel } = yield prompt.custom({
    component: EpochSelectionContainer,
    componentProps: {
      id,
    },
  });

  if (didCancel || !value) {
    return;
  }
}

function* getEpochs(action: ActionEpochs) {
  const { id } = action;

  if (!id) {
    return;
  }

  try {
    const response = yield* call(api.models.getEpochs, id);
    const modelMetadata = get(response, 'data.modelMetadata');
    yield put(actions.models.patch(id, { modelMetadata }));
  } catch (err: any) {
    console.log('err ->', err.message);
  }
}

function* setAsDefault(action: ActionEpochs) {
  const currentIds = yield* select(selectors.raw.$rawCurrentIds);
  const { modelId } = currentIds;
  const { id } = action;

  if (!id || !modelId) {
    return;
  }

  try {
    const response = yield* call(api.models.changeDefaultEpoch, modelId, id);

    if (response.isSuccess) {
      yield* put({ type: SET_CONTROLLER_PARAMS, delta: { epoch: parseInt(id) } });
      toast.show('Default epoch changed successfully', 'success');
      yield* call(getEpochs as any, { id: modelId });
    } else {
      toast.show('Failed to change default epoch', 'error');
    }
  } catch (err: any) {
    console.log('err.message ->', err.message);
    toast.show('Failed to change default epoch', 'error');
  }
}

function* playgroundWithEpoch(action: ActionEpochs) {
  const currentIds = yield* select(selectors.raw.$rawCurrentIds);
  const { modelId } = currentIds;
  const { id } = action;
  const currentModel = yield* select(selectors.singles.$model, modelId);
  yield* put({
    type: SET_CONTROLLER_PARAMS,
    delta: { model: currentModel.name, epoch: parseInt(id) },
  });

  navigate('/home/complete/single-input-1-output');
}

function* changeDefaultEpoch(action: ActionEpochs) {
  const { id } = action;

  const response = yield* call(api.models.getEpochs, id);
}

export function* epochs(action: ActionEpochs) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('EPOCHS', epochs);
}
