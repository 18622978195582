import {
  IEvaluationJobConfig,
  actions,
  createEvaluationJob,
} from '@ai21/studio-store';
import { call, delay, put } from 'saga-ts';
import { guid4 } from 'shared-base';
import { analytics } from './analytics';
import { ax } from '@ai21/studio-analytics';
import { get } from 'lodash';

export function* createJob(dataset: Json[], data: IEvaluationJobConfig) {
  const setId = data.id || guid4();

  const isSample = !get(dataset, '[0].prompt', '').includes(
    'Classify this sentence to one of the following classes'
  );

  try {
    yield* call(createEvaluationJob, dataset, {
      ...data,
      id: setId,
      isSample,
    });

    const linesToAdd = dataset.filter((i) => i.prompt);
    const linesCount = linesToAdd.length;

    yield* call(analytics, {
      action: 'upload',
      actionValue: linesToAdd.length,
      eventId: 'EvaluationUploadSuccess',
      extra: { isSample },
    });

    yield delay(500);

    ax.set('evaluationSets.total');

    if (isSample) {
      ax.nudge('evaluationSets.sample');
    } else {
      ax.nudge('evaluationSets.customStarted');
      ax.nudge('evaluationSets.custom');
      ax.nudge('evaluationSets.customTotalLines', linesCount);
    }

    ax.set(`evaluationSetsProgress.${setId}`, {
      status: 'New',
      linesCount,
      isSample,
    });

    yield put(actions.evaluationJobs.get({}));

    yield put({
      type: 'NAVIGATE',
      to: `/tools/evaluation/${setId}/inbox`,
    });
  } catch (err: any) {
    yield* call(analytics, {
      action: 'upload',
      actionValue: err.message,
      eventId: 'EvaluationUploadError',
      extra: { isSample },
    });
  }
}
