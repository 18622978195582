import { icons } from '@ai21/studio-icons';
import { get } from 'lodash';

export type IconProps = {
  iconName?: string;
};

export function Icon(props: IconProps) {
  const { iconName } = props;

  if (!iconName) {
    return null;
  }

  const Cmp = get(icons, iconName);

  if (!Cmp) {
    return null;
  }

  return <Cmp />;
}

export default Icon;
