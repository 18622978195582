import type { IMenuItem } from '@ai21/studio-base-ui';
import { Divider, Icon } from '@ai21/studio-base-ui';
import { Item, ItemIcon, Text, UserEmail, Wrapper } from './AccountMenu.style';

export type AccountMenuProps = {
  userEmail?: string;
  items: IMenuItem[];
  anchor: Element | null;
  onClick: (item: IMenuItem) => void;
  onClose: () => void;
};

export function AccountMenu(props: AccountMenuProps) {
  const { items, anchor, userEmail } = props;

  function renderItem(item: IMenuItem) {
    const { title, iconName, hasDivider } = item;

    return [
      <Item
        data-testid={`AccountMenu-item-${item.id}`}
        key={item.id}
        onClick={() => props.onClick(item)}
        data-href={item.path}
      >
        {iconName && (
          <ItemIcon>
            <Icon iconName={iconName} />
          </ItemIcon>
        )}
        <Text textAlign='center'>{title}</Text>
      </Item>,
      hasDivider ? <Divider /> : null,
    ];
  }

  function renderItems() {
    return items.map((item: IMenuItem) => renderItem(item));
  }

  function renderUserEmail() {
    if (!userEmail) {
      return null;
    }

    return (
      <>
        <UserEmail disabled>
          <ItemIcon>
            <Icon iconName='menu.User' />
          </ItemIcon>
          {userEmail}
        </UserEmail>
        <Divider />
      </>
    );
  }

  return (
    <Wrapper
      className='AccountMenu-wrapper'
      data-testid='AccountMenu-wrapper'
      sx={{ mt: '30px' }}
      id='app-bar-account-menu'
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchor)}
      onClose={props.onClose}
    >
      {renderUserEmail()}
      {renderItems()}
    </Wrapper>
  );
}

export default AccountMenu;
