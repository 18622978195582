import { fork, put, take } from 'saga-ts';
import { root as fetchRoot } from './saga.root';

function* root() {
  yield* fork(fetchRoot);
  yield take('AUTHENTICATION_COMPLETED');
  yield put({ type: 'FETCH_ROOT' });
}

export const studioSagas = [root];
