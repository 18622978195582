import {
  Auto,
  Checkbox,
  Dropdown,
  IOption,
  Input,
  MultiSelect,
  SliderInput,
  StopSequences,
  Tags,
  Tip,
} from '@ai21/studio-base-ui';
import classnames from 'classnames';
import { FC } from 'react';
import { Control, useController, useFormState } from 'react-hook-form';
import { FieldType, IFormField, LabelSize } from '../../forms.types';
import { IOEditor } from '../IOEditor/IOEditor';
import JsonEditor from '../JsonEditor/JsonEditor';
import { JsonTable } from '../JsonTable/JsonTable';
import { Label } from '../Label/Label';
import { Editor, LabelText, Wrapper } from './Field.style';

export type FieldProps = {
  field: IFormField;
  control: Control;
  errorMessage?: string;
  labelSize?: LabelSize;
  itemId?: string;
  isError?: boolean;
  options?: IOption[];
};

export function Field(props: FieldProps) {
  const { field, labelSize } = props;
  const { id, fieldType, params, tooltip } = field;

  const formState = useFormState();

  const isError = formState.errors[id] !== undefined;
  const isHidden = shouldHide(props);

  const Cmp = map[fieldType];

  const className = classnames('Field-wrapper', params?.className, {});

  if (isHidden) {
    return null;
  }

  function renderLabel() {
    if (labelSize === 'none') {
      return;
    }

    return <Label field={field} isError={isError} tooltip={tooltip} />;
  }

  return (
    <Wrapper className={className} data-testid='Field-wrapper'>
      {renderLabel()}
      <Cmp {...props} isError={isError} />
    </Wrapper>
  );
}

export function FieldInput(props: FieldProps) {
  const { field, control, isError, labelSize } = props;
  let { placeholder, label, autoFocus, isDisabled, params } = field;
  const { rows } = params ?? {};

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  if (labelSize === 'base') {
    label = '';
  }

  return (
    <Input
      placeholder={placeholder}
      fullWidth={true}
      rows={rows}
      autoFocus={autoFocus}
      label={label}
      isError={isError}
      isDisabled={isDisabled}
      data-testid={`input-${field.id}`}
      {...fieldMethods}
    />
  );
}

export function FieldSlider(props: FieldProps) {
  const { field, control, isError, labelSize } = props;
  let { label, isDisabled, params } = field;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  if (labelSize === 'base') {
    label = '';
  }

  return (
    <SliderInput
      isError={isError}
      isDisabled={isDisabled}
      data-testid={`input-${field.id}`}
      step={params?.step}
      min={params?.min}
      max={params?.max}
      {...fieldMethods}
    />
  );
}

export function FieldSelect(props: FieldProps) {
  const { field, control, isError, labelSize, options = [] } = props;
  let { placeholder, label, autoFocus, isReadOnly, isDisabled } = field;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  if (labelSize === 'base') {
    label = '';
  }

  return (
    <Dropdown
      placeholder={placeholder}
      fullWidth={true}
      autoFocus={autoFocus}
      label={label}
      isError={isError}
      data-testid={`input-${field.id}`}
      readOnly={isReadOnly}
      isDisabled={isDisabled}
      options={options}
      {...fieldMethods}
    />
  );
}

export function FieldAutoComplete(props: FieldProps) {
  const { field, control, isError, labelSize, options = [] } = props;
  let { placeholder, label, autoFocus, isReadOnly, isDisabled } = field;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  if (labelSize === 'base') {
    label = '';
  }

  return (
    <Auto
      placeholder={placeholder}
      fullWidth={true}
      autoFocus={autoFocus}
      label={label}
      isError={isError}
      data-testid={`input-${field.id}`}
      readOnly={isReadOnly}
      isDisabled={isDisabled}
      options={options}
      {...fieldMethods}
    />
  );
}

export function FieldMultiSelect(props: FieldProps) {
  const { field, control, isError, labelSize, options = [] } = props;
  let { placeholder, label, autoFocus, isReadOnly, isDisabled, params } = field;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  if (labelSize === 'base') {
    label = '';
  }

  return (
    <MultiSelect
      placeholder={placeholder}
      fullWidth={true}
      autoFocus={autoFocus}
      label={label}
      isError={isError}
      data-testid={`input-${field.id}`}
      readOnly={isReadOnly}
      isDisabled={isDisabled}
      options={options}
      minimumInputLength={params?.min}
      {...fieldMethods}
    />
  );
}

export function FieldCheckbox(props: FieldProps) {
  const { field, control, isError, labelSize } = props;

  let { placeholder, label, autoFocus, isDisabled, tooltip } = field;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  return (
    <>
      <Checkbox disabled={isDisabled} {...fieldMethods} defaultChecked />
      <LabelText>
        {label} <Tip value={tooltip} />
      </LabelText>
    </>
  );
}

export function FieldEmail(props: FieldProps) {
  const { field, control, isError, labelSize } = props;
  let { placeholder, label, autoFocus, isDisabled } = field;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  if (labelSize === 'base') {
    label = '';
  }

  return (
    <Input
      placeholder={placeholder}
      autoFocus={autoFocus}
      isError={isError}
      label={label}
      data-testid={`input-${field.id}`}
      isDisabled={isDisabled}
      {...fieldMethods}
    />
  );
}

export function FieldPassword(props: FieldProps) {
  const { field, control, isError, labelSize } = props;
  let { placeholder, label, autoFocus, isDisabled } = field;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  if (labelSize === 'base') {
    label = '';
  }

  return (
    <Input
      isPassword
      isError={isError}
      autoFocus={autoFocus}
      label={label}
      placeholder={placeholder}
      fullWidth={true}
      data-testid={`input-${field.id}`}
      isDisabled={isDisabled}
      {...fieldMethods}
    />
  );
}

export function FieldHidden(props: FieldProps) {
  const { field, control } = props;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  return <input type='hidden' data-testid={`input-${field.id}`} {...fieldMethods} />;
}

export function FieldTags(props: FieldProps) {
  const { field, control, isError, labelSize } = props;
  let { placeholder, label, autoFocus, params } = field;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  if (labelSize === 'base') {
    label = '';
  }
  return (
    <Tags
      isError={isError}
      options={[]}
      autoFocus={autoFocus}
      label={label}
      placeholder={placeholder}
      fullWidth={true}
      data-testid={`input-${field.id}`}
      maxTags={params?.maxTags}
      enterAsSymbol={params?.enterAsSymbol}
      {...fieldMethods}
    />
  );
}

export function FieldStopSequences(props: FieldProps) {
  const { field, control, isError, labelSize } = props;
  let { placeholder, label, autoFocus, params } = field;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  if (labelSize === 'base') {
    label = '';
  }

  return (
    <StopSequences
      isError={isError}
      options={[]}
      autoFocus={autoFocus}
      label={label}
      placeholder={placeholder}
      fullWidth={true}
      data-testid={`input-${field.id}`}
      maxTags={params?.maxTags}
      {...fieldMethods}
    />
  );
}

export function FieldIO(props: FieldProps) {
  const { field, control } = props;
  const { label, params } = field;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  return <IOEditor title={label} {...fieldMethods} {...params} />;
}

export function FieldJson(props: FieldProps) {
  const { field, control } = props;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  return <JsonTable {...fieldMethods} />;
}

export function FieldCode(props: FieldProps) {
  const { field, control } = props;

  const { field: fieldMethods } = useController({
    name: field.id,
    control,
  });

  return (
    <Editor>
      <JsonEditor height={200} {...fieldMethods} />
    </Editor>
  );
}

export const shouldHide = (props: FieldProps) => {
  const { field, options = [] } = props;
  const { fieldType, params } = field;
  const { hideIfEmpty, emptyCount } = params ?? {};

  if (!['select', 'multiselect', 'autocomplete'].includes(fieldType)) {
    return false;
  }

  if (hideIfEmpty && options.length <= emptyCount) {
    return true;
  }
};

const map: Record<FieldType, FC<FieldProps>> = {
  text: FieldInput,
  password: FieldPassword,
  email: FieldEmail,
  autocomplete: FieldAutoComplete,
  checkbox: FieldCheckbox,
  select: FieldSelect,
  stopSequences: FieldStopSequences,
  multiselect: FieldMultiSelect,
  slider: FieldSlider,
  hidden: FieldHidden,
  tags: FieldTags,
  io: FieldIO,
  json: FieldJson,
  code: FieldCode,
};

export default Field;
