import { A, Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  margin-top: -16px;
`;

export const Header = styled(Typography)``;

export const Documentation = styled(A)``;

export const P = styled(Typography)`
  margin: 20px 0 30px;
`;
