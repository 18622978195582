interface AccountIconProps {
  style: React.CSSProperties;
  width?: number;
  height?: number;
}

export function AccountIcon({ style, width = 22, height = 22 }: AccountIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 22 22'
      style={style}
    >
      <path d='M11 22C17.0752 22 22 17.0752 22 11C22 4.9248 17.0752 0 11 0C4.9248 0 0 4.9248 0 11C0 17.0752 4.9248 22 11 22ZM14.0557 7.94434C14.0557 9.63232 12.6882 11 11 11C9.31177 11 7.94458 9.63232 7.94458 7.94434C7.94458 6.25586 9.31177 4.88867 11 4.88867C12.6882 4.88867 14.0557 6.25586 14.0557 7.94434ZM4.88892 15.583C4.88892 13.5513 8.96045 12.5273 11 12.5273C13.0396 12.5273 17.1111 13.5513 17.1111 15.583V17.1108H4.88892V15.583Z' />
    </svg>
  );
}
