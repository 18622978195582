import { MenuItemProps as Props } from '@mui/material';
import React, { ForwardedRef } from 'react';
import { invokeEvent } from 'shared-base';
import { Wrapper } from './MenuItem.style';

export type MenuItemProps = Props & {
  className?: string;
};

export const MenuItem = React.forwardRef(
  (props: MenuItemProps, ref: ForwardedRef<HTMLLIElement>) => {
    function onClick(ev: React.MouseEvent<HTMLLIElement, MouseEvent>) {
      if (!props.onClick) {
        return;
      }

      invokeEvent('global/click', {
        ev,
      });

      return props.onClick(ev);
    }

    return (
      <Wrapper
        className='MenuItem-wrapper'
        data-testid='MenuItem-wrapper'
        ref={ref}
        {...props}
        onClick={onClick}
      />
    );
  }
);

export default MenuItem;
