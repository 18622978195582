import { fireRequest } from './axios';

export const getPaymentInfo = () => {
  return fireRequest<Json>({
    path: '/billing/payment-method',
    endpoint: 'billing.getPaymentInfo',
  });
};

export const getBillingInfo = () => {
  return fireRequest<Json>({
    path: '/billing',
    endpoint: 'billing.getBillingInfo',
  });
};

export const getInvoices = () => {
  return fireRequest<Json>({
    path: '/billing/invoices',
    endpoint: 'billing.getInvoices',
  });
};

export const cancelSubscription = () => {
  return fireRequest<Json>({
    path: '/tier',
    method: 'delete',
    endpoint: 'billing.cancelSubscription',
  });
};

export const uncancelSubscription = () => {
  return fireRequest<Json>({
    path: '/tier',
    method: 'put',
    endpoint: 'billing.uncancelSubscription',
  });
};

export const updatePaymentMethod = (paymentMethodId: string) => {
  return fireRequest<Json>({
    path: '/billing/update-payment-method',
    method: 'post',
    data: {
      paymentMethodId,
    },
    endpoint: 'billing.updatePaymentMethod',
  });
};

export const billing = {
  getPaymentInfo,
  getBillingInfo,
  getInvoices,
  cancelSubscription,
  uncancelSubscription,
  updatePaymentMethod,
};
