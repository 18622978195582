import { firebase } from '../../utils/globals';
import { eventChannel } from 'redux-saga';

export function authChangeChannel() {
  return eventChannel((emitter) => {
    function onAuthStateChanged(user: any) {
      emitter({ user });
    }

    firebase.addAuthListener(onAuthStateChanged);

    return () => {
      firebase.removeAuthListener(onAuthStateChanged);
    };
  });
}
