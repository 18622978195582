import { AxiosInstance } from 'axios';
import { setInstances } from '../axios';
import { MockRequest } from '../types';
import { get } from 'lodash';
import { cans } from './test.cans';

export const initAxiosForTests = () => {
  const instanceApi = createAxiosMock() as AxiosInstance;

  const instanceIdentity = createAxiosMock() as AxiosInstance;

  setInstances(instanceApi, instanceIdentity);
};

const createAxiosMock = () => {
  return ((request: MockRequest) => {
    const { method, url } = request;

    let data: Json = {};

    const canMethod = get(cans, [url, method]);

    if (typeof canMethod === 'function') {
      data = canMethod(request);
    }

    return wrapData(data);
  }) as any;
};

export const wrapData = (data: Json) => {
  return Promise.resolve({
    status: 200,
    statusText: 'OK',
    headers: {},
    data,
  });
};
