import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  position: fixed;
  top: 100px;
  right: 100px;
  width: 50px;
  height: 50px;
  background-color: #334;
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 1000px;
  height: 640px;
  z-index: 999999;

  &.compact {
    width: 590px;

    .log-item {
      float: none;
      width: 100%;
    }
  }

  &.minimized {
    width: 180px;
    height: 40px;

    .content,
    .lastEvent {
      display: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #778;
  padding: 6px 15px 6px 10px;
`;

export const Title = styled.div`
  flex: 1;
`;

export const Close = styled.div`
  font-size: 10px;

  cursor: pointer;

  &:hover {
    svg {
      fill: gold;
    }
  }
`;

export const Items = styled.div``;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #556;
  float: left;
  width: 33%;
  padding: 6px;
  box-sizing: border-box;
  border-top: none;
  border-left: none;

  &.focused {
    background-color: gold;
    color: #333;
    font-weight: bold;
  }

  &:hover {
    background-color: #445;
    cursor: pointer;
  }
`;

export const ItemTitle = styled.div`
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
`;

export const ItemCount = styled.div`
  width: 25px;
  text-align: center;
  margin-right: 6px;
`;

export const ItemCheckmark = styled.div`
  width: 22px;
  height: 22px;
  text-align: center;
  color: #778;

  &.done {
    background-color: green;
    border-radius: 20px;
    color: #fff;
  }
`;

export const Checkmark = styled.div``;

export const Count = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 6px;
  border-radius: 6px;
`;

export const Content = styled.div``;

export const Index = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 6px;
  width: 25px;
  text-align: center;
  margin-right: 6px;
  border-radius: 6px;
`;

export const LastEvent = styled.div`
  width: 300px;
  border-right: 1px solid #778;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const List = styled.div`
  flex: 1;
`;

export const Pre = styled.pre`
  color: #ccd;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const Action = styled.button`
  background-color: #334;
  color: white;
  border: 1px solid #555;
  cursor: pointer;
  position: relative;
  margin: 2px 0;

  &:hover {
    background-color: #445;
  }

  &:active {
    background-color: #556;
    bottom: 1px;
    left: 1px;
  }
`;

export const History = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 321px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
`;

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 8px;
  margin: 3px;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: yellowgreen;

  &:hover {
    border: 1px solid gold;
  }

  &.ButtonPressed {
    background-color: gold;
  }

  &.NavigatorPressed {
    background-color: pink;
  }

  &.PageEntered {
    background-color: #667;
  }

  &.PageExited {
    background-color: #667;
  }
`;
