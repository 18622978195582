import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #626b78;

  svg {
    width: 18px;
  }
`;
