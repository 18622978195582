import { Icon, PopMenu, Search, icons } from '@ai21/studio-base-ui';
import { useContext } from 'react';
import { TableContext } from '../../context/Table.context';
import { ITableAction, ITableHeader } from '../../types';
import {
  Actions,
  Count,
  Flex,
  SearchContainer,
  Subtitle,
  TableActionButton,
  Title,
  TitleContainer,
  TitleWithChevron,
  Wrapper,
} from './TableTop.style';

export type TableTopProps = {
  header: ITableHeader;
  onTableAction: (actionType: string, params?: Json) => void;
  cta?: React.ReactNode;
  children?: React.ReactNode;
};

export function TableTop(props: TableTopProps) {
  const { header, cta } = props;
  const { callbacks, features, state, config, count, isMobile } = useContext(TableContext);

  const { tableActions = [], misc } = config;
  const { hideTop } = misc ?? {};
  const { q = '' } = state;
  const { withSearch, withNew } = features;

  const { title, subtitle, link } = header ?? {};

  if (hideTop) {
    return null;
  }

  function onTitleClick() {
    if (!link) {
      return;
    }

    props.onTableAction('navigate', { to: link });
  }

  function onTableAction(action: ITableAction) {
    if (action.id === 'exportData') {
      callbacks.onExportData();
      return;
    }

    props.onTableAction(action.id);
  }

  function renderTitle() {
    if (withSearch) {
      return null;
    }

    if (!title) {
      return null;
    }

    return (
      <TitleContainer>
        <TitleWithChevron data-testid={`table-header-${config.id}`} onClick={onTitleClick}>
          <Title variant='h6' color='text.primary' link={link}>
            {title}
          </Title>
          {count.all > 0 ? (
            <Count variant='subtitle1' onClick={onTitleClick} style={{ cursor: 'pointer' }}>
              ({count.all})
            </Count>
          ) : null}
          {link && (
            <Icon iconName='ForwardChevron' onClick={onTitleClick} style={{ cursor: 'pointer' }} />
          )}
        </TitleWithChevron>

        <Subtitle variant='subtitle2' color='text.secondary'>
          {subtitle}
        </Subtitle>
      </TitleContainer>
    );
  }

  function renderSearch() {
    if (!withSearch) {
      return null;
    }

    return (
      <SearchContainer>
        <Search
          placeholder='Search...'
          value={q}
          onChange={(value: string) => callbacks.onSearch(value)}
        />
      </SearchContainer>
    );
  }

  function renderNew() {
    if (!withNew || isMobile) {
      return null;
    }

    return (
      <TableActionButton key={'Plus'} onClick={() => onTableAction(newAction)} iconName='Plus' />
    );
  }

  function renderTableActions() {
    if (tableActions.length === 0) {
      return null;
    }

    return (
      <PopMenu
        iconName='ThreeDots'
        items={tableActions}
        onClick={(item) => onTableAction(item as ITableAction)}
        dataTestId='tableActions'
      />
    );
  }

  return (
    <Wrapper className='TableTop-wrapper' data-testid='TableTop-wrapper'>
      {renderTitle()}
      {renderSearch()}
      {renderNew()}
      <Actions className='actions'>
        {renderTableActions()}
        {cta}
      </Actions>
    </Wrapper>
  );
}

const newAction: ITableAction = {
  id: 'new',
  iconName: 'Plus',
  title: 'New',
};

export default TableTop;
