import type { IRouteMap } from '@ai21/studio-ui';

export enum AppRoutes {
  RagEngine = '/rag-engine',
}

export const publicRoutes: IRouteMap = {};

export const privateRoutes: IRouteMap = {
  [AppRoutes.RagEngine]: 'ragEngine',
};
