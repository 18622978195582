export function UploadingDatasetIcon() {
  return (
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32zM3.237 32c0 15.885 12.878 28.763 28.763 28.763 15.885 0 28.763-12.878 28.763-28.763C60.763 16.115 47.885 3.237 32 3.237 16.115 3.237 3.237 16.115 3.237 32z'
        fill='#F8B5CC'
      />
      <path
        d='M64 32a32 32 0 0 1-28.095 31.76l-.395-3.212A28.762 28.762 0 0 0 60.763 32H64z'
        fill='#753FEA'
      />
      <path
        d='M32 28c5.892 0 10.667-1.79 10.667-4s-4.775-4-10.666-4-10.667 1.79-10.667 4 4.776 4 10.667 4z'
        stroke='#626B78'
        strokeWidth='2.063'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.334 24v8c0 1.06 1.124 2.078 3.124 2.828S29.172 36 32.001 36c2.829 0 5.542-.421 7.542-1.172 2-.75 3.124-1.767 3.124-2.828v-8'
        stroke='#626B78'
        strokeWidth='2.063'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.334 32v8c0 1.06 1.124 2.078 3.124 2.828s4.714 1.171 7.543 1.171c2.829 0 5.542-.42 7.542-1.171 2-.75 3.124-1.768 3.124-2.829v-8'
        stroke='#626B78'
        strokeWidth='2.063'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
