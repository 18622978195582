import styled from 'styled-components';
import Button from '../Button/Button';

export const Wrapper = styled(Button)`
  padding: 6px;
  margin-left: 4px;

  svg {
    stroke-width: 1.5px;
  }

  &.unlocked {
    height: 34px;
    min-width: 34px;
    & .MuiButton-endIcon {
      margin-left: 0;
    }
  }
`;
