import { Box, Typography, createStyleHook, layout } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

interface CatalogueStyleProps {
  maxContainerHeight?: number;
  numOfCardsPerRow: number;
  addSectionsPadding?: boolean;
}

export const useCatalogueStyles = createStyleHook((theme, props: CatalogueStyleProps) => {
  return {
    root: {
      maxHeight: props.maxContainerHeight,
    },
    sectionsContainer: {
      marginTop: props.addSectionsPadding ? '80px' : '0',
    },
    titleText: {
      color: tokens.text.primary,
    },
    subtitleText: {
      color: tokens.text.secondary,
    },
    sectionContainer: {
      marginTop: '24px',
    },
    catalogueCell: {
      display: 'table-cell',
      width: `${100 / props.numOfCardsPerRow}%`,
      padding: '0 10px 24px 10px',

      '&:first-of-type': {
        paddingLeft: 0,
      },

      '&:last-of-type': {
        paddingRight: 0,
      },
    },
    catalogueCellEmpty: {
      display: 'table-cell',
      width: `${100 / props.numOfCardsPerRow}%`,
      padding: '0 10px 24px 10px',
    },
    rowsContainer: {
      paddingTop: '24px',
      display: 'table',
      height: '1px',
    },
    cardsRow: {
      display: 'table-row',
    },
    divider: {
      width: '100%',
      height: '1px',
      backgroundColor: tokens.divider,
      marginTop: '24px',
      marginBottom: '24px',
    },
  };
});

export const Wrapper = styled.div<{ maxHeight?: number }>``;

export const Title = styled(Typography)``;

export const Container = styled(layout.Container)``;

export const Subtitle = styled(Typography)``;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  padding: 20px 0;

  &.sticky {
    position: fixed;
    left: 240px;
    right: 0;
    height: 33px;
    background-color: #ffffff;
    z-index: 2;
    top: 210px;

    &.closed {
      top: 122px;
      background-color: #ffffff;
      box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.03);
    }
  }
`;

export const Content = styled.div<{ sticky?: boolean }>`
  padding-top: ${(props) => (props.sticky ? '80px' : '0')};
`;

export const Row = styled.div`
  display: table-row;
`;

export const Cell = styled.div<{ itemsPerRow: number }>`
  display: table-cell;
  width: ${(props) => 100 / props.itemsPerRow}%;
  padding: 0 10px 24px 10px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const CellEmpty = styled.div<{ itemsPerRow: number }>`
  display: table-cell;
  width: ${(props) => 100 / props.itemsPerRow}%;
  padding: 0 10px 24px 10px;
`;

export const Section = styled.div`
  margin-top: 24px;
`;

export const SectionTitle = styled(Typography)`
  color: ${tokens.text.primary};
`;

export const SectionSubtitle = styled(Typography)`
  color: ${tokens.text.secondary};
`;

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${tokens.divider};
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const Items = styled.div`
  padding-top: 24px;
  display: table;
  height: 1px;
`;

export const WrapperEmpty = styled.div`
  margin-top: 25px;
`;

export const A = styled.button`
  margin-top: 4px;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  color: #667;
  text-decoration: underline;
`;

export const Header = styled.div`
  padding-bottom: 20px;
  box-shadow: rgb(229, 229, 229) 0px -1px 0px inset;
`;

export const Column = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};

  &:first-child {
    padding-right: 17px;
  }

  &:last-child {
    padding-left: 3px;
  }
`;
