import { Icon, IMenuItem, Table, useTableCallbacks } from '@ai21/studio-ui';
import { useDispatch, useSelector } from 'react-redux';
import { tables } from '../../_definitions/tables';
import { Cta, Menu, Subtitle, Wrapper } from './CollectionLinesModal.style';
import { selectors } from '@ai21/studio-store';
import { menuItems } from './CollectionLinesModal.data';

export type CollectionLinesModalProps = {
  tableSets: Json[];
  collectionId: string;
};

export function CollectionLinesModal(props: CollectionLinesModalProps) {
  const { tableSets, collectionId } = props;
  const dispatch = useDispatch();
  const collectionName = useSelector(selectors.tables.$collectionJobs).filter(
    (job) => job.id === collectionId
  )[0].name;

  const callbacks = useTableCallbacks(dispatch as any, tables, {
    onAction: (action: Json) => {
      dispatch(action);
    },
    onEvaluate: () => {
      dispatch({
        type: 'COLLECTION_JOB',
        verb: 'evaluate',
        id: collectionId,
      });
    },
    onCta: () => {
      dispatch({
        type: 'COLLECTION_JOB',
        verb: 'start',
        id: collectionId,
      });
    },
    onDownloadCollection: () => {
      dispatch({
        type: 'COLLECTION_JOB',
        verb: 'downloadJson',
        id: collectionId,
      });
    },
  });

  function onMenu(menu: IMenuItem) {
    callbacks.onAction({
      type: 'COLLECTION_LINE',
      verb: menu.id,
      id: collectionId,
    });
  }

  function renderCta() {
    return (
      <>
        <Cta
          variant='outlined'
          color='primary'
          data-testid='EvaluateCollectionSet'
          startIcon={<Icon iconName='Scale' />}
          onClick={callbacks.onEvaluate}
        >
          Evaluate collection
        </Cta>
        <Menu
          iconName='DotsVertical'
          items={menuItems}
          testId='DotMenu'
          onClick={onMenu}
        />
      </>
    );
  }

  return (
    <Wrapper>
      <Subtitle variant='subtitle2' color='textSecondary'>
        Watching <strong>{collectionName}</strong> set
      </Subtitle>
      <Table
        config={tables.collectionLinesModal}
        data={tableSets}
        callbacks={callbacks}
        cta={renderCta()}
      />
    </Wrapper>
  );
}

export default CollectionLinesModal;
