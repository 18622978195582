import { FC, useCallback } from 'react';
import { CatalogueCardsRowProps } from '../Catalogue.types';
import { useCatalogueStyles } from '../Catalogue.styles';
import { range } from 'lodash';
import { Box } from '@ai21/studio-base-ui';
import CatalogueCard from '../../CatalogueCard/CatalogueCard';

export const CatalogueCardsRow: FC<CatalogueCardsRowProps> = ({
  numOfCardsPerRow,
  rowNumber,
  cards,
  cardTestId,
  onClick,
}) => {
  const styles = useCatalogueStyles({
    numOfCardsPerRow: numOfCardsPerRow,
  });

  const renderCards = useCallback(() => {
    return range(0, numOfCardsPerRow).map((columnNumber) => {
      // bad formula to resolve card index, over complicating things
      // TODO: as said below, we must replace this with simple rendering of cards
      // and use CSS to render them properly.
      const cardIndex = rowNumber * numOfCardsPerRow + columnNumber;
      const currentCard = cards[cardIndex];

      if (!currentCard) {
        return <Box key={`empty-card-${cardIndex}`} sx={styles.catalogueCellEmpty} />;
      }

      return (
        <Box key={`card-${currentCard.id}`} sx={styles.catalogueCell}>
          <CatalogueCard
            id={currentCard.id}
            item={currentCard}
            onClick={() => onClick(currentCard)}
          />
        </Box>
      );
    });
  }, [numOfCardsPerRow, cards, onClick, cardTestId]);

  return (
    <Box key={`cards-row-${rowNumber}`} sx={styles.cardsRow}>
      {renderCards()}
    </Box>
  );
};
