import { delay, takeEvery } from 'saga-ts';
import { logEvent } from '../analytics.amplitude';
import { IncomingNavigationEventWrapped } from '../analytics.types';
import { customEvenChannel } from './channels/channel.customEvent';

let pageVisitStartTime = Date.now();
let previousLocation: Location;

export function* listenToNavigation(event: IncomingNavigationEventWrapped) {
  const { nextLocation } = event.data;

  if (previousLocation) {
    pageExited({ ...previousLocation });
  }

  yield delay(0);
  pageEntered(nextLocation);
  previousLocation = nextLocation;
}

export function* root() {
  const channel = customEvenChannel('global/navigation');
  yield takeEvery(channel, listenToNavigation);
}

function pageExited(previousLocation: Location) {
  const { search, pathname, origin } = previousLocation;

  const duration = Date.now() - pageVisitStartTime;

  logEvent('PageExited', {
    path: pathname,
    origin,
    search,
    durationMillis: duration,
    navAction: '',
  });
  return duration;
}

function pageEntered(nextLocation: Location) {
  const { search, pathname, origin } = nextLocation;

  const prevDuration = Date.now() - pageVisitStartTime;
  pageVisitStartTime = Date.now();

  const ev = {
    curr_path: pathname,
    curr_search: search,
    prev_durationMillis: prevDuration,
    prev_path: previousLocation?.pathname,
    queryParams: search,
    action: '',
    referrer: document.referrer,
    origin,
  };

  logEvent('PageEntered', ev);
}
