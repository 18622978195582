import { apiUrl } from '../globals';
import { CustomModelType, IChatMessage } from '../types';

export function isGeneralModel(modelName: string) {
  return Object.keys(MODELS_DISPLAY_NAMES).includes(modelName);
}

export const MODELS_DISPLAY_NAMES: Json = {
  'j2-light': 'j2-light',
  'jamba-instruct-preview': 'jamba-instruct-preview',
  'jamba-1.5-large': 'jamba-1.5-large',
  'jamba-1.5-mini': 'jamba-1.5-mini',
  'j2-mid': 'j2-mid',
  'j2-ultra': 'j2-ultra',
  'j2-jumbo-instruct': 'j2-ultra',
  'j2-grande-instruct': 'j2-mid',
  'j2-jumbo': 'j2-ultra',
  'j2-grande': 'j2-mid',
  'j2-large': 'j2-light',
};

export const formatChatMessages = (messages: string) => {
  const parsedMessages = JSON.parse(messages);

  if (parsedMessages && parsedMessages.length > 0) {
    const content = JSON.stringify(parsedMessages, null, 2);

    // pad each line with 15 spaces
    const contentWithPad = content.replace(/^(?=.)/gm, ' '.repeat(18));
    return contentWithPad.trim().replace(/'/g, "'").replace(/`/g, '`');
  }

  return messages;
};

export const buildCompletionUrl = (modelName: string, customModelType: string | null) => {
  if (
    modelName === CustomModelType.JAMBA_INSTRUCT ||
    modelName === CustomModelType.JAMBA_1_5_LARGE ||
    modelName === CustomModelType.JAMBA_1_5_MINI
  ) {
    return `${apiUrl}/chat/completions`;
  }
  if (isGeneralModel(modelName)) {
    return `${apiUrl}/${modelName}/complete`;
  }
  // CustomModel Flow:
  return `${apiUrl}/${customModelType ? `${customModelType}/` : ''}${modelName}/complete`;
};
