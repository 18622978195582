import { IUser } from '../types';

/**
 * Indicates whether the current user works at AI21 Labs or not
 * (employee user)
 */
export const isAI21User = (user: IUser) => {
  return user.userEmail?.endsWith('@ai21.com') ?? false;
};

/**
 * Checks if the given feature flag is enabled on the user config
 */
export const isFeatureFlagEnabled = (user: IUser, featureFlagName: string) => {
  const enabledFeatures = (window.__env__.VITE_FEATURE_FLAG_ENABLED ?? '').split(',');

  if (enabledFeatures.includes(featureFlagName)) return true;

  const featureFlagValue = user.featureFlags?.[featureFlagName];
  if (!featureFlagValue) return false;

  return featureFlagValue.toLocaleLowerCase() === 'true';
};
