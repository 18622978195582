import { Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  background-color: #fafaff;
`;

export const Header = styled(Typography)`
  padding: 0;
  margin: 0;
  color: #dbd7fd;
  font-size: 140px;
  font-weight: 700;
`;

export const P = styled(Typography)`
  padding: 0;
  margin: 0;
  color: #a8aeb8;
`;
export const P1 = styled(Typography)`
  padding: 0;
  margin-top: 24px;
  max-width: 500px;
  text-align: center;
  color: #626b78;
`;
