import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1px 8px;
  letter-spacing: 0.25px;
  font-weight: 500;
  float: left;
  border-radius: 4px;

  &.rank-10 {
    background: #f0fdf3;
    color: #13682c;
  }

  &.rank-5 {
    background: #fefce8;
    color: #a46004;
  }

  &.rank-0 {
    background: #fff1f0;
    color: #d20f03;
  }

  &.rank--1 {
    background: #f4f5f7;
    color: #484d56;
  }
`;
