import { FC, useCallback, useEffect } from 'react';
import { useSetState } from 'react-use';
import { addListener, invokeEvent } from 'shared-base';
import { useEscape } from '../../hooks/useEscape';
import { Drawer } from './Drawer';

const DRAWER_SHOW_EVENT = 'SHOW_DRAWER';
const DRAWER_CLOSE_EVENT = 'DRAWER_CLOSE';
const DRAWER_CLOSE_TRIGGER_EVENT = 'DRAWER_CLOSE_TRIGGER';

type DrawerContainerProps = {
  formComponent?: FC<any>;
  isRtl?: boolean;
};

type DrawerState = {
  id: string;
  show: boolean;
  hideBackdrop?: boolean;
  variant: 'persistent' | 'temporary';
  params: Json;
};

export function DrawerContainer(props: DrawerContainerProps) {
  const [state, patchState] = useSetState<DrawerState>({
    id: '',
    show: false,
    variant: 'temporary', // persistent
    hideBackdrop: false,
    params: {},
  });

  const { show } = state;

  useEffect(() => {
    invokeEvent('drawer/show', { show });
  }, [show]);

  useEffect(() => {
    const removeListener = addListener(DRAWER_SHOW_EVENT, (ev: any) => {
      patchState({
        ...ev,
        show: true,
      });
    });

    const removeTriggerListener = addListener(
      DRAWER_CLOSE_TRIGGER_EVENT,
      () => {
        patchState({
          show: false,
        });
      }
    );

    return () => {
      removeListener();
      removeTriggerListener();
    };
  }, []);

  const onClose = useCallback(() => {
    invokeEvent(DRAWER_CLOSE_EVENT, {
      id: 'drawer_close',
      title: 'Drawer Close',
    });

    patchState({ show: false });
  }, []);

  useEscape(
    () => {
      onClose();
    },
    show,
    [state]
  );

  return <Drawer {...state} onClose={onClose} />;
}

export default DrawerContainer;
