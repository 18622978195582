import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px 0 10px;
  position: relative;
`;

export const InputWrapper = styled.div`
  margin-left: 30px;
  position: absolute;
  top: -30px;
  right: 6px;

  .MuiInputBase-root {
    &::before {
      border-bottom: none;
    }
  }

  input {
    min-width: 50px;
    text-align: right;

    &::before {
      border-bottom: none;
    }
  }
`;
