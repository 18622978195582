export const buttons: Json[] = [
  {
    value: 10,
    title: 'Great',
    iconName: 'DoubleThumbUp',
    color: 'green',
    shortKey: {
      key: '1',
      withAlt: true,
      preventDefault: true,
    },
  },
  {
    value: 5,
    title: 'Okay',
    iconName: 'ThumbUp',
    color: 'yellow',
    shortKey: {
      key: '2',
      withAlt: true,
      preventDefault: true,
    },
  },
  {
    value: 0,
    title: 'Bad',
    color: 'red',
    iconName: 'ThumbDown',
    shortKey: {
      key: '3',
      withAlt: true,
      preventDefault: true,
    },
  },
  {
    value: -1,
    title: 'Skip',
    selectedTitle: 'Skipped',
    color: 'gray',
    shortKey: {
      key: '4',
      withAlt: true,
      preventDefault: true,
    },
  },
];

export const scoreToTagPlaceholder: any = {
  10: 'What makes it great? Select or start typing...',
  5: 'What should improve? Select or start typing...',
  0: 'What should improve? Select or start typing...',
  '': 'Add reasons...',
};
