import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 50px;
    height: 50px;
    stroke-width: 1px;
  }
`;

export const Text = styled.div`
  margin-top: 30px;
  font-weight: 200;
  font-size: 30px;
`;

export const Instructions = styled.div`
  margin-top: 15px;
  font-weight: 200;
  font-size: 24px;
`;
