import { PaymentActionTypes } from './PaymentActionTypes';
import { CustomModelsActionTypes } from './customModelsActionTypes';
import { DatasetsActionTypes } from './datasetsActionTypes';
import { OverviewActionTypes } from './overviewActionTypes';
import { PlaygroundActionTypes } from './playgroundActionTypes';
import { PresetActionTypes } from './presetActionTypes';
import { PricingActionTypes } from './pricingActionTypes';
import { RewriteActionTypes } from './rewriteActionTypes';
import { SummarizationActionTypes } from './summarizationActionTypes';
import { UserActionTypes } from './userActionTypes';

export type ActionTypes =
  | PlaygroundActionTypes
  | UserActionTypes
  | MobilePageShown
  | PaymentActionTypes
  | DatasetsActionTypes
  | CustomModelsActionTypes
  | PresetActionTypes
  | PricingActionTypes
  | OverviewActionTypes
  | SummarizationActionTypes
  | RewriteActionTypes;
export const MOBILE_PAGE_SHOWN = 'MOBILE_PAGE_SHOWN';
export interface MobilePageShown {
  type: typeof MOBILE_PAGE_SHOWN;
  path: string;
}
