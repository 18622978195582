interface CompletionApiIconProps {
  selected: boolean;
}

export function CompletionApiIcon({ selected }: CompletionApiIconProps) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 20.0003H8L18.5 9.5003C19.6046 8.39573 19.6046 6.60487 18.5 5.5003C17.3954 4.39573 15.6046 4.39573 14.5 5.5003L4 16.0003V20.0003'
        stroke={selected ? '#262123' : '#CECBCD'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 6.5L17.5 10.5'
        stroke={selected ? '#262123' : '#CECBCD'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
