export function PlaygroundIcon() {
  return (
    <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.97561 3.5014C5.1535 3.40199 5.37125 3.40657 5.5448 3.51337L15.2948 9.51337C15.4612 9.61575 15.5625 9.7971 15.5625 9.99243C15.5625 10.1878 15.4612 10.3691 15.2948 10.4715L5.5448 16.4715C5.37125 16.5783 5.1535 16.5829 4.97561 16.4835C4.79772 16.3841 4.6875 16.1962 4.6875 15.9924V3.99243C4.6875 3.78865 4.79772 3.6008 4.97561 3.5014ZM5.8125 4.99906V14.9858L13.9267 9.99243L5.8125 4.99906Z'
        fill='currentColor'
        fillOpacity='0.6'
      />
    </svg>
  );
}
