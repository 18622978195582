import { FirebaseApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import {
  collection,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  setDoc,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import { ResponseBuilder } from './builders/ResponseBuilder';
import { organizationId } from './globals';

const startFirestoreEmulator = (emulatorAddr: string) => {
  const addressArr = emulatorAddr.split(':');
  connectFirestoreEmulator(db, addressArr[0], Number.parseInt(addressArr[1]));
};

// Initialize Firebase
let app: FirebaseApp, db: Firestore, auth: Auth;

export const initFirebase = (value: FirebaseApp) => {
  app = value;
  db = getFirestore(app);
  if (process.env.FIRESTORE_EMULATOR_HOST) {
    startFirestoreEmulator(process.env.FIRESTORE_EMULATOR_HOST);
  }
  auth = getAuth(app);
};

export type Scope = 'organization' | 'user' | 'root';

const getScopedPath = (pathRaw: string, scope: Scope) => {
  const path = pathRaw.replace(/^\//, '');

  switch (scope) {
    case 'user':
      const uid = auth.currentUser?.uid;
      return `users/${uid}/${path}`;
    case 'organization':
      return `data/${organizationId}/${path}`;
    case 'root':
      return `/${path}`;
  }
};

export const getCollection = async (path: string, scope: Scope) => {
  const response = new ResponseBuilder({} as any);
  const scopedPath = getScopedPath(path, scope);

  const ref = collection(db, scopedPath);

  try {
    const snapshot = await getDocs(ref);
    const items = snapshot.docs.map((doc) => doc.data());

    handleSuccess(response, items);
  } catch (err: any) {
    handleError(response, err);
  }

  return response.build<Json[]>();
};

export const addToCollection = async (path: string, item: Json, scope: Scope) => {
  const response = new ResponseBuilder({} as any);
  const scopedPath = getScopedPath(path, scope);

  const ref = collection(db, scopedPath);
  const docRef = doc(ref, item.id);

  try {
    await setDoc(docRef, item);
    handleSuccess(response, item);
  } catch (err: any) {
    handleError(response, err);
  }

  return response.build<Json>();
};

export const removeCollection = async (path: string, scope: Scope) => {
  const response = new ResponseBuilder({} as any);

  const scopedPath = getScopedPath(path, scope);
  const ref = collection(db, scopedPath);

  try {
    const snapshot = await getDocs(ref);
    const items = snapshot.docs.map((doc) => doc.data());

    handleSuccess(response, items);
  } catch (err: any) {
    handleError(response, err);
  }

  return response.build<Json[]>();
};

export const addToCollectionBatch = async (path: string, item: Json[], scope: Scope) => {
  const response = new ResponseBuilder({} as any);

  const scopedPath = getScopedPath(path, scope);
  const ref = collection(db, scopedPath);
  const batch = writeBatch(db);

  item.forEach((item) => {
    const docRef = doc(ref, item.id);
    batch.set(docRef, item);
  });

  try {
    await batch.commit();
    handleSuccess(response, item);
  } catch (err: any) {
    handleError(response, err);
  }

  return response.build<Json[]>();
};

export const getCollectionItem = async (path: string, scope: Scope) => {
  const response = new ResponseBuilder({} as any);

  const scopedPath = getScopedPath(path, scope);
  const ref = doc(db, scopedPath);

  try {
    const docSnap = await getDoc(ref);
    const data = docSnap.data() as Json;
    handleSuccess(response, data);
  } catch (err: any) {
    handleError(response, err);
  }

  return response.build<Json>();
};

export const updateCollectionItem = async (path: string, change: Json, scope: Scope) => {
  const response = new ResponseBuilder({} as any);

  const scopedPath = getScopedPath(path, scope);
  const ref = doc(db, scopedPath);

  try {
    await updateDoc(ref, change);
    const data = await getCollectionItem(path, scope);
    handleSuccess(response, data);
  } catch (err: any) {
    console.log('err ->', err);
    handleError(response, err);
  }

  return response.build<Json>();
};

export const removeCollectionItem = async (path: string, scope: Scope) => {
  const response = new ResponseBuilder({} as any);

  const scopedPath = getScopedPath(path, scope);
  const ref = doc(db, scopedPath);

  try {
    await deleteDoc(ref);
    handleSuccess(response, {});
  } catch (err: any) {
    handleError(response, err);
  }

  return response.build<Json>();
};

export const listenToCollection = (path: string, callback: any, scope: Scope) => {
  const scopedPath = getScopedPath(path, scope);
  const ref = collection(db, scopedPath);

  return onSnapshot(ref, (snapshot) => {
    const change = snapshot.docChanges()[0];
    const item = change.doc.data();
    callback(item);
  });
};

export const setSingle = async (path: string, change: Json, scope: Scope) => {
  const response = new ResponseBuilder({} as any);

  const scopedPath = getScopedPath(path, scope);
  const ref = doc(db, scopedPath);

  try {
    await setDoc(ref, change);
    const data = await getSingle(path, scope);
    handleSuccess(response, data);
  } catch (err: any) {
    console.log('err ->', err);
    handleError(response, err);
  }

  return response.build<Json>();
};

export const getSingle = async (path: string, scope: Scope) => {
  const response = new ResponseBuilder({} as any);

  const scopedPath = getScopedPath(path, scope);
  const ref = doc(db, scopedPath);

  try {
    const docSnap = await getDoc(ref);
    const data = docSnap.data() as Json;
    handleSuccess(response, data);
  } catch (err: any) {
    handleError(response, err);
  }

  return response.build<Json>();
};

export const handleSuccess = (response: ResponseBuilder, data: Json | Json[]) => {
  response
    .withData(data) //
    .withIsSuccess(true);
};

export const handleError = (response: ResponseBuilder, err: any) => {
  response
    .withErrorMessage(err.message) //
    .withIsError(true)
    .withIsSuccess(false);
};
