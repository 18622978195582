import { Button, styled } from '@ai21/studio-ui';
import { layout } from '@ai21/studio-ui';

export const Wrapper = styled('div')`
  font-size: 14px;
  flex: 1;
`;

export const Container = styled(layout.Container)``;

export const Cta = styled(Button)`
  svg {
    position: relative;
    top: 1px;
    left: 4px;
  }
`;

export const Content = styled.div`
  min-height: calc(100vh - 260px);

  @media (max-width: 1000px) {
  }
`;
