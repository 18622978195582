import { ICatalogueItem } from '@ai21/studio-ui';
import { createSelector } from 'reselect';
import { IStudioStore } from '../types';
import * as raw from './selectors.raw';
import { sortBy } from 'shared-base';
import { isAI21User } from '../utils/userUtils';
import {
  resolveUrlForFoundationModel,
  resolveUrlForJambaModel,
  shouldTSMLinkToExternalDocs,
} from '../utils/urlUtils';
import { isBetaAvailable } from '../utils/modelUtils';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $examples = createSelector(raw.$rawAppState, raw.$rawPresets, (appState, presets) => {
  return Object.values(presets)
    .filter((preset) => !preset.isUserPreset && !preset.isHidden)
    .map((preset) => {
      const { id, name, iconName, description, primaryLabel, secondaryLabels = [] } = preset;

      const playgroundUrl = '/playground';

      return {
        id,
        title: name,
        iconName,
        description,
        tags: [primaryLabel, ...secondaryLabels],
        url: playgroundUrl + '/complete?presetId=' + id,
      };
    });
});

/**
 * Data for task specific models, used by the data models catalogue page
 */
export const $apis = createSelector(
  raw.$rawAppState,
  raw.$rawApis,
  (appState, apis): ICatalogueItem[] => {
    return Object.values(apis).map((api) => {
      let {
        id,
        name,
        iconName,
        description,
        endpoint,
        playgroundUrl,
        docsUrl,
        isBeta,
        isNew,
        imageUrl,
        familyName,
      } = api;

      const shouldLinkToDocs = shouldTSMLinkToExternalDocs(api);

      return {
        id,
        title: name,
        iconName: iconName ?? '',
        description,
        slogan: endpoint,
        groupByValue: familyName,
        url: shouldLinkToDocs ? docsUrl : playgroundUrl,
        isBeta,
        ctaButtonText: shouldLinkToDocs ? 'Docs' : 'Playground',
        ctaButtonIcon: shouldLinkToDocs ? 'OutLink' : 'ChevronRight',
        isNew,
        imageUrl,
      };
    });
  }
);

/**
 * Data for our new Jamba foundation models, used by the models catalogue page
 */
export const $foundationModels = createSelector(
  raw.$rawAppState,
  raw.$rawModels,
  raw.$rawUser,
  (appState, models, user): ICatalogueItem[] => {
    const playgroundUrl = '/playground';
    const isEmployee = isAI21User(user);

    return Object.values(models)
      .filter((item) => !item.forEmployees || isEmployee)
      .filter((model) => model.isFoundationModel)
      .filter((model) => !model.isDeprecated)
      .sort(sortBy('orderCatalogue'))
      .map((model) => {
        const {
          id,
          title,
          iconName,
          description,
          slogan,
          isInstruct,
          familyName,
          layoutId,
          forEmployees,
          isDeprecated,
          isBeta,
        } = model;

        const isBetaModelAvailable = isBetaAvailable(model, user);

        return {
          id,
          title: title,
          titleTag: isInstruct ? 'Instruct' : '',
          iconName: iconName ?? '',
          description,
          slogan,
          groupByValue: familyName,
          isBeta: model.isBeta,
          isBetaEnabled: isBetaModelAvailable,
          url: isBetaModelAvailable ? resolveUrlForJambaModel(model) : model.docsUrl,
          ctaButtonText: isBetaModelAvailable ? 'Playground' : 'Join the Waiting List',
          ctaButtonIcon: 'ChevronRight',
          forEmployees: forEmployees ?? false,
          isDeprecated: isDeprecated,
        } as ICatalogueItem;
      });
  }
);

/**
 * Data for deprecated foundation models, used by the models catalogue page
 */
export const $deprecatedModels = createSelector(
  raw.$rawAppState,
  raw.$rawModels,
  raw.$rawUser,
  (appState, models, user): ICatalogueItem[] => {
    const playgroundUrl = '/playground';
    const isEmployee = isAI21User(user);

    return Object.values(models)
      .filter((item) => !item.forEmployees || isEmployee)
      .filter((model) => model.isFoundationModel)
      .filter((model) => model.isDeprecated)
      .sort(sortBy('orderCatalogue'))
      .map((model) => {
        const {
          id,
          title,
          iconName,
          description,
          slogan,
          isInstruct,
          familyName,
          layoutId,
          forEmployees,
          isDeprecated,
        } = model;

        return {
          id,
          title: title.replace('[Instruct]', ''),
          titleTag: isInstruct ? 'Instruct' : '',
          iconName: iconName ?? '',
          description,
          slogan,
          groupByValue: familyName,
          url: resolveUrlForFoundationModel(model),
          ctaButtonText: 'Playground',
          ctaButtonIcon: 'ChevronRight',
          forEmployees: forEmployees ?? false,
          isDeprecated: isDeprecated,
        } as ICatalogueItem;
      });
  }
);

export const $categories = createSelector(
  raw.$rawPresets,
  raw.$rawApis,
  raw.$rawModels,
  (examples, apis, models) => {
    const examplesCount = Object.values(examples).filter((preset) => !preset.isUserPreset).length;
    const apisCount = Object.values(apis).length;
    const modelsCount = Object.values(models).filter((model) => model.isFoundationModel).length;

    return {
      examples: {
        id: 'examples',
        title: 'Examples',
        subtitle: `${examplesCount} Use-cases`,
        description:
          'Explore and test variety of real use-cases examples, presets in the studio’s playground.',
        iconName: 'overview.Example',
        href: '/examples',
      },
      apis: {
        id: 'apis',
        title: 'Task-specific Models',
        subtitle: `${apisCount} specialized models`,
        description:
          'Dedicated models that work out-of-the-box for specific reading and writing tasks.',
        iconName: 'overview.SpecializedApi',
        href: '/task-specific-models',
      },
      models: {
        id: 'models',
        title: 'Foundation Models',
        subtitle: `${modelsCount} base models`,
        description: 'General-purpose models that you can easily train and customize for any need.',
        iconName: 'overview.FoundationModel',
        href: '/foundation-models',
      },
    };
  }
);
