export function LongFormIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='6' fill='white' />
      <rect width='52' height='52' rx='6' fill='#D0C55C' fillOpacity='0.1' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1199 21.6667C16.1199 20.148 17.3511 18.9167 18.8699 18.9167H32.8699C34.3887 18.9167 35.6199 20.148 35.6199 21.6667V31.6667C35.6199 33.1855 34.3887 34.4167 32.8699 34.4167H18.8699C17.3511 34.4167 16.1199 33.1855 16.1199 31.6667V21.6667ZM18.8699 20.4167C18.1795 20.4167 17.6199 20.9764 17.6199 21.6667V31.6667C17.6199 32.3571 18.1795 32.9167 18.8699 32.9167H32.8699C33.5602 32.9167 34.1199 32.3571 34.1199 31.6667V21.6667C34.1199 20.9764 33.5602 20.4167 32.8699 20.4167H18.8699Z'
        fill='black'
      />
    </svg>
  );
}
