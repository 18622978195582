import { selectors } from '@ai21/studio-store';
import { Catalogue, prompt } from '@ai21/studio-ui';
import { select, takeEvery } from 'saga-ts';
import { navigate } from './saga.navigation';

export function* showExamples() {
  const cards = yield* select(selectors.catalogues.$examples);

  const { value, didCancel } = yield prompt.custom({
    component: Catalogue,
    componentProps: {
      cards,
    },
  });

  if (didCancel || !value || !value.url) {
    return;
  }

  navigate(value.url);
}

export function* showApis() {
  const cards = yield* select(selectors.catalogues.$apis);

  const { value, didCancel } = yield prompt.custom({
    component: Catalogue,
    componentProps: {
      cards,
      maxHeight: 750,
      hideFilter: true,
    },
  });

  if (didCancel || !value || !value.url) {
    return;
  }

  navigate(value.url);
}

export function* showModels() {
  const cards = yield* select(selectors.catalogues.$deprecatedModels);

  const sections = [
    {
      id: '1',
      title: 'Jurassic 2 Models Family',
      subtitle:
        'Trained on a massive text corpus, making them a powerful basis for text generation applications.',
      groupByValue: 'J2',
    },
  ];

  const { value, didCancel } = yield prompt.custom({
    component: Catalogue,
    componentProps: {
      cards,
      sections,
      hideFilter: true,
    },
  });

  if (didCancel || !value || !value.url) {
    return;
  }

  navigate(value.url);
}

export function* root() {
  yield takeEvery('SHOW_CATALOGUE_EXAMPLES', showExamples);
  yield takeEvery('SHOW_CATALOGUE_APIS', showApis);
  yield takeEvery('SHOW_CATALOGUE_MODELS', showModels);
}
