import { MultiFactorResolver } from '../../types';
import { ApiKeyEndpointResponse } from '../../data-types/ApiKey';
import { AuthenticationPageType } from '../../data-types/AuthenticationPageType';
import {
  OrganizationDetailsResponse,
  OrganizationUserDetailsListElement,
} from '../../data-types/Organization';
import {
  BillingInfoEndpointResponse,
  InvoiceInfo,
  PaymentMethodEndpointResponse,
} from '../../data-types/Payment';
import { SignInStage } from '../../data-types/SignInStage';
import {
  CreditInfoEndpointResponse,
  SpecializedModelUsageEndpointResponse,
  TrainModelsUsageEndpointListElement,
  UsageEndpointListElement,
} from '../../data-types/Usage';
import {
  DocsOpenedPayload,
  FeedbackActivatedPayload,
  FeedbackDeactivatedPayload,
  FeedbackDismissedPayload,
  FeedbackShownPayload,
  FeedbackSubmittedPayload,
  MassageDismissedPayload,
  MessageShownPayload,
  ModalDismissedPayload,
  ModalShownPayload,
  UserEndpointResponse,
} from '../../data-types/User';

export const FETCH_API_INFO_STARTED = 'FETCH_API_INFO_STARTED';
export const FETCH_API_INFO_SUCCESS = 'FETCH_API_INFO_SUCCESS';
export const FETCH_API_INFO_FAILED = 'FETCH_API_INFO_FAILED';
export const SIGN_IN_EMAIL_AUTHENTICATION_STARTED = 'SIGN_IN_EMAIL_AUTHENTICATION_STARTED';
export const SIGN_IN_EMAIL_AUTHENTICATION_SUCCESS = 'SIGN_IN_EMAIL_AUTHENTICATION_SUCCESS';
export const SIGN_IN_EMAIL_AUTHENTICATION_VERIFICATION_PENDING =
  'SIGN_IN_EMAIL_AUTHENTICATION_VERIFICATION_PENDING';
export const SIGN_IN_SEND_SMS_STARTED = 'SIGN_IN_SEND_SMS_STARTED';
export const SIGN_IN_SEND_SMS_SUCCESS = 'SIGN_IN_SEND_SMS_SUCCESS';
export const SIGN_IN_SEND_SMS_FAILED = 'SIGN_IN_SEND_SMS_FAILED';
export const SIGN_IN_SMS_CODE_VERIFICATION_STARTED = 'SIGN_IN_SMS_CODE_VERIFICATION_STARTED';
export const SIGN_IN_SMS_CODE_VERIFICATION_SUCCESS = 'SIGN_IN_SMS_CODE_VERIFICATION_SUCCESS';
export const SIGN_IN_SMS_CODE_VERIFICATION_FAILED = 'SIGN_IN_SMS_CODE_VERIFICATION_FAILED';
export const SIGN_IN_EMAIL_AUTHENTICATION_FAILED = 'SIGN_IN_EMAIL_AUTHENTICATION_FAILED';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const FIREBASE_EMAIL_VERIFIED = 'FIREBASE_EMAIL_VERIFIED';
export const AUTH_STATE_CHANGE = 'AUTH_STATE_CHANGE';
export const FETCH_USER_DETAILS_STARTED = 'FETCH_USER_DETAILS_STARTED';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_WITH_INVITE_SUCCESS = 'FETCH_USER_DETAILS_WITH_INVITE_SUCCESS';
export const FETCH_USER_DETAILS_RETRY_SUCCESS = 'FETCH_USER_DETAILS_RETRY_SUCCESS';
export const SET_USER = 'SET_USER';
export const FETCH_USER_DETAILS_RETRY_FAILURE = 'FETCH_USER_DETAILS_RETRY_FAILURE';
export const FETCH_USER_DETAILS_FAILURE = 'FETCH_USER_DETAILS_FAILURE';
export const FETCH_USAGE_INFO_STARTED = 'FETCH_USAGE_INFO_STARTED';
export const FETCH_SPECIALIZED_MODEL_USAGE_INFO_STARTED =
  'FETCH_SPECIALIZED_MODEL_USAGE_INFO_STARTED';
export const FETCH_CREDIT_INFO_STARTED = 'FETCH_CREDIT_INFO_STARTED';
export const FETCH_PAYMENT_INFO_STARTED = 'FETCH_PAYMENT_INFO_STARTED';
export const FETCH_BILLING_INFO_STARTED = 'FETCH_BILLING_INFO_STARTED';
export const FETCH_ORGANIZATION_DETAILS_STARTED = 'FETCH_ORGANIZATION_DETAILS_STARTED';
export const FETCH_ORGANIZATION_USERS_DETAILS_STARTED = 'FETCH_ORGANIZATION_USERS_DETAILS_STARTED';
export const SEND_INVITE_ORGANIZATION_STARTED = 'SEND_INVITE_ORGANIZATION';
export const CHANGE_USER_ROLE_STARTED = 'CHANGE_USER_ROLE_STARTED';
export const REMOVE_ORGANIZATION_USER_STARTED = 'REMOVE_ORGANIZATION_USER_STARTED';
export const EDIT_ORGANIZATION_NAME_STARTED = 'EDIT_ORGANIZATION_NAME_STARTED';
export const FETCH_USAGE_INFO_SUCCESS = 'FETCH_USAGE_INFO_SUCCESS';
export const FETCH_SPECIALIZED_MODEL_USAGE_INFO_SUCCESS =
  'FETCH_SPECIALIZED_MODEL_USAGE_INFO_SUCCESS';
export const FETCH_CREDIT_INFO_SUCCESS = 'FETCH_CREDIT_INFO_SUCCESS';
export const FETCH_PAYMENT_INFO_SUCCESS = 'FETCH_PAYMENT_INFO_SUCCESS';
export const FETCH_BILLING_INFO_SUCCESS = 'FETCH_BILLING_INFO_SUCCESS';
export const FETCH_ORGANIZATION_DETAILS_SUCCESS = 'FETCH_ORGANIZATION_DETAILS_SUCCESS';
export const FETCH_ORGANIZATION_USERS_DETAILS_SUCCESS = 'FETCH_ORGANIZATION_USERS_DETAILS_SUCCESS';
export const SEND_INVITE_ORGANIZATION_SUCCESS = 'SEND_INVITE_ORGANIZATION_SUCCESS';
export const CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';
export const REMOVE_ORGANIZATION_USER_SUCCESS = 'REMOVE_ORGANIZATION_USER_SUCCESS';
export const FETCH_USAGE_INFO_FAILURE = 'FETCH_USAGE_INFO_FAILURE';
export const FETCH_SPECIALIZED_MODEL_USAGE_INFO_FAILURE =
  'FETCH_SPECIALIZED_MODEL_USAGE_INFO_FAILURE';
export const FETCH_CREDIT_INFO_FAILURE = 'FETCH_CREDIT_INFO_FAILURE';
export const FETCH_PAYMENT_INFO_FAILURE = 'FETCH_PAYMENT_INFO_FAILURE';
export const FETCH_BILLING_INFO_FAILURE = 'FETCH_BILLING_INFO_FAILURE';
export const FETCH_ORGANIZATION_DETAILS_FAILURE = 'FETCH_ORGANIZATION_DETAILS_FAILURE';
export const FETCH_ORGANIZATION_USERS_DETAILS_FAILURE = 'FETCH_ORGANIZATION_USERS_DETAILS_FAILURE';
export const SEND_INVITE_ORGANIZATION_FAILURE = 'SEND_INVITE_ORGANIZATION_FAILURE';
export const EDIT_ORGANIZATION_NAME_FAILURE = 'EDIT_ORGANIZATION_NAME_FAILURE';
export const CHANGE_USER_ROLE_FAILURE = 'CHANGE_USER_ROLE_FAILURE';
export const REMOVE_ORGANIZATION_USER_FAILURE = 'REMOVE_ORGANIZATION_USER_FAILURE';
export const SIGN_OUT_STARTED = 'SIGN_OUT_STARTED';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILED = 'SIGN_OUT_FAILED';
export const FETCH_REFRESH_API_KEY_STARTED = 'FETCH_REFRESH_API_KEY_STARTED';
export const FETCH_REFRESH_API_KEY_SUCCESS = 'FETCH_REFRESH_API_KEY_SUCCESS';
export const FETCH_REFRESH_API_KEY_FAILURE = 'FETCH_REFRESH_API_KEY_FAILURE';
export const SIGN_IN_STAGE_SET = 'SIGN_IN_STAGE_SET';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const PASSWORD_RESET_STARTED = 'PASSWORD_RESET_STARTED';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED';
export const CANCEL_SUBSCRIPTION_STARTED = 'CANCEL_SUBSCRIPTION_STARTED';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILED = 'CANCEL_SUBSCRIPTION_FAILED';
export const CANCEL_SUBSCRIPTION_MODAL_OPENED = 'CANCEL_SUBSCRIPTION_MODAL_OPENED';
export const CANCEL_SUBSCRIPTION_CONFIRMED = 'CANCEL_SUBSCRIPTION_CONFIRMED';
export const FETCH_INVOICE_HISTORY_STARTED = 'FETCH_INVOICE_HISTORY_STARTED';
export const FETCH_INVOICE_HISTORY_SUCCESS = 'FETCH_INVOICE_HISTORY_SUCCESS';
export const FETCH_INVOICE_HISTORY_FAILURE = 'FETCH_INVOICE_HISTORY_FAILURE';
export const PAYMENT_PLAN_CLICKED = 'PAYMENT_PLAN_CLICKED';
export const PLAN_UPGRADE_CLICKED = 'PLAN_UPGRADE_CLICKED';
export const CLEAR_SNACKBAR_MESSAGE = 'CLEAR_SNACKBAR_MESSAGE';
export const ROTATE_API_KEY_STARTED = 'ROTATE_API_KEY_STARTED';
export const ROTATE_API_KEY_SUCCESS = 'ROTATE_API_KEY_SUCCESS';
export const ROTATE_API_KEY_FAILURE = 'ROTATE_API_KEY_FAILURE';
export const FETCH_TRAIN_MODELS_USAGE_STARTED = 'FETCH_TRAIN_MODELS_USAGE_STARTED';
export const FETCH_TRAIN_MODELS_USAGE_FAILURE = 'FETCH_TRAIN_MODELS_USAGE_FAILURE';
export const FETCH_TRAIN_MODELS_USAGE_SUCCESS = 'FETCH_TRAIN_MODELS_USAGE_SUCCESS';
export const JOINED_FROM_INVITE = 'JOINED_FROM_INVITE';
export const SIGN_IN_TYPE = 'SIGN_IN_TYPE';
export const FEEDBACK_SUBMIT_STARTED = 'FEEDBACK_SUBMIT_STARTED';
export const FEEDBACK_SUBMIT_SUCCESS = 'FEEDBACK_SUBMIT_SUCCESS';
export const FEEDBACK_SUBMIT_FAILURE = 'FEEDBACK_SUBMIT_FAILURE';
export const FEEDBACK_DISMISSED = 'FEEDBACK_DISMISSED';
export const FEEDBACK_DEACTIVATED = 'FEEDBACK_DEACTIVATED';
export const FEEDBACK_SHOWN = 'FEEDBACK_SHOWN';
export const MESSAGE_SHOWN = 'MESSAGE_SHOWN';
export const MESSAGE_DISMISSED = 'MESSAGE_DISMISSED';
export const MODAL_SHOWN = 'MODAL_SHOWN';
export const MODAL_DISMISSED = 'MODAL_DISMISSED';
export const FEEDBACK_ACTIVATED = 'FEEDBACK_ACTIVATED';
export const API_KEY_COPIED = 'API_KEY_COPIED';
export const UPGRADE_PLAN_CTA_PRESSED = 'UPGRADE_PLAN_CTA_PRESSED';
export const DOCS_OPENED = 'DOCS_OPENED';

export enum SignInMethod {
  GOOGLE,
  EMAIL_PW,
}

export interface ClearErrorMessage {
  type: typeof CLEAR_ERROR_MESSAGE;
}

export interface SignInEmailAuthenticationStarted {
  type: typeof SIGN_IN_EMAIL_AUTHENTICATION_STARTED;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod;
}

export interface SignInEmailAuthenticationSuccess {
  type: typeof SIGN_IN_EMAIL_AUTHENTICATION_SUCCESS;
  resolver: MultiFactorResolver | null;
  phoneNumber: string | null;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod;
}

export interface SignInEmailAuthenticationVerificationPending {
  type: typeof SIGN_IN_EMAIL_AUTHENTICATION_VERIFICATION_PENDING;
  email: string | null | undefined;
}

export interface SignInEmailAuthenticationFailed {
  type: typeof SIGN_IN_EMAIL_AUTHENTICATION_FAILED;
  errorCode: string;
  errorMessage: string;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod;
}

export interface UserSigninSuccess {
  type: typeof USER_SIGNIN_SUCCESS;
  signInType: AuthenticationPageType | null;
  signInMethod: SignInMethod | null;
}

export interface FirebaseEmailVerified {
  type: typeof FIREBASE_EMAIL_VERIFIED;
  signInType: AuthenticationPageType | null;
}

export interface SignInSendSmsStarted {
  type: typeof SIGN_IN_SEND_SMS_STARTED;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod | null;
}

export interface SignInSendSmsSuccess {
  type: typeof SIGN_IN_SEND_SMS_SUCCESS;
  resolver: MultiFactorResolver | null;
  phoneNumber: string;
  smsVerificationId: string;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod | null;
}

export interface SignInSendSmsFailed {
  type: typeof SIGN_IN_SEND_SMS_FAILED;
  errorCode: string;
  errorMessage: string;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod | null;
}

export interface SignInSmsCodeVerificationStarted {
  type: typeof SIGN_IN_SMS_CODE_VERIFICATION_STARTED;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod | null;
}

export interface SignInSmsCodeVerificationSuccess {
  type: typeof SIGN_IN_SMS_CODE_VERIFICATION_SUCCESS;
  token: string;
  email: string | null;
  newUser: boolean;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod | null;
}

export interface SignInSmsCodeVerificationFailed {
  type: typeof SIGN_IN_SMS_CODE_VERIFICATION_FAILED;
  errorCode: string;
  errorMessage: string;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod | null;
}

export interface AuthStateChange {
  type: typeof AUTH_STATE_CHANGE;
}

export interface SignOutStarted {
  type: typeof SIGN_OUT_STARTED;
}

export interface SignOutSuccess {
  type: typeof SIGN_OUT_SUCCESS;
}

export interface SignOutFailed {
  type: typeof SIGN_OUT_FAILED;
}

export interface FetchUserDetailsStarted {
  type: typeof FETCH_USER_DETAILS_STARTED;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod | null;
}

export interface FetchUserDetailsSuccess {
  type: typeof FETCH_USER_DETAILS_SUCCESS;
  payload: UserEndpointResponse;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod | null;
  gclid: string | null;
  gbraid: string | null;
  wbraid: string | null;
}

export interface FetchUserDetailsRetrySuccess {
  type: typeof FETCH_USER_DETAILS_RETRY_SUCCESS;
  payload: UserEndpointResponse;
}
export interface SetUser {
  type: typeof SET_USER;
  payload: UserEndpointResponse;
}

export interface FetchUserDetailsRetryFailure {
  type: typeof FETCH_USER_DETAILS_RETRY_FAILURE;
  error: string | null;
}

export interface FetchUserDetailsFailed {
  type: typeof FETCH_USER_DETAILS_FAILURE;
  error: string;
  signInType: AuthenticationPageType;
  signInMethod: SignInMethod | null;
}

export interface FetchUserDetailsWithInviteSuccess {
  type: typeof FETCH_USER_DETAILS_WITH_INVITE_SUCCESS;
  payload: { newMemberUserId: string; organizationId: string };
}

export interface FetchUsageInfoStarted {
  type: typeof FETCH_USAGE_INFO_STARTED;
}

export interface FetchSpecializedModelUsageInfoStarted {
  type: typeof FETCH_SPECIALIZED_MODEL_USAGE_INFO_STARTED;
}

export interface FetchCreditInfoStarted {
  type: typeof FETCH_CREDIT_INFO_STARTED;
}

export interface FetchBillingInfoStarted {
  type: typeof FETCH_BILLING_INFO_STARTED;
}

export interface FetchPaymentInfoStarted {
  type: typeof FETCH_PAYMENT_INFO_STARTED;
}

export interface FetchOrganizationDetailsStarted {
  type: typeof FETCH_ORGANIZATION_DETAILS_STARTED;
}

export interface FetchOrganizationUsersDetailsStarted {
  type: typeof FETCH_ORGANIZATION_USERS_DETAILS_STARTED;
}

export interface SendInviteOrganizationStarted {
  type: typeof SEND_INVITE_ORGANIZATION_STARTED;
  payload: { origin: string };
}

export interface ChangeUserRoleStarted {
  type: typeof CHANGE_USER_ROLE_STARTED;
}

export interface RemoveOrganizationUserStarted {
  type: typeof REMOVE_ORGANIZATION_USER_STARTED;
}

export interface EditOrganizationNameStarted {
  type: typeof EDIT_ORGANIZATION_NAME_STARTED;
}

export interface FetchUsageInfoSuccess {
  type: typeof FETCH_USAGE_INFO_SUCCESS;
  payload: UsageEndpointListElement[];
}

export interface FetchSpecializedModelUsageInfoSuccess {
  type: typeof FETCH_SPECIALIZED_MODEL_USAGE_INFO_SUCCESS;
  payload: SpecializedModelUsageEndpointResponse[];
}

export interface FetchCreditInfoSuccess {
  type: typeof FETCH_CREDIT_INFO_SUCCESS;
  payload: CreditInfoEndpointResponse;
}

export interface FetchPaymentInfoSuccess {
  type: typeof FETCH_PAYMENT_INFO_SUCCESS;
  payload: PaymentMethodEndpointResponse;
}

export interface FetchBillingInfoSuccess {
  type: typeof FETCH_BILLING_INFO_SUCCESS;
  payload: BillingInfoEndpointResponse;
}

export interface FetchOrganizationDetailsSuccess {
  type: typeof FETCH_ORGANIZATION_DETAILS_SUCCESS;
  payload: OrganizationDetailsResponse;
}

export interface SendInviteOrganizationSuccess {
  type: typeof SEND_INVITE_ORGANIZATION_SUCCESS;
  payload: { method: string; origin: string };
}

export interface FetchOrganizationUsersDetailsSuccess {
  type: typeof FETCH_ORGANIZATION_USERS_DETAILS_SUCCESS;
  payload: OrganizationUserDetailsListElement[];
}

export interface ChangeUserRoleSuccess {
  type: typeof CHANGE_USER_ROLE_SUCCESS;
}

export interface RemoveOrganizationUserSuccess {
  type: typeof REMOVE_ORGANIZATION_USER_SUCCESS;
}

export interface FetchUsageInfoFailed {
  type: typeof FETCH_USAGE_INFO_FAILURE;
  error: Error;
}

export interface FetchSpecializedModelUsageInfoFailed {
  type: typeof FETCH_SPECIALIZED_MODEL_USAGE_INFO_FAILURE;
  error: Error;
}

export interface FetchCreditInfoFailed {
  type: typeof FETCH_CREDIT_INFO_FAILURE;
  error: Error;
}

export interface FetchPaymentInfoFailed {
  type: typeof FETCH_PAYMENT_INFO_FAILURE;
  error: string;
}

export interface FetchBillingInfoFailed {
  type: typeof FETCH_BILLING_INFO_FAILURE;
  error: string;
}

export interface FetchOrganizationDetailsFailed {
  type: typeof FETCH_ORGANIZATION_DETAILS_FAILURE;
  error: string;
}

export interface FetchOrganizationUsersDetailsFailed {
  type: typeof FETCH_ORGANIZATION_USERS_DETAILS_FAILURE;
  error: string;
}

export interface SendInviteOrganizationFailure {
  type: typeof SEND_INVITE_ORGANIZATION_FAILURE;
  error: string;
}

export interface EditOrganizationNameFailure {
  type: typeof EDIT_ORGANIZATION_NAME_FAILURE;
  error: string;
}

export interface ChangeUserRoleFailure {
  type: typeof CHANGE_USER_ROLE_FAILURE;
  error: string;
}

export interface RemoveOrganizationUserFailure {
  type: typeof REMOVE_ORGANIZATION_USER_FAILURE;
  error: string;
}

export interface FetchApiInfoStarted {
  type: typeof FETCH_API_INFO_STARTED;
}

export interface FetchApiInfoSuccess {
  type: typeof FETCH_API_INFO_SUCCESS;
  payload: ApiKeyEndpointResponse;
}

export interface FetchApiInfoFailed {
  type: typeof FETCH_API_INFO_FAILED;
}

export interface FetchRefreshApiKeyStarted {
  type: typeof FETCH_REFRESH_API_KEY_STARTED;
}

export interface FetchRefreshApiKeySuccess {
  type: typeof FETCH_REFRESH_API_KEY_SUCCESS;
  payload: UserEndpointResponse;
}

export interface FetchRefreshApiKeyFailed {
  type: typeof FETCH_REFRESH_API_KEY_FAILURE;
  error: string;
}

export interface SignInSetStage {
  type: typeof SIGN_IN_STAGE_SET;
  stage: SignInStage;
}

export interface PasswordResetStarted {
  type: typeof PASSWORD_RESET_STARTED;
}

export interface PasswordResetSuccess {
  type: typeof PASSWORD_RESET_SUCCESS;
  email: string;
}

export interface PasswordResetFailed {
  type: typeof PASSWORD_RESET_FAILED;
  errorMessage: string;
  errorCode: string;
}

export interface CancelSubscriptionStarted {
  type: typeof CANCEL_SUBSCRIPTION_STARTED;
}

export interface CancelSubscriptionSuccess {
  type: typeof CANCEL_SUBSCRIPTION_SUCCESS;
}

export interface CancelSubscriptionFailed {
  type: typeof CANCEL_SUBSCRIPTION_FAILED;
  errorMessage: string;
}

export interface FetchInvoiceHistoryStarted {
  type: typeof FETCH_INVOICE_HISTORY_STARTED;
}

export interface FetchInvoiceHistorySuccess {
  type: typeof FETCH_INVOICE_HISTORY_SUCCESS;
  invoiceHistory: InvoiceInfo[];
}

export interface FetchInvoiceHistoryFailed {
  type: typeof FETCH_INVOICE_HISTORY_FAILURE;
  error: string;
}

export interface CancelSubscriptionModalOpened {
  type: typeof CANCEL_SUBSCRIPTION_MODAL_OPENED;
  callToActionText: string;
  path: string;
}

export interface CancelSubscriptionConfirmed {
  type: typeof CANCEL_SUBSCRIPTION_CONFIRMED;
  callToActionText: string;
}

export interface PaymentPlanClicked {
  type: typeof PAYMENT_PLAN_CLICKED;
  callToActionText: string;
  plan: string;
  path: string;
  displayedPrice: number;
  displayedDescription: string | null;
}

export interface PlanUpgradeClicked {
  type: typeof PLAN_UPGRADE_CLICKED;
  path: string;
  callToActionText: string;
  origin: string;
}

export interface ClearSnackbarMessage {
  type: typeof CLEAR_SNACKBAR_MESSAGE;
}

export interface RotateApiKeyStarted {
  type: typeof ROTATE_API_KEY_STARTED;
}

export interface RotateApiKeySuccess {
  type: typeof ROTATE_API_KEY_SUCCESS;
}

export interface RotateApiKeyFailure {
  type: typeof ROTATE_API_KEY_FAILURE;
  error: string;
}

export interface FetchTrainModelsUsageStarted {
  type: typeof FETCH_TRAIN_MODELS_USAGE_STARTED;
}

export interface FetchTrainModelsUsageSuccess {
  type: typeof FETCH_TRAIN_MODELS_USAGE_SUCCESS;
  payload: TrainModelsUsageEndpointListElement[];
}

export interface FeedbackSubmitSuccess {
  type: typeof FEEDBACK_SUBMIT_SUCCESS;
  payload: FeedbackSubmittedPayload;
}
export interface FeedbackSubmitStarted {
  type: typeof FEEDBACK_SUBMIT_STARTED;
}
export interface FeedbackSubmitFailure {
  type: typeof FEEDBACK_SUBMIT_FAILURE;
  payload: FeedbackSubmittedPayload;
  errorMessage: string;
}
export interface FeedbackDismissed {
  type: typeof FEEDBACK_DISMISSED;
  payload: FeedbackDismissedPayload;
}
export interface FeedbackDeactivated {
  type: typeof FEEDBACK_DEACTIVATED;
  payload: FeedbackDeactivatedPayload;
}
export interface FeedbackActivated {
  type: typeof FEEDBACK_ACTIVATED;
  payload: FeedbackActivatedPayload;
}

export interface FeedbackShown {
  type: typeof FEEDBACK_SHOWN;
  payload: FeedbackShownPayload;
}

export interface MessageShown {
  type: typeof MESSAGE_SHOWN;
  payload: MessageShownPayload;
}
export interface DocsOpened {
  type: typeof DOCS_OPENED;
  payload: DocsOpenedPayload;
}

export interface MassageDismissed {
  type: typeof MESSAGE_DISMISSED;
  payload: MassageDismissedPayload;
}

export interface ModalShown {
  type: typeof MODAL_SHOWN;
  payload: ModalShownPayload;
}

export interface ModalDismissed {
  type: typeof MODAL_DISMISSED;
  payload: ModalDismissedPayload;
}
export interface APIKeyCopied {
  type: typeof API_KEY_COPIED;
  origin: string;
}
export interface UpgradePlanCTAPressed {
  type: typeof UPGRADE_PLAN_CTA_PRESSED;
  origin: string;
}

export interface FetchTrainModelsUsageFailed {
  type: typeof FETCH_TRAIN_MODELS_USAGE_FAILURE;
  error: string;
}

export interface JoinedFromInvite {
  type: typeof JOINED_FROM_INVITE;
  fromInvite: boolean;
}

export interface SignInType {
  type: typeof SIGN_IN_TYPE;
  signInType: AuthenticationPageType;
}

export type UserActionTypes =
  | SignInEmailAuthenticationStarted
  | SignInEmailAuthenticationSuccess
  | SignInEmailAuthenticationVerificationPending
  | SignInSendSmsStarted
  | SignInSendSmsSuccess
  | SignInSendSmsFailed
  | SignInSmsCodeVerificationStarted
  | SignInSmsCodeVerificationSuccess
  | SignInSmsCodeVerificationFailed
  | SignInEmailAuthenticationFailed
  | UserSigninSuccess
  | FirebaseEmailVerified
  | AuthStateChange
  | FetchUserDetailsStarted
  | FetchUserDetailsSuccess
  | FetchUserDetailsWithInviteSuccess
  | FetchUserDetailsRetrySuccess
  | FetchUserDetailsRetryFailure
  | FetchUserDetailsFailed
  | FetchApiInfoStarted
  | FetchApiInfoSuccess
  | FetchApiInfoFailed
  | SignOutStarted
  | SignOutSuccess
  | SignOutFailed
  | FetchUsageInfoStarted
  | FetchBillingInfoStarted
  | FetchPaymentInfoStarted
  | FetchUsageInfoSuccess
  | FetchPaymentInfoSuccess
  | FetchBillingInfoSuccess
  | FetchUsageInfoFailed
  | FetchPaymentInfoFailed
  | FetchBillingInfoFailed
  | FetchRefreshApiKeyStarted
  | FetchRefreshApiKeyFailed
  | FetchRefreshApiKeySuccess
  | SignInSetStage
  | ClearErrorMessage
  | PasswordResetStarted
  | PasswordResetSuccess
  | PasswordResetFailed
  | CancelSubscriptionStarted
  | CancelSubscriptionSuccess
  | CancelSubscriptionFailed
  | FetchInvoiceHistoryStarted
  | FetchInvoiceHistorySuccess
  | FetchInvoiceHistoryFailed
  | FetchOrganizationDetailsStarted
  | FetchOrganizationDetailsSuccess
  | FetchOrganizationDetailsFailed
  | FetchOrganizationUsersDetailsStarted
  | FetchOrganizationUsersDetailsSuccess
  | FetchOrganizationUsersDetailsFailed
  | SendInviteOrganizationStarted
  | SendInviteOrganizationSuccess
  | SendInviteOrganizationFailure
  | RemoveOrganizationUserStarted
  | RemoveOrganizationUserSuccess
  | RemoveOrganizationUserFailure
  | EditOrganizationNameStarted
  | EditOrganizationNameFailure
  | ChangeUserRoleStarted
  | ChangeUserRoleSuccess
  | ChangeUserRoleFailure
  | CancelSubscriptionModalOpened
  | CancelSubscriptionConfirmed
  | PaymentPlanClicked
  | PlanUpgradeClicked
  | ClearSnackbarMessage
  | RotateApiKeyStarted
  | RotateApiKeySuccess
  | RotateApiKeyFailure
  | FetchTrainModelsUsageStarted
  | FetchTrainModelsUsageSuccess
  | FetchTrainModelsUsageFailed
  | SignInType
  | JoinedFromInvite
  | FetchCreditInfoStarted
  | FetchCreditInfoSuccess
  | FetchCreditInfoFailed
  | FetchSpecializedModelUsageInfoStarted
  | FetchSpecializedModelUsageInfoSuccess
  | FetchSpecializedModelUsageInfoFailed
  | FeedbackSubmitSuccess
  | FeedbackSubmitStarted
  | FeedbackSubmitFailure
  | FeedbackDismissed
  | FeedbackDeactivated
  | FeedbackShown
  | MessageShown
  | MassageDismissed
  | ModalShown
  | ModalDismissed
  | FeedbackActivated
  | APIKeyCopied
  | UpgradePlanCTAPressed
  | DocsOpened
  | SetUser;
