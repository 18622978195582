export function J1Icon() {
  return (
    <svg width='53' height='52' viewBox='0 0 53 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' width='52' height='52' rx='6' fill='#231044' />
      <path
        d='M16.9144 39.1124C21.2397 39.1124 22.8196 36.3152 22.8196 33.0259V15.1549H18.7533V33.0259C18.7533 34.2691 18.2353 35.2792 16.9144 35.2792C16.7849 35.2792 15.3345 35.2792 14.5316 34.9943L14.169 38.6721C15.1791 38.9829 16.4223 39.1124 16.9144 39.1124ZM33.3962 15.7765L25.8593 18.8586V22.381L29.9256 20.8529V33H33.7329V15.7765H33.3962Z'
        fill='white'
      />
    </svg>
  );
}
