interface AlertWarningIconProps {
  width?: number;
  height?: number;
}

export function AlertWarningIcon({ width = 24, height = 24 }: AlertWarningIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 10v3m0 2.5h.009M5.794 19h12.412c1.38 0 2.24-1.459 1.551-2.625l-6.206-10.5c-.69-1.167-2.413-1.167-3.102 0l-6.206 10.5C3.553 17.541 4.415 19 5.794 19z'
        stroke='#D58F05'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
