import { Icon, Tip } from '@ai21/studio-base-ui';
import React, { useState } from 'react';
import { IFormGroup } from '../../forms.types';
import { Chevron, Content, Header, Label, Wrapper } from './Group.style';
import classnames from 'classnames';

export type GroupProps = {
  group: IFormGroup;
  children: React.ReactNode;
};

export function Group(props: GroupProps) {
  const { group } = props;
  const { tooltip, title, isCollapsible } = group;
  const [isCollapsed, setIsCollapsed] = useState(true);

  function renderHeader() {
    if (!isCollapsible) {
      return null;
    }

    const className = classnames('chevron', {
      open: !isCollapsed,
    });

    return (
      <Header onClick={() => setIsCollapsed(!isCollapsed)}>
        <Label variant='subtitle1bold'>
          {title}
          <Tip value={tooltip} />
        </Label>
        <Chevron className={className}>
          <Icon iconName='ChevronRight' />
        </Chevron>
      </Header>
    );
  }

  function renderContent() {
    if (isCollapsible && isCollapsed) {
      return null;
    }

    return <Content>{props.children}</Content>;
  }

  return (
    <Wrapper className='Group-wrapper' data-testid='Group-wrapper'>
      {renderHeader()}
      {renderContent()}
    </Wrapper>
  );
}

export default Group;
