import { actions, selectors } from '@ai21/studio-store';
import { prompt } from '@ai21/studio-ui';
import { delay, takeEvery, select, put, take } from 'saga-ts';
import { Json } from '../types';

type Verb = 'new' | 'source' | 'start' | 'select' | 'delete' | 'startHere';

type ActionJob = {
  type: 'EVALUATION_ITEM';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  new: newJob,
  source: selectSource,
  start: startJob,
  select: startHere,
  startHere: startHere,
  delete: deleteJob,
};

function* newJob(action: ActionJob) {
  console.log('newJob', action);
}

function* selectSource(action: ActionJob) {
  console.log('selectSource', action);
}

function* startJob(action: ActionJob) {
  const lines = yield* select(selectors.evaluation.$evaluationLines);

  const firstLine = lines[0];
  if (!firstLine) {
    return;
  }

  yield put(
    actions.currentIds.patch({
      evaluationLineId: firstLine.id,
    })
  );
}

function* startHere(action: ActionJob) {
  const { id } = action;

  yield put(
    actions.currentIds.patch({
      evaluationLineId: id,
    })
  );
}

function* deleteJob(action: ActionJob) {
  const { id } = action;
  const model = yield* select(selectors.singles.$model, id);
  const { name } = model;

  const { didCancel } = yield prompt.confirm({
    title: 'Delete item',
    description: `Are you sure you want to delete this item "${name}"?`,
    ctaButtonText: 'Delete Item',
    intention: 'delete',
  });

  if (didCancel) {
    return;
  }

  yield put(actions.models.delete(id));
}

export function* evaluationItem(action: ActionJob) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('EVALUATION_ITEM', evaluationItem);
}
