interface ClearIconProps {
  width?: number;
  height?: number;
}

export function ClearIcon({ width = 16, height = 16 }: ClearIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m12.375 4.506-.881-.881L8 7.119 4.506 3.625l-.881.881L7.119 8l-3.494 3.494.881.881L8 8.881l3.494 3.494.881-.881L8.881 8l3.494-3.494z'
        fill='#D6D8DE'
      />
    </svg>
  );
}
