interface DbTableIconProps {
  width?: number;
  height?: number;
}

export function DbTableIcon({ width = 24, height = 24 }: DbTableIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 57 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#l67hqi3l7a)'>
        <path
          d='M28.985 33.02 9.917 42.663l18.032 8.16 19.903-9.271-18.867-8.532zM28.402 6.621 9.954 15.953 27.4 23.844l19.252-8.97-18.25-8.253z'
          fill='#000'
          stroke='#000'
          strokeWidth='.942'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='m30.454 12.587.645 1.742a7.424 7.424 0 0 0-5.824-4.708 8.116 8.116 0 0 0-1.11-.1 7.881 7.881 0 0 0-7.897 6.268 7.325 7.325 0 0 0 .316 3.917l-.645-1.742a7.311 7.311 0 0 1-.316-3.913 7.882 7.882 0 0 1 7.896-6.267 7.566 7.566 0 0 1 6.935 4.812v-.01z'
          fill='#000'
        />
        <path
          d='M17.892 21.91a7.255 7.255 0 0 1-1.308-2.213l-.645-1.742c.299.81.742 1.56 1.308 2.213l.071.193.07.198.08.212.08.212.076.211.08.212.075.198.071.193v.085l.042.028zM31.537 17.15c-.016.366-.06.73-.131 1.088l-.645-1.742a7.274 7.274 0 0 0-.306-3.908l.645 1.742c.331.902.48 1.86.437 2.82z'
          fill='#fff'
        />
        <path
          d='M17.892 21.91a.55.55 0 0 1-.042-.113c-.202-.542-.405-1.088-.603-1.63a7.477 7.477 0 0 0 4.506 2.496c.37.061.742.098 1.116.108a7.881 7.881 0 0 0 7.891-6.276l.645 1.742a7.877 7.877 0 0 1-7.89 6.272 7.712 7.712 0 0 1-5.623-2.6z'
          fill='#fff'
        />
        <path
          d='M23.519 7.776c.372.005.744.038 1.111.098a7.34 7.34 0 0 1 6.262 7.534c-.016.364-.06.726-.132 1.083a7.882 7.882 0 0 1-7.89 6.276 8.232 8.232 0 0 1-1.117-.108 7.327 7.327 0 0 1-6.257-7.534c.015-.365.057-.729.127-1.087a7.882 7.882 0 0 1 7.896-6.262z'
          fill='#fff'
        />
        <path
          d='M24.804 6.975a8.225 8.225 0 0 1 7.025 8.475 8.382 8.382 0 0 1-.146 1.215 8.843 8.843 0 0 1-8.856 7.034 8.652 8.652 0 0 1-1.248-.113 8.23 8.23 0 0 1-7.025-8.475c.02-.41.071-.817.151-1.22a8.838 8.838 0 0 1 10.1-6.916zm5.956 9.52c.072-.357.116-.719.132-1.083A7.34 7.34 0 0 0 24.63 7.88a7.435 7.435 0 0 0-1.111-.099 7.882 7.882 0 0 0-7.896 6.267c-.07.359-.112.722-.127 1.087a7.326 7.326 0 0 0 6.257 7.534c.37.061.742.097 1.116.108A7.882 7.882 0 0 0 30.76 16.5'
          fill='#000'
        />
        <path
          d='m31.335 12.258.645 1.737a8.136 8.136 0 0 1 .348 4.393 8.847 8.847 0 0 1-10.104 6.917 8.315 8.315 0 0 1-6.53-5.288l-.645-1.737a8.32 8.32 0 0 0 6.53 5.287c.413.068.83.106 1.248.113a8.842 8.842 0 0 0 8.856-7.034 8.136 8.136 0 0 0-.348-4.388z'
          fill='#000'
        />
        <path d='m44.302 14.796-.41 1.52-.447.048.41-1.516.447-.052z' fill='#fff' />
        <path
          d='m44.024 17.611-.415 1.52c.072-.266.118-.538.137-.814a4.708 4.708 0 0 0-.301-1.954c-.942-2.462-3.668-4.34-6.7-4.811a9.218 9.218 0 0 0-2.496-.057c-2.707.306-4.6 1.794-5.122 3.72l.414-1.521c.518-1.921 2.41-3.41 5.118-3.715a9.256 9.256 0 0 1 2.5.052c3.032.47 5.777 2.354 6.695 4.816.24.623.342 1.289.302 1.954a4.238 4.238 0 0 1-.132.81z'
          fill='#000'
        />
        <path
          d='M28.99 16.025c.019-.274.065-.545.137-.81l.414-1.52c-.125.459-.172.937-.137 1.412l-.414 1.474a5.473 5.473 0 0 1 0-.556z'
          fill='#fff'
        />
        <path
          d='M29.291 17.993a5.117 5.117 0 0 1-.3-1.38l.05-.174.048-.188.056-.198.052-.197.052-.198.057-.198.047-.183.051-.18c.033.473.135.938.302 1.38a6.08 6.08 0 0 0 1.007 1.724l-.41 1.52a6.074 6.074 0 0 1-1.012-1.728z'
          fill='#fff'
        />
        <path
          d='M35.992 22.79a9.29 9.29 0 0 1-5.688-3.084l.047-.179.052-.188.052-.193.051-.203.057-.197.052-.193.051-.189.047-.179a9.285 9.285 0 0 0 5.693 3.103c.825.13 1.665.149 2.495.057 2.707-.306 4.6-1.794 5.123-3.72l-.415 1.52c-.517 1.922-2.415 3.41-5.117 3.716a9.07 9.07 0 0 1-2.5-.07z'
          fill='#fff'
        />
        <path
          d='M44.763 14.744c.27.701.388 1.453.344 2.203-.175 2.6-2.463 4.709-5.919 5.1-.94.101-1.89.079-2.825-.067-3.413-.532-6.502-2.641-7.533-5.419a5.26 5.26 0 0 1-.348-2.199c.178-2.599 2.462-4.708 5.918-5.099.94-.106 1.89-.084 2.825.066 3.39.532 6.479 2.641 7.538 5.415zm-5.843 6.591c3.075-.348 5.1-2.222 5.255-4.53a4.665 4.665 0 0 0-.302-1.953c-.918-2.467-3.663-4.341-6.695-4.817a9.258 9.258 0 0 0-2.5-.051c-3.075.348-5.1 2.217-5.255 4.524-.04.667.063 1.335.302 1.959.913 2.467 3.644 4.35 6.68 4.821.826.13 1.666.149 2.496.057'
          fill='#000'
        />
        <path
          d='M37.16 10.03c3.031.472 5.776 2.355 6.694 4.817l.471-.051-.47.051c.238.623.34 1.289.3 1.954-.155 2.307-2.18 4.181-5.254 4.53a9.42 9.42 0 0 1-2.495-.057c-3.037-.47-5.782-2.354-6.7-4.812a4.708 4.708 0 0 1-.302-1.958c.156-2.307 2.18-4.177 5.255-4.525a9.256 9.256 0 0 1 2.5.052z'
          fill='#fff'
        />
        <path
          d='m44.932 17.856-.41 1.521c-.588 2.166-2.72 3.837-5.767 4.186-.94.106-1.89.084-2.825-.066-3.413-.532-6.497-2.642-7.533-5.42-.27-.7-.388-1.45-.344-2.198.02-.31.07-.615.15-.914l.415-1.516c-.08.3-.132.605-.155.914-.042.749.076 1.499.348 2.198 1.036 2.778 4.125 4.888 7.533 5.42.935.145 1.885.167 2.825.066 3.042-.349 5.18-2.02 5.764-4.19z'
          fill='#000'
        />
        <path
          d='m46.651 14.875-19.252 8.97v22.067l19.153-8.88.099-22.157z'
          fill='#D53864'
          stroke='#000'
          strokeWidth='.942'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.954 15.953v21.884L27.4 45.912V23.844l-17.445-7.89z'
          fill='#fff'
          stroke='#000'
          strokeWidth='.942'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='m22.083 26.391-8.004-3.62'
          stroke='#000'
          strokeWidth='.942'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='l67hqi3l7a'>
          <path fill='#fff' transform='translate(9.446 6.15)' d='M0 0h38.877v45.144H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
