import { Divider } from '@ai21/studio-base-ui';
import { ILine, Verb } from '../../InboxEditor.types';
import { IOEditor } from '../IOEditor/IOEditor';
import { Container, Wrapper } from './Comment.style';

export type CommentProps = {
  line?: ILine;
  onChange: (comment: string, lineId: string) => void;
  onAction: (verb: Verb, params?: Json) => void;
  disabled?: boolean;
};

export function Comment(props: CommentProps) {
  const { line, disabled } = props;

  return (
    <Wrapper className='Comment-wrapper' data-testid='Comment-wrapper'>
      <Container>
        <Divider />
      </Container>
      <IOEditor
        id={line?.id}
        content={line?.comment}
        onChange={props.onChange}
        onAction={props.onAction}
        flex={2}
        title='Comment'
        iconName='MessageDots'
        copyTestId='CopyCompletion'
        withBorder
        showHide
        disabled={disabled}
      />
    </Wrapper>
  );
}

export default Comment;
