import { Box, Grid, Typography, icons } from '@ai21/studio-ui';
import React from 'react';
import { ErrorMessageBox } from '../datasets.ErrorMessageBox/ErrorMessageBox';

export interface DatasetValidationProps {
  datasetName: string | undefined;
  errorMessage: string;
}

export function DatasetValidationFatalError({ datasetName, errorMessage }: DatasetValidationProps) {
  return (
    <Grid container alignItems='center' justifyContent='center' direction='column'>
      <icons.UploadingDataset />
      <Box marginTop={2} />
      <Typography variant='subtitle1'>Dataset {datasetName}</Typography>
      <Box marginTop={2} />
      <Typography variant='body1'>
        Oops, we can’t process the dataset due to the problems below. Please fix the file and upload
        again.
      </Typography>
      <Box marginTop={2} />
      <ErrorMessageBox errorMessage={errorMessage} />
      <Box style={{ marginTop: 40 }} />
    </Grid>
  );
}
