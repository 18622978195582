import { Typography } from '@ai21/studio-ui';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  width: 700px;
`;

export const Header = styled(Typography)``;

export const Details = styled(Typography)``;

export const Content = styled.div``;

export const Title = styled(Typography)`
  font-weight: 600;
`;

export const Subtitle = styled(Typography)``;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }
`;
