import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { statusMap } from './Status.data';
import {
  IconWrapper,
  NoTooltip,
  Progress,
  StatusText,
  Wrapper,
} from './Status.style';
import Icon from '../Icon/Icon';

export type StatusProps = {
  value: string;
  total?: string;
  completed?: string;
  tooltip?: string;
  link?: string;
  progress?: number;
  onClick?: (link: string) => void;
  shouldSpin?: boolean;
};

export function Status(props: StatusProps) {
  const {
    value,
    total,
    completed,
    tooltip,
    link,
    progress,
    shouldSpin = true,
  } = props;

  function onClick() {
    if (!link || !props.onClick) {
      return;
    }

    props.onClick(link);
  }

  const info = statusMap[value];

  if (!info) {
    return null;
  }

  let { text, color, isSpinning, icon } = info;
  const clickable = !isEmpty(link);

  const CellWrapper = tooltip ? Tooltip : NoTooltip;

  if (typeof progress === 'number' && progress >= 0 && progress <= 100) {
    text += ` (${progress}%)`;
  }

  function renderProgress() {
    if (!total || !completed) {
      return null;
    }

    return (
      <Progress>
        ({completed}/{total})
      </Progress>
    );
  }

  const className = classNames(value.toLowerCase(), color, {
    clickable,
  });

  return (
    <CellWrapper title={tooltip}>
      <Wrapper className={className} onClick={onClick} data-testid='set-status'>
        <IconWrapper
          className='icon-wrapper'
          spinning={!shouldSpin ? false : isSpinning}
        >
          <Icon iconName={icon} />
        </IconWrapper>
        <StatusText clickable={clickable}>{text}</StatusText>
        {renderProgress()}
      </Wrapper>
    </CellWrapper>
  );
}

export default Status;
