import { styled } from '@ai21/studio-base-ui';
import { createContext, useEffect, useMemo } from 'react';
import { useSetState } from 'react-use';
import { invokeEvent } from 'shared-base';
import { useTableData } from '../hooks/useTableData';
import { ISorts, ITableContext, ITableState, TableProviderProps } from '../types';
import { emptyConfig, initialState } from './Table.context.empty';
import { calculateFeatures } from './Table.context.utils';

export const TableContext = createContext<ITableContext>({
  state: {
    ...initialState,
  },
  features: {
    withSort: true,
    withPagination: true,
    withSearch: false,
    withNew: true,
  },
  config: emptyConfig,
  data: [],
  count: {
    all: 0,
    filtered: 0,
  },
  callbacks: {
    onSetActiveRowIndex: (_id?: number) => {},
    onSort: (_sorts: ISorts) => {},
    onSearch: (_q?: string) => {},
    onPageChange: (_pageIndex: number) => {},
    onExportData: () => {},
  },
  patchState: () => {},
  isMobile: false,
});

export const TableContextProvider = (props: TableProviderProps) => {
  const { config, data: inData } = props;
  const [state, patchState] = useSetState<ITableState>(initialState);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (config.sort) {
      patchState({
        sortFieldId: config.sort.defaultField,
        sortDirection: config.sort.defaultDirection ?? 'asc',
      });
    }
  }, [config]);

  const features = useMemo(() => {
    return calculateFeatures(config.features);
  }, [config]);

  const [parsedData, count, dataCallbacks] = useTableData(inData, state, features);

  const callbacks = useMemo(
    () => ({
      onSetActiveRowIndex: (id?: number) => {
        patchState({ activeRowIndex: id });
      },
      onSort: (sorts: ISorts = []) => {
        if (sorts.length === 0) {
          return;
        }

        const sortFieldId = sorts[0].field;
        const sortDirection = sorts[0].sort as any;

        patchState({
          sortFieldId: sortFieldId,
          sortDirection,
        });

        invokeEvent('global/table', {
          entity: config.id,
          scope: 'table',
          action: 'sort',
          fieldId: sortFieldId,
          direction: sortDirection,
        });
      },
      onSearch: (q?: string) => {
        const isFiltered = (q ?? '').length > 0;
        patchState({ q: q, isFiltered });
      },
      onPageChange: (pageIndex: number) => {
        patchState({ pageIndex: pageIndex });

        const direction = state.pageIndex ?? 0 < pageIndex ? 'next' : 'previous';

        invokeEvent('global/table', {
          entity: config.id,
          scope: 'table',
          action: 'pageChange',
          pageIndex,
          direction,
        });
      },
      onExportData: dataCallbacks.onExportData,
    }),
    [state]
  );

  const cValue = useMemo(
    () => ({
      state,
      patchState,
      callbacks,
      config,
      features,
      data: parsedData,
      count,
      isMobile,
    }),
    [state, config, features, parsedData, count, isMobile]
  );

  return (
    <TableContext.Provider value={cValue}>
      <Wrapper>{props.children}</Wrapper>
    </TableContext.Provider>
  );
};

export const Wrapper = styled.div``;
