import { Button, Markdown, Typography } from '@ai21/studio-base-ui';
import { IApp, actions } from '@ai21/studio-store';
import classnames from 'classnames';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AppActions from '../../components/AppActions/AppActions';
import ModelsUsage from '../../components/AppActions/ModelsUsage';
import AppHeader from '../../components/AppHeader/AppHeader';

import AppScreenshots from '../../components/AppScreenshots/AppScreenshots';
import {
  Column,
  Container,
  QuoteAction,
  QuoteWrapper,
  Row,
  Wrapper,
} from './AppPage.style';

export type AppPageProps = {
  app: IApp;
  callbacks: {
    onAction: (action: Json) => void;
    onTagClicked: (tag: string) => void;
    onBack: () => void;
  };
};

export function AppPage(props: AppPageProps) {
  const { app, callbacks } = props;
  const dispatch = useDispatch();
  const { descriptionFull, overviewDescription, quoteText } = app;

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const mobileClassName = classnames({ mobile: isMobile });
  const lpClassName = classnames({ lp: window.location.href.includes('lp') });

  useEffect(() => {
    setTimeout(() => {
      dispatch(actions.appState.patch({ isIntercomVisible: false }));
    }, 500);

    return () => {
      dispatch(actions.appState.patch({ isIntercomVisible: true }));
    };
  }, []);

  function renderDescription() {
    return (
      <Column>
        <Markdown markdown={overviewDescription} />
        {quoteText && (
          <QuoteWrapper>
            <Markdown markdown={quoteText} />
            <QuoteAction>
              <Typography variant='subtitle2' color='textPrimary'>
                Build your own solution with the help of our experts
              </Typography>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => callbacks.onAction({ id: 'support' })}
              >
                Contact sales
              </Button>
            </QuoteAction>
          </QuoteWrapper>
        )}
        <Markdown markdown={descriptionFull} />
      </Column>
    );
  }

  function renderColumns() {
    if (isMobile) {
      return (
        <Row className={mobileClassName}>
          <AppActions app={app} onAction={callbacks.onAction} />
          {renderDescription()}
          <ModelsUsage app={app} onAction={callbacks.onAction} />
        </Row>
      );
    }
    return (
      <Row>
        {renderDescription()}
        <Column>
          <AppActions app={app} onAction={callbacks.onAction} />
          <ModelsUsage app={app} onAction={callbacks.onAction} />
        </Column>
      </Row>
    );
  }

  return (
    <Wrapper
      className={`AppPage-wrapper ${mobileClassName} ${lpClassName}`}
      data-testid='AppPage-wrapper'
    >
      <Container>
        <AppHeader
          app={app}
          onBack={callbacks.onBack}
          onAction={callbacks.onAction}
        />
        <AppScreenshots app={app} />
        {renderColumns()}
      </Container>
    </Wrapper>
  );
}

export default AppPage;
