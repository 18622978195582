import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  background: ${tokens.primitives.grey[10]};
  border-radius: 12px;
  padding: 16px;
  font-size: 16px;
  line-height: 135%;
  color: ${tokens.text.primary};
  margin: 8px 24px;
  border: 1px solid ${tokens.primary.borderDefault};
  @media (max-width: 1000px) {
    padding: 8px;
  }
`;

export const Question = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  background: ${tokens.primitives.grey[10]};
  padding: 16px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 15px;
  line-height: 1.5;
  color: ${tokens.text.primary};
  border: 1px solid ${tokens.primitives.grey[10]};
  @media (max-width: 1000px) {
    padding: 8px;
  }
`;

export const Content = styled.div`
  flex: 1;
  margin-left: 16px;
`;

export const FeedbackWrapper = styled.div`
  margin-left: 20px;
  @media (max-width: 1000px) {
    align-self: flex-end;
  }
`;

export const BasedOn = styled.div`
  margin-top: 24px;
  color: ${tokens.text.secondary};
`;

export const P = styled.p`
  line-height: 160%;
  font-weight: 400;
  font-size: 12px;
  color: ${tokens.text.secondary};
  padding: 0;
  margin: 0;
`;

export const Answer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  font-size: 14px;

  @media (max-width: 1000px) {
    margin-top: 4px;
    padding: 8px;
  }
`;

export const Error = styled.div`
  color: ${tokens.error.mainDefault};
  font-size: 14px;
`;

export const Letter = styled.div`
  font-weight: bold;
  width: 48px;
  height: 48px;
  border: 1px solid ${tokens.primary.borderDefault};
  border-radius: 8px;
  text-align: center;
  line-height: 48px;
  font-weight: 500;
  font-size: 22px;
  background: ${tokens.primitives.grey[50]};

  &.white {
    background-color: ${tokens.primary.backgroundDefault};
    background: ${tokens.primary.backgroundDefault};

    margin-left: 16px;

    @media (max-width: 1000px) {
      margin-left: 0px;
    }
  }

  @media (max-width: 1000px) {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
  }
`;

export const Sources = styled.div``;
