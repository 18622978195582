import { MenuBuilder, RouterBuilder } from '@ai21/studio-ui';
import { fork } from 'saga-ts';
import { getBoolean } from 'shared-base';
import { allApps } from '../apps';
import {
  initRoutes as initRoutesWeb,
  initSideBar as initSideBarCore,
  initTopBar as initTopBarCore,
} from './Web.init';

const appsGlobal = (window.__env__.VITE_GLOBAL_APPS ?? '').split(',');

export const initRoutes = (routerBuilder: RouterBuilder) => {
  initRoutesWeb(routerBuilder);
  Object.values(allApps).forEach((item) => {
    const isGlobalAndExternal =
      routerBuilder.routes.find((p) => p.pageId === item.id)?.isExternal &&
      appsGlobal.includes(item.id);
    if (getBoolean(item.localStorageKey) || isGlobalAndExternal) {
      item.app.initRoutes(routerBuilder);
    }
  });
};

export const initSideBar = (menuBuilder: MenuBuilder) => {
  initSideBarCore(menuBuilder);

  Object.values(allApps).forEach((item) => {
    if (getBoolean(item.localStorageKey)) {
      item.app.initSideBar(menuBuilder);
    }
  });
};

export const initTopBar = (menuBuilder: MenuBuilder) => {
  initTopBarCore(menuBuilder);

  Object.values(allApps).forEach((item) => {
    if (getBoolean(item.localStorageKey)) {
      item.app.initTopBar(menuBuilder);
    }
  });
};

export function* initSagas() {
  const appsGlobal = (window.__env__.VITE_GLOBAL_APPS ?? '').split(',');
  // apps
  for (let item of Object.values(allApps)) {
    if (getBoolean(item.localStorageKey) || appsGlobal.includes(item.id)) {
      yield* fork(item.app.root);
    }
  }
  const isDemo = document.location.pathname.startsWith('/demo/');

  if (isDemo) {
    yield* fork(allApps.demos.app.root);
    yield* fork(allApps.ragEngine.app.root);
  }
}
