import { EventLogs } from '@ai21/studio-ui';
import { initialLogs } from './AuthLogs.data';

export type AuthLogsProps = {};

export function AuthLogsContainer(_props: AuthLogsProps) {
  const config = {
    id: 'auth',
    eventId: 'logs/auth',
    initialLogs,
  };

  return <EventLogs config={config} compact />;
}

export default AuthLogsContainer;
