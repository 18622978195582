import { ActionApiIcon } from './ActionApiIcon';
import { ActionShareIcon } from './ActionShareIcon';
import { ChevronRightIcon } from './ChevronRightIcon';
import { DatasetTableIcon } from './DatasetTableIcon';
import { ExampleIcon } from './ExampleIcon';
import { FoundationModelIcon } from './FoundationModelIcon';
import { J1Icon } from './J1Icon';
import { J2Icon } from './J2Icon';
import { ModelTableIcon } from './ModelTableIcon';
import { PlaygroundIcon } from './PlaygroundIcon';
import { PresetsTableIcon } from './PresetsTableIcon';
import { SpecializedApiIcon } from './SpecializedApiIcon';
import { StatusActiveIcon } from './StatusActiveIcon';
import { StatusFailedIcon } from './StatusFailedIcon';
import { StatusTrainingIcon } from './StatusTrainingIcon';
import { WordtuneIcon } from './WordtuneIcon';
import { presetCatalogue } from './presetCatalogue';
import QAndAIcon from './QAndAIcon';

export const overview = {
  ActionApi: ActionApiIcon,
  ActionShare: ActionShareIcon,
  ChevronRight: ChevronRightIcon,
  DatasetTable: DatasetTableIcon,
  Example: ExampleIcon,
  FoundationModel: FoundationModelIcon,
  J1: J1Icon,
  J2: J2Icon,
  ModelTable: ModelTableIcon,
  Playground: PlaygroundIcon,
  PresetsTable: PresetsTableIcon,
  QAndAIcon: QAndAIcon,
  SpecializedApi: SpecializedApiIcon,
  StatusActive: StatusActiveIcon,
  StatusFailed: StatusFailedIcon,
  StatusTraining: StatusTrainingIcon,
  Wordtune: WordtuneIcon,
  ...presetCatalogue,
};
