import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
`;

export const WrapperLog = styled.div``;

export const Field = styled.div``;

export const Key = styled.div`
  font-weight: bold;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 8px;
  padding: 2px 6px;
  background-color: #f4f4f4;
`;

export const Value = styled.pre`
  max-width: 300px;
  white-space: pre-wrap;
  padding: 0;
  padding-left: 6px;
  margin: 6px 0;
`;

export const Index = styled.div`
  font-size: 24px;
  font-family: monospace;
`;

export const Empty = styled.div`
  padding: 30px 0;
  text-align: center;
  opacity: 0.5;
`;
