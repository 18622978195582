export function EditIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9697 4.96973C15.3671 3.57227 17.6329 3.57227 19.0303 4.96973C20.4278 6.36719 20.4278 8.63293 19.0303 10.0304L8.53033 20.5304C8.38968 20.671 8.19891 20.7501 8 20.7501H4C3.58579 20.7501 3.25 20.4143 3.25 20.0001V16.0001C3.25 15.8011 3.32902 15.6104 3.46967 15.4697L12.9641 5.9753C12.9659 5.97341 12.9678 5.97154 12.9697 5.96967C12.9715 5.9678 12.9734 5.96594 12.9753 5.9641L13.9697 4.96973ZM13.5 7.56069L4.75 16.3107V19.2501H7.68934L16.4394 10.5L13.5 7.56069ZM17.5 9.43937L14.5607 6.50003L15.0303 6.03039C15.842 5.21871 17.158 5.21871 17.9697 6.03039C18.7813 6.84206 18.7813 8.15805 17.9697 8.96973L17.5 9.43937Z'
        fill='black'
      />
    </svg>
  );
}
