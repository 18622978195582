import { A, Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-direction: column;
  align-items: center;
  width: 520px;
  padding: 30px 50px 44px;
  box-sizing: border-box;

  > * {
    margin: 4px 0;
  }
`;

export const Header = styled(Typography)`
  padding: 20px 0;
`;

export const Content = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const P = styled(Typography)`
  font-size: 18px;
  text-align: center;
`;
