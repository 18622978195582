import styled from 'styled-components';
import { Typography } from '@ai21/studio-base-ui';
import { Button } from '@ai21/studio-ui';

export const Wrapper = styled.div`
  flex: 1;
  margin-top: 0;
  margin-bottom: 4px;
`;

export const Title = styled(Typography)`
  padding: 16px 0;
  &.mobile {
    font-size: 24px;
  }
`;

export const ShareButton = styled(Button)`
  margin-right: 10px;
`;

export const Subtitle = styled(Typography)`
  &.mobile {
    font-size: 16px;
  }
`;

export const Description = styled(Typography)``;
