import { api } from '@ai21/studio-api';
import { eventChannel } from 'redux-saga';

export function createFirestoreChannel(setId: string) {
  return eventChannel((emitter) => {
    function onChange(data: any) {
      emitter({ data });
    }

    const unlisten = api.generation.listenToLines(setId, onChange);

    return () => {
      unlisten();
    };
  });
}
