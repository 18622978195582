import { addListener } from 'shared-base';
import { AuthOptions } from './types';
import { AuthState } from './utils/authState';
import { Firebase } from './utils/firebase';
import { setAuthState, setFirebase, setStore } from './utils/globals';

export const initAuth = (options: AuthOptions) => {
  const firebase = new Firebase(options);
  setFirebase(firebase);

  const instance = new AuthState(options);
  setAuthState(instance);

  setStore(options.store);

  return firebase.app;
};
