import styled from 'styled-components';
import { Select as SelectMui } from '@mui/material';
import { tokens } from '../../theme';

export const Select = styled(SelectMui)`
  margin-right: 12px;
  .MuiOutlinedInput-notchedOutline {
    border-radius: ${tokens.primitives.radius.full}px;
    border: 1px solid ${tokens.primary.borderDefault};
  }

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${tokens.neutral.almostBlack};
      border-width: 2px;
    }
  }

  svg {
    color: ${tokens.primary.mainDefault};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 14px;

  &.mobile {
    width: 100%;

    ${Select} {
      width: 100%;
    }
  }
`;
