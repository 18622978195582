import { createSelector } from 'reselect';
import { sortBy } from 'shared-base';
import { IStudioStore } from '../types';
import * as raw from './selectors.raw';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $collectionJob = createSelector(
  raw.$rawCollectionJobs,
  raw.$rawCurrentIds,
  (jobs, currentIds) => {
    const { collectionJobId } = currentIds;

    if (!collectionJobId) {
      return;
    }

    return jobs[collectionJobId];
  }
);

export const $collectionLines = createSelector(
  raw.$rawCollectionLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { collectionJobId } = currentIds;

    if (!collectionJobId) {
      return [];
    }

    return Object.values(lines)
      .filter((line) => line.setId === collectionJobId && !line.isDeleted)
      .sort(sortBy('index'));
  }
);

export const $collectionLineId = createSelector(raw.$rawCurrentIds, (currentIds) => {
  const { collectionLineId } = currentIds;
  return collectionLineId;
});

export const $collectionLine = createSelector(
  raw.$rawCollectionLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { collectionLineId } = currentIds;

    if (!collectionLineId) {
      return;
    }

    return lines[collectionLineId];
  }
);

export const $collectionDots = createSelector(
  raw.$rawCollectionLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { collectionLineId } = currentIds;

    return Object.values(lines)
      .map((line) => {
        const { id, index } = line;

        const isCurrent = collectionLineId === id;

        return {
          id,
          index,
          isCurrent,
        };
      })
      .sort(sortBy('index'));
  }
);

export const $collectionJobInfo = createSelector($collectionJob, $collectionDots, (job, dots) => {
  const countTotal = dots.length;

  const currentIndex = dots.findIndex((dot) => dot.isCurrent);

  const previousDot = dots[currentIndex - 1];
  const nextDot = dots[currentIndex + 1];

  return {
    job,
    currentIndex,
    dots,
    countTotal,
    previousDot,
    nextDot,
  };
});
