export enum CodeSnippetModalContext {
  PLAYGROUND = 'playground',
  MODELS = 'models',
  OVERVIEW = 'overview',
}
// shell is being used for CURL just because react-code-blocks has
// no support for curl, but it does support shell -- close enough.

export enum ProgrammingLanguage {
  PYTHON = 'python',
  JAVASCRIPT = 'javascript',
  CURL = 'curl',
}
