import { createContext, useEffect, useMemo } from 'react';
import { useSetState } from 'react-use';
import { parseLabels } from './InboxEditor.data';
import { IInboxContext, IInboxProviderProps, IInboxState, Verb } from './InboxEditor.types';
import { delay } from 'shared-base';

export const InboxContext = createContext<IInboxContext>({
  line: undefined,
  jobInfo: undefined,
  state: {
    colorMode: 'color',
    stage: 'pre-score',
    isMac: false,
    lineId: '',
    scoreOptions: [],
    showComment: false,
  },
  callbacks: {
    onAction: (_verb: Verb, _params?: Json) => {},
    onEvaluate: (_score: number, lineId: string, _params?: Json) => {},
    onTags: (_tags: string[], _lineId?: string) => {},
    onComment: (_tag: string) => {},
    onClose: (_params?: Json) => {},
    onToggleColorMode: () => {},
  },
  patchState: () => {},
});

export const InboxProvider = (props: IInboxProviderProps) => {
  const { line, jobInfo, callbacks: inCallbacks } = props;
  const [state, patchState] = useSetState<IInboxState>({
    colorMode: 'color',
    stage: 'pre-score',
    isMac: false,
    lineId: '',
    scoreOptions: [],
    showComment: false,
  });

  useEffect(() => {
    patchState({
      lineId: line?.id ?? '',
    });
  }, [line]);

  useEffect(() => {
    const { job } = jobInfo ?? {};
    const colorMode = localStorage.getItem('inboxColorMode') ?? ('color' as any);
    var isMac = navigator.userAgent.toLocaleLowerCase().indexOf('mac') >= 0;

    patchState({
      colorMode,
      isMac,
      scoreOptions: parseLabels(job),
    });
  }, [jobInfo?.job]);

  const callbacks = useMemo(
    () => ({
      simulateSave: async () => {
        patchState({ isSaving: true });
        await delay(500);
        patchState({ isSaving: false });
      },
      onAction: (verb: Verb, params?: Json) => {
        inCallbacks.onAction(verb, params);
      },
      onEvaluate: (score: number, lineId: string, params?: Json) => {
        callbacks.simulateSave();
        inCallbacks.onEvaluate(score, lineId, params);
      },
      onTags: (tags: string[], lineId: string) => {
        callbacks.simulateSave();
        inCallbacks.onTags(tags, lineId);
      },
      onComment: (comment: string, lineId: string) => {
        callbacks.simulateSave();
        inCallbacks.onComment(comment, lineId);
      },
      onClose: (_params?: Json) => {
        inCallbacks.onClose(_params);
      },
      onToggleColorMode: () => {
        const nextValue = state.colorMode === 'color' ? 'grayscale' : 'color';
        localStorage.setItem('inboxColorMode', nextValue);
        patchState({
          colorMode: nextValue,
        });
      },
    }),
    [state]
  );

  const cValue = useMemo(
    () => ({
      state,
      patchState,
      callbacks,
      line,
      jobInfo,
    }),
    [state, line, jobInfo, callbacks]
  );

  return <InboxContext.Provider value={cValue}>{props.children}</InboxContext.Provider>;
};
