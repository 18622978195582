export function InfoIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24'>
      <path
        fill='currentColor'
        d='M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM11.25 8C11.25 7.58579 11.5858 7.25 12 7.25H12.01C12.4242 7.25 12.76 7.58579 12.76 8C12.76 8.41421 12.4242 8.75 12.01 8.75H12C11.5858 8.75 11.25 8.41421 11.25 8ZM10.25 11C10.25 10.5858 10.5858 10.25 11 10.25H12C12.4142 10.25 12.75 10.5858 12.75 11V15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H12C11.5858 16.75 11.25 16.4142 11.25 16V11.75H11C10.5858 11.75 10.25 11.4142 10.25 11Z'
      />
    </svg>
  );
}
