import styled from 'styled-components';

export const Alert = styled.div`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #d48f05;
  display: flex;
  width: 101%;
  padding: 14px 16px;
  margin: -2px;
  align-items: start;

  svg {
    path {
      stroke: #66490e;
    }
  }
`;
