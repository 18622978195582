import { fork } from 'saga-ts';
import { root as api } from './saga.api';
import { root as evaluationExtract } from './saga.evaluationExtract';
import { root as evaluationInbox } from './saga.evaluationInbox';
import { root as evaluationItem } from './saga.evaluationItem';
import { root as evaluationJobs } from './saga.evaluationJobs';
import { root as evaluationLines } from './saga.evaluationLines';
import { root as evaluationStatus } from './saga.evaluationStatus';
import { root as mocks } from './saga.mocks';

export function* root() {
  yield* fork(api);
  yield* fork(evaluationExtract);
  yield* fork(evaluationInbox);
  yield* fork(evaluationItem);
  yield* fork(evaluationJobs);
  yield* fork(evaluationLines);
  yield* fork(evaluationStatus);
  yield* fork(mocks);
}
