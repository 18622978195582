import { select } from 'saga-ts';
import { invokeEvent } from 'shared-base';
import { selectors } from '@ai21/studio-store';

type Action =
  | 'navigate'
  | 'evaluate'
  | 'navigate'
  | 'reasons'
  | 'score'
  | 'unEvaluate'
  | 'closeDrawer'
  | string;

type ActionValue = 'next' | 'previous' | string | number;

type Params = {
  action: Action;
  actionValue?: ActionValue;
  eventId?: string;
  extra?: Json;
};

export function* analytics(params: Params) {
  const { action, actionValue, eventId, extra } = params;

  const currentIds = yield* select(selectors.raw.$rawCurrentIds);
  const { playJobId, playLineId } = currentIds;

  invokeEvent('global/flow', {
    flow: 'play',
    action,
    actionValue,
    parentId: playJobId,
    itemId: playLineId,
    eventId,
    extra,
  });
}
