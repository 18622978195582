export function FeedbackCheckIcon() {
  return (
    <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.49999 9.47487L2.02499 5.99987L0.84166 7.17487L5.49999 11.8332L15.5 1.8332L14.325 0.658203L5.49999 9.47487Z'
        fill='currentColor'
      />
      <path
        d='M5.49999 9.47487L2.02499 5.99987L0.84166 7.17487L5.49999 11.8332L15.5 1.8332L14.325 0.658203L5.49999 9.47487Z'
        fillOpacity='0.2'
      />
    </svg>
  );
}
