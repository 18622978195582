import { Copy, Icon, IconLink } from '@ai21/studio-base-ui';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { formatNumber } from '../../../../utils/format';
import { Verb } from '../../InboxEditor.types';
import { Actions, Content, Count, Gap, TextArea, Title, Top, Wrapper } from './IOEditor.style';

export type IOEditorProps = {
  id?: string;
  title: string;
  content?: string;
  iconName?: string;
  flex?: number;
  copyTestId?: string;
  withBorder?: boolean;
  showHide?: boolean;
  disabled?: boolean;
  onChange?: (content: string, id?: string) => void;
  onAction: (verb: Verb, params?: Json) => void;
};

export function IOEditor(props: IOEditorProps) {
  const {
    id,
    title,
    content = '',
    flex,
    iconName,
    copyTestId = 'copy',
    withBorder,
    showHide,
    disabled,
  } = props;
  const [value, setValue] = useState(content || '');
  const previousId = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (!props.onChange) {
      return;
    }

    props.onChange(value, previousId.current ?? '');
    setValue(content || '');
    previousId.current = id;
  }, [id]);

  useEffect(() => {
    setValue(content);
  }, [content]);

  function onChange(event: any) {
    const value = event.target.value;
    setValue(value);
  }

  function onBlur() {
    if (!props.onChange) {
      return;
    }

    props.onChange(value, id ?? '');
  }

  const style: React.CSSProperties = {
    flex,
  };

  const className = classnames('IOEditor-wrapper', {
    border: withBorder,
    noBorder: !withBorder,
    'show-hide': showHide,
  });

  return (
    <Wrapper
      className={className}
      data-testid='IOEditor-wrapper'
      style={style}
      paddingLeft={withBorder ? 24 : 0}
    >
      <Top className='top'>
        <Title>
          <Icon iconName={iconName} />
          {title}
        </Title>
        <Count>({formatNumber(value.length)} characters)</Count>
        <Gap />
        <Actions>
          <Copy value={value} delay={3000} />
          <IconLink
            onClick={() => props.onAction('hide')}
            data-testid={copyTestId}
            className='action-hide'
            text='Hide'
            iconName='Eye'
          />
        </Actions>
      </Top>
      <Content>
        <TextArea
          multiline
          fullWidth
          disabled={disabled}
          inputProps={{
            className: 'IOEditor-textarea',
            style: {
              border: 'none',
            },
          }}
          onChange={props.onChange ? onChange : undefined}
          onBlur={onBlur}
          value={value.replace(/\\n/g, '\n')}
        />
      </Content>
    </Wrapper>
  );
}

export default IOEditor;
