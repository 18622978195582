import { IconButton, Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 15px;
`;

export const Flex = styled('div')`
  flex: 1;
`;

export const TitleContainer = styled('div')`
  flex: 1;
`;

export const TitleWithChevron = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  svg {
    top: 1px;
    position: relative;
    width: 16px;
    height: 16px;
    stroke-width: 1.5px;
    color: #1d1e22;
  }
`;

export const Title = styled(Typography)<{ link?: string }>`
  margin-right: 6px;
  margin-bottom: 6px;
  padding-bottom: 0px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.87); */
  /* border-bottom-width: ${(props) => (props.link ? '1px' : 0)}; */
  color: ${tokens.text.primary};
  font-weight: 500;
  cursor: ${(props) => (props.link ? 'pointer' : 'default')};
`;

export const Subtitle = styled(Typography)`
  color: ${tokens.text.secondary};
`;

export const SearchContainer = styled('div')`
  width: 500px;
`;

export const TableActionButton = styled(IconButton)`
  color: #626b78;
  border: solid 1px #ccd0d5;
  border-radius: 8px;
  height: 36px;
  width: 36px;
  margin-left: 10px;
`;

export const Count = styled(Title)``;

export const Actions = styled.div`
  margin-top: 12px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;

  button {
    margin-left: 8px;
  }
`;
