import { initAxios } from './axios';
import { initFirebase } from './firebase';
import * as globals from './globals';
import { initAxiosForTests } from './test/test.axios';
import { initFirebaseForTests } from './test/test.firebase';
import { IApiConfig } from './types';

export const initApi = (config: IApiConfig) => {
  initAxios(config);
  globals.setApiUrl(config.studioApiBaseUrl);
  initFirebase(config.app);
  globals.setOrganizationId(config.organizationId);
};

export const initApiOffline = () => {
  initAxiosForTests();
  globals.setApiUrl('');
  initFirebaseForTests();
  globals.setOrganizationId('');
};

export const setOrganizationId = (orgId: string) => {
  globals.setOrganizationId(orgId);
};

export const setUserId = (userId: string) => {
  globals.setUserId(userId);
};
