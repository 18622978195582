import { IconButton } from '../IconButton/IconButton';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
`;

export const CopyButton = styled(IconButton)`
  width: 28px;
  height: 28px;
  padding: 2px;

  &.with-border {
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 8px;
  }
`;
