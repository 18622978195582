import { Navigate, Route } from 'react-router-dom';
import {
  IPages,
  IRoute,
  IAppConfig,
  IRouteMap,
  IRouterBuilder,
  Json,
  UserStatus,
} from './platform.types';

export class RouterBuilder implements IRouterBuilder {
  public routes: IRoute[] = [];
  private defaultRoute: string = '';
  private notFoundPageId: string = '';
  private redirects: Json = {};
  private pages: IPages = {};

  constructor(private userStatus: UserStatus | null, private userEmail: string = '') {}

  withAppConfig(appConfig: IAppConfig) {
    return this.withDefaultRoute(appConfig.defaultRoute)
      .withRedirects(appConfig.redirects)
      .withPublicRoutes(appConfig.publicRoutes)
      .withPrivateRoutes(appConfig.privateRoutes)
      .withExternalRoutes(appConfig.externalRoutes)
      .withPages(appConfig.pages);
  }

  withPages(pages: IPages, forEmployeesOnly = false) {
    const isEmployee = this.userEmail.endsWith('@ai21.com');

    if (!isEmployee && forEmployeesOnly) {
      return this;
    }

    this.pages = { ...this.pages, ...pages };
    return this;
  }

  withPublicRoutes(routes: IRouteMap) {
    Object.keys(routes).forEach((path) => {
      this.routes.push({
        path,
        pageId: routes[path],
        isPrivate: false,
      });
    });

    return this;
  }

  withPrivateRoutes(routes: IRouteMap) {
    if (this.userStatus !== UserStatus.VERIFIED) {
      return this;
    }

    Object.keys(routes).forEach((path) => {
      this.routes.push({
        path,
        pageId: routes[path],
        isPrivate: true,
      });
    });

    return this;
  }

  withExternalRoutes(routes: IRouteMap) {
    Object.keys(routes).forEach((path) => {
      this.routes.push({
        path,
        pageId: routes[path],
        isExternal: true,
      });
    });

    return this;
  }

  withDefaultRoute(path: string) {
    this.defaultRoute = path;
    return this;
  }

  with404PageId(pageId: string) {
    this.notFoundPageId = pageId;
    return this;
  }

  withRedirects(redirects: Json) {
    this.redirects = redirects;
    return this;
  }

  build() {
    const output: JSX.Element[] = [];

    this.routes.forEach((route) => {
      const Cmp = this.pages[route.pageId];

      if (!Cmp) {
        console.warn(`No page found for pageId: ${route.pageId}`);
        return;
      }

      if (route.redirectPath) {
        output.push(
          <Route
            key={route.pageId}
            path={route.path}
            element={<Navigate replace to={route.redirectPath} />}
          />
        );
      } else {
        output.push(<Route key={route.pageId} path={route.path} element={<Cmp />} />);
      }
    });

    Object.keys(this.redirects).forEach((path) => {
      const to = this.redirects[path] ?? '/';

      output.push(<Route key={path} path={path} element={<Navigate replace to={to} />} />);
    });

    output.push(
      <Route key='redirect' path='/' element={<Navigate replace to={this.defaultRoute} />} />
    );

    const NotFound = this.pages[this.notFoundPageId];

    if (NotFound) {
      output.push(<Route key='404' path='*' element={<NotFound />} />);
    }

    return output;
  }
}
