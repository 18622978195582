import React, { useMemo } from 'react';
import { Plus, Wrapper } from './NameAvatar.style';
import { Tooltip } from '@mui/material';

export type NameAvatarProps = {
  people: string[];
};

export function NameAvatar(props: NameAvatarProps) {
  const { people } = props;

  if (people.length === 0) {
    return null;
  }

  if (people.length > 1) {
    return <NameAvatarPlural people={people} />;
  }

  const text = people[0]
    .split(' ')
    .map((word) => word[0])
    .join('');

  return (
    <Wrapper className='NameAvatar-wrapper' data-testid='NameAvatar-wrapper'>
      {text}
    </Wrapper>
  );
}

export function NameAvatarPlural(props: NameAvatarProps) {
  const { people } = props;

  return (
    <Tooltip title={people.join(', ')}>
      <Wrapper className='NameAvatar-wrapper' data-testid='NameAvatar-wrapper'>
        <Plus>+</Plus>
        {people.length}
      </Wrapper>
    </Tooltip>
  );
}

export default NameAvatar;
