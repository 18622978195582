import { IconButton, Tags } from '@ai21/studio-base-ui';
import { Button } from '@ai21/studio-base-ui';
import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';
import { Typography } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 4px 16px;

  &.initial {
    background-color: ${tokens.brand.backgroundHover};

    .back,
    .tags,
    .cta {
      display: none;
    }
  }

  &.ranked {
    .left {
      display: none;
    }

    .tags,
    .cta {
      display: block;

      &.check {
        display: flex;
        align-items: center;
      }
    }
  }

  &.thankYou {
    background-color: ${tokens.brand.backgroundHover};
    height: 38px;

    .left,
    .back,
    .tags,
    .cta,
    .buttons {
      display: none;
    }
  }

  &.score-0 {
    .green {
      display: none;
    }

    .yellow {
      display: none;
    }
  }

  &.score-5 {
    .green {
      display: none;
    }

    .red {
      display: none;
    }
  }

  &.score-10 {
    .yellow {
      display: none;
    }

    .red {
      display: none;
    }
  }
`;

export const Left = styled.div`
  display: flex;
  align-content: flex-end;
  flex: 1;
  margin-bottom: 8px;

  span {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 4px;
    stroke-width: 1.5px;
    path {
      fill: ${tokens.text.tertiary};
    }
  }
`;

export const Text = styled.div``;

export const Buttons = styled.div``;

export const IconButtonBase = styled(IconButton)`
  color: ${tokens.primary.mainDefault};
  border: 1px solid ${tokens.primary.borderDefault};
  padding: 6px;
  margin: 4px 2px;

  svg {
    color: ${tokens.primary.mainDefault};
    stroke-width: 1.5px;
    width: 18px;
    height: 18px;
  }
`;

export const Reasons = styled(Tags)`
  position: relative;
  bottom: 4px;
  margin: 0 2px;

  .MuiChip-root {
    padding: 0;
    height: 20px;
  }

  .MuiChip-label {
  }

  .MuiAutocomplete-root {
    .MuiTextField-root {
    }

    & .MuiAutocomplete-endAdornment {
      top: unset;
    }

    svg {
      width: 14px;
      height: 14px;
    }

    input {
      height: 26px;
      padding: 0 0 0 5px !important;
      font-size: 13px;
    }
  }
`;

export const Cta = styled(IconButtonBase)`
  border-color: ${tokens.primary.borderDefault};
  svg {
    color: ${tokens.primary.mainDefault};
  }
  &:hover {
    background: ${tokens.primary.backgroundHover};
  }
`;

export const Clear = styled(IconButtonBase)`
  svg {
    width: 15px;
    height: 15px;
  }

  width: 30px;
  height: 30px;
`;

export const Score = styled(IconButtonBase)`
  &.green {
    &.selected {
      background-color: ${tokens.success.backgroundActive};
      border: 1px solid ${tokens.success.borderActive};

      svg {
        color: ${tokens.success.mainActive};
      }

      &:hover {
        background-color: ${tokens.success.backgroundActive};
        border: 1px solid ${tokens.success.borderActive};
        cursor: default;
        svg {
          color: ${tokens.success.mainActive};
        }
      }
    }

    &:hover {
      background-color: ${tokens.success.backgroundHover};
      border: 1px solid ${tokens.success.borderHover};
      svg {
        color: ${tokens.success.mainActive};
      }
    }

    &:active {
      background-color: ${tokens.success.backgroundActive};
      border: 1px solid ${tokens.success.borderActive};
    }

    &:disabled {
      color: #88aa93;
      border: 1px solid #93e0a8;
      background-color: #edfef1;
      svg {
        color: #88aa93;
      }
    }
  }

  &.yellow {
    &.selected {
      background-color: ${tokens.warning.backgroundActive};
      border: 1px solid ${tokens.warning.borderActive};

      svg {
        color: ${tokens.warning.mainActive};
      }

      &:hover {
        background-color: ${tokens.warning.backgroundActive};
        border: 1px solid ${tokens.warning.borderActive};
        cursor: default;
      }
    }

    &:hover {
      background-color: ${tokens.warning.backgroundHover};
      border: 1px solid ${tokens.warning.borderHover};

      svg {
        color: ${tokens.warning.mainActive};
      }
    }

    &:active {
      background-color: ${tokens.warning.backgroundActive};
      border: 1px solid ${tokens.warning.borderActive};
    }

    &:disabled {
      color: #b99e87;
      border: 1px solid #f7d881;
      background-color: #fdf7e5;
      svg {
        color: #b99e87;
      }
    }
  }

  &.red {
    &.selected {
      background-color: ${tokens.error.backgroundActive};
      border: 1px solid ${tokens.error.borderActive};

      svg {
        color: ${tokens.error.mainActive};
      }

      &:hover {
        background-color: ${tokens.error.backgroundActive};
        border: 1px solid ${tokens.error.borderActive};
        cursor: default;
      }
    }

    &:hover {
      background-color: ${tokens.error.backgroundHover};
      border: 1px solid ${tokens.error.borderHover};

      svg {
        color: ${tokens.error.mainActive};
      }
    }

    &:active {
      background-color: ${tokens.error.backgroundActive};
      border: 1px solid ${tokens.error.borderActive};
    }

    &:disabled {
      color: #c78a86;
      border: 1px solid #f4a49f;
      background-color: #ffefef;
      svg {
        color: #c78a86;
      }
    }
  }
`;

export const ReasonsWrapper = styled.div``;
