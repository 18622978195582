import type { IRouteMap } from '@ai21/studio-ui';

export enum AppRoutes {
  COLLECTION_SETS = '/datasets/data-collections',
  COLLECTION_LINES = '/datasets/data-collections/:id/lines',
}

export const publicRoutes: IRouteMap = {};

export const privateRoutes: IRouteMap = {
  [AppRoutes.COLLECTION_SETS]: 'collectionSets',
  [AppRoutes.COLLECTION_LINES]: 'collectionLines',
};
