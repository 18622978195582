import React from 'react';

const EvaluationJobsPage = React.lazy(
  () => import('./evaluationJobs/EvaluationJobsPage.container')
);
const EvaluationInboxPage = React.lazy(
  () => import('./evaluationInbox/EvaluationInboxPage.container')
);

export const pages: Record<string, React.FC<any>> = {
  evaluationJobs: EvaluationJobsPage,
  evaluationInbox: EvaluationInboxPage,
};
