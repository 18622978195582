export function PinkCircles() {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_15_2665)'>
        <rect width='48' height='48' rx='2' fill='#F8B5CC' />
        <g filter='url(#filter0_ddd_15_2665)'>
          <rect x='8' y='22' width='18' height='18' rx='9' fill='#FFF9EC' />
        </g>
        <g filter='url(#filter1_ddd_15_2665)'>
          <rect x='14' y='6' width='28' height='28' rx='14' fill='#753FEA' />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_ddd_15_2665'
          x='-2'
          y='13'
          width='38'
          height='38'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_15_2665' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_15_2665'
            result='effect2_dropShadow_15_2665'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect3_dropShadow_15_2665'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend
            mode='normal'
            in2='effect2_dropShadow_15_2665'
            result='effect3_dropShadow_15_2665'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect3_dropShadow_15_2665'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_ddd_15_2665'
          x='4'
          y='-3'
          width='48'
          height='48'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_15_2665' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_15_2665'
            result='effect2_dropShadow_15_2665'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect3_dropShadow_15_2665'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend
            mode='normal'
            in2='effect2_dropShadow_15_2665'
            result='effect3_dropShadow_15_2665'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect3_dropShadow_15_2665'
            result='shape'
          />
        </filter>
        <clipPath id='clip0_15_2665'>
          <rect width='48' height='48' rx='2' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
