import { fork, take } from 'saga-ts';
import { navigate } from './saga.navigation';

export function* preview() {
  yield* take('AUTHENTICATION_COMPLETED');
  const previewRoute = localStorage.getItem('preview_route');
  localStorage.removeItem('preview_route');

  if (!previewRoute) {
    return;
  }

  // navigate(previewRoute);
}

export function* root() {
  yield fork(preview);
}
