import { Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  height: auto;
  /* position: fixed; */
  /* bottom: 120px; */
  /* left: 50%; */
  /* transform: translateX(-50%); */
  /* width: 720px; */

  @media (max-width: 1000px) {
    align-items: center;
    width: 94vw;
    bottom: 80px;

    box-sizing: border-box;
  }
`;

export const Question = styled.div`
  width: 342px;
  margin: 8px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 6px 8px rgba(0, 0, 0, 0.04),
    0px 3px 6px -1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;

  svg {
    stroke-width: 1.5px;
    opacity: 0.5;
    height: 20px;
    width: 20px;
  }

  &:hover {
    background-color: #f4f5f7;
  }

  @media (max-width: 1000px) {
    width: 100vw;
  }
`;

export const QuestionText = styled(Typography)`
  flex: 1;
`;
