import {
  addToCollection,
  addToCollectionBatch,
  getCollection,
  listenToCollection,
  removeCollection,
  updateCollectionItem,
} from './firebase';

export const getJobs = (_extraParams?: Json) => {
  return getCollection('/evaluationJobs', 'organization');
};

export const createJob = (job: Json) => {
  return addToCollection('/evaluationJobs', job, 'organization');
};

export const updateJob = (setId: string, change: Json) => {
  return updateCollectionItem(`/evaluationJobs/${setId}`, change, 'organization');
};

export const removeJob = (setId: string) => {
  return removeCollection(`/evaluationJobs/${setId}`, 'organization');
};

export const getLines = (extraParams?: Json) => {
  const { setId } = extraParams || {};
  return getCollection(`/evaluationJobs/${setId}/lines`, 'organization');
};

export const createLines = (setId: string, items: Json[]) => {
  return addToCollectionBatch(`/evaluationJobs/${setId}/lines`, items, 'organization');
};

export const createLine = (setId: string, item: Json) => {
  return addToCollection(`/evaluationJobs/${setId}/lines`, item, 'organization');
};

export const updateLine = (setId: string, lineId: string, change: Json) => {
  return updateCollectionItem(`/evaluationJobs/${setId}/lines/${lineId}`, change, 'organization');
};

export const listenToLines = (setId: string, callback: any) => {
  return listenToCollection(`/evaluationJobs/${setId}/lines`, callback, 'organization');
};

export const evaluation = {
  getJobs,
  createJob,
  updateJob,
  removeJob,
  getLines,
  updateLine,
  createLine,
  createLines,
  listenToLines,
};
