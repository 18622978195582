import { Chip } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { addListener } from 'shared-base';
import { Icon } from '../Icon/Icon';
import { IconWrapper, Row, Tags, Wrapper } from './PanelAccordion.style';

type IPanel = {
  id: string;
  iconName: string;
  title: string;
  tags: string[];
  shouldRender: boolean;
};

export type PanelAccordionProps = {
  panels: IPanel[];
  expandedPanels?: string[];
  renderPanel: (panelId: string) => JSX.Element;
};

export function PanelAccordion(props: PanelAccordionProps) {
  const { panels } = props;
  const initialPanelId = localStorage.getItem('expanded_panels');

  const [expandedPanels, setExpandedPanels] = useState<string[] | undefined>(
    initialPanelId ? initialPanelId.split(',') : []
  );

  useEffect(() => {
    if (expandedPanels?.length === 0) {
      setExpandedPanels(initialPanelId ? initialPanelId.split(',') : []);
    }
  }, [panels]);

  useEffect(() => {
    const unlisten = addListener('OpenAccordion', (details: Json) => {
      if (!expandedPanels?.includes(details.id)) {
        let newCandidates = [...(expandedPanels || [])];
        newCandidates.push(details.id);
        setExpandedPanels(newCandidates);
        localStorage.setItem('expanded_panels', newCandidates.join(','));
      }
    });

    return () => unlisten();
  }, [expandedPanels]);

  useEffect(() => {
    const unlisten = addListener('closeAccordion', () => {
      let newCandidates: any[] = [];
      setExpandedPanels(newCandidates);
      localStorage.setItem('expanded_panels', newCandidates.join(','));
    });

    return () => unlisten();
  }, [expandedPanels]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      let newCandidates = [...(expandedPanels || [])];
      if (!newExpanded) {
        const index = newCandidates.indexOf(panel);
        if (index !== undefined && index > -1) {
          newCandidates.splice(index, 1);
        }
      } else {
        newCandidates.push(panel);
      }
      setExpandedPanels(newCandidates);
      localStorage.setItem('expanded_panels', newCandidates.join(','));
    };

  function renderTag(tag: string) {
    return <Chip key={tag} className={tag} label={tag} />;
  }

  function renderTags(tags: string[] = []) {
    return tags.map((tag: string) => renderTag(tag));
  }

  function renderPanel(panel: IPanel) {
    const { id, title, iconName, tags, shouldRender } = panel;

    if (!shouldRender) return null;

    return (
      <Accordion
        expanded={expandedPanels?.includes(id)}
        onChange={handleChange(id)}
        square={true}
        key={id}
      >
        <AccordionSummary aria-controls={`panel-${id}-content`} id={id}>
          <Row>
            <IconWrapper>
              <Icon iconName={iconName} />
            </IconWrapper>
            <Typography variant='body1bold'>{title}</Typography>
            <Tags>{renderTags(tags)}</Tags>
          </Row>
        </AccordionSummary>
        <AccordionDetails>{props.renderPanel(id)}</AccordionDetails>
      </Accordion>
    );
  }

  function renderPanels() {
    return panels.map((panel: IPanel) => renderPanel(panel));
  }

  return (
    <Wrapper
      className='PanelAccordion-wrapper'
      data-testid='PanelAccordion-wrapper'
    >
      {renderPanels()}
    </Wrapper>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderRadius: 0,
  flexGrow: 1,
  '&:not(:last-child)': {},
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<Icon iconName='ChevronDown' />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row',
  height: 48,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    height: '36px',
    width: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    svg: {
      height: '18px',
      width: '18px',
    },
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '2px solid #E6EAEC',
  overflow: 'auto',
  maxHeight: '70vh',
  backgroundColor: '#FBFBFB',

  '& .Field-wrapper': {
    margin: '16px 0px 0px 0px',
  },
}));

export default PanelAccordion;
