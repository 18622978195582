import {
  actions,
  createCollectionLine,
  IBox,
  ICollectionLine,
  selectors,
} from '@ai21/studio-store';
import { prompt as dialog, toast } from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, put, select, take } from 'saga-ts';
import { invokeEvent } from 'shared-base';
import type { ActionPlay } from './saga.play';

export function* addToCollection(action: ActionPlay, box: IBox) {
  const inputBoxes = yield* select(selectors.playground.$inputBoxes);
  const outputBoxes = yield* select(selectors.playground.$outputBoxes);
  const hasSelectedOutput =
    outputBoxes.filter((box) => box.values.isSelected === true).length > 0;
  const outputBox = hasSelectedOutput
    ? outputBoxes.filter((box) => box.values.isSelected === true)[0]
    : outputBoxes[0];
  const paramValues = yield* select(selectors.playground.$params);
  const collection = yield* select(selectors.playground.$collection);
  const { collectionId } = collection ?? {};

  const input = get(inputBoxes, '[0].values.prompt', '');
  const output = get(outputBox, 'values.content', '');

  invokeEvent('closeAccordion');
  invokeEvent('OpenAccordion', { id: collection.boxId });

  if (!input || !output) {
    toast.show('Missing input or output', 'warning');
    return;
  }

  if (!collectionId) {
    toast.show(
      'First, select an existing collection or create a new one.',
      'warning'
    );
    return;
  }

  const index = yield* call(getNextIndex, collectionId);

  const modelId = get(outputBox, 'values.modelId', '');

  const values: Partial<ICollectionLine> = {
    input,
    output,
    ...paramValues,
    modelName: modelId,
    foundationModelName: modelId,
    setSource: 'collection',
    setId: collectionId,
    index,
  };

  yield call(createCollectionLine, {
    ...values,
  });

  yield put(
    actions.collectionJobs.patch(collectionId, {
      linesCount: collection?.collectionLines.length + 1,
    })
  );

  toast.show('Added to collection', 'success');
}

export function* fetchCollectionLines(collectionJobId: string) {
  let collectionLinesRaw = yield* select(selectors.raw.$rawCollectionLines),
    collectionLines = Object.values(collectionLinesRaw).filter(l => l.setId === collectionJobId && !l.isDeleted); // prettier-ignore

  if (collectionLines.length > 0) {
    return collectionLines;
  }

  yield put(actions.collectionLines.get({ setId: collectionJobId }));
  yield take('SET_COLLECTIONLINES');

  collectionLinesRaw = yield* select(selectors.raw.$rawCollectionLines),
    collectionLines = Object.values(collectionLinesRaw).filter(l => l.setId === collectionJobId && !l.isDeleted); // prettier-ignore

  return collectionLines;
}

export function* getNextIndex(collectionJobId: string) {
  const collectionLines = yield* call(fetchCollectionLines, collectionJobId);
  const indexArray =
    (collectionLines as any).length > 0
      ? (collectionLines as any).map((l: any) => l.index)
      : [0];

  const maxIndex = Math.max(...indexArray);

  return maxIndex + 1;
}

export function* deleteLine(collectionId: string, collectionLineId: string) {
  if (!collectionLineId) return;

  const { didCancel } = yield dialog.confirm({
    title: 'Remove collection line',
    description: `Are you sure you want to remove this collection line?`,
    ctaButtonText: 'Remove line',
    intention: 'delete',
  });

  if (didCancel) {
    return false;
  }

  yield put(
    actions.collectionLines.patch(collectionLineId, {
      isDeleted: true,
      setId: collectionId,
    })
  );

  const collectionLines = yield* call(fetchCollectionLines, collectionId);

  yield put(
    actions.collectionJobs.patch(collectionId, {
      linesCount: Object.values(collectionLines).length - 1,
    })
  );
  return true;
}

export function* deleteAllLines(collectionId: string) {
  const collectionLines = yield* call(fetchCollectionLines, collectionId);

  const job = yield* select(selectors.singles.$collectionJob, collectionId);

  const { name } = job;

  const { didCancel } = yield dialog.confirm({
    title: 'Remove collection lines',
    description: `Are you sure you want to remove all lines from collection "${name}"?`,
    ctaButtonText: 'Remove all lines',
    intention: 'delete',
  });

  if (didCancel) {
    return false;
  }

  for (const lineIndex in collectionLines) {
    if (Object.prototype.hasOwnProperty.call(collectionLines, lineIndex)) {
      const element = collectionLines[lineIndex];
      yield put(
        actions.collectionLines.patch(element.id, {
          isDeleted: true,
          setId: collectionId,
        })
      );

      yield put(
        actions.collectionJobs.patch(collectionId, {
          linesCount: 0,
        })
      );
    }
  }
  return true;
}
