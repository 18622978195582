import styled from 'styled-components';

export const CountContainer = styled.div`
  display: flex;
  cursor: default;

  svg {
    height: 16px;
    width: 16px;
    margin-top: 1px;
    margin-left: 2px;
  }
`;
