import { Box, Button, Grid, Typography, Icon } from '@ai21/studio-ui';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DOCS_ROUTE, MOBILE_PAGE_MAIN_TEXT } from '../../constants/constants';
import { MOBILE_PAGE_SUB_TEXT } from '../../constants/constants.components';
import { MOBILE_PAGE_SHOWN } from '../../redux/actionTypes/actionTypes';
import { useExperiment } from '@ai21/studio-experiment';

export function MobilePage() {
  const [countDown, setCountDown] = useState(10);
  const dispatch = useDispatch();
  const {
    isLoading,
    values: [enableNewAuthWebapp],
  } = useExperiment('enable-new-auth-webapp');

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      }
      if (countDown <= 1) {
        window.location.href = DOCS_ROUTE;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  useEffect(() => {
    dispatch({
      type: MOBILE_PAGE_SHOWN,
      path: window.location.pathname,
    });
  }, [dispatch]);

  if (isLoading) {
    return <></>;
  }

  if (enableNewAuthWebapp === 'on') {
    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.search;
    window.location.href = `${window.location.origin}/v2/chat${queryParams}`;
    return <></>;
  }

  return (
    <Grid item container direction='column' alignItems='center' style={{ padding: 32 }}>
      <Box marginTop='16px' />
      <Icon iconName='StudioLogo' />
      <Box marginTop='80px' />
      <Icon iconName='Frame2' />
      <Box marginTop='24px' />
      <Grid item>
        <Typography
          variant='subtitle2bold'
          style={{
            color: 'black',
            whiteSpace: 'break-spaces',
            textAlign: 'center',
            fontWeight: 400,
          }}
        >
          {MOBILE_PAGE_MAIN_TEXT}
        </Typography>
      </Grid>
      <Box marginTop='24px' />
      <Grid style={{ textAlign: 'center', whiteSpace: 'break-spaces' }}>
        <Typography variant='subtitle2' color='secondary'>
          {MOBILE_PAGE_SUB_TEXT}
          {` ${countDown} seconds.`}
        </Typography>
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          direction='row'
          style={{ textAlign: 'center', whiteSpace: 'break-spaces', marginTop: 16 }}
        >
          <Box marginTop='32px' />
          <Button
            size='medium'
            color='primary'
            variant='outlined'
            onClick={() => {
              window.location.href = DOCS_ROUTE;
            }}
          >
            Documentation
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
