export function formatBytes(bytes: number | undefined, decimals: number) {
  if (!bytes || bytes === 0) {
    return '0 Bytes';
  }
  const unitMultiple = 1000;
  const unitNames =
    unitMultiple === 1000 // 1000 bytes in 1 Kilobyte (KB) or 1024 bytes for the binary version (KiB)
      ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
  return `${parseFloat((bytes / unitMultiple ** unitChanges).toFixed(decimals || 0))} ${
    unitNames[unitChanges]
  }`;
}
