import { Button, IconButton, Markdown, Typography, layout } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  display: flex;
  height: 153px;

  &.sticky {
    position: fixed;
    top: 55px;
    left: 240px;
    right: 0;
    height: 153px;
    background-color: #ffffff;
    z-index: 2;
    transition: all 0.2s linear;
    transition-delay: 0s;

    &.closed {
      height: 67px;
      background-color: #ffffff;
      box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.03);

      .title {
        margin-top: 6px;
      }

      .subtitle {
        height: 0;
        transition-delay: 0s;
      }

      .cta {
        margin-bottom: 11px;
      }
    }

    &.noBars {
      top: 0;
      left: 0;
    }
  }

  &.back-normal {
    .actions {
      margin-top: 30px;
    }
  }

  &.slim {
    &.sticky {
      left: 60px;
    }
  }

  svg {
    stroke-width: 1.5px;
  }
`;

export const TopBack = styled.div`
  position: fixed;
  top: 9px;
  left: 250px;
  z-index: 1100;
`;

export const Back = styled(IconButton)``;

export const Container = styled(layout.Container)<{ hideBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e7e7e7;
  ${({ hideBorder }) => hideBorder && 'border-bottom: none;'}
`;

export const Column = styled.div`
  flex: 1;
`;

export const Title = styled(Typography)`
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
`;

export const Subtitle = styled(Typography)`
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  transition-delay: 0s;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`;

export const Cta = styled(Button)`
  margin-top: 15px;

  svg {
    width: 24px;
    height: 24px;
  }

  & + & {
    margin-left: 8px;
  }
`;

export const SubtitleRich = styled.div`
  color: ${tokens.text.secondary};

  p {
    padding: 0;
    margin: 0;
    color: ${tokens.text.secondary};
  }

  a {
    color: ${tokens.text.secondary};
    text-decoration: underline;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    margin-left: 8px;
  }
`;
