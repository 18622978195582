export function RewriteApiIcon() {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='42' height='42' rx='4' fill='#F6E9FD' />
      <path
        d='M6.34386 19.1526C6.29002 19.5633 6.57932 19.9399 6.99002 19.9937C7.40072 20.0475 7.7773 19.7582 7.83113 19.3475L6.34386 19.1526ZM8.65926 25.9725C8.50598 25.5877 8.06978 25.4 7.68497 25.5533C7.30016 25.7066 7.11246 26.1428 7.26573 26.5276L8.65926 25.9725ZM6.33749 35.0001C6.33749 35.4143 6.67328 35.7501 7.08749 35.7501C7.50171 35.7501 7.83749 35.4143 7.83749 35.0001H6.33749ZM7.08749 26.2501V25.5001C6.67328 25.5001 6.33749 25.8359 6.33749 26.2501H7.08749ZM15.8375 27.0001C16.2517 27.0001 16.5875 26.6643 16.5875 26.2501C16.5875 25.8359 16.2517 25.5001 15.8375 25.5001V27.0001ZM7.83113 19.3475C8.69764 12.7369 14.3412 7.7997 21.0084 7.81964L21.0129 6.31965C13.5909 6.29745 7.30846 11.7936 6.34386 19.1526L7.83113 19.3475ZM21.0084 7.81964C27.6755 7.83958 33.2895 12.8104 34.1164 19.4261L35.6048 19.2401C34.6843 11.8754 28.4348 6.34184 21.0129 6.31965L21.0084 7.81964ZM34.1164 19.4261C34.9434 26.0418 30.7258 32.2416 24.2687 33.9021L24.6423 35.3548C31.8304 33.5064 36.5254 26.6047 35.6048 19.2401L34.1164 19.4261ZM24.2687 33.9021C17.8116 35.5625 11.1263 32.1665 8.65926 25.9725L7.26573 26.5276C10.0121 33.4227 17.4541 37.2033 24.6423 35.3548L24.2687 33.9021ZM7.83749 35.0001V26.2501H6.33749V35.0001H7.83749ZM7.08749 27.0001H15.8375V25.5001H7.08749V27.0001Z'
        fill='#7B08A9'
      />
    </svg>
  );
}
