import React from 'react';

const GenerationsPage = React.lazy(
  () => import('./generationsPage/GenerationsPage.container')
);

const GenerationLines = React.lazy(
  () => import('./generationLinesPage/GenerationLinesPage.container')
);

export const pages: Record<string, React.FC<any>> = {
  generationSets: GenerationsPage,
  generationLines: GenerationLines,
};
