import { Typography, styled, tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  margin-bottom: 16px;
`;

export const Check = styled.div`
  margin-top: 12px;
`;

export const Label = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  /* text-transform: capitalize; */
  font-size: 14px;
  color: ${tokens.neutral.deepest};
  margin-bottom: 6px;

  span {
    display: flex;
    align-items: center;
  }

  svg {
    height: 14px;
    width: 14px;
    color: ${tokens.text.secondary};
  }
`;
