// const iconStyles = makeStyles(() => ({
//     enabled: {
//         '&:hover': {
//             fill: 'currentColor !important',
//         },
//     },
// }));

export function TrashIcon({
  enabled,
  height = 24,
  width = 24,
}: {
  enabled?: boolean;
  height?: number;
  width?: number;
}) {
  return (
    <svg
      fill='currentColor'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      className={enabled ? '' : ''}
    >
      <path d='M20,6.2h-1c0,0,0,0,0,0h-3.2V4c0-1-0.8-1.8-1.8-1.8h-4C9,2.2,8.2,3,8.2,4v2.2H5c0,0,0,0,0,0H4C3.6,6.2,3.2,6.6,3.2,7S3.6,7.8,4,7.8h0.3L5.3,19c0,1.5,1.2,2.7,2.7,2.7h8c1.5,0,2.7-1.2,2.7-2.7l0.9-11.3H20c0.4,0,0.8-0.3,0.8-0.8S20.4,6.2,20,6.2zM9.8,4c0-0.1,0.1-0.2,0.2-0.2h4c0.1,0,0.2,0.1,0.2,0.2v2.2H9.8V4z M17.3,18.9C17.3,19,17.2,19,17.3,18.9c0,0.8-0.6,1.3-1.3,1.3H8c-0.7,0-1.2-0.6-1.2-1.2c0,0,0,0,0-0.1L5.8,7.8h12.4L17.3,18.9z M10,17.8c0.4,0,0.8-0.3,0.8-0.8v-6c0-0.4-0.3-0.8-0.8-0.8S9.2,10.6,9.2,11v6C9.2,17.4,9.6,17.8,10,17.8z M14,17.8c0.4,0,0.8-0.3,0.8-0.8v-6c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v6C13.2,17.4,13.6,17.8,14,17.8z' />
    </svg>
  );
}
