import { Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { Icon as IconOrg } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  padding: 24px;
  cursor: pointer;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-color: ${tokens.primary.borderDefault};
  max-height: 227px;
  user-select: none;

  &:hover {
    background: ${tokens.primitives.grey[0]};
    border-color: ${tokens.primary.borderHover};
    border-radius: 12px;
    box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.06);
  }

  &:active {
    background: ${tokens.primitives.grey[10]};
    border-color: ${tokens.primary.borderActive};
    box-shadow: none;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Icon = styled(IconOrg)``;

export const Title = styled(Typography)`
  color: ${tokens.text.primary};
`;

export const Subtitle = styled(Typography)`
  margin-bottom: 14px;
  color: ${tokens.text.tertiary};
`;

export const Description = styled(Typography)`
  font-size: 14px;
  color: ${tokens.text.secondary};
`;

/*
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.25px;
  margin-top: 20px;
*/
