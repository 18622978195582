import { upperFirst } from 'lodash';
import { createSelector } from 'reselect';
import {
  MODELS_DISPLAY_NAMES,
  MODELS_GROUPS_DISPLAY_NAMES,
  MODEL_STATUS_ACTIVE,
} from '../constants/constants';
import { PricingInfo } from '../data-types/Payment';
import { TierType } from '../data-types/Tier';
import { UserStatus } from '../data-types/User';
import { RootState } from './store';

function pricingToProductIds(pricingInfo: PricingInfo): string[] {
  const pricePerModel = pricingInfo.modelsPricing.flatMap((v) => [
    v.stripeRequestProductId,
    v.stripeTokenProductId,
    v.stripeTrainingProductId,
  ]);
  const pricePerService = pricingInfo.servicesPricing.flatMap((v) => [
    v.stripeRequestProductId,
    v.stripeCharacterProductId,
  ]);
  return [...pricePerModel, ...pricePerService, pricingInfo.minimumPerBillingCycleProductId];
}

export const getCompletionResponse = (state: RootState) => state._playground.completionResponse;
export const isNewUser = (state: RootState) => state._signin.newUser;
export const getAuthGen = (state: RootState) => state._signin.authGen;
export const getPhoneNumber = (state: RootState) => state._signin.phoneNumber;
export const getEmail = (state: RootState) => state._signin.email;
export const getSignInEmailAuthenticationInProgress = (state: RootState) =>
  state._signin.signInEmailAuthenticationInProgress;
export const getSignInSendSmsInProgress = (state: RootState) =>
  state._signin.signInSendSmsInProgress;
export const getSignInSmsCodeVerificationInProgress = (state: RootState) =>
  state._signin.signInSmsCodeVerificationInProgress;
export const getSignInErrorCode = (state: RootState) => state._signin.errorCode;
export const getSignInErrorMessage = (state: RootState) => state._signin.errorMessage;
export const getApiKey = (state: RootState) => state._user.apiKey;
export const getProductIds = (state: RootState) =>
  state._pricing.pricingInfo && pricingToProductIds(state._pricing.pricingInfo);
export const getTierName = (state: RootState) => state._pricing.pricingInfo?.tier.toString();
export const getMinimumPerMonth = (state: RootState) =>
  state._pricing.pricingInfo?.minimumPerBillingCycle;
export const getPaymentCoupon = (state: RootState) => state._payment.stripeCoupon;
export const getUserTier = (state: RootState) => state._user.userTier ?? TierType.NONE;
export const getUserRole = (state: RootState) => state._user.userRole;
export const getCancelSubscriptionInProgress = (state: RootState) =>
  state._user.cancelSubscriptionInProgress;
export const getMinimumMonthlyPrice = (state: RootState) =>
  state?._user?.billingInfoEndpointResponse?.minimumPrice;
export const getUserStatus = (state: RootState) =>
  state._user.apiKey ? UserStatus.VERIFIED : state._user.userStatus;
export const getCustomPresetsList = (state: RootState) => state._playground.presetsEndpointResponse;
export const getSelectedPresetName = (state: RootState) => state._playground.selectedPresetName;
export const getCurrentPreset = (state: RootState) => state._playground.currentPreset;
export const getSnackbarMessage = (state: RootState) => state._playground.snackbarMessage;
export const getSnackbarSeverity = (state: RootState) => state._playground.snackbarSeverity;
export const getPromptShareText = (state: RootState) => state._playground.promptShareText;
export const getCurrentApi = (state: RootState) => state._playground.currentApi;
export const getShowAlternativeTokens = (state: RootState) =>
  state._playground.controllerParams.showAlternativeTokens;
export const getPerformTypingAnimation = (state: RootState) =>
  state._playground.performTypingAnimation;
export const getPrompt = (state: RootState) => state._playground.controllerParams.prompt;
export const getShowSavePresetDialog = (state: RootState) => state._playground.showSavePresetDialog;
export const getShowSavePresetOnExitDialog = (state: RootState) =>
  state._playground.showSavePresetOnExitDialog;
export const getNextPreset = (state: RootState) => state._playground.nextPreset;
export const getPresetToDelete = (state: RootState) => state._playground.presetToDelete;
export const getUsageInfo = (state: RootState) => state._user.usageEndpointResponse;
export const getSpecializedModelUsageInfo = (state: RootState) =>
  state._user.specializedModelUsageEndpointResponse;
export const getCreditInfo = (state: RootState) => state._user.creditInfoEndpointResponse;
export const getDatasetsList = (state: RootState) => state._datasets.datasetsEndpointResponse;
export const getUploadDatasetsFile = (state: RootState) => state._datasets.uploadDatasetFile;
export const getUploadedDatasetId = (state: RootState) => state._datasets.uploadedDatasetId;
export const getFetchDatasetPreviewInProgress = (state: RootState) =>
  state._datasets.fetchDatasetPreviewInProgress;
export const getCurrentDatasetPreview = (state: RootState) => state._datasets.datasetPreview;
export const getCustomModelsList = (state: RootState) => state._customModels.modelsEndpointResponse;
export const getFetchModelCostInProgress = (state: RootState) =>
  state._customModels.fetchModelCostInProgress;
export const getDatasetsPageState = (state: RootState) => state._datasets.state;
export const getSelectedDatasetName = (state: RootState) => state._customModels.datasetName;
export const getModelCost = (state: RootState) => state._customModels.modelCost;
export const getModelEpochs = (state: RootState) => state._customModels.modelEpochs;
export const getSelectedDatasetId = (state: RootState) => state._customModels.datasetId;
export const getSelectedModelType = (state: RootState) => state._customModels.modelType;
export const getSelectedDatasetSizeBytes = (state: RootState) => state._customModels.sizeBytes;
export const getModelsPageState = (state: RootState) => state._customModels.state;
export const getUploadDatasetName = (state: RootState) => state._datasets.uploadDatasetName;
export const getApproveWhitespaceCorrection = (state: RootState) =>
  state._datasets.approveWhitespaceCorrection;
export const getSelectedColumns = (state: RootState) => state._datasets.selectedColumns;
export const getUploadDatasetColumnsName = (state: RootState) =>
  state._datasets.uploadDatasetColumnsName;
export const getUploadDatasetErrorMessage = (state: RootState) =>
  state._datasets.uploadDatasetErrorDetails;
export const getDatasetFile = (state: RootState) => state._datasets.uploadDatasetFile;
export const getDatasetUrl = (state: RootState) => state._datasets.datasetUrl;
export const getExampleDatasetUrl = (state: RootState) => state._datasets.exampleDatasetUrl;
export const getPaymentMethodInfo = (state: RootState) => state._user.paymentMethodEndpointResponse;
export const getBillingInfo = (state: RootState) => state._user.billingInfoEndpointResponse;
export const getFetchingApiKey = (state: RootState) => state._user.fetchingApiKey;
export const getFeatureFlags = (state: RootState) => state._user.featureFlags;
export const getSubscriptionExpirationDate = (state: RootState) =>
  state._user.subscriptionExpirationDate;
export const getSubscriptionCancellationDate = (state: RootState) =>
  state._user.subscriptionCancellationDate;
export const getInvoiceHistory = (state: RootState) => state._user.invoiceHistoryResponse;
export const getCompletionInProgress = (state: RootState) => state._playground.completionInProgress;
export const isPostPromptShareInProgress = (state: RootState) =>
  state._playground.postPromptShareInProgress;
export const getPromptShareParams = (state: RootState) => state._playground.getPromptShareParams;
export const getPromptShareUrl = (state: RootState) => state._playground.promptShareUrl;
export const getCompletionAnimationInProgress = (state: RootState) =>
  state._playground.completionAnimationInProgress;
export const getLatestInteractionId = (state: RootState) => state._playground.latestInteractionId;
export const getCompletionFailureReason = (state: RootState) =>
  state._playground.completionFailureReason;
export const getTokenClickedParams = (state: RootState) => state._playground.tokenClickedParams;
export const getCursorPosition = (state: RootState) => state._playground.cursorPosition;
export const getSignInStage = (state: RootState) => state._signin.signInStage;
export const getIsPwResetInProgress = (state: RootState) => state._signin.passwordResetInProgress;
export const getPwResetErrorCode = (state: RootState) => state._signin.passwordResetErrorCode;
export const getPwResetErrorMessage = (state: RootState) => state._signin.passwordResetErrorMessage;
export const getSignInMethod = (state: RootState) => state._signin.signInMethod;
export const getJoinedFromInvite = (state: RootState) => state._signin.joinedFromInvite;
export const getSignInType = (state: RootState) => state._signin.signInType;
export const getShowDeleteDatasetDialog = (state: RootState) =>
  state._datasets.showDeleteDatasetDialog;
export const getShowDeleteModelDialog = (state: RootState) =>
  state._customModels.showDeleteModelDialog;
export const postFeedBackInProgress = (state: RootState) => state._user.postFeedBackInProgress;

// controller params
export const getControllerParams = (state: RootState) => state._playground.controllerParams;
export const getControllerParamsModel = (state: RootState) =>
  state._playground?.controllerParams?.model;

// tokenization
export const getCurrentTokenCount = (state: RootState) => state._playground.currentTokenCount;
export const getTokenizationInProgress = (state: RootState) =>
  state._playground.tokenizationInProgress;

// payment
export const getPaymentInProgress = (state: RootState) => state._payment.inProgress;
export const getPaymentError = (state: RootState) => state._payment.error;
export const getPaymentSnackbarMessage = (state: RootState) => state._payment.snackbarMessage;
export const getPaymentSnackbarSeverity = (state: RootState) => state._payment.snackbarSeverity;
// export const getPaymentPlan = (state: RootState) => state._payment.paymentPlan;

// organization
export const getOrganizationDetails = (state: RootState) => state._user.organizationDetailsResponse;
export const getOrganizationUsersDetailsResponse = (state: RootState) =>
  state._user.organizationUserDetailsResponse;
export const getOrganizationSnackbarMessage = (state: RootState) => state._user.snackbarMessage;
export const getOrganizationSnackbarSeverity = (state: RootState) => state._user.snackbarSeverity;
export const getOpenInviteUserDialog = (state: RootState) =>
  state._organization.inviteUserDialogOpen;
export const getOpenManagePlanDialog = (state: RootState) =>
  state._organization.managePlanDialogOpen;
export const getOpenCancelPlanDialog = (state: RootState) =>
  state._organization.cancelPlanDialogOpen;
export const getCurrentTab = (state: RootState) => state._organization.currentTab;

// Pricing
export const getPricingInfo = (state: RootState) => state._pricing.pricingInfo;
export const getModelPricing = (state: RootState) => state._pricing.pricingInfo?.modelsPricing;

// Overview
export const getClickedPresetLabels = (state: RootState) => state._overview.clickedPresetLabels;
export const getPresetSearchValue = (state: RootState) => state._overview.presetSearchValue;

// reselect
export const $models = createSelector(getCustomModelsList, (modelsList) => {
  const generalModels = Object.keys(MODELS_DISPLAY_NAMES).map((modelId) => ({
    id: modelId,
    label: MODELS_DISPLAY_NAMES[modelId],
    groupId: 'foundation',
    customModelType: undefined,
    groupLabel: MODELS_GROUPS_DISPLAY_NAMES.foundation,
  }));

  const customModels = (modelsList ?? [])
    .filter((m) => m.status === MODEL_STATUS_ACTIVE)
    .map((m) => ({
      id: m.id,
      label: m.name,
      groupId: 'custom',
      customModelType: m.customModelType,
      groupLabel: MODELS_GROUPS_DISPLAY_NAMES.custom,
    }));

  return [...customModels, ...generalModels];
});

export const $currentModel = createSelector(
  getControllerParams,
  $models,
  (controllerParams, models) => {
    const id = controllerParams.model;

    const defaultOptions = {
      id: 'j2-mid',
      label: 'j2-mid',
      groupId: 'foundation',
      groupLabel: MODELS_GROUPS_DISPLAY_NAMES.foundation,
    };

    return models.find((m) => m.id === id) ?? models.find((m) => m.id === id) ?? defaultOptions;
  }
);

export const getValidSpecializedModelUsageInfo = (state: RootState) =>
  (state._user.specializedModelUsageEndpointResponse ?? []).filter(
    (model) => model.displayName !== undefined
  );

export const $nextBilling = createSelector(getBillingInfo, (billingInfo) => {
  if (!billingInfo?.upcomingBilling) {
    return null;
  }

  return {
    amount: billingInfo.upcomingBilling.amount,
    date: billingInfo.upcomingBilling.date * 1000,
  };
});
getInvoiceHistory;

export const $invoicesTable = createSelector(getInvoiceHistory, (invoiceHistory) => {
  return (invoiceHistory ?? []).map((invoiceInfo) => {
    return {
      id: invoiceInfo.invoiceId,
      invoiceNumber: invoiceInfo.invoiceId,
      invoiceStatus: upperFirst(invoiceInfo.status),
      amount: invoiceInfo.amount / 100,
      created: invoiceInfo.periodStart * 1000,
      pdf: invoiceInfo.pdf,
    };
  });
});

export const $invoice = createSelector(
  [$invoicesTable, (_state, id) => id],
  (invoices, id: string) => {
    return invoices.find((invoice) => invoice.id === id);
  }
);

export const $usersTable = createSelector(getInvoiceHistory, (invoiceHistory) => {
  return (invoiceHistory ?? []).map((invoiceInfo) => {
    return {
      id: invoiceInfo.invoiceId,
      invoiceNumber: invoiceInfo.invoiceId,
      invoiceStatus: upperFirst(invoiceInfo.status),
      amount: invoiceInfo.amount / 100,
      created: invoiceInfo.periodStart * 1000,
      pdf: invoiceInfo.pdf,
    };
  });
});
