import { api, generateCode } from '@ai21/studio-api';
import {
  IBox,
  selectors,
  IUser,
  isFeatureFlagEnabled,
} from '@ai21/studio-store';
import { prompt as dialog, toast } from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, delay, select } from 'saga-ts';
import { guid4, invokeEvent } from 'shared-base';
import { patchBox } from './helpers/boxes';
import {
  addMessage,
  clearMessages,
  generateMessage,
  hideAllFeedbacks,
  setMessages,
  updateMessage,
  ChatActionId,
} from './helpers/chat';
import { ActionPlay } from './saga.play';
import { CustomModelType } from '../../../../web/src/data-types/Model';
import { IChatMessage } from '@ai21/studio-editors/dist/dts/components/ChatEditor/ChatEditor.types';

export type Verb =
  | 'generateChat'
  | 'addAssistantMessage'
  | 'feedbackScoreChat'
  | 'feedbackTagsChat'
  | 'jsonChange'
  | 'chatUndo'
  | 'chatRedo'
  | 'chatClear'
  | 'chatReset'
  | 'chatCode'
  | 'chatCopy'
  | 'messageEdit'
  | 'messageRegenerate'
  | 'messageCopy'
  | 'messageDelete';

export const mapVerbToSaga: Record<Verb, any> = {
  feedbackScoreChat: feedbackScoreChat,
  feedbackTagsChat: feedbackTagsChat,
  generateChat: generateChat,
  addAssistantMessage: addAssistantMessage,
  jsonChange: jsonChange,
  chatClear: chatClear,
  chatReset: chatReset,
  chatCode: chatCode,
  chatCopy: chatCopy,
  chatRedo: chatRedo,
  chatUndo: chatUndo,
  messageEdit: messageEdit,
  messageRegenerate: messageRegenerate,
  messageCopy: messageCopy,
  messageDelete: messageDelete,
};

export function* addAssistantMessage(action: ActionPlay, box: IBox) {
  const prompt = get(action, 'params.prompt', '');
  yield call(addMessage, box, { text: prompt, role: 'assistant' });
}

export function* generateChat(action: ActionPlay, box: IBox) {
  const { values } = box;
  const prompt = get(action, 'params.prompt', '');
  const editorBox = yield* select(selectors.playground.$editorBox);

  const user: IUser = yield* select(selectors.raw.$rawUser);
  const isJambaChatEnabled: boolean = isFeatureFlagEnabled(
    user,
    'isJambaChatEnabled'
  );

  const { messages = [] } = values;

  if (!prompt || !editorBox) {
    return;
  }

  yield call(addMessage, box, { text: prompt });

  yield* call(patchBox, box, { messages, isWaiting: true });
  yield* call(patchBox, editorBox, {
    text: JSON.stringify(messages, null, 2),
  });

  const tsStart = Date.now();

  const response = yield* call(generateMessage, messages);

  if (!response.isSuccess) {
    const errorDetail = get(
      response,
      'data.detail',
      'Something went wrong check your internet connection and try again'
    );
    yield* call(patchBox, box, {
      alertData: {
        title: errorDetail,
        type: 'error',
      },
      isWaiting: false,
    });
    return;
  }

  if (isJambaChatEnabled) {
    const completionId = response?.data?.id;
    const { finish_reason = '' } = response?.data?.choices[0];
    const { content = '', role = '' } =
      response?.data?.choices[0]?.message || {};

    yield call(addMessage, box, {
      text: content,
      role,
      completionId,
      finishReason: finish_reason,
    });
  } else {
    const newMessage = get(response, 'data.outputs[0]', {});
    const completionId = get(response, 'data.id', '');
    const { text, role, finishReason } = newMessage;

    yield call(addMessage, box, {
      text,
      role,
      completionId,
      finishReason,
    });
  }

  yield* call(patchBox, box, {
    alertData: null,
    isWaiting: false,
  });
}

export function* jsonChange(action: ActionPlay, box: IBox) {
  const content = get(action, 'params.content', '');
  const chatBox = yield* select(selectors.playground.$chatBox);

  if (!chatBox) {
    return;
  }

  try {
    const json = JSON.parse(content);
    yield* call(setMessages, chatBox, json);
  } catch (err) {
    toast.show('Invalid JSON', 'error');
  }
  yield delay(500);

  yield call(hideAllFeedbacks, 0, action.verb as ChatActionId);
}

export function* feedbackScoreChat(action: ActionPlay, box: IBox) {
  const { messageId, score } = action.params ?? {};
  const messages = get(box, 'values.messages', []);
  const model = get(box, 'values.modelId', '');
  const message = messages.find((m: any) => m.id === messageId);

  if (!message) {
    return;
  }

  const { completionId } = message;

  if (!messageId || score === undefined || !completionId) {
    return;
  }

  const response: any = yield* call(api.feedback.send, {
    requestId: completionId,
    score,
    model,
  });

  if (!response.isSuccess) {
    console.error('feedback', 'something went wrong, cannot send feedback');
  }

  yield* call(updateMessage, box, messageId, { feedbackScore: score }, false);
}

export function* feedbackTagsChat(action: ActionPlay, box: IBox) {
  const { messageId, tags } = action.params ?? {};
  const messages = get(box, 'values.messages', []);
  const model = get(box, 'values.modelId', '');
  const message = messages.find((m: any) => m.id === messageId);
  const { feedbackScore, completionId } = message;

  if (!message || !tags || feedbackScore === undefined || !completionId) {
    return;
  }

  const response: any = yield* call(api.feedback.send, {
    requestId: completionId,
    score: feedbackScore,
    reasons: tags,
    model,
  });

  if (!response.isSuccess) {
    console.error('feedback', 'something went wrong, cannot send feedback');
  }
  const feedbackMap: any = get(box, 'values.feedbackMap', {});
  feedbackMap[messageId as string] = false;

  yield call(patchBox, box, {
    feedbackMap,
  });
}

export function* chatUndo(action: ActionPlay, box: IBox) {
  invokeEvent('editor/undo', {});
}

export function* chatRedo(action: ActionPlay, box: IBox) {
  invokeEvent('editor/redo', {});
}

export function* chatClear(_action: ActionPlay, _box: IBox) {
  const chatBox = yield* select(selectors.playground.$chatBox);
  yield call(clearMessages, chatBox);
  const editorBox = yield* select(selectors.playground.$chatBox);
  yield call(clearMessages, editorBox);
}

export function* chatReset(_action: ActionPlay, _box: IBox) {
  const paramsBox = yield* select(selectors.playground.$paramsBox);

  const defaultParams = {
    hideSelectors: true,
    outputId: '1',
    modelId: 'jamba-instruct-preview',
    content: '',
    maxTokens: 1024,
    temperature: 0.7,
    topP: 1,
    stopSequences: ['==='],
    countPenalty: 1,
  };

  if (paramsBox) {
    yield* call(patchBox, paramsBox, { ...defaultParams });
  }

  const instructionsBox = yield* select(selectors.playground.$instructionBox);

  yield* call(patchBox, instructionsBox, { content: '' });

  yield call(chatClear, _action, _box);
}

export function* chatCode(action: ActionPlay, box: IBox) {
  const chatBox = yield* select(selectors.playground.$chatBox);
  const messages = get(chatBox, 'values.messages', []);
  const instructionsText = yield* select(selectors.playground.$instructions);
  const paramsBox = yield* select(selectors.playground.$paramsBox);
  const user: IUser = yield* select(selectors.raw.$rawUser);
  const isJambaChatEnabled: boolean = isFeatureFlagEnabled(
    user,
    'isJambaChatEnabled'
  );

  const { values } = paramsBox || {};
  const newMessages = messages.map((message: IChatMessage) => {
    return { content: message.text, role: message.role };
  });

  const systemMessage =
    isJambaChatEnabled && instructionsText
      ? { content: instructionsText, role: 'system' }
      : null;

  const code = generateCode({
    flavour: 'chat',
    system: isJambaChatEnabled ? null : instructionsText,
    messages: JSON.stringify(
      systemMessage ? [systemMessage, ...newMessages] : newMessages,
      null,
      2
    ),
    controllerParams: values,
    customModelType: '',
    modelName: CustomModelType.JAMBA_INSTRUCT,
    context: 'playground',
  });

  dialog.code({
    title: 'API call',
    flavour: 'chat',
    code,
  });
}

export function* chatCopy(action: ActionPlay, box: IBox) {
  const chatBox = yield* select(selectors.playground.$chatBox);
  const { values } = chatBox;
  const { messages = [] } = values;

  const content = JSON.stringify(messages, null, 2);

  navigator.clipboard.writeText(content);

  toast.show('Copied to clipboard');
}

export function* messageEdit(action: ActionPlay, box: IBox) {
  const { values } = box;
  const message = get(action, 'params.message', {});
  const { messages = [] } = values;
  const messageIndex = messages.indexOf(message);

  if (!message) {
    return;
  }

  const { text, id: messageId } = message;

  const { value, didCancel } = yield dialog.input({
    title: 'Edit message',
    ctaButtonText: 'Save Changes',
    defaultValue: text,
    allowNewLine: true,
    intention: 'edit',
    rows: 4,
  });

  if (didCancel || text === value) {
    return;
  }

  yield call(updateMessage, box, messageId, {
    text: value,
  });

  yield delay(500);

  yield call(hideAllFeedbacks, messageIndex, action.verb as ChatActionId);
}

export function* messageRegenerate(action: ActionPlay, box: IBox) {
  const { values } = box;
  const message = get(action, 'params.message', {});
  const { messages = [] } = values;
  const { id: messageId } = message;

  const user: IUser = yield* select(selectors.raw.$rawUser);
  const isJambaChatEnabled: boolean = isFeatureFlagEnabled(
    user,
    'isJambaChatEnabled'
  );

  if (!message || !messages) {
    return;
  }
  yield call(updateMessage, box, messageId, {
    isLoading: true,
  });

  const messageIndex = messages.indexOf(message);
  const newMessages = messages.slice(0, messageIndex);

  const response = yield* call(generateMessage, newMessages);

  if (!response.isSuccess) {
    yield call(updateMessage, box, messageId, {
      isLoading: false,
    });
    return;
  }
  const newMessage = get(
    response,
    isJambaChatEnabled ? 'data.choices[0]' : 'data.outputs[0]',
    {}
  );
  const completionId = get(response, 'data.id', '');

  const content = get(
    newMessage,
    isJambaChatEnabled ? 'message.content' : 'text',
    ''
  );
  const role = get(
    newMessage,
    isJambaChatEnabled ? 'message.role' : 'role',
    ''
  );

  yield call(updateMessage, box, messageId, {
    id: guid4(),
    text: content,
    role,
    completionId,
    isLoading: false,
  });
  yield delay(500);

  yield call(hideAllFeedbacks, messageIndex, action.verb as ChatActionId);
}

export function* messageCopy(action: ActionPlay, box: IBox) {
  const text = get(action, 'params.message.text', '');

  navigator.clipboard.writeText(text);

  toast.show('Copied to clipboard');
}

export function* messageDelete(action: ActionPlay, box: IBox) {
  const messageId = get(action, 'params.message.id', {});
  const messages = get(box, 'values.messages', []);

  if (!messageId || !messages) {
    return;
  }

  const newMessages = messages.filter((m: any) => m.id !== messageId);

  yield call(setMessages, box, newMessages);
}
