import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  height: 26px;
  float: left;

  .icon-wrapper {
    margin-right: 2px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.active,
  &.green {
    background-color: #f0fdf3;
    color: #13682c;

    svg {
      fill: #13682c;
    }
  }

  &.discontinued,
  &.yellow {
    background-color: #fefce8;
    color: #a46004;

    svg {
      fill: #a46004;
      path {
        fill: #a46004;
      }
    }
  }

  &.in_training,
  &.pending,
  &.blue {
    background-color: #edf7ff;
    color: #1d3eb6;

    svg {
      color: #1d3eb6;
      stroke-width: 1.5px;
    }
  }

  &.purple {
    background-color: #f4f3ff;
    color: #652bd6;

    svg {
      color: #652bd6;
      stroke-width: 1.5px;
    }
  }

  &.gray {
    background-color: #f4f5f7;
    color: #484d56;

    svg {
      color: #484d56;
      stroke-width: 1.5px;
    }
  }

  &.red {
    background-color: #fff1f0;
    color: #d20f03;

    svg {
      color: #d20f03;
      stroke-width: 1.5px;
    }
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: #f3e7c3;
      text-decoration: underline;
    }
  }
`;

export const StatusText = styled.div<{ clickable?: boolean }>`
  float: left;
  text-decoration: ${(props) => (props.clickable ? 'underline' : 'none')};
  white-space: nowrap;
  font-weight: 500;
`;
