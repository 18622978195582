interface RocketIconProps {
  disabled?: boolean;
}

export function RocketIcon({ disabled = false }: RocketIconProps) {
  return (
    <svg width='24' height='24' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7194 2.43833C12.7296 2.43778 12.7398 2.4375 12.75 2.4375C14.3033 2.4375 15.5625 3.6967 15.5625 5.25C15.5625 5.26021 15.5623 5.27043 15.5617 5.28062C15.4075 8.10907 13.636 10.5818 11.0354 11.6479C10.8621 13.2463 9.93688 14.6768 8.53098 15.4873C8.36719 15.5817 8.16692 15.5876 7.99791 15.5028C7.82891 15.4181 7.71377 15.2541 7.69146 15.0664C7.64152 14.6462 7.54419 14.2409 7.40492 13.8561C6.30707 15.1214 4.63211 15.7799 2.92736 15.5578C2.67434 15.5248 2.4752 15.3257 2.44224 15.0727C2.22014 13.3679 2.87859 11.693 4.1439 10.5951C3.7591 10.4558 3.35386 10.3585 2.93365 10.3086C2.74591 10.2863 2.58194 10.1711 2.4972 10.0021C2.41246 9.83312 2.41829 9.63285 2.51272 9.46906C3.32324 8.06315 4.75377 7.13789 6.35213 6.96459C7.41822 4.36404 9.89097 2.59255 12.7194 2.43833ZM5.26216 11.1531C4.15742 11.897 3.50577 13.1495 3.52511 14.4749C4.85053 14.4943 6.10308 13.8426 6.84691 12.7379C6.43442 12.1059 5.89415 11.5656 5.26216 11.1531ZM12.7646 3.56256C10.2725 3.70489 8.10934 5.331 7.28066 7.68667C7.2037 7.90543 7.00038 8.05454 6.7686 8.06219C5.68653 8.09793 4.68018 8.57617 3.97152 9.36374C6.24898 9.96536 8.03467 11.751 8.63629 14.0285C9.42386 13.3199 9.9021 12.3135 9.93784 11.2314C9.94549 10.9997 10.0946 10.7963 10.3134 10.7194C12.669 9.89069 14.2951 7.72748 14.4375 5.23542C14.4297 4.31501 13.685 3.57035 12.7646 3.56256ZM11.25 6.5625C11.1465 6.5625 11.0625 6.64645 11.0625 6.75C11.0625 6.85355 11.1465 6.9375 11.25 6.9375C11.3536 6.9375 11.4375 6.85355 11.4375 6.75C11.4375 6.64645 11.3536 6.5625 11.25 6.5625ZM9.93753 6.75C9.93753 6.02513 10.5252 5.4375 11.25 5.4375C11.9749 5.4375 12.5625 6.02513 12.5625 6.75C12.5625 7.47487 11.9749 8.0625 11.25 8.0625C10.5252 8.0625 9.93753 7.47487 9.93753 6.75Z'
        fill={disabled ? 'black' : 'url(#paint0_linear_783_36390)'}
        fillOpacity={disabled ? '0.38' : '1'}
      />
      <defs>
        {!disabled && (
          <linearGradient
            id='paint0_linear_783_36390'
            x1='8.9811'
            y1='2.4375'
            x2='17.0295'
            y2='16.3775'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#1D1E22' />
            <stop offset='1' stopColor='#1D1E22' />
          </linearGradient>
        )}
      </defs>
    </svg>
  );
}
