import { ButtonMenu, IconButton } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Actions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const Editor = styled.div`
  flex: 2;
  display: flex;
`;

export const Duration = styled.div`
  padding-left: 15px;
  position: absolute;
  bottom: 65px;
  right: 10px;
  opacity: 0.3;
`;

export const Undo = styled(IconButton)`
  margin-right: 5px;
`;

export const Menu = styled(ButtonMenu)`
  margin-left: 5px;

  .MuiButton-root {
    border: none;
    padding: 6px;
  }
`;
