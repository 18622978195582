import { initAnalytics } from '@ai21/studio-analytics';
import { initApi } from '@ai21/studio-api';
import { authRoutes, authStageRoutes, initAuth } from '@ai21/studio-auth';
import { analytics } from './_definitions/analytics';
import { externalRoutes, privateRoutes, publicRoutes } from './bootstrap/Web.routes';
import { config } from './main.firebase';
import './main.scss';
import { store } from './redux/store';
import { navigate } from './sagas/saga.navigation';

export const init = () => {
  const identityBaseUrl = (window.__env__.VITE_IDENTITY_SERVER_URL ?? '') + '/identity';
  const studioApiBaseUrl = (window.__env__.VITE_API_SERVER_URL ?? '') + '/studio/v1';
  const emailCaptcha = window.__env__.VITE_CAPTCHA_EMAIL === 'true';
  const amplitudeApiKey = window.__env__.VITE_AMPLITUDE || '';
  const analyticsEnabled = window.__env__.VITE_ANALYTICS_ENABLED === 'true';

  const firebaseApp = initAuth({
    firebaseConfig: config,
    defaultRoute: '/v2/chat',
    routes: {
      publicRoutes,
      privateRoutes,
      externalRoutes,
      authRoutes,
      authStageRoutes,
    },
    navigate,
    logEnabled: true,
    captcha: {
      email: emailCaptcha,
      providers: false,
    },
    store,
  });

  initApi({
    identityBaseUrl,
    studioApiBaseUrl,
    app: firebaseApp,
  });

  initAnalytics({
    ...analytics,
    apiKey: amplitudeApiKey,
    enabled: analyticsEnabled,
  });
};
