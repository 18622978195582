import { Json } from '../../types';

export const initialLogs: Json[] = [
  { id: 'PageEntered', title: 'PageEntered', count: 0, events: [], isAuto: true },
  { id: 'PageExited', title: 'PageExited', count: 0, events: [], isAuto: true },
  { id: 'NavigatorPressed', title: 'NavigatorPressed', count: 0, events: [], isAuto: true },
  { id: 'ButtonPressed', title: 'ButtonPressed', count: 0, events: [], isAuto: true },
  { id: 'Table', title: 'Table', count: 0, events: [], isAuto: true },
  { id: 'Dialog', title: 'Dialog', count: 0, events: [], isAuto: true },
  { id: 'ApiRequest', title: 'ApiRequest', count: 0, events: [], isAuto: true },
  { id: 'ApiError', title: 'ApiError', count: 0, events: [], isAuto: true },
  { id: 'ApiSuccess', title: 'ApiSuccess', count: 0, events: [], isAuto: true },
  { id: 'Auth', title: 'Auth', count: 0, events: [], isAuto: true },
];
