import { useEffect } from 'react';

export function useMobile() {
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (isMobile) {
      document.body.classList.add('mobile');
    } else {
      document.body.classList.remove('mobile');
    }
  }, [isMobile]);

  return isMobile;
}
