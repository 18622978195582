import { ILayoutId } from '../../components/BoxPlayground/BoxPlayground.types';
import ape from './json/layout.ape.json';
import complete1_1 from './json/layout.complete1-1.json';
import complete1_2 from './json/layout.complete1-2.json';
import complete1_3 from './json/layout.complete1-3.json';
import completeExp from './json/layout.complete-exp.json';
import completeAlt from './json/layout.complete-alternative.json';
import chat1 from './json/layout.chat-1.json';
import chatHistory from './json/layout.chat-history.json';
import legoAnswer from './json/layout.lego.answer.json';
import legoGec from './json/layout.lego.gec.json';
import legoParaphrase from './json/layout.lego.paraphrase.json';
import legoSummarize from './json/layout.lego.summarize.json';
import legoTextImprovements from './json/layout.lego.textImprovements.json';

export const allLayouts: Record<ILayoutId, any> = {
  complete1: complete1_1,
  complete2: complete1_2,
  complete3: complete1_3,
  completeExp: completeExp,
  completeAlt: completeAlt,
  chat1: chat1,
  chatHistory: chatHistory,
  ape: ape,
  paraphrase: legoParaphrase,
  summarize: legoSummarize,
  gec: legoGec,
  textImprovements: legoTextImprovements,
  answer: legoAnswer,
};
