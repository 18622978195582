import React from 'react';
import { Left, Logo, Right, Wrapper } from './Page.style';
import classnames from 'classnames';
import { Icon } from '@ai21/studio-base-ui';

export type PageProps = {
  children: React.ReactNode;
  className?: string;
};

export function Page(props: PageProps) {
  const className = classnames('Page-wrapper', props.className, {});

  return (
    <Wrapper className={className} data-testid='Page-wrapper'>
      <Left>{props.children}</Left>
      {/* <Right></Right> */}
      <Logo>{/* <Icon iconName='Logo' /> */}</Logo>
    </Wrapper>
  );
}

export default Page;
