import { createSelector } from 'reselect';
import { IStudioStore } from '../types';
import * as raw from './selectors.raw';
import { sortBy } from 'shared-base';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $evaluationLines = createSelector(
  [raw.$rawEvaluationLines, (_state, setId) => setId],
  (lines, setId: string) => {
    return Object.values(lines)
      .filter((line) => line.setId === setId)
      .map((line) => {
        return {
          index: line.index,
          quality: toQuality(line.quality?.value),
          labels: (line.quality?.tags ?? []).join('|'),
          input: line.input,
          output: line.output,
          evaluator: line.evaluator,
          comment: line.comment,
          model: line.modelName,
          temp: line.temperature,
          topP: line.topP,
          maxTokens: line.maxTokens,
          timeStamp: toDateAndTimeString(line.dateCreated),
        };
      })
      .sort(sortBy('index'));
  }
);

export const toDateAndTimeString = (date?: string | number | Date) => {
  if (!date) {
    return '';
  }

  const d = new Date(date);
  return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
};

export const lz = (n: number) => {
  return n < 10 ? `0${n}` : n;
};

export const toQuality = (quality?: number) => {
  if (quality === undefined) {
    return 'N/A';
  }

  switch (quality) {
    case 0:
      return 'Bad';
    case 5:
      return 'Okay';
    case 10:
      return 'Great';
    default:
      return 'N/A';
  }
};
