import { A, Typography } from '@ai21/studio-base-ui';
import { useState } from 'react';
import { delay } from 'shared-base';
import styled from 'styled-components';
import { store } from '../../utils/globals';
import { AuthMessage } from './AuthMessage';

export type CheckEmailProps = {};

let delayIndex = 1;

export function CheckEmail(_props: CheckEmailProps) {
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);

  async function onClick() {
    setIsResendButtonDisabled(true);

    store.dispatch({
      type: 'RESEND_VERIFICATION_EMAIL',
    });

    await delay(3000 * delayIndex);
    delayIndex += 1;

    setIsResendButtonDisabled(false);
  }

  return (
    <AuthMessage
      title='Verify your email'
      message="We've sent you an email with a link to verify your account. Please open your email client and click the link to continue."
    >
      <ResendLink>
        Haven't received it?
        <Link
          data-testid='link-resend-email'
          disabled={isResendButtonDisabled}
          onClick={onClick}
          button
        >
          Resend link
        </Link>
      </ResendLink>
    </AuthMessage>
  );
}

export const ResendLink = styled(Typography)`
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
`;

export const Link = styled(A)`
  margin-left: 7px;
  font-size: 14px;
  cursor: pointer;
`;

export default CheckEmail;
