import { ToastFlavour } from '../../types';
import { toast as toastify, ToastOptions } from 'react-toastify';

type Options = {
  promise?: Promise<any>;
  delay?: number;
};

export function showToast(
  message: string | string[],
  flavour?: ToastFlavour,
  options?: Options
): any {
  const toastOptions: ToastOptions = {
    theme: 'dark',
  };

  switch (flavour) {
    case 'error':
      toastify.error(message, toastOptions);
      break;

    case 'warning':
      toastify.warn(message, toastOptions);
      break;
    case 'promise':
      let { promise, delay } = options ?? {};

      if (!promise) {
        promise = createPromise(delay ?? 1000);
      }

      if (!promise) {
        return;
      }
      toastify.promise(
        promise,
        {
          pending: message[0],
          success: message[1],
          error: message[2],
        },
        toastOptions
      );
      break;
    case 'info':
      toastify.info(message, toastOptions);
      break;

    case 'custom':
      toastify(message, toastOptions);
      break;
    case 'success':
    default:
      toastify.success(message, toastOptions);
      break;
  }
}

const createPromise = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, delay);
  });
};

export const toast = {
  show: showToast,
};
