import { createSelector } from 'reselect';
import { ISheetCell, ISheetCells, IStudioStore } from '../types';
import * as raw from './selectors.raw';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $sheetCells = createSelector(raw.$rawSheetsCells, (sheetCells) => {
  return Object.values(sheetCells).map((cell) => {
    const { id } = cell;

    const coord = getCoord(id);

    return { ...cell, ...coord };
  });
});

export const $selectedCellContent = createSelector(
  raw.$rawSheetsCells,
  raw.$rawCurrentIds,
  (sheetCells, currentIds) => {
    const { cellId } = currentIds;

    if (!cellId) {
      return;
    }

    return sheetCells[cellId]?.value;
  }
);

export const $selectedCellInfo = createSelector(
  raw.$rawSheetsCells,
  raw.$rawCurrentIds,
  (sheetCells, currentIds) => {
    const { cellId } = currentIds;

    if (!cellId) {
      return null;
    }

    const coord = getCoord(cellId);
    const cell = sheetCells[cellId];

    if (cell?.cellType !== 'value') {
      return null;
    }

    return {
      prompt: getCellValue(sheetCells, coord.y, 1),
      modelName: getCellValue(sheetCells, 1, coord.x),
      temperature: getCellValue(sheetCells, 2, coord.x),
      topP: getCellValue(sheetCells, 3, coord.x),
      maxTokens: getCellValue(sheetCells, 4, coord.x),
    };
  }
);

export const getCellValue = (cells: ISheetCells, y: number, x: number) => {
  const cell = cells[`${y}-${x}`];

  if (!cell) {
    return '';
  }

  return cell.value;
};

export const getCoord = (id: string) => {
  const parts = id.split('-');

  const x = parseInt(parts[1], 10);
  const y = parseInt(parts[0], 10);

  return { x, y };
};
