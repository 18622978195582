import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  width: 760px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12), 0px 6px 8px rgba(0, 0, 0, 0.14),
    0px 3px 6px -1px rgba(0, 0, 0, 0.2);

  &.demo {
    box-shadow: none;
    height: 100%;
    margin: auto;
    margin-bottom: 140px;

    @media (max-width: 1000px) {
      margin-bottom: 70px;
    }

    .MuiOutlinedInput-root {
      fieldset {
        border-color: #334;
      }

      &:hover fieldset {
        border-color: #334;
      }

      &.mui-focused fieldset {
        border-color: #334;
      }
    }

    .AskTopBar-wrapper {
      display: none;
    }

    .AskContent-wrapper {
      overflow: hidden;

      .AskContent-flex {
        min-height: calc(100vh - 320px);
      }
    }

    .AskBottomBar-wrapper {
      width: 720px;
    }
  }

  @media (max-width: 1000px) {
    width: 100vw;

    .AskBottomBar-wrapper {
      width: 100vw;
    }
  }
`;

export const EmptyBar = styled.div`
  flex: 1;
  padding: 0 10px;
  max-height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e9e9e9;
`;
