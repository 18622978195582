export function LeftWireIcon() {
  return (
    <svg width='116' height='47' viewBox='0 0 115 47'>
      <g>
        <path
          fill='#A0A2A8'
          stroke='#A0A2A8'
          d='M141.002 54.115a2.5 2.5 0 11-5.001.003 2.5 2.5 0 015-.003zm0 0'
          transform='scale(.80986 .81034)'
        />
        <path
          fill='none'
          stroke='#A0A2A8'
          d='M138.498 53.739H42.721V32.72C42.72 15.204 28.52.998 11.002.998h-27.001'
          transform='scale(.80986 .81034)'
        />
        <path fill='#753FEA' d='M72.273 51.848h-4.406V37.41h4.406zm0 0' />
        <path fill='#753FEA' d='M71.07 37.41h4.41v14.438h-4.41zm0 0' />
        <path
          fill='#ED4B82'
          d='M68.266 51.848h-4.41V37.41h4.41zm6.812-14.438h4.41v14.438h-4.41zm0 0'
        />
        <path
          fill='#F8B5CC'
          d='M64.258 51.848H60.25V37.41h4.008zM79.086 37.41h4.008v14.438h-4.008zm0 0'
        />
        <path
          fill='#FDEBF1'
          d='M60.652 51.852h-4.41V37.414h4.41zm22.04-14.442h4.41v14.438h-4.41zm0 0'
        />
        <path
          fill='#FFF9FB'
          d='M56.645 51.852h-4.41V37.414h4.41zM86.699 37.41h4.41v14.438H86.7zm0 0'
        />
        <path
          fill='none'
          stroke='#A0A2A8'
          d='M114.977 56.805h42.06a2.72 2.72 0 012.72 2.723 2.72 2.72 0 01-2.72 2.724h-42.06a2.725 2.725 0 110-5.447zm0 0'
          transform='matrix(-.80986 0 0 -.81034 181.818 92.067)'
        />
      </g>
    </svg>
  );
}
