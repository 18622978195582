export function FoundationModelIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='8' transform='matrix(1 0 0 -1 0 52)' fill='#DBD7FD' />
      <rect
        width='52'
        height='52'
        rx='8'
        transform='matrix(1 0 0 -1 0 52)'
        fill='url(#paint0_linear_1874_719)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.5097 13.1284C25.8142 12.9572 26.1859 12.9572 26.4903 13.1284L37.1569 19.1284C37.4718 19.3055 37.6667 19.6387 37.6667 20V32C37.6667 32.3613 37.4718 32.6945 37.1569 32.8716L26.4903 38.8716C26.1859 39.0428 25.8142 39.0428 25.5097 38.8716L14.8431 32.8716C14.5282 32.6945 14.3333 32.3613 14.3333 32V20C14.3333 19.6387 14.5282 19.3055 14.8431 19.1284L25.5097 13.1284ZM16.3333 21.7098L25 26.5848V36.2902L16.3333 31.4152V21.7098ZM27 36.2902L35.6667 31.4152V21.7098L27 26.5848V36.2902ZM26 24.8527L34.6269 20L26 15.1473L17.3731 20L26 24.8527Z'
        fill='#1D1E22'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1874_719'
          x1='52'
          y1='2.95043e-06'
          x2='2.5'
          y2='52'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.5' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
}
