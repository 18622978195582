import {
  Checkbox,
  ListItemText,
  OutlinedInput,
  SelectProps,
} from '@mui/material';
import React from 'react';
import { IOption } from '../../types';
import { Dropdown, Item, Wrapper } from './MultiSelect.style';

export type MultiSelectProps = SelectProps & {
  value: string[];
  options: IOption[];
  minimumInputLength?: number;
};

export const MultiSelect = React.forwardRef(
  (props: MultiSelectProps, ref: any) => {
    const { value, options, minimumInputLength } = props;

    const handleChange = (event: any, child: React.ReactNode) => {
      const { value } = event.target;

      if (minimumInputLength && value.length < minimumInputLength) {
        return;
      }

      if (!props.onChange) {
        return;
      }

      props.onChange(event as any, child);
    };

    function renderOption(option: IOption) {
      const { id, text } = option;

      return (
        <Item key={id} value={id}>
          <Checkbox checked={value.indexOf(id) > -1} />
          <ListItemText primary={text} />
        </Item>
      );
    }

    function renderOptions() {
      return options.map((option: IOption) => renderOption(option));
    }

    return (
      <Wrapper
        className='MultiSelect-wrapper'
        data-testid='MultiSelect-wrapper'
      >
        <Dropdown
          data-testid='MultiSelect-dropdown'
          ref={ref}
          multiple
          rows={10}
          minRows={10}
          maxRows={10}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(value: any) => value.join(', ')}
          MenuProps={MenuProps}
        >
          {renderOptions()}
        </Dropdown>
      </Wrapper>
    );
  }
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 280,
      padding: '0 10px',
    },
  },
};

export default MultiSelect;
