import { takeEvery } from 'saga-ts';
import { logEvent } from '../analytics.amplitude';
import { IncomingTableEventWrapped } from '../analytics.types';
import { config } from '../utils/globals';
import { customEvenChannel } from './channels/channel.customEvent';

export function* listenToDialogs(event: IncomingTableEventWrapped) {
  const { dialogs } = config;
  const { eventId = 'Dialog' } = dialogs as Json;

  const { data } = event;

  const log = {
    ...data,
  };

  logEvent(eventId, log, true);
}

export function* root() {
  const channel = customEvenChannel('global/dialog');
  yield takeEvery(channel, listenToDialogs);
}
