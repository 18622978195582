import docEdit from './json/form.docEdit.json';
import docLabel from './json/form.docLabel.json';
import docAddLabels from './json/form.docAddLabels.json';
import docEditDefaults from './form.docEdit.detaults.json';

export const forms = {
  docEdit,
  docLabel,
  docAddLabels,
};

export const formDefaults = {
  docEdit: docEditDefaults,
  docLabel: docEditDefaults,
  docAddLabels: docEditDefaults,
};
