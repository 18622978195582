import { PricingInfo } from '../../data-types/Payment';

export const FETCH_PRICING_STARTED = 'FETCH_PRICING_STARTED';
export const FETCH_PRICING_SUCCESS = 'FETCH_PRICING_SUCCESS';
export const FETCH_PRICING_FAILED = 'FETCH_PRICING_FAILED';

export const UPGRADE_PLAN_PRESSED = 'UPGRADE_PLAN_PRESSED';
export const GUIDANCE_CTA_PRESSED = 'GUIDANCE_CTA_PRESSED';
export const TRY_IN_PLAYGROUND_CTA_PRESSED = 'TRY_IN_PLAYGROUND_CTA_PRESSED';
export const FAQ_OPENED = 'FAQ_OPENED';
export const FAQ_EXPANDED = 'FAQ_EXPANDED';

export interface FetchPricingStarted {
  type: typeof FETCH_PRICING_STARTED;
}

export interface FetchPricingSuccess {
  type: typeof FETCH_PRICING_SUCCESS;
  pricingInfo: PricingInfo;
}

export interface FetchPricingFailed {
  type: typeof FETCH_PRICING_FAILED;
  error: string;
}

export interface UpgradePlanPressed {
  type: typeof UPGRADE_PLAN_PRESSED;
  ctaText: string;
  origin: string;
}

export interface GuidanceCTAPressed {
  type: typeof GUIDANCE_CTA_PRESSED;
  ctaText: string;
}

export interface TryInPlaygroundCTAPressed {
  type: typeof TRY_IN_PLAYGROUND_CTA_PRESSED;
  path: string;
  origin: string;
  ctaText: string;
  labelName: string;
}

export interface FaqOpened {
  type: typeof FAQ_OPENED;
  questionText: string;
}

export type PricingActionTypes =
  | FetchPricingStarted
  | FetchPricingSuccess
  | FetchPricingFailed
  | UpgradePlanPressed
  | GuidanceCTAPressed
  | TryInPlaygroundCTAPressed
  | FaqOpened;
