import { EventLogs } from '@ai21/studio-ui';
import { initialLogs } from './AnalyticsLogs.data';

export type AnalyticsLogsProps = {};

export function AnalyticsLogsContainer(_props: AnalyticsLogsProps) {
  const config = {
    id: 'analytics',
    eventId: 'logs/analytics',
    initialLogs,
    persistent: true,
  };

  return <EventLogs config={config} />;
}

export default AnalyticsLogsContainer;
