import { toast } from '@ai21/studio-ui';
import { takeEvery } from 'saga-ts';

type ActionToast = {
  type: 'TOAST';
  message: string;
  toastType?: string;
};

export function* showToast(action: ActionToast) {
  const { message, toastType } = action;
  toast.show(message, toastType);
}

export function* root() {
  yield takeEvery('TOAST', showToast);
}
