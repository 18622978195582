import React from 'react';
import { AuthRoutes } from '../types';

const LoginContainer = React.lazy(() => import('../components/Login/Login.container')); // prettier-ignore
const SignUpContainer = React.lazy(() => import('../components/SignUp/SignUp.container')); // prettier-ignore
const CheckEmail = React.lazy(() => import('../components/AuthMessage/CheckEmail')); // prettier-ignore
const CheckEmailDone = React.lazy(() => import('../components/AuthMessage/CheckEmailDone')); // prettier-ignore
const ForgotPassword = React.lazy(() => import('../components/ForgotPassword/ForgotPassword.container')); // prettier-ignore
const ForgotPasswordCheck = React.lazy(() => import('../components/AuthMessage/ForgotPasswordCheck')); // prettier-ignore
const ForgotPasswordDone = React.lazy(() => import('../components/AuthMessage/ForgotPasswordDone')); // prettier-ignore
const SSOLogin = React.lazy(() => import('../components/SSOLogin/SSOLogin.container')); // prettier-ignore
export const authRoutes = {
  [AuthRoutes.login]: 'login',
  [AuthRoutes.signUp]: 'signUp',
  [AuthRoutes.checkEmail]: 'checkEmail',
  [AuthRoutes.checkEmailDone]: 'checkEmailDone',
  [AuthRoutes.forgotPassword]: 'forgotPassword',
  [AuthRoutes.forgotPasswordCheck]: 'forgotPasswordCheck',
  [AuthRoutes.forgotPasswordDone]: 'forgotPasswordDone',
  [AuthRoutes.ssoLogin]: 'ssoLogin',
};

export const authStageRoutes = {
  [AuthRoutes.checkEmail]: 'checkEmail',
  [AuthRoutes.forgotPassword]: 'forgotPassword',
  [AuthRoutes.forgotPasswordCheck]: 'forgotPasswordCheck',
};

export const authPages = {
  login: LoginContainer,
  signUp: SignUpContainer,
  checkEmail: CheckEmail,
  checkEmailDone: CheckEmailDone,
  forgotPassword: ForgotPassword,
  forgotPasswordCheck: ForgotPasswordCheck,
  forgotPasswordDone: ForgotPasswordDone,
  ssoLogin: SSOLogin,
};
