import { createSelector } from 'reselect';
import { sortBy } from 'shared-base';
import { calcIsEvaluated } from '..';
import { IStudioStore } from '../types';
import * as raw from './selectors.raw';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $evaluationJob = createSelector(
  raw.$rawEvaluationJobs,
  raw.$rawCurrentIds,
  (jobs, currentIds) => {
    const { evaluationJobId } = currentIds;

    if (!evaluationJobId) {
      return;
    }

    return jobs[evaluationJobId];
  }
);

export const $evaluationLines = createSelector(
  raw.$rawEvaluationLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { evaluationJobId } = currentIds;

    if (!evaluationJobId) {
      return [];
    }

    return Object.values(lines)
      .filter((line) => line.setId === evaluationJobId)
      .sort(sortBy('index'));
  }
);

export const $evaluationLineId = createSelector(raw.$rawCurrentIds, (currentIds) => {
  const { evaluationLineId } = currentIds;
  return evaluationLineId;
});

export const $evaluationLine = createSelector(
  raw.$rawEvaluationLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { evaluationLineId } = currentIds;

    if (!evaluationLineId) {
      return;
    }

    return lines[evaluationLineId];
  }
);

export const $evaluationStats = createSelector(raw.$rawEvaluationLines, (lines) => {
  const total = Object.values(lines).length;

  return Object.values(lines).reduce(
    (output, line) => {
      const { quality, comment } = line;

      if (calcIsEvaluated(quality)) {
        output.evaluated.value += 1;
        output.good.evaluatedCount += 1;
        output.bad.evaluatedCount += 1;
        output.ok.evaluatedCount += 1;

        switch (quality?.value) {
          case 10:
            output.good.value += 1;
            break;
          case 5:
            output.ok.value += 1;
            break;
          case 0:
            output.bad.value += 1;
            break;
          default:
            break;
        }
      }

      if (comment !== '') {
        output.comments.value += 1;
      }

      return output;
    },
    {
      evaluated: {
        id: 'evaluated',
        iconName: 'ListCheck',
        title: 'Evaluated',
        value: 0,
        total,
        displayTotal: true,
      },
      good: {
        id: 'good',
        iconName: 'DoubleThumbUp',
        title: 'Great',
        value: 0,
        displayTotal: false,
        evaluatedCount: 0,
      },
      ok: {
        id: 'ok',
        iconName: 'ThumbUp',
        title: 'Okay',
        value: 0,
        displayTotal: false,
        evaluatedCount: 0,
      },
      bad: {
        id: 'bad',
        iconName: 'ThumbDown',
        title: 'Bad',
        value: 0,
        displayTotal: false,
        evaluatedCount: 0,
      },
      comments: {
        id: 'comments',
        iconName: 'MessageDots',
        title: 'Comments',
        value: 0,
        displayTotal: false,
        total,
      },
    }
  );
});

export const $evaluationGraph = createSelector($evaluationStats, (stats) => {
  const { evaluated, good, ok, bad } = stats;

  return [
    {
      color: '#11783c',
      value: good.value,
    },
    {
      color: '#996e00',
      value: ok.value,
    },
    {
      color: '#c7200a',
      value: bad.value,
    },
    {
      color: '#f2f2f2',
      value: evaluated.total - evaluated.value,
    },
  ];
});

export const $evaluationDotsPeople = createSelector(raw.$rawEvaluationLines, (lines) => {
  const output: Json = {};

  Object.values(lines).forEach((line) => {
    const { id, lastOpenedBy, lastOpenedAt } = line;

    if (!lastOpenedBy || !lastOpenedAt) {
      return;
    }

    const lastOpenedAtMillis = new Date(lastOpenedAt).getTime();
    const delta = Date.now() - lastOpenedAtMillis;

    if (delta > 1000 * 60) {
      return;
    }

    if (!output[lastOpenedBy] || output[lastOpenedBy].delta > delta) {
      output[lastOpenedBy] = { id, delta, lastOpenedBy };
    }
  });

  // { 'lineId': 'UserName' }
  return Object.values(output).reduce((output, item) => {
    output[item.id] = item.lastOpenedBy;
    return output;
  }, {});
});

export const $evaluationDots = createSelector(
  raw.$rawEvaluationLines,
  raw.$rawCurrentIds,
  $evaluationDotsPeople,
  (lines, currentIds, people) => {
    const { evaluationLineId } = currentIds;

    return Object.values(lines)
      .map((line) => {
        const { id, quality, index } = line;

        const scoreNumber = quality ? quality.value : undefined;
        const isCompleted = typeof scoreNumber === 'number';
        const isCurrent = evaluationLineId === id;

        return {
          id,
          index,
          color: colors[scoreNumber ?? 99],
          isCompleted,
          isCurrent,
          lastOpenedBy: people[id],
        };
      })
      .sort(sortBy('index'));
  }
);

export const $evaluationJobInfo = createSelector($evaluationJob, $evaluationDots, (job, dots) => {
  const countTotal = dots.length;
  const countCompleted = dots.filter((dot) => dot.isCompleted).length;

  const currentIndex = dots.findIndex((dot) => dot.isCurrent);

  const previousDot = dots[currentIndex - 1];
  const nextDot = dots[currentIndex + 1];

  return {
    job,
    currentIndex,
    dots,
    countTotal,
    countCompleted,
    previousDot,
    nextDot,
  };
});

const colors: any = {
  99: 'gray',
  10: 'green',
  5: 'orange',
  0: 'red',
};
