import { IconButton } from '@ai21/studio-base-ui';
import { Typography, tokens } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  /* text-transform: capitalize; */
  font-size: 14px;
  color: ${tokens.neutral.deepest};
  margin-bottom: 6px;

  span {
    display: flex;
    align-items: center;
  }

  svg {
    height: 14px;
    width: 14px;
    color: ${tokens.text.secondary};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 6px;

  button {
    margin: 0 2px;
  }
`;

export const Action = styled(IconButton)`
  width: 36px;
  height: 36px;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
  }
`;
