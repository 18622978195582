export function ExclamationMarkIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.167 12.5h1.667v1.666H9.167V12.5zm0-6.667h1.667v5H9.167v-5zm.825-4.167c-4.6 0-8.325 3.734-8.325 8.334s3.725 8.333 8.325 8.333c4.608 0 8.342-3.733 8.342-8.333S14.6 1.667 9.992 1.667zm.008 15A6.665 6.665 0 0 1 3.334 10 6.665 6.665 0 0 1 10 3.333 6.665 6.665 0 0 1 16.667 10 6.665 6.665 0 0 1 10 16.666z'
        fill='#E31B0C'
      />
    </svg>
  );
}
