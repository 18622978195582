import { tokens, makeStyles } from '@ai21/studio-ui';

export const DatasetUploadStyles = makeStyles(() => ({
  dialogContainer: {
    // padding: '16px 24px',
    minWidth: 600,
    minHeight: 306,
    maxWidth: 600,
  },
  title: {
    fontWeight: 400,
    color: `${tokens?.text?.primary ? tokens?.text?.primary : '#262123'}`,
  },
  link: { cursor: 'pointer' },
  datasetTable: {
    width: '100%',
    padding: '8px 24px',
    borderRadius: 4,
    backgroundColor: '#F6F6F6',
  },
}));

export default DatasetUploadStyles;
