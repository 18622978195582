export function RewriteIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='6' fill='white' />
      <rect width='52' height='52' rx='6' fill='#59AEAE' fillOpacity='0.1' />
      <path
        d='M25.0389 20.37L22.7692 20.5154L22.0316 18.3355C21.9975 18.2349 21.85 18.2461 21.8386 18.3579L21.4869 20.9737L19.6144 22.304C19.5349 22.3599 19.569 22.4716 19.6711 22.4828L21.6117 22.6393L22.4174 24.5732C22.4515 24.6627 22.5876 24.6515 22.6103 24.5509L23.0756 22.2369L25.1184 20.5377C25.1864 20.4818 25.1297 20.3589 25.0389 20.37Z'
        fill='black'
      />
      <path
        d='M24.6643 29.5264L21.6935 29.7286L20.7212 26.866C20.678 26.7383 20.4835 26.749 20.4619 26.8873L19.9974 30.3246L17.5234 32.0804C17.4262 32.1549 17.4694 32.3039 17.5883 32.3145L20.1486 32.5167L21.2073 35.0601C21.2613 35.1771 21.4342 35.1665 21.4666 35.0388L22.0716 31.9953L24.7508 29.7606C24.8588 29.6754 24.794 29.5158 24.6643 29.5264Z'
        fill='black'
      />
      <path
        d='M30.5952 23.1749L35.248 22.8619C35.4523 22.8508 35.5544 23.0967 35.3842 23.2197L31.1853 26.7186L30.232 31.4919C30.198 31.6819 29.9143 31.7155 29.8348 31.5254L28.1779 27.5458L24.172 27.2328C23.979 27.2216 23.9109 26.9757 24.0698 26.8639L27.9396 24.1139L28.6659 18.7258C28.7 18.5134 29.0064 18.4799 29.0745 18.6923L30.5952 23.1749Z'
        fill='black'
      />
    </svg>
  );
}
