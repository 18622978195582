import {
  Action,
  ApiInfo,
  analyzeStructure,
  generateActionTypesDictionaryForStore,
} from 'redux-store-generator';
import { initialState } from '../initialState';
import { IStudioStore } from '../types';
import { connectedAdd } from './connected.add';
import { connectedGet } from './connected.get';
import { connectedPatch } from './connected.patch';
import { IConnectedResult, INodeInfo } from './connected.types';
import { connectedDelete } from './connected.delete';

export const connectedAll = async (action: Action): Promise<IConnectedResult> => {
  const info = infoFromActionType(action.type);

  if (!info || info?.isLocal) {
    return {
      shouldReachStore: true,
      shouldReachStoreReason: 'not an API action',
      response: undefined,
    };
  }

  const { verb, isGet } = info;

  if (isGet) {
    return connectedGet(info, action);
  }

  switch (verb) {
    case 'add':
      return connectedAdd(info, action);
    case 'patch':
      return connectedPatch(info, action);
    case 'delete':
      return connectedDelete(info, action);
  }

  return {
    shouldReachStore: true,
  };
};

const dictionary = generateActionTypesDictionaryForStore<IStudioStore>(initialState);
const structure = analyzeStructure(initialState);

const infoFromActionType = (actionType: string): INodeInfo | null => {
  const actionInfo: ApiInfo = dictionary[actionType];

  if (!actionInfo) {
    return null;
  }
  const { nodeName } = actionInfo;
  const nodeType = structure[nodeName];

  return {
    ...actionInfo,
    nodeType,
  };
};
