import { RouterBuilder, MenuBuilder } from '@ai21/studio-ui';
import { pages } from '../pages';
import {
  defaultRoute,
  externalRoutes,
  notFoundPageId,
  privateRoutes,
  publicRoutes,
  redirects,
} from './Web.routes';
import { sideBarGroups, sideBarItems, newSideBarItems } from './SideBar.data';
import { topBarGroups, topBarItems } from './TopBar.data';

export const initRoutes = (builder: RouterBuilder) => {
  builder
    .withPublicRoutes(publicRoutes)
    .withPrivateRoutes(privateRoutes)
    .withDefaultRoute(defaultRoute)
    .withExternalRoutes(externalRoutes)
    .withRedirects(redirects)
    .withPages(pages)
    .with404PageId(notFoundPageId);
};

export const initSideBar = (builder: MenuBuilder) => {
  builder.withItems(sideBarItems).withItems(newSideBarItems).withGroups(sideBarGroups);
};

export const initTopBar = (builder: MenuBuilder) => {
  builder.withItems(topBarItems).withGroups(topBarGroups);
};
