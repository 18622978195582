export function PresetsTableIcon() {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6666 4.95081C11.0913 4.95081 10.6249 5.41718 10.6249 5.99247C10.6249 6.56777 11.0913 7.03414 11.6666 7.03414C12.2419 7.03414 12.7083 6.56777 12.7083 5.99247C12.7083 5.41718 12.2419 4.95081 11.6666 4.95081ZM9.46118 5.36747C9.73323 4.4056 10.6176 3.70081 11.6666 3.70081C12.7156 3.70081 13.5999 4.4056 13.872 5.36747H16.6666C17.0118 5.36747 17.2916 5.64729 17.2916 5.99247C17.2916 6.33765 17.0118 6.61747 16.6666 6.61747H13.872C13.5999 7.57935 12.7156 8.28414 11.6666 8.28414C10.6176 8.28414 9.73323 7.57935 9.46118 6.61747H3.33325C2.98807 6.61747 2.70825 6.33765 2.70825 5.99247C2.70825 5.64729 2.98807 5.36747 3.33325 5.36747H9.46118ZM6.66659 9.95081C6.09129 9.95081 5.62492 10.4172 5.62492 10.9925C5.62492 11.5678 6.09129 12.0341 6.66659 12.0341C7.24188 12.0341 7.70825 11.5678 7.70825 10.9925C7.70825 10.4172 7.24188 9.95081 6.66659 9.95081ZM4.46118 10.3675C4.73324 9.4056 5.6176 8.70081 6.66659 8.70081C7.71557 8.70081 8.59993 9.4056 8.87199 10.3675H16.6666C17.0118 10.3675 17.2916 10.6473 17.2916 10.9925C17.2916 11.3376 17.0118 11.6175 16.6666 11.6175H8.87199C8.59994 12.5793 7.71557 13.2841 6.66659 13.2841C5.6176 13.2841 4.73324 12.5793 4.46118 11.6175H3.33325C2.98807 11.6175 2.70825 11.3376 2.70825 10.9925C2.70825 10.6473 2.98807 10.3675 3.33325 10.3675H4.46118ZM14.1666 14.9508C13.5913 14.9508 13.1249 15.4172 13.1249 15.9925C13.1249 16.5678 13.5913 17.0341 14.1666 17.0341C14.7419 17.0341 15.2083 16.5678 15.2083 15.9925C15.2083 15.4172 14.7419 14.9508 14.1666 14.9508ZM11.9612 15.3675C12.2332 14.4056 13.1176 13.7008 14.1666 13.7008C15.2156 13.7008 16.0999 14.4056 16.372 15.3675H16.6666C17.0118 15.3675 17.2916 15.6473 17.2916 15.9925C17.2916 16.3376 17.0118 16.6175 16.6666 16.6175H16.372C16.0999 17.5793 15.2156 18.2841 14.1666 18.2841C13.1176 18.2841 12.2332 17.5793 11.9612 16.6175H3.33325C2.98807 16.6175 2.70825 16.3376 2.70825 15.9925C2.70825 15.6473 2.98807 15.3675 3.33325 15.3675H11.9612Z'
        fill='currentColor'
        fillOpacity='0.87'
      />
    </svg>
  );
}
