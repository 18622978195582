import { Box, Button, Chip, Icon, Stack } from '@ai21/studio-base-ui';
import { invokeEvent } from 'shared-base';
import { ICatalogueItem } from '../../types';
import { useCatalogueCardStyles } from './CatalogueCard.styles';
import { catalogueCardData } from './CatalogueCard.data';
import { useCallback, useMemo } from 'react';
import { Typography } from '@ai21/studio-base-ui';
import { CatalogueCardProps } from './CatalogueCard.types';
import {
  CatalogueCardCtaButton,
  CatalogueCardDetails,
  CatalogueCardIcon,
  CatalogueCardTitle,
} from './CatalogueCard.components';
import { isLeftMouseClick } from '../../utils/uiEvents';

export function CatalogueCard(props: CatalogueCardProps) {
  const { item, testId = 'catalogue-card', id, onClick } = props;
  const {
    title,
    iconName,
    description,
    slogan,
    titleTag,
    ctaButtonText,
    ctaButtonIcon,
    imageUrl,
    isNew = false,
    isBeta = false,
    forEmployees = false,
    tags = [],
    isDeprecated = false,
    isBetaEnabled = false,
  } = item ?? {};

  const styles = useCatalogueCardStyles({ imageUrl, isDeprecated, isBeta, isBetaEnabled });

  const handleClick = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>, id: string) => {
      // We want to support left clicks only
      if (!isLeftMouseClick(ev)) {
        return;
      }

      invokeEvent('global/click', {
        ev,
      });

      onClick();
    },
    [onClick]
  );

  const CtaButton = useMemo(() => {}, []);

  return (
    <Box
      sx={styles.root}
      className='CatalogueCard-wrapper'
      data-testid={item.id}
      onClick={(ev) => handleClick(ev, id)}
    >
      <CatalogueCardIcon imageUrl={imageUrl} iconName={iconName} isDeprecated={isDeprecated} />
      <Box sx={styles.detailsContainer}>
        <CatalogueCardTitle
          title={title}
          isNew={isNew}
          isInternal={forEmployees}
          isDeprecated={isDeprecated}
        />
        <CatalogueCardDetails
          description={description}
          slogan={slogan}
          tags={tags}
          isDeprecated={isDeprecated}
        />
        <CatalogueCardCtaButton
          text={ctaButtonText}
          icon={ctaButtonIcon}
          isDeprecated={isDeprecated}
          isBeta={isBeta}
          isBetaEnabled={isBetaEnabled}
        />
      </Box>
    </Box>
  );
}

export default CatalogueCard;
