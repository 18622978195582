import type { IMenuGroup, IMenuItem } from '@ai21/studio-ui';
import { AppRoutes } from './App.routes';

// 1st word capitalized, 2nd word lowercase
export const sideBarItems: IMenuItem[] = [
  {
    id: 'evaluation',
    title: 'Evaluation tool',
    path: AppRoutes.EVALUATION_JOBS,
    iconName: 'menu.Scale',
    groupId: 'tools',
    order: 0,
    isChild: true,
  },
];

export const sideBarGroups: IMenuGroup[] = [];
