export const calcIsEvaluated = (quality?: Json) => {
  if (!quality) {
    return false;
  }

  const { value } = quality;

  if ((value as any) === '' || value === undefined) {
    return false;
  }

  return value >= 0;
};
