interface LMIconProps {
  width?: number;
  height?: number;
}

export function LMIcon({ width = 24, height = 24 }: LMIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 57 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.328 16.154a3.531 3.531 0 0 1 3.375-3.528l33.084-1.466a3.532 3.532 0 0 1 2.591.971l.464.441c.7.667 1.096 1.591 1.096 2.558v27.745a3.531 3.531 0 0 1-3.532 3.531h-34.73a3.531 3.531 0 0 1-3.257-2.164l-.48-1.144a3.531 3.531 0 0 1-.242-1.857l1.631-11.65V16.154z'
        fill='#000'
      />
      <rect
        x='8.445'
        y='11.977'
        width='38.844'
        height='31.781'
        rx='2.648'
        fill='#fff'
        stroke='#000'
        strokeWidth='1.766'
      />
      <path
        d='M27.155 23.664h-1.163l-1.568 7.33a7.07 7.07 0 0 1-.546 1.64c-.224.47-.518.875-.882 1.216a3.614 3.614 0 0 1-1.268.757c-.494.177-1.07.265-1.728.265-1.033 0-1.838-.27-2.414-.81-.575-.541-.863-1.264-.863-2.168 0-.306.035-.629.105-.97l.212-.951h1.48l-.211.951a4.08 4.08 0 0 0-.106.881c0 1.128.664 1.692 1.991 1.692.823 0 1.445-.235 1.868-.705.435-.481.735-1.104.9-1.868l1.55-7.26h-1.375v-1.375h4.018v1.375zm3.742 9.586 2.185-10.309h-.212l-3.101 4.635h-1.604v-.106l3.542-5.18h2.996l-2.326 10.96h3.49v1.375h-8.46V33.25h3.49z'
        fill='#D53864'
      />
    </svg>
  );
}
