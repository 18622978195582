import { useCallback, useEffect } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { invokeEvent } from 'shared-base';
import { logEvent } from '../analytics.amplitude';
import { Json } from '../analytics.types';

export function useAnalytics() {
  const send = useCallback((eventId: string, params: Json) => {
    logEvent(eventId, params);
  }, []);

  return send;
}

let delay = 800;

export function useNavigationAnalytics() {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      invokeEvent('global/navigation', {
        nextLocation: location,
      });
      delay = 0;
    }, delay);
  }, [location]);
}

export const getLocation = (): Location => {
  const { pathname, search, hash } = document.location;

  return {
    pathname,
    search,
    hash,
    state: '',
    key: '',
  };
};
