import { Typography } from '@ai21/studio-base-ui';
import {
  AppBar,
  Button,
  ListItemText,
  MenuItem,
  Toolbar as ToolbarMUI,
  tokens,
} from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';

export const Wrapper = styled(AppBar)`
  background-color: ${tokens.primitives.coal[50]};
  box-shadow: unset;
  border-radius: 0px;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
`;

export const ItemText = styled(Typography)`
  color: ${tokens.primitives.coal[1000]};
  font-weight: 500;
`;

export const ItemBadge = styled('div')`
  position: relative;
  left: 6px;
  font-size: 10px;
  color: white;
  border-radius: 4px;
  height: 20px;

  &.AI21 {
    background-color: #e91e63;
  }

  &.Beta {
    background-color: ${tokens.neutral.almostBlack};
  }
`;

export const Item = styled(Button)`
  padding: 6px 10px;
  margin-left: 8px;
  background-color: ${tokens.primitives.coal[50]};

  &:hover {
    background-color: ${tokens.primitives.coal[75]};
  }

  &.on {
    background-color: ${tokens.primitives.coal[75]};
  }

  &:active {
    background-color: ${tokens.primitives.coal[90]};
  }

  svg {
    fill: currentColor;
  }
`;

export const Toolbar = styled(ToolbarMUI)`
  display: flex;
  justify-content: space-between;
  padding-right: 0;
`;

export const Gap = styled.div`
  flex: 1;
`;

export const Home = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 216px;
  text-align: start;
  height: 56px;
`;

export const MenuToggle = styled(Button)`
  min-width: 32px;
  padding-left: 6px;
  padding-right: 6px;
  margin-right: 10px;
  margin-left: -10px;
`;

export const Navigation = styled.div`
  display: flex;
  border-bottom: 1px solid ${tokens.primitives.coal[50]};
  height: 54px;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 216px);
  padding-right: 16px;

  @media (max-width: 768px) {
    width: auto;
  }
`;

export const BreadcrumbsWrapper = styled.div`
  color: ${tokens.neutral.deep};
  padding: 8px 24px;
`;

export const NavigationItems = styled.div`
  display: flex;
`;
