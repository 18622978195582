import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 500px;
`;

export const Content = styled.div`
  padding: 20px;
`;
