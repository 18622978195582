import { useEffect, useMemo } from 'react';
import { search, sortBy } from '../context/Table.context.utils';
import { ITableCount, ITableData, ITableFeatures, ITableState } from '../types';
import { downloadJson, invokeEvent } from 'shared-base';

type Data = Json[];

type Callbacks = {
  onExportData: () => void;
};

export type UseTableDataReturn = [Data, ITableCount, Callbacks];

export function useTableData(
  inData: Data,
  state: ITableState,
  features: ITableFeatures
): UseTableDataReturn {
  const { withPagination, withSearch, withSort } = features;
  const { pageIndex = 0, sortFieldId, sortDirection, q, itemsPerPage = 100 } = state;

  const tableData = useMemo(() => {
    const output: ITableData = {
      all: inData,
      filtered: [],
      paginated: [],
    };

    let transient = [...inData];

    if (withSearch) {
      if (q) {
        transient = search(inData, q);
      }
    }

    if (withSort) {
      if (sortFieldId && sortDirection) {
        transient.sort(sortBy(sortFieldId, sortDirection));
      }
    }

    output.filtered = [...transient];
    output.paginated = [...transient];

    return output;
  }, [inData, state, features]);

  const count = useMemo(() => {
    const output: ITableCount = {
      all: tableData.all.length,
      filtered: tableData.filtered.length,
      paginated: tableData.paginated.length,
    };

    return output;
  }, [tableData]);

  const callbacks: Callbacks = useMemo(
    () => ({
      onExportData: () => {
        downloadJson('table-data.json', tableData.filtered);
        invokeEvent('export_data');
      },
    }),
    [tableData]
  );

  return [tableData.paginated, count, callbacks];
}
