export function DoubleThumbUpIcon() {
  return (
    <svg width='24' height='21' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.75 11.75V19.75C4.75 20.3023 4.30228 20.75 3.75 20.75H1.75C1.19772 20.75 0.75 20.3023 0.75 19.75V12.75C0.75 12.1977 1.19772 11.75 1.75 11.75H4.75ZM4.75 11.75C6.95914 11.75 8.75 9.95916 8.75 7.75002V6.75002C8.75 5.64546 9.64543 4.75002 10.75 4.75002C11.8546 4.75002 12.75 5.64546 12.75 6.75002V11.75H15.75C16.8546 11.75 17.75 12.6455 17.75 13.75L16.75 18.75C16.4546 20.0103 15.6392 20.8257 14.75 20.75H7.75C6.09315 20.75 4.75 19.4069 4.75 17.75M5.80005 11V9.80005C5.80005 9.24776 6.24776 8.80005 6.80005 8.80005H8.30005M17 17.8H19.8C20.6892 17.8757 21.5046 17.0603 21.8 15.8L22.8 10.8C22.8 9.69548 21.9046 8.80005 20.8 8.80005H17.8V3.80005C17.8 2.69548 16.9046 1.80005 15.8 1.80005C14.6955 1.80005 13.8 2.69548 13.8 3.80005V4.80005C13.8 5.75888 13.4627 6.63892 12.9002 7.32793'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
