import { NavigateFunction, NavigateOptions } from 'react-router-dom';
import { delay, takeEvery } from 'saga-ts';
import { store } from '../redux/store';
import { invokeEvent } from 'shared-base';

let navigateMethod: NavigateFunction;
let contentRef: React.RefObject<HTMLDivElement>;

type ActionNavigate = {
  type: 'NAVIGATE';
  to: string;
  options?: NavigateOptions;
};

export function* navigation(action: ActionNavigate) {
  try {
    const { to = '', options } = action;

    yield delay(100);

    if (to.startsWith('http')) {
      window.open(to);
      return;
    }

    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }

    if (isCurrentPath(to)) {
      return;
    }

    invokeEvent('DRAWER_CLOSE_TRIGGER');
    navigateMethod(to, options);

    invokeEvent('PROMPT_CANCEL_TRIGGER');
  } catch (err: any) {
    console.log('err ->', err);
  }
}

export function* root() {
  yield takeEvery('NAVIGATE', navigation);
}

export const setNavigate = (value: NavigateFunction) => {
  navigateMethod = value;
};

export const setContentRef = (value: React.RefObject<HTMLDivElement>) => {
  contentRef = value;
};

export const navigate = (to: string, options?: NavigateOptions) => {
  store.dispatch({ type: 'NAVIGATE', to, options });
};

const isCurrentPath = (to: string) => {
  return to === window.location.pathname;
};
