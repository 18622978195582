import { Action } from 'redux-store-generator';
import { IConnectedResult, INodeInfo } from './connected.types';
import { serverMap } from './serverMap';

export const connectedDelete = async (
  info: INodeInfo,
  action: Action
): Promise<IConnectedResult> => {
  const { nodeName } = info;

  const node = serverMap[nodeName];

  // cannot fire API call
  if (!node || !node.delete || !action.id) {
    return {
      shouldReachStore: false,
      shouldReachStoreReason: 'no definition for DELETE method',
      response: undefined,
    };
  }

  // API call
  const response = await node.delete(action.id);

  const { isSuccess } = response;

  return {
    shouldReachStore: isSuccess,
    shouldReachStoreReason: 'success',
    response,
  };
};
