import { ITableConfig, ITableState } from '../types';

export const initialState: ITableState = {
  q: undefined,
  sortFieldId: undefined,
  sortDirection: undefined,
  pageIndex: 0,
  activeRowIndex: undefined,
  itemsPerPage: 100,
  isFiltered: false,
  hoveredRowId: '',
};

export const emptyConfig: ITableConfig = {
  id: '',
  header: {
    title: '',
    subtitle: '',
    link: '',
  },
  fields: [],
};
