import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;

  .MuiSelect-select,
  .MuiDataGrid-main {
    background-color: white;
  }

  textarea {
    background-color: white;
  }
`;
