export function NavigationBack() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.333 7.334H5.22l3.727-3.727L8 2.667 2.667 8 8 13.334l.94-.94-3.72-3.727h8.113V7.334z'
        fill='#2979FF'
      />
      <path
        d='M13.333 7.334H5.22l3.727-3.727L8 2.667 2.667 8 8 13.334l.94-.94-3.72-3.727h8.113V7.334z'
        fill='#000'
        fillOpacity='.2'
      />
    </svg>
  );
}
