import styled from 'styled-components';
import { IconButton, tokens } from '../..';

export const Wrapper = styled(IconButton)`
  svg {
    fill: #626b78;
  }

  &:hover {
    background-color: ${tokens.primitives.coal[50]};
  }

  &.on {
    background-color: ${tokens.primitives.coal[75]};
  }

  &:active {
    background-color: ${tokens.primitives.coal[90]};
  }
`;
