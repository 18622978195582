import pa from './json/form.pa.json';
import paDefaults from './form.pa.detaults.json';
import preset from './json/form.preset.json';

export const forms = {
  pa,
  preset,
};

export const formDefaults = {
  pa: paDefaults,
};
