import { Button as ButtonMui, DialogActions } from '@mui/material';
import { CodeBlock } from 'react-code-blocks';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
`;

export const Button = styled(ButtonMui)`
  font-size: 14px;
  padding-top: 6px;
  /* padding-bottom: 6px; */

  @media (max-width: 768px) {
    padding-right: 14px;
    padding-left: 14px;
  }

  &.selected {
    background-color: #e6eaec;
    color: #484d56;

    svg {
      fill: #484d56;
    }
  }
`;

export const Block = styled(CodeBlock)``;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const WrapperLanguages = styled.div`
  flex: 1;
  /* margin-bottom: 4px; */
`;

export const Cta = styled(Button)`
  background-color: #fff;
  color: #626b78;
  min-width: 80px;
  height: 32px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
    min-width: 100%;
  }

  svg {
    width: 21px;
    height: 21px;
  }

  &:hover {
    background-color: #f4f5f7;
    border-color: #a8aeb8;
  }
`;

export const blockStyles = {
  fontFamily: 'monospace',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.33,
  letterSpacing: '0.15px',
  backgroundColor: '#334',
  height: '400px',
  maxWidth: '709px',
  borderRadius: 4,
};
