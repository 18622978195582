interface ForwardChevronIconProps {
  style?: React.CSSProperties;
  width?: number;
  height?: number;
}

export function ForwardChevronIcon({ style, width = 24, height = 24 }: ForwardChevronIconProps) {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      transform='scale(-1, 1)'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
    >
      <path d='M17.67 3.87 15.9 2.1 6 12l9.9 9.9 1.77-1.77L9.54 12l8.13-8.13z' />
    </svg>
  );
}
