import { LinearProgress, TableCell, Tooltip, Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled('div')`
  font-size: 14px;
`;

export const Image = styled('div')`
  width: 70px;
  height: 80px;
  background-size: cover;
  background-position: center center;
`;

export const Number = styled('div')``;

export const Text = styled('div')`
  line-height: 24px;
`;

export const Date = styled('div')`
  line-height: 24px;
  white-space: nowrap;
`;

export const SubText = styled('div')`
  line-height: 24px;
  font-size: 16px;
  color: ${tokens.text.tertiary};
`;

// =========== Id ===========
export const Id = styled('div')`
  line-height: 24px;
  max-width: 100px;
  opacity: 0.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

// =========== Icon ===========
export const IconWrapper = styled('div')<{ spinning?: boolean }>`
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    stroke-width: 1.5px;
    width: 20px;
    color: ${tokens.text.secondary};
  }
`;

export const Time = styled('div')`
  font-size: 20px;
  font-variation-settings: 'wdth' 100, 'wght' 200;
  color: pink;

  span {
    font-size: 12px;
  }
`;

export const CopyTooltip = styled(Tooltip)``;

export const Copy = styled('div')`
  max-width: 70px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
`;

// =========== Progress ===========
export const Progress = styled(LinearProgress)``;

// =========== Name ===========
export const Name = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NameIcon = styled('div')`
  margin-top: 6px;
  margin-right: 6px;

  svg {
    width: 20px;
    height: 20px;
    stroke-width: 1.5px;
  }
`;

export const NameDetails = styled('div')``;

export const NameText = styled(Typography)`
  word-break: break-all;
`;

export const NameDescription = styled(Typography)``;

export const Avatar = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${tokens.primary.mainDefault};
  border: 1px solid white;
  font-weight: 500;
  border-radius: 50%;
  color: ${tokens.neutral.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
