import { GENERIC_ERROR_MESSAGE } from '../../constants/constants';
import {
  DatasetEndpointListElement,
  DatasetPreview,
  SelectedColumns,
} from '../../data-types/Dataset';
import {
  DELETE_DATASET_SUCCESS,
  DatasetsActionTypes,
  DatasetsUploadingErrorType,
  FETCH_DATASETS_FAILURE,
  FETCH_DATASETS_PREVIEW_FAILURE,
  FETCH_DATASETS_PREVIEW_STARTED,
  FETCH_DATASETS_PREVIEW_SUCCESS,
  FETCH_DATASETS_STARTED,
  FETCH_DATASETS_SUCCESS,
  FETCH_DATASET_URL_FAILURE,
  FETCH_DATASET_URL_STARTED,
  FETCH_DATASET_URL_SUCCESS,
  FETCH_EXAMPLE_DATASET_URL_FAILURE,
  FETCH_EXAMPLE_DATASET_URL_STARTED,
  FETCH_EXAMPLE_DATASET_URL_SUCCESS,
  POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE,
  POST_DATASET_FAILURE,
  POST_DATASET_STARTED,
  POST_DATASET_SUCCESS,
  RESET_DATASETS_PAGE_STATE,
  UPLOAD_DATASET,
  SHOW_DELETE_DATASET_DIALOG,
  RESET_EXAMPLE_DATASET_URL,
  ADD_DATASET_FILE,
} from '../actionTypes/datasetsActionTypes';

export enum DatasetsPageState {
  EMPTY_STATE = 'EMPTY_STATE',
  SHOW_DATASETS = 'SHOW_DATASETS',
  UPLOADING_DATASET_IN_PROGRESS = 'UPLOADING_DATASET_IN_PROGRESS',
  UPLOADING_DATASET_DONE = 'UPLOADING_DATASET_DONE',
  DATASET_UNKNOWN_EXTENSION_ERROR = 'DATASET_UNKNOWN_EXTENSION_ERROR',
  DATASET_ILLEGAL_FORMAT_ERROR = 'DATASET_ILLEGAL_FORMAT_ERROR',
  DATASET_MIN_ROWS_ERROR = 'DATASET_MIN_ROWS_ERROR',
  DATASET_COLUMNS_NOT_SPECIFIED_ERROR = 'DATASET_COLUMNS_NOT_SPECIFIED_ERROR',
  DATASET_COLUMNS_NAME_NOT_EXIST_ERROR = 'DATASET_COLUMNS_NAME_NOT_EXIST_ERROR',
  DATASET_MULTIPLE_WHITESPACE_ERROR = 'DATASET_MULTIPLE_WHITESPACE_ERROR',
  DATASET_GENERAL_ERROR = 'DATASET_GENERAL_ERROR',
  DATASET_TO_LONG_ROWS_ERROR = 'DATASET_TO_LONG_ROWS_ERROR',
  INVALID_DATASET_NAME_ERROR = 'INVALID_DATASET_NAME_ERROR',
  DUPLICATE_DATASET_NAME_ERROR = 'DUPLICATE_DATASET_NAME_ERROR',
  EMPTY_COMPLETION = 'EMPTY_COMPLETION',
  NEW_DATASET_NAMING = 'NEW_DATASET_NAMING',
}

export interface DatasetsState {
  datasetsEndpointResponse: DatasetEndpointListElement[] | null;
  fetchDatasetsError: string | undefined;
  fetchDatasetsInProgress: boolean;
  uploadDatasetInProgress: boolean;
  fetchDatasetPreviewInProgress: boolean;
  uploadDatasetName: string | undefined;
  uploadDatasetFile: File | undefined;
  uploadDatasetError: string | undefined;
  uploadDatasetColumnsName: string[] | undefined;
  approveWhitespaceCorrection: boolean | undefined;
  deleteLongRows: boolean | undefined;
  selectedColumns: SelectedColumns | undefined;
  datasetPreview: DatasetPreview[] | undefined;
  state: DatasetsPageState | undefined;
  datasetUrl: string | undefined;
  exampleDatasetUrl: string | undefined;
  showDeleteDatasetDialog: boolean;
  uploadDatasetErrorDetails?: string | undefined;
  uploadedDatasetId?: string | undefined;
}

const initialState: DatasetsState = {
  datasetsEndpointResponse: null,
  fetchDatasetsError: undefined,
  fetchDatasetsInProgress: false,
  uploadDatasetInProgress: false,
  fetchDatasetPreviewInProgress: false,
  uploadDatasetError: undefined,
  state: undefined,
  uploadDatasetName: undefined,
  uploadDatasetFile: undefined,
  selectedColumns: undefined,
  approveWhitespaceCorrection: undefined,
  deleteLongRows: undefined,
  datasetPreview: undefined,
  uploadDatasetColumnsName: undefined,
  datasetUrl: undefined,
  exampleDatasetUrl: undefined,
  showDeleteDatasetDialog: false,
  uploadDatasetErrorDetails: undefined,
  uploadedDatasetId: undefined,
};

function getDataSetFailureState(uploadDatasetError: DatasetsUploadingErrorType | undefined) {
  switch (uploadDatasetError) {
    case DatasetsUploadingErrorType.UNKNOWN_EXTENSION:
      return DatasetsPageState.DATASET_UNKNOWN_EXTENSION_ERROR;
    case DatasetsUploadingErrorType.ILLEGAL_FORMAT:
      return DatasetsPageState.DATASET_ILLEGAL_FORMAT_ERROR;
    case DatasetsUploadingErrorType.MIN_ROWS:
      return DatasetsPageState.DATASET_MIN_ROWS_ERROR;
    case DatasetsUploadingErrorType.COLUMNS_NOT_SPECIFIED:
      return DatasetsPageState.DATASET_COLUMNS_NOT_SPECIFIED_ERROR;
    case DatasetsUploadingErrorType.COLUMNS_NAME_NOT_EXIST:
      return DatasetsPageState.DATASET_COLUMNS_NAME_NOT_EXIST_ERROR;
    case DatasetsUploadingErrorType.MULTIPLE_WHITESPACE:
      return DatasetsPageState.DATASET_MULTIPLE_WHITESPACE_ERROR;
    case DatasetsUploadingErrorType.GENERAL_EXCEPTION:
      return DatasetsPageState.DATASET_GENERAL_ERROR;
    case DatasetsUploadingErrorType.TO_LONG_ROWS:
      return DatasetsPageState.DATASET_TO_LONG_ROWS_ERROR;
    case DatasetsUploadingErrorType.INVALID_DATASET_NAME:
      return DatasetsPageState.INVALID_DATASET_NAME_ERROR;
    case DatasetsUploadingErrorType.DUPLICATE_DATASET_NAME:
      return DatasetsPageState.DUPLICATE_DATASET_NAME_ERROR;
    case DatasetsUploadingErrorType.EMPTY_COMPLETION:
      return DatasetsPageState.EMPTY_COMPLETION;
    default:
      return undefined;
  }
}

export function reducer(state = initialState, action: DatasetsActionTypes): DatasetsState {
  const { type } = action;

  switch (type) {
    case FETCH_DATASETS_STARTED: {
      return { ...state, fetchDatasetsError: undefined, fetchDatasetsInProgress: true };
    }
    case FETCH_DATASETS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        fetchDatasetsError: error || GENERIC_ERROR_MESSAGE,
        fetchDatasetsInProgress: false,
      };
    }
    case FETCH_DATASETS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        datasetsEndpointResponse: payload,
        fetchDatasetsError: undefined,
        fetchDatasetsInProgress: false,
        state: payload.length ? DatasetsPageState.SHOW_DATASETS : DatasetsPageState.EMPTY_STATE,
      };
    }
    case RESET_DATASETS_PAGE_STATE: {
      return {
        ...state,
        fetchDatasetsInProgress: false,
        fetchDatasetsError: undefined,
        state:
          state.datasetsEndpointResponse != null && state.datasetsEndpointResponse.length > 0
            ? DatasetsPageState.SHOW_DATASETS
            : DatasetsPageState.EMPTY_STATE,
      };
    }
    case ADD_DATASET_FILE: {
      const { datasetFile } = action;
      return {
        ...state,
        uploadDatasetError: undefined,
        uploadDatasetColumnsName: undefined,
        uploadDatasetFile: datasetFile,
        state: DatasetsPageState.NEW_DATASET_NAMING,
        uploadDatasetErrorDetails: undefined,
      };
    }
    case POST_DATASET_STARTED: {
      const { datasetName, datasetFile } = action;
      return {
        ...state,
        uploadDatasetInProgress: true,
        uploadDatasetError: undefined,
        uploadDatasetColumnsName: undefined,
        uploadDatasetName: datasetName,
        uploadDatasetFile: datasetFile,
        state: DatasetsPageState.UPLOADING_DATASET_IN_PROGRESS,
        uploadDatasetErrorDetails: undefined,
      };
    }
    case POST_DATASET_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ...action,
        uploadDatasetInProgress: false,
        uploadDatasetError: undefined,
        uploadDatasetColumnsName: undefined,
        state: DatasetsPageState.UPLOADING_DATASET_DONE,
        uploadedDatasetId: payload.id,
        datasetsEndpointResponse: state.datasetsEndpointResponse
          ? [...state.datasetsEndpointResponse, payload]
          : [payload],
      };
    }
    case POST_DATASET_FAILURE: {
      const {
        uploadDatasetError,
        selectedColumns,
        approveWhitespaceCorrection,
        deleteLongRows,
        uploadDatasetErrorDetails,
      } = action;
      return {
        ...state,
        uploadDatasetInProgress: false,
        uploadDatasetError,
        uploadDatasetColumnsName: undefined,
        selectedColumns,
        approveWhitespaceCorrection,
        deleteLongRows,
        state: getDataSetFailureState(uploadDatasetError),
        uploadDatasetErrorDetails,
      };
    }
    case POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE: {
      const { uploadDatasetError, uploadDatasetColumnsName } = action;
      return {
        ...state,
        uploadDatasetInProgress: false,
        uploadDatasetError,
        uploadDatasetColumnsName,
        state: DatasetsPageState.DATASET_COLUMNS_NOT_SPECIFIED_ERROR,
      };
    }
    case UPLOAD_DATASET: {
      return {
        ...initialState,
        state: DatasetsPageState.EMPTY_STATE,
      };
    }
    case FETCH_DATASETS_PREVIEW_STARTED: {
      return {
        ...state,
        fetchDatasetPreviewInProgress: true,
      };
    }
    case FETCH_DATASETS_PREVIEW_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        datasetPreview: payload && payload?.length > 0 ? payload : [],
        fetchDatasetPreviewInProgress: false,
      };
    }
    case FETCH_DATASETS_PREVIEW_FAILURE: {
      return {
        ...state,
        datasetPreview: undefined,
        fetchDatasetPreviewInProgress: false,
      };
    }
    case FETCH_DATASET_URL_SUCCESS: {
      const { url } = action;
      return {
        ...state,
        datasetUrl: url,
      };
    }
    case FETCH_DATASET_URL_FAILURE: {
      return {
        ...state,
        datasetUrl: undefined,
      };
    }
    case FETCH_DATASET_URL_STARTED: {
      return {
        ...state,
        datasetUrl: undefined,
      };
    }
    case FETCH_EXAMPLE_DATASET_URL_SUCCESS: {
      const { url } = action;
      return {
        ...state,
        exampleDatasetUrl: url,
      };
    }
    case FETCH_EXAMPLE_DATASET_URL_FAILURE: {
      return {
        ...state,
        exampleDatasetUrl: undefined,
      };
    }
    case RESET_EXAMPLE_DATASET_URL: {
      return {
        ...state,
        exampleDatasetUrl: undefined,
      };
    }
    case FETCH_EXAMPLE_DATASET_URL_STARTED: {
      return {
        ...state,
        exampleDatasetUrl: undefined,
      };
    }
    case SHOW_DELETE_DATASET_DIALOG: {
      const { showDeleteDatasetDialog } = action;
      return {
        ...state,
        showDeleteDatasetDialog,
      };
    }
    case DELETE_DATASET_SUCCESS: {
      const filteredDatasets = state.datasetsEndpointResponse
        ? state.datasetsEndpointResponse.filter((dataset) => dataset.id !== action.datasetId)
        : [];
      return {
        ...state,
        datasetsEndpointResponse: filteredDatasets,
        state:
          filteredDatasets != null && filteredDatasets.length > 0
            ? DatasetsPageState.SHOW_DATASETS
            : DatasetsPageState.EMPTY_STATE,
      };
    }
    default: {
      return state;
    }
  }
}
