import React, { useState } from 'react';
import { Wrapper, Button } from './Feedback.style';
import { invokeEvent } from 'shared-base';
import Icon from '../Icon/Icon';

export type FeedbackProps = {
  id: string;
};

export function Feedback(props: FeedbackProps) {
  const { id } = props;
  const [chosenFeedback, setChosenFeedback] = useState('');

  function onClick(thumb: string, score: number) {
    invokeEvent('global/flow', {
      flow: 'documentLibrary',
      action: 'answerFeedback',
      actionValue: score,
      eventId: 'MultiDocQuestionFeedback',
    });
    setChosenFeedback(chosenFeedback === thumb ? '' : thumb);
  }

  return (
    <Wrapper className='Feedback-wrapper' data-testid='Feedback-wrapper'>
      {chosenFeedback !== 'down' && (
        <Button
          data-testid='MultiDocThumbUp'
          id={id}
          className={chosenFeedback === 'up' ? 'selected' : ''}
          onClick={() => onClick('up', 10)}
          iconName='ThumbUp'
        />
      )}
      {chosenFeedback !== 'up' && (
        <Button
          data-testid='MultiDocThumbDown'
          id={id}
          className={chosenFeedback === 'down' ? 'selected' : ''}
          onClick={() => onClick('down', 0)}
          iconName='ThumbDown'
        />
      )}
    </Wrapper>
  );
}

export default Feedback;
