import { get } from 'lodash';
import { IncomingNetworkEvent } from '../../analytics.types';
import { logEvent } from '../../analytics.amplitude';

function* ParaphraseApiSuccess(data: IncomingNetworkEvent) {
  let params = get(data, 'response.request.data', {});
  const endpoint = get(data, 'endpoint', '');
  const duration = get(data, 'response.duration', 0);
  const outputData = get(data, 'response.data', {});
  const numSuggestions = get(data, 'response.data.suggestions', []).length;

  params = {
    ...params,
    textLength: get(params, 'text', '').length,
    text: undefined,
    requestDuration: duration,
    interactionId: get(outputData, 'id', ''),
    numSuggestions,
  };
  logEvent(
    'ParaphraseGenerated',
    {
      endpoint,
      ...params,
    },
    true
  );
}

function* SummarizeApiSuccess(data: IncomingNetworkEvent) {
  let params = get(data, 'response.request.data', {});
  const endpoint = get(data, 'endpoint', '');
  const duration = get(data, 'response.duration', 0);
  const outputData = get(data, 'response.data', {});
  const generatedTextLength = get(data, 'response.data.summary', '').length;

  params = {
    ...params,
    textLength: get(params, 'source', '').length,
    documentType: get(params, 'sourceType', ''),
    source: undefined,
    sourceType: undefined,
    requestDuration: duration,
    interactionId: get(outputData, 'id', ''),
    generatedTextLength,
  };

  logEvent(
    'SummarizationGenerated',
    {
      endpoint,
      ...params,
    },
    true
  );
}

function* GrammaticalErrorCorrectionApiSuccess(data: IncomingNetworkEvent) {
  let params = get(data, 'response.request.data', {});
  const endpoint = get(data, 'endpoint', '');
  const duration = get(data, 'response.duration', 0);
  const outputData = get(data, 'response.data', {});
  const numSuggestions = get(data, 'response.data.corrections', []).length;

  params = {
    ...params,
    textLength: get(params, 'text', '').length,
    text: undefined,
    requestDuration: duration,
    interactionId: get(outputData, 'id', ''),
    numSuggestions,
  };

  logEvent(
    'GrammaticalErrorCorrectionGenerated',
    {
      endpoint,
      ...params,
    },
    true
  );
}

function* ImprovementsApiSuccess(data: IncomingNetworkEvent) {
  let params = get(data, 'response.request.data', {});
  const types = get(data, 'response.request.data.types', []);
  const endpoint = get(data, 'endpoint', '');
  const duration = get(data, 'response.duration', 0);
  const outputData = get(data, 'response.data', {});
  const numSuggestions = get(data, 'response.data.improvements', []).length;

  params = {
    ...params,
    textLength: get(params, 'text', '').length,
    text: undefined,
    types,
    requestDuration: duration,
    interactionId: get(outputData, 'id', ''),
    numSuggestions,
  };

  logEvent(
    'ImprovementsGenerated',
    {
      endpoint,
      ...params,
    },
    true
  );
}

function* AnswerApiSuccess(data: IncomingNetworkEvent) {
  let params = get(data, 'response.request.data', {});
  const endpoint = get(data, 'endpoint', '');
  const duration = get(data, 'response.duration', 0);
  const outputData = get(data, 'response.data', {});
  const generatedTextLength = get(data, 'response.data.answer', '').length;

  params = {
    ...params,
    textLength: get(params, 'question', '').length,
    generatedTextLength,
    context: undefined,
    question: undefined,
    requestDuration: duration,
    interactionId: get(outputData, 'id', ''),
  };

  logEvent(
    'AnswerGenerated',
    {
      endpoint,
      ...params,
    },
    true
  );
}

export const root = {
  'lego.paraphrase': {
    success: ParaphraseApiSuccess,
  },
  'lego.summarize': {
    success: SummarizeApiSuccess,
  },
  'lego.gec': {
    success: GrammaticalErrorCorrectionApiSuccess,
  },
  'lego.improvements': {
    success: ImprovementsApiSuccess,
  },
  'lego.answer': {
    success: AnswerApiSuccess,
  },
};
