import { Button, Link } from '@ai21/studio-base-ui';
import { TableBody, TableRow, Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled('div')`
  flex: 1;
  min-height: 156px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -20px;
  text-align: center;
  background: ${tokens.primary.backgroundHover};
  border-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  > svg {
    width: 24px;
    height: 24px;
    color: ${tokens.text.secondary};
    margin-right: 10px;
    stroke-width: 1.5px;
  }
`;

export const Message = styled(Typography)`
  font-size: 16px;
  color: ${tokens.text.secondary};
`;

export const Cta = styled(Link)`
  color: ${tokens.text.secondary};
  margin-left: 6px;
  cursor: pointer;
  text-decoration-color: #aab;
`;
