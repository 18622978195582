import { put, takeEvery } from 'saga-ts';
import { customEvenChannel } from './channels/channel.customEvent';
import { actions } from '@ai21/studio-store';

export function* toggleIntercom(action: any) {
  const { data } = action;
  const { show: isDrawerShown } = data;

  if (document.location.pathname.includes('/demos') || document.location.pathname.includes('/lp')) {
    return;
  }

  yield put(actions.appState.patch({ isIntercomVisible: !isDrawerShown }));
}

export function* openIntercom() {
  intercomShowMethod(true);
}

export function* root() {
  yield takeEvery('OPEN_INTERCOM', openIntercom);
  const channel = customEvenChannel('drawer/show');
  yield takeEvery(channel, toggleIntercom);
}

type ShowMethod = (show: boolean) => void;

let intercomShowMethod: ShowMethod;

export const setShowIntercomMethod = (value: ShowMethod) => {
  intercomShowMethod = value;
};
