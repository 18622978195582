export function ExclamationMarkIcon2() {
  return (
    <svg width='14' height='14' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.333 10h1.334v1.333H7.333V10zm0-5.333h1.334v4H7.333v-4zm.66-3.334A6.663 6.663 0 0 0 1.333 8c0 3.68 2.98 6.667 6.66 6.667A6.67 6.67 0 0 0 14.667 8a6.67 6.67 0 0 0-6.674-6.667zm.007 12A5.332 5.332 0 0 1 2.667 8 5.332 5.332 0 0 1 8 2.667 5.332 5.332 0 0 1 13.333 8 5.332 5.332 0 0 1 8 13.333z'
        fill='#F4472F'
      />
    </svg>
  );
}
