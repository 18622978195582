export const predicateCurrentId = (key: string) => (action: any) => {
  return action.type === 'PATCH_CURRENTIDS' && key in action.payload;
};

export const predicateBox = (endsWith: string) => (action: any) => {
  const { id = '' } = action;
  return action.type === 'PATCH_BOX' && id.endsWith(endsWith);
};

export const predicateValues = (endsWith: string) => (action: any) => {
  const { id = '' } = action;
  return action.type === 'PATCH_BOX' && id.endsWith(endsWith);
};
