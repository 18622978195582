import { Button } from '@ai21/studio-base-ui';
import { TextField } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  box-shadow: inset 0px 1px 0px #e5e5e5;
`;

export const Actions = styled.div`
  > button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 16px;
  height: 42px;

  &:first-child {
    border-bottom: 1px solid #e5e5e5;
  }

  &:last-child {
    justify-content: flex-end;
  }
`;

export const Prompt = styled(TextField)`
  flex: 1;
  margin-right: 8px;

  input {
    height: 8px;
  }

  @media (max-width: 1000px) {
    max-width: 80vw;
  }
`;

export const Cta = styled(Button)`
  width: 208px;
  height: 42px;
  max-height: 42px;

  &:disabled {
    background-color: #e5e5e5 !important;
  }

  @media (max-width: 1000px) {
    max-width: 60px;

    svg {
      position: relative;
      right: 5px;
    }
  }
`;
