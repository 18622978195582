import { fireRequest } from './axios';

export const validate = (extraParams?: Json) => {
  return fireRequest<Json>({
    path: '/demo-pin-code/validate-code',
    method: 'post',
    data: extraParams,
    endpoint: 'pincode.validate',
  });
};
export const create = (extraParams?: Json) => {
  return fireRequest<Json>({
    path: '/demo-pin-code/create-code',
    method: 'post',
    data: extraParams,
    endpoint: 'pincode.create',
  });
};
export const get = () => {
  return fireRequest<Json>({
    path: '/demo-pin-code',
    endpoint: 'pincode.get',
  });
};

export const remove = (id: string) => {
  return fireRequest<Json>({
    method: 'delete',
    path: '/demo-pin-code/' + id,
    endpoint: 'pincode.remove',
  });
};

export const pincode = {
  create,
  get,
  remove,
  validate,
};
