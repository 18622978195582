import { invokeEvent } from 'shared-base';
import { AuthLogProps } from '../types';
import { authState } from './globals';

export const ChangeAuth = (user: any) =>
  SuccessEvent('ChangeAuth', {
    userExists: user ? true : false,
  });

export const AuthStarted = () => SuccessEvent('AuthStarted');
export const AuthFailed = (err: any) => FailureEvent('AuthFailed', err);
export const AuthCompleted = () => SuccessEvent('AuthCompleted');

export const LoginStarted = () => SuccessEvent('UserLoginStarted');
export const SignUpStarted = () => SuccessEvent('UserSignUpStarted');
export const LoginSucceeded = () => SuccessEvent('UserLoginSucceeded');
export const SignUpSucceeded = () => SuccessEvent('UserSignUpSucceeded');
export const AuthProviderSucceeded = () => SuccessEvent('AuthProviderSucceeded');

export const LoginFailed = (err: any) => FailureEvent('UserLoginFailed', err);
export const SignUpFailed = (err: any) => FailureEvent('UserSignUpFailed', err);
export const AuthProviderFailed = (err: any) => FailureEvent('AuthProviderFailed', err);

const SuccessEvent = (eventId: string, extra?: Json) => {
  logAnalytics(eventId, {
    ...authState.getLogsProps(),
    ...extra,
  });
};

const FailureEvent = (eventId: string, err: any) => {
  logAnalytics(eventId, {
    ...authState.getLogsProps(),
    errorMessage: err.message,
    errorCode: err.code,
  });
};

const logAnalytics = (eventId: string, props?: AuthLogProps) => {
  const { options } = authState;

  if (!options.logEnabled) {
    return;
  }

  invokeEvent('global/auth', {
    eventType: eventId,
    props,
    timestamp: Date.now(),
  });
};
