export function DownloadIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 3.25C12.4142 3.25 12.75 3.58579 12.75 4V14.1893L16.4697 10.4697C16.7626 10.1768 17.2374 10.1768 17.5303 10.4697C17.8232 10.7626 17.8232 11.2374 17.5303 11.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303L6.46967 11.5303C6.17678 11.2374 6.17678 10.7626 6.46967 10.4697C6.76256 10.1768 7.23744 10.1768 7.53033 10.4697L11.25 14.1893V4C11.25 3.58579 11.5858 3.25 12 3.25ZM4 16.25C4.41421 16.25 4.75 16.5858 4.75 17V19C4.75 19.6904 5.30964 20.25 6 20.25H18C18.6904 20.25 19.25 19.6904 19.25 19V17C19.25 16.5858 19.5858 16.25 20 16.25C20.4142 16.25 20.75 16.5858 20.75 17V19C20.75 20.5188 19.5188 21.75 18 21.75H6C4.48122 21.75 3.25 20.5188 3.25 19V17C3.25 16.5858 3.58579 16.25 4 16.25Z'
        fill='#626B78'
      />
    </svg>
  );
}
