import { Button } from '@ai21/studio-base-ui';
import { A, Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-direction: column;
  align-items: center;
  width: 556px;
  padding: 30px 50px 44px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  background-color: white-space;
  border-radius: 20px;
  text-align: center;

  > * {
    margin: 4px 0;
  }
`;

export const Header = styled(Typography)`
  padding: 20px 0 2px;
`;

export const Actions = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  a {
    color: #334;
  }
`;

export const DontHave = styled(Typography)`
  a {
    margin-left: 8px;
  }
`;

export const ToSignUp = styled(A)`
  margin-left: 8px;
`;

export const ForgotPassword = styled(Typography)``;

export const Link = styled(A)`
  margin-left: 8px;
  cursor: pointer;
`;

export const Slogan = styled(Typography)`
  margin-bottom: 30px;
`;

export const SSOLoginButton = styled(Button)`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  &:hover {
    color: #334;
    text-shadow: 0 0 1px #334;
    background-color: transparent;
  }
`;
