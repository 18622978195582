import { Form } from '@ai21/studio-forms';
import { actions, selectors } from '@ai21/studio-store';
import { prompt, toast } from '@ai21/studio-ui';
import { delay, put, select, takeEvery } from 'saga-ts';
import { guid4 } from 'shared-base';
import { formDefaults, forms } from '../_definitions/forms';
import { Json } from '../types';

type Verb = 'new' | 'edit' | 'delete';

type ActionJob = {
  type: 'PAS';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  new: newPa,
  edit: editPa,
  delete: deletePa,
};

function* newPa(action: ActionJob) {
  const user = yield* select(selectors.raw.$rawUser);

  const newPaId = guid4();

  const { didCancel, value } = yield prompt.form({
    component: Form,
    title: 'PA Settings',
    form: {
      config: forms.pa,
      data: {
        ...formDefaults.pa,
        name: `PA #${newPaId}`,
        id: newPaId,
      },
      allOptions: {},
      allDetails: {},
      allMethods: {},
    },
  });

  if (didCancel) {
    return;
  }

  const {
    id,
    name,
    description = '',
    labels,
    systemMessage,
    userPrompt,
  } = value;

  yield put(
    actions.pas.add({
      id,
      name,
      description,
      dateCreated: Date.now(),
      creator: user.userName ?? '',
      labels,
      systemMessage,
      userPrompt,
    })
  );
}

function* deletePa(action: ActionJob) {
  const { id } = action;
  const pa = yield* select(selectors.singles.$pa, id);
  const { name } = pa;

  const { didCancel } = yield prompt.confirm({
    title: 'Delete PA',
    description: `Are you sure you want to delete PA "${name}"?`,
    ctaButtonText: 'Delete PA',
    intention: 'delete',
  });

  if (didCancel) {
    return;
  }

  yield put(
    actions.pas.patch(id, {
      isDeleted: true,
    })
  );
}

export function* editPa(action: ActionJob) {
  const { id } = action;
  const allOptions = yield* select(selectors.options.$allOptions);
  const pa = yield* select(selectors.singles.$pa, id);

  if (!pa) {
    return;
  }

  const { didCancel, value } = yield prompt.form({
    component: Form,
    title: 'PA settings',
    form: {
      config: forms.pa,
      data: {
        ...pa,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
      isEdit: true,
    },
  });

  if (didCancel) {
    return;
  }

  yield put(actions.pas.patch(id, value));

  toast.show('PA updated', 'success');
}

export function* pas(action: ActionJob) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('PAS', pas);
}
