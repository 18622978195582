import { ListItemIcon, MenuItem } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
`;

export const ListIcon = styled(ListItemIcon)`
  min-width: 32px !important;

  svg {
    fill: rgba(0, 0, 0, 0.87);
    width: 20px;
  }
`;

export const Action = styled(MenuItem)`
  color: rgba(0, 0, 0, 0.87);

  &.delete {
    color: #d20f03;

    svg {
      fill: #d20f03;
    }
  }
`;
