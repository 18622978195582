import styled from 'styled-components';
import { Button } from '@mui/material';

export const Wrapper = styled(Button)`
  span {
    font-size: 11px;
    margin-left: 4px;
    margin-right: 4px;
  }
  max-height: 42px;
`;

export const Loading = styled.div`
  max-height: 24px;
  overflow: visible;
`;

export const LoadingHiddenText = styled.div`
  max-height: 0;
  overflow: hidden;
`;
