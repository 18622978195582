export function JavascriptIcon({ style }: { style: React.CSSProperties }) {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      xmlns='http://www.w3.org/2000/svg'
      fill='#626B78'
    >
      <path
        d='M7.65625 8.00003C7.65625 7.58425 7.99331 7.24719 8.40909 7.24719H11.4091C11.8249 7.24719 12.1619 7.58425 12.1619 8.00003V16C12.1619 16.2609 12.0268 16.5033 11.8049 16.6404C11.5829 16.7776 11.3058 16.7901 11.0724 16.6734L9.07241 15.6734C8.70052 15.4874 8.54979 15.0352 8.73573 14.6633C8.92167 14.2915 9.37388 14.1407 9.74577 14.3267L10.6563 14.7819V8.75287H8.40909C7.99331 8.75287 7.65625 8.41581 7.65625 8.00003Z'
        style={style}
      />
      <path
        d='M14.9091 7.24719C14.5768 7.24719 14.2581 7.37918 14.0232 7.61414C13.7882 7.84909 13.6562 8.16776 13.6562 8.50003V11.5C13.6562 11.8323 13.7882 12.151 14.0232 12.3859C14.2581 12.6209 14.5768 12.7529 14.9091 12.7529H16.0405L15.7353 14.8925L14.2265 15.2697C13.8231 15.3705 13.5779 15.7793 13.6787 16.1826C13.7796 16.586 14.1883 16.8312 14.5917 16.7304L16.5917 16.2304C16.8887 16.1561 17.1111 15.9094 17.1544 15.6064L17.5725 12.6754C17.5976 12.4979 17.5843 12.317 17.5335 12.145C17.4827 11.973 17.3955 11.814 17.2779 11.6786C17.1604 11.5432 17.0151 11.4346 16.8519 11.3602C16.6888 11.2857 16.5114 11.2472 16.3321 11.2472H15.1619V8.75287H17.4091C17.8249 8.75287 18.1619 8.41581 18.1619 8.00003C18.1619 7.58425 17.8249 7.24719 17.4091 7.24719H14.9091Z'
        style={style}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.34155 3.50538C4.48453 3.34133 4.69149 3.24717 4.9091 3.24717H20.9091C21.1267 3.24717 21.3337 3.34133 21.4766 3.50538C21.6196 3.66943 21.6846 3.88731 21.6549 4.10288L19.6549 18.6029C19.6157 18.8867 19.4189 19.1236 19.1472 19.2142L13.1472 21.2142C12.9926 21.2657 12.8256 21.2657 12.671 21.2142L6.67103 19.2142C6.39926 19.1236 6.20246 18.8867 6.16332 18.6029L4.16332 4.10288C4.13358 3.88731 4.19858 3.66943 4.34155 3.50538ZM5.7729 4.75286L7.59096 17.9337L12.9091 19.7064L18.2272 17.9337L20.0453 4.75286H5.7729Z'
        style={style}
      />
    </svg>
  );
}
