interface ArrowDropIconProps {
  style?: React.CSSProperties;
}

export function ArrowDropIcon({ style }: ArrowDropIconProps) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path d='M11.333 6.667 8 10 4.667 6.667h6.666z' fill='#626B78' />
    </svg>
  );
}
