import { WebRoutes } from './../../../../web/src/bootstrap/Web.routes';
import { IApi, IModel, IUser } from '../types';
import { isBetaAvailable } from './modelUtils';

export const resolveUrlForJambaModel = (model: IModel) => {
  return `${WebRoutes.j2SingleChat}?modelId=${model.id}`;
};

/**
 * Resolves the url that the current foundation model should link to
 */
export const resolveUrlForFoundationModel = (foundationModel: IModel) => {
  const { id, layoutId } = foundationModel;

  switch (layoutId) {
    case 'chat':
      return WebRoutes.j2SingleChat;
    case 'complete':
    default:
      return `${WebRoutes.j2Complete1Input1Output}?modelId=${id}`;
  }
};

/**
 * Resolves whether the current task specific model refers
 * to an external link of docs (which is outside
 * of the webapp) or to a playground that lives within the app
 * TODO: kill this ASAP. this causes SO MANY issues. why do we separate the url from the component
 * definition?
 * opening ticket
 */
export const shouldTSMLinkToExternalDocs = (taskSpecificModel: IApi) => {
  const { id } = taskSpecificModel;

  const shouldLinkToDocs = [
    'text_segmentation_api',
    'summarization_by_segment_api',
    'embeddings_api',
    'semantic_search_api',
    'pdf_parser',
    'summarization_by_conversation_api',
  ].includes(id);

  return shouldLinkToDocs;
};
