import MuiInput from '@mui/material/Input';
import SliderMui from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { InputWrapper, Wrapper } from './Slider.style';
import React, { useEffect, useState } from 'react';

const Input = styled(MuiInput)`
  width: 42px;
`;

export type SliderProps = {
  value: number;
  step?: number;
  min?: number;
  max?: number;
  onChange: (ev: any) => void;
  isError?: boolean;
  isDisabled?: boolean;
};

export const Slider = React.forwardRef((props: SliderProps, ref: any) => {
  const { step = 10, min = 0, max = 100, isError, isDisabled, ...rest } = props;

  const [value, setValue] = useState<number | number[]>(props.value);

  const handleSliderChange = (_event: any, newValue: number | number[]) => {
    setValue(newValue);
  };

  function onChangeCommitted(event: any, value?: number | number[]) {
    props.onChange({
      target: {
        value,
      },
    });
  }

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const marks = [
    { value: min, label: min },
    { value: max, label: max },
  ];

  return (
    <Wrapper className='Slider-wrapper' data-testid='Slider-wrapper'>
      <SliderMui
        value={value}
        onChange={handleSliderChange}
        onChangeCommitted={onChangeCommitted}
        aria-labelledby='input-slider'
        min={min}
        max={max}
        step={step}
        color='secondary'
        size='small'
        valueLabelFormat={() =>
          marks.findIndex((mark) => mark.value === value) + 1
        }
        marks={marks}
      />
      <InputWrapper>
        <Input
          {...rest}
          value={value}
          size='small'
          inputProps={{
            step,
            min,
            max,
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
        />
      </InputWrapper>
    </Wrapper>
  );
});

export default Slider;
