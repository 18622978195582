import {
  Autocomplete,
  AutocompleteProps,
  Popper,
  TextField,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMount } from 'react-use';
import { IOption } from '../../types';
import { GroupHeader, GroupItems, Wrapper } from './Auto.style';

export type AutoProps = AutocompleteProps<any, any, any, any> & {
  value: string;
  options: IOption[];
  isError?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  onChange: (items: string[]) => void;
  groupFieldId?: string;
};

export const Auto = React.forwardRef((props: AutoProps, ref: any) => {
  const {
    options,
    isError,
    isDisabled,
    value,
    placeholder,
    groupFieldId = 'groupId',
    ...rest
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const refInput = useRef<HTMLInputElement>(null);

  useMount(() => {
    if (wrapperRef.current) {
      setWidth(wrapperRef.current.clientWidth);
    }
  });

  useEffect(() => {
    if (!refInput.current) {
      return;
    }

    refInput.current.value = value;
  }, [value]);

  function onChange(_event: React.SyntheticEvent, value: any) {
    props.onChange(value.id);
  }

  const valueItem = useMemo(() => {
    return options.find((option) => option.id === value);
  }, [value]);

  function isOptionEqualToValue(option: IOption, value: IOption) {
    return option?.id === value?.id;
  }

  function renderPopper(params: any) {
    const minWidth = Math.max(width, 170);

    return (
      <Popper
        {...params}
        style={{ minWidth: minWidth + 'px', width: 'auto' }}
        placement='bottom-start'
      />
    );
  }

  function renderInput(params: any) {
    return <TextField ref={refInput} {...params} placeholder={placeholder} />;
  }

  return (
    <Wrapper
      className='Auto-wrapper'
      data-testid='Auto-wrapper'
      ref={wrapperRef}
    >
      <Autocomplete
        // open={true}
        ref={ref}
        autoHighlight
        fullWidth
        options={options}
        groupBy={(option) => option[groupFieldId]}
        getOptionLabel={(option) => option.text ?? ''}
        disableClearable={true}
        filterSelectedOptions={false}
        isOptionEqualToValue={isOptionEqualToValue}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader color='secondary'>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        {...rest}
        value={valueItem}
        PopperComponent={renderPopper}
        onChange={onChange}
        renderInput={renderInput}
      />
    </Wrapper>
  );
});

export default Auto;
