import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  background: #fbfbfb;
  overflow-x: scroll;

  .MuiAccordionSummary-expandIconWrapper {
    right: 10px;
    position: absolute;
  }
`;
