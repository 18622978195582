import { app as appDocs } from '@ai21/studio-app-document-library';
import { app as appEvaluation } from '@ai21/studio-app-evaluation';
import { app as appBatchGenerate } from '@ai21/studio-app-batch-generate';
import { app as appDemos } from '@ai21/studio-app-demos';
import { app as appPlay } from '@ai21/studio-app-play';
import { app as appCollections } from '@ai21/studio-app-collections';
import { app as appShowroom } from '@ai21/studio-app-showroom';

export const allApps = {
  evaluation: {
    id: 'evaluation',
    name: 'Evaluation',
    localStorageKey: 'app_evaluation_enabled',
    app: appEvaluation,
  },
  ragEngine: {
    id: 'ragEngine',
    name: 'Rag Engine',
    localStorageKey: 'app_ragEngine_enabled',
    app: appDocs,
  },
  batchGenerate: {
    id: 'batchGenerate',
    name: 'Batch Generate',
    localStorageKey: 'app_batchGenerate_enabled',
    app: appBatchGenerate,
  },
  play: {
    id: 'play',
    name: 'Play',
    localStorageKey: 'app_play_enabled',
    app: appPlay,
  },
  collections: {
    id: 'collections',
    name: 'Collections',
    localStorageKey: 'app_collections_enabled',
    app: appCollections,
  },
  demos: {
    id: 'demos',
    name: 'Demos',
    localStorageKey: 'app_demos_enabled',
    app: appDemos,
  },
  showroom: {
    id: 'showroom',
    name: 'Showroom',
    localStorageKey: 'app_showroom_enabled',
    app: appShowroom,
  },
};
