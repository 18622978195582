import { apiUrl } from '../globals';
import { IChatMessage } from '../types';
import {
  getChatCurlCodeSnippet,
  getChatJsCodeSnippet,
  getChatPythonCodeSnippet,
} from './codeBlocks';
import { GenerateSnippetsParams } from './snippets.types';
import { formatChatMessages } from './snippets.utils';

const model = 'jamba-instruct-preview';

const url = (apiHost: string) => `${apiHost}/chat/completions`;

export const generate = (params: GenerateSnippetsParams) => {
  params.messages = formatChatMessages(params?.messages);

  return {
    python: python(params),
    javascript: javascript(params),
    curl: curl(params),
  };
};

const python = (params: GenerateSnippetsParams) => {
  const { messages, controllerParams, modelName, system } = params as any;
  return getChatPythonCodeSnippet(messages, system, modelName, controllerParams);
};

const javascript = (params: GenerateSnippetsParams) => {
  const { messages, system, controllerParams, modelName } = params as any;

  const path = url(apiUrl);

  return getChatJsCodeSnippet(path, messages, system, modelName, controllerParams);
};
const curl = (params: GenerateSnippetsParams) => {
  const { messages, system, controllerParams, modelName } = params;

  const path = url(apiUrl);

  return getChatCurlCodeSnippet(path, messages, system, modelName, controllerParams);
};
