import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { experimentConfig } from '../config/experiment.config';
import { ExperimentClient } from '../ExperimentClient';
import type VariantsClient from '../VariantsClient';

/**********************************************************************
 * ********************************************************************
 * **                                                              ****
 * **  Due to the weird nature of how this app behaves with user   ****
 * **  authentication, it breaks the logic of the @ai21/experiment ****
 * **  package and renders the experiment context useless.         ****
 * **  This is an ugly workaround :(                               ****
 * **                                                              ****
 * ********************************************************************
 **********************************************************************/

export type RawUser = {
  userId: string;
};

const experimentClient = ExperimentClient.getInstance(experimentConfig);

/**
 * A hook to fetch experiment variants for a given user.
 */
export const useFetchVariants = (user: RawUser) => {
  const [variantsClient, setVariantsClient] = useState<VariantsClient | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    if (!user?.userId) return;

    (async () => {
      try {
        const client = await experimentClient.fetchVariants();
        setVariantsClient(client);
      } catch (e) {
        console.error('Error initializing experiment: ', e);
      } finally {
        setIsFetching(false);
      }
    })();
  }, [user]);

  return { isFetching, variantsClient };
};

/**
 * A hook for accessing specific experiment variant values.
 * @param variantName The name or names of the variant(s) to access. Can be a single string or an array of strings.
 */
export const useExperiment = (variantName: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState<(string | undefined)[]>([]);
  const user: RawUser = useSelector((state: any) => state.user);

  const { variantsClient } = useFetchVariants(user);

  useEffect(() => {
    if (!variantsClient) return;

    setValues([variantsClient.get(variantName)?.value]);

    setIsLoading(false);
  }, [variantName, variantsClient]);

  return { isLoading, values };
};
