import { apiUrl } from '../globals';
import {
  getSummarizationCurlCodeSnippet,
  getSummarizationJsCodeSnippet,
  getSummarizationPythonCodeSnippet,
} from './codeBlocks';
import { GenerateSnippetsParams } from './snippets.types';
import { sanitizeCode } from '../utils/snippets';

const url = (apiHost: string) => `${apiHost}/summarize`;

export const generate = (params: GenerateSnippetsParams) => {
  return {
    python: python(params),
    javascript: javascript(params),
    curl: curl(params),
  };
};

const python = (params: GenerateSnippetsParams) => {
  const { main, apiParams } = params as any;
  return getSummarizationPythonCodeSnippet(url(apiUrl), sanitizeCode(main), apiParams?.sourceType);
};

const javascript = (params: GenerateSnippetsParams) => {
  const { main, apiParams } = params as any;
  return getSummarizationJsCodeSnippet(url(apiUrl), sanitizeCode(main), apiParams?.sourceType);
};

const curl = (params: GenerateSnippetsParams) => {
  const { main, apiParams } = params as any;
  return getSummarizationCurlCodeSnippet(url(apiUrl), sanitizeCode(main), apiParams?.sourceType);
};
