import React from 'react';

const CollectionsPage = React.lazy(
  () => import('./collectionsPage/CollectionsPage.container')
);

const CollectionLines = React.lazy(
  () => import('./collectionLinesPage/CollectionLinesPage.container')
);

export const pages: Record<string, React.FC<any>> = {
  collectionSets: CollectionsPage,
  collectionLines: CollectionLines,
};
