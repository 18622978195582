import { call, select, takeEvery } from 'saga-ts';
import { api } from '@ai21/studio-api';
import { actions, selectors } from '@ai21/studio-store';
import { downloadUrl } from '../utils/download';

type ActionJob = {
  type: 'EVALUATION_EXTRACT_FTS';
  id: string;
};

export function* extractFts(action: ActionJob) {
  const { id } = action;

  const FineTuningSet = yield* select(selectors.singles.$fts, id);
  const { name } = FineTuningSet;
  const response = yield* call(api.fts.getDownloadUrl, id, name);
  const { dataset_url } = response.data;

  // get json from url
  const data = downloadUrl(dataset_url);
}

export function* root() {
  yield takeEvery('EVALUATION_EXTRACT_FTS', extractFts);
}
