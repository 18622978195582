import { fork } from 'saga-ts';
import { root as api } from './saga.api';
import { root as apiSync } from './saga.apiSync';
import { root as demos } from './saga.demos';

export function* root() {
  yield* fork(api);
  yield* fork(apiSync);
  yield* fork(demos);
}
