import { MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Dropdown = styled(Select)`
  width: 260px;
  border-radius: 8px;
`;

export const Item = styled(MenuItem)`
  padding: 0;
  height: 35px;
  background-color: white;
  margin: 4px 0;

  .MuiMenuItem-root {
    background-color: transparent;
  }
`;
