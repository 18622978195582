import { AuthState } from './authState';
import { Firebase } from './firebase';

export let authState: AuthState;
export let firebase: Firebase;
export let store: any;

export const setAuthState = (state: AuthState) => {
  authState = state;
};

export const setFirebase = (value: Firebase) => {
  firebase = value;
};

export const setStore = (value: any) => {
  store = value;
};

export const dispatchP = async (action: any) => {
  const newAction = addPromiseToAction(action);
  store.dispatch(newAction);
  return newAction.promise;
};

const addPromiseToAction = (action: any) => {
  const promise = new Promise((resolve, reject) => {
    action.resolve = resolve;
    action.reject = reject;
  });
  action.promise = promise;
  return action;
};
