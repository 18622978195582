export function BlueCircles() {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_15_2648)'>
        <rect width='48' height='48' rx='2' fill='#94BCFF' />
        <g filter='url(#filter0_ddd_15_2648)'>
          <rect x='13' y='11' width='31' height='31' rx='15.5' fill='white' />
        </g>
        <g filter='url(#filter1_ddd_15_2648)'>
          <rect x='5' y='4' width='18' height='18' rx='9' fill='#2979FF' />
          <rect x='5' y='4' width='18' height='18' rx='9' fill='black' fillOpacity='0.2' />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_ddd_15_2648'
          x='-7.66667'
          y='-7.6'
          width='72.3333'
          height='72.3333'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2.06667' />
          <feGaussianBlur stdDeviation='10.3333' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_15_2648' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='8.26667' />
          <feGaussianBlur stdDeviation='5.16667' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_15_2648'
            result='effect2_dropShadow_15_2648'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='2.06667'
            operator='erode'
            in='SourceAlpha'
            result='effect3_dropShadow_15_2648'
          />
          <feOffset dy='4.13333' />
          <feGaussianBlur stdDeviation='4.13333' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend
            mode='normal'
            in2='effect2_dropShadow_15_2648'
            result='effect3_dropShadow_15_2648'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect3_dropShadow_15_2648'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_ddd_15_2648'
          x='-5'
          y='-5'
          width='38'
          height='38'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_15_2648' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_15_2648'
            result='effect2_dropShadow_15_2648'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect3_dropShadow_15_2648'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend
            mode='normal'
            in2='effect2_dropShadow_15_2648'
            result='effect3_dropShadow_15_2648'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect3_dropShadow_15_2648'
            result='shape'
          />
        </filter>
        <clipPath id='clip0_15_2648'>
          <rect width='48' height='48' rx='2' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
