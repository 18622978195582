import { PresetLabel } from '../../data-types/PresetParams';

export const PRESET_LABEL_CLICKED = 'PRESET_LABEL_CLICKED';
export const PRESET_LABELS_CLEARED = 'PRESET_LABELS_CLEARED';
export const PRESET_SEARCH_VALUE_CHANGED = 'PRESET_SEARCH_VALUE_CHANGED';
export const PRESET_SEARCH_VALUE_CLEARED = 'PRESET_SEARCH_VALUE_CLEARED';
export const NAVIGATOR_CLICKED = 'NAVIGATOR_CLICKED';

export interface PresetLabelClicked {
  type: typeof PRESET_LABEL_CLICKED;
  presetLabel: PresetLabel;
  clicked: boolean;
}

export interface PresetLabelsCleared {
  type: typeof PRESET_LABELS_CLEARED;
}

export interface PresetSearchValueChanged {
  type: typeof PRESET_SEARCH_VALUE_CHANGED;
  searchValue: string;
}

export interface PresetSearchValueCleared {
  type: typeof PRESET_SEARCH_VALUE_CLEARED;
}

export interface NavigatorClicked {
  type: typeof NAVIGATOR_CLICKED;
  buttonText: string;
  destination: string;
  origin: string;
  path: string;
}

export type OverviewActionTypes =
  | PresetLabelClicked
  | PresetLabelsCleared
  | NavigatorClicked
  | PresetSearchValueChanged
  | PresetSearchValueCleared;
