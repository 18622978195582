import { apiUrl } from '../globals';
import { sanitizeCode } from '../utils/snippets';
import {
  getLibraryCurlCodeSnippet,
  getLibraryJsCodeSnippet,
  getLibraryPythonCodeSnippet,
} from './codeBlocks';
import { GenerateSnippetsParams } from './snippets.types';

const url = (apiHost: string) => `${apiHost}/library/answer`;

export const generate = (params: GenerateSnippetsParams) => {
  return {
    python: python(params),
    javascript: javascript(params),
    curl: curl(params),
  };
};

const python = (params: GenerateSnippetsParams) => {
  const { main } = params as any;
  return getLibraryPythonCodeSnippet(url(apiUrl), sanitizeCode(main));
};

const javascript = (params: GenerateSnippetsParams) => {
  const { main } = params as any;
  return getLibraryJsCodeSnippet(url(apiUrl), sanitizeCode(main));
};

const curl = (params: GenerateSnippetsParams) => {
  const { main } = params as any;
  return getLibraryCurlCodeSnippet(url(apiUrl), sanitizeCode(main));
};
