import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .Form-wrapper {
    margin-top: -16px;

    .Mui-focused fieldset {
      border-color: ${tokens.primary.mainDefault};
    }
  }

  .Presets-wrapper + .Form-wrapper {
    margin-top: 0;
  }
`;
