import {
  IWidgetConfig,
  Input,
  InputToPayload,
  ResponseToOutput,
  WidgetPayload,
  OutputToAnalyticsPayload,
} from '../MiniPlayground.types';

export const config: IWidgetConfig = {
  title: 'Summarize API',
  ctaText: 'Summarize',
  tooltipText: 'API supports texts up to 50,000 characters',
  defaultInput: {
    main: "Rob Roussel, senior project manager, said the bioremediation process was successfully breaking down the oil into carbon dioxide and water. He said the process was at the mercy of the weather, but would hopefully be complete by the end of next month. The oil was dumped in the quarry in the Vale after the tanker spill in 1967.\nThe 974ft (297m) Torrey Canyon was carrying 100,000 tons of crude oil when it hit the UK's south-west coast.\nThe shipwreck coated miles of Cornish beach in brown sludge with the pollution stretching from Hartland Point in North Devon to the Channel Islands and even the coast of Normandy.",
  },
  docsUrl: 'https://docs.ai21.com/reference/summarize-api-ref',
  noSkeletonBorder: true,
  inputType: 'basic',
  outputType: 'basic',
  apiMethodName: 'summarize',
  snippetMethodName: 'summarize',
  rules: [
    {
      ruleType: 'minLength',
      value: 30,
      errorMessage: 'Minimum of {value} characters is needed. You have {delta} characters left.',
    },
    {
      ruleType: 'maxLength',
      errorMessage:
        'Demo is limited to {value} characters. Use the API to summarize texts up to 50,000 characters.',
      value: 700,
      linkText: 'Use the API',
      linkUrl: 'https://docs.ai21.com/reference/summarize-api-ref',
    },
  ],
};

export const inTransformer: InputToPayload = (
  input: Input,
  params: Json = { sourceType: 'text' }
) => ({
  sourceType: (params.sourceType as string).toUpperCase(),
  source: input.main,
});

export const analyticsInputPayload = (input: Input, params: Json = { sourceType: 'text' }) => ({
  documentType: (params.sourceType as string).toUpperCase(),
  textLength: input.main.length,
});

export const outTransformer: ResponseToOutput = (response: Json, input: Input) => {
  const { data, isSuccess } = response;
  return {
    text: data.summary,
    errorMessage: data.detail,
    isSuccess,
    originalInput: input,
    count: data.summary.length,
    unit: 'characters',
  };
};

export const analyticsOutputPayload: OutputToAnalyticsPayload = (response: Json) => {
  const { data, duration, request } = response;
  return {
    generatedTextLength: data.summary?.length,
    errorMessage: data.detail,
    requestDuration: duration,
    interactionId: data.id,
    endpoint: request.path,
  };
};

export const payload: WidgetPayload = {
  id: 'summarize',
  config,
  inTransformer,
  outTransformer,
  analyticsInputPayload,
  analyticsOutputPayload,
};
