import { snakeCase } from 'lodash';

type Json = Record<string, any>;

export type Meta = {
  layout: string;
  variables: Json;
  instructions?: string;
  context?: string;
  shots?: Json[];
  isChat: boolean;
  isMimic: boolean;
};

export const addVariables = (prompt: string, meta: Meta) => {
  const { variables = {} } = meta;

  let output = prompt;

  Object.keys(variables).forEach((key) => {
    const value = variables[key];
    output = output.replace(new RegExp(`{{${key}}}`, 'g'), value);
    output = output.replace(new RegExp(`{{${snakeCase(key)}}}`, 'g'), value);
  });

  return output;
};

export const addContext = (prompt: string, meta: Meta) => {
  const { isMimic, isChat, instructions = '', context = '', shots = [] } = meta;

  let output = prompt;

  if (isChat) {
    output = [instructions, context].join('\n');
  }

  if (isMimic) {
    output = [instructions, shots.join('\n'), context, prompt].join('\n');
  }

  return output;
};

export const findVariables = (prompt: string) => {
  const regex = /{{(.*?)}}/g;
  const matches = [];
  let match;

  while ((match = regex.exec(prompt))) {
    matches.push(match[1]);
  }

  return matches;
};

const instructions = `I'm a software developer, writing and experimenting with LLM prompts as part of developing a product or a feature that integrates with LLM to provide a rich user experience.
My goal is the write prompts that will best for prompting an LLM to generate the outputs I need, both in terms of quality and consistency.

In your response, include only the suggested prompt. Do not explain your choices. No need for a title. The second part of the response must start with the title "Suggested Prompt", followed by the prompt you suggest based on my instructions.

Instructions: improve the following prompt: `;

export const improvePrompt = (prompt: string) => {
  return instructions + prompt;
};

//
