export function CelebrationIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.682 3.273a.75.75 0 0 1 .546.91l-.5 2a.75.75 0 0 1-1.455-.365l.5-2a.75.75 0 0 1 .91-.545zM5.75 4a.75.75 0 1 0-1.5 0v.25H4a.75.75 0 1 0 0 1.5h.25V6a.75.75 0 0 0 1.5 0v-.25H6a.75.75 0 1 0 0-1.5h-.25V4zM18 4.25a.75.75 0 0 0 0 1.5h.25V6a.75.75 0 0 0 1.5 0v-.25H20a.75.75 0 0 0 0-1.5h-.25V4a.75.75 0 0 0-1.5 0v.25H18zM15.53 9.53a.75.75 0 1 0-1.06-1.06l-1 1a.75.75 0 1 0 1.06 1.06l1-1zM20.728 12.318a.75.75 0 0 1-.546.91l-2 .5a.75.75 0 0 1-.363-1.455l2-.5a.75.75 0 0 1 .91.545zM18 18.25a.75.75 0 0 0 0 1.5h.25V20a.75.75 0 0 0 1.5 0v-.25H20a.75.75 0 0 0 0-1.5h-.25V18a.75.75 0 0 0-1.5 0v.25H18z'
        fill='#626B78'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.013 9.47a.75.75 0 0 0-1.212.218l-4.39 9.58a1.753 1.753 0 0 0 2.322 2.323l9.58-4.391a.75.75 0 0 0 .218-1.212L8.013 9.47zM3.775 19.892l3.94-8.599 4.993 4.993-8.598 3.941a.253.253 0 0 1-.335-.335z'
        fill='#626B78'
      />
    </svg>
  );
}

export default CelebrationIcon;
