import { call, select, takeEvery } from 'saga-ts';
import { predicateBox } from './predicates';
import { get, uniq } from 'lodash';
import { IBox, selectors } from '@ai21/studio-store';
import { findVariables } from '../utils/prompt';
import { patchBox } from './helpers/boxes';
import { sortBy } from 'shared-base';

export function* onBoxChange(action: any) {
  const prompt = get(action, 'payload.values.prompt', '');
  const variablesBox = yield* select(selectors.playground.$variablesBox);

  if (!prompt || !variablesBox) {
    return;
  }

  const currentVariables = yield* select(selectors.playground.$variables);
  const currentVariablesKeys = Object.keys(currentVariables);
  const { fields = [] } = currentVariables;

  const variables = findVariables(prompt);

  const missingVariables = variables.filter(
    (v) => !currentVariablesKeys.includes(v)
  );

  const redundantVariables = fields.filter(
    (v: string) => !variables.includes(v)
  );

  const newFields = [...fields, ...missingVariables]
    .filter((v: string) => !redundantVariables.includes(v))
    .map((name) => ({
      name,
      order: variables.indexOf(name),
    }))
    .sort(sortBy('order'))
    .map(({ name }) => name);

  yield* call(patchBox, variablesBox, {
    fields: uniq(newFields),
  });
}

export function* root() {
  yield takeEvery(predicateBox('-input'), onBoxChange);
}
