import { createContext, useEffect, useMemo } from 'react';
import { useMount, useSetState } from 'react-use';
import { IAskContext, IAskProviderProps, IAskState, Verb } from './AskEditor.types';

export const AskContext = createContext<IAskContext>({
  state: {
    docId: '',
  },
  callbacks: {
    onAction: (verb: Verb, _params?: Json) => {},
    onPrompt: (_params?: Json) => {},
    onClose: () => {},
  },
  patchState: () => {},
});

export const InboxProvider = (props: IAskProviderProps) => {
  const { docId, callbacks: inCallbacks, emptyStateMessage } = props;
  const [state, patchState] = useSetState<IAskState>({
    docId: '',
    isMac: false,
  });

  useEffect(() => {
    patchState({
      docId: docId ?? '',
    });
  }, [docId]);

  useMount(() => {
    var isMac = navigator.userAgent.toLocaleLowerCase().indexOf('mac') >= 0;

    patchState({
      isMac,
    });
  });

  const callbacks = useMemo(
    () => ({
      onAction: (verb: Verb, params?: Json) => {
        inCallbacks.onAction(verb, params);
      },
      onPrompt: (params?: Json) => {
        inCallbacks.onPrompt(params);
      },
      onClose: () => {
        inCallbacks.onClose();
      },
    }),
    [state, inCallbacks]
  );

  const cValue = useMemo(
    () => ({
      state,
      patchState,
      callbacks,
    }),
    [state, callbacks]
  );

  return <AskContext.Provider value={cValue}>{props.children}</AskContext.Provider>;
};
