export const experimentConfig = {
  clientIdentifier: 'language-studio-web',
  // @ts-expect-error: __env__ is a global variable injected by Vite
  amplitudeExperimentApiKey: window.__env__.VITE_AMPLITUDE_EXPERIMENT_API_KEY,
  // @ts-expect-error: __env__ is a global variable injected by Vite
  debug: window.__env__.VITE_ENV === 'development',
  // The server url's are the same for all the environments
  amplitudeServerUrl: 'https://api.lab.amplitude.com',
  amplitudeFlagsServerUrl: 'https://flag.lab.amplitude.com',
  // Placeholder function for trackEvent.
  // We don't have to use it, but it's required to be defined.
  trackEvent: () => undefined,
};
