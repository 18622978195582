import { MenuBuilder, RouterBuilder } from '@ai21/studio-ui';
import { pages } from '../pages';
import { privateRoutes, publicRoutes } from './App.routes';
import { sideBarGroups, sideBarItems } from './SideBar.data';
import { topBarGroups, topBarItems } from './TopBar.data';

export const initRoutes = (builder: RouterBuilder) => {
  builder
    .withPublicRoutes(publicRoutes)
    .withPrivateRoutes(privateRoutes)
    .withPages(pages, false);
};

export const initSideBar = (builder: MenuBuilder) => {
  builder.withItems(sideBarItems).withGroups(sideBarGroups);
};

export const initTopBar = (builder: MenuBuilder) => {
  builder.withItems(topBarItems).withGroups(topBarGroups);
};
