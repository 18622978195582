import { tokens } from '@ai21/studio-ui';
import styled from 'styled-components';

export const Wrapper = styled('div')`
  font-size: 14px;
  background-color: #fbfbfb;
  border-radius: 4px;
  border: 1px solid ${tokens.primary.borderDefault};
  min-height: calc(100vh);
  margin-top: 50px;
  padding: 12px;
`;

export const Document = styled('div')`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
`;
export const Preview = styled('div')`
  width: auto;
  display: flex;
  border-bottom: 1px solid ${tokens.primary.borderDefault};
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

export const DocumentsPreviewTitle = styled('div')`
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  color: #767676;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const DocumentLibraryTitle = styled('div')`
  font-family: Inter;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  padding-bottom: 8px;
  border-bottom: 1px solid ${tokens.primary.borderDefault};

  svg {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    stroke-width: 1.5px;
  }
`;

export const PreviewEmptyState = styled('div')`
  font-family: Inter;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  color: #7d8693;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  padding: 24px;

  svg {
    margin-bottom: 16px;
    width: 56px;
    height: 56px;
    color: #7d8693;
  }
`;

export const DocumentsPreview = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid ${tokens.primary.borderDefault};
  overflow: scroll;
  height: 600px;
`;

export const Documents = styled('div')`
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid ${tokens.primary.borderDefault};
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  background-color: #f4f5f7;
`;
