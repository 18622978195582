export function RoutingIcon() {
  return (
    <svg
      width='47px'
      height='52px'
      viewBox='0 0 47 52'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>jx-routing</title>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeDasharray='169'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g
          id='jx-routing'
          transform='translate(2.000000, 2.000000)'
          stroke='#626B78'
          strokeWidth='4'
        >
          <path
            d='M5.3335,0 C8.27911071,0 10.667,2.38788929 10.667,5.3335 C10.667,8.27911071 8.27911071,10.667 5.3335,10.667 C2.38788929,10.667 1.77635684e-15,8.27911071 1.77635684e-15,5.3335 C1.77635684e-15,2.38788929 2.38788929,0 5.3335,0 Z M37.3335,37.333 C40.2791107,37.333 42.667,39.7208893 42.667,42.6665 C42.667,45.6121107 40.2791107,48 37.3335,48 C34.3878893,48 32,45.6121107 32,42.6665 C32,39.7208893 34.3878893,37.333 37.3335,37.333 L37.3335,37.333 Z M21.3335,5.333 L33.3335,5.333 C38.4882497,5.333 42.667,9.51175029 42.667,14.6665 C42.667,19.8212497 38.4882497,24 33.3335,24 L11.9995,24 C6.84475029,24 2.666,28.1787503 2.666,33.3335 C2.666,38.4882497 6.84475029,42.667 11.9995,42.667 L21.3335,42.667'
            id='Shape'
          />
        </g>
      </g>
    </svg>
  );
}
