import { selectors } from '@ai21/studio-store';
import { call, delay, select, takeEvery } from 'saga-ts';
import { customEvenChannel } from './channels/channel.customEvent';
import { patchBox } from './helpers/boxes';

function* changeModel(event: any) {
  const { data } = event;
  const { modelId } = data;

  yield delay(100);

  const outputBoxes = yield* select(selectors.playground.$outputBoxes);
  const paramsBox = yield* select(selectors.playground.$paramsBox);

  for (let box of outputBoxes) {
    yield* call(patchBox, box, { modelId });
  }

  if (paramsBox) {
    yield* call(patchBox, paramsBox, { modelId });
  }
}

export function* root() {
  const channel = customEvenChannel('play/changeModel');
  yield takeEvery(channel, changeModel);
}
