export function ScaleIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V4.36465L18.1233 5.2602C18.36 5.29965 18.5635 5.44997 18.6708 5.66459L21.6708 11.6646C21.7229 11.7687 21.75 11.8836 21.75 12C21.75 14.0711 20.0711 15.75 18 15.75C15.9289 15.75 14.25 14.0711 14.25 12C14.25 11.8836 14.2771 11.7687 14.3292 11.6646L16.875 6.57285L12.75 5.88535V19.25H17C17.4142 19.25 17.75 19.5858 17.75 20C17.75 20.4142 17.4142 20.75 17 20.75H7C6.58579 20.75 6.25 20.4142 6.25 20C6.25 19.5858 6.58579 19.25 7 19.25H11.25V5.88535L7.12495 6.57285L9.67082 11.6646C9.72289 11.7687 9.75 11.8836 9.75 12C9.75 14.0711 8.07107 15.75 6 15.75C3.92893 15.75 2.25 14.0711 2.25 12C2.25 11.8836 2.27711 11.7687 2.32918 11.6646L5.32918 5.66459C5.43649 5.44997 5.64001 5.29965 5.8767 5.2602L11.25 4.36465V3C11.25 2.58579 11.5858 2.25 12 2.25ZM6 7.67705L8.24403 12.1651C8.15949 13.3307 7.18711 14.25 6 14.25C4.81289 14.25 3.84051 13.3307 3.75597 12.1651L6 7.67705ZM18 7.67705L15.756 12.1651C15.8405 13.3307 16.8129 14.25 18 14.25C19.1871 14.25 20.1595 13.3307 20.244 12.1651L18 7.67705Z'
      />
    </svg>
  );
}
