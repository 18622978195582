export function InfoCircleIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 2.8125C5.58274 2.8125 2.8125 5.58274 2.8125 9C2.8125 12.4173 5.58274 15.1875 9 15.1875C12.4173 15.1875 15.1875 12.4173 15.1875 9C15.1875 5.58274 12.4173 2.8125 9 2.8125ZM1.6875 9C1.6875 4.96142 4.96142 1.6875 9 1.6875C13.0386 1.6875 16.3125 4.96142 16.3125 9C16.3125 13.0386 13.0386 16.3125 9 16.3125C4.96142 16.3125 1.6875 13.0386 1.6875 9ZM8.4375 6C8.4375 5.68934 8.68934 5.4375 9 5.4375H9.0075C9.31816 5.4375 9.57 5.68934 9.57 6C9.57 6.31066 9.31816 6.5625 9.0075 6.5625H9C8.68934 6.5625 8.4375 6.31066 8.4375 6ZM7.6875 8.25C7.6875 7.93934 7.93934 7.6875 8.25 7.6875H9C9.31066 7.6875 9.5625 7.93934 9.5625 8.25V11.4375H9.75C10.0607 11.4375 10.3125 11.6893 10.3125 12C10.3125 12.3107 10.0607 12.5625 9.75 12.5625H9C8.68934 12.5625 8.4375 12.3107 8.4375 12V8.8125H8.25C7.93934 8.8125 7.6875 8.56066 7.6875 8.25Z'
        fill='black'
        fillOpacity='0.54'
      />
    </svg>
  );
}
