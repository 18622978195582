import { Tabs as TabsUI, Tab as TabUI } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Tabs = styled(TabsUI)``;

export const Tab = styled(TabUI)`
  font-size: 14px;
`;

export const Content = styled.div`
  flex: 1;
  overflow: auto;
  padding: 30px 30px 30px 70px;
`;
