import { prompt } from '@ai21/studio-ui';
import { put, takeEvery } from 'saga-ts';

export function* logoutAsk() {
  const { didCancel } = yield prompt.confirm({
    title: 'Logout',
    description: 'Are you sure you want to logout?',
    ctaButtonText: 'Logout',
    intention: 'logout',
  });

  if (didCancel) {
    return;
  }

  yield put({ type: 'LOGOUT' });
}

export function* root() {
  yield takeEvery('LOGOUT_ASK', logoutAsk);
}
