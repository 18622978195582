export function QAndAIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='6' fill='white' />
      <rect width='52' height='52' rx='6' fill='#AE6D59' fillOpacity='0.1' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.8699 18.4167C21.3135 18.4167 17.6199 22.1104 17.6199 26.6667C17.6199 31.2231 21.3135 34.9167 25.8699 34.9167C30.4262 34.9167 34.1199 31.2231 34.1199 26.6667C34.1199 22.1104 30.4262 18.4167 25.8699 18.4167ZM16.1199 26.6667C16.1199 21.282 20.4851 16.9167 25.8699 16.9167C31.2546 16.9167 35.6199 21.282 35.6199 26.6667C35.6199 32.0515 31.2546 36.4167 25.8699 36.4167C20.4851 36.4167 16.1199 32.0515 16.1199 26.6667ZM23.2724 22.2134C24.1318 21.0807 25.6125 20.6139 26.9662 21.0489C28.3198 21.4839 29.2514 22.726 29.29 24.1473C29.3286 25.5685 28.4658 26.8594 27.1378 27.3672L27.1187 27.3742C26.8036 27.485 26.5998 27.7908 26.6187 28.1242C26.6421 28.5378 26.3259 28.892 25.9123 28.9155C25.4988 28.939 25.1445 28.6227 25.1211 28.2092C25.0646 27.2129 25.671 26.2989 26.6097 25.9632C27.3387 25.6807 27.8117 24.9701 27.7905 24.188C27.7692 23.4031 27.2547 22.7172 26.5072 22.4769C25.7597 22.2367 24.9419 22.4945 24.4674 23.12C24.217 23.45 23.7465 23.5146 23.4166 23.2642C23.0866 23.0138 23.022 22.5434 23.2724 22.2134ZM25.8699 30.9168C26.2841 30.9168 26.6199 31.2525 26.6199 31.6668V31.6768C26.6199 32.091 26.2841 32.4268 25.8699 32.4268C25.4557 32.4268 25.1199 32.091 25.1199 31.6768V31.6668C25.1199 31.2525 25.4557 30.9168 25.8699 30.9168Z'
        fill='black'
      />
    </svg>
  );
}
