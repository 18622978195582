import { actions, selectors } from '@ai21/studio-store';
import { drawer } from '@ai21/studio-ui';
import { put, select, takeEvery } from 'saga-ts';
import { createFirestoreChannel } from './channels/channel.firestore';
import { predicateCurrentId } from './predicates';
import { InboxEditorContainer } from '../containers/InboxEditor.container';

type ActionCurrentId = {
  type: 'PATCH_CURRENTIDS';
  payload: {
    evaluationJobId: string;
    evaluationLineId: string;
  };
};

let channel: any;

export function* onRemoteLineChange(action: any) {
  const { data } = action;

  yield put(actions.evaluationLines.set(data.id, data));
}

export function* onJobIdChange(action: ActionCurrentId) {
  const { payload } = action;
  const { evaluationJobId } = payload;

  if (channel) {
    channel.close();
  }

  channel = createFirestoreChannel(evaluationJobId);
  yield takeEvery(channel, onRemoteLineChange);
}

export function* onLineChange(action: ActionCurrentId) {
  const { payload } = action;
  const { evaluationLineId } = payload;
  const job = yield* select(selectors.evaluation.$evaluationJob);
  const user = yield* select(selectors.raw.$rawUser);
  const line = yield* select(selectors.evaluation.$evaluationLine);

  if (!job || !line) {
    return;
  }

  yield drawer.open({
    component: InboxEditorContainer,
    variant: 'persistent',
    hideBackdrop: true,
  });

  yield put(
    actions.evaluationLines.patch(evaluationLineId, {
      lastOpenedAt: Date.now(),
      lastOpenedBy: user.userName,
      setId: job.id,
    })
  );
}

export function* root() {
  yield takeEvery(predicateCurrentId('evaluationJobId'), onJobIdChange);
  yield takeEvery(predicateCurrentId('evaluationLineId'), onLineChange);
}
