import { fireRequest } from './axios';
import {
  addToCollection,
  addToCollectionBatch,
  getCollection,
  listenToCollection,
  removeCollection,
  updateCollectionItem,
} from './firebase';

export const getJobs = (_extraParams?: Json) => {
  return getCollection('/generationJobs', 'organization');
};

export const createJob = (gs: Json) => {
  return addToCollection('/generationJobs', gs, 'organization');
};

export const updateJob = (setId: string, change: Json) => {
  return updateCollectionItem(`/generationJobs/${setId}`, change, 'organization');
};

export const removeJob = (setId: string) => {
  return removeCollection(`/generationJobs/${setId}`, 'organization');
};

export const getLines = (extraParams?: Json) => {
  const { setId } = extraParams || {};
  return getCollection(`/generationJobs/${setId}/lines`, 'organization');
};

export const createLines = (setId: string, items: Json[]) => {
  return addToCollectionBatch(`/generationJobs/${setId}/lines`, items, 'organization');
};

export const createLine = (setId: string, item: Json) => {
  return addToCollection(`/generationJobs/${setId}/lines`, item, 'organization');
};

export const updateLine = (setId: string, lineId: string, change: Json) => {
  return updateCollectionItem(`/generationJobs/${setId}/lines/${lineId}`, change, 'organization');
};

export const listenToLines = (setId: string, callback: any) => {
  return listenToCollection(`/generationJobs/${setId}/lines`, callback, 'organization');
};

export const transformToEvaluationSet = (setId: string) => {
  const path = `/sets/generation/${setId}/evaluation`;
  return fireRequest<Json>({
    path,
    method: 'post',
    endpoint: 'generation.transformToEvaluationSet',
  });
};

export const generation = {
  getJobs,
  createJob,
  updateJob,
  removeJob,
  getLines,
  updateLine,
  createLine,
  createLines,
  listenToLines,
  transformToEvaluationSet,
};
