import { ToggleButtonGroup } from '@mui/material';
import React, { ForwardedRef } from 'react';
import { IOption } from '../../types';
import { ToggleButton, Wrapper } from './BarSelect.style';
import { invokeEvent } from 'shared-base';

export type BarSelectProps = {
  value: string;
  options: IOption[];
  onChange: (id: string) => void;
  ['data-testid']?: string;
  source?: string;
};

export const BarSelect = React.forwardRef(
  (props: BarSelectProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { value, source, options = [] } = props;

    const handleChange = (ev: React.MouseEvent<HTMLElement>, id: string) => {
      props.onChange(id);

      invokeEvent('global/click', {
        source,
        ev,
      });
    };

    function renderOption(option: IOption) {
      return (
        <ToggleButton
          data-testid={props['data-testid']}
          value={option.id}
          key={option.id}
          size='small'
        >
          {option.text}
        </ToggleButton>
      );
    }

    function renderOptions() {
      return options.map((option) => renderOption(option));
    }

    return (
      <Wrapper className='BarSelect-wrapper' data-testid='BarSelect-wrapper'>
        <ToggleButtonGroup
          color='secondary'
          value={value}
          exclusive
          onChange={handleChange}
        >
          {renderOptions()}
        </ToggleButtonGroup>
      </Wrapper>
    );
  }
);

export default BarSelect;
