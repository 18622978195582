import { setTokens } from '@ai21/studio-api';
import { patchJson } from 'shared-base';
import { getJson } from '../../utils/storage';

const STORAGE_KEY = 'STUDIO_AUTH_KEYS';

export const setAccessToken = (token: string) => {
  setTokens({ firebaseIdToken: token });
  patchJson(STORAGE_KEY, { firebaseIdToken: token });
};

export const setApiToken = (token: string) => {
  setTokens({ studioApiKey: token });
  patchJson(STORAGE_KEY, { studioApiKey: token });
};

export const loadTokensFromStorage = () => {
  const tokens = getJson(STORAGE_KEY);

  if (tokens) {
    setTokens(tokens);
  }
};
