import styled from 'styled-components';
import { tokens, Container as ContainerMui } from '@ai21/studio-ui';

export const Container = styled(ContainerMui)`
  margin-top: -4px;
`;

export const Wrapper = styled.div`
  flex: 1;
  margin-top: 0px;
  .Markdown-wrapper {
    max-width: 100%;
  }

  h3 {
    padding-top: 0px;
  }

  &.mobile {
    &.lp {
      ${Container} {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }
`;

export const Column = styled.div`
  flex: 1;

  &:last-child {
    max-width: 342px;
    padding-left: 24px;
  }

  &:first-child {
    padding-right: 24px;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 52px;
    margin-bottom: 8px;
    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-top: 0px;
  }

  ol {
    padding: 0 0 0 14px;
  }

  ul {
    margin-top: 0px;
    margin-bottom: 32px;
  }
`;

export const QuoteWrapper = styled.div`
  border-radius: ${tokens.primitives.radius.large}px;
  border: 1px solid ${tokens.primary.borderDefault};
  background: ${tokens.neutral.white};
  margin: 38px 0 52px 0;
  .Markdown-wrapper {
    margin: 0;
    color: ${tokens.text.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: 145%;
    padding: 16px 16px 0 16px;

    p {
      margin: 0 0 16px 0;
    }
  }
`;

export const QuoteAction = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  background: ${tokens.neutral.almostWhite};
  border-bottom-left-radius: ${tokens.primitives.radius.large}px;
  border-bottom-right-radius: ${tokens.primitives.radius.large}px;

  & > h6 {
    font-weight: 600;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  &.mobile {
    flex-direction: column;

    ${QuoteWrapper} {
      font-size: 14px;
      margin: 24px 0 38px 0;

      .Markdown-wrapper {
        font-size: 14px;
      }
    }

    ${QuoteAction} {
      h6 {
        width: 45%;
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    ${Column} {
      &:last-child {
        padding-left: 0;
      }
    }
  }
`;
