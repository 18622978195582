import { CustomModelType } from '../types';
import {
  getCompletionChatCurlCodeSnippet,
  getCompletionChatJsCodeSnippet,
  getCompletionChatPythonCodeSnippet,
  getCompletionCurlCodeSnippet,
  getCompletionJsCodeSnippet,
  getCompletionPythonCodeSnippet,
  getCustomModelCompletionPythonCodeSnippet,
} from './codeBlocks';
import { GenerateSnippetsParams } from './snippets.types';
import { buildCompletionUrl, isGeneralModel } from './snippets.utils';

export const generate = (params: GenerateSnippetsParams) => {
  return {
    python: python(params),
    javascript: javascript(params),
    curl: curl(params),
  };
};

const python = (params: GenerateSnippetsParams) => {
  const { modelName, customModelType, prompt, controllerParams, context } = params as any;
  const promptParsedForPython = prompt.replaceAll('"', '\\"');

  if (
    modelName === CustomModelType.JAMBA_INSTRUCT ||
    modelName === CustomModelType.JAMBA_1_5_LARGE ||
    modelName === CustomModelType.JAMBA_1_5_MINI
  ) {
    return getCompletionChatPythonCodeSnippet(modelName, promptParsedForPython, controllerParams);
  }

  return !isGeneralModel(modelName)
    ? getCustomModelCompletionPythonCodeSnippet(
        customModelType,
        modelName,
        promptParsedForPython,
        controllerParams,
        context
      )
    : getCompletionPythonCodeSnippet(modelName, promptParsedForPython, controllerParams, context);
};

const javascript = (params: GenerateSnippetsParams) => {
  const { modelName, customModels, prompt, controllerParams, context, customModelType } =
    params as any;

  const url = buildCompletionUrl(modelName, customModelType);
  const promptParsedForJs = prompt.replaceAll('"', '\\"');
  if (
    modelName === CustomModelType.JAMBA_INSTRUCT ||
    modelName === CustomModelType.JAMBA_1_5_LARGE ||
    modelName === CustomModelType.JAMBA_1_5_MINI
  ) {
    return getCompletionChatJsCodeSnippet(modelName, url, promptParsedForJs, controllerParams);
  }
  return getCompletionJsCodeSnippet(url, promptParsedForJs, controllerParams, context);
};

const curl = (params: GenerateSnippetsParams) => {
  const { modelName, customModels, prompt, controllerParams, context, customModelType } =
    params as any;

  const url = buildCompletionUrl(modelName, customModelType);
  const promptParsedForCurl = prompt.replaceAll("'", "'\\''").replaceAll('"', '\\"');
  if (
    modelName === CustomModelType.JAMBA_INSTRUCT ||
    modelName === CustomModelType.JAMBA_1_5_LARGE ||
    modelName === CustomModelType.JAMBA_1_5_MINI
  ) {
    return getCompletionChatCurlCodeSnippet(modelName, url, promptParsedForCurl, controllerParams);
  }
  return getCompletionCurlCodeSnippet(url, promptParsedForCurl, controllerParams, context);
};
