import { ax } from '@ai21/studio-analytics';
import { calcIsEvaluated, selectors } from '@ai21/studio-store';
import { call, select, takeEvery } from 'saga-ts';
import { analytics } from './helpers/analytics';
import { predicateJobStatusChange } from './predicates';

export function* onStatusChange(action: any) {
  const { id, payload } = action;
  const { status } = payload;

  const lines = yield* select(selectors.evaluation.$evaluationLines);

  const linesCount = lines.length;
  const evaluatedCount = lines.filter((line) => calcIsEvaluated(line.quality)).length; // prettier-ignore

  yield* call(analytics, {
    action: 'jobStatus',
    actionValue: status,
    eventId: 'EvaluationJobStatusChanged',
    extra: {
      isManual: status !== 'Evaluating',
      linesCount,
      evaluatedCount,
    },
  });

  if (!id) {
    return;
  }

  ax.set(`evaluationSetsProgress.${id}`, { status });
}

export function* root() {
  yield takeEvery(predicateJobStatusChange(), onStatusChange);
}
