interface DocsIconProps {
  style: React.CSSProperties;
}

export function DocsIcon({ style }: DocsIconProps) {
  return (
    <svg
      style={style}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M7.19508 12.805H12.8051V14.2075H7.19508V12.805ZM7.19508 10H12.8051V11.4025H7.19508V10ZM11.4026 2.98749H5.79258C5.0212 2.98749 4.39008 3.61861 4.39008 4.38999V15.61C4.39008 16.3814 5.01419 17.0125 5.78556 17.0125H14.2076C14.979 17.0125 15.6101 16.3814 15.6101 15.61V7.19499L11.4026 2.98749ZM14.2076 15.61H5.79258V4.38999H10.7013V7.89624H14.2076V15.61Z' />
    </svg>
  );
}
