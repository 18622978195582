import { OrganizationTabs } from '../../constants/constants';

export const INVITE_USER_DIALOG_OPEN = 'INVITE_USER_DIALOG_OPEN';
export const MANAGE_PLAN_DIALOG_OPEN = 'MANAGE_PLAN_DIALOG_OPEN';
export const CANCEL_PLAN_DIALOG_OPEN = 'CANCEL_PLAN_DIALOG_OPEN';
export const CURRENT_TAB = 'CURRENT_TAB';

export interface InviteUserDialogOpen {
  type: typeof INVITE_USER_DIALOG_OPEN;
  open: boolean;
}

export interface ManagePlanDialogOpen {
  type: typeof MANAGE_PLAN_DIALOG_OPEN;
  open: boolean;
}

export interface CancelPlanDialogOpen {
  type: typeof CANCEL_PLAN_DIALOG_OPEN;
  open: boolean;
}

export interface CurrentTab {
  type: typeof CURRENT_TAB;
  currentTab: OrganizationTabs;
}

export type OrganizationActionTypes =
  | InviteUserDialogOpen
  | ManagePlanDialogOpen
  | CurrentTab
  | CancelPlanDialogOpen;
