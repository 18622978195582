import { Checkbox } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline;
`;

export const Input = styled(Checkbox)``;

export const Label = styled.label``;
