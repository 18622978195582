import { Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
`;

export const FormContainer = styled.form``;

export const ErrorMessage = styled.div`
  color: red;
  line-height: 1.5;
  margin: 10px 0;
`;

export const FormContent = styled.div`
  padding-right: 0px;
`;

export const Description = styled(Typography)`
  max-width: 550px;
  margin-top: 20px;
`;
