export function MapIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.3943 3.36201C21.6154 3.49867 21.75 3.74007 21.75 4V17C21.75 17.2841 21.5895 17.5438 21.3354 17.6708L15.3354 20.6708C15.1243 20.7764 14.8757 20.7764 14.6646 20.6708L9 17.8385L3.33541 20.6708C3.10292 20.7871 2.82681 20.7746 2.6057 20.638C2.38459 20.5013 2.25 20.2599 2.25 20V7C2.25 6.71592 2.4105 6.45622 2.66459 6.32918L8.66459 3.32918C8.87574 3.22361 9.12426 3.22361 9.33541 3.32918L15 6.16148L20.6646 3.32918C20.8971 3.21293 21.1732 3.22536 21.3943 3.36201ZM14.25 7.46353L9.75 5.21353V16.5365L14.25 18.7865V7.46353ZM15.75 18.7865L20.25 16.5365V5.21353L15.75 7.46353V18.7865ZM8.25 16.5365V5.21353L3.75 7.46353V18.7865L8.25 16.5365Z'
        fill='black'
      />
    </svg>
  );
}
