import {
  ButtonInfo,
  ButtonType,
  ClickEventTarget,
  DomClickEvent,
  TargetInfo,
} from '../analytics.types';

export const parseButtonInfo = (ev: DomClickEvent) => {
  let output: ButtonInfo = {};

  const { currentTarget } = ev;

  if (!currentTarget) {
    return output;
  }

  const targetInfo = parseTargetInfo(currentTarget);
  output = { ...output, ...targetInfo };

  if (!targetInfo.testId) {
    return output;
  }

  return output;
};

export const parseTargetInfo = (target: ClickEventTarget) => {
  const { tagName, id, className } = target;

  const testId = target.getAttribute('data-testid') ?? '';
  const dataHref = target.getAttribute('data-href') ?? '';
  const href = target.getAttribute('href') ?? '';
  const buttonType = tagName.toLowerCase() as ButtonType;
  const buttonText = getButtonText(target);

  const output: TargetInfo = {
    id,
    className,
    testId,
    buttonType,
    buttonText,
    href: href || dataHref,
  };

  return output;
};

export const getButtonText = (ev: ClickEventTarget) => {
  try {
    return ev.innerText;
  } catch (_err) {
    return '';
  }
};
