interface SaveIconProps {
  color: string;
}

export function ShareIcon({ color }: SaveIconProps) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 3.75C16.7574 3.75 15.75 4.75736 15.75 6C15.75 6.32629 15.8195 6.63636 15.9444 6.91623C15.9522 6.92936 15.9597 6.94282 15.9667 6.95658C15.9801 6.98243 15.9917 7.00869 16.0018 7.03524C16.3765 7.75696 17.1306 8.25 18 8.25C19.2426 8.25 20.25 7.24264 20.25 6C20.25 4.75736 19.2426 3.75 18 3.75ZM15.0262 8.28479C15.7118 9.17581 16.7888 9.75 18 9.75C20.0711 9.75 21.75 8.07107 21.75 6C21.75 3.92893 20.0711 2.25 18 2.25C15.9289 2.25 14.25 3.92893 14.25 6C14.25 6.32338 14.2909 6.6372 14.3679 6.93656L8.97389 9.71529C8.2883 8.82423 7.21121 8.25 6 8.25C3.92893 8.25 2.25 9.92893 2.25 12C2.25 14.0711 3.92893 15.75 6 15.75C7.21118 15.75 8.28824 15.1758 8.97383 14.2848L14.3679 17.0635C14.2909 17.3629 14.25 17.6767 14.25 18C14.25 20.0711 15.9289 21.75 18 21.75C20.0711 21.75 21.75 20.0711 21.75 18C21.75 15.9289 20.0711 14.25 18 14.25C16.7888 14.25 15.7117 14.8242 15.0261 15.7153L9.6321 12.9365C9.70907 12.6372 9.75 12.3234 9.75 12C9.75 11.6767 9.70908 11.3629 9.63213 11.0635L15.0262 8.28479ZM7.99817 10.9647C8.00825 10.9913 8.01994 11.0176 8.03328 11.0435C8.0404 11.0573 8.04788 11.0708 8.05571 11.084C8.18058 11.3638 8.25 11.6738 8.25 12C8.25 12.3264 8.18052 12.6365 8.05554 12.9164C8.04777 12.9295 8.04034 12.9429 8.03328 12.9566C8.02 12.9824 8.00835 13.0086 7.99831 13.0351C7.62368 13.7569 6.86943 14.25 6 14.25C4.75736 14.25 3.75 13.2426 3.75 12C3.75 10.7574 4.75736 9.75 6 9.75C6.86932 9.75 7.62349 10.243 7.99817 10.9647ZM15.9442 17.0842C15.9521 17.0709 15.9596 17.0574 15.9667 17.0435C15.9801 17.0176 15.9918 16.9912 16.0019 16.9645C16.3766 16.2429 17.1308 15.75 18 15.75C19.2426 15.75 20.25 16.7574 20.25 18C20.25 19.2426 19.2426 20.25 18 20.25C16.7574 20.25 15.75 19.2426 15.75 18C15.75 17.6739 15.8194 17.3639 15.9442 17.0842Z'
      />
    </svg>
  );
}
