import { api, generateCode } from '@ai21/studio-api';
import { WidgetId, allWidgets } from '@ai21/studio-editors';
import { IBox, selectors } from '@ai21/studio-store';
import { prompt as dialog, toast } from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, fork, select } from 'saga-ts';
import { Json } from '../types';
import { patchBox } from './helpers/boxes';
import { ActionPlay } from './saga.play';

const mapLayoutToWidgetId: Record<string, WidgetId> = {
  summarize: 'summarization',
  paraphrase: 'paraphrase',
  segmentation: 'segmentation',
  gec: 'gec',
  answer: 'answer',
  textImprovements: 'improvements',
};

export function* generateSingleLego(_action: ActionPlay, outputBox: IBox) {
  const { layoutId } = outputBox;

  const prompt = yield* select(selectors.playground.$prompt);

  const widget = allWidgets[mapLayoutToWidgetId[layoutId]];
  const { config, inTransformer, outTransformer } = widget;

  const widgetParams = yield* select(selectors.playground.$legoParams);

  if (!outputBox) {
    return;
  }

  if (prompt.main === '') {
    toast.show('Please enter a prompt', 'warning');
    return;
  }

  const { apiMethodName } = config;

  const payload =
    Object.keys(widgetParams).length !== 0
      ? { ...inTransformer(prompt, widgetParams) }
      : { ...inTransformer(prompt) };

  const apiMethod = (api.lego as any)[apiMethodName];

  yield* call(patchBox, outputBox, {
    isLoading: true,
    alertData: null,
    output: undefined,
    showFeedback: false,
  });

  const response: any = yield* call(apiMethod, payload);

  if (!response.isSuccess) {
    console.error(response.errorType, response.errorMessage);

    const errorDetail = get(response, 'data.detail', '');

    yield* call(patchBox, outputBox, {
      alertData: {
        title: errorDetail,
        type: 'error',
      },
      isLoading: false,
    });

    return;
  }

  const parsedData = outTransformer(response as Json, { ...prompt });

  yield* call(patchBox, outputBox, {
    output: parsedData,
    count: parsedData.count,
    unit: parsedData.unit,
    isLoading: false,
  });

  if (parsedData.count === 0) {
    toast.show(
      `No ${
        layoutId === 'gec' ? 'grammar errors' : 'improvement suggestions'
      } found`,
      'info'
    );
    return;
  }
}

export function* codeLego(_action: ActionPlay, box: IBox) {
  const currentIds = yield* select(selectors.raw.$rawCurrentIds);
  const { layoutId } = currentIds;

  const prompt = yield* select(selectors.playground.$prompt);
  const widgetParams = yield* select(selectors.playground.$legoParams);

  const flavour = layoutId.replace(/[0-9]+$/g, '');

  const code = generateCode({
    flavour,
    main: prompt.main,
    secondary: prompt.secondary,
    apiParams: widgetParams,
  });

  dialog.code({
    title: 'API call',
    flavour,
    code,
  });
}

export function* generateAllLego(action: ActionPlay, box: IBox) {
  const outputBox = yield* select(selectors.playground.$outputBox);
  yield* fork(generateSingleLego, action, outputBox);
}

//
