import { api } from '@ai21/studio-api';
import { Stripe, StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import { Editor } from '@tiptap/react';
import { Dispatch } from 'react';
import { WebRoutes } from '../../bootstrap/Web.routes';
import { OrganizationTabs } from '../../constants/constants';
import { ChooseHyperParametersOrigin } from '../../data-types/ChooseHyperParametersOrigin';
import { CustomModelType } from '../../data-types/Model';
import { Preset, PresetLabel, PresetOrigin } from '../../data-types/PresetParams';
import { TierType } from '../../data-types/Tier';
import {
  PAYMENT_FAILURE,
  PAYMENT_STARTED,
  PAYMENT_SUCCESS,
  PAYMENT_UPDATE_FAILURE,
  PAYMENT_UPDATE_STARTED,
  PAYMENT_UPDATE_SUCCESS,
  PaymentFailure,
  PaymentStarted,
  PaymentSuccess,
  PaymentUpdateFailure,
  PaymentUpdateStarted,
  PaymentUpdateSuccess,
} from '../actionTypes/PaymentActionTypes';
import { ActionTypes } from '../actionTypes/actionTypes';
import {
  CHOOSE_HYPER_PARAMETERS,
  CustomModelsActionTypes,
} from '../actionTypes/customModelsActionTypes';
import {
  CANCEL_PLAN_DIALOG_OPEN,
  CURRENT_TAB,
  CancelPlanDialogOpen,
  CurrentTab,
  INVITE_USER_DIALOG_OPEN,
  InviteUserDialogOpen,
  MANAGE_PLAN_DIALOG_OPEN,
  ManagePlanDialogOpen,
} from '../actionTypes/organizationActionTypes';
import {
  PRESET_LABELS_CLEARED,
  PRESET_LABEL_CLICKED,
  PRESET_SEARCH_VALUE_CHANGED,
  PRESET_SEARCH_VALUE_CLEARED,
} from '../actionTypes/overviewActionTypes';
import {
  CLEAR_COMPLETION_RESPONSE,
  CUSTOM_PRESET_SELECTED,
  PRESET_CLICKED,
  PRESET_SELECTED,
  PlaygroundActionTypes,
} from '../actionTypes/playgroundActionTypes';
import { JOINED_FROM_INVITE, JoinedFromInvite } from '../actionTypes/userActionTypes';
import { RootState } from '../store';
import { refreshUserDetails } from './actions.get';
import { updatePaymentMethodIdentity, updateUserPremium } from './actions.post';
import { toast } from '@ai21/studio-base-ui';

export function stripeErrorMessage(error: StripeError) {
  switch (error.type) {
    case 'invalid_request_error':
      switch (error.code) {
        case 'parameter_invalid_empty':
          switch (error.param) {
            case 'billing_details[name]':
              return 'Please fill your name.';
            default:
              return error.message;
          }
        default:
          return error.message;
      }
    default:
      return error.message;
  }
}

async function createStripePaymentMethod(
  card: StripeCardNumberElement,
  name: string,
  zip: string,
  stripe: Stripe
) {
  return stripe.createPaymentMethod({
    type: 'card',
    card,
    billing_details: {
      name,
      address: {
        postal_code: zip,
      },
    },
  });
}

export function updatePaymentMethod(
  card: StripeCardNumberElement,
  name: string,
  zip: string,
  stripe: Stripe,
  navigate: any
) {
  return async function inner(dispatch: Dispatch<ActionTypes>) {
    dispatch(paymentUpdateStarted(Date.now()));

    const { error, paymentMethod } = await createStripePaymentMethod(card, name, zip, stripe);

    if (error) {
      dispatch(paymentUpdateFailure(stripeErrorMessage(error)));
      return;
    }

    if (!paymentMethod) {
      dispatch(paymentUpdateFailure('Failed to update Stripe payment method'));
      return;
    }

    const success = await updatePaymentMethodIdentity(paymentMethod.id);
    if (!success) {
      dispatch(paymentUpdateFailure('Failed to update payment method'));
      return;
    }
    await refreshUserDetails()(dispatch);
    dispatch(paymentUpdateSuccess());
    navigate(WebRoutes.accountDetails);
  };
}

export function premiumPay(
  card: StripeCardNumberElement,
  name: string,
  zip: string,
  stripe: Stripe,
  tier: TierType,
  stripeCoupon: string | undefined,
  navigate?: any
) {
  return async function inner(dispatch: Dispatch<ActionTypes>) {
    dispatch(paymentStarted(Date.now()));

    const { error, paymentMethod } = await createStripePaymentMethod(card, name, zip, stripe);

    if (error) {
      dispatch(paymentFailure(stripeErrorMessage(error), error.code));
    } else {
      const success = await updateUserPremium(dispatch, paymentMethod.id, tier, stripeCoupon);

      if (!success) return;

      await refreshUserDetails()(dispatch);
      dispatch(paymentSuccess());
      if (navigate) {
        navigate(WebRoutes.accountDetails);
      }
    }
  };
}

export function paymentSuccess(): PaymentSuccess {
  return {
    type: PAYMENT_SUCCESS,
  };
}

export function paymentStarted(fetchStartTime: number): PaymentStarted {
  return {
    type: PAYMENT_STARTED,
    fetchStartTime,
  };
}

export function paymentFailure(message?: string | null, code?: string | null): PaymentFailure {
  return {
    type: PAYMENT_FAILURE,
    message: message || null,
    code: code || null,
  };
}

export function paymentUpdateFailure(
  message?: string | null,
  code?: string | null
): PaymentUpdateFailure {
  return {
    type: PAYMENT_UPDATE_FAILURE,
    message: message || null,
    code: code || null,
  };
}

export function paymentUpdateSuccess(): PaymentUpdateSuccess {
  return {
    type: PAYMENT_UPDATE_SUCCESS,
  };
}

export function paymentUpdateStarted(fetchStartTime: number): PaymentUpdateStarted {
  return {
    type: PAYMENT_UPDATE_STARTED,
    fetchStartTime,
  };
}

export function presetLabelClicked(presetLabel: PresetLabel, clicked: boolean) {
  return {
    type: PRESET_LABEL_CLICKED,
    presetLabel,
    clicked,
  };
}

export function clearPresetLabels() {
  return {
    type: PRESET_LABELS_CLEARED,
  };
}

export function presetSearchValueChanged(searchValue: string) {
  return {
    type: PRESET_SEARCH_VALUE_CHANGED,
    searchValue,
  };
}

export function presetSearchValueCleared() {
  return {
    type: PRESET_SEARCH_VALUE_CLEARED,
  };
}

export function openUserInviteDialog(open: boolean): InviteUserDialogOpen {
  return {
    type: INVITE_USER_DIALOG_OPEN,
    open,
  };
}

export function openManagePlanDialog(open: boolean): ManagePlanDialogOpen {
  return {
    type: MANAGE_PLAN_DIALOG_OPEN,
    open,
  };
}
export function openCancelPlanDialog(open: boolean): CancelPlanDialogOpen {
  return {
    type: CANCEL_PLAN_DIALOG_OPEN,
    open,
  };
}

export function setCurrentTab(currentTab: OrganizationTabs): CurrentTab {
  return {
    type: CURRENT_TAB,
    currentTab,
  };
}

export function setJoinedFromInvite(fromInvite: boolean): JoinedFromInvite {
  return {
    type: JOINED_FROM_INVITE,
    fromInvite,
  };
}

export function setCustomModelHyperParameters(
  datasetName: string,
  sizeBytes: number,
  modelType: CustomModelType,
  datasetId: string,
  origin: ChooseHyperParametersOrigin
) {
  return {
    type: CHOOSE_HYPER_PARAMETERS,
    origin,
    datasetDetails: {
      datasetName,
      sizeBytes,
      modelType,
      datasetId,
    },
  };
}

export function setCustomModelParameters(
  datasetDetails: {
    datasetName: string;
    sizeBytes: number;
    modelType: CustomModelType;
    datasetId: string;
  },
  origin: ChooseHyperParametersOrigin
) {
  return (dispatch: Dispatch<CustomModelsActionTypes>, getState: () => RootState) => {
    dispatch({
      type: CHOOSE_HYPER_PARAMETERS,
      origin,
      datasetDetails,
    });
  };
}

export function onPresetSelected(
  preset: Preset | null,
  editor?: Editor | null,
  origin: PresetOrigin | null = PresetOrigin.PLAYGROUND
) {
  return (dispatch: Dispatch<PlaygroundActionTypes>, getState: () => RootState) => {
    const { currentPreset } = getState()._playground;
    if (preset === null) {
      return;
    }
    if (preset.text === currentPreset.text) {
      editor?.commands.clearContent();
    }
    if (preset.text === '') {
      dispatch({ type: PRESET_CLICKED, nextPreset: null });
    }
    dispatch({ type: CLEAR_COMPLETION_RESPONSE });
    if (preset.primaryLabel !== PresetLabel.MY_PRESETS) {
      dispatch({
        type: PRESET_SELECTED,
        preset,
        origin: origin,
      });
    } else {
      dispatch({ type: CUSTOM_PRESET_SELECTED, preset, origin: origin });
    }
  };
}

export function uncancelSubscription() {
  return async function inner(dispatch: Dispatch<ActionTypes>) {
    try {
      const response = await api.billing.uncancelSubscription();
      if (response.isSuccess) {
        toast.show('Your subscription has been reactivated');
        await refreshUserDetails()(dispatch);
      } else {
        const errorMessage = `un-cancel subscription for apiKey failed with response: ${response.status}`;
        errorHandler.report(errorMessage);
      }
    } catch (err: any) {
      const message = err instanceof Error ? err.message : 'UNKNOWN_ERROR';
    }
  };
}
