import type { IRouteMap } from '@ai21/studio-ui';

export enum AppRoutes {
  PLAY_HOME = '/home',
  PLAY_FAMILY = '/home/:familyId',
  PLAY = '/home/:familyId/:id',
  PAS = '/pas',
}

export const publicRoutes: IRouteMap = {};

export const privateRoutes: IRouteMap = {
  [AppRoutes.PLAY_HOME]: 'playHome',
  [AppRoutes.PLAY_FAMILY]: 'playFamily',
  [AppRoutes.PLAY]: 'playLayout',
  [AppRoutes.PAS]: 'playPas',
};
