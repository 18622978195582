import { fireRequest } from './axios';
import {
  PayloadAnswer,
  PayloadGEC,
  PayloadImprovements,
  PayloadParaphrase,
  PayloadSegmentation,
  PayloadSummarization,
} from './types';

export const summarize = (payload: PayloadSummarization) => {
  const { demo } = payload;

  let path = '/summarize';

  if (demo) {
    path = '/demo' + path;
  }

  return fireRequest<Json>({
    method: 'post',
    path,
    data: payload,
    endpoint: 'lego.summarize',
  });
};

export const segmentation = (payload: PayloadSegmentation) => {
  const path = '/segmentation';

  return fireRequest<Json>({
    method: 'post',
    path,
    data: payload,
    endpoint: 'lego.segmentation',
  });
};

export const paraphrase = (payload: PayloadParaphrase) => {
  const { demo } = payload;

  let path = '/paraphrase';

  if (demo) {
    path = '/demo' + path;
  }

  return fireRequest<Json>({
    method: 'post',
    path,
    data: payload,
    endpoint: 'lego.paraphrase',
  });
};

export const improvements = (payload: PayloadImprovements) => {
  const { demo } = payload;
  let path = '/improvements';

  if (demo) {
    path = '/demo' + path;
  }

  return fireRequest<Json>({
    method: 'post',
    path,
    data: payload,
    endpoint: 'lego.improvements',
  });
};

export const gec = (payload: PayloadGEC) => {
  const { demo } = payload;
  let path = '/gec';

  if (demo) {
    path = '/demo' + path;
  }

  return fireRequest<Json>({
    method: 'post',
    path,
    data: payload,
    endpoint: 'lego.gec',
  });
};

export const answer = (payload: PayloadAnswer) => {
  const { demo } = payload;
  let path = '/answer';

  if (demo) {
    path = '/demo' + path;
  } else {
    path = '/experimental' + path;
  }

  return fireRequest<Json>({
    method: 'post',
    path,
    data: payload,
    endpoint: 'lego.answer',
  });
};

export const lego = {
  summarize,
  segmentation,
  paraphrase,
  improvements,
  gec,
  answer,
};
