export const calculateReasonsDelta = (
  newTags: string[] = [],
  previousTags: string[] = []
): string[] => {
  try {
    const added = newTags.filter((tag) => !previousTags.includes(tag));
    const removed = previousTags.filter((tag) => !newTags.includes(tag));

    return added.length > 0 ? added : removed;
  } catch (err: any) {
    return [];
  }
};
