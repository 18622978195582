import { setTokens, setUserId, setOrganizationId } from '@ai21/studio-api';
import { call, fork, put, takeLatest, delay } from 'saga-ts';
import { getBoolean, invokeEvent } from 'shared-base';
import { authState, firebase } from '../utils/globals';
import { Logger, logAnalytics, logger } from '../utils/log';
import { setBoolean } from '../utils/storage';
import {
  navigateToJotform,
  navigateToLogin,
  navigateToOriginalLocation,
} from './helpers/navigation';
import { createUser, getUser } from './helpers/user';
import { shouldUseCookieAuth } from './saga.featureflags';

const identityBaseUrl = ((window as any).__env__.VITE_IDENTITY_SERVER_URL ?? '') + '/identity';

export type ActionLastStep = {
  type: 'AUTH_LAST_STEP';
  user: any;
};

async function startSession(): Promise<any> {
  try {
    const response = await fetch(`${identityBaseUrl}/start-session?product_name=studio_app`, {
      credentials: 'include',
      method: 'POST',
    });

    if (response.status !== 200) {
      return null;
    }

    return await response.json();
  } catch (err) {
    return null;
  }
}

export function* onAuthenticationCompleted(action: ActionLastStep) {
  const { user } = action;

  const shouldUseCookie: boolean = yield call(shouldUseCookieAuth);
  setTokens({ firebaseIdToken: user.firebaseToken || user.accessToken }); // TODO remove user.accessToken

  authState.setUser(user);
  let response: any;

  if (shouldUseCookie) {
    response = yield* call(startSession);
  } else {
    response = yield* call(getUser, user);
    response = response.data;
  }

  if (!response.isSuccess && !response.apiKey) {
    return;
  }

  const { apiKey, organizationId, userId } = response;
  setTokens({ studioApiKey: apiKey });
  setUserId(user.uid || userId); //TODO remove user.uid
  setOrganizationId(organizationId);
  authState.setApiKey(apiKey);

  invokeEvent('FIREBASE_USER_READY', user);

  logger.AuthSuccess();
  setBoolean('USER_LOGGED_IN_ONCE', true);

  yield put({ type: 'AUTHENTICATION_COMPLETED' });

  if (authState.isManual) {
    logger.ManualAuthSucceeded();
  }

  yield* call(navigateToOriginalLocation);

  logger.AuthCompleted();
}

export function* root() {
  yield takeLatest('saga/complete', onAuthenticationCompleted);
}
