import TelemetryClient from "../TelemetryClient";
import { Counter } from "../types/telemetry";
import { Optional } from "../types/types";

export let initSdkCounter: Optional<Counter>;
export let initExperimentProviderInvokedCounter: Optional<Counter>;
export let initExperimentProviderFailedCounter: Optional<Counter>;
export let fetchVariantsInvokedCounter: Optional<Counter>;
export let fetchVariantsFailedCounter: Optional<Counter>;
export let getVariantInvokedCounter: Optional<Counter>;
export let getVariantReturnedFallbackCounter: Optional<Counter>;
export let allVariantsInvokedCounter: Optional<Counter>;

export const initCounters = () => {
  initSdkCounter = TelemetryClient.getIntCounter("initExperimentSDK");
  initExperimentProviderInvokedCounter = TelemetryClient.getIntCounter("initExperimentProvider");
  initExperimentProviderFailedCounter = TelemetryClient.getIntCounter("initExperimentProviderFailed");
  fetchVariantsInvokedCounter = TelemetryClient.getIntCounter("fetchExperimentVariants");
  fetchVariantsFailedCounter = TelemetryClient.getIntCounter("fetchExperimentVariantsFailed");
  getVariantInvokedCounter = TelemetryClient.getIntCounter("getVariant");
  getVariantReturnedFallbackCounter = TelemetryClient.getIntCounter("getVariantReturnedFallback");
  allVariantsInvokedCounter = TelemetryClient.getIntCounter("allVariants");
};
