import React from 'react';
import { Evaluator, TimeAgo, Wrapper } from './EvaluatorInfo.style';
import { ILine } from '../../InboxEditor.types';
import { format, Icon } from '@ai21/studio-base-ui';

export type EvaluatorInfoProps = {
  line: ILine;
};

export function EvaluatorInfo(props: EvaluatorInfoProps) {
  const { line } = props;
  const { evaluator, quality } = line ?? {};
  const { timestamp } = quality ?? {};

  const timeAgo = timestamp ? format.date.timeAgo(timestamp) : undefined;

  return (
    <Wrapper className='EvaluatorInfo-wrapper' data-testid='EvaluatorInfo-wrapper'>
      {evaluator && <Evaluator color='textSecondary'>Evaluated by {evaluator}</Evaluator>}
      {timeAgo && (
        <TimeAgo color='text.tertiary'>
          <Icon iconName='ClockHour4' />
          {timeAgo}
        </TimeAgo>
      )}
    </Wrapper>
  );
}

export default EvaluatorInfo;
