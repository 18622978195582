import { Icon, useShortKey } from '@ai21/studio-base-ui';
import { useContext, useEffect, useState } from 'react';
import { AskContext } from '../../AskEditor.context';
import { Cta, Prompt, Wrapper } from './AskBottomBar.style';
import { addListener, invokeEvent } from 'shared-base';

export type AskBottomBarProps = {
  ctaColor?: string;
  ctaBkColor?: string;
};

export function AskBottomBar(props: AskBottomBarProps) {
  const { ctaColor, ctaBkColor } = props;

  const { callbacks } = useContext(AskContext);
  const [prompt, setPrompt] = useState('');
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  function onClick(keyboard: boolean) {
    if (!prompt) {
      return;
    }

    setPrompt('');
    callbacks.onPrompt({ prompt });

    invokeEvent('global/flow', {
      flow: 'documentLibrary',
      action: 'askQuestion',
      actionValue: prompt.length,
      keyboard,
      eventId: 'MultiDocQuestionAsked',
    });
  }

  useShortKey(
    {
      key: 'Enter',
      preventDefault: true,
    },
    () => onClick(true),
    [prompt]
  );

  function onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    setPrompt(ev.target.value);
  }

  return (
    <Wrapper className='AskBottomBar-wrapper' data-testid='AskBottomBar-wrapper'>
      <Prompt placeholder='Type your question' value={prompt} onChange={onChange} />
      <Cta
        variant='contained'
        color='primary'
        backgroundColor={ctaBkColor}
        textColor={ctaColor}
        size='large'
        data-testid='AskQuestion'
        onClick={() => onClick(false)}
        endIcon={<Icon iconName='Send' />}
      >
        {isMobile ? '' : 'Ask question'}
      </Cta>
    </Wrapper>
  );
}

export default AskBottomBar;
