import { CSSProperties } from 'react';

// const plusStyles = makeStyles(() => ({
//     plus: {
//         '&:hover': {
//             fill: 'currentColor',
//         },
//     },
// }));

export function PlusIcon({
  style,
  disableHover,
}: {
  style?: CSSProperties;
  disableHover?: boolean;
}) {
  return (
    <svg
      fill='currentColor'
      style={style}
      className={disableHover ? '' : ''}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M12 4.25C12.4142 4.25 12.75 4.58579 12.75 5V11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H12.75V19C12.75 19.4142 12.4142 19.75 12 19.75C11.5858 19.75 11.25 19.4142 11.25 19V12.75H5C4.58579 12.75 4.25 12.4142 4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H11.25V5C11.25 4.58579 11.5858 4.25 12 4.25Z' />
    </svg>
  );
}
