import { Divider } from '@ai21/studio-base-ui';
import { useContext, useMemo } from 'react';
import { InboxContext } from '../../InboxEditor.context';
import { IOEditor } from '../IOEditor/IOEditor';
import { Wrapper } from './IOEditors.style';

export type IOEditorsProps = {};

export function IOEditors(_props: IOEditorsProps) {
  const { line, callbacks } = useContext(InboxContext);
  const { input, output, inputEdited, outputEdited } = line ?? {};

  const contentInput = useMemo(() => {
    let content = [input];

    if (inputEdited) {
      content.push('\n', '='.repeat(27) + ' APE ' + '='.repeat(27), '\n', inputEdited);
    }

    return content.join('\n');
  }, [line]);

  return (
    <Wrapper className='IOEditors-wrapper' data-testid='IOEditors-wrapper'>
      <IOEditor
        title='Input prompt'
        iconName='ArrowDownRight'
        copyTestId='CopyPrompt'
        flex={2}
        content={contentInput}
        onAction={() => {}}
      />
      <Divider />
      <IOEditor
        title='Output'
        iconName='ArrowUpRight'
        copyTestId='CopyCompletion'
        flex={2}
        content={output}
        onAction={() => {}}
      />
    </Wrapper>
  );
}

export default IOEditors;
