import { Button } from '@ai21/studio-base-ui';
import { styled, Input as InputMui } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  margin-right: 10px;
  max-height: 42px;
  position: relative;
`;

export const Cta = styled(Button)``;

export const Dot = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #753fea;
  z-index: 999;
`;
