export function Frame2Icon() {
  return (
    <svg width='65' height='64' viewBox='0 0 65 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.499817' width='64.0004' height='64.0004' rx='32.0002' fill='#F4F3FF' />
      <path
        d='M31.3921 23.6047L28.3658 23.7984L27.3823 20.892C27.3369 20.7578 27.1402 20.7727 27.1251 20.9218L26.656 24.4095L24.1593 26.1832C24.0534 26.2577 24.0988 26.4068 24.2349 26.4217L26.8224 26.6304L27.8967 29.2089C27.9421 29.3282 28.1236 29.3133 28.1539 29.1791L28.7743 26.0938L31.498 23.8283C31.5888 23.7538 31.5131 23.5898 31.3921 23.6047Z'
        fill='#753FEA'
      />
      <path
        d='M30.8925 35.8132L26.9313 36.0828L25.635 32.266C25.5774 32.0957 25.3181 32.1099 25.2893 32.2943L24.6698 36.8773L21.3712 39.2184C21.2416 39.3178 21.2992 39.5164 21.4577 39.5306L24.8715 39.8002L26.283 43.1914C26.3551 43.3474 26.5855 43.3333 26.6287 43.163L27.4354 39.105L31.0077 36.1254C31.1517 36.0119 31.0653 35.799 30.8925 35.8132Z'
        fill='#753FEA'
      />
      <path
        d='M38.8003 27.3446L45.0041 26.9274C45.2765 26.9125 45.4127 27.2404 45.1857 27.4043L39.5871 32.0695L38.316 38.4339C38.2706 38.6873 37.8923 38.732 37.7864 38.4786L35.5773 33.1724L30.236 32.7551C29.9788 32.7401 29.888 32.4122 30.0998 32.2632L35.2596 28.5966L36.2281 21.4124C36.2735 21.1293 36.682 21.0845 36.7728 21.3677L38.8003 27.3446Z'
        fill='#753FEA'
      />
    </svg>
  );
}
