import { APIType } from '../../constants/constants';
import { CompletionRequestParams } from '../../data-types/Completion';
import { CustomPreset } from '../../data-types/PresetParams';

export const POST_PRESET_STARTED = 'POST_PRESET_STARTED';
export const POST_PRESET_FAILED = 'POST_PRESET_FAILED';
export const POST_PRESET_SUCCESS = 'POST_PRESET_SUCCESS';
export const GET_PRESETS_STARTED = 'GET_PRESETS_STARTED';
export const GET_PRESETS_FAILED = 'GET_PRESETS_FAILED';
export const GET_PRESETS_SUCCESS = 'GET_PRESETS_SUCCESS';
export const UPDATE_PRESET_STARTED = 'UPDATE_PRESET_STARTED';
export const UPDATE_PRESET_FAILED = 'UPDATE_PRESET_FAILED';
export const UPDATE_PRESET_SUCCESS = 'UPDATE_PRESET_SUCCESS';
export const DELETE_PRESET_STARTED = 'DELETE_PRESET_STARTED';
export const DELETE_PRESET_FAILED = 'DELETE_PRESET_FAILED';
export const DELETE_PRESET_SUCCESS = 'DELETE_PRESET_SUCCESS';
export const CLEAR_SNACKBAR_MESSAGE = 'CLEAR_SNACKBAR_MESSAGE';

export enum PresetListName {
  EXAMPLES = 'Examples',
  MY_PRESETS = 'My Presets',
}

export interface PostPresetPayload {
  name: string;
  apiType: APIType;
  params: CompletionRequestParams | { prompt: string };
}

export interface PostPresetStarted {
  type: typeof POST_PRESET_STARTED;
  payload: PostPresetPayload;
}

export interface PostPresetFailed {
  type: typeof POST_PRESET_FAILED;
  payload: PostPresetPayload;
  reason: string;
}

export interface PostPresetSuccess {
  type: typeof POST_PRESET_SUCCESS;
  payload: PostPresetPayload;
  requestDuration: number;
  prompt: string;
}

export interface GetPresetsStarted {
  type: typeof GET_PRESETS_STARTED;
}

export interface ClearSnackbarMessage {
  type: typeof CLEAR_SNACKBAR_MESSAGE;
}

export interface GetPresetsFailed {
  type: typeof GET_PRESETS_FAILED;
  reason: string;
}

export interface GetPresetsSuccess {
  type: typeof GET_PRESETS_SUCCESS;
  payload: CustomPreset[];
  requestDuration: number;
}

export interface UpdatePresetStarted {
  type: typeof UPDATE_PRESET_STARTED;
  name: string;
}

export interface UpdatePresetFailed {
  type: typeof UPDATE_PRESET_FAILED;
  name: string;
  reason: string;
}

export interface UpdatePresetSuccess {
  type: typeof UPDATE_PRESET_SUCCESS;
  name: string;
  requestDuration: number;
}

export interface DeletePresetStarted {
  type: typeof DELETE_PRESET_STARTED;
  name: string;
}

export interface DeletePresetFailed {
  type: typeof DELETE_PRESET_FAILED;
  name: string;
  reason: string;
}

export interface DeletePresetSuccess {
  type: typeof DELETE_PRESET_SUCCESS;
  name: string;
  origin: string;
  prompt: string;
}

export type PresetActionTypes =
  | PostPresetStarted
  | PostPresetFailed
  | PostPresetSuccess
  | UpdatePresetStarted
  | UpdatePresetFailed
  | UpdatePresetSuccess
  | DeletePresetStarted
  | DeletePresetFailed
  | DeletePresetSuccess
  | GetPresetsStarted
  | GetPresetsSuccess
  | GetPresetsFailed
  | ClearSnackbarMessage;
