export function MastercardIcon() {
  return (
    <svg width='24' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='.25' y='.25' width='23.5' height='15.5' rx='.75' stroke='#C3C6CE' strokeWidth='.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.107 11.327h3.786V4.664h-3.786v6.663z'
        fill='#FF5F00'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.348 7.995c0-1.352.646-2.555 1.652-3.331a4.365 4.365 0 0 0-2.674-.906C6.936 3.758 5 5.655 5 7.995s1.937 4.237 4.326 4.237c1.01 0 1.938-.338 2.674-.905a4.2 4.2 0 0 1-1.652-3.332z'
        fill='#EB001B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.865 10.62v-.164h-.044l-.05.114-.05-.114h-.045v.165h.031v-.124l.048.107h.032l.047-.107v.124h.031zm-.278 0v-.135h.056v-.028H18.5v.027h.057v.137h.03zM19 7.996c0 2.34-1.937 4.237-4.326 4.237A4.365 4.365 0 0 1 12 11.328a4.199 4.199 0 0 0 1.652-3.332A4.2 4.2 0 0 0 12 4.664a4.365 4.365 0 0 1 2.674-.906c2.39 0 4.326 1.897 4.326 4.237z'
        fill='#F79E1B'
      />
    </svg>
  );
}
