import React from 'react';
import { Wrapper } from './UserButton.style';
import Icon from '../Icon/Icon';

export type UserButtonProps = {
  children?: string;
  onClick: (ev?: React.MouseEvent<HTMLButtonElement>) => void;
};

export function UserButton(props: UserButtonProps) {
  return (
    <Wrapper
      className='UserButton-wrapper'
      data-testid='UserButton-wrapper'
      iconName='menu.Avatar'
      onClick={props.onClick}
      size='medium'
    >
      {props.children}
    </Wrapper>
  );
}

export default UserButton;
