export function TrainIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.525 4.30823C11.1264 4.53835 10.9899 5.048 11.22 5.44658L12.47 7.61165L6.6965 10.945L5.4465 8.77992C5.21638 8.38134 4.70672 8.24478 4.30815 8.47489L2.14308 9.72489C1.74451 9.95501 1.60794 10.4647 1.83806 10.8632L3.08806 13.0283C2.68948 13.2584 2.55292 13.7681 2.78304 14.1667C3.01316 14.5652 3.52282 14.7018 3.9214 14.4717L5.1714 16.6368C5.40151 17.0353 5.91117 17.1719 6.30975 16.9418L8.47481 15.6918C8.87339 15.4617 9.00995 14.952 8.77983 14.5534L7.52983 12.3884L13.3033 9.05502L14.5533 11.2201C14.7835 11.6187 15.2931 11.7552 15.6917 11.5251L17.8568 10.2751C18.2553 10.045 18.3919 9.53533 18.1618 9.13675L16.9118 6.97169C17.3104 6.74157 17.4469 6.23191 17.2168 5.83333C16.9867 5.43476 16.477 5.29819 16.0784 5.52831L14.8284 3.36325C14.5983 2.96467 14.0887 2.82811 13.6901 3.05823L11.525 4.30823ZM12.5109 5.18243L15.4275 10.2342L16.8709 9.40091L13.9542 4.34909L12.5109 5.18243ZM6.04559 15.6509L3.12893 10.5991L4.5723 9.76576L7.48897 14.8176L6.04559 15.6509Z'
      />
    </svg>
  );
}
