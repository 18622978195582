import { apiUrl } from '../globals';
import { sanitizeCode } from '../utils/snippets';
import { getGECCurlCodeSnippet, getGECJsCodeSnippet, getGECPythonCodeSnippet } from './codeBlocks';
import { GenerateSnippetsParams } from './snippets.types';

const url = (apiHost: string) => `${apiHost}/gec`;

export const generate = (params: GenerateSnippetsParams) => {
  return {
    python: python(params),
    javascript: javascript(params),
    curl: curl(params),
  };
};

const python = (params: GenerateSnippetsParams) => {
  const { main } = params as any;
  return getGECPythonCodeSnippet(url(apiUrl), sanitizeCode(main));
};

const javascript = (params: GenerateSnippetsParams) => {
  const { main } = params as any;
  return getGECJsCodeSnippet(url(apiUrl), sanitizeCode(main));
};

const curl = (params: GenerateSnippetsParams) => {
  const { main } = params as any;
  return getGECCurlCodeSnippet(url(apiUrl), sanitizeCode(main));
};
