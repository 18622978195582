import { Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  margin-right: 10px;
`;

export const Evaluator = styled(Typography)`
  font-size: 14px;
`;

export const TimeAgo = styled(Typography)`
  margin-top: 3px;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-top: 0px;

  svg {
    stroke: ${tokens.text.tertiary};
    width: 12px;
    height: 12px;
    margin-right: 4px;
    margin-top: 2px;
  }
`;
