import { makeStyles } from '@ai21/studio-base-ui';

export const SimpleTableStyles = makeStyles(() => ({
  table: {},
  tableContainer: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
  },
  dataItem: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: '0.15px',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  columnHeader: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: '0.15px',
    background: '#F6F6F6',
    height: 56,
  },
  row: {
    height: 52,
  },
}));

export default SimpleTableStyles;
