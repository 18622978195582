import { IGenerationLines, IGenerationJobs } from './types.sets';

export const generationJobs: IGenerationJobs = {
  '1': {
    id: '1',
    name: 'Generation set 1',
    description: 'Generation set 1 Description',
    dateCreated: Date.now(),
    creator: 'John Doe',
    setType: 'generation',
    setSource: 'upload',
    fileSizeInBytes: 100,
    linesCount: 100,
    linesCompleted: 99,
    status: 'Completed',
    tags: [],
    price: {
      tags: [],
      value: 0,
      timestamp: 0,
    },
  },
};

export const generationLines: IGenerationLines = {
  '1': {
    id: '1',
    setId: '',
    index: 1,
    dateCreated: Date.now(),
    creator: 'Jimmy Bill',
    input: 'Input 1',
    output: 'Output 1',
    status: 'Completed',
    comment: 'Comment 1',
    modelName: 'Model 1',
    outputEdited: 'Output Suggestion 1',
    inputEdited: '',
    lastOpenedAt: Date.now(),
    lastOpenedBy: 'Jimmy Bill',
    setSource: 'generation',
    setType: 'generation',
    price: {
      tags: [],
      value: 0,
      timestamp: 0,
    },
    foundationModelName: '',
    maxTokens: 200,
    minTokens: 0,
    temperature: 0.9,
    topP: 0.9,
  },
};
