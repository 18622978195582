import type { SearchHandle } from '@ai21/studio-base-ui';
import { Box, Search, Tags, Typography } from '@ai21/studio-base-ui';
import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { ICatalogueItem, ICatalogueSection } from '../../types';
import { CatalogueCard } from '../CatalogueCard/CatalogueCard';
import { useCatalogueStyles } from './Catalogue.styles';
import { ContentContext } from '../Content/Content.context';
import classnames from 'classnames';
import { CatalogueProps } from './Catalogue.types';
import { resolveCardsForSection } from './Catalogue.utils';
import { CatalogueSection } from './components/CatalogueSection';

export const Catalogue: FC<CatalogueProps> = ({
  sections = [],
  cards,
  maxHeight,
  itemsPerRow = 2,
  sticky = false,
  onCta,
}) => {
  const styles = useCatalogueStyles({
    maxContainerHeight: maxHeight,
    numOfCardsPerRow: itemsPerRow,
    addSectionsPadding: sticky,
  });

  const renderSections = useCallback(() => {
    return sections.map((section) => {
      const cardsForSection = resolveCardsForSection(section, cards);
      return (
        <CatalogueSection
          key={`section-${section.id}`}
          section={section}
          cards={cardsForSection}
          numOfCardsPerRow={itemsPerRow}
          addPaddingToSections={sticky}
          onClickCard={onCta}
        />
      );
    });
  }, [sections, cards, itemsPerRow, sticky, onCta]);

  return (
    <Box className='Catalogue-wrapper' data-testid='Catalogue-wrapper' sx={styles.root}>
      <Box className='content' sx={styles.sectionsContainer}>
        {renderSections()}
      </Box>
    </Box>
  );
};

export default Catalogue;
