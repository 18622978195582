import { useMemo } from 'react';
import { ITableConfigs, ReduxDispatch } from '../types';
import { invokeEvent } from 'shared-base';

export function useTableCallbacks<T>(
  dispatch: ReduxDispatch,
  tableConfigs: ITableConfigs,
  extraCallbacks?: T,
  depsArray: any[] = []
) {
  const callbacks = useMemo(
    () => ({
      onRowAction: (tableId: string, rowId: string, actionId: string) => {
        const actionType = findTableActionType(tableConfigs, tableId);

        invokeEvent('global/table', {
          entity: tableId,
          scope: 'row',
          rowId,
          action: actionId,
        });

        dispatch({
          type: actionType,
          verb: actionId,
          id: rowId,
        });
      },
      onTableAction: (tableId: string, actionId: string, params?: Json) => {
        const actionType = findTableActionType(tableConfigs, tableId);

        invokeEvent('global/table', {
          entity: tableId,
          scope: 'table',
          action: actionId,
        });

        dispatch({
          type: actionType,
          verb: actionId,
          params,
        });
      },
      ...extraCallbacks,
    }),
    [depsArray]
  ) as TableCallbacks & T;

  return callbacks;
}

export type TableCallbacks = {
  onRowAction: (tableId: string, rowId: string, actionId: string) => void;
  onTableAction: (tableId: string, actionId: string, params?: Json) => void;
};

const findTableActionType = (tableConfigs: ITableConfigs, tableId: string) => {
  const table = Object.values(tableConfigs).find((t) => t.id === tableId);

  if (!table) {
    console.warn(`Table with id ${tableId} not found`);
    return;
  }

  if (!table.misc?.actionType) {
    console.warn(`Table with id ${tableId} has no actionType`);
    return;
  }

  return table.misc.actionType;
};
