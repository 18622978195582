export function J2Icon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='8' fill='#F4F5F7' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.5097 13.1284C25.8141 12.9572 26.1858 12.9572 26.4902 13.1284L37.1569 19.1284C37.4718 19.3055 37.6666 19.6387 37.6666 20V32C37.6666 32.3613 37.4718 32.6945 37.1569 32.8716L26.4902 38.8716C26.1858 39.0428 25.8141 39.0428 25.5097 38.8716L14.8431 32.8716C14.5282 32.6945 14.3333 32.3613 14.3333 32V20C14.3333 19.6387 14.5282 19.3055 14.8431 19.1284L25.5097 13.1284ZM16.3333 21.7098L25 26.5848V36.2902L16.3333 31.4152V21.7098ZM27 36.2902L35.6666 31.4152V21.7098L27 26.5848V36.2902ZM26 24.8527L34.6269 20L26 15.1473L17.373 20L26 24.8527Z'
        fill='#1D1E22'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.5097 13.1284C25.8141 12.9572 26.1858 12.9572 26.4902 13.1284L37.1569 19.1284C37.4718 19.3055 37.6666 19.6387 37.6666 20V32C37.6666 32.3613 37.4718 32.6945 37.1569 32.8716L26.4902 38.8716C26.1858 39.0428 25.8141 39.0428 25.5097 38.8716L14.8431 32.8716C14.5282 32.6945 14.3333 32.3613 14.3333 32V20C14.3333 19.6387 14.5282 19.3055 14.8431 19.1284L25.5097 13.1284ZM16.3333 21.7098L25 26.5848V36.2902L16.3333 31.4152V21.7098ZM27 36.2902L35.6666 31.4152V21.7098L27 26.5848V36.2902ZM26 24.8527L34.6269 20L26 15.1473L17.373 20L26 24.8527Z'
        fill='#1D1E22'
      />
    </svg>
  );
}
