import { MockRequest } from '../types';

export const cans = {
  'j2-light/complete': {
    post: (request: MockRequest) => {
      return mockComplete(request);
    },
  },
  'j2-mid/complete': {
    post: (request: MockRequest) => {
      return mockComplete(request);
    },
  },
  'j2-ultra/complete': {
    post: (request: MockRequest) => {
      return mockComplete(request);
    },
  },
};

const mockComplete = (request: MockRequest) => {
  const { data } = request;

  return {
    completions: [
      {
        data: {
          text: JSON.stringify(data, null, 4),
          tokens: [],
        },
      },
    ],
  };
};
