export const defaultLabels = {
  great: [
    { id: 'relevant', title: 'Relevant', definition: '', category: 'great', score: 10 },
    { id: 'creative', title: 'Creative', definition: '', category: 'great', score: 10 },
    { id: 'style', title: 'Style', definition: '', category: 'great', score: 10 },
    { id: 'coherent', title: 'Coherent', definition: '', category: 'great', score: 10 },
    { id: 'truthful', title: 'Truthful', definition: '', category: 'great', score: 10 },
  ],
  okay: [
    { id: 'relevance', title: 'Relevance', definition: '', category: 'okay', score: 5 },
    { id: 'coherence', title: 'Coherence', definition: '', category: 'okay', score: 5 },
    { id: 'length', title: 'Length issue', definition: '', category: 'okay', score: 5 },
    { id: 'creativity', title: 'Creativity', definition: '', category: 'okay', score: 5 },
    { id: 'style', title: 'Style', definition: '', category: 'okay', score: 5 },
    { id: 'truthfulness', title: 'Truthfulness', definition: '', category: 'okay', score: 5 },
  ],
  bad: [
    { id: 'irrelevant', title: 'Irrelevant', definition: '', category: 'bad', score: 0 },
    { id: 'incoherent', title: 'Incoherent', definition: '', category: 'bad', score: 0 },
    { id: 'false', title: 'False', definition: '', category: 'bad', score: 0 },
    { id: 'length', title: 'Length issue', definition: '', category: 'bad', score: 0 },
    { id: 'repetitive', title: 'Repetitive', definition: '', category: 'bad', score: 0 },
    { id: 'offensive', title: 'Offensive', definition: '', category: 'bad', score: 0 },
  ],
};

export const parseLabels = (job?: Json) => {
  const output: Json[] = [];

  const { greatLabels, okLabels, badLabels } = job ?? {};

  if (greatLabels) {
    output.push(...greatLabels.map((label: string) => parseLabel(label, 'great', 10)));
  } else {
    output.push(...defaultLabels.great);
  }

  if (okLabels) {
    output.push(...okLabels.map((label: string) => parseLabel(label, 'okay', 5)));
  } else {
    output.push(...defaultLabels.okay);
  }

  if (badLabels) {
    output.push(...badLabels.map((label: string) => parseLabel(label, 'bad', 0)));
  } else {
    output.push(...defaultLabels.bad);
  }

  return output;
};

export const parseLabel = (label: string, category: string, score: number) => {
  return {
    id: label.toLowerCase(),
    title: label,
    definition: '',
    category,
    score,
  };
};
