import { get } from 'lodash';
import { createContext, useEffect, useMemo } from 'react';
import { useSetState } from 'react-use';
import { allLayouts } from '../../_definitions/layouts';
import {
  IPlaygroundContext,
  IPlaygroundState,
  PlaygroundProviderProps,
  Verb,
} from './BoxPlayground.types';

const initialState: IPlaygroundState = {
  layoutId: '',
  definition: {
    id: '',
    shortId: '',
    family: 'complete',
    instances: [],
    slots: [],
  },
  isLego: false,
  countUnit: 'characters',
  showParameters: false,
  flavour: '',
};

export const PlaygroundContext = createContext<IPlaygroundContext>({
  state: {
    ...initialState,
  },
  data: {},
  allOptions: {},
  callbacks: {
    onAction: (_id: string, _verb: string, _params?: Json) => {},
    onChange: (_id: string, _value: Json) => {},
  },
  patchState: () => {},
  flavour: '',
  isMobile: false,
});

export const PlaygroundContextProvider = (props: PlaygroundProviderProps) => {
  const { data: inData, familyId, layoutId, callbacks: inCallbacks, allOptions } = props;
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const [state, patchState] = useSetState<IPlaygroundState>({
    ...initialState,
  });

  useEffect(() => {
    const definition = allLayouts[layoutId];

    const initialFlavour = get(definition, 'initialFlavour', '');

    patchState({
      definition,
      layoutId,
      flavour: initialFlavour,
      isLego: familyId === 'task-specific-models',
    });
  }, [familyId, layoutId]);

  const callbacks = useMemo(
    () => ({
      onAction: (id: string, verb: Verb, params?: Json) => {
        inCallbacks.onAction(id, verb, params);
      },
      onChange: (id: string, value: Json) => {
        inCallbacks.onChange(id, value);
      },
    }),

    [state]
  );

  const cValue = useMemo(
    () => ({
      state,
      patchState,
      callbacks,
      allOptions: allOptions ?? {},
      data: inData,
      isMobile,
    }),
    [state, inData, allOptions, isMobile]
  );

  return <PlaygroundContext.Provider value={cValue}>{props.children}</PlaygroundContext.Provider>;
};
