import { LoginContext } from '@ai21/studio-auth';
import { CommandBar } from '@ai21/studio-ui';
import { useContext } from 'react';
import { invokeEvent } from 'shared-base';

export function CommandBarContainer() {
  const { isEmployee } = useContext(LoginContext).state;

  function onCommandBar(item: any) {
    const eventId = item.actionId || item.id;
    invokeEvent(eventId, item);
  }

  if (!isEmployee) {
    return null;
  }

  return <CommandBar items={commandBarItems as any} onRun={onCommandBar} />;
}

let commandBarItems = [
  {
    id: 'toggle_event_logs_analytics',
    label: 'Toggle analytics logs',
  },
  {
    id: 'toggle_event_logs_auth',
    label: 'Toggle auth logs',
  },
  {
    id: 'copy_amplitude_user_id_to_clipboard',
    label: 'Copy amplitude userId to clipboard',
  },
  {
    id: 'open_amplitude_dashboard',
    label: 'Open amplitude dashboard',
  },
  {
    id: 'evaluation_toggle',
    label: 'Evaluation: toggle',
    appId: 'evaluation',
    actionId: 'app_toggle',
  },
  {
    id: 'sheets_toggle',
    label: 'Sheets: toggle',
    appId: 'sheets',
    actionId: 'app_toggle',
  },
  {
    id: 'sheets_mock_data',
    label: 'Sheets: mock data',
    actionId: 'app/sheet',
  },
  {
    id: 'sheets_start_evaluation',
    label: 'Sheets: evaluate sheet',
    actionId: 'app/sheet',
  },
  {
    id: 'doc_library_toggle',
    label: 'RAG Engine: toggle',
    appId: 'documentLibrary',
    actionId: 'app_toggle',
  },
  {
    id: 'batch_generate_toggle',
    label: 'Batch generate: toggle',
    appId: 'batchGenerate',
    actionId: 'app_toggle',
  },
  {
    id: 'play_toggle',
    label: 'Play: toggle',
    appId: 'play',
    actionId: 'app_toggle',
  },
  {
    id: 'hub_toggle',
    label: 'Hub: toggle',
    appId: 'hub',
    actionId: 'app_toggle',
  },
];
