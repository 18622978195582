import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  font-size: 14px;
  color: ${tokens.text.secondary};
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex: 1;
`;

export const Name = styled.div`
  font-weight: 600;
  margin-right: 6px;
`;

export const OutOf = styled.div``;

export const Done = styled.div`
  color: ${tokens.text.secondary};
  margin-left: 4px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
