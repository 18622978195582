export function SnippetIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill='currentColor'
        d='M14.1819 3.27257C14.5837 3.37303 14.8281 3.78023 14.7276 4.18208L10.7276 20.1821C10.6271 20.5839 10.2199 20.8282 9.8181 20.7278C9.41625 20.6273 9.17193 20.2201 9.27239 19.8183L13.2724 3.81828C13.3729 3.41643 13.7801 3.17211 14.1819 3.27257ZM7.53033 7.46985C7.82322 7.76274 7.82322 8.23761 7.53033 8.53051L4.06066 12.0002L7.53033 15.4698C7.82322 15.7627 7.82322 16.2376 7.53033 16.5305C7.23744 16.8234 6.76256 16.8234 6.46967 16.5305L2.46967 12.5305C2.17678 12.2376 2.17678 11.7627 2.46967 11.4698L6.46967 7.46985C6.76256 7.17695 7.23744 7.17695 7.53033 7.46985ZM16.4697 7.46985C16.7626 7.17695 17.2374 7.17695 17.5303 7.46985L21.5303 11.4698C21.8232 11.7627 21.8232 12.2376 21.5303 12.5305L17.5303 16.5305C17.2374 16.8234 16.7626 16.8234 16.4697 16.5305C16.1768 16.2376 16.1768 15.7627 16.4697 15.4698L19.9393 12.0002L16.4697 8.53051C16.1768 8.23761 16.1768 7.76274 16.4697 7.46985Z'
      />
    </svg>
  );
}
