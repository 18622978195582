import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 46px;
  background: linear-gradient(0deg, #ffffff, #ffffff), #f6f6f6;
  padding: 6px 16px;
  border-bottom: 1px solid #e5e5e5;
`;

export const Actions = styled.div`
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid #e5e5e5;

  svg {
    stroke-width: 1.5px;
    color: #333;
  }
`;

export const People = styled.div`
  margin-right: 8px;
`;

export const Title = styled.div`
  color: ${tokens.text.secondary};
  font-weight: 600;
  flex: 1;
`;
