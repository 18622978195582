export function LogoDarkIcon() {
  return (
    <svg
      width='133'
      height='23'
      viewBox='0 0 133 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M124.276 20.8438C122.901 20.8438 121.719 20.5625 120.73 20C119.747 19.4319 118.989 18.6421 118.455 17.6307C117.926 16.6137 117.662 15.4347 117.662 14.0938C117.662 12.7472 117.926 11.5682 118.455 10.5569C118.989 9.53981 119.747 8.75004 120.73 8.18754C121.719 7.61936 122.901 7.33527 124.276 7.33527C125.651 7.33527 126.83 7.61936 127.812 8.18754C128.801 8.75004 129.56 9.53981 130.088 10.5569C130.622 11.5682 130.889 12.7472 130.889 14.0938C130.889 15.4347 130.622 16.6137 130.088 17.6307C129.56 18.6421 128.801 19.4319 127.812 20C126.83 20.5625 125.651 20.8438 124.276 20.8438ZM124.301 17.6989C124.801 17.6989 125.224 17.5455 125.571 17.2387C125.918 16.9319 126.182 16.5057 126.364 15.9603C126.551 15.4148 126.645 14.7841 126.645 14.0682C126.645 13.3409 126.551 12.7046 126.364 12.1591C126.182 11.6137 125.918 11.1875 125.571 10.8807C125.224 10.5739 124.801 10.4205 124.301 10.4205C123.784 10.4205 123.347 10.5739 122.989 10.8807C122.636 11.1875 122.366 11.6137 122.179 12.1591C121.997 12.7046 121.906 13.3409 121.906 14.0682C121.906 14.7841 121.997 15.4148 122.179 15.9603C122.366 16.5057 122.636 16.9319 122.989 17.2387C123.347 17.5455 123.784 17.6989 124.301 17.6989Z'
        fill='#E91E63'
      />
      <path
        d='M111.344 20.5966V7.50568H115.512V20.5966H111.344ZM113.433 5.98011C112.847 5.98011 112.344 5.78693 111.924 5.40057C111.504 5.00852 111.293 4.53693 111.293 3.9858C111.293 3.44034 111.504 2.97443 111.924 2.58807C112.344 2.19602 112.847 2 113.433 2C114.023 2 114.526 2.19602 114.941 2.58807C115.362 2.97443 115.572 3.44034 115.572 3.9858C115.572 4.53693 115.362 5.00852 114.941 5.40057C114.526 5.78693 114.023 5.98011 113.433 5.98011Z'
        fill='#E91E63'
      />
      <path
        d='M100.774 20.7841C99.8082 20.7841 98.9304 20.5341 98.1406 20.0341C97.3509 19.5341 96.7202 18.7841 96.2486 17.7841C95.777 16.7841 95.5412 15.5427 95.5412 14.0597C95.5412 12.5199 95.7855 11.2529 96.2742 10.2586C96.7628 9.26425 97.402 8.52845 98.1918 8.05118C98.9872 7.57391 99.8423 7.33527 100.757 7.33527C101.445 7.33527 102.033 7.45459 102.521 7.69323C103.01 7.92618 103.413 8.22732 103.732 8.59664C104.05 8.96595 104.291 9.34948 104.456 9.7472H104.541V3.14209H108.709V20.5966H104.584V18.4745H104.456C104.28 18.8779 104.03 19.2557 103.706 19.608C103.382 19.9603 102.976 20.2444 102.487 20.4603C102.004 20.6762 101.433 20.7841 100.774 20.7841ZM102.223 17.537C102.729 17.537 103.161 17.3949 103.518 17.1108C103.876 16.8211 104.152 16.4148 104.345 15.8921C104.538 15.3694 104.635 14.7557 104.635 14.0512C104.635 13.3353 104.538 12.7188 104.345 12.2017C104.158 11.6847 103.882 11.287 103.518 11.0086C103.161 10.7302 102.729 10.591 102.223 10.591C101.706 10.591 101.268 10.733 100.911 11.0171C100.553 11.3012 100.28 11.7017 100.092 12.2188C99.9105 12.7358 99.8196 13.3466 99.8196 14.0512C99.8196 14.7557 99.9134 15.3694 100.101 15.8921C100.288 16.4148 100.558 16.8211 100.911 17.1108C101.268 17.3949 101.706 17.537 102.223 17.537Z'
        fill='#E91E63'
      />
      <path
        d='M89.1811 14.9461V7.50574H93.3402V20.5966H89.3686V18.1591H89.2322C88.9425 18.9603 88.4481 19.5966 87.7493 20.0682C87.0561 20.5341 86.218 20.7671 85.2351 20.7671C84.343 20.7671 83.5589 20.5626 82.8828 20.1535C82.2067 19.7444 81.6811 19.1734 81.3061 18.4404C80.9311 17.7018 80.7408 16.8381 80.7351 15.8495V7.50574H84.9027V15.0313C84.9084 15.7415 85.0959 16.3012 85.4652 16.7103C85.8345 17.1194 86.3374 17.3239 86.9737 17.3239C87.3885 17.3239 87.7606 17.233 88.0902 17.0512C88.4254 16.8637 88.6896 16.5938 88.8828 16.2415C89.0817 15.8836 89.1811 15.4518 89.1811 14.9461Z'
        fill='#E91E63'
      />
      <path
        d='M78.6087 7.50569V10.5739H70.3501V7.50569H78.6087ZM72.0803 4.36932H76.2479V16.4801C76.2479 16.7358 76.2876 16.9432 76.3672 17.1023C76.4524 17.2557 76.5746 17.3665 76.7337 17.4347C76.8928 17.4972 77.0831 17.5284 77.3047 17.5284C77.4638 17.5284 77.6314 17.5142 77.8075 17.4858C77.9893 17.4517 78.1257 17.4233 78.2166 17.4006L78.8473 20.4091C78.6484 20.4659 78.3672 20.5369 78.0036 20.6222C77.6456 20.7074 77.2166 20.7614 76.7166 20.7841C75.7393 20.8296 74.9013 20.7159 74.2024 20.4432C73.5092 20.1648 72.978 19.733 72.6087 19.1477C72.245 18.5625 72.0689 17.8267 72.0803 16.9403V4.36932Z'
        fill='#E91E63'
      />
      <path
        d='M64.8104 8.37503C64.7535 7.75003 64.5007 7.26424 64.0518 6.91765C63.6087 6.56537 62.9751 6.38924 62.1513 6.38924C61.6058 6.38924 61.1513 6.46026 60.7876 6.60231C60.424 6.74435 60.1513 6.94037 59.9695 7.19037C59.7876 7.43469 59.6939 7.71594 59.6882 8.03412C59.6768 8.29549 59.728 8.5256 59.8416 8.72447C59.9609 8.92333 60.1314 9.09947 60.353 9.25287C60.5803 9.4006 60.853 9.53128 61.1712 9.64492C61.4893 9.75856 61.8473 9.85799 62.245 9.94322L63.745 10.2841C64.6087 10.4716 65.37 10.7216 66.0291 11.0341C66.6939 11.3466 67.2507 11.7188 67.6996 12.1506C68.1541 12.5824 68.4979 13.0796 68.7308 13.6421C68.9638 14.2046 69.0831 14.8353 69.0888 15.5341C69.0831 16.6364 68.8047 17.5824 68.2535 18.3722C67.7024 19.162 66.9098 19.7671 65.8757 20.1875C64.8473 20.608 63.6058 20.8182 62.1513 20.8182C60.691 20.8182 59.4183 20.5995 58.3331 20.162C57.2479 19.7245 56.4041 19.0597 55.8018 18.1676C55.1996 17.2756 54.8899 16.1478 54.8729 14.7841H58.9126C58.9467 15.3466 59.0973 15.8154 59.3643 16.1904C59.6314 16.5654 59.9979 16.8495 60.4638 17.0426C60.9354 17.2358 61.4808 17.3324 62.1001 17.3324C62.6683 17.3324 63.1513 17.2557 63.549 17.1023C63.9524 16.9489 64.2621 16.7358 64.478 16.4631C64.6939 16.1904 64.8047 15.8779 64.8104 15.5256C64.8047 15.1961 64.7024 14.9148 64.5035 14.6819C64.3047 14.4432 63.9979 14.2387 63.5831 14.0682C63.174 13.8921 62.6513 13.7301 62.0149 13.5824L60.191 13.1563C58.6797 12.8097 57.4893 12.25 56.62 11.4773C55.7507 10.6989 55.3189 9.64776 55.3246 8.3239C55.3189 7.24435 55.6087 6.29833 56.1939 5.48583C56.7791 4.67333 57.5888 4.03981 58.6229 3.58526C59.657 3.13072 60.8359 2.90344 62.1598 2.90344C63.5121 2.90344 64.6854 3.13356 65.6797 3.59378C66.6797 4.04833 67.4553 4.68753 68.0064 5.5114C68.5575 6.33526 68.8388 7.28981 68.8501 8.37503H64.8104Z'
        fill='#E91E63'
      />
      <path
        d='M51.483 3.14209V20.5966H47.2727V7.07959H47.1705L43.267 9.46595V5.81823L47.571 3.14209H51.483Z'
        fill='#1D1E22'
      />
      <path
        d='M28.1072 20.5966V17.5625L34.4737 11.9887C34.951 11.5569 35.3572 11.162 35.6925 10.804C36.0277 10.4404 36.2834 10.0767 36.4595 9.7131C36.6357 9.34378 36.7237 8.94322 36.7237 8.5114C36.7237 8.02844 36.6186 7.61651 36.4084 7.2756C36.1982 6.92901 35.9084 6.66197 35.5391 6.47447C35.1697 6.28697 34.7464 6.19322 34.2692 6.19322C33.7862 6.19322 33.3629 6.29265 32.9993 6.49151C32.6357 6.68469 32.3516 6.96594 32.147 7.33526C31.9482 7.70458 31.8487 8.15344 31.8487 8.68185H27.8516C27.8516 7.49435 28.1186 6.46878 28.6527 5.60515C29.1868 4.74151 29.9368 4.07674 30.9027 3.61083C31.8743 3.13924 33.0021 2.90344 34.2862 2.90344C35.6101 2.90344 36.7607 3.12503 37.7379 3.56822C38.7152 4.0114 39.4709 4.63072 40.005 5.42617C40.5447 6.21594 40.8146 7.13356 40.8146 8.17901C40.8146 8.84378 40.6811 9.50287 40.4141 10.1563C40.147 10.8097 39.6669 11.5313 38.9737 12.3211C38.2862 13.1108 37.3089 14.0569 36.0419 15.1591L33.9538 17.0853V17.1961H41.0277V20.5966H28.1072Z'
        fill='#1D1E22'
      />
      <path d='M25.4929 3.14209V20.5966H21.2741V3.14209H25.4929Z' fill='#1D1E22' />
      <path
        d='M6.53409 20.5966H2L7.8892 3.14209H13.5057L19.3949 20.5966H14.8608L10.7614 7.53982H10.625L6.53409 20.5966ZM5.92898 13.7273H15.4062V16.9319H5.92898V13.7273Z'
        fill='#1D1E22'
      />
    </svg>
  );
}
