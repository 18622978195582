import { fork } from 'saga-ts';
import { root as api } from './saga.api';
import { root as collectionJobs } from './saga.collectionJobs';
import { root as collectionLines } from './saga.collectionLines';
import { root as collectionStatus } from './saga.collectionStatus';
import { root as listener } from './saga.listener';

export function* root() {
  yield* fork(api);
  yield* fork(collectionJobs);
  yield* fork(collectionLines);
  yield* fork(collectionStatus);
  yield* fork(listener);
}
