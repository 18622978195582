/*
const boxStyles = makeStyles(() => ({
    enabled: {
        '&:hover': {
            fill: '#626B78',
        },
    },
    disabled: {
        fill: '#D6D8DE',
    },
}));
*/

export function BoxIcon({ enabled }: { enabled: boolean }) {
  return (
    <svg
      fill='currentColor'
      // className={enabled ? classes.enabled : classes.disabled}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6323 2.34632C11.8606 2.21789 12.1394 2.21789 12.3677 2.34632L20.3677 6.84632C20.6039 6.97916 20.75 7.22904 20.75 7.5V16.5C20.75 16.771 20.6039 17.0208 20.3677 17.1537L12.3677 21.6537C12.1394 21.7821 11.8606 21.7821 11.6323 21.6537L3.6323 17.1537C3.39615 17.0208 3.25 16.771 3.25 16.5V7.5C3.25 7.22904 3.39615 6.97916 3.6323 6.84632L11.6323 2.34632ZM4.75 8.78239L11.25 12.4386V19.7176L4.75 16.0614V8.78239ZM12.75 19.7176L19.25 16.0614V8.78239L12.75 12.4386V19.7176ZM12 11.1395L18.4702 7.5L12 3.86051L5.5298 7.5L12 11.1395Z'
      />
    </svg>
  );
}
