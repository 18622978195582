export function GoogleIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='24' fill='none' viewBox='0 0 22 24'>
      <g clipPath='url(#clip0)'>
        <path
          fill='#4285F4'
          d='M22 12.07c0-.764-.061-1.532-.194-2.283H11.221v4.327h6.062c-.252 1.395-1.06 2.63-2.244 3.414v2.808h3.617C20.78 18.379 22 15.489 22 12.07z'
        />
        <path
          fill='#34A853'
          d='M11.22 23.048c3.027 0 5.58-.995 7.44-2.712l-3.617-2.808c-1.006.686-2.305 1.074-3.818 1.074-2.928 0-5.41-1.978-6.301-4.637H1.192v2.894c1.905 3.795 5.785 6.19 10.028 6.19z'
        />
        <path
          fill='#FBBC04'
          d='M4.92 13.965c-.47-1.396-.47-2.907 0-4.302V6.768H1.191C-.4 9.944-.4 13.684 1.192 16.86l3.727-2.894z'
        />
        <path
          fill='#EA4335'
          d='M11.22 5.022c1.6-.025 3.147.578 4.306 1.685l3.204-3.209C16.7 1.591 14.008.542 11.22.575 6.977.575 3.098 2.97 1.192 6.768l3.727 2.895C5.806 7 8.293 5.022 11.22 5.022z'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <path fill='#fff' d='M0 0H22V22.473H0z' transform='translate(0 .575)' />
        </clipPath>
      </defs>
    </svg>
  );
}
