import { createExperimentClient, experimentConfig, VariantsClient } from '@ai21/studio-experiment';
import { call, fork, put, take, takeEvery } from 'saga-ts';
import { invokeEvent } from 'shared-base';
import { authState } from '../utils/globals';
import { logger } from '../utils/log';
import { authChangeChannel } from './channels/channel.authChange';
import { navigateToLogin } from './helpers/navigation';
import { checkLimboState } from './saga.email';
import { User } from 'firebase/auth';
import { getFeatureFlag, initFeatureFlags, shouldUseCookieAuth } from './saga.featureflags';

const identityBaseUrl = ((window as any).__env__.VITE_IDENTITY_SERVER_URL ?? '') + '/identity';
// step #3
export function* authChange(event: any) {
  const { user } = event;
  const isUsingCookieAuth = yield* call(shouldUseCookieAuth);

  authState.setUser(user);
  logger.CheckCurrentUser();

  if (!user) {
    yield* fork(navigateToLogin, true);
    return;
  }

  const emailShouldNotBeVerified = isGithubUser(user) || isWorkOSUser(user);
  const isEmailVerified = user.email_verified || user.emailVerified; // TODO: remove emailVerified

  invokeEvent('user', { user });
  if (!isUsingCookieAuth) {
    yield* fork(checkLimboState, user);
  }

  if (user && (isEmailVerified || emailShouldNotBeVerified)) {
    yield put({ type: 'saga/complete', user });
  }
}

const isGithubUser = (user: User) => {
  try {
    const provider = user?.firebase?.sign_in_provider || user.providerData[0].providerId; // TODO: remove user.providerData[0].providerId
    return (
      provider === 'github.com' ||
      user.providerData.some(({ providerId }) => providerId === 'github.com')
    );
  } catch (err: any) {
    return false;
  }
};

export const isWorkOSUser = (user: User) => {
  try {
    const provider = user?.firebase?.sign_in_provider || user.providerData[0].providerId; // TODO: remove user.providerData[0].providerId
    return (
      provider === 'oidc.workos' ||
      user.providerData.some(({ providerId }) => providerId === 'oidc.workos')
    );
  } catch (err: any) {
    return false;
  }
};

const getFirebaseUser = async () => {
  const firebasebUser = await fetch(`${identityBaseUrl}/legacy/fb-user`, {
    credentials: 'include', // This line ensures cookies are sent with the request
  }).then((res) => {
    if (res.status !== 200) {
      throw new Error('Failed to get firebase user');
    }
    return res.json();
  });
  return firebasebUser;
};

export function* root() {
  yield take('saga/auth');
  yield call(initFeatureFlags);
  const shouldUseCookie: boolean = yield call(shouldUseCookieAuth);

  if (shouldUseCookie) {
    try {
      const response = yield* call(getFirebaseUser);
      const user = { ...response[0], firebaseToken: response[1] };
      yield call(authChange, { user });
    } catch (error) {
      yield* fork(navigateToLogin, true);
    }
  }

  const channel = authChangeChannel();
  yield takeEvery(channel, authChange);
}
