import { ToggleButton as ToggleButtonUI } from '@mui/material';
import { styled } from '../..';
import { tokens } from '../../theme';

export const Wrapper = styled.div`
  background: ${tokens.primitives.grey[50]};
  border-radius: ${tokens.primitives.radius.medium}px;
`;

export const ToggleButton = styled(ToggleButtonUI)`
  font-size: 14px;

  &.MuiToggleButtonGroup-grouped {
    border-color: ${tokens.primitives.grey[50]};
    color: ${tokens.primary.mainDefault};
    height: 36px;

    &:not(:last-of-type) {
      color: ${tokens.primary.mainActive};
      border-top-left-radius: ${tokens.primitives.radius.medium}px;
      border-bottom-left-radius: ${tokens.primitives.radius.medium}px;
    }

    &:not(:first-of-type) {
      border-top-right-radius: ${tokens.primitives.radius.medium}px;
      border-bottom-right-radius: ${tokens.primitives.radius.medium}px;
    }

    &.Mui-selected {
      border: 1px solid ${tokens.primary.borderActive};
      background: #fff;
      border-radius: ${tokens.primitives.radius.medium}px;
      border-color: ${tokens.primary.borderActive};
      color: ${tokens.primary.mainActive};

      &:not(:last-of-type) {
        color: ${tokens.primary.mainActive};
        border-top-right-radius: ${tokens.primitives.radius.medium}px;
        border-bottom-right-radius: ${tokens.primitives.radius.medium}px;
      }

      &:not(:first-of-type) {
        border-top-left-radius: ${tokens.primitives.radius.medium}px;
        border-bottom-left-radius: ${tokens.primitives.radius.medium}px;
      }
    }
  }
`;
