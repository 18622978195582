import { invokeEvent } from 'shared-base';

type DrawerRequest = {
  id: string;
  hideBackdrop?: boolean;
  variant?: 'persistent' | 'temporary';
  component?: React.FC<any>;
  componentProps?: Json;
  componentCta?: string;
  callbacks?: any;
};

const open = (promptRequest: DrawerRequest) => {
  const {
    id,
    component,
    componentProps,
    componentCta,
    hideBackdrop,
    variant = 'temporary',
  } = promptRequest;

  invokeEvent('SHOW_DRAWER', {
    flavour: 'basic',
    hideBackdrop,
    variant,
    params: {
      id,
      component,
      componentProps,
      componentCta,
    },
  });

  invokeEvent('global/drawer', {
    type: 'basic',
    action: 'show',
  });
};

export const drawer = {
  open,
};
