import { Tooltip } from '@mui/material';
import { useShortKey } from '../../hooks/useShortKeys';
import { Icon } from '../Icon/Icon';
import { Wrapper } from './Lock.style';
import classnames from 'classnames';

export type LockProps = {
  isLocked: boolean;
  onToggle: () => void;
};

const isMac = navigator.userAgent.toLocaleLowerCase().indexOf('mac') >= 0;
const altKey = isMac ? '⌥' : 'Alt+';

const buttonParams: Record<string, Json> = {
  true: {
    iconName: 'Lock',
    tooltip: `Unlock (${altKey}L)`,
    color: 'primary',
    text: 'Unlock to edit',
  },
  false: {
    iconName: 'LockOpen',
    tooltip: `Lock (${altKey}L)`,
    color: 'secondary',
    text: '',
  },
};

export function Lock(props: LockProps) {
  const { isLocked = false } = props;

  const params = buttonParams[String(isLocked)];

  useShortKey({ key: '¬', withAlt: true, preventDefault: true }, () => {
    props.onToggle();
  });

  const className = classnames({
    unlocked: !isLocked,
  });

  return (
    <Tooltip title={params.tooltip}>
      <Wrapper
        className={className}
        onClick={props.onToggle}
        color={params.color}
        endIcon={<Icon iconName={params.iconName} />}
        data-testid='Lock-wrapper'
      >
        {params.text}
      </Wrapper>
    </Tooltip>
  );
}

export default Lock;
