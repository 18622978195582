import type { IMenuGroup, IMenuItem } from '@ai21/studio-ui';
import { AppRoutes } from './App.routes';

// 1st word capitalized, 2nd word lowercase
export const sideBarItems: IMenuItem[] = [
  {
    id: 'batchGenerate',
    title: 'Generation sets',
    path: AppRoutes.GENERATION_SETS,
    iconName: 'menu.Generation',
    order: 1,
    groupId: 'tools',
    isChild: true,
  },
];

export const sideBarGroups: IMenuGroup[] = [];
