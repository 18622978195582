import { LogAttributes } from '@opentelemetry/api-logs';
import { AI21Telemetry, SeverityNumber } from './ai21-telemetry-js';
import { ErrorLogParams, LogParams, Counter } from './types/telemetry';
import { ValueType } from '@opentelemetry/api';
import { Optional } from './types/types';

interface TelemetryClientOptions {
  clientIdentifier: string;
  debug?: boolean;
}

class TelemetryClient {
  private static instance: Optional<AI21Telemetry>;
  private static clientIdentifier: string;
  private static allowDebug: boolean;

  static init(instance: AI21Telemetry, options: TelemetryClientOptions) {
    TelemetryClient.instance = instance;
    TelemetryClient.clientIdentifier = options.clientIdentifier;
    TelemetryClient.allowDebug = !!options.debug;
  }

  private static log(logParams: LogParams | ErrorLogParams): void {
    const attributes = {
      source: 'Experiment SDK',
      ...logParams.attributes,
    };
    const body = `[Experiment SDK] ${logParams.body}`;

    TelemetryClient.instance?.getLogger(TelemetryClient.clientIdentifier).emit({
      ...logParams,
      attributes,
      body,
    });
  }

  static info(body: string, attributes: LogAttributes = {}): void {
    TelemetryClient.log({ attributes, body, severityNumber: SeverityNumber.INFO });
  }

  static debug(body: string, attributes: LogAttributes = {}): void {
    if (!TelemetryClient.allowDebug) {
      return;
    }

    TelemetryClient.log({ attributes, body, severityNumber: SeverityNumber.DEBUG });

    console.debug(`[Experiment SDK] ${body}`, attributes);
  }

  static warning(body: string, error: Error, attributes: LogAttributes = {}): void {
    TelemetryClient.log({ attributes, error, body, severityNumber: SeverityNumber.WARN });
  }

  static error(body: string, error: Error, attributes: LogAttributes = {}): void {
    TelemetryClient.log({ attributes, error, body, severityNumber: SeverityNumber.ERROR });
  }

  static getIntCounter(counterName: string): Optional<Counter> {
    return TelemetryClient.instance
      ?.getMeter(TelemetryClient.clientIdentifier)
      .createCounter(counterName, { valueType: ValueType.INT });
  }
}

export default TelemetryClient;
