interface ModelsIconProps {
  style: React.CSSProperties;
}

export function ModelsIcon({ style }: ModelsIconProps) {
  return (
    <svg
      style={style}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.792 6.372a2.293 2.293 0 1 0-1.25 0v7.256a2.293 2.293 0 1 0 2.626 1.088l8.548-8.548c.154.086.319.155.492.204v7.256a2.293 2.293 0 1 0 1.25 0V6.372a2.293 2.293 0 1 0-2.626-1.088l-8.548 8.548a2.276 2.276 0 0 0-.492-.204V6.372zm-.625-3.247a1.042 1.042 0 0 0-.003 2.083h.006a1.042 1.042 0 0 0-.003-2.083zm11.67 2.083a1.042 1.042 0 1 0-.006 0h.005zm-.004 9.584a1.042 1.042 0 1 0 0 2.083 1.042 1.042 0 0 0 0-2.083zm-11.666 0a1.042 1.042 0 1 0 0 2.083 1.042 1.042 0 0 0 0-2.083z'
      />
    </svg>
  );
}
