import { Box, Button, Dialog, DialogActions, Divider, Grid, Typography } from '@ai21/studio-ui';

import DatasetUploadStyles from './DatasetUploadStyles';
import { formatBytes } from '../../utils/utils';
import { tokens } from '@ai21/studio-ui';
import { useDispatch } from 'react-redux';
import { MODAL_DISMISSED, MODAL_SHOWN } from '../../redux/actionTypes/userActionTypes';
import { useEffect } from 'react';

interface UploadDatasetDialogSuccessStateProps {
  viewDatasets: () => void;
  trainModel: () => void;
  onClose: () => void;
  fileDetails: { name: string | undefined; size: number | undefined; id: string | undefined };
}

export function UploadDatasetDialogSuccessState({
  viewDatasets,
  trainModel,
  onClose,
  fileDetails,
}: UploadDatasetDialogSuccessStateProps) {
  const classes = DatasetUploadStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: MODAL_SHOWN,
      payload: {
        origin: 'dataset',
        text: 'Awesome! Your dataset is ready!',
        name: 'uploadDatasetDone',
      },
    });

    return () => {
      dispatch({
        type: MODAL_DISMISSED,
        payload: { origin: 'dataset', reason: 'next step', name: 'uploadDatasetDone' },
      });
    };
  }, []);
  return (
    <div className={classes.dialogContainer} data-testid='dataset-success-state-dialog'>
      <Grid container justifyContent='space-between'>
        <Typography className={classes.title} variant='h5'>
          Awesome! Your dataset is ready! 🎉
        </Typography>
      </Grid>
      <Box marginTop='16px' />
      <Typography
        variant='body1'
        style={{
          marginTop: 20,
          fontSize: 20,
          fontWeight: 300,
          color: `${tokens?.text?.secondary ? tokens?.text?.secondary : '#676465'}`,
        }}
      >
        {
          'Your new dataset has been successfully uploaded to your\naccount! Now you can access and train models with it.'
        }
      </Typography>
      <Box marginTop='34px' />
      <Grid
        container
        style={{
          backgroundColor: 'rgba(46, 46, 46, 0.04)',
          padding: '16px 24px',
          borderRadius: 4,
        }}
      >
        <Grid item container direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='subtitle2'>Dataset</Typography>
          <Typography variant='subtitle2' style={{ fontWeight: 800 }}>
            {fileDetails?.name}
          </Typography>
        </Grid>
        <Divider sx={{ width: '100%', margin: '14px 0px' }} />
        <Grid item container direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='subtitle2'>Size</Typography>
          <Typography variant='subtitle2'>{formatBytes(fileDetails?.size, 2)}</Typography>
        </Grid>
        <Divider sx={{ width: '100%', margin: '14px 0px' }} />
        <Grid item container direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='subtitle2'>ID</Typography>
          <Typography variant='subtitle2'>{fileDetails?.id}</Typography>
        </Grid>
      </Grid>
      <Box marginTop='32px' />
      <DialogActions sx={{ marginBottom: 1 }}>
        <Button
          data-testid='dataset-cancel-dialog-btn'
          variant='outlined'
          size='medium'
          color='secondary'
          onClick={viewDatasets}
        >
          View all my datasets
        </Button>
        <Button variant='contained' size='medium' color='primary' onClick={trainModel}>
          Train Model Now
        </Button>
      </DialogActions>
    </div>
  );
}
