import { TaskSpecificModels } from './../../../tests/playwright/infra/page-objects/TaskSpecificModels';
import { Json } from '../types';
import { IRouteMap, ILinks } from '@ai21/studio-ui';
import { authRoutes } from '@ai21/studio-auth';

/**
 * Holds all routes of our applications
 */
export enum WebRoutes {
  uiComponents = '/ui-components',
  billingPlans = '/account/plans-billing',
  accountDetails = '/account',
  apiKey = '/account/api-key',
  organization = '/account/organization',
  modelUsage = '/account/model-usage',
  changePayment = '/account/change-payment',
  fineTuningSets = '/datasets/fine-tuning-sets',
  examples = '/examples',
  foundationModels = '/foundation-models',
  onboardingForm = '/form',
  customModels = '/custom-models',
  trainModelRoot = '/custom-models/train/*',
  trainModelStep1 = '/custom-models/train/choose-dataset',
  trainModelStep2 = '/custom-models/train/settings',
  trainModelErrorCredits = '/custom-models/train/credit-exceeded',
  trainModelErrorTrail = '/custom-models/train/trail-ended',
  trainModelErrorGeneral = '/custom-models/train/error',
  home = '/home',
  payment = '/payment',
  playground = '/playground',
  presets = '/datasets/presets',
  pricing = '/pricing',
  releaseLogs = '/release-logs',
  taskSpecificModels = '/task-specific-models',
  models = '/models',
  privatePolicy = '/privacy-policy',
  termsOfUse = '/terms-of-use',
  termsOfUsePublic = '/public/terms-of-use',
  notFound = '/404',
  public = '/public',
  external = '/external',
  widget = '/widget/:widgetDest/:widgetId',
  SHOWROOM = '/use-cases',
  LP_SHOWROOM = '/lp/use-cases',
  LP_APP = '/lp/use-cases/:appTitle/:appId',
  LP_COMPANY = '/lp/company',
  datasets = '/datasets',
  tools = '/tools',
  evaluationJobs = '/tools/evaluation',
  evaluationLines = '/tools/evaluation/:evaluationId/inbox',
  generationSets = '/tools/generation-sets',
  generationLines = '/tools/generation-sets/:id/lines',
  playgroundHome = '/ply',
  j2Complete1Input1Output = '/home/complete/single-input-1-output',
  j2Complete1Input2Output = '/home/complete/single-input-2-outputs',
  j2Complete1Input3Output = '/home/complete/single-input-3-outputs',
  j2Complete2Input2Output = '/home/complete/double-input-2-outputs',
  j2Complete2Input4Output = '/home/complete/double-input-4-outputs',
  paraphrazeModel = '/home/task-specific-models/paraphrase',
  summarizeModel = '/home/task-specific-models/summarize',
  gecModel = '/home/task-specific-models/grammatical-error-correction',
  textImprovementsModel = '/home/task-specific-models/text-improvements',
  contextualAnswersModel = '/home/task-specific-models/contextual-answers',
  j2Chat1 = '/home/complete/chat1',
  j2Chat = '/home/complete/chat',
  j2ChatHistory = '/home/complete/chat-history',
  j2SingleChat = '/home/chat/single-chat',
  conversationalChat = '/v2/conversational-chat',
  connectors = '/v2/integrations',
}

export enum DeprecatedWebRoutes {
  taskSpecificApis = '/task-specific-apis',
  summarize = '/home/summarize',
  rewrite = '/home/rewrite',
  dataSets = '/datasets',
  overview = '/overview',
  trainCustomModel = '/custom-models/train',
  playground = '/playground',
  billing = '/account/billing-plans',
}

/**
 * External routes that do not require to be logged in
 */
export const publicRoutes: IRouteMap = {
  ...authRoutes,
  [WebRoutes.privatePolicy]: 'privacy',
  [WebRoutes.termsOfUsePublic]: 'termsFullPage',
  [WebRoutes.public]: 'generic',
};

/**
 * private routes are routes that require users to be logged in
 */
export const privateRoutes: IRouteMap = {
  [WebRoutes.uiComponents]: 'uiComponents',
  [WebRoutes.billingPlans]: 'billing',
  [WebRoutes.accountDetails]: 'account',
  [WebRoutes.apiKey]: 'apiKey',
  [WebRoutes.organization]: 'organization',
  [WebRoutes.modelUsage]: 'usage',
  [WebRoutes.changePayment]: 'changePayment',
  [WebRoutes.fineTuningSets]: 'fineTuningSets',
  [WebRoutes.examples]: 'examples',
  [WebRoutes.foundationModels]: 'foundation',
  [WebRoutes.onboardingForm]: 'onBoarding',
  [WebRoutes.customModels]: 'customModels',
  [WebRoutes.trainModelRoot]: 'training',
  [WebRoutes.home]: 'home',
  [WebRoutes.payment]: 'payment',
  [WebRoutes.presets]: 'presets',
  [WebRoutes.tools]: 'tools',
  [WebRoutes.datasets]: 'datasets',
  [WebRoutes.releaseLogs]: 'releaseLogs',
  [WebRoutes.taskSpecificModels]: 'lego',
  [WebRoutes.models]: 'ourModels',
  [WebRoutes.notFound]: 'notFound',
  [WebRoutes.termsOfUse]: 'terms',
  [WebRoutes.evaluationJobs]: 'evaluationJobs',
  [WebRoutes.evaluationLines]: 'evaluationLines',
  [WebRoutes.generationSets]: 'generationSets',
  [WebRoutes.generationLines]: 'generationLines',
  [WebRoutes.playgroundHome]: 'newPlaygroundHome',
  [WebRoutes.j2Complete1Input1Output]: 'playground-1-1',
  [WebRoutes.j2Complete1Input2Output]: 'playground-1-2',
  [WebRoutes.j2Complete1Input3Output]: 'playground-1-3',
  [WebRoutes.j2Complete2Input2Output]: 'playground-2-2',
  [WebRoutes.j2Complete2Input4Output]: 'playground-2-4',
  [WebRoutes.paraphrazeModel]: 'playground-paraphrase',
  [WebRoutes.summarizeModel]: 'playground-summarize',
  [WebRoutes.gecModel]: 'playground-gec',
  [WebRoutes.textImprovementsModel]: 'playground-improvements',
  [WebRoutes.contextualAnswersModel]: 'playground-answers',
  [WebRoutes.j2Chat1]: 'playground-chat1',
  [WebRoutes.j2Chat]: 'playground-chat',
  [WebRoutes.j2ChatHistory]: 'playground-chatHistory',
  [WebRoutes.j2SingleChat]: 'playground-single-chat',
  [WebRoutes.SHOWROOM]: 'showroom',
};

/**
 * External routes (which I assume) that lead to external pages, outside of the webapp
 */
export const externalRoutes: IRouteMap = {
  [WebRoutes.widget]: 'widget',
  [WebRoutes.external]: 'external',
  [WebRoutes.LP_SHOWROOM]: 'showroom',
  [WebRoutes.LP_APP]: 'showroom',
  [WebRoutes.LP_COMPANY]: 'company',
};

export const defaultRoute = WebRoutes.home;

export const redirects: IRouteMap = {
  [DeprecatedWebRoutes.summarize]: WebRoutes.summarizeModel,
  [DeprecatedWebRoutes.rewrite]: WebRoutes.paraphrazeModel,
  [DeprecatedWebRoutes.dataSets]: WebRoutes.fineTuningSets,
  [DeprecatedWebRoutes.taskSpecificApis]: WebRoutes.models,
  [DeprecatedWebRoutes.overview]: WebRoutes.home,
  [DeprecatedWebRoutes.trainCustomModel]: WebRoutes.trainModelStep1,
  [DeprecatedWebRoutes.playground]: WebRoutes.home,
  [WebRoutes.taskSpecificModels]: WebRoutes.models,
  [WebRoutes.foundationModels]: WebRoutes.models,
  [DeprecatedWebRoutes.billing]: WebRoutes.billingPlans,
};

export const notFoundPageId = 'notFound';

export const externalLinks: ILinks = {
  docs: 'https://docs.ai21.com',
  responsibleUse: 'https://docs.ai21.com/docs/responsible-use',
  trainingData: 'https://docs.ai21.com/docs/training-data',
};

export const hideIntercomPaths = [
  WebRoutes.widget,
  WebRoutes.uiComponents,
  WebRoutes.changePayment,
  WebRoutes.payment,
  WebRoutes.LP_SHOWROOM,
  WebRoutes.LP_APP,
  WebRoutes.LP_COMPANY,
].map((path) => path.replaceAll(/\/:[a-z]+/gi, '')); // remove `/:variables` from paths

export const V2_PATHS_MAP: Record<string, string> = {
  '/home': '/v2',
  '/home/chat/single-chat': '/v2/chat',
};

export const pages = {
  ...publicRoutes,
  ...privateRoutes,
  ...externalRoutes,
};
