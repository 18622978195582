export const catalogueCardData = {
  ux: {
    texts: {
      badges: {
        newBadge: 'New',
        ai21Badge: 'AI21',
        betaBadge: "Beta"
      },
    },
  },
};
