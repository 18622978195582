import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 24px;
  height: 24px;
  background-color: #753fea;
  border: 1px solid white;
  font-weight: 600;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 24px;
`;

export const Plus = styled.span`
  margin-right: -1px;
`;
