import * as ax from './selectors.ax';
import * as base from './selectors.base';
import * as catalogues from './selectors.catalogues';
import * as collection from './selectors.collection';
import * as documents from './selectors.documents';
import * as download from './selectors.download';
import * as evaluation from './selectors.evaluation';
import * as generation from './selectors.generation';
import * as options from './selectors.options';
import * as playground from './selectors.playground';
import * as raw from './selectors.raw';
import * as sheets from './selectors.sheets';
import * as showroom from './selectors.showroom';
import * as singles from './selectors.singles';
import * as tables from './selectors.tables';

export const selectors = {
  raw,
  ax,
  base,
  catalogues,
  collection,
  documents,
  download,
  evaluation,
  generation,
  tables,
  options,
  playground,
  sheets,
  showroom,
  singles,
};
