import { generate as answer } from './snippets.answer';
import { generate as completion } from './snippets.completion';
import { generate as chat } from './snippets.chat';
import { generate as gec } from './snippets.gec';
import { generate as improvements } from './snippets.improvements';
import { generate as paraphrase } from './snippets.paraphrase';
import { generate as segmentation } from './snippets.segmentation';
import { generate as summarize } from './snippets.summarization';
import { generate as library } from './snippets.library';
import { GenerateMethod, SnippetFlavour } from './snippets.types';

type FlavourVariation = 'complete' & 'textImprovements';

export const all: Record<SnippetFlavour & FlavourVariation, GenerateMethod> = {
  answer,
  chat,
  completion,
  complete: completion,
  gec,
  improvements,
  textImprovements: improvements,
  paraphrase,
  segmentation,
  summarize,
  library,
};
