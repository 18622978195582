import { get } from 'lodash';
import { takeEvery } from 'saga-ts';
import { logEvent } from '../analytics.amplitude';
import {
  ApiEventType,
  IncomingNetworkEvent,
  IncomingNetworkEventWrapped,
} from '../analytics.types';
import { config } from '../utils/globals';
import { customEvenChannel } from './channels/channel.customEvent';
import { request } from 'http';
import { root as completion } from './network/network.completion';
import { root as feedback } from './network/network.feedback';
import { root as lego } from './network/network.lego';
import { rootDefault } from './network/network.default';

const mapApiEventToSaga: Record<ApiEventType, any> = {
  ...completion,
  ...feedback,
  ...lego,
};

export function* listenToNetwork(event: IncomingNetworkEventWrapped) {
  const { network } = config;

  const { data } = event;
  const { endpoint, eventType } = data;

  const endpointConfig = get(network, endpoint, []) as string[];
  const globalConfig = get(network, 'global', {}) as Json;

  let isEnabled = endpointConfig.includes(eventType);

  if (globalConfig['error'] !== 'none' && eventType === 'error') {
    isEnabled = true;
  }

  if (!isEnabled) {
    return;
  }
  const defaultForType = rootDefault[eventType];
  const saga = get(mapApiEventToSaga, [endpoint, eventType], defaultForType);

  if (!saga) {
    console.warn(`No ApiEvent saga found for ${eventType}, endpoint:${endpoint}`);
    return;
  }

  yield* saga(data);
}

export function* root() {
  const channel = customEvenChannel('global/network');
  yield takeEvery(channel, listenToNetwork);
}
