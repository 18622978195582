import { initStore } from '@ai21/studio-store';
import thunkMiddleware from 'redux-thunk';
import { SignInState } from '../auth/signin';
import { root as rootSaga } from '../sagas/saga.index';
import { analyticsMiddleware } from './middleware/analyticsMiddleware';
import { rootReducer } from './reducers';
import { CustomModelsState } from './reducers/customModels';
import { DatasetsState } from './reducers/datasets';
import { OrganizationState } from './reducers/organization';
import { OverviewState } from './reducers/overview';
import { PaymentState } from './reducers/payment';
import { PlaygroundState } from './reducers/playground';
import { PricingState } from './reducers/pricing';
import { UserState } from './reducers/user';

export interface RootState {
  _signin: SignInState;
  _payment: PaymentState;
  _playground: PlaygroundState;
  _user: UserState;
  _datasets: DatasetsState;
  _customModels: CustomModelsState;
  _organization: OrganizationState;
  _pricing: PricingState;
  _overview: OverviewState;
}

export const store = initStore({
  rootSaga,
  previousRootReducer: rootReducer,
  middlewares: [thunkMiddleware, analyticsMiddleware],
});
