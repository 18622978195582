import { AskEditor } from '@ai21/studio-editors';
import { actions, selectors } from '@ai21/studio-store';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export type AskEditorContainerProps = {
  onClose: () => void;
};

export function AskEditorContainer(props: AskEditorContainerProps) {
  const dispatch = useDispatch();
  const questions = useSelector(selectors.documents.$documentQuestions);
  const filesIds = useSelector(
    selectors.raw.$rawCurrentIds
  ).askSpecificDocumentIds;
  const fileName = useSelector(selectors.raw.$rawDocuments)[
    filesIds && filesIds.length === 1 ? filesIds[0] : ''
  ];
  const [filteredQuestions, setFilteredQuestions] = useState(questions);

  useEffect(() => {
    const filteredQuestions = questions.filter((question) => {
      return question.source === 'library';
    });

    setFilteredQuestions(filteredQuestions);
  }, [questions]);

  const callbacks = useMemo(
    () => ({
      onAction: (verb: any, params?: Json) => {
        dispatch({
          type: 'DOCUMENT',
          verb,
          params: { ...params, source: 'library' },
        });
      },
      onPrompt: (params?: Json) => {
        dispatch({
          type: 'DOCUMENT',
          verb: 'ask',
          params: { ...params, source: 'library' },
        });
      },
      askAllLibrary: () => {
        dispatch(
          actions.currentIds.patch({
            askSpecificDocumentIds: '',
          })
        );
        dispatch({ type: 'DOCUMENT', verb: 'clear' });
      },
      onClose: () => {
        props.onClose();

        dispatch(
          actions.currentIds.patch({
            askSpecificDocumentIds: '',
          })
        );
        dispatch({ type: 'DOCUMENT', verb: 'clear' });
      },
    }),
    []
  );

  return (
    <AskEditor
      items={filteredQuestions}
      callbacks={callbacks}
      open={true}
      fileName={fileName ? fileName.name : ''}
    />
  );
}
