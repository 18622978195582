export function EyeIcon() {
  return (
    <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.39033 6.99984C3.50931 10.5562 6.05117 12.2082 9.00008 12.2082C11.949 12.2082 14.4908 10.5562 16.6098 6.99984C14.4908 3.44343 11.949 1.7915 9.00008 1.7915C6.05117 1.7915 3.50931 3.44343 1.39033 6.99984ZM0.124103 6.68974C2.41437 2.68198 5.36674 0.541504 9.00008 0.541504C12.6334 0.541504 15.5858 2.68198 17.8761 6.68974C17.9859 6.88189 17.9859 7.11779 17.8761 7.30994C15.5858 11.3177 12.6334 13.4582 9.00008 13.4582C5.36674 13.4582 2.41437 11.3177 0.124103 7.30994C0.0142963 7.11779 0.0142963 6.88189 0.124103 6.68974ZM9.00008 5.95817C8.42478 5.95817 7.95842 6.42454 7.95842 6.99984C7.95842 7.57513 8.42478 8.0415 9.00008 8.0415C9.57538 8.0415 10.0417 7.57513 10.0417 6.99984C10.0417 6.42454 9.57538 5.95817 9.00008 5.95817ZM6.70842 6.99984C6.70842 5.73418 7.73443 4.70817 9.00008 4.70817C10.2657 4.70817 11.2917 5.73418 11.2917 6.99984C11.2917 8.26549 10.2657 9.2915 9.00008 9.2915C7.73443 9.2915 6.70842 8.26549 6.70842 6.99984Z'
        fill='black'
        fillOpacity='0.6'
      />
    </svg>
  );
}
