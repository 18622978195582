import { DialogActions } from '@mui/material';
import { styled } from '@mui/system';
import { Input } from '../Input/Input';

export const Wrapper = styled('div')`
  flex: 1;
  padding: 0 24px 0 24px;
  padding-bottom: 12px;
  min-height: 50px;
  min-width: 300px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    min-width: 240px;
  }

  &.form {
    padding-bottom: 16px;
  }
`;

export const P = styled('p')`
  font-size: 18px;
  flex: 1;
`;

export const Content = styled('div')`
  padding-bottom: 25px;

  &.form,
  &.custom {
    padding-bottom: 0;
  }
`;

export const Actions = styled(DialogActions)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > button {
    margin: 0 6px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Warning = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e91e63;
  margin: 20px 0 5px;
  padding: 10px;
  border-radius: 5px;
  color: #e91e63;
  width: fit-content;
  line-height: 22px;
`;

export const WarningIcon = styled('div')`
  font-size: 20px;
  margin-right: 8px;
`;

export const WarningText = styled('div')`
  font-size: 16px;
`;

export const PromptInput = styled(Input)`
  textarea {
    min-width: 380px;
  }
`;
