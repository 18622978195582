import { Icon, Tooltip } from '@ai21/studio-base-ui';
import { calcIsEvaluated } from '@ai21/studio-store';
import { useCallback, useContext } from 'react';
import { InboxContext } from '../../InboxEditor.context';
import { AddComment } from '../AddComment/AddComment';
import Comment from '../Comment/Comment';
import { FeedbackEditor } from '../FeedbackEditor/FeedbackEditor';
import { Actions, Cta, Line, Wrapper } from './EditorBottomBar.style';
import EvaluatorInfo from '../EvaluatorInfo/EvaluatorInfo';

export type EditorBottomBarProps = {};

export function EditorBottomBar(_props: EditorBottomBarProps) {
  const { state, callbacks, line, patchState } = useContext(InboxContext);
  const { id, quality, isLocked, isSkipped } = line || {};

  const toggleComment = useCallback(() => {
    const showComment = !state.showComment;
    patchState({ showComment });

    if (!showComment) {
      return;
    }

    setTimeout(() => {
      const ev = document.querySelector('.Comment-wrapper textarea');

      if (ev) {
        (ev as any).focus();
      }
    }, 0);
  }, [state]);

  return (
    <Wrapper className='EditorBottomBar-wrapper' data-testid='EditorBottomBar-wrapper'>
      <FeedbackEditor
        id={id}
        isLocked={isLocked}
        isSkipped={isSkipped}
        quality={quality}
        scoreOptions={state.scoreOptions}
        onEvaluate={(value, lineId, params) => callbacks.onEvaluate(value, lineId, params)}
        onTags={(value, lineId) => callbacks.onTags(value, lineId)}
        onClear={() => callbacks.onAction('clear')}
      />
      {state.showComment && (
        <Comment
          disabled={isLocked}
          line={line}
          onChange={(value, lineId) => callbacks.onComment(value, lineId)}
          onAction={(verb) => {
            if (verb === 'hide') {
              patchState({ showComment: false });
            }
          }}
        />
      )}
      <Line>
        <AddComment comment={line?.comment} onClick={toggleComment} isMac={state.isMac} />
        <EvaluatorInfo line={line} />
        <Actions>
          <Cta
            shortKey={{
              key: 'ArrowLeft',
              withAlt: true,
              preventDefault: true,
            }}
            shortKeyLocation='before'
            variant='outlined'
            color='secondary'
            size='large'
            onClick={() => callbacks.onAction('previous')}
            onShortKey={() => callbacks.onAction('previous', { keyboard: true })}
            data-testid='ContinueEvaluation'
          >
            Prev
          </Cta>
          <Cta
            shortKey={{
              key: 'ArrowRight',
              withAlt: true,
              preventDefault: true,
            }}
            color={calcIsEvaluated(quality) ? 'primary' : 'secondary'}
            variant='outlined'
            size='large'
            onClick={() => callbacks.onAction('next')}
            onShortKey={() => callbacks.onAction('next', { keyboard: true })}
            data-testid='ContinueEvaluation'
          >
            Next
          </Cta>
        </Actions>
      </Line>
    </Wrapper>
  );
}

export default EditorBottomBar;
