import { fork } from 'saga-ts';
import { root as api } from './saga.api';
import { root as documents } from './saga.documents';
import { root as mockAnswer } from './saga.mockAnswer';
import { root as library } from './saga.library';

export function* root() {
  yield* fork(api);
  yield* fork(documents);
  yield* fork(mockAnswer);
  yield* fork(library);
}
