import { Grid, Typography } from '@ai21/studio-ui';
import { GENERAL_EXCEPTION_ERROR_MESSAGE } from '../../constants/constants';

export function ModelsAndDatasetsGeneralErrorStatePage(props: any) {
  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Typography variant='h4'>{GENERAL_EXCEPTION_ERROR_MESSAGE}</Typography>
    </Grid>
  );
}
