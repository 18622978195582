export const statusMap: any = {
  ACTIVE: {
    icon: 'overview.StatusActive',
    text: 'Trained',
    entities: ['models'],
  },
  IN_TRAINING: {
    icon: 'overview.StatusTraining',
    text: 'In training',
    isSpinning: true,
    entities: ['models'],
  },
  PENDING: {
    icon: 'overview.StatusTraining',
    text: 'Pending',
    isSpinning: true,
    entities: ['models'],
  },
  DISCONTINUED: {
    icon: 'overview.StatusFailed',
    text: 'Training Failed',
    entities: ['models'],
  },
  Incomplete: {
    icon: 'overview.StatusFailed',
    text: 'Incomplete',
    color: 'yellow',
    entities: ['batch'],
  },
  Completed: {
    text: 'Done',
    color: 'green',
    icon: 'overview.StatusActive',
    entities: ['evaluation', 'batch'],
  },
  PROCESSED: {
    text: 'Ready',
    color: 'green',
    icon: 'overview.StatusActive',
    entities: ['library'],
  },
  PROCESSING_FAILED: {
    text: 'Indexing failed',
    color: 'yellow',
    icon: 'overview.StatusFailed',
    entities: ['library'],
  },
  Evaluating: {
    text: 'Ongoing',
    color: 'blue',
    icon: 'Refresh',
    entities: ['evaluation'],
  },
  New: {
    text: 'New',
    color: 'purple',
    entities: ['batch', 'evaluation'],
  },
  UPLOADED: {
    text: 'Parsing',
    icon: 'overview.StatusTraining',
    isSpinning: true,
    color: 'blue',
    entities: ['library'],
  },
  PROCESSING: {
    text: 'Processing',
    icon: 'overview.StatusTraining',
    isSpinning: true,
    color: 'blue',
    entities: ['library'],
  },
};
