import { fireRequest } from './axios';
import { PayloadCreatePreset } from './types';
import { customTransformer, idTransformer } from './utils/transformers';

export const getAll = () => {
  return fireRequest<Json>({
    path: '/preset',
    endpoint: 'presets.getAll',
    transformer: customTransformer({
      'params.model': 'params.modelId',
    }),
  });
};

export const create = (payload: PayloadCreatePreset) => {
  payload.params.model = payload.params.modelId;
  delete payload.params.modelId;

  return fireRequest<Json>({
    path: '/preset',
    method: 'post',
    data: payload,
    endpoint: 'presets.create',
    transformer: customTransformer({
      'params.model': 'params.modelId',
    }),
  });
};

export const remove = (id: string) => {
  return fireRequest<Json>({
    method: 'delete',
    path: '/preset',
    data: {
      id,
      apiType: 'completion',
    },
    endpoint: 'presets.remove',
  });
};

export const presets = {
  getAll,
  create,
  remove,
};
