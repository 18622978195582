import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  height: 200px;
  background: repeating-linear-gradient(
    -45deg,
    #00000011,
    #00000011 10px,
    #00000022 10px,
    #00000022 20px
  );
  line-height: 200px;
  text-align: center;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.2);
`;
