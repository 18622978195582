import { fireRequest } from './axios';

export const get = () => {
  return fireRequest<Json>({
    path: '/usage',
    endpoint: 'usage.get',
  });
};

export const getLego = () => {
  return fireRequest<Json>({
    path: '/usage/task-specific',
    endpoint: 'usage.getLego',
  });
};

export const getCredits = () => {
  return fireRequest<Json>({
    path: '/usage/credit',
    endpoint: 'usage.getCredits',
  });
};

export const getTraining = () => {
  return fireRequest<Json>({
    path: '/usage/custom-models-training',
    endpoint: 'usage.getTraining',
  });
};

export const usage = {
  get,
  getLego,
  getCredits,
  getTraining,
};
