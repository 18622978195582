const APP_STATE_KEY = 'APP_STATE_KEY';

export const getString = (key: string): string => {
  return localStorage.getItem(key) ?? '';
};

export const setString = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const clearString = (key: string) => {
  localStorage.removeItem(key);
};

export const getJson = (key: string): Json | null => {
  const raw = getString(key);

  try {
    return JSON.parse(raw);
  } catch (e) {
    return null;
  }
};

export const setJson = (key: string, json: Json) => {
  setString(key, JSON.stringify(json));
};

export const clearJson = (key: string) => {
  clearString(key);
};

export const patchJson = (key: string, patch: Json) => {
  const json = getJson(key) ?? {};
  setJson(key, { ...json, ...patch });
};

export const getAppState = () => {
  return getJson(APP_STATE_KEY);
};

export const setAppState = (appState: Json) => {
  return setJson(APP_STATE_KEY, appState);
};

export const getBoolean = (key: string): boolean => {
  return localStorage.getItem(key) === 'true';
};

export const setBoolean = (key: string, value: boolean) => {
  setString(key, value ? 'true' : 'false');
};
