import { Box, Button, Grid, Link, Typography, icons } from '@ai21/studio-ui';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TRAINING_DATA_LINK } from '../../constants/constants';
import { MESSAGE_DISMISSED, MESSAGE_SHOWN } from '../../redux/actionTypes/userActionTypes';
import { postDataset } from '../../redux/actions/actions.post';
import { getApiKey, getDatasetFile, getSelectedColumns } from '../../redux/selectors';

export function DatasetValidationImproveData({
  datasetName,
  fixWhitespaces,
}: {
  datasetName: string | undefined;
  fixWhitespaces: CallableFunction;
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: MESSAGE_SHOWN,
      payload: {
        origin: 'datasetUploadError',
        type: 'error',
        text: 'It is recommended to end prompts with a newline or start completions with a whitespace.',
        name: 'MULTIPLE_WHITESPACE_ERROR',
      },
    });
  }, []);

  return (
    <Grid container alignItems='center' justifyContent='center' direction='column'>
      <icons.UploadingDataset />
      <Box marginTop={2} />
      <Typography variant='subtitle1'>
        {'Dataset '}
        {datasetName}
      </Typography>
      <Box marginTop={3} />
      <Grid container alignItems='center' justifyContent='center'>
        <Box
          sx={{
            width: 520,
            height: 52,
            bgcolor: '#ffede1',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
            boxShadow:
              '0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 1px 0 rgba(0, 0, 0, 0.08), 0 2px 1px -1px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box style={{ marginTop: '15px' }} />
          <Grid container alignItems='center' justifyContent='center'>
            <icons.Models style={{ marginRight: '4px', fill: 'inherit' }} />
            <Box style={{ marginRight: '8px' }} />
            <Typography color='textPrimary' variant='body1'>
              Your data might not comply with best practices.
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Box marginTop={3} />
      <Typography variant='body1'>
        It is recommended to end prompts with a newline or start completions with a <br />
        whitespace. It’s best to pick one of the options and apply it consistently to all <br />
        examples. &nbsp;&nbsp;
        <Link
          style={{ cursor: 'pointer' }} // moved the custom color to be primary color outside the style - Yuval
          data-testid='link-learn-more'
          color='primary'
          variant='inherit'
          underline='none'
          target='_blank'
          href={TRAINING_DATA_LINK}
        >
          Learn more.
        </Link>{' '}
        <br />
        <br />
        Don’t worry! We can fix this for you. If you click “fix & continue”, we’ll edit the file{' '}
        <br />
        to end all prompts with a newline and remove leading whitespaces from all <br />
        completions.
        <br />
        <br />
        If you’re sure you’d like to keep the file as-is, click “skip”. Be advised the quality{' '}
        <br />
        of the trained model may be negatively affected in this case. <br />
        <br />
      </Typography>
      <Box style={{ marginTop: '26px' }} />
      <Grid container justifyContent='center'>
        <Button
          variant='outlined'
          color='secondary'
          size='small'
          onClick={() => {
            dispatch({
              type: MESSAGE_DISMISSED,
              payload: {
                origin: 'datasetUploadError',
                type: 'error',
                reason: 'skipPressed',
                text: 'It is recommended to end prompts with a newline or start completions with a whitespace.',
                name: 'MULTIPLE_WHITESPACE_ERROR',
              },
            });
            fixWhitespaces(false);
          }}
        >
          Skip
        </Button>
        <Box style={{ marginLeft: '16px' }} />
        <Button
          variant='outlined'
          color='primary' // updated the styles of the colors and buttons on this area - Yuval
          data-testid='approve-whitespace-fix-btn'
          onClick={() => {
            dispatch({
              type: MESSAGE_DISMISSED,
              payload: {
                origin: 'datasetUploadError',
                type: 'error',
                reason: 'fixPressed',
                text: 'It is recommended to end prompts with a newline or start completions with a whitespace.',
                name: 'MULTIPLE_WHITESPACE_ERROR',
              },
            });
            fixWhitespaces(true);
          }}
        >
          Fix & Continue
        </Button>
      </Grid>
      <Box style={{ marginTop: '68px' }} />
    </Grid>
  );
}
