export function GreenCircles() {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_15_2657)'>
        <rect width='48' height='48' rx='2' fill='#09D6A5' />
        <g filter='url(#filter0_ddd_15_2657)'>
          <rect x='-6' y='16' width='39' height='39' rx='19.5' fill='#FFF9EC' />
        </g>
        <g filter='url(#filter1_ddd_15_2657)'>
          <rect x='9' y='7' width='18' height='18' rx='9' fill='#9DEFDB' />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_ddd_15_2657'
          x='-32'
          y='-7.4'
          width='91'
          height='91'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2.6' />
          <feGaussianBlur stdDeviation='13' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_15_2657' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='10.4' />
          <feGaussianBlur stdDeviation='6.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_15_2657'
            result='effect2_dropShadow_15_2657'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='2.6'
            operator='erode'
            in='SourceAlpha'
            result='effect3_dropShadow_15_2657'
          />
          <feOffset dy='5.2' />
          <feGaussianBlur stdDeviation='5.2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend
            mode='normal'
            in2='effect2_dropShadow_15_2657'
            result='effect3_dropShadow_15_2657'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect3_dropShadow_15_2657'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_ddd_15_2657'
          x='-1'
          y='-2'
          width='38'
          height='38'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_15_2657' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_15_2657'
            result='effect2_dropShadow_15_2657'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect3_dropShadow_15_2657'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend
            mode='normal'
            in2='effect2_dropShadow_15_2657'
            result='effect3_dropShadow_15_2657'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect3_dropShadow_15_2657'
            result='shape'
          />
        </filter>
        <clipPath id='clip0_15_2657'>
          <rect width='48' height='48' rx='2' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
