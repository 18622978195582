import styled from 'styled-components';
import { Select as SelectMui } from '@mui/material';

export const Wrapper = styled.div`
  flex: 1;
`;

export const Select = styled(SelectMui)<{ height?: number }>`
  height: ${(props) => (props.height ? props.height + 'px' : 'auto')};
`;
