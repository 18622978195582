import { actions } from '@ai21/studio-store';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { AppRoutes } from '../bootstrap/App.routes';
import DemosFramePageContainer from '../pages/demosFramePage/DemosFramePage.container';
import DemosNoApiKeyPage from '../pages/demosNoApiKey/DemosNoApiKeyPage';
import DemosNotFoundPage from '../pages/demosNotFound/DemosNotFoundPage';
import { store } from '../utils/globals';

export function DemosRootContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.appState.patch({ isIntercomVisible: false }));
    document.body.classList.add('slim');

    return () => {
      dispatch(actions.appState.patch({ isIntercomVisible: true }));
      document.body.classList.remove('slim');
    };

    console.log('store ->', store);
  }, []);

  return (
    <Routes>
      <Route path={AppRoutes.DEMO_NOT_FOUND} Component={DemosNotFoundPage} />
      <Route path={AppRoutes.DEMO_FRAME} Component={DemosFramePageContainer} />
      <Route path={AppRoutes.DEMO_NO_API_KEY} Component={DemosNoApiKeyPage} />
    </Routes>
  );
}
