import { api } from '@ai21/studio-api';
import { toast } from '@ai21/studio-base-ui';
import { IBox, selectors } from '@ai21/studio-store';
import { get } from 'lodash';
import { call, delay, select } from 'saga-ts';
import { improvePrompt } from '../utils/prompt';
import { patchBox } from './helpers/boxes';
import { prepareRequestParams } from './helpers/completion';
import { ActionPlay } from './saga.play';

export function* optimizePrompt(action: ActionPlay, box: IBox) {
  const inputBoxes = yield* select(selectors.playground.$inputBoxes);

  const prompt = get(inputBoxes, '[0].values.prompt', '');

  if (!prompt) {
    toast.show('Please enter a prompt to optimize', 'warning');
    return;
  }

  yield* call(patchBox, box, { showApe: true });

  const params = {
    modelId: 'j2-ultra',
    temperature: 0.7,
    topP: 1,
    maxTokens: 80,
  };

  const promptWithInstructions = improvePrompt(prompt);

  const parsedParams = prepareRequestParams(promptWithInstructions, params);

  const response = yield* call(api.completion.complete, parsedParams);

  const completion = get(response, 'data.completions[0].data.text', '').replace('Suggested Prompt:', '').replace(/\n/g, ''); // prettier-ignore

  yield delay(1000);

  yield* call(patchBox, box, {
    prompt: completion,
    showApe: false,
    promptBackup: prompt,
  });
}

export function* optimizePromptUndo(_action: ActionPlay, box: IBox) {
  const promptBackup = get(box, 'values.promptBackup', '');
  yield* call(patchBox, box, {
    prompt: promptBackup,
    promptBackup: '',
  });
}
