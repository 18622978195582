import { put, takeEvery } from 'saga-ts';
import { navigate } from './saga.navigation';
import { WebRoutes } from '../bootstrap/Web.routes';

type ActionLoadExample = {
  type: 'LOAD_EXAMPLE';
  exampleId: string;
};

export function* loadExample(action: ActionLoadExample) {
  const { exampleId } = action;
  navigate(WebRoutes.playground);
  yield put({ type: 'PRESET', verb: 'playground', id: exampleId });
}

export function* root() {
  yield takeEvery('LOAD_EXAMPLE', loadExample);
}
