import { Button, Chip, Stack, Typography, createStyleHook } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

interface CatalogueCardStyleProps {
  isDeprecated: boolean;
  isBeta?: boolean;
  isBetaEnabled?: boolean;
  imageUrl?: string;
}

export const useCatalogueCardStyles = createStyleHook((theme, props: CatalogueCardStyleProps) => {
  return {
    root: {
      boxSizing: 'border-box',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      border: '1px solid',
      borderColor: tokens.secondary.borderDefault,
      borderRadius: '12px',
      padding: '16px',
      minWidth: '40%',
      flex: 1,
      cursor: 'pointer',
      position: 'relative',
      transition: 'all 150ms ease-in-out',
      backgroundColor:
        props.isDeprecated || (props.isBeta && !props.isBetaEnabled)
          ? tokens.disabled.background
          : tokens.primary.backgroundDefault,

      '&:hover': {
        border: `1px solid ${tokens.secondary.borderHover}`,
        background: props.isDeprecated ? tokens.disabled.background : tokens.primitives.grey[0],
        borderColor: tokens.secondary.borderHover,
        boxShadow:
          '0px 1px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.06)',
        '&.outlink': {
          display: 'block',
        },
        '&:active': {
          background: tokens.primitives.grey[10],
          borderColor: tokens.secondary.borderActive,
          boxShadow: 'none',
          userSelect: 'none',
        },
      },
    },
    image: {
      height: '48px',
      maxHeight: '48px',
      maxWidth: '48px',
      width: '48px',
      marginBottom: '12px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundImage: props.imageUrl ? `url(${props.imageUrl})` : '',
    },
    iconWrapper: {
      paddingRight: '16px',
      position: 'relative',
    },
    disabledIconOverlay: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: tokens.disabled.background,
      opacity: '80%',
    },
    detailsContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    titleText: {
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: '8px',
      color: props.isDeprecated ? tokens.text.placeholder : tokens.text.primary,
    },
    betaBadge: {
      background: tokens.neutral.almostBlack,
      borderRadius: '2px',
      padding: '2px 4px',
      textTransform: 'uppercase',
      color: tokens.text.contrast,
      fontSize: '10px',
      fontWeight: 700,
      marginLeft: '6px',
    },
    newBadge: {
      background: tokens.primitives.blue[50],
      borderRadius: '2px',
      padding: '2px 4px',
      textTransform: 'capitalize',
      color: tokens.primitives.blue[900],
      fontSize: '14px',
      fontWeight: 500,
      marginLeft: '6px',
      display: 'flex',
      alignItems: 'center',

      svg: {
        width: '16px',
        height: '16px',
        marginRight: '4px',
      },
    },
    ai21Badge: {
      position: 'absolute',
      fontSize: '10px',
      color: 'white',
      padding: '2px 4px',
      borderRadius: '5px',
      top: '8px',
      right: '8px',
      zIndex: 9999 /* Set a high z-index value to ensure it appears above everything */,
      backgroundColor: '#e91e63',
    },
    descriptionText: {
      color: props.isDeprecated ? tokens.text.placeholder : tokens.text.secondary,
      flex: 1,
      marginRight: '50px',
    },
    sloganText: {
      marginTop: '16px',
      marginBottom: '12px',
      color: tokens.text.tertiary,
    },
    tag: {
      cursor: 'pointer',
      height: '26px',
      background: tokens.text.contrast,
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    button: {
      backgroundColor: props.isDeprecated ? tokens.disabled.background : '',
    },
  };
});

export const Image = styled.div`
  height: 48px;
  max-height: 48px;
  max-width: 48px;
  width: 48px;

  margin-bottom: 12px;
  background-size: contain;
  background-repeat: no-repeat;
`;
