import {
  createExperimentClient,
  experimentConfig,
  type VariantsClient,
} from '@ai21/studio-experiment';

let featureFlags: VariantsClient;

export const initFeatureFlags = async () => {
  const experimentClient = createExperimentClient(experimentConfig);
  featureFlags = await experimentClient.fetchVariants();
};

export const getFeatureFlag = (name: string): boolean => {
  if (!featureFlags) {
    throw new Error('Feature flags not initialized');
  }

  return featureFlags.get(name)?.value === 'on';
};

export const shouldUseCookieAuth = () => getFeatureFlag('enable-cookie-based-auth');
