import { apiUrl } from '../globals';
import { sanitizeCode } from '../utils/snippets';
import {
  getImprovementsCurlCodeSnippet,
  getImprovementsJsCodeSnippet,
  getImprovementsPythonCodeSnippet,
} from './codeBlocks';
import { GenerateSnippetsParams } from './snippets.types';

const url = (apiHost: string) => `${apiHost}/improvements`;

export const generate = (params: GenerateSnippetsParams) => {
  return {
    python: python(params),
    javascript: javascript(params),
    curl: curl(params),
  };
};

const python = (params: GenerateSnippetsParams) => {
  const { main, apiParams } = params as any;
  return getImprovementsPythonCodeSnippet(
    url(apiUrl),
    sanitizeCode(main),
    apiParams?.improvementTypes
  );
};

const javascript = (params: GenerateSnippetsParams) => {
  const { main, apiParams } = params as any;
  return getImprovementsJsCodeSnippet(url(apiUrl), sanitizeCode(main), apiParams?.improvementTypes);
};

const curl = (params: GenerateSnippetsParams) => {
  const { main, apiParams } = params as any;

  return getImprovementsCurlCodeSnippet(
    url(apiUrl),
    sanitizeCode(main),
    apiParams?.improvementTypes
  );
};
