import { TableContainer, Table, TableBody } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';

export const Wrapper = styled(TableContainer)`
  border: 1px solid #dadde1; //Table outline border
  border-radius: 8px;
  margin-bottom: 20px;

  &.MuiTableContainer-root {
    width: auto;
  }

  &.hideFooter {
    .TableBottom-wrapper {
      display: none;
    }
  }

  .MuiDataGrid-overlay {
    display: none;
  }

  .MuiDataGrid-virtualScroller {
    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background: #e6eaec;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #ccd0d5;
    }
  }

  &.selectionMode {
    .TableRow-wrapper {
      cursor: pointer;
    }
  }

  .MuiDataGrid-cell {
    padding: 16px;

    &:focus {
      outline: solid transparent 1px;
    }
  }
  .MuiDataGrid-iconButtonContainer {
  }
  .MuiDataGrid-columnHeaderCheckbox {
    .MuiDataGrid-columnHeaderTitleContainerContent {
      overflow: visible;
    }
  }

  .MuiDataGrid-columnHeader {
    padding: 16px;
    &:focus {
      outline: none;
    }
  }

  .MuiDataGrid-columnHeaders {
    border-bottom: 1px solid #dadde1; //Table Header Divider

    &:focus-within {
      outline: 1px solid transparent;
    }
  }

  .MuiDataGrid-pinnedColumnHeaders {
    padding-right: 0 !important;
  }

  .MuiDataGrid-row {
    > div {
      border-bottom: 1px solid #dadde1; //Table Row Divider
    }
  }
`;

export const TableSection = styled('div')`
  padding: 52px 0px 22px;

  &.noPadding {
    padding: 0;
  }

  &.empty {
    .MuiDataGrid-footerContainer,
    .MuiDataGrid-virtualScroller {
      display: none;
    }

    .MuiDataGrid-columnHeaders {
      border-bottom: none;
    }

    .MuiTableContainer-root {
      border-radius: 8px 8px 0 0;
    }
  }
`;
