import { TextField } from '@ai21/studio-base-ui';
import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div<{ paddingLeft?: number }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 6px;
  padding: 0px 8px 8px 20px;
  border: 1px solid transparent;
  border-radius: 12px;

  .top {
    height: 50px;
  }

  .Copy-wrapper {
    display: none;
  }

  & .IOEditor-textarea {
    flex: 1;
    height: 100% !important;
  }

  .action-hide {
    display: none;
  }

  &.border {
  }

  &.show-hide {
    .action-copy {
      display: none;
    }

    .action-hide {
      display: flex;
    }
  }

  &:hover,
  &:focus-within {
    border: 1px solid ${tokens.primary.borderDefault};
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
`;

export const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #1d1e22;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    stroke-width: 1.5px;
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
`;

export const Count = styled.div`
  font-size: 12px;
  color: ${tokens.text.tertiary};
  margin-left: 10px;
`;

export const Gap = styled.div`
  flex: 1;
`;

export const Actions = styled.div`
  svg {
    stroke-width: 1.5px;
    color: ${tokens.text.secondary};
    width: 20px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;

  > div {
    flex: 1;
    display: flex;

    > div {
      flex: 1;
      display: flex;
    }
  }
`;

export const TextArea = styled(TextField)`
  fieldset {
    border: none;
  }

  textarea {
    font-size: 14px;
    line-height: 1.4;
    padding: 0;
    outline: none;
    overflow: auto !important;
  }
`;
