import { useEffect, useMemo, useState } from 'react';
import { dracula } from 'react-code-blocks';
import { Icon } from '../Icon/Icon';
import { ProgrammingLanguage, Snippet } from './Code.data';
import { Languages } from './Code.parts';
import { Block, Cta, Top, Wrapper, blockStyles } from './Code.style';
import { invokeEvent } from 'shared-base';
import { useLocalStorage } from 'react-use';

export type CodeProps = {
  snippet: Snippet;
  onCta: () => void;
};

export function Code(props: CodeProps) {
  const { snippet } = props;
  const [language, setLanguage] = useLocalStorage<ProgrammingLanguage>(
    'SAVED_CODE_SNIPPET_LANGUAGE',
    'python'
  );

  const [copied, setCopied] = useState(false);

  const text = useMemo(() => {
    return (snippet ?? {})[language ?? 'python'] ?? '';
  }, [snippet, language]);

  function onCopy() {
    invokeEvent('global/dialog', {
      type: 'code',
      action: 'copyCode',
      actionValue: language,
      title: 'API call',
    });

    setCopied(true);

    navigator.clipboard.writeText(text).catch(() => {});
  }

  function onLanguageChange(value: ProgrammingLanguage) {
    setLanguage(value);
    navigator.clipboard.writeText(text).catch(() => {});

    invokeEvent('global/dialog', {
      type: 'code',
      action: 'changeLanguage',
      actionValue: value,
      title: 'API call',
    });
  }

  useEffect(() => {
    setCopied(false);
  }, [language]);

  function renderCta() {
    const text = copied ? 'Copied' : 'Copy';
    const iconName = copied ? 'Check' : 'Copy';

    return (
      <Cta
        endIcon={<Icon iconName={iconName} />}
        color='secondary'
        variant='contained'
        size='medium'
        onClick={onCopy}
      >
        {text}
      </Cta>
    );
  }

  return (
    <Wrapper className='Code-wrapper' data-testid='Code-wrapper'>
      <Top>
        <Languages language={language} onChange={onLanguageChange} />
        {renderCta()}
      </Top>
      <Block
        text={text}
        language={language === 'curl' ? 'shell' : language}
        showLineNumbers={false}
        theme={dracula}
        customStyle={blockStyles}
      />
    </Wrapper>
  );
}

export default Code;
