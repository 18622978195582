import { IApp } from '@ai21/studio-store';
import classnames from 'classnames';
import { Subtitle, Title, Wrapper } from './AppHeader.style';

export type AppHeaderProps = {
  app: IApp;
  onBack: () => void;
  onAction: (action: Json) => void;
};

export function AppHeader(props: AppHeaderProps) {
  const { app } = props;
  const { title, subtitle } = app;
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const mobileClassName = classnames({ mobile: isMobile });

  return (
    <Wrapper
      className={`AppHeader-wrapper ${mobileClassName}`}
      data-testid='AppHeader-wrapper'
    >
      <Title className={`AppHeader-title ${mobileClassName}`} variant='h3bold'>
        {title}
      </Title>
      <Subtitle
        className={`AppHeader-subtitle  ${mobileClassName}`}
        variant='h6'
      >
        {subtitle}
      </Subtitle>
    </Wrapper>
  );
}

export default AppHeader;
