import type { TypographyProps as Props } from '@mui/material';
import React, { ForwardedRef } from 'react';
import type { TypographyVariant } from '../../theme/theme.typography';
import { Wrapper } from './Typography.style';

export type TypographyProps = Props & {
  variant?: TypographyVariant;
};

export const Typography = React.forwardRef(
  (props: TypographyProps, ref: ForwardedRef<any>) => {
    return <Wrapper ref={ref} {...props} />;
  }
);

export default Typography;
