import { ax } from '@ai21/studio-analytics';
import { selectors } from '@ai21/studio-store';
import { call, select, takeEvery } from 'saga-ts';
import { analytics } from './helpers/analytics';
import { predicateJobStatusChange } from './predicates';

export function* onStatusChange(action: any) {
  const { id, payload } = action;
  const { status } = payload;

  const lines = yield* select(selectors.evaluation.$evaluationLines);

  const linesCount = lines.length;

  yield* call(analytics, {
    action: 'jobStatus',
    actionValue: status,
    eventId: 'CollectionJobStatusChanged',
    extra: {
      isManual: status !== 'Evaluating',
      linesCount,
    },
  });

  if (!id) {
    return;
  }

  ax.set(`collectionSetsProgress.${id}`, { status });
}

export function* root() {
  yield takeEvery(predicateJobStatusChange(), onStatusChange);
}
