interface PricingIconProps {
  style: React.CSSProperties;
}

export function PricingIcon({ style }: PricingIconProps) {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='m15.7 4.933.01 4.435-6.84 6.87-4.446-4.425 6.841-6.87 4.435-.01zm-5.269-.996a1 1 0 0 1 .707-.294l4.86-.01a1 1 0 0 1 1.001.997l.011 4.86a1 1 0 0 1-.291.707l-7.14 7.172a1 1 0 0 1-1.414.003l-4.87-4.85a1 1 0 0 1-.004-1.413l7.14-7.172zm.962 3.286a1.5 1.5 0 1 0 2.126 2.116 1.5 1.5 0 0 0-2.126-2.116z'
      />
    </svg>
  );
}
