export function AccountIconSmall() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.90448 12.2993C4.45887 11.4207 6.21454 10.9603 8.00004 10.963C9.85189 10.963 11.5904 11.4482 13.0956 12.2993M10.2223 6.51856C10.2223 7.10793 9.98814 7.67316 9.57139 8.08991C9.15464 8.50666 8.58941 8.74078 8.00004 8.74078C7.41067 8.74078 6.84544 8.50666 6.42869 8.08991C6.01194 7.67316 5.77782 7.10793 5.77782 6.51856C5.77782 5.92919 6.01194 5.36396 6.42869 4.94721C6.84544 4.53046 7.41067 4.29634 8.00004 4.29634C8.58941 4.29634 9.15464 4.53046 9.57139 4.94721C9.98814 5.36396 10.2223 5.92919 10.2223 6.51856ZM14.6667 8.00004C14.6667 8.87552 14.4943 9.74243 14.1592 10.5513C13.8242 11.3601 13.3331 12.095 12.7141 12.7141C12.095 13.3331 11.3601 13.8242 10.5513 14.1592C9.74243 14.4943 8.87552 14.6667 8.00004 14.6667C7.12456 14.6667 6.25765 14.4943 5.44882 14.1592C4.63998 13.8242 3.90505 13.3331 3.286 12.7141C2.66694 12.095 2.17588 11.3601 1.84084 10.5513C1.50581 9.74243 1.33337 8.87552 1.33337 8.00004C1.33337 6.23193 2.03575 4.53624 3.286 3.286C4.53624 2.03575 6.23193 1.33337 8.00004 1.33337C9.76815 1.33337 11.4638 2.03575 12.7141 3.286C13.9643 4.53624 14.6667 6.23193 14.6667 8.00004Z'
        stroke='#676465'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
