import { ListItemIcon, Menu, MenuItem, Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled(Menu)``;

export const Item = styled(MenuItem)`
  height: 36px;
  padding: 10px;
  margin: 1px 8px;
  border-radius: 8px;

  &:hover {
    background: ${tokens.primary.backgroundHover};
  }
`;

export const Text = styled(Typography)``;

export const UserEmail = styled(MenuItem)`
  opacity: 1 !important;
  color: ${tokens.text.secondary};

  svg {
    color: ${tokens.text.secondary} !important;
  }
`;

export const ItemIcon = styled(ListItemIcon)`
  min-width: 30px !important;

  svg {
    color: ${tokens.primitives.grey[1000]};
  }
`;
