import { createSelector } from 'reselect';
import { IStudioStore } from '../types';
import * as raw from './selectors.raw';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $tags = createSelector(raw.$rawApps, (apps) => {
  const tagsObject = { model: [], industry: [], metric: [] };
  Object.values(apps).map((app) => {
    const { tags = { model: [], industry: [], metric: [] } } = app;
    Object.entries(tags).forEach(([key, values]) => {
      values.forEach((tag) => {
        if (!tagsObject[key].includes(tag)) {
          tagsObject[key].push(tag);
        }
      });
    });
  });

  return tagsObject;
});

export const $currentApp = createSelector(raw.$rawApps, raw.$rawCurrentIds, (apps, currentIds) => {
  const { appId = '' } = currentIds;

  return apps[appId];
});

export const $similarUseCases = createSelector($currentApp, raw.$rawApps, (currentApp, apps) => {
  if (!currentApp) {
    return [];
  }

  const { tags: currentAppTags = { model: [], industry: [], metric: [] } } = currentApp;

  return Object.values(apps).filter((app) => {
    const { tags = { model: [], industry: [], metric: [] } } = app;
    const modelTagsMatch = tags.model.some((tag) => currentAppTags.model.includes(tag));
    const industryTagsMatch = tags.industry.some((tag) => currentAppTags.industry.includes(tag));
    const metricTagsMatch = tags.metric.some((tag) => currentAppTags.metric.includes(tag));
    return modelTagsMatch && industryTagsMatch && metricTagsMatch;
  });
});
