import { api } from '@ai21/studio-api';
import { actions, selectors } from '@ai21/studio-store';
import { get } from 'lodash';
import { call, fork, put, select, take } from 'saga-ts';
import { getBoolean } from 'shared-base';

export function* bootstrapHub() {
  try {
    const responseOn = yield* call(api.completion.hubOn);
    const isRunning = get(responseOn, 'data.isRunning', false);

    if (!isRunning) {
      return;
    }

    const responseOptions = yield* call(api.completion.hubOptions);
    const appState = yield* select(selectors.raw.$rawAppState);
    const currentOptions = appState.playModels;
    const options = get(responseOptions, 'data.options', []);

    yield put(
      actions.appState.patch({
        playModels: [...currentOptions, ...options],
      })
    );
  } catch (err) {
    console.log('err ->', err);
  }
}

export function* root() {
  yield* take('AUTHENTICATION_COMPLETED');

  if (!getBoolean('app_hub_enabled')) {
    return;
  }

  yield fork(bootstrapHub);
}
