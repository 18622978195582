import { Box, Button, Dialog, DialogActions, Grid, Typography, icons } from '@ai21/studio-ui';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_DISMISSED, MODAL_SHOWN } from '../../redux/actionTypes/userActionTypes';
import DatasetUploadStyles from './DatasetUploadStyles';

interface UploadDatasetDialogUploadingStateProps {
  onClose: () => void;
}

export function UploadDatasetDialogUploadingState({
  onClose,
}: UploadDatasetDialogUploadingStateProps) {
  const classes = DatasetUploadStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: MODAL_SHOWN,
      payload: { origin: 'dataset', text: 'Upload dataset file', name: 'datasetUploadingFile' },
    });

    return () => {
      dispatch({
        type: MODAL_DISMISSED,
        payload: { origin: 'dataset', reason: 'next step', name: 'datasetUploadingFile' },
      });
    };
  }, []);

  return (
    <div className={classes.dialogContainer} data-testid='dataset-uploading-state-dialog'>
      <Grid container>
        <Typography className={classes.title} variant='h5'>
          Upload dataset file
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ height: 230 }}
      >
        <Box marginTop='16px' />
        <icons.UploadFileAnimation />
        <Typography variant='h5' style={{ marginTop: 20, marginBottom: 20, fontWeight: 400 }}>
          Uploading your dataset!
        </Typography>
      </Grid>
      <DialogActions sx={{ marginBottom: 1 }}>
        <Button
          data-testid='dataset-cancel-dialog-btn'
          variant='outlined'
          size='medium'
          color='secondary'
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
}
