import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// DO NOT move env import - should always come before App
import "./env.ts";
import { WebContainer } from './bootstrap/Web.container';
import { ThirdPartiesProvider } from './bootstrap/thirdParties';
import { init as initCore } from './main.init';
import './main.scss';
import { init as initTest } from './main.test';
import { store } from './redux/store';
import { savePreviewHash } from './utils/preview';

if (window.__env__.NODE_ENV === 'test') {
  initTest();
} else {
  initCore();
}

const isTest = window.__env__.NODE_ENV === 'test';
const intercomEnabled = !isTest;
const stripeEnabled = !isTest;

savePreviewHash();

ReactDOM.render(
  <Provider store={store}>
    <ThirdPartiesProvider intercomEnabled={intercomEnabled} stripeEnabled={stripeEnabled}>
      <WebContainer />
    </ThirdPartiesProvider>
  </Provider>,
  document.getElementById('root')
);
