import type { IRouteMap } from '@ai21/studio-ui';

export enum AppRoutes {
  GENERATION_SETS = '/tools/generation-sets',
  GENERATION_LINES = '/tools/generation-sets/:id/lines',
}

export const publicRoutes: IRouteMap = {};

export const privateRoutes: IRouteMap = {
  [AppRoutes.GENERATION_SETS]: 'generationSets',
  [AppRoutes.GENERATION_LINES]: 'generationLines',
};
