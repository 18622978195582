import { Typography } from '@ai21/studio-base-ui';
import { styled, tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  border-top: 1px solid transparent;

  &:hover,
  &:active {
    border-color: ${tokens.component.dividerDefault};

    & .PromptBottomActions-wrapper {
      border-top-color: ${tokens.component.dividerDefault};
    }
  }
`;

export const Output = styled.div`
  flex: 1;

  & > div {
    border-color: transparent;
  }

  & .linesOutput,
  & .basicOutput {
    & h6 {
      font-size: 14px;

      & div {
        &:hover {
          background: ${tokens.primary.backgroundHover};
        }
      }
    }
  }
`;

export const AlertWrapper = styled.div`
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
`;
