export enum CodeSnippetModalContext {
  PLAYGROUND = 'playground',
  MODELS = 'models',
  OVERVIEW = 'overview',
}

export interface Penalty {
  scale: number;
  applyToWhitespaces: boolean;
  applyToPunctuations: boolean;
  applyToNumbers: boolean;
  applyToStopwords: boolean;
  applyToEmojis: boolean;
}

export interface ControllerParams {
  maxTokens: number;
  epoch: number;
  temperature: number;
  topP: number;
  stopSequences: string[];
  model: string;
  showAlternativeTokens: boolean;
  prompt: string;
  presencePenalty: Penalty;
  countPenalty: Penalty;
  frequencyPenalty: Penalty;
}

export const getCompletionCurlCodeSnippet = (
  url: string,
  prompt: string,
  controllerParams: ControllerParams,
  context: CodeSnippetModalContext
) => `curl '${url}' \\
  -H 'content-type: application/json' \\
  -H 'authorization: Bearer YOUR_API_KEY' \\
  --data-raw '{
  "prompt":"${prompt}",
  "numResults":1,
  "epoch": ${controllerParams.epoch},
  "maxTokens":${controllerParams.maxTokens},
  "temperature":${controllerParams.temperature},
  ${
    context === CodeSnippetModalContext.PLAYGROUND
      ? `"topKReturn": 0,
  "topP":${controllerParams.topP},
  "presencePenalty": {
    "scale": ${controllerParams.presencePenalty},
    "applyToNumbers": true,
    "applyToPunctuations": true,
    "applyToStopwords": true,
    "applyToWhitespaces": true,
    "applyToEmojis": true
  },
  "countPenalty": {
    "scale": ${controllerParams.countPenalty},
    "applyToNumbers": true,
    "applyToPunctuations": true,
    "applyToStopwords": true,
    "applyToWhitespaces": true,
    "applyToEmojis": true
  },
  "frequencyPenalty": {
    "scale": ${controllerParams.frequencyPenalty},
    "applyToNumbers": true,
    "applyToPunctuations": true,
    "applyToStopwords": true,
    "applyToWhitespaces": true,
    "applyToEmojis": true
  },
  "stopSequences":[${controllerParams.stopSequences?.map((sq) => `"${sq}"`).join(',')}]`
      : '"topKReturn": 0'
  }}'`;

export const getCompletionChatCurlCodeSnippet = (
  modelName: string,
  url: string,
  prompt: string,
  controllerParams: ControllerParams
) => `curl '${url}' \\
  -H 'content-type: application/json' \\
  -H 'authorization: Bearer YOUR_API_KEY' \\
  --data-raw '{
  "model": "${modelName}",
  "messages": [{"content": "${prompt}", "role": "user"}],
  "n":1,
  "max_tokens":${controllerParams.maxTokens},
  "temperature":${controllerParams.temperature},
  "top_p":${controllerParams.topP},
  "stop":[${controllerParams.stopSequences?.map((sq) => `"${sq}"`).join(',')}]
  }'`;

export const getCompletionJsCodeSnippet = (
  url: string,
  prompt: string,
  controllerParams: ControllerParams,
  context: CodeSnippetModalContext
) => {
  return `fetch("${url}", {
  headers: {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
      "prompt": "${prompt}",
      "numResults": 1,
      "epoch": ${controllerParams.epoch},
      "maxTokens": ${controllerParams.maxTokens},
      "temperature": ${controllerParams.temperature},
      ${
        context === CodeSnippetModalContext.PLAYGROUND
          ? `"topKReturn": 0,
      "topP":${controllerParams.topP},
      "presencePenalty": {
        "scale": ${controllerParams.presencePenalty},
        "applyToNumbers": true,
        "applyToPunctuations": true,
        "applyToStopwords": true,
        "applyToWhitespaces": true,
        "applyToEmojis": true
      },
      "countPenalty": {
        "scale": ${controllerParams.countPenalty},
        "applyToNumbers": true,
        "applyToPunctuations": true,
        "applyToStopwords": true,
        "applyToWhitespaces": true,
        "applyToEmojis": true
      },
      "frequencyPenalty": {
        "scale": ${controllerParams.frequencyPenalty},
        "applyToNumbers": true,
        "applyToPunctuations": true,
        "applyToStopwords": true,
        "applyToWhitespaces": true,
        "applyToEmojis": true
      },
      "stopSequences":[${controllerParams.stopSequences?.map((sq) => `"${sq}"`).join(',')}]`
          : '"topKReturn": 0'
      }
    }),
  method: "POST"
});`;
};

export const getCompletionChatJsCodeSnippet = (
  modelName: string,
  url: string,
  prompt: string,
  controllerParams: ControllerParams
) => {
  return `fetch("${url}", {
  headers: {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
      "model": "${modelName}",
      "messages": [{"content": "${prompt}", "role": "user"}],
      "n": 1,
      "max_tokens": ${controllerParams.maxTokens},
      "temperature": ${controllerParams.temperature},
      "top_p":${controllerParams.topP},
      "stop":[${controllerParams.stopSequences?.map((sq) => `"${sq}"`).join(',')}]
      }
    }),
  method: "POST"
});`;
};

function createMessages(messages: string) {
  const newMessages = JSON.parse(messages);
  if (newMessages.length === 0) {
    return 'messages=[]';
  }

  return `messages=[${newMessages.map((message) => {
    return `ChatMessage(
      content="${message.content}",
      role="${message.role.toLowerCase()}",
    )\n`;
  })}]`;
}

function getPythonPenalties(controllerParams: ControllerParams) {
  return `presence_penalty=Penalty(
    scale=${Math.max(controllerParams.presencePenalty, 1)},
    apply_to_numbers=True,
    apply_to_punctuation=True,
    apply_to_stopwords=True,
    apply_to_whitespaces=True,
    apply_to_emojis=True
  ),
  count_penalty=Penalty(
    scale=${Math.max(controllerParams.countPenalty, 1)},
    apply_to_numbers=True,
    apply_to_punctuation=True,
    apply_to_stopwords=True,
    apply_to_whitespaces=True,
    apply_to_emojis=True
  ),
  frequency_penalty=Penalty(
    scale=${Math.max(controllerParams.frequencyPenalty, 1)},
    apply_to_numbers=True,
    apply_to_punctuation=True,
    apply_to_stopwords=True,
    apply_to_whitespaces=True,
    apply_to_emojis=True
  ),`;
}

export const getExperimentalCompletionPythonCodeSnippet = (
  modelName: string,
  prompt: string,
  controllerParams: ControllerParams,
  context: CodeSnippetModalContext
) => `import os
from ai21 import AI21Client

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)

client.experimental.${modelName.replace(/-/g, '_')}.create(
  prompt="${prompt}",
  num_results=1,
  max_tokens=${controllerParams.maxTokens},
  temperature=${controllerParams.temperature},
  ${
    context === CodeSnippetModalContext.PLAYGROUND
      ? `top_k_return=0,
  top_p=${controllerParams.topP},
  ${getPythonPenalties(controllerParams)}
  stop_sequences=[${controllerParams.stopSequences.map((sq) => `"${sq}"`).join(',')}]`
      : 'top_k_return=0'
  }
)`;

export const getCompletionPythonCodeSnippet = (
  modelName: string,
  prompt: string,
  controllerParams: ControllerParams,
  context: CodeSnippetModalContext
) => `import os
from ai21 import AI21Client
from ai21.models import Penalty

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)

client.completion.create(
  model="${modelName}",
  prompt="${prompt}",
  num_results=1,
  max_tokens=${controllerParams.maxTokens},
  temperature=${controllerParams.temperature},
  ${
    context === CodeSnippetModalContext.PLAYGROUND
      ? `top_k_return=0,
  top_p=${controllerParams.topP},
  ${getPythonPenalties(controllerParams)}
  stop_sequences=[${controllerParams.stopSequences.map((sq) => `"${sq}"`).join(',')}]`
      : 'top_k_return=0'
  }
)`;

export const getCompletionChatPythonCodeSnippet = (
  modelName: string,
  prompt: string,
  controllerParams: ControllerParams
) => `import os
from ai21 import AI21Client
from ai21.models.chat import ChatMessage

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)
client.chat.completions.create(
  model="${modelName}",
  messages=[ChatMessage(
    content="${prompt}",
    role="user",
  )],
  num_results=1,
  max_tokens=${controllerParams.maxTokens},
  temperature=${controllerParams.temperature},
  top_p=${controllerParams.topP},
  stop_sequences=[${controllerParams.stopSequences.map((sq) => `"${sq}"`).join(',')}]
)`;

export const getCustomModelCompletionPythonCodeSnippet = (
  modelType: string,
  customModelName: string,
  prompt: string,
  controllerParams: ControllerParams,
  context: CodeSnippetModalContext
) => `import os
from ai21 import AI21Client
from ai21.models import Penalty

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)
client.completion.create(
  model="${modelType}",
  custom_model="${customModelName}",
  prompt="${prompt}",
  num_results=1,
  epoch: ${controllerParams.epoch},
  max_tokens=${controllerParams.maxTokens},
  temperature=${controllerParams.temperature},
  ${
    context === CodeSnippetModalContext.PLAYGROUND
      ? `top_k_return=0,
  top_p=${controllerParams.topP},
  ${getPythonPenalties(controllerParams)}
  stop_sequences=[${controllerParams.stopSequences.map((sq) => `"${sq}"`).join(',')}]`
      : 'top_k_return=0'
  }
)`;

// Summarization:

export const getSummarizationPythonCodeSnippet = (
  url: string,
  prompt: string,
  type = 'TEXT'
) => `import os
from ai21 import AI21Client
from ai21.models import DocumentType

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)
client.summarize.create(
  source="${prompt}",
  source_type=DocumentType.${type.toUpperCase()}
  )`;

export const getSummarizationJsCodeSnippet = (
  url: string,
  prompt: string,
  type = 'TEXT'
) => `fetch("${url}", {
  headers: {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
      "source": "${prompt}",
      "sourceType": "${type.toUpperCase()}",
    }),
  method: "POST"
});`;

export const getSummarizationCurlCodeSnippet = (
  url: string,
  prompt: string,
  type = 'TEXT'
) => `curl '${url}' \\
  -H 'content-type: application/json' \\
  -H 'authorization: Bearer YOUR_API_KEY' \\
  --data-raw '{
  "source":"${prompt}",
  "sourceType":"${type.toUpperCase()}"
}'`;

// Segmentation:

export const getSegmentationPythonCodeSnippet = (
  url: string,
  prompt: string,
  type = 'TEXT'
) => `import os
from ai21 import AI21Client
from ai21.models import DocumentType

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)
client.segmentation.create(
  source="${prompt}", source_type=DocumentType.${type.toUpperCase()})`;

export const getSegmentationJsCodeSnippet = (
  url: string,
  prompt: string,
  type = 'TEXT'
) => `fetch("${url}", {
  headers: {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
      "source": "${prompt}",
      "sourceType": "${type.toUpperCase()}",
    }),
  method: "POST"
});`;

export const getSegmentationCurlCodeSnippet = (
  url: string,
  prompt: string,
  type = 'TEXT'
) => `curl '${url}' \\
  -H 'content-type: application/json' \\
  -H 'authorization: Bearer YOUR_API_KEY' \\
  --data-raw '{
  "source":"${prompt}",
  "sourceType":"${type.toUpperCase()}"
}'`;

// Paraphrase :

export const getParaphraseCurlCodeSnippet = (
  url: string,
  text: string,
  style: string = 'general'
) => `curl '${url}' \\
  -H 'content-type: application/json' \\
  -H 'authorization: Bearer YOUR_API_KEY' \\
  --data-raw '{
  "text":"${text}",
  "style": "${style}"
  }'`;

export const getParaphraseJsCodeSnippet = (
  url: string,
  text: string,
  style: string = 'general'
) => `fetch("${url}", {
  headers: {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    "text": "${text}",
    "style": "${style}"
    }),
  method: "POST"
});`;

export const getParaphrasePythonCodeSnippet = (
  url: string,
  text: string,
  style: string = 'general'
) => `import os
from ai21 import AI21Client
from ai21.models import ParaphraseStyleType

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)

client.paraphrase.create(
  text="${text}",
  style=ParaphraseStyleType.${style.toUpperCase()}
  )`;

// Improvements :

export const getImprovementsCurlCodeSnippet = (
  url: string,
  text: string,
  types = [
    'fluency',
    'vocabulary/specificity',
    'vocabulary/variety',
    'clarity/short-sentences',
    'clarity/conciseness',
  ]
) => `curl '${url}' \\
  -H 'content-type: application/json' \\
  -H 'authorization: Bearer YOUR_API_KEY' \\
  --data-raw '{
  "text": "${text}",
  "types": ["${types.join('", "')}"]
}'`;

export const getImprovementsJsCodeSnippet = (
  url: string,
  text: string,
  types = [
    'fluency',
    'vocabulary/specificity',
    'vocabulary/variety',
    'clarity/short-sentences',
    'clarity/conciseness',
  ]
) => `fetch("${url}", {
  headers: {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    "text": "${text}",
    "types": ["${types.join('", "')}"]
    }),
  method: "POST"
});`;

export const getImprovementsPythonCodeSnippet = (
  url: string,
  text: string,
  types = [
    'fluency',
    'vocabulary/specificity',
    'vocabulary/variety',
    'clarity/short-sentences',
    'clarity/conciseness',
  ]
) => `import os
from ai21 import AI21Client
from ai21.models import ImprovementType

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)
client.improvements.create(
  text="${text}",
  types=[${types.map((type) => {
    return `\n    ImprovementType.${type.replace('/', '_').replace('-', '_').toUpperCase()}`;
  })}
])`;

// GEC :

export const getGECCurlCodeSnippet = (url: string, text: string) => `curl '${url}' \\
  -H 'content-type: application/json' \\
  -H 'authorization: Bearer YOUR_API_KEY' \\
  --data-raw '{
  "text":"${text}"
}'`;

export const getGECJsCodeSnippet = (url: string, text: string) => `fetch("${url}", {
  headers: {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    "text": "${text}",
    }),
  method: "POST"
});`;

export const getGECPythonCodeSnippet = (url: string, text: string) => `import os
from ai21 import AI21Client

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)

client.gec.create(text="${text}")`;

// Answer :

export const getAnswerCurlCodeSnippet = (
  url: string,
  context: string,
  question: string
) => `curl '${url}' \\
  -H 'content-type: application/json' \\
  -H 'authorization: Bearer YOUR_API_KEY' \\
  --data-raw '{
  "context":"${context}",
  "question":"${question}"
  }'`;

export const getAnswerJsCodeSnippet = (
  url: string,
  context: string,
  question: string
) => `fetch("${url}", {
  headers: {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    "context": "${context}",
    "question": "${question}",
    }),
  method: "POST"
});`;

export const getAnswerPythonCodeSnippet = (
  url: string,
  context: string,
  question: string
) => `import os
from ai21 import AI21Client

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)

client.answer.create(
  context="${context}",
   question="${question}"
  )`;

// Document Library :

export const getLibraryCurlCodeSnippet = (url: string, question: string) => `curl '${url}' \\
  -H 'content-type: application/json' \\
  -H 'authorization: Bearer YOUR_API_KEY' \\
  --data-raw '{
  "question":"${question}"
  }'`;

export const getLibraryJsCodeSnippet = (url: string, question: string) => `fetch("${url}", {
  headers: {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    "question": "${question}",
    }),
  method: "POST"
});`;

export const getLibraryPythonCodeSnippet = (url: string, question: string) => `import os
from ai21 import AI21Client

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)

client.library.answer.create(question="${question}")`;

export const getChatJsCodeSnippet = (
  url: string,
  messages: string,
  system: string,
  modelName: string,
  controllerParams: ControllerParams
) => {
  return `fetch("${url}", {
  headers: {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
      "model": "${modelName}",
      "messages": ${messages},
      "n": 1, ${system ? `"\n system": "${system}",` : ''}
      "max_tokens": ${controllerParams.maxTokens},
      "temperature": ${controllerParams.temperature},
      "top_p": ${controllerParams.topP},
      "stop": [${controllerParams.stopSequences?.map((sq) => `"${sq}"`).join(',')}]
    }),
  method: "POST"
});`;
};

export const getChatCurlCodeSnippet = (
  url: string,
  messages: string,
  system: string,
  modelName: string,
  controllerParams: ControllerParams
) => `curl '${url}' \\
  -H 'Content-type: application/json' \\
  -H 'Authorization: Bearer YOUR_API_KEY' \\
  --data '{
      "model": "${modelName}",
      "messages": ${messages},
      "n": 1, ${system ? `"\n system": "${system}",` : ''}
      "max_tokens": ${controllerParams.maxTokens},
      "temperature": ${controllerParams.temperature},
      "top_p": ${controllerParams.topP},
      "stop": [${controllerParams.stopSequences?.map((sq) => `"${sq}"`).join(',')}]
    }'`;

export const getChatPythonCodeSnippet = (
  messages: string,
  system: string,
  modelName: string,
  controllerParams: ControllerParams
) => `import os
from ai21 import AI21Client
from ai21.models.chat import ChatMessage

client = AI21Client(
    # This is the default and can be omitted
    api_key=os.environ.get("AI21_API_KEY"),
)

client.chat.completions.create(
  model="${modelName}",
  ${createMessages(messages)},
  n=1, ${system ? `"\n system"="${system}",` : ''}
  max_tokens=${controllerParams.maxTokens},
  temperature=${controllerParams.temperature},
  top_p=${controllerParams.topP},
  stop=[${controllerParams.stopSequences.map((sq) => `"${sq}"`).join(',')}],
)`;
