import { ISheetCells } from './types';

export const sheetCells: ISheetCells = {
  '1-2': { id: '1-2', value: 'light', cellType: 'model' },
  '1-3': { id: '1-3', value: 'light', cellType: 'model' },
  '1-4': { id: '1-4', value: 'mid', cellType: 'model' },
  '1-5': { id: '1-5', value: 'mid', cellType: 'model' },
  '2-2': { id: '2-2', value: '0.9', cellType: 'temperature' },
  '2-3': { id: '2-3', value: '0.5', cellType: 'temperature' },
  '2-4': { id: '2-4', value: '0.9', cellType: 'temperature' },
  '2-5': { id: '2-5', value: '0.5', cellType: 'temperature' },
  '3-2': { id: '3-2', value: '1.0', cellType: 'topP' },
  '3-3': { id: '3-3', value: '1.0', cellType: 'topP' },
  '3-4': { id: '3-4', value: '1.0', cellType: 'topP' },
  '3-5': { id: '3-5', value: '1.0', cellType: 'topP' },
  '4-2': { id: '4-2', value: '50', cellType: 'maxTokens' },
  '4-3': { id: '4-3', value: '50', cellType: 'maxTokens' },
  '4-4': { id: '4-4', value: '50', cellType: 'maxTokens' },
  '4-5': { id: '4-5', value: '50', cellType: 'maxTokens' },
  '1-1': { id: '1-1', value: '//', cellType: 'label' },
  '2-1': { id: '2-1', value: 'temperature', cellType: 'label' },
  '3-1': { id: '3-1', value: 'topP', cellType: 'label' },
  '4-1': { id: '4-1', value: 'maxTokens', cellType: 'label' },
  '5-1': { id: '5-1', value: 'capital of france', cellType: 'prompt' },
  '5-2': { id: '5-2', value: '-', cellType: 'value' },
  '5-3': { id: '5-3', value: '-', cellType: 'value' },
  '5-4': { id: '5-4', value: '-', cellType: 'value' },
  '5-5': { id: '5-5', value: '-', cellType: 'value' },
  '6-1': { id: '6-1', value: 'generate guid', cellType: 'prompt' },
  '6-2': { id: '6-2', value: '-', cellType: 'value' },
  '6-3': { id: '6-3', value: '-', cellType: 'value' },
  '6-4': { id: '6-4', value: '-', cellType: 'value' },
  '6-5': { id: '6-5', value: '-', cellType: 'value' },
  '7-1': { id: '7-1', value: 'a limerick about work', cellType: 'prompt' },
  '7-2': { id: '7-2', value: '-', cellType: 'value' },
  '7-3': { id: '7-3', value: '-', cellType: 'value' },
  '7-4': { id: '7-4', value: '-', cellType: 'value' },
  '7-5': { id: '7-5', value: '-', cellType: 'value' },
};
