import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  width: 50vw;
  /* max-width: 40vw; */
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12), 0px 5px 8px rgba(0, 0, 0, 0.14),
    0px 3px 5px -1px rgba(0, 0, 0, 0.2);

  &.fullscreen {
    width: 100vw;
  }

  @media (max-width: 1320px) {
    zoom: 0.9;
  }
`;

export const Container = styled.div`
  flex: 1;
  width: 50vw;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const EmptyBar = styled.div`
  flex: 1;
  padding: 0 10px;
  max-height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e9e9e9;
`;
