import styled from 'styled-components';
import { CircularProgress, tokens } from '@ai21/studio-base-ui';
import { Typography } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  padding: 14px 20px 8px 20px;
  background-color: ${tokens.primary.backgroundHover};
  border-radius: 12px;

  code {
  }

  .mark-down {
    white-space: normal;
    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      margin-block-start: 0.8;
      margin-block-end: 0.8em;
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
    h4 {
      font-size: 16px;
      line-height: 24px;
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
    h5 {
      font-size: 14px;
      line-height: 20px;
      margin-block-start: 1em;
      margin-block-end: 1em;
    }

    p {
      margin-block-start: 0.25em;
      margin-block-end: 0.5;
    }

    li {
      /* width: 100px; */
    }

    ul {
      margin-block-start: 1em;
      margin-block-end: 1em;
      padding-inline-start: 40px;
    }

    code {
      border-radius: 8px;
      margin-block-end: 1em;
    }
    > pre {
      position: relative;
      cursor: pointer;
      margin: 0;

      code {
        min-height: 10px;
        border-radius: 8px;
      }

      &::after {
        content: 'Copy';
        position: absolute;
        top: 12px;
        right: 18px;
        margin: 5px;
      }

      &:hover {
        &::after {
          color: #fbfbfb;
        }
      }
    }
  }

  &.assistant {
    background-color: ${tokens.brand.backgroundHover};

    &:hover {
      background-color: ${tokens.brand.backgroundActive} !important;
    }
  }

  &.user {
    background-color: ${tokens.primary.backgroundHover};

    &:hover {
      background-color: ${tokens.primary.backgroundActive} !important;
    }
  }

  .MiniFeedbackEditor-wrapper {
    padding: 0;
    margin-right: -4px;
    button {
      background-color: white;
      margin: 4px;
      width: 30px;
      height: 30px;

      &.back {
        &:hover {
          background-color: #f4f5f7;
        }

        &:active {
          background-color: #e6eaec;
        }
      }

      &.check {
        &:hover {
          background-color: #f4f3ff;
          border: 1px solid #a28df8;
        }

        &:active {
          background-color: #ece9fe;
          border: 1px solid #845ff3;
        }
      }
    }
  }
`;

export const Loader = styled(CircularProgress)`
  color: 'primary';
`;
