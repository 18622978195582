import { Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(20vh - 100px);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 60px;
    height: 60px;
  }
`;

export const H1 = styled(Typography)`
  font-weight: 100;
  opacity: 0.2;
`;
