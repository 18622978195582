export function QuestionMarkIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.1666 16.875C14.7419 16.875 15.2082 16.4086 15.2082 15.8333V4.16667C15.2082 3.59137 14.7419 3.125 14.1666 3.125H5.83325C5.25795 3.125 4.79158 3.59137 4.79158 4.16667V12.7083H7.49991C8.30533 12.7083 8.95825 13.3613 8.95825 14.1667V16.875H14.1666ZM7.70825 15.9911L5.67546 13.9583H7.49991C7.61497 13.9583 7.70825 14.0516 7.70825 14.1667V15.9911ZM16.4582 15.8333C16.4582 17.099 15.4322 18.125 14.1666 18.125H8.33325C8.16749 18.125 8.00852 18.0592 7.89131 17.9419L3.72464 13.7753C3.60743 13.6581 3.54158 13.4991 3.54158 13.3333V4.16667C3.54158 2.90101 4.56759 1.875 5.83325 1.875H14.1666C15.4322 1.875 16.4582 2.90101 16.4582 4.16667V15.8333Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.79639 6.04863C8.92305 6.14765 8.40254 6.83614 8.47396 7.466C8.51285 7.80898 8.26633 8.11855 7.92335 8.15744C7.58037 8.19633 7.2708 7.94981 7.23191 7.60683C7.06883 6.16854 8.22549 4.96874 9.65556 4.80659L10.2514 4.73903C11.6758 4.57751 13.0664 5.48014 13.2385 6.90866C13.4107 7.97252 12.9066 9.04633 11.9557 9.54393C11.5363 9.83065 11.2037 10.599 11.3492 11.5395C11.402 11.8806 11.1682 12.1999 10.8271 12.2527C10.486 12.3055 10.1667 12.0717 10.1139 11.7306C9.92 10.4773 10.3145 9.10997 11.3031 8.47706C11.3213 8.46542 11.3401 8.45473 11.3594 8.44503C11.82 8.21347 12.0996 7.67228 12.0031 7.09985C12.0012 7.08874 11.9997 7.07757 11.9984 7.06638C11.927 6.43651 11.2655 5.88205 10.3922 5.98107L9.79639 6.04863ZM10.9442 13.5104C11.2872 13.4715 11.5967 13.718 11.6356 14.061L11.6363 14.0673C11.6752 14.4102 11.4287 14.7198 11.0857 14.7587C10.7427 14.7976 10.4332 14.5511 10.3943 14.2081L10.3936 14.2019C10.3547 13.8589 10.6012 13.5493 10.9442 13.5104Z'
        fill='currentColor'
      />
      <path
        d='M5.95025 17.9205L4.82581 16.8211L4.96622 16.0076C5.00127 15.9443 4.9273 15.8736 4.86559 15.9187L4.0005 16.3712L2.46156 16.0154C2.39655 16.0001 2.35586 16.0684 2.39751 16.126L3.21712 17.1938L2.61356 18.4883C2.58416 18.5465 2.65287 18.6114 2.71419 18.5772L4.09829 17.7624L5.90236 18.0376C5.96212 18.0471 5.99794 17.962 5.95025 17.9205Z'
        fill='currentColor'
      />
    </svg>
  );
}
