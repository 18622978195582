import { User } from 'firebase/auth';
import { AuthOptions, AuthType, FirebaseUser, LoginProviderAll, RouteType } from '../types';
import { getBoolean } from 'shared-base';

export class AuthState {
  public options: AuthOptions;
  public routeType: RouteType = 'unknown';
  public lastProvider?: LoginProviderAll;
  public user: Json = {};
  public authType?: AuthType;
  public isNewUser?: boolean;
  public isManual?: boolean;

  constructor(options: AuthOptions) {
    this.options = options;
    this.isNewUser = !getBoolean('USER_LOGGED_IN_ONCE');
    this.authType = 'LOGIN';
  }

  setUser(user: User | FirebaseUser | null) {
    if (!user) {
      return;
    }

    let { email, name, displayName } = user; // TODO: remove displayName

    if (!name || !displayName) {
      name = (email ?? '').split('@')[0];
    }

    this.user.name = name || displayName;
    this.user.email = email;
  }

  setApiKey(apiKey: string) {
    this.user.apiKey = apiKey;
  }

  getLogsProps() {
    return {
      authType: this.authType!,
      authMethod: this.lastProvider,
    };
  }
}
