import { fireRequest } from './axios';
import { UserRole } from './types';

export const getAll = () => {
  return fireRequest<Json>({
    path: '/organization/users',
    endpoint: 'users.getAll',
  });
};

export const changeRole = (email: string, newRole: UserRole) => {
  return fireRequest<Json>({
    method: 'post',
    path: '/organization/users',
    data: {
      email,
      newRole,
    },
    endpoint: 'users.changeRole',
  });
};

export const remove = (email: string) => {
  return fireRequest<Json>({
    method: 'delete',
    path: `/organization/users/${email}`,
    endpoint: 'users.remove',
  });
};

export const deleteUser = (organizationId: string, userId: string) => {
  return fireRequest<Json>({
    method: 'delete',
    path: `/organization/${organizationId}/users/${userId}`,
    endpoint: 'users.deleteUser',
  });
};

export const rotateApiKey = (email: string) => {
  return fireRequest<Json>({
    method: 'post',
    path: '/organization/users/rotate',
    data: {
      regenerateKey: true,
      email,
    },
    endpoint: 'users.rotateApiKey',
  });
};

export const users = {
  getAll,
  changeRole,
  remove,
  rotateApiKey,
  deleteUser
};
