import React from 'react';

const CompanyPage = React.lazy(() => import('./company/CompanyPage.container'));
const ShowroomPage = React.lazy(
  () => import('./showroom/ShowroomPage.container')
);
const AppPage = React.lazy(() => import('./app/AppPage.container'));

export const pages: Record<string, React.FC<any>> = {
  company: CompanyPage,
  showroom: ShowroomPage,
  app: AppPage,
};
