import { actions } from '@ai21/studio-store';
import { fork, put, takeEvery } from 'saga-ts';
import { getJson } from 'shared-base';
import { removeJson, setJson } from '../utils/storage';

type ActionSaveUser = {
  type: 'SET_USER';
  payload: {
    apiKey: string;
    userState: string;
    userTier: string;
    userRole: string;
    featureFlags: string | null;
    subscriptionCancellationDate: string | null;
    subscriptionExpirationDate: string | null;
  };
};

export function* saveUser(action: ActionSaveUser) {
  const {
    apiKey,
    userState,
    userTier,
    userRole,
    featureFlags,
    subscriptionCancellationDate,
    subscriptionExpirationDate,
  } = action.payload;

  setJson('USER', {
    apiKey,
    userState,
    userTier,
    userRole,
    featureFlags,
    subscriptionCancellationDate,
    subscriptionExpirationDate,
  });
}

export function* loadUser() {
  const user = getJson('USER');

  if (!user || !user.apiKey) {
    return;
  }

  yield put(actions.user.setAll(user as any));

  yield put({
    type: 'LOAD_USER',
    payload: user ?? {},
  });
}

type ActionSaveCoupon = {
  type: 'SAVE_COUPON';
  payload: {
    stripeCoupon: string;
    stripeCouponExpiration: Date;
  };
};

export function* saveCoupon(action: ActionSaveCoupon) {
  const { stripeCoupon, stripeCouponExpiration } = action.payload;

  setJson('COUPON', {
    stripeCoupon,
    stripeCouponExpiration,
  });
}

export function* loadCoupon() {
  const coupon = getJson('COUPON');

  if (!coupon || !coupon.stripeCoupon) {
    return;
  }

  yield put({
    type: 'LOAD_COUPON',
    ...coupon,
  });
}

export function* clearAll() {
  removeJson('USER');
  removeJson('COUPON');
}

export function* root() {
  yield fork(loadUser);
  yield fork(loadCoupon);
  yield takeEvery('SET_USER', saveUser);
  yield takeEvery('SAVE_COUPON', saveCoupon);
  yield takeEvery('LOGOUT', clearAll);
}
