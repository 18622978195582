import type { IMenuGroup, IMenuItem } from '@ai21/studio-ui';
import { AppRoutes } from './App.routes';

// 1st word capitalized, 2nd word lowercase
export const sideBarItems: IMenuItem[] = [
  {
    id: 'collection',
    title: 'Data collections',
    path: AppRoutes.COLLECTION_SETS,
    groupId: 'assets',
    iconName: 'SquarePlus',
    order: 2,
    isChild: true,
  },
];

export const sideBarGroups: IMenuGroup[] = [];
