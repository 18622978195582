import { selectors } from '@ai21/studio-store';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CompletionInspector } from '@ai21/studio-ui';

export type CompletionInspectorContainerProps = {
  onClose: () => void;
};

export function CompletionInspectorContainer(
  props: CompletionInspectorContainerProps
) {
  const dispatch = useDispatch();
  const line = useSelector(selectors.base.$currentGenerationLine);
  const job = useSelector(selectors.base.$currentGenerationJob);

  const { linesCount: total } = job ?? { linesCount: 0 };

  const callbacks = useMemo(
    () => ({
      onNext: () => {
        dispatch({
          type: 'GENERATION_LINE',
          verb: 'next',
        });
      },
      onPrev: () => {
        dispatch({
          type: 'GENERATION_LINE',
          verb: 'previous',
        });
      },
      onClose: () => {
        props.onClose();
      },
    }),
    []
  );

  if (!line) {
    return null;
  }

  return (
    <CompletionInspector line={line} total={total} callbacks={callbacks} />
  );
}

export default CompletionInspectorContainer;
