export function LeftNavigationIcon({ enabled }: { enabled: boolean }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill={enabled ? '#626B78' : '#a0a2a8'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M12.842 6.175 11.667 5l-5 5 5 5 1.175-1.175L9.025 10l3.817-3.825z' />
    </svg>
  );
}
