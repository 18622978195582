export function DatasetLargeIcon() {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 9.5c4.418 0 8-1.343 8-3s-3.582-3-8-3-8 1.343-8 3 3.582 3 8 3z'
        stroke='#626B78'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 6.5v6c0 .796.843 1.559 2.343 2.121 1.5.563 3.535.879 5.657.879s4.157-.316 5.657-.879C19.157 14.06 20 13.296 20 12.5v-6'
        stroke='#626B78'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 12.5v6c0 .796.843 1.559 2.343 2.121 1.5.563 3.535.879 5.657.879s4.157-.316 5.657-.879C19.157 20.06 20 19.296 20 18.5v-6'
        stroke='#626B78'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
