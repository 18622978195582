import { makeStyles } from '@ai21/studio-base-ui';

export const paginatedTableStyles = makeStyles((theme) => ({
  table: {},
  tableContainer: {
    borderRadius: 12,
  },
  tableLiteContainer: {
    borderRadius: 12,
    maxHeight: 'inherit',
  },
  dataItem: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: '0.15px',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  columnHeader: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: '0.15px',
  },
  row: {
    height: 52,
  },
  shortRow: {
    height: 48,
  },
  selectableItem: {
    cursor: 'pointer',
  },
  starContainer: {
    cursor: 'pointer',
  },
  actionButton: {
    backgroundColor: 'transparent',
    color: '#e91e63',
    '&:hover': {
      backgroundColor: '#fdebf1',
    },
  },
  datasetActionBtn: {
    maxWidth: '35px',
    maxHeight: '35px',
  },
  pagination: {
    borderTopStyle: 'solid',
    p: {
      fontSize: 70,
    },
  },
  root: {
    backgroundColor: (theme as any).palette.primary.dark,
    fontSize: 70,
  },
  tablePagination: { fontSize: 70 },
  tablePaginationCaption: {
    color: 'white',
    fontSize: 70,
  },
  tablePaginationSelectIcon: {
    color: 'white',
    fontSize: 70,
  },
  tablePaginationSelect: {
    color: 'white',
    fontSize: 70,
  },
  tablePaginationActions: {
    color: 'white',
    fontSize: 70,
  },
}));

export default paginatedTableStyles;
