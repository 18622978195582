import { Icon, Markdown, TableBody } from '@ai21/studio-base-ui';
import classnames from 'classnames';
import { ITableEmptyState } from '../../types';
import { Cta, Message, Wrapper } from './TableEmpty.style';
import TableLoadingRow from '../TableLoadingRow/TableLoadingRow';

export type TableEmptyProps = {
  emptyState?: ITableEmptyState;
  onCta: (actionId: string) => void;
  isFiltered?: boolean;
};

export function TableEmpty(props: TableEmptyProps) {
  const { emptyState, isFiltered } = props;

  if (isFiltered) {
    return <TableNoSearchResults emptyState={emptyState} />;
  }

  if (!emptyState) {
    return <DefaultTableEmpty />;
  }

  const { iconName, ctaActionId, ctaButton, description } = emptyState;

  const className = classnames('TableEmpty-wrapper', {});

  function onCta() {
    if (!ctaActionId) {
      return;
    }

    props.onCta(ctaActionId);
  }

  function renderCta() {
    if (!ctaActionId && !ctaButton) {
      return null;
    }

    return (
      <Cta data-testid='link-empty-table-create' onClick={onCta}>
        {ctaButton}
      </Cta>
    );
  }

  return (
    <Wrapper className={className} data-testid='TableEmpty-wrapper'>
      <Icon iconName={iconName} />
      <Message variant='body1' color='text.secondary'>
        {description}.{renderCta()}.
      </Message>
    </Wrapper>
  );
}

export default TableEmpty;

export function DefaultTableEmpty() {
  return (
    <Wrapper className='TableEmpty-wrapper' data-testid='TableEmpty-wrapper'>
      <Icon iconName='Database' />
      <Message variant='body1'>No items</Message>
    </Wrapper>
  );
}

type TableNoSearchResultsProps = {
  emptyState?: ITableEmptyState;
};

export function TableNoSearchResults(props: TableNoSearchResultsProps) {
  const { emptyState } = props;
  const { searchEmpty = 'No search results', iconName = 'Database' } = emptyState ?? {};

  return (
    <Wrapper className='TableNoSearchResults-wrapper' data-testid='TableNoSearchResults-wrapper'>
      <Icon iconName={iconName} />
      <Message variant='body1'>{searchEmpty}</Message>
    </Wrapper>
  );
}
