import styled from 'styled-components';
import { TextField } from '@mui/material';

export const Wrapper = styled.div`
  flex: 1;

  .MuiTextField-root {
    background-color: white;
    border-radius: 12px;
  }
`;

export const Input = styled(TextField)``;
