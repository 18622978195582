import { selectors } from '@ai21/studio-store';
import { useSelector } from 'react-redux';
import { UploadProgress } from './UploadProgress';

export type UploadProgressContainerProps = {
  onCancel: () => void;
};

export function UploadProgressContainer(props: UploadProgressContainerProps) {
  const appState = useSelector(selectors.raw.$rawAppState);

  const { progressTotal, progressCompleted } = appState;

  return (
    <UploadProgress
      total={progressTotal}
      completed={progressCompleted}
      onCancel={props.onCancel}
    />
  );
}

export default UploadProgressContainer;
