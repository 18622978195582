import { IDocumentQuestion } from '@ai21/studio-store';
import AskLineBase from '../AskLineBase/AskLineBase';
import AskLineEmpty from '../AskLineEmpty/AskLineEmpty';
import { Source } from '../Source/Source';
import { Wrapper } from './AskLine.style';
import { Verb } from '../../AskEditor.types';

export type AskLineProps = {
  item: IDocumentQuestion;
  fileName: string;
  callbacks: {
    onAction: (verb: Verb, params?: Json) => void;
    askAllLibrary: () => void;
  };
};

export function AskLine(props: AskLineProps) {
  const { item } = props;
  const { answerInContext, sources = [] } = item;

  if (!answerInContext) {
    return <AskLineEmpty {...props} />;
  }

  function renderSource(source: Json) {
    return <Source key={source.fileId} source={source} />;
  }

  function renderSources() {
    return sources.map((source: Json) => renderSource(source));
  }

  return (
    <Wrapper className='AskLine-wrapper' data-testid='AskLine-wrapper'>
      <AskLineBase {...props}>{renderSources()}</AskLineBase>
    </Wrapper>
  );
}

export default AskLine;
