import collections from './json/form.collections.json';
import collectionsEdit from './json/form.collectionsEdit.json';
import inspector from './json/form.inspector.json';
import collectionsDefault from './form.collections.detaults.json';

export const forms = {
  collections,
  collectionsEdit,
  inspector,
};

export const formDefaults = {
  collectionsDefault,
  inspector: {},
};
