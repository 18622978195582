import { logEvent } from '../../analytics.amplitude';
import { IncomingNetworkEvent } from '../../analytics.types';

function* ApiRequest(data: IncomingNetworkEvent) {
  const { endpoint } = data;
  logEvent(
    'ApiRequest',
    {
      endpoint,
    },
    true
  );
}

function* ApiError(data: IncomingNetworkEvent) {
  const { endpoint, errorMessage, status } = data;

  logEvent(
    'ApiError',
    {
      endpoint,
      errorMessage,
      status,
    },
    true
  );
}

function* ApiSuccess(data: IncomingNetworkEvent) {
  const { endpoint } = data;

  logEvent(
    'ApiSuccess',
    {
      endpoint,
    },
    true
  );
}

export const rootDefault = {
  success: ApiSuccess,
  error: ApiError,
  request: ApiRequest,
};
