export function SummarizeIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='6' fill='white' />
      <rect width='52' height='52' rx='6' fill='#8459AE' fillOpacity='0.1' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.8699 18.4167C20.1795 18.4167 19.6199 18.9764 19.6199 19.6667V33.6667C19.6199 34.3571 20.1795 34.9167 20.8699 34.9167H30.8699C31.5602 34.9167 32.1199 34.3571 32.1199 33.6667V23.4167H28.8699C27.9034 23.4167 27.1199 22.6332 27.1199 21.6667V18.4167H20.8699ZM28.6199 19.4774L31.0592 21.9167H28.8699C28.7318 21.9167 28.6199 21.8048 28.6199 21.6667V19.4774ZM18.1199 19.6667C18.1199 18.148 19.3511 16.9167 20.8699 16.9167H27.8699C28.0688 16.9167 28.2596 16.9958 28.4002 17.1364L33.4002 22.1364C33.5409 22.2771 33.6199 22.4678 33.6199 22.6667V33.6667C33.6199 35.1855 32.3887 36.4167 30.8699 36.4167H20.8699C19.3511 36.4167 18.1199 35.1855 18.1199 33.6667V19.6667ZM22.1199 23.6667C22.1199 23.2525 22.4557 22.9167 22.8699 22.9167H23.8699C24.2841 22.9167 24.6199 23.2525 24.6199 23.6667C24.6199 24.081 24.2841 24.4167 23.8699 24.4167H22.8699C22.4557 24.4167 22.1199 24.081 22.1199 23.6667ZM22.1199 27.6667C22.1199 27.2525 22.4557 26.9167 22.8699 26.9167H28.8699C29.2841 26.9167 29.6199 27.2525 29.6199 27.6667C29.6199 28.081 29.2841 28.4167 28.8699 28.4167H22.8699C22.4557 28.4167 22.1199 28.081 22.1199 27.6667ZM22.1199 31.6667C22.1199 31.2525 22.4557 30.9167 22.8699 30.9167H28.8699C29.2841 30.9167 29.6199 31.2525 29.6199 31.6667C29.6199 32.081 29.2841 32.4167 28.8699 32.4167H22.8699C22.4557 32.4167 22.1199 32.081 22.1199 31.6667Z'
        fill='black'
      />
    </svg>
  );
}
