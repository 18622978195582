import { styled, tokens } from '@ai21/studio-base-ui';
import { Link as LinkMui, Typography } from '@ai21/studio-base-ui';
import { Breadcrumbs } from '@ai21/studio-base-ui';

export const BreadcrumbsMui = styled(Breadcrumbs)``;

export const Link = styled(LinkMui)`
  cursor: pointer;
  color: ${tokens.primitives.coal[400]};
`;

export const Text = styled(Typography)`
  color: ${tokens.primitives.coal[400]};
`;
