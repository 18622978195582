import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

type ToastBarContainerProps = {};

export function Toast(_props: ToastBarContainerProps) {
  return (
    <StyledToastContainer
      // position={toast.POSITION.BOTTOM_CENTER}
      style={{ zIndex: 999999 }}
      theme='dark'
      position='bottom-center'
      autoClose={1800}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      toastStyle={{ borderRadius: '8px' }}
      icon={true}
    />
  );
}

const StyledToastContainer = styled(ToastContainer)`
  // left: 460px;
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    --toastify-color-light: #fff;
    --toastify-color-dark: #132429;
    --toastify-color-info: #C1B6FC;
    --toastify-color-success: #8be9a4;
    --toastify-color-warning: #f8df60;
    --toastify-color-error: #f0675f;
    --toastify-icon-color-info: #C1B6FC;
    --toastify-icon-color-success: #8be9a4;
    --toastify-icon-color-warning: #f8df60;
    --toastify-icon-color-error: #f0675f;
    --toastify-font-family: font-family: "Inter", sans-serif;
  }
  .Toastify__progress-bar {
    --toastify-color-progress-info: #C1B6FC;
    --toastify-color-progress-success: #8be9a4;
    --toastify-color-progress-warning: #f8df60;
    --toastify-color-progress-error: #f0675f;
  }
`;
