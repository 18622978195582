import React from 'react';
import { Wrapper } from './AppScreenshots.style';
import { IApp } from '@ai21/studio-store';
import { Carousel } from '@ai21/studio-ui';

export type AppScreenshotsProps = {
  app: IApp;
};

export function AppScreenshots(props: AppScreenshotsProps) {
  const { app } = props;
  const { screenshots } = app;

  return (
    <Wrapper
      className='AppScreenshots-wrapper'
      data-testid='AppScreenshots-wrapper'
    >
      <Carousel items={screenshots} />
    </Wrapper>
  );
}

export default AppScreenshots;
