import { actions, calcIsEvaluated, selectors } from '@ai21/studio-store';
import { call, delay, put, select, takeEvery } from 'saga-ts';
import { Json } from '../types';
import { calculateReasonsDelta } from '../utils/reasons';
import { customEvenChannel } from './channels/channel.customEvent';
import { analytics } from './helpers/analytics';
import { predicateCurrentId } from './predicates';
import { refreshJobStats } from './saga.evaluationJobs';

type Verb =
  | 'select' //
  | 'previous'
  | 'next'
  | 'clear'
  | 'evaluate'
  | 'evaluateTags'
  | 'evaluateComment'
  | 'copy'
  | 'toggleLock'
  | 'closeDrawer';

type ActionJob = {
  type: 'EVALUATION_INBOX';
  verb: Verb;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  select: selectItem,
  previous: previous,
  next: next,
  clear: clearScore,
  evaluate: evaluate,
  evaluateTags: evaluateTags,
  evaluateComment: evaluateComment,
  copy: copyToClipboard,
  closeDrawer: closeDrawer,
  toggleLock: toggleLock,
};

function* selectItem(action: ActionJob) {
  const { params = {} } = action;

  if (!params?.id) {
    return;
  }

  const { id } = params as EvaluationParams;

  yield put(
    actions.currentIds.patch({
      evaluationLineId: id as string,
    })
  );
}

function* previous(action: ActionJob) {
  const { params = {} } = action;
  const { keyboard } = params as EvaluationParams;

  const jobInfo = yield* select(selectors.evaluation.$evaluationJobInfo);

  const { previousDot } = jobInfo;

  if (!previousDot) {
    return;
  }

  yield* call(analytics, {
    action: 'navigate',
    actionValue: 'previous',
    eventId: 'EvaluationNavigationPressed',
    extra: {
      keyboard: keyboard === true,
    },
  });

  yield put(
    actions.currentIds.patch({
      evaluationLineId: previousDot.id as string,
    })
  );
}

function* lockCurrentLine(_action: ActionJob) {
  const line = yield* select(selectors.evaluation.$evaluationLine);
  const job = yield* select(selectors.evaluation.$evaluationJob);

  if (!line || !job) {
    return;
  }

  if (!calcIsEvaluated(line?.quality) && !line.isLocked) {
    return;
  }

  yield put(
    actions.evaluationLines.patch(line.id, {
      isLocked: true,
      setId: job.id,
    })
  );
}

function* next(action: ActionJob) {
  const { params = {} } = action;
  const { keyboard } = params as EvaluationParams;

  const jobInfo = yield* select(selectors.evaluation.$evaluationJobInfo);

  const { nextDot } = jobInfo;

  if (!nextDot) {
    return;
  }

  yield* call(analytics, {
    action: 'navigate',
    actionValue: 'next',
    eventId: 'EvaluationNavigationPressed',
    extra: {
      keyboard: keyboard === true,
    },
  });

  yield call(lockCurrentLine, action);

  yield put(
    actions.currentIds.patch({
      evaluationLineId: nextDot.id as string,
    })
  );
}

function* skip(action: ActionJob) {
  const { params = {} } = action;
  const { keyboard } = params as EvaluationParams;

  const jobInfo = yield* select(selectors.evaluation.$evaluationJobInfo);

  const { nextDot } = jobInfo;

  if (!nextDot) {
    return;
  }

  yield* call(analytics, {
    action: 'navigate',
    actionValue: 'skip',
    eventId: 'EvaluationSkipped',
    extra: {
      keyboard: keyboard === true,
    },
  });

  yield call(lockCurrentLine, action);
}

function* clearScore(action: ActionJob) {
  const { params = {} } = action;
  const { keyboard } = params as EvaluationParams;
  const job = yield* select(selectors.evaluation.$evaluationJob);
  const lineId = yield* select(selectors.evaluation.$evaluationLineId);

  if (!lineId || !job) {
    return;
  }

  yield* call(analytics, {
    action: 'unEvaluate',
    actionValue: '',
    eventId: 'RevertEvaluation',
    extra: {
      keyboard: keyboard === true,
    },
  });

  yield put(
    actions.evaluationLines.patch(lineId, {
      status: 'Evaluated',
      quality: {
        value: '' as any,
        tags: [],
      },
      setId: job.id,
    })
  );

  yield delay(10);
  yield call(refreshJobStats);
}

function* closeDrawer(_action: ActionJob) {
  yield* call(analytics, {
    action: 'closeDrawer',
    actionValue: '',
    eventId: 'EvaluationDrawerClosed',
  });

  yield put(
    actions.currentIds.patch({
      evaluationLineId: '',
    })
  );
}

type EvaluationParams = {
  id: string;
  score?: number;
  tags?: string[];
  comment?: string;
  lineId?: string;
  keyboard: boolean;
};

function* toggleLock(action: ActionJob) {
  const { params = {} } = action;
  const { lineId } = params as EvaluationParams;
  const line = yield* select(selectors.singles.$evaluationLine, lineId);
  const job = yield* select(selectors.evaluation.$evaluationJob);

  if (!line || !job) {
    return;
  }

  yield put(
    actions.evaluationLines.patch(line.id, {
      isLocked: !line.isLocked,
      setId: job.id,
    })
  );
}

function* evaluate(action: ActionJob) {
  const { params = {} } = action;
  const { score, lineId, keyboard } = params as EvaluationParams;
  const line = yield* select(selectors.singles.$evaluationLine, lineId);
  const job = yield* select(selectors.evaluation.$evaluationJob);
  const user = yield* select(selectors.raw.$rawUser);

  if (!line || !job || !user || score === undefined) {
    return;
  }

  if (line.quality?.value === score) {
    return;
  }

  yield* call(analytics, {
    action: 'evaluate',
    actionValue: String(score),
    eventId: 'EvaluationScorePressed',
    extra: {
      keyboard: keyboard === true,
    },
  });

  const isSkipped = score === -1;

  yield put(
    actions.evaluationLines.patch(line.id, {
      status: 'Evaluated',
      quality: {
        value: score,
        tags: [],
        timestamp: Date.now(),
      },
      setId: job.id,
      evaluator: user.userName,
      isSkipped,
    })
  );

  yield delay(10);
  yield call(refreshJobStats);

  if (isSkipped) {
    yield call(skip, action);
  }
}

function* evaluateTags(action: ActionJob) {
  const { params = {} } = action;
  const { tags, lineId } = params as EvaluationParams;

  const line = yield* select(selectors.singles.$evaluationLine, lineId);
  const job = yield* select(selectors.evaluation.$evaluationJob);
  const user = yield* select(selectors.raw.$rawUser);

  if (!line || !tags || !job || !user) {
    return;
  }

  const { quality } = line;

  const reasonsDelta = calculateReasonsDelta(tags, quality?.tags);

  const currentCount = line.quality?.tags?.length ?? 0;
  const newCount = tags.length;
  const isAddition = newCount > currentCount;

  yield* call(analytics, {
    action: 'reasons',
    actionValue: reasonsDelta.join(' '), // TODO: add new reasons
    eventId: isAddition ? 'EvaluationReasonAdded' : 'EvaluationReasonRemoved',
  });

  yield put(
    actions.evaluationLines.patch(line.id, {
      quality: {
        ...line.quality,
        tags,
        timestamp: Date.now(),
      } as any,
      setId: job.id,
      evaluator: user.userName,
    })
  );
}

function* evaluateComment(action: ActionJob) {
  const { params = {} } = action;
  const { comment, lineId } = params as EvaluationParams;
  const line = yield* select(selectors.singles.$evaluationLine, lineId);
  const job = yield* select(selectors.evaluation.$evaluationJob);
  const user = yield* select(selectors.raw.$rawUser);

  if (!line || comment === undefined || !job || !user) {
    return;
  }

  if (line?.comment === comment) {
    return;
  }

  yield* call(analytics, {
    action: 'comment',
    actionValue: String(comment.length),
    eventId: 'EvaluationCommentChanged',
  });

  yield put(
    actions.evaluationLines.patch(line.id, {
      comment,
      setId: job.id,
      evaluator: user.userName,
    })
  );
}

function* copyToClipboard(action: ActionJob) {
  const { params = {} } = action;
  const { message = 'Copied to clipboard' } = params ?? {};

  // toast.show(message);
}

export function* evaluationAction(action: ActionJob) {
  const { verb } = action;

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* onLineChange(action: any) {
  const { payload } = action;
  const { evaluationLineId } = payload;

  document.location.hash = evaluationLineId;
}

export function* restoreIdFromDocumentHash() {
  const hash = document.location.hash;

  if (!hash) {
    return;
  }

  yield put(
    actions.currentIds.patch({
      evaluationLineId: hash.slice(1),
    })
  );
}

export function* fullScreenChange(action: any) {
  const { data } = action;
  const { isFullscreen } = data ?? {};

  yield* call(analytics, {
    action: 'fullscreen',
    actionValue: isFullscreen,
    eventId: 'EvaluationFullscreenPressed',
  });
}

export function* openInitialJobSettings(event: any) {
  const { data } = event;
  const { jobId } = data;

  yield delay(500);

  yield put({
    type: 'EVALUATION_JOB',
    verb: 'settings',
    id: jobId,
  });
}

export function* root() {
  yield takeEvery('EVALUATION_INBOX', evaluationAction);

  // we change the hash if evaluationLineId changes
  yield takeEvery(predicateCurrentId('evaluationLineId'), onLineChange);

  // we restore the evaluationLineId from the hash
  // yield fork(restoreIdFromDocumentHash);

  const channel = customEvenChannel('evaluate_drawer_fullscreen');
  yield takeEvery(channel, fullScreenChange);

  const channel2 = customEvenChannel('evaluation/settings');
  yield takeEvery(channel2, openInitialJobSettings);
}
