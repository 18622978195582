import { DataGridPro, GridSortModel } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import classnames from 'classnames';
import { useContext, useMemo } from 'react';
import { TableContext, TableContextProvider } from '../../context/Table.context';
import { ITableConfig } from '../../types';
import { TableEmpty } from '../TableEmpty/TableEmpty';
import { TableTop } from '../TableTop/TableTop';
import { useColumns } from './Table.hooks';
import { TableSection, Wrapper } from './Table.style';
import { setTableClassName } from './Table.utils';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X);

export type TableProps = {
  config: ITableConfig;
  data: Json[];
  callbacks: {
    onTableAction: (tableId: string, actionType: string, params?: Json) => void;
    onRowSelectionChange: (selectedRowIds: string[]) => void;
    onRowAction: (tableId: string, itemId: string, actionType: string) => void;
  };
  selectionMode?: boolean;
  checkboxSelection?: boolean;
  selectedRowsIds?: string[];
  isLoading?: boolean;
  cta?: React.ReactNode;
  children?: React.ReactNode;
  noPadding?: boolean;
  hideFooter?: boolean;
};

export function TableInner(props: TableProps) {
  const {
    config,
    callbacks,
    selectionMode,
    cta,
    isLoading,
    noPadding,
    hideFooter,
    checkboxSelection,
    selectedRowsIds,
  } = props;
  const { id: tableId, header, emptyState, rowActions = [] } = config;
  const { data, state, callbacks: tableCallbacks } = useContext(TableContext);
  const { isFiltered } = state;

  const isEmpty = data.length === 0;

  function renderEmpty() {
    if (!isEmpty || isLoading) {
      return null;
    }

    return (
      <TableEmpty
        emptyState={emptyState}
        onCta={(actionId) => callbacks.onTableAction(tableId, actionId)}
        isFiltered={isFiltered}
      />
    );
  }

  function onRowAction(itemId: string, actionType: string) {
    callbacks.onRowAction(tableId, itemId, actionType);
  }

  const className = classnames({
    noPadding,
    empty: isEmpty,
  });

  const classNameTable = classnames('Table-wrapper', {
    selectionMode,
    hideFooter: hideFooter || isEmpty,
  });

  const columns = useColumns(config, onRowAction);

  const tableInitialState = useMemo(() => {
    const { fields, sort } = config;
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    const hasActions = rowActions.length > 0;

    return {
      pinnedColumns: {
        left: isMobile
          ? []
          : ['__check__', ...fields.filter((field) => field.isPinned).map((field) => field.id)],
        right: hasActions ? ['actions'] : [],
      },
      sorting: {
        sortModel: [
          {
            field: sort?.defaultField ?? '',
            sort: (sort?.defaultDirection ?? '') as any,
          },
        ],
      },
      pagination: { paginationModel: { pageSize: config.pagination?.itemsPerPage ?? 100 } },
    };
  }, [config]);

  const slotProps = useMemo(
    () => ({
      row: {
        onMouseOver: (ev: React.MouseEvent) => {
          const dataId = ev.currentTarget.getAttribute('data-id');
          setTableClassName(ev, dataId ?? '');
        },
        onMouseLeave: (ev: React.MouseEvent) => {
          setTableClassName(ev, '');
        },
        onClick: (ev: React.MouseEvent) => {
          if (!selectionMode) {
            return;
          }
          const dataId = ev.currentTarget.getAttribute('data-id');
          callbacks.onRowAction(tableId, dataId ?? '', 'select');
        },
      },
    }),
    []
  );

  function onSortModelChange(model: GridSortModel) {
    tableCallbacks.onSort(model as any);
  }

  return (
    <TableSection data-testid={`Table-${tableId}`} className={`Table-${tableId} ${className}`}>
      <TableTop
        header={header}
        onTableAction={(actionId: string, params?: Json) =>
          callbacks.onTableAction(tableId, actionId, params)
        }
        cta={cta}
      >
        {props.children}
      </TableTop>
      <Wrapper className={classNameTable} data-testid='Table-wrapper'>
        <DataGridPro
          rows={data}
          columns={columns}
          hideFooter={hideFooter}
          density='standard'
          disableRowSelectionOnClick
          columnBuffer={2}
          columnThreshold={2}
          sortingMode='server'
          initialState={tableInitialState}
          rowSelectionModel={selectedRowsIds}
          onRowSelectionModelChange={(models) => {
            props.callbacks.onRowSelectionChange(models);
          }}
          onSortModelChange={onSortModelChange}
          slotProps={slotProps}
          pagination={true}
          pageSizeOptions={[]}
          checkboxSelection={checkboxSelection}
          checkboxSelectionVisibleOnly
        />
      </Wrapper>

      {renderEmpty()}
    </TableSection>
  );
}

export function Table(props: TableProps) {
  const { config, data } = props;

  return (
    <TableContextProvider config={config} data={data}>
      <TableInner {...props} />
    </TableContextProvider>
  );
}

export default Table;
