import { call, takeEvery } from 'saga-ts';
import { firebase } from '../utils/globals';
import { navigateToForgotPasswordCheck } from './helpers/navigation';

type ActionResetPassword = {
  type: 'RESET_PASSWORD';
  params: {
    email: string;
  };
  resolve: () => void;
  reject: () => void;
};

export function* resetPassword(action: ActionResetPassword) {
  const { params, resolve, reject } = action;
  const { email } = params;

  const response: any = yield* call(firebase.sendPasswordResetEmail, email);

  resolve();

  yield call(navigateToForgotPasswordCheck);
}

export function* root() {
  yield takeEvery('RESET_PASSWORD', resetPassword);
}
