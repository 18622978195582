export function CodeIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='6' fill='white' />
      <rect width='52' height='52' rx='6' fill='#65AE59' fillOpacity='0.1' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.0518 17.9391C28.4536 18.0396 28.6979 18.4468 28.5975 18.8486L24.5975 34.8486C24.497 35.2505 24.0898 35.4948 23.688 35.3944C23.2861 35.2939 23.0418 34.8867 23.1423 34.4848L27.1423 18.4848C27.2427 18.083 27.6499 17.8387 28.0518 17.9391ZM21.4002 22.1364C21.6931 22.4293 21.6931 22.9042 21.4002 23.1971L17.9305 26.6667L21.4002 30.1364C21.6931 30.4293 21.6931 30.9042 21.4002 31.1971C21.1073 31.49 20.6324 31.49 20.3395 31.1971L16.3395 27.1971C16.0466 26.9042 16.0466 26.4293 16.3395 26.1364L20.3395 22.1364C20.6324 21.8435 21.1073 21.8435 21.4002 22.1364ZM30.3395 22.1364C30.6324 21.8435 31.1073 21.8435 31.4002 22.1364L35.4002 26.1364C35.6931 26.4293 35.6931 26.9042 35.4002 27.1971L31.4002 31.1971C31.1073 31.49 30.6324 31.49 30.3395 31.1971C30.0466 30.9042 30.0466 30.4293 30.3395 30.1364L33.8092 26.6667L30.3395 23.1971C30.0466 22.9042 30.0466 22.4293 30.3395 22.1364Z'
        fill='black'
      />
    </svg>
  );
}
