import { put, takeEvery } from 'saga-ts';
import { DELETE_PRESET_SUCCESS } from '../redux/actionTypes/presetActionTypes';
import { setIdentity } from '@ai21/studio-analytics';

/*
  This file is TEMPORARY!
  It will be deleted after conVisit *or* auto-analytics is implemented
*/

export function* analyticsPresetsDelete(action: any) {
  const { preset } = action;

  const { name } = preset;

  const origin = getOrigin();

  yield* put({
    type: DELETE_PRESET_SUCCESS,
    origin,
    name,
    prompt: preset.text,
  });
}

function getOrigin() {
  const { pathname } = document.location;

  if (pathname.startsWith('/overview')) {
    return 'overviewPage';
  }

  if (pathname.startsWith('/playground')) {
    return 'playgroundPage';
  }
}

export function* setAnalyticsIdentity(action: any) {
  const { organizationId, userId, userRole, userTier, isNewUser } = action.payload;

  setIdentity({
    userId,
    organizationId,
    organizationPlanType: userTier,
    userRole,
    isNewUser,
  });
}

export function* root() {
  yield takeEvery('SET_USER', setAnalyticsIdentity);
  yield takeEvery('ANALYTICS_PRESET_DELETED', analyticsPresetsDelete);
}
