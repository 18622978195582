import { Menu as MenuMui, Typography } from '@mui/material';
import classnames from 'classnames';
import { IMenuItem } from '../../types';
import Icon from '../Icon/Icon';
import { Action, ListIcon } from './Menu.style';

export type MenuProps = {
  items?: IMenuItem[];
  onClick: (item: IMenuItem) => void;
  handleClose: () => void;
  anchorEl: Element | null;
};

export function Menu(props: MenuProps) {
  const { items = [], anchorEl } = props;

  if (!anchorEl) {
    return null;
  }

  function renderAction(item: IMenuItem) {
    const { id, title, iconName } = item;

    const className = classnames(id);

    return (
      <Action
        key={id}
        className={className}
        onClick={() => props.onClick(item)}
        data-testid={`pop-menu-item-${id}`}
      >
        <ListIcon>
          <Icon iconName={iconName} />
        </ListIcon>
        <Typography variant='body1' style={{ display: 'flex' }}>
          {title}
        </Typography>
      </Action>
    );
  }

  function renderActions() {
    return (
      <MenuMui
        id='menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: '30px' }}
        keepMounted
        open={anchorEl !== null}
        onClose={props.handleClose}
      >
        {items.map((action: IMenuItem) => renderAction(action))}
      </MenuMui>
    );
  }

  return <>{renderActions()}</>;
}

export default Menu;
