import { styled } from '@ai21/studio-base-ui';
import { layout, Typography } from '../..';

export const Wrapper = styled.div`
  flex: 1;
  color: #616262;
  font-size: 14px;
`;

export const Container = styled(layout.Container)`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 40px;
  padding: 20px 0 16px 0;
  display: flex;
  flex-direction: row;
`;

export const CompanyName = styled(Typography)`
  display: flex;
  flex-direction: row;
`;

export const Version = styled.span`
  color: #ccc;
  padding: 0 7px;
`;

export const Gap = styled.div`
  flex: 1;
`;

export const A = styled.a`
  color: #616262;

  &::after {
    content: ' | ';
    display: inline-block;
    margin: 0 8px;
  }

  &:last-child::after {
    display: none;
  }
`;
