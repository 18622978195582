import { api } from '@ai21/studio-api';
import { Form } from '@ai21/studio-forms';
import { actions, selectors } from '@ai21/studio-store';
import { PincodeShare, prompt, toast } from '@ai21/studio-ui';
import { call, delay, put, select, takeEvery } from 'saga-ts';
import { guid4, guid8 } from 'shared-base';
import { formDefaults, forms } from '../_definitions/forms';
import { Json } from '../types';
import { generatePincode } from '../utils/utils';

type Verb =
  | 'new'
  | 'delete'
  | 'sharePincode'
  | 'validatePincode'
  | 'copyId'
  | 'view'
  | 'showDocLibrary'
  | 'settings'
  | 'start'
  | 'share'
  | 'navigate';

type ActionDemo = {
  type: 'DEMO';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  new: newDemo,
  delete: deleteDemo,
  sharePincode: sharePincode,
  copyId: copyId,
  settings: openDemoSettings,
  showDocLibrary: showDocLibrary,
  view: viewDemo,
  start: start,
  share: share,
  navigate: navigate,
};

function* share(action: ActionDemo) {
  const demo = yield* select(selectors.singles.$demo, action.id);

  if (!demo) {
    return;
  }

  const { company = 'generic' } = demo;
  const url = `${window.__env__.VITE_APP_SERVING_URL || 'https://studio.ai21.com'}/demo/${company.toLowerCase()}/${action.id}`; // prettier-ignore

  const newUrl = url ? url.replaceAll(' ', '%20') : url;
  navigator.clipboard.writeText(newUrl);
  toast.show('Copied to clipboard', { type: 'success' });
}

function* newDemo(action: ActionDemo) {
  const allOptions = yield* select(selectors.options.$allOptions);
  const user = yield* select(selectors.raw.$rawUser);

  const newDemoId = guid8();

  const { didCancel, value } = yield prompt.form({
    component: Form,
    title: 'Create a demo',
    form: {
      config: forms.demo,
      data: {
        creator: user.userName,
        name: `Demo #${guid4()}`,
        ...formDefaults.demo,
        id: newDemoId,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
    },
  });

  if (didCancel) {
    return;
  }

  const { id, name, company, params, theme, isPrivate } = value;

  if (isPrivate) {
    const pincode = yield* call(createPincode, id);
    if (!pincode) {
      return;
    }
    navigator.clipboard.writeText(pincode as any);
    toast.show('Pin code copied to clipboard', { type: 'success' });
  }

  yield put(
    actions.demos.add({
      id,
      name,
      company,
      isPrivate,
      theme,
      params,
      flavour: 'contextual_answers',
      creator: user.userName,
      dateCreated: Date.now(),
    } as any)
  );
}

function* createPincode(id: string) {
  const pin = generatePincode();
  const response = yield* call(api.pincode.create, {
    demoId: id,
    pinCode: pin,
  });
  if (!response.isSuccess) {
    toast.show(
      "something went wrong, can't create pincode please try again later",
      { type: 'error' }
    );
    return null;
  }
  return pin;
}

function* start(action: ActionDemo) {
  const { id } = action;

  const demo = yield* select(selectors.singles.$demo, id);

  if (!demo) {
    return;
  }
}

function* showDocLibrary(action: ActionDemo) {
  yield put({
    type: 'DOCUMENT',
    verb: 'openDrawer',
  });
}

function* viewDemo(action: ActionDemo) {
  const demo = yield* select(selectors.singles.$demo, action.id);

  if (!demo) {
    return;
  }

  const { company = 'generic' } = demo;

  window.open(`/demo/${company.toLowerCase()}/${action.id}`, '_blank');
}

function* navigate(action: ActionDemo) {
  const { to } = action.params || {};

  yield put({
    type: 'NAVIGATE',
    to,
  });
}

function* deleteDemo(action: ActionDemo) {
  const { id } = action;

  const demo = yield* select(selectors.singles.$demo, id);
  const { name, isPrivate } = demo;

  const { didCancel } = yield prompt.confirm({
    title: 'Delete demo',
    description: `Are you sure you want to delete demo "${name}"?`,
    ctaButtonText: 'Delete Demo',
    intention: 'delete',
  });

  if (didCancel) {
    return;
  }

  if (isPrivate) {
    yield* call(api.pincode.remove, id);
  }

  yield put(
    actions.demos.patch(id, {
      isDeleted: true,
    })
  );

  if (document.location.pathname.includes('lines')) {
    yield put({
      type: 'NAVIGATE',
      to: '/demos',
    });
  }
}

function* sharePincode(action: ActionDemo) {
  const { id } = action;

  const demo = yield* select(selectors.singles.$demo, id);

  const { name, isPrivate } = demo;

  if (!isPrivate) {
    toast.show('Pin code not set', {
      type: 'success',
    });
    return;
  }

  const response = yield* call(api.pincode.get);
  if (!response.isSuccess) {
    toast.show('something went wrong, please try again later', {
      type: 'error',
    });
    return;
  }
  const data = response.data;

  const pincodes = Object.values(data).filter((item: any) => {
    return item.demoId === id;
  });
  if (pincodes.length > 0) {
    const pincode = pincodes[0].pinCode;
    const { didCancel } = yield prompt.custom({
      title: 'Share Pin Code',
      component: PincodeShare,
      componentProps: {
        code: pincode,
      },
    });

    if (didCancel) {
      return;
    }
    navigator.clipboard.writeText(pincode);
    toast.show('Pin code copied to clipboard', { type: 'success' });
  } else {
    toast.show('Something went wrong, please try again later', 'success');
  }
}

function* copyId(action: ActionDemo) {
  const { id } = action;
  navigator.clipboard.writeText(id);
  toast.show('Copied to clipboard', { type: 'success' });
}

export function* openDemoSettings(action: ActionDemo) {
  const { id } = action;
  const allOptions = yield* select(selectors.options.$allOptions);
  const demo = yield* select(selectors.singles.$demo, id);

  if (!demo) {
    return;
  }
  if (!demo.isPrivate) {
    demo.isPrivate = false;
  }

  const { didCancel, value } = yield prompt.form({
    component: Form,
    title: 'Edit your demo',
    form: {
      config: forms.demoEdit,
      data: {
        ...demo,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
      isEdit: true,
    },
  });

  if (didCancel) {
    return;
  }
  const { isPrivate } = value;
  if (isPrivate !== undefined) {
    if (isPrivate) {
      const pincode = yield* call(createPincode, id);
      if (!pincode) {
        return;
      }
    } else {
      const response = yield* call(api.pincode.remove, id);
      if (!response.isSuccess) {
        toast.show(
          "something went wrong, can't save changes please try again later",
          { type: 'error' }
        );
        return;
      }
    }
  }
  yield put(actions.demos.patch(id, value));

  toast.show('Demo settings updated', 'success');
}

export function* demos(action: ActionDemo) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('DEMOS', demos);
}
