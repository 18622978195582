import { Json } from '../types';

export const toBoxId = (boxId: string, boxSuffix: string) => {
  const parts = boxId.split('-');
  parts.pop();
  parts.push(boxSuffix);
  return parts.join('-');
};

const generationParamsFields = [
  'maxTokens',
  'temperature',
  'topP',
  'presencePenalty',
  'frequencyPenalty',
  'countPenalty',
  'modelId',
  'stopSequences',
  'epoch',
];

const presetDefault = window.__env__.VITE_PRESET_DEFAULT;

const defaultValues = {
  maxTokens: 64,
  temperature: 0.0,
  topP: 1.0,
  presencePenalty: 0.0,
  frequencyPenalty: 0.0,
  countPenalty: 0.0,
  modelId: presetDefault,
  stopSequences: [],
  epoch: '0',
};

export const getGenerationParams = (values: Json) => {
  const params: Json = { ...defaultValues };

  generationParamsFields.forEach((field) => {
    if (values[field] !== undefined) {
      params[field] = values[field];
    }
  });

  return params;
};

export const checkEmptyPrompt = (prompt: string) => {
  const isEmpty =
    prompt.replace(/\n/g, '').replace(/\\n/g, '').trim().length === 0;
  return isEmpty;
};
