import { Icon } from '@ai21/studio-ui';
import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { Title } from '../../components/Header/Header.style';
import {
  Document,
  DocumentLibraryTitle,
  Documents,
  DocumentsPreview,
  DocumentsPreviewTitle,
  Preview,
  PreviewEmptyState,
  Wrapper,
} from './DocumentLibrarySection.style';

export type DocumentLibrarySectionProps = {
  documents: Json[];
  selectedDocuments: string[];
  handleSelecteDocuemnt: (id: string) => void;
  callbacks: {
    onClear: () => void;
  };
};

export function DocumentLibrarySection(props: DocumentLibrarySectionProps) {
  const { documents, callbacks, selectedDocuments, handleSelecteDocuemnt } =
    props;

  function renderOption(document: Json) {
    const { id, text, selected } = document;

    return (
      <Document key={id}>
        <Checkbox
          checked={selectedDocuments?.includes(id)}
          onChange={() => handleSelecteDocuemnt(id)}
        />
        {text}
      </Document>
    );
  }

  function renderPreview(document: Json) {
    const { path, id } = document;
    if (selectedDocuments?.includes(id)) {
      return (
        <Preview key={`docPreviewimage-${id}`}>
          <img width={360} src={path} />
        </Preview>
      );
    }

    return null;
  }

  const renderDocumentsList = () => {
    return documents.map((document: Json) => renderOption(document));
  };

  const renderDocumentsPreview = () => {
    if (selectedDocuments?.length > 0) {
      return documents.map((document: Json) => renderPreview(document));
    }

    return (
      <PreviewEmptyState>
        <Icon iconName='QuestionMarkIcon' />
        Select the document to query from the list above and view the document
        in this window.
      </PreviewEmptyState>
    );
  };

  return (
    <Wrapper>
      <Documents>
        <DocumentLibraryTitle>
          <Icon iconName='File' />
          RAG Engine
        </DocumentLibraryTitle>
        {renderDocumentsList()}
      </Documents>
      <DocumentsPreviewTitle>Document Preview</DocumentsPreviewTitle>

      <DocumentsPreview> {renderDocumentsPreview()}</DocumentsPreview>
    </Wrapper>
  );
}
