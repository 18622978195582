import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMemo } from 'react';
import { IntercomProvider } from 'react-use-intercom';

let stripe: any;

const options = {
  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans' }],
};

const isTest = window.__env__.NODE_ENV === 'test';

if (!isTest) {
  stripe = loadStripe(window.__env__.VITE_STRIPE_PUBKEY!);
}

export type ThirdPartiesProviderProps = {
  children: any;
  stripeEnabled: boolean;
  intercomEnabled: boolean;
};

export const ThirdPartiesProvider = (props: ThirdPartiesProviderProps) => {
  const { stripeEnabled, intercomEnabled } = props;

  const jsx = useMemo(() => {
    let output = <>{props.children}</>;

    if (stripeEnabled) {
      output = (
        <Elements stripe={stripe} options={options}>
          {output}
        </Elements>
      );
    }

    return (
      <IntercomProvider
        appId={window.__env__.VITE_INTERCOM_APP_ID ?? ''}
        autoBoot={intercomEnabled}
        shouldInitialize={intercomEnabled}
      >
        {output}
      </IntercomProvider>
    );
  }, [stripeEnabled, intercomEnabled]);

  return jsx;
};

export const initGoogleAnalytics = () => {
  let newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.setAttribute('async', 'true');
  var trackingId = window.__env__.VITE_GA_ID;

  if (!trackingId) {
    return;
  }

  newScript.setAttribute('src', 'https://www.googletagmanager.com/gtm.js?id=' + trackingId);
  document.head.appendChild(newScript);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  window.gtag('js', new Date());
  window.gtag('config', trackingId);
};

if (!window.gtag) {
  initGoogleAnalytics();
}
