import React from 'react';
import {
  Wrapper,
  Image,
  Arrows,
  Arrow,
  CarouselWrapper,
} from './Carousel.style';
import classnames from 'classnames';

export type CarouselProps = {
  items: string[];
};

export function Carousel(props: CarouselProps) {
  const { items } = props;

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const mobileClassName = classnames({ mobile: isMobile });

  const imageSize = isMobile ? 296 : 781;

  function renderItem(item: string) {
    const style: React.CSSProperties = {
      backgroundImage: `url(${item})`,
    };

    return (
      <Image key={item} className={`item ${mobileClassName}`} style={style} />
    );
  }

  function renderItems() {
    return items.map((item: string) => renderItem(item));
  }

  function scrollLeft() {
    document.getElementsByClassName('Carousel-items')[0].scrollLeft -=
      imageSize;

    document
      .getElementsByClassName('ArrowRight')[0]
      .classList.remove('disabled');

    if (document.getElementsByClassName('Carousel-items')[0].scrollLeft == 0) {
      document.getElementsByClassName('ArrowLeft')[0].classList.add('disabled');
    }
  }

  function scrollRight() {
    document.getElementsByClassName('Carousel-items')[0].scrollLeft +=
      imageSize;

    document
      .getElementsByClassName('ArrowLeft')[0]
      .classList.remove('disabled');

    if (
      document.getElementsByClassName('Carousel-items')[0].scrollWidth -
        document.getElementsByClassName('Carousel-items')[0].scrollLeft -
        document.getElementsByClassName('Carousel-items')[0].clientWidth ==
      0
    ) {
      document
        .getElementsByClassName('ArrowRight')[0]
        .classList.add('disabled');
    }
  }

  return (
    <Wrapper
      className={`Carousel-wrapper  ${mobileClassName}`}
      data-testid='Carousel-wrapper'
    >
      <CarouselWrapper className={`Carousel-items ${mobileClassName}`}>
        {renderItems()}
      </CarouselWrapper>
      {items && items.length > 1 && (
        <Arrows>
          <Arrow
            className='ArrowLeft disabled'
            iconName='ArrowNarrowLeft'
            onClick={scrollLeft}
          />
          <Arrow
            className='ArrowRight'
            iconName='ArrowNarrowRight'
            onClick={scrollRight}
          />
        </Arrows>
      )}
    </Wrapper>
  );
}

export default Carousel;
