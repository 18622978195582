export function CodeIcon() {
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.8183 0.726939C11.1531 0.810657 11.3567 1.14999 11.273 1.48486L7.93967 14.8182C7.85595 15.1531 7.51662 15.3567 7.18175 15.273C6.84688 15.1892 6.64328 14.8499 6.72699 14.515L10.0603 1.18169C10.144 0.846821 10.4834 0.643221 10.8183 0.726939ZM5.27528 4.22467C5.51935 4.46875 5.51935 4.86448 5.27528 5.10855L2.38388 7.99994L5.27528 10.8913C5.51935 11.1354 5.51935 11.5311 5.27528 11.7752C5.0312 12.0193 4.63547 12.0193 4.39139 11.7752L1.05806 8.44189C0.813981 8.19781 0.813981 7.80208 1.05806 7.558L4.39139 4.22467C4.63547 3.98059 5.0312 3.98059 5.27528 4.22467ZM12.7247 4.22467C12.9688 3.98059 13.3645 3.98059 13.6086 4.22467L16.9419 7.558C17.186 7.80208 17.186 8.19781 16.9419 8.44189L13.6086 11.7752C13.3645 12.0193 12.9688 12.0193 12.7247 11.7752C12.4806 11.5311 12.4806 11.1354 12.7247 10.8913L15.6161 7.99994L12.7247 5.10855C12.4806 4.86448 12.4806 4.46875 12.7247 4.22467Z'
      />
    </svg>
  );
}
