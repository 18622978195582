import { Icon } from '@ai21/studio-base-ui';
import { Cta, Dot, Wrapper } from './AddComment.style';

export type AddCommentProps = {
  comment?: string;
  onClick: () => void;
  isMac?: boolean;
};

export function AddComment(props: AddCommentProps) {
  const { comment, isMac } = props;
  const altKey = isMac ? '⌥' : 'Alt+';

  return (
    <Wrapper className='AddComment-wrapper' data-testid='AddComment-wrapper'>
      <Cta
        variant='outlined'
        color='secondary'
        size='large'
        startIcon={<Icon iconName='MessageDots' />}
        onClick={props.onClick}
        onShortKey={props.onClick}
        shortKey={{
          key: 'C',
          code: 'KeyC',
          preventDefault: true,
          withAlt: true,
        }}
      >
        Comment
      </Cta>
      {comment && <Dot />}
    </Wrapper>
  );
}

export default AddComment;
