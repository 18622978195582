type MockDataField = 'string' | 'date' | 'number';

const mockData: Record<string, MockDataField> = {
  id: 'string',
  name: 'string',
  model: 'string',
  requests: 'number',
  valueLoss: 'number',
  epochs: 'number',
  learningRate: 'number',
  dateAdded: 'date',
  progress: 'number',
};

const randomItem = () => {
  const data = Object.keys(mockData).reduce((acc, key) => {
    const type = mockData[key];
    if (type === 'string') {
      acc[key] = Math.random().toString(36).substring(2, 15);
    } else if (type === 'date') {
      acc[key] = randomDate().toISOString();
    } else if (type === 'number') {
      acc[key] = Math.floor(Math.random() * 100);
    }
    return acc;
  }, {} as Record<string, string | number>);

  return data;
};

const generateRandomData = (count: number) => {
  const data = Array.from({ length: count }, randomItem);
  return data;
};

const randomDate = () => {
  const now = new Date();
  const start = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());

  return new Date(start.getTime() + Math.random() * (now.getTime() - start.getTime()));
};

export const data = generateRandomData(100);
