import { actions, selectors } from '@ai21/studio-store';
import { drawer } from '@ai21/studio-ui';
import { delay, put, select, takeEvery } from 'saga-ts';
import { Json } from '../types';
import { predicateCurrentId } from './predicates';
import CompletionInspectorContainer from '../containers/CompletionInspector.container';

type Verb = 'select' | 'previous' | 'next';

type ActionJob = {
  type: 'GENERATION_LINE';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  select: openDrawer,
  previous: previousLine,
  next: nextLine,
};

export function* openDrawer(action: ActionJob) {
  const { id } = action;

  // resets the form
  yield put(actions.currentIds.patch({ generationLineId: '' }));

  yield put(
    actions.currentIds.patch({
      generationLineId: id,
    })
  );

  drawer.open({
    component: CompletionInspectorContainer,
  });
}
export function* previousLine(_action: ActionJob) {
  const jobInfo = yield* select(selectors.generation.$generationJobInfo);
  const { previousDot } = jobInfo;

  if (!previousDot) {
    return;
  }

  yield put(actions.currentIds.patch({ generationLineId: '' })); // refreshes the form
  yield put(
    actions.currentIds.patch({
      generationLineId: previousDot.id,
    })
  );
}

export function* nextLine(_action: ActionJob) {
  const jobInfo = yield* select(selectors.generation.$generationJobInfo);
  const { nextDot } = jobInfo;

  if (!nextDot) {
    return;
  }

  yield put(actions.currentIds.patch({ generationLineId: '' })); // refreshes the form
  yield put(
    actions.currentIds.patch({
      generationLineId: nextDot.id,
    })
  );
}

export function* onLineChange(action: any) {
  const { payload } = action;
  const { generationLineId } = payload;

  // document.location.hash = generationLineId;
}

export function* generationLine(action: ActionJob) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('GENERATION_LINE', generationLine);
  yield takeEvery(predicateCurrentId('generationLineId'), onLineChange);
}
