import styled from 'styled-components';

export const Wrapper = styled.span`
  margin-left: 4px;
  padding-top: 2px;

  span {
    display: flex;
    align-content: center;
  }

  svg {
    width: 14px;
    height: 14px;
    color: #626b78;
  }
`;
