import { initApiOffline } from '@ai21/studio-api';
import { delay, invokeEvent } from 'shared-base';
import './main.scss';

export const init = async () => {
  initApiOffline();

  await delay(1000);

  invokeEvent('FIREBASE_USER_READY', {
    displayName: 'Test User',
    email: 'test@ai21.com',
  });
};
