import type { IMenuGroup, IMenuItem } from '@ai21/studio-ui';
import { WebRoutes } from './Web.routes';

// 1st word capitalized, 2nd word lowercase
export const newSideBarItems: IMenuItem[] = [
  /////////////////////////
  // Account + sub menu
  {
    id: 'account',
    title: 'Settings',
    iconName: 'menu.Settings',
    groupId: 'newSidebar',
    isParent: true,
    order: 5,
  },
  {
    id: 'accountDetails',
    title: 'Account details',
    path: WebRoutes.accountDetails,
    groupId: 'newSidebar',
    parentId: 'account',
    featureFlag: '!navigate-to-new-account-pages',
    order: 5,
  },
  {
    id: 'organization',
    title: 'Organization',
    path: WebRoutes.organization,
    groupId: 'newSidebar',
    parentId: 'account',
    featureFlag: '!navigate-to-new-account-pages',
    order: 5,
  },
  {
    id: 'newOrganization',
    title: 'Organization',
    path: '/v2/account/organization',
    groupId: 'newSidebar',
    parentId: 'account',
    featureFlag: 'navigate-to-new-account-pages',
    order: 5,
  },
  {
    id: 'members',
    title: 'Members',
    path: '/v2/account/members',
    groupId: 'newSidebar',
    parentId: 'account',
    featureFlag: 'navigate-to-new-account-pages',
    order: 5,
  },
  {
    id: 'modelUsage',
    title: 'Usage',
    path: WebRoutes.modelUsage,
    groupId: 'newSidebar',
    parentId: 'account',
    order: 5,
  },
  {
    id: 'accountKeys',
    title: 'API key',
    path: WebRoutes.apiKey,
    groupId: 'newSidebar',
    parentId: 'account',
    order: 5,
    featureFlag: '!navigate-to-new-account-pages',
  },
  {
    id: 'newApiKey',
    title: 'API key',
    path: '/v2/account/api-key',
    groupId: 'newSidebar',
    parentId: 'account',
    order: 5,
    featureFlag: 'navigate-to-new-account-pages',
  },
  {
    id: 'billing',
    title: 'Plans & Billing',
    path: WebRoutes.billingPlans,
    groupId: 'newSidebar',
    order: 5,
    parentId: 'account',
  },
  {
    id: 'newAccount',
    title: 'My account',
    path: '/v2/account',
    groupId: 'newSidebar',
    parentId: 'account',
    featureFlag: 'navigate-to-new-account-pages',
    order: 5,
  },
  ////////////////////////////////////
  {
    id: 'integrations',
    title: 'Integrations',
    path: WebRoutes.connectors,
    groupId: 'newSidebar',
    iconName: 'Plug',
    featureFlag: 'enable-connectors-page',
    order: 3,
  },
];

export const sideBarItems: IMenuItem[] = [
  /////////////////////////
  // Account + sub menu
  {
    id: 'account',
    title: 'Settings',
    iconName: 'menu.Settings',
    groupId: 'account',
    isParent: true,
  },
  {
    id: 'accountDetails',
    title: 'Account details',
    path: WebRoutes.accountDetails,
    groupId: 'account',
    parentId: 'account',
  },
  {
    id: 'organization',
    title: 'Organization',
    path: WebRoutes.organization,
    groupId: 'account',
    parentId: 'account',
  },
  {
    id: 'modelUsage',
    title: 'Usage',
    path: WebRoutes.modelUsage,
    groupId: 'account',
    parentId: 'account',
  },
  {
    id: 'accountKeys',
    title: 'API key',
    path: WebRoutes.apiKey,
    groupId: 'account',
    parentId: 'account',
  },
  {
    id: 'billing',
    title: 'Plans & Billing',
    path: WebRoutes.billingPlans,
    groupId: 'account',
    parentId: 'account',
  },
  ////////////////////////////////////
  {
    id: 'integrations',
    title: 'Integrations',
    path: WebRoutes.connectors,
    groupId: 'assetsAndTools',
    iconName: 'Plug',
    featureFlag: 'enable-connectors-page',
    order: 2,
  },
  // More + sub menu
  {
    id: 'more',
    title: 'More',
    groupId: 'top',
    iconName: 'Box',
    isParent: true,
    order: 6,
    description: 'Available via API',
  },
  {
    id: 'semanticSearch',
    title: 'Semantic Search',
    groupId: 'top',
    href: 'https://docs.ai21.com/docs/semantic-search-api',
    parentId: 'more',
    order: 0,
  },
  {
    id: 'embeddings',
    title: 'Embeddings',
    groupId: 'top',
    href: 'https://docs.ai21.com/docs/embeddings-api',
    parentId: 'more',
    order: 1,
  },
  {
    id: 'textSegmentation',
    title: 'Text Segmentation',
    groupId: 'top',
    href: 'https://docs.ai21.com/docs/text-segmentation-api',
    parentId: 'more',
    order: 2,
  },
  ///////////////////////////
];

export const sideBarGroups: IMenuGroup[] = [
  {
    id: 'home',
    order: 0,
  },
  {
    id: 'assetsAndTools',
    title: 'Assets & Tools',
    order: 2,
  },
  { id: 'assets', title: 'DATASETS', order: 1 },
  { id: 'account', title: '', order: 3 },
];

export const hideMenuPaths = [
  'sandbox',
  'widgets',
  'login',
  'sign-up',
  'public',
  'check-email',
  'check-email-done',
  'forgot-password',
  'forgot-password-check',
  'forgot-password-done',
  'sso-login',
  'lp',
];
