import { Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
  max-height: calc(100vh - 133px);
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
`;

export const Empty = styled.div`
  display: flex;
  padding: 14px 16px;
  margin: 24px;
  margin-top: 113px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  background: ${tokens.primary.backgroundHover};

  svg {
    width: 24px;
    height: 24px;
    color: ${tokens.text.secondary};
    stroke-width: 1.5px;
  }
`;

export const Flex = styled.div`
  flex: 1;
`;

export const EmptyContent = styled.div`
  display: flex;
  padding: 0 0 0 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

export const EmptyTitle = styled(Typography)`
  font-weight: 600;
  color: #2e2e2e;
`;

export const EmptyText = styled(Typography)`
  line-height: 1;
`;
