export function LinkOutIcon({
  style,
  width = 18,
  height = 18,
}: {
  style?: React.CSSProperties;
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path d='M4.72 5.47a.75.75 0 0 1 .53-.22h3v-1.5h-3A2.25 2.25 0 0 0 3 6v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 15 13.5V9.75h-1.5v3.75a.75.75 0 0 1-.75.75h-7.5a.75.75 0 0 1-.75-.75V6a.75.75 0 0 1 .22-.53z' />
      <path d='M16.282 1.721a.748.748 0 0 1 .218.527V6.75H15V4.06l-4.345 4.345a.75.75 0 1 1-1.06-1.06L13.939 3H11.25V1.5h4.5c.206 0 .393.083.529.218l.003.003z' />
    </svg>
  );
}
