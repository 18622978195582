import { Dispatch, Middleware } from 'redux';
import {
  APIType,
  DATASET_ILLEGAL_FORMAT_ERROR_MESSAGE,
  DATASET_MIN_ROWS_ERROR_MESSAGE,
  DATASET_UNKNOWN_EXTENSION_ERROR_MESSAGE,
  DUPLICATE_DATASET_NAME_ERROR_MESSAGE,
  GENERAL_EXCEPTION_ERROR_MESSAGE,
  INVALID_DATASET_NAME_ERROR_MESSAGE,
  PLAYGROUND_EDITOR_WHITESPACE_WARNING,
  PLAYGROUND_SETTINGS_CHANGE_INTERVAL_SECONDS,
} from '../../constants/constants';
import { AuthenticationPageType } from '../../data-types/AuthenticationPageType';
import { ChooseHyperParametersOrigin } from '../../data-types/ChooseHyperParametersOrigin';
import { CodeSnippetModalContext, ProgrammingLanguage } from '../../data-types/CodeSnippet';
import {
  CompletionConfigChanged,
  CompletionFailureReason,
  CompletionRequestParams,
  CompletionResponse,
  ModelCostChanged,
  ModelTrainSettingsChanged,
} from '../../data-types/Completion';
import { CustomModelType } from '../../data-types/Model';
import { UserRole } from '../../data-types/Organization';
import { PresetLabel, PresetOrigin } from '../../data-types/PresetParams';
import { TierType } from '../../data-types/Tier';
import { logEvent } from '@ai21/studio-analytics';
import { errorHandler } from '../../utils/error-reporting';
import {
  PAYMENT_FAILURE,
  PAYMENT_STARTED,
  PAYMENT_SUCCESS,
} from '../actionTypes/PaymentActionTypes';
import { ActionTypes, MOBILE_PAGE_SHOWN } from '../actionTypes/actionTypes';
import {
  API_MODAL_COPIED,
  API_MODAL_SHOWN,
  CHOOSE_HYPER_PARAMETERS,
  CUSTOM_MODEL_TRY_IN_PLAYGROUND_PRESSED,
  DELETE_MODEL_PRESSED,
  DELETE_MODEL_SUCCESS,
  MODEL_COST_CHANGED,
  NEW_CUSTOM_MODEL,
  POST_MODEL_SUCCESS,
  TRAIN_SETTINGS_CHANGED,
} from '../actionTypes/customModelsActionTypes';
import {
  DELETE_DATASET_PRESSED,
  DELETE_DATASET_SUCCESS,
  DatasetsType,
  DatasetsUploadingErrorType,
  FETCH_DATASET_URL_SUCCESS,
  POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE,
  POST_DATASET_FAILURE,
  POST_DATASET_SUCCESS,
  DATASET_COPY_ID,
  UPLOAD_DATASET_PRESSED,
  FETCH_EXAMPLE_DATASET_URL_SUCCESS,
  TECHNICAL_DOCUMENTATION_PRESSED,
  ADD_DATASET_FILE,
  POST_DATASET_STARTED,
  DATASET_EXPORT_DATA_PRESSED,
  PRESET_EXPORT_DATA_PRESSED,
  CUSTOM_MODELS_EXPORT_DATA_PRESSED,
} from '../actionTypes/datasetsActionTypes';

import {
  NAVIGATOR_CLICKED,
  PRESET_LABELS_CLEARED,
  PRESET_LABEL_CLICKED,
} from '../actionTypes/overviewActionTypes';
import {
  ALTERNATIVE_TOKENS_ACTIVATED,
  ALTERNATIVE_TOKENS_DEACTIVATED,
  CANVAS_CLEARED,
  COMPLETION_CANCELLED,
  COMPLETION_CLEARED,
  CTA_TRAILING_WHITESPACE_ALERT,
  CUSTOM_MODELS_CTA_PRESSED,
  CUSTOM_PRESET_SELECTED,
  DISMISS_TRAILING_WHITESPACE_ALERT,
  EMAIL_VERIFICATION_RESEND_PRESSED,
  FETCH_COMPLETION_FAILED,
  FETCH_COMPLETION_STARTED,
  FETCH_COMPLETION_SUCCESS,
  GET_PROMPT_SHARE_FAILED,
  GET_PROMPT_SHARE_STARTED,
  GET_PROMPT_SHARE_SUCCESS,
  INAPPROPRIATE_CONTENT_FLAGGED,
  MODEL_CONFIG_CHANGED,
  NEW_PRESET_PRESSED,
  ONBOARDING_VIDEO_PRESSED,
  POST_PROMPT_SHARE_FAILED,
  POST_PROMPT_SHARE_STARTED,
  POST_PROMPT_SHARE_SUCCESS,
  PRESET_SELECTED,
  PROMPT_SHARE_COPIED,
  REGENERATED_PRESSED,
  SHOW_TRAILING_WHITESPACE_ALERT,
  SMS_CODE_RESEND_PRESSED,
} from '../actionTypes/playgroundActionTypes';
import {
  DELETE_PRESET_SUCCESS,
  POST_PRESET_SUCCESS,
  PresetListName,
} from '../actionTypes/presetActionTypes';
import {
  FAQ_OPENED,
  GUIDANCE_CTA_PRESSED,
  TRY_IN_PLAYGROUND_CTA_PRESSED,
  UPGRADE_PLAN_PRESSED,
} from '../actionTypes/pricingActionTypes';
import {
  CANCEL_SUBSCRIPTION_CONFIRMED,
  CANCEL_SUBSCRIPTION_MODAL_OPENED,
  FEEDBACK_ACTIVATED,
  FEEDBACK_DEACTIVATED,
  FEEDBACK_DISMISSED,
  FEEDBACK_SHOWN,
  FEEDBACK_SUBMIT_FAILURE,
  FEEDBACK_SUBMIT_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_RETRY_SUCCESS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_WITH_INVITE_SUCCESS,
  FIREBASE_EMAIL_VERIFIED,
  MESSAGE_DISMISSED,
  MESSAGE_SHOWN,
  MODAL_DISMISSED,
  MODAL_SHOWN,
  PAYMENT_PLAN_CLICKED,
  PLAN_UPGRADE_CLICKED,
  SEND_INVITE_ORGANIZATION_STARTED,
  SEND_INVITE_ORGANIZATION_SUCCESS,
  SIGN_IN_EMAIL_AUTHENTICATION_FAILED,
  SIGN_IN_EMAIL_AUTHENTICATION_SUCCESS,
  SIGN_IN_SEND_SMS_FAILED,
  SIGN_IN_SEND_SMS_SUCCESS,
  SIGN_IN_SMS_CODE_VERIFICATION_FAILED,
  SIGN_IN_SMS_CODE_VERIFICATION_SUCCESS,
  SIGN_OUT_SUCCESS,
  SignInMethod,
  USER_SIGNIN_SUCCESS,
  API_KEY_COPIED,
  UPGRADE_PLAN_CTA_PRESSED,
  DOCS_OPENED,
} from '../actionTypes/userActionTypes';
import {
  ANALYTICS_ALTERNATIVE_TOKENS_ACTIVATED,
  ANALYTICS_ALTERNATIVE_TOKENS_DEACTIVATED,
  ANALYTICS_API_KEY_COPIED,
  ANALYTICS_API_SNIPPET_COPIED,
  ANALYTICS_API_SNIPPET_SHOWN,
  ANALYTICS_CANVAS_CLEARED,
  ANALYTICS_COMPLETION_CANCELLED,
  ANALYTICS_COMPLETION_CLEARED,
  ANALYTICS_COMPLETION_ERROR,
  ANALYTICS_COMPLETION_GENERATED,
  ANALYTICS_COMPLETION_REQUESTED,
  ANALYTICS_CONFIRM_CANCEL_PRESSED,
  ANALYTICS_CUSTOM_MODELS_CTA_PRESSED,
  ANALYTICS_CUSTOM_MODELS_EXPORT_DATA_PRESSED,
  ANALYTICS_DATASET_COPY_ID,
  ANALYTICS_DATASET_EXAMPLE_DOWNLOADED,
  ANALYTICS_DATASET_EXPORT_DATA_PRESSED,
  ANALYTICS_DATASET_SAVE_PRESSED,
  ANALYTICS_DATASET_SELECT_FILE_PRESSED,
  ANALYTICS_DOCS_OPENED,
  ANALYTICS_EMAIL_VERIFICATION_RESEND_PRESSED,
  ANALYTICS_FEEDBACK_ACTIVATED,
  ANALYTICS_FEEDBACK_DEACTIVATED,
  ANALYTICS_FEEDBACK_DISMISSED,
  ANALYTICS_FEEDBACK_FAILED,
  ANALYTICS_FEEDBACK_SHOWN,
  ANALYTICS_FEEDBACK_SUBMITTED,
  ANALYTICS_FETCH_USER_DETAILS_WITH_INVITE_SUCCESS,
  ANALYTICS_FIREBASE_EMAIL_VERIFIED,
  ANALYTICS_GET_PROMPT_SHARE_FAILED,
  ANALYTICS_GET_PROMPT_SHARE_STARTED,
  ANALYTICS_GET_PROMPT_SHARE_SUCCESS,
  ANALYTICS_INAPPROPRIATE_CONTENT_FLAGGED,
  ANALYTICS_LOGOUT_SUCCESS,
  ANALYTICS_MESSAGE_DISMISSED,
  ANALYTICS_MESSAGE_SHOWN,
  ANALYTICS_MOBILE_PAGE_SHOWN,
  ANALYTICS_MODAL_DISMISSED,
  ANALYTICS_MODAL_SHOWN,
  ANALYTICS_MODEL_CONFIG_CHANGED,
  ANALYTICS_MODEL_COST_CHANGED,
  ANALYTICS_NAVIGATOR_PRESSED,
  ANALYTICS_NEW_PRESET_PRESSED,
  ANALYTICS_ONBOARDING_VIDEO_PRESSED,
  ANALYTICS_PAYMENT_FAILED,
  ANALYTICS_PAYMENT_SUBMITTED,
  ANALYTICS_PAYMENT_SUCCESS,
  ANALYTICS_PLAN_CANCELED_PRESSED,
  ANALYTICS_PLAN_PRESSED,
  ANALYTICS_PLAN_UPGRADE_PRESSED,
  ANALYTICS_POST_PROMPT_SHARE_FAILED,
  ANALYTICS_POST_PROMPT_SHARE_STARTED,
  ANALYTICS_POST_PROMPT_SHARE_SUCCESS,
  ANALYTICS_PRESET_DELETED,
  ANALYTICS_PRESET_EXPORT_DATA_PRESSED,
  ANALYTICS_PRESET_LABELS_CLEARED,
  ANALYTICS_PRESET_LABEL_CLICKED,
  ANALYTICS_PRESET_SAVED,
  ANALYTICS_PRESET_SELECTED,
  ANALYTICS_PROMPT_SHARE_COPIED,
  ANALYTICS_REGENERATED_PRESSED,
  ANALYTICS_SEND_INVITE_ORGANIZATION_STARTED,
  ANALYTICS_SEND_INVITE_ORGANIZATION_SUCCESS,
  ANALYTICS_SIGN_IN_FAILED,
  ANALYTICS_SIGN_IN_SUCCESS,
  ANALYTICS_SMS_CODE_FAILED,
  ANALYTICS_SMS_CODE_RESEND_PRESSED,
  ANALYTICS_SMS_CODE_SUCCESS,
  ANALYTICS_SMS_SEND_FAILED,
  ANALYTICS_SMS_SEND_SUCCESS,
  ANALYTICS_TECHNICAL_DOCUMENTATION_PRESSED,
  ANALYTICS_TRAIN_SETTINGS_CHANGED,
  ANALYTICS_UPLOAD_DATASET_PRESSED,
  ANALYTICS_USER_SIGNIN_SUCCESS,
  ANALYTICS_WHITESPACE_MESSAGE_DISMISSED,
  ANALYTICS_WHITESPACE_MESSAGE_SHOWN,
  APP_USER_CREATION_FAILED,
  APP_USER_CREATION_SUCCESS,
  APP_USER_FETCH_FAILED,
  APP_USER_FETCH_SUCCESS,
  CUSTOM_MODEL_TRAIN_SUBMITTED,
  CUSTOM_MODEL_TRY_IN_PLAYGROUND,
  DATASET_DELETE_PRESSED,
  DATASET_DELETE_SUCCEEDED,
  DATASET_DOWNLOAD_PRESSED,
  DATASET_UPLOADED,
  DATASET_UPLOAD_ERROR,
  MODEL_DELETE_PRESSED,
  MODEL_DELETE_SUCCEEDED,
  NEW_CUSTOM_MODEL_PRESSED,
} from './analyticsEvents';

function customModelTrainSubmitted(
  epochs: number,
  learningRate: number,
  datasetId: string,
  modelType: CustomModelType | undefined,
  costDollars: number
) {
  logEvent(CUSTOM_MODEL_TRAIN_SUBMITTED, {
    learningRate,
    epochs,
    datasetId,
    modelType,
    costDollars,
  });
}
export function FireGoogleTagManagerEvent(eventName: string) {
  try {
    // eslint-disable-next-line
    window.dataLayer.push({ event: eventName });
  } catch (err: any) {
    errorHandler.report(`Couldn't report Google tag event: ${eventName}`);
  }
}

function mapProgrammingLanguage(language: ProgrammingLanguage) {
  switch (language) {
    case ProgrammingLanguage.PYTHON:
      return 'python';
    case ProgrammingLanguage.JAVASCRIPT:
      return 'js';
    case ProgrammingLanguage.CURL:
      return 'curl';
    default:
      return 'python';
  }
}

function customModelAPISnippetShown(customModelId: string, language: ProgrammingLanguage) {
  logEvent(ANALYTICS_API_SNIPPET_SHOWN, {
    origin: 'modelsTableItem',
    customModelId,
    language: mapProgrammingLanguage(language),
  });
}

function customModelAPISnippetCopied(customModelId: string, language: ProgrammingLanguage) {
  logEvent(ANALYTICS_API_SNIPPET_COPIED, {
    origin: 'modelsTableItem',
    customModelId,
    language: mapProgrammingLanguage(language),
  });
}

function customModelTryInPlaygroundPressed(customModelId: string) {
  logEvent(CUSTOM_MODEL_TRY_IN_PLAYGROUND, {
    origin: 'modelsTableItem',
    customModelId,
  });
}

function downloadDataset(datasetType: DatasetsType, datasetId: string) {
  if (!datasetType) {
    return;
  }
  logEvent(DATASET_DOWNLOAD_PRESSED, {
    datasetType: datasetType.toString(),
    datasetId,
    origin: 'datasetsTableItem',
  });
}

function mapErrorTypeToErrorMessage(uploadDatasetError: DatasetsUploadingErrorType | undefined) {
  switch (uploadDatasetError) {
    case DatasetsUploadingErrorType.ILLEGAL_FORMAT:
      return DATASET_ILLEGAL_FORMAT_ERROR_MESSAGE;
    case DatasetsUploadingErrorType.INVALID_DATASET_NAME:
      return INVALID_DATASET_NAME_ERROR_MESSAGE;
    case DatasetsUploadingErrorType.DUPLICATE_DATASET_NAME:
      return DUPLICATE_DATASET_NAME_ERROR_MESSAGE;
    case DatasetsUploadingErrorType.UNKNOWN_EXTENSION:
      return DATASET_UNKNOWN_EXTENSION_ERROR_MESSAGE;
    case DatasetsUploadingErrorType.COLUMNS_NOT_SPECIFIED:
      return 'Could not detect ‘prompt’ and ‘completion’ columns in the file.';
    case DatasetsUploadingErrorType.MULTIPLE_WHITESPACE:
      return 'Your data might not comply with best practices. It is recommended to end prompts with a newline or start completions with a whitespace. It’s best to pick one of the options and apply it consistently to all examples.';
    case DatasetsUploadingErrorType.TO_LONG_ROWS:
      return 'Your dataset contains examples that are too long. The combined length of the prompt and completion must be less than 2048 tokens. If you want, we can delete the long rows for you, but the quality of your model may be affected';
    case DatasetsUploadingErrorType.GENERAL_EXCEPTION:
      return GENERAL_EXCEPTION_ERROR_MESSAGE;
    case DatasetsUploadingErrorType.DATASET_MIN_ROWS_ERROR:
      return DATASET_MIN_ROWS_ERROR_MESSAGE;
    case DatasetsUploadingErrorType.EMPTY_COMPLETION:
      return 'Empty completion value is not allowed';
    default:
      return uploadDatasetError
        ? `Ask studio dev to map ${uploadDatasetError} to the relevant message`
        : 'undefined';
  }
}

function postDatasetFailure(
  uploadDatasetError: DatasetsUploadingErrorType | undefined,
  uploadDatasetErrorDetails: string | undefined = undefined
) {
  logEvent(DATASET_UPLOAD_ERROR, {
    errorType: uploadDatasetError ? uploadDatasetError.toString() : '',
    errorMessage: uploadDatasetErrorDetails
      ? `Illegal format: ${uploadDatasetErrorDetails}`
      : mapErrorTypeToErrorMessage(uploadDatasetError),
  });
}

function postDatasetSuccess(sizeBytes: number, datasetId: string) {
  logEvent(DATASET_UPLOADED, {
    origin: 'datasetsPageUpload',
    size: sizeBytes,
    datasetId,
  });
}

function deleteDatasetSuccess(datasetId: string) {
  logEvent(DATASET_DELETE_SUCCEEDED, {
    origin: 'datasetsPage',
    datasetId,
  });
}

function deleteDatasetPressed(datasetId: string, origin: string) {
  logEvent(DATASET_DELETE_PRESSED, {
    origin,
    datasetId,
  });
}

function deleteModelSuccess(modelName: string, modelId: string) {
  logEvent(MODEL_DELETE_SUCCEEDED, {
    origin: 'modelsPage',
    modelName,
    modelId,
  });
}

function deleteModelPressed(modelId: string) {
  logEvent(MODEL_DELETE_PRESSED, {
    origin: 'modelsPage',
    modelId,
  });
}

function newCustomModelPressed() {
  logEvent(NEW_CUSTOM_MODEL_PRESSED, {
    origin: 'modelsNewButton',
    CTAText: 'New',
  });
}

function trainAnotherModelOnSameDatasetPressed(
  datasetId: string,
  origin: ChooseHyperParametersOrigin
) {
  const ctaText = (function getCtaText() {
    switch (origin) {
      case ChooseHyperParametersOrigin.DATASETS_TABLE_ITEM:
        return 'Train';
      case 'modelsTableItem':
        return 'Train another model on the same dataset';
      default:
        return 'Undefined';
    }
  })();

  logEvent(NEW_CUSTOM_MODEL_PRESSED, {
    origin: origin.toString(),
    CTAText: ctaText,
    datasetId,
  });
}

function signInEmailAuthenticationFailed(
  signInType: AuthenticationPageType,
  signInMethod: SignInMethod,
  errorMessage: string,
  errorCode: string
) {
  logEvent(ANALYTICS_SIGN_IN_FAILED, {
    signInType: AuthenticationPageType[signInType],
    signInMethod: SignInMethod[signInMethod],
    errorMessage,
    errorCode,
  });
}

function signInEmailAuthenticationSuccess(
  signInType: AuthenticationPageType,
  signInMethod: SignInMethod
) {
  logEvent(ANALYTICS_SIGN_IN_SUCCESS, {
    signInType: AuthenticationPageType[signInType],
    signInMethod: SignInMethod[signInMethod],
  });
}

function signInSendSmsFailed(
  signInType: AuthenticationPageType,
  signInMethod: SignInMethod | null,
  errorMessage: string,
  errorCode: string
) {
  logEvent(ANALYTICS_SMS_SEND_FAILED, {
    signInType: AuthenticationPageType[signInType],
    signInMethod: signInMethod !== null ? SignInMethod[signInMethod] : null,
    errorMessage,
    errorCode: errorCode || '',
  });
}

function signInSendSmsSuccess(
  signInType: AuthenticationPageType,
  signInMethod: SignInMethod | null
) {
  logEvent(ANALYTICS_SMS_SEND_SUCCESS, {
    signInType: AuthenticationPageType[signInType],
    signInMethod: signInMethod !== null ? SignInMethod[signInMethod] : null,
  });
}

function signInSmsCodeVerificationFailed(
  signInType: AuthenticationPageType,
  signInMethod: SignInMethod | null,
  errorMessage: string,
  errorCode: string
) {
  logEvent(ANALYTICS_SMS_CODE_FAILED, {
    signInType: AuthenticationPageType[signInType],
    signInMethod: signInMethod !== null ? SignInMethod[signInMethod] : null,
    errorMessage,
    errorCode,
  });
}

function signInSmsCodeVerificationSuccess(
  signInType: AuthenticationPageType,
  signInMethod: SignInMethod | null
) {
  FireGoogleTagManagerEvent('PhoneVerificationSuccess');
  logEvent(ANALYTICS_SMS_CODE_SUCCESS, {
    signInType: AuthenticationPageType[signInType],
    signInMethod: signInMethod !== null ? SignInMethod[signInMethod] : null,
  });
}

function firebaseEmailVerified(signInType: AuthenticationPageType | null) {
  FireGoogleTagManagerEvent('EmailVerification');
  logEvent(ANALYTICS_FIREBASE_EMAIL_VERIFIED, {
    signInType: signInType !== null ? AuthenticationPageType[signInType] : null,
  });
}

function userSiginSuccess(
  signInType: AuthenticationPageType | null,
  signInMethod: SignInMethod | null
) {
  logEvent(ANALYTICS_USER_SIGNIN_SUCCESS, {
    signInType: signInType !== null ? AuthenticationPageType[signInType] : null,
    signInMethod: signInMethod !== null ? SignInMethod[signInMethod] : null,
  });
}

function identityUserFetchFailed(
  signInType: AuthenticationPageType,
  signInMethod: SignInMethod | null,
  errorMessage: string
) {
  if (signInType === AuthenticationPageType.SIGNUP) {
    logEvent(APP_USER_CREATION_FAILED, {
      signInType: AuthenticationPageType[signInType],
      signInMethod: signInMethod !== null ? SignInMethod[signInMethod] : null,
      errorMessage,
    });
  } else {
    logEvent(APP_USER_FETCH_FAILED, {
      signInType: AuthenticationPageType[signInType],
      signInMethod: signInMethod !== null ? SignInMethod[signInMethod] : null,
      errorMessage,
    });
  }
}

function identityUserFetchSuccess(
  signInType: AuthenticationPageType,
  signInMethod: SignInMethod | null,
  organizationId: string,
  organizationPlanType: TierType,
  userRole: UserRole,
  userId: string,
  gclid: string | null,
  gbraid: string | null,
  wbraid: string | null
) {
  if (signInType === AuthenticationPageType.SIGNUP) {
    FireGoogleTagManagerEvent('SignUp');
    logEvent(APP_USER_CREATION_SUCCESS, {
      signInType: AuthenticationPageType[signInType],
      signInMethod: signInMethod !== null ? SignInMethod[signInMethod] : null,
    });
  } else {
    FireGoogleTagManagerEvent('SignIn');
    logEvent(APP_USER_FETCH_SUCCESS, {
      signInType: AuthenticationPageType[signInType],
      signInMethod: signInMethod !== null ? SignInMethod[signInMethod] : null,
    });
  }
}

function identityUserFetchRetrySuccess(
  organizationId: string,
  userId: string,
  organizationPlanType: TierType,
  userRole: UserRole
) {}

function fetchCompletionRequested(completionParams: CompletionRequestParams) {
  FireGoogleTagManagerEvent('GenerateButtonClicked');
  const { prompt, ...params } = completionParams;
  logEvent(ANALYTICS_COMPLETION_REQUESTED, {
    ...params,
  });
}

const buildPromptSharePayload = (
  payload: CompletionRequestParams | { prompt: string },
  apiType: APIType
) => {
  return {
    model: (payload as CompletionRequestParams).model,
    responseLength: (payload as CompletionRequestParams).maxTokens,
    temperature: (payload as CompletionRequestParams).temperature,
    numStopSequences: (payload as CompletionRequestParams).stopSequences
      ? (payload as CompletionRequestParams).stopSequences.length
      : 0,
    topKReturn: (payload as CompletionRequestParams).topKReturn,
    promptLength: (payload as CompletionRequestParams).prompt
      ? (payload as CompletionRequestParams).prompt.length
      : 0,
    topP: (payload as CompletionRequestParams).topP,
    numResults: 1,
    apiType,
  };
};

function postPromptShareStarted(
  payload: CompletionRequestParams | { prompt: string },
  apiType: APIType
) {
  logEvent(ANALYTICS_POST_PROMPT_SHARE_STARTED, {
    ...buildPromptSharePayload(payload, apiType),
    apiType,
  });
}

function postPromptShareSuccess(
  payload: CompletionRequestParams | { prompt: string },
  requestDuration: number,
  promptShareId: string,
  apiType: APIType
) {
  logEvent(ANALYTICS_POST_PROMPT_SHARE_SUCCESS, {
    ...buildPromptSharePayload(payload, apiType),
    apiType,
  });
}

function postPromptShareFailed(
  payload: CompletionRequestParams | { prompt: string },
  error: string,
  apiType: APIType
) {
  logEvent(ANALYTICS_POST_PROMPT_SHARE_FAILED, {
    ...buildPromptSharePayload(payload, apiType),
    apiType,
    error,
  });
}

function mobilePageShown(path: string) {
  logEvent(ANALYTICS_MOBILE_PAGE_SHOWN, {
    path,
  });
}

function getPromptShareStarted(promptShareId: string) {
  logEvent(ANALYTICS_GET_PROMPT_SHARE_STARTED, {
    promptShareId,
  });
}

function getPromptShareSuccess(
  params: CompletionRequestParams,
  text: string,
  promptShareId: string,
  requestDuration: number,
  apiType: APIType
) {
  logEvent(ANALYTICS_GET_PROMPT_SHARE_SUCCESS, {
    promptShareId,
    model: params.model,
    numResults: 1,
    responseLength: params.maxTokens,
    temperature: params.temperature,
    topKReturn: params.topKReturn,
    numStopSequences: params.stopSequences ? params.stopSequences.length : 0,
    promptLength: text ? text.length : 0,
    requestDuration,
    topP: params.topP,
    apiType,
  });
}

function getPromptShareFailed(promptShareId: string, error: string) {
  logEvent(ANALYTICS_GET_PROMPT_SHARE_FAILED, {
    promptShareId,
    errorMessage: error,
  });
}

function inviteMemberPressed(origin: string) {
  logEvent(ANALYTICS_SEND_INVITE_ORGANIZATION_STARTED, {
    origin,
  });
}

function inviteMemberSent(origin: string, method: string) {
  logEvent(ANALYTICS_SEND_INVITE_ORGANIZATION_SUCCESS, {
    origin,
    method,
  });
}

function memberAddToOrganizationSuccess(newMemberUserId: string, organizationId: string) {
  logEvent(ANALYTICS_FETCH_USER_DETAILS_WITH_INVITE_SUCCESS, {
    organizationId,
    newMemberUserId,
  });
}

function fetchCompletionSuccess(
  completionResponse: CompletionResponse,
  completionParams: CompletionRequestParams,
  requestDuration: number
) {
  const { prompt, ...params } = completionParams;
  logEvent(ANALYTICS_COMPLETION_GENERATED, {
    ...params,
    interactionId: completionResponse.id,
    generatedTextLength: completionResponse.completions[0].data.text.length,
    requestDuration,
  });
}

function fetchCompletionFailed(
  completionParams: CompletionRequestParams,
  error: CompletionFailureReason
) {
  const { prompt, ...params } = completionParams;
  logEvent(ANALYTICS_COMPLETION_ERROR, {
    ...params,
    errorMessage: CompletionFailureReason[error],
  });
}

function presetSaved(
  presetTitle: string,
  presetListName: PresetListName,
  prompt: string,
  apiType: APIType
) {
  logEvent(ANALYTICS_PRESET_SAVED, {
    presetTitle,
    presetListName,
    promptLength: prompt ? prompt.length : 0,
    apiType,
  });
}

function presetDeleted(
  presetTitle: string,
  presetListName: PresetListName,
  prompt: string,
  origin: string
) {
  logEvent(ANALYTICS_PRESET_DELETED, {
    presetTitle,
    presetListName,
    promptLength: prompt ? prompt.length : 0,
    origin,
  });
}

function presetSelected(
  presetTitle: string,
  presetListName: PresetListName,
  prompt: string,
  presetLabel: PresetLabel | null,
  origin: PresetOrigin,
  apiType: APIType
) {
  logEvent(ANALYTICS_PRESET_SELECTED, {
    presetTitle,
    presetListName,
    promptLength: prompt ? prompt.length : 0,
    presetLabel,
    origin,
    apiType,
  });
}

function presetLabelClicked(presetLabel: PresetLabel, clicked: boolean) {
  logEvent(ANALYTICS_PRESET_LABEL_CLICKED, {
    origin: PresetOrigin.OVERVIEW,
    labelName: presetLabel,
    action: clicked ? 'press' : 'unpress',
    path: '/overview',
  });
}

function presetLabelsCleared() {
  logEvent(ANALYTICS_PRESET_LABELS_CLEARED, {
    origin: PresetOrigin.OVERVIEW,
    path: '/overview',
  });
}

function customModelsCtaPressed(origin: string, buttonText: string) {
  logEvent(ANALYTICS_CUSTOM_MODELS_CTA_PRESSED, {
    origin,
    buttonText,
  });
}

function promptShareCopied(promptShareUrl: string) {
  logEvent(ANALYTICS_PROMPT_SHARE_COPIED, {
    promptShareUrl,
  });
}

function paymentSuccess() {
  FireGoogleTagManagerEvent('PaymentSuccess');
  logEvent(ANALYTICS_PAYMENT_SUCCESS, {});
}

function paymentFailed(errorMessage: string | null) {
  logEvent(ANALYTICS_PAYMENT_FAILED, {
    errorMessage,
  });
}

function paymentSubmitted() {
  logEvent(ANALYTICS_PAYMENT_SUBMITTED, {});
}

function navigatorClicked(buttonText: string, destination: string, origin: string, path: string) {
  logEvent(ANALYTICS_NAVIGATOR_PRESSED, {
    buttonText,
    destination,
    origin,
    path,
  });
}

function planCanceledPressed(callToActionText: string, path: string) {
  logEvent(ANALYTICS_PLAN_CANCELED_PRESSED, {
    callToActionText,
    path,
  });
}

function cancelPlanConfirmed(callToActionText: string) {
  logEvent(ANALYTICS_CONFIRM_CANCEL_PRESSED, {
    callToActionText,
  });
}

function planPressed(
  callToActionText: string,
  plan: string,
  path: string,
  displayedPrice: number,
  displayedDescription: string | null
) {
  logEvent(ANALYTICS_PLAN_PRESSED, {
    callToActionText,
    plan,
    path,
    displayedPrice,
    displayedDescription,
  });
}

function planUpgradePressed(callToActionText: string, origin: string, path: string) {
  logEvent(ANALYTICS_PLAN_UPGRADE_PRESSED, {
    callToActionText,
    path,
    origin,
  });
}

function playgroundAPISnippetShown(
  payload: CompletionRequestParams,
  language: ProgrammingLanguage
) {
  logEvent(ANALYTICS_API_SNIPPET_SHOWN, {
    origin: 'playground',
    language: mapProgrammingLanguage(language),
    model: payload?.model,
    numResults: payload?.numResults,
    maxTokens: payload?.maxTokens,
    temperature: payload?.temperature,
    topKReturn: payload?.topKReturn,
    topP: payload?.topP,
    stopSequences: payload?.stopSequences,
  });
}

function playgroundAPISnippetCopied(
  payload: CompletionRequestParams,
  language: ProgrammingLanguage
) {
  logEvent(ANALYTICS_API_SNIPPET_COPIED, {
    origin: 'playground',
    language: mapProgrammingLanguage(language),
    model: payload?.model,
    numResults: payload?.numResults,
    maxTokens: payload?.maxTokens,
    temperature: payload?.temperature,
    topKReturn: payload?.topKReturn,
    topP: payload?.topP,
    stopSequences: payload?.stopSequences,
  });
}

function onboardingVideoPressed(path: string) {
  logEvent(ANALYTICS_ONBOARDING_VIDEO_PRESSED, {
    origin: 'canvasQuickstart',
    buttonText: 'Quickstart',
    path,
  });
}

function canvasCleared(path: string) {
  logEvent(ANALYTICS_CANVAS_CLEARED, {
    origin: 'canvas',
    buttonText: 'Clear all',
    path,
  });
}

function inappropriateContentFlagged(path: string) {
  logEvent(ANALYTICS_INAPPROPRIATE_CONTENT_FLAGGED, {
    origin: 'canvas',
    buttonText: 'Flag as inappropriate content',
    path,
  });
}

function newPresetPressed(origin: string, api_type: APIType) {
  logEvent(ANALYTICS_NEW_PRESET_PRESSED, {
    origin,
    API: api_type,
  });
}

function completionCleared(path: string) {
  logEvent(ANALYTICS_COMPLETION_CLEARED, {
    origin: 'canvas',
    buttonText: 'X',
    path,
  });
}

function completionCancelled(path: string) {
  logEvent(ANALYTICS_COMPLETION_CANCELLED, {
    origin: 'canvas',
    buttonText: 'X Cancel',
    path,
  });
}

function modelConfigChanged(payload: CompletionConfigChanged) {
  logEvent(ANALYTICS_MODEL_CONFIG_CHANGED, {
    origin: 'canvasConfigPanel',
    newModel: payload.newModel,
    newMaxTokens: payload.newMaxTokens,
    newTemperature: payload.newTemperature,
    newTopP: payload.newTopP,
    newStopSequences: payload.newStopSequences,
    isPresencePenaltyChanged: payload.isPresencePenaltyChanged,
    isCountPenaltyChanged: payload.isCountPenaltyChanged,
    isFrequencyPenaltyChanged: payload.isFrequencyPenaltyChanged,
    eventFireDelaySeconds: PLAYGROUND_SETTINGS_CHANGE_INTERVAL_SECONDS,
    propertyName: payload.propertyName,
  });
}

function alternativeTokensActivated() {
  logEvent(ANALYTICS_ALTERNATIVE_TOKENS_ACTIVATED, {});
}

function alternativeTokensDeactivated() {
  logEvent(ANALYTICS_ALTERNATIVE_TOKENS_DEACTIVATED, {});
}

function trainSettingsChanged(payload: Partial<ModelTrainSettingsChanged>) {
  logEvent(ANALYTICS_TRAIN_SETTINGS_CHANGED, {
    origin: 'canvasConfigPanel',
    newModel: payload.newModel,
    newLearningRate: payload.newLearningRate,
    newNumOfEpochs: payload.newNumOfEpochs,
    currentCost: payload.currentCost,
    eventFireDelaySeconds: PLAYGROUND_SETTINGS_CHANGE_INTERVAL_SECONDS,
    propertyName: payload.propertyName,
  });
}

function modelCostChanged(payload: Partial<ModelCostChanged>) {
  logEvent(ANALYTICS_MODEL_COST_CHANGED, {
    origin: 'canvasConfigPanel',
    modelType: payload.modelType,
    numOfEpochs: payload.numOfEpochs,
    cost: payload.cost,
  });
}

function emailVerificationResendPressed(path: string) {
  logEvent(ANALYTICS_EMAIL_VERIFICATION_RESEND_PRESSED, {
    path,
  });
}

function smsCodeResendPressed(path: string) {
  logEvent(ANALYTICS_SMS_CODE_RESEND_PRESSED, {
    path,
  });
}

function signOutSuccess() {
  logEvent(ANALYTICS_LOGOUT_SUCCESS, {});
}

function overviewAPISnippetShown(customModelId: string, language: ProgrammingLanguage) {
  logEvent(ANALYTICS_API_SNIPPET_SHOWN, {
    origin: CodeSnippetModalContext.OVERVIEW,
    customModelId,
    language: mapProgrammingLanguage(language),
  });
}

function widgetAPISnippetShown(customModelId: string, language: ProgrammingLanguage) {
  logEvent(ANALYTICS_API_SNIPPET_SHOWN, {
    origin: 'widget',
    customModelId,
    language: mapProgrammingLanguage(language),
  });
}

function presetAPISnippetShown(customModelId: string, language: ProgrammingLanguage) {
  logEvent(ANALYTICS_API_SNIPPET_SHOWN, {
    origin: 'presets',
    presetName: customModelId,
    language: mapProgrammingLanguage(language),
  });
}

function overviewAPISnippetCopied(customModelId: string, language: ProgrammingLanguage) {
  logEvent(ANALYTICS_API_SNIPPET_COPIED, {
    origin: CodeSnippetModalContext.OVERVIEW,
    customModelId,
    language: mapProgrammingLanguage(language),
  });
}

function widgetAPISnippetCopied(customModelId: string, language: ProgrammingLanguage) {
  logEvent(ANALYTICS_API_SNIPPET_COPIED, {
    origin: 'widget',
    customModelId,
    language: mapProgrammingLanguage(language),
  });
}

function presetAPISnippetCopied(customModelId: string, language: ProgrammingLanguage) {
  logEvent(ANALYTICS_API_SNIPPET_COPIED, {
    origin: 'presets',
    presetName: customModelId,
    language: mapProgrammingLanguage(language),
  });
}

function regeneratePressed(apiType: APIType, interactionId: string) {
  logEvent(ANALYTICS_REGENERATED_PRESSED, {
    origin: CodeSnippetModalContext.PLAYGROUND,
    apiType,
    interactionId,
  });
}

export const analyticsMiddleware: Middleware =
  () => (next: Dispatch<ActionTypes>) => (action: ActionTypes) => {
    switch (action.type) {
      case SIGN_IN_EMAIL_AUTHENTICATION_FAILED:
        signInEmailAuthenticationFailed(
          action.signInType,
          action.signInMethod,
          action.errorMessage,
          action.errorCode
        );
        break;
      case SIGN_IN_EMAIL_AUTHENTICATION_SUCCESS:
        signInEmailAuthenticationSuccess(action.signInType, action.signInMethod);
        break;
      case SIGN_IN_SEND_SMS_FAILED:
        signInSendSmsFailed(
          action.signInType,
          action.signInMethod,
          action.errorMessage,
          action.errorCode
        );
        break;
      case SIGN_IN_SEND_SMS_SUCCESS:
        signInSendSmsSuccess(action.signInType, action.signInMethod);
        break;
      case SIGN_IN_SMS_CODE_VERIFICATION_FAILED:
        signInSmsCodeVerificationFailed(
          action.signInType,
          action.signInMethod,
          action.errorMessage,
          action.errorCode
        );
        break;
      case SIGN_IN_SMS_CODE_VERIFICATION_SUCCESS:
        signInSmsCodeVerificationSuccess(action.signInType, action.signInMethod);
        break;
      case USER_SIGNIN_SUCCESS:
        userSiginSuccess(action.signInType, action.signInMethod);
        break;
      case FIREBASE_EMAIL_VERIFIED:
        firebaseEmailVerified(action.signInType);
        break;
      case SIGN_OUT_SUCCESS:
        signOutSuccess();
        break;
      case FETCH_USER_DETAILS_FAILURE:
        identityUserFetchFailed(action.signInType, action.signInMethod, action.error);
        break;
      case FETCH_USER_DETAILS_SUCCESS:
        identityUserFetchSuccess(
          action.signInType,
          action.signInMethod,
          action.payload.organizationId,
          action.payload.userTier,
          action.payload.userRole,
          action.payload.userId,
          action.gclid,
          action.gbraid,
          action.wbraid
        );
        break;
      case FETCH_USER_DETAILS_RETRY_SUCCESS:
        identityUserFetchRetrySuccess(
          action.payload.organizationId,
          action.payload.userId,
          action.payload.userTier,
          action.payload.userRole
        );
        break;
      case FETCH_COMPLETION_STARTED:
        fetchCompletionRequested(action.completionParams);
        break;
      case FETCH_COMPLETION_SUCCESS:
        fetchCompletionSuccess(
          action.completionResponse,
          action.completionParams,
          action.requestDuration
        );
        break;
      case FETCH_COMPLETION_FAILED:
        if (action.reason) {
          fetchCompletionFailed(action.completionParams, action.reason);
        }
        break;
      case POST_PRESET_SUCCESS:
        presetSaved(
          action.payload.name,
          PresetListName.MY_PRESETS,
          action.prompt,
          action.payload.apiType
        );
        break;
      case DELETE_PRESET_SUCCESS:
        presetDeleted(action.name, PresetListName.MY_PRESETS, action.prompt, action.origin);
        break;
      case PRESET_SELECTED:
        presetSelected(
          action.preset.name,
          PresetListName.EXAMPLES,
          action.preset.text,
          action.preset.primaryLabel,
          action.origin,
          action.preset.apiType
        );
        break;
      case PRESET_LABEL_CLICKED:
        presetLabelClicked(action.presetLabel, action.clicked);
        break;
      case PRESET_LABELS_CLEARED:
        presetLabelsCleared();
        break;
      case CUSTOM_PRESET_SELECTED:
        presetSelected(
          action.preset.name,
          PresetListName.MY_PRESETS,
          action.preset.text,
          null,
          action.origin,
          action.preset.apiType
        );
        break;
      case CUSTOM_MODELS_CTA_PRESSED:
        customModelsCtaPressed(action.origin, action.buttonText);
        break;
      case POST_PROMPT_SHARE_STARTED:
        postPromptShareStarted(action.payload, action.apiType);
        break;
      case POST_PROMPT_SHARE_SUCCESS:
        postPromptShareSuccess(
          action.payload,
          action.requestDuration,
          action.promptShareId,
          action.apiType
        );
        break;
      case POST_PROMPT_SHARE_FAILED:
        postPromptShareFailed(action.payload, action.reason, action.apiType);
        break;
      case GET_PROMPT_SHARE_STARTED:
        getPromptShareStarted(action.promptShareId);
        break;
      case GET_PROMPT_SHARE_SUCCESS:
        getPromptShareSuccess(
          action.params,
          action.text,
          action.promptShareId,
          action.requestDuration,
          action.apiType
        );
        break;
      case GET_PROMPT_SHARE_FAILED:
        getPromptShareFailed(action.promptShareId, action.reason);
        break;
      case MOBILE_PAGE_SHOWN:
        mobilePageShown(action.path);
        break;
      case PROMPT_SHARE_COPIED:
        promptShareCopied(action.promptShareUrl);
        break;
      case PAYMENT_SUCCESS:
        paymentSuccess();
        break;
      case PAYMENT_FAILURE:
        paymentFailed(action.message);
        break;
      case PAYMENT_STARTED:
        paymentSubmitted();
        break;
      case NAVIGATOR_CLICKED:
        navigatorClicked(action.buttonText, action.destination, action.origin, action.path);
        break;
      case CANCEL_SUBSCRIPTION_MODAL_OPENED:
        planCanceledPressed(action.callToActionText, action.path);
        break;
      case CANCEL_SUBSCRIPTION_CONFIRMED:
        cancelPlanConfirmed(action.callToActionText);
        break;
      case PAYMENT_PLAN_CLICKED:
        planPressed(
          action.callToActionText,
          action.plan,
          action.path,
          action.displayedPrice,
          action.displayedDescription
        );
        break;
      case PLAN_UPGRADE_CLICKED:
        planUpgradePressed(action.callToActionText, action.origin, action.path);
        break;
      // Custom models & Datasets section:
      case NEW_CUSTOM_MODEL:
        newCustomModelPressed();
        break;
      case CHOOSE_HYPER_PARAMETERS:
        if (action.origin !== ChooseHyperParametersOrigin.MODELS_NEW_BUTTON) {
          trainAnotherModelOnSameDatasetPressed(action.datasetDetails.datasetId, action.origin);
        }
        break;
      case FETCH_EXAMPLE_DATASET_URL_SUCCESS:
        logEvent(ANALYTICS_DATASET_EXAMPLE_DOWNLOADED, {});
        break;
      case TECHNICAL_DOCUMENTATION_PRESSED:
        logEvent(ANALYTICS_TECHNICAL_DOCUMENTATION_PRESSED, {});
        break;
      case ADD_DATASET_FILE:
        logEvent(ANALYTICS_DATASET_SELECT_FILE_PRESSED, {});
        break;
      case POST_DATASET_STARTED:
        logEvent(ANALYTICS_DATASET_SAVE_PRESSED, {});
        break;
      case POST_DATASET_SUCCESS:
        postDatasetSuccess(action.payload.sizeBytes, action.payload.id);
        break;
      case POST_DATASET_FAILURE:
        postDatasetFailure(action.uploadDatasetError, action.uploadDatasetErrorDetails);
        break;
      case POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE:
        postDatasetFailure(DatasetsUploadingErrorType.COLUMNS_NOT_SPECIFIED);
        break;
      case DELETE_DATASET_SUCCESS:
        deleteDatasetSuccess(action.datasetId);
        break;
      case DELETE_DATASET_PRESSED:
        deleteDatasetPressed(action.datasetId, action.origin);
        break;
      case UPLOAD_DATASET_PRESSED:
        logEvent(ANALYTICS_UPLOAD_DATASET_PRESSED, {});
        break;
      case FETCH_DATASET_URL_SUCCESS:
        downloadDataset(action.datasetType, action.datasetId);
        break;
      case DATASET_COPY_ID:
        logEvent(ANALYTICS_DATASET_COPY_ID, {
          id: action.datasetId,
          origin: action.origin,
        });
        break;
      case API_MODAL_SHOWN:
        if (action.context === CodeSnippetModalContext.PLAYGROUND) {
          playgroundAPISnippetShown(action.completionParams, action.language);
        } else if (action.context === CodeSnippetModalContext.MODELS) {
          customModelAPISnippetShown(action.customModelId, action.language);
        } else if (action.context === CodeSnippetModalContext.OVERVIEW) {
          overviewAPISnippetShown(action.customModelId, action.language);
        } else if (action.context === 'widget') {
          widgetAPISnippetShown(action.customModelId, action.language);
        } else if (action.context === 'presets') {
          presetAPISnippetShown(action.customModelId, action.language);
        }
        break;
      case API_MODAL_COPIED:
        if (action.context === CodeSnippetModalContext.PLAYGROUND) {
          playgroundAPISnippetCopied(action.completionParams, action.language);
        } else if (action.context === CodeSnippetModalContext.MODELS) {
          customModelAPISnippetCopied(action.customModelId, action.language);
        } else if (action.context === CodeSnippetModalContext.OVERVIEW) {
          overviewAPISnippetCopied(action.customModelId, action.language);
        } else if (action.context === 'widget') {
          widgetAPISnippetCopied(action.customModelId, action.language);
        } else if (action.context === 'presets') {
          presetAPISnippetCopied(action.customModelId, action.language);
        }
        break;
      case CUSTOM_MODEL_TRY_IN_PLAYGROUND_PRESSED:
        customModelTryInPlaygroundPressed(action.customModelId);
        break;
      case POST_MODEL_SUCCESS:
        customModelTrainSubmitted(
          action.payload.modelMetadata?.numEpochs,
          action.payload.modelMetadata?.learningRate,
          String(action.payload.datasetId),
          action.payload.customModelType,
          action.payload.modelMetadata?.cost
        );
        break;
      case DELETE_MODEL_SUCCESS:
        deleteModelSuccess(action.name, action.id);
        break;
      case DELETE_MODEL_PRESSED:
        deleteModelPressed(action.modelId);
        break;
      case SEND_INVITE_ORGANIZATION_STARTED:
        inviteMemberPressed(action.payload.origin);
        break;
      case SEND_INVITE_ORGANIZATION_SUCCESS:
        inviteMemberSent(action.payload.origin, action.payload.method);
        break;
      case NEW_PRESET_PRESSED:
        newPresetPressed(action.origin, action.api_type);
        break;
      case FETCH_USER_DETAILS_WITH_INVITE_SUCCESS:
        memberAddToOrganizationSuccess(
          action.payload.newMemberUserId,
          action.payload.organizationId
        );
        break;
      case ONBOARDING_VIDEO_PRESSED:
        onboardingVideoPressed(action.path);
        break;
      case INAPPROPRIATE_CONTENT_FLAGGED:
        inappropriateContentFlagged(action.path);
        break;
      case COMPLETION_CLEARED:
        completionCleared(action.path);
        break;
      case CANVAS_CLEARED:
        canvasCleared(action.path);
        break;
      case COMPLETION_CANCELLED:
        completionCancelled(action.path);
        break;
      case ALTERNATIVE_TOKENS_ACTIVATED:
        alternativeTokensActivated();
        break;
      case ALTERNATIVE_TOKENS_DEACTIVATED:
        alternativeTokensDeactivated();
        break;
      case EMAIL_VERIFICATION_RESEND_PRESSED:
        emailVerificationResendPressed(action.path);
        break;
      case SMS_CODE_RESEND_PRESSED:
        smsCodeResendPressed(action.path);
        break;
      case MODEL_CONFIG_CHANGED:
        modelConfigChanged(action.payload);
        break;
      case TRAIN_SETTINGS_CHANGED:
        trainSettingsChanged(action.payload);
        break;
      case MODEL_COST_CHANGED:
        modelCostChanged(action.payload);
        break;
      case SHOW_TRAILING_WHITESPACE_ALERT:
        logEvent(ANALYTICS_WHITESPACE_MESSAGE_SHOWN, {
          origin: 'completionGenerated',
          type: 'question',
          text: PLAYGROUND_EDITOR_WHITESPACE_WARNING,
          name: 'whitespaceTrailingAlert',
        });
        break;
      case DISMISS_TRAILING_WHITESPACE_ALERT:
        logEvent(ANALYTICS_WHITESPACE_MESSAGE_DISMISSED, {
          type: 'question',
          reason: 'ignorePressed',
          name: 'whitespaceTrailingAlert',
        });
        break;
      case CTA_TRAILING_WHITESPACE_ALERT:
        logEvent(ANALYTICS_WHITESPACE_MESSAGE_DISMISSED, {
          type: 'question',
          reason: 'fixPressed',
          name: 'whitespaceTrailingAlert',
        });
        break;
      case GUIDANCE_CTA_PRESSED:
        logEvent(ANALYTICS_GUIDANCE_CTA_PRESSED, {
          path: '/pricing',
          ctaText: action.ctaText,
          origin: 'pricingPageGuidanceSection',
        });
        break;
      case UPGRADE_PLAN_PRESSED:
        logEvent(ANALYTICS_UPGRADE_PLAN_PRESSED, {
          path: '/pricing',
          ctaText: action.ctaText,
          origin: action.origin,
        });
        break;
      case TRY_IN_PLAYGROUND_CTA_PRESSED:
        logEvent(ANALYTICS_TRY_IN_PLAYGROUND_CTA_PRESSED, {
          path: action.path,
          ctaText: action.ctaText,
          origin: action.origin,
          labelName: action.labelName,
        });
        break;
      case DATASET_EXPORT_DATA_PRESSED:
        logEvent(ANALYTICS_DATASET_EXPORT_DATA_PRESSED, {
          origin: action.origin,
        });
        break;
      case PRESET_EXPORT_DATA_PRESSED:
        logEvent(ANALYTICS_PRESET_EXPORT_DATA_PRESSED, {
          origin: action.origin,
        });
        break;
      case CUSTOM_MODELS_EXPORT_DATA_PRESSED:
        logEvent(ANALYTICS_CUSTOM_MODELS_EXPORT_DATA_PRESSED, {
          origin: action.origin,
        });
        break;
      case FAQ_OPENED:
        logEvent(ANALYTICS_FAQ_OPENED, {
          path: '/pricing',
          questionText: action.questionText,
        });
        break;
      case FEEDBACK_DISMISSED:
        logEvent(ANALYTICS_FEEDBACK_DISMISSED, {
          ...action.payload,
        });
        break;
      case FEEDBACK_DEACTIVATED:
        logEvent(ANALYTICS_FEEDBACK_DEACTIVATED, {
          ...action.payload,
        });
        break;
      case FEEDBACK_SUBMIT_SUCCESS:
        logEvent(ANALYTICS_FEEDBACK_SUBMITTED, {
          ...action.payload,
        });
        break;
      case FEEDBACK_SUBMIT_FAILURE:
        logEvent(ANALYTICS_FEEDBACK_FAILED, {
          ...action.payload,
          errorMessage: action.errorMessage,
        });
        break;
      case FEEDBACK_SHOWN:
        logEvent(ANALYTICS_FEEDBACK_SHOWN, {
          ...action.payload,
        });
        break;
      case MESSAGE_SHOWN:
        logEvent(ANALYTICS_MESSAGE_SHOWN, {
          ...action.payload,
        });
        break;
      case API_KEY_COPIED:
        logEvent(ANALYTICS_API_KEY_COPIED, {
          origin: action.origin,
        });
        break;
      case UPGRADE_PLAN_CTA_PRESSED:
        logEvent(ANALYTICS_UPGRADE_PLAN_PRESSED, {
          origin: action.origin,
        });
        break;
      case MESSAGE_DISMISSED:
        logEvent(ANALYTICS_MESSAGE_DISMISSED, {
          ...action.payload,
        });
        break;
      case MODAL_SHOWN:
        logEvent(ANALYTICS_MODAL_SHOWN, {
          ...action.payload,
        });
        break;
      case MODAL_DISMISSED:
        logEvent(ANALYTICS_MODAL_DISMISSED, {
          ...action.payload,
        });
        break;
      case FEEDBACK_ACTIVATED:
        logEvent(ANALYTICS_FEEDBACK_ACTIVATED, {
          ...action.payload,
        });
        break;
      case REGENERATED_PRESSED:
        regeneratePressed(action.apiType, action.interactionId);
        break;
      default:
        break;
    }
    return next(action);
  };
