export function VisaIcon() {
  return (
    <svg width='24' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='.25' y='.25' width='23.5' height='15.5' rx='.75' stroke='#C3C6CE' strokeWidth='.5' />
      <path
        d='M14.375 5c-1.349 0-2.554.7-2.554 1.993 0 1.483 2.138 1.586 2.138 2.33 0 .314-.36.595-.973.595-.87 0-1.52-.392-1.52-.392l-.28 1.305s.75.331 1.745.331c1.475 0 2.636-.734 2.636-2.05 0-1.567-2.147-1.666-2.147-2.357 0-.246.295-.515.906-.515.69 0 1.254.285 1.254.285l.272-1.26S15.24 5 14.375 5zm-12.342.095L2 5.285s.568.104 1.079.312c.658.238.705.376.816.806l1.208 4.661h1.619l2.494-5.969H7.601L5.998 9.153l-.654-3.44c-.06-.393-.364-.618-.736-.618H2.033zm7.833 0-1.267 5.969h1.54l1.263-5.969H9.866zm8.592 0c-.371 0-.568.2-.713.547l-2.257 5.422h1.616l.312-.904h1.968l.19.904H21l-1.244-5.969h-1.298zm.21 1.613.479 2.24h-1.283l.804-2.24z'
        fill='#1434CB'
      />
    </svg>
  );
}
