import { call, takeEvery } from 'saga-ts';
import { getReadmeToken } from '../utils/getReadmeToken';
import { customEvenChannel } from './channels/channel.customEvent';

type ActionExternal = {
  type: 'EXTERNAL';
  data: {
    src: string;
    path: string;
    email: string;
    name: string;
    apiKey: string;
  };
};

const map: Record<string, any> = {
  docs: navigateToDocs,
};

export function* navigateToExternalPage(action: ActionExternal) {
  const { data } = action;
  const { src } = data;

  const saga = map[src];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* navigateToDocs(action: ActionExternal) {
  const { email, name, apiKey, path } = action.data;

  const readmeToken = yield* call(getReadmeToken, email, name, apiKey);
  window.location.replace(`https://docs.ai21.com/${path}?auth_token=${readmeToken}`);
}

export function* root() {
  const channel = customEvenChannel('saga/external');
  yield takeEvery(channel, navigateToExternalPage);
}
