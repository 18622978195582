import React from 'react';
import { LabelText, Wrapper } from './Label.style';
import { IFormField } from '../../forms.types';
import classnames from 'classnames';
import { Tip } from '@ai21/studio-base-ui';

export type LabelProps = {
  field: IFormField;
  isError?: boolean;
};

export function Label(props: LabelProps) {
  const { field, isError } = props;
  const { label, fieldType, tooltip, params } = field;

  if (['checkbox'].includes(fieldType)) {
    return null;
  }

  const className = classnames('Label-wrapper', fieldType, {
    error: isError,
  });

  return (
    <Wrapper className={className} data-testid='Label-wrapper'>
      <LabelText>
        {label} <Tip value={tooltip} />
      </LabelText>
    </Wrapper>
  );
}

export default Label;
