import { useNavigationAnalytics } from '@ai21/studio-analytics';
import { DemosRootContainer } from '@ai21/studio-app-demos';
import { CheckEmail, LoginContext } from '@ai21/studio-auth';
import { selectors } from '@ai21/studio-store';
import { BreadcrumbsProvider, ContentProvider, Loader, RouterBuilder } from '@ai21/studio-ui';
import { Suspense, useContext, useMemo, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Routes, useLocation } from 'react-router-dom';
import { CommandBarContainer } from '../bootstrap/CommandBar.container';
import { AnalyticsLogsContainer } from '../components/AnalyticsLogs/AnalyticsLogs.container';
import { AuthLogsContainer } from '../components/AuthLogs/AuthLogs.container';
import { UserStatus } from '../data-types/User';
import { useApiTokens } from '../hooks/useApiTokens';
import { useIntercom } from '../hooks/useIntercom';
import { useMobile } from '../hooks/useMobile';
import { useSaveGlobals } from '../hooks/useSaveGlobals';
import { MobilePage } from '../pages/mobile/MobilePage';
import { navigate } from '../sagas/saga.navigation';
import { SideBarContainer } from './SideBar.container';
import { sideBarItems } from './SideBar.data';
import { TopBarContainer } from './TopBar.container';
import { initRoutes } from './Web.apps';
import { V2_PATHS_MAP } from './Web.routes';
import { Wrapper } from './Web.style';

const isMobileOpen = (window.__env__.VITE_MOBILE_OPEN ?? '') === 'true';

export function Web() {
  const contentRef = useRef<HTMLDivElement>(null);
  const context = useContext(LoginContext);
  const { userStatus, userEmail, shouldVerifyEmail } = context.state;

  const slimSideBar = useSelector(selectors.raw.$rawAppState).slimSideBar;
  const location = useLocation();

  useNavigationAnalytics();
  useSaveGlobals(contentRef);
  useApiTokens();
  useIntercom();
  useMobile();

  const pathname = document.location.pathname;

  const routes = useMemo(() => {
    const routerBuilder = new RouterBuilder(userStatus, userEmail);
    initRoutes(routerBuilder);
    return routerBuilder.build();
  }, [userStatus, userEmail]);

  const navigateToV2 = (to: string = '') => {
    to = to.includes('/v2') ? to : V2_PATHS_MAP[to];
    window.location.href = window.location.origin + to;
  };

  const navigateToNewAppFlag = window.__env__.VITE_NAVIGATE_TO_NEW_APP === 'true';

  const shouldNavigateToNewApp =
    navigateToNewAppFlag &&
    (Object.keys(V2_PATHS_MAP).includes(pathname) || pathname.includes('/v2'));

  if (pathname.startsWith('/demo')) {
    return <DemosRootContainer />;
  }

  if (shouldNavigateToNewApp) {
    navigateToV2(pathname);
    return;
  }

  if (
    !isMobileOpen &&
    isMobile &&
    !pathname.startsWith('/widget') &&
    !pathname.startsWith('/lp/use-cases')
  ) {
    return <MobilePage />;
  }

  if (userStatus === UserStatus.NOT_LOADED) {
    return <Loader full />;
  }

  if (shouldVerifyEmail) {
    return <CheckEmail />;
  }

  const breadCrumbsItems = [
    {
      id: 'evaluation',
      title: 'Generation sets',
      path: '/tools/generation-sets',
    },
    {
      id: 'evaluation',
      title: 'Evaluation tool',
      path: '/tools/evaluation',
    },
    {
      id: 'data-collections',
      title: 'Data collections',
      path: '/datasets/data-collections',
    },
    {
      id: 'rag-engine',
      title: 'RAG Engine',
      path: '/rag-engine',
    },
    {
      id: 'home',
      title: 'Home',
      path: '/home',
    },
    {
      id: 'use-cases',
      title: 'Use cases',
      path: '/use-cases',
    },
    {
      id: 'models',
      title: 'Our Models',
      path: '/models',
    },
    {
      id: 'chat',
      title: 'Chat',
      path: '/home/chat',
    },
    {
      id: 'complete',
      title: 'Complete',
      path: '/home/complete',
    },
    {
      id: 'contextual-answers',
      title: 'Contextual Answers',
      path: '/home/task-specific-models/contextual-answers',
    },
    {
      id: 'summarize',
      title: 'Summarize',
      path: '/home/task-specific-models/summarize',
    },
    {
      id: 'paraphrase',
      title: 'Paraphrase',
      path: '/home/task-specific-models/paraphrase',
    },
    {
      id: 'text-improvements',
      title: 'Text Improvements',
      path: '/home/task-specific-models/text-improvements',
    },
    {
      id: 'grammatical-error-correction',
      title: 'Grammatical Error Corrections',
      path: '/home/task-specific-models/grammatical-error-correction',
    },
    {
      id: 'demos',
      title: 'Demos (CA)',
      path: '/tools/demos',
    },
    {
      id: 'change-payment',
      title: 'Account details',
      path: '/account/change-payment',
    },
    {
      id: 'payment',
      title: 'Subscription',
      path: '/payment',
    },
  ];

  return (
    <Wrapper>
      <BreadcrumbsProvider
        items={[...sideBarItems, ...breadCrumbsItems]}
        onNavigate={(path: string) => {
          navigate(path);
        }}
      >
        <SideBarContainer />
        <TopBarContainer />
        <Suspense fallback={<Loader debounce={120} full />}>
          <ContentProvider className='site-content' isSlim={slimSideBar} contentRef={contentRef}>
            <Routes>{routes}</Routes>
          </ContentProvider>
        </Suspense>
        <CommandBarContainer />
        <AnalyticsLogsContainer />
        <AuthLogsContainer />
      </BreadcrumbsProvider>
    </Wrapper>
  );
}
