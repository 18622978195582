import { useEffect, useState } from 'react';
import { useList } from 'react-use';
import { addListener, downloadJson } from 'shared-base';
import { getJson, removeJson, setJson } from '../../utils/storage';
import { Event, EventLog, LoggerConfig } from './EventLogs.types';
import { deltaTimestamp } from './EventLogs.utils';

let index = 0;

type UseEventDataReturn = [
  EventLog[],
  Event[],
  Event | undefined,
  {
    clearEvents: () => void;
    showHistory: (id: string) => void;
    setLastEvent: (event: Event) => void;
    downloadEvents: () => void;
  }
];

export function useEventData(config: LoggerConfig): UseEventDataReturn {
  const { id, eventId, persistent } = config;
  const cachedStorageId = `CACHED_LOGS_${id}`;
  const cachedLogs: any = getJson(cachedStorageId) || config.initialLogs;
  const initialLogs = persistent ? cachedLogs : config.initialLogs;

  const [logs, { updateAt, push: pushLog }] = useList<EventLog>(initialLogs);
  const [allEvents, { push }] = useList<Event>([]);
  const [lastEvent, setLastEvent] = useState<Event>();

  const automaticEventIds = config.initialLogs.map((log) => log.id);

  useEffect(() => {
    const callback = (data: any) => {
      setLastEvent(data);
      const { eventType, props } = data as any;

      let log = logs.find((log) => log.id === eventType);

      if (!log) {
        // create new log
        const newLog = {
          id: eventType,
          title: eventType,
          count: 0,
          events: [],
          isAuto: automaticEventIds.includes(eventType),
        };

        pushLog(newLog);
        log = newLog;
      }

      if (!log) {
        return;
      }

      const logIndex = logs.indexOf(log);

      log.count += 1;

      const newEvent: Event = {
        index: index++,
        timestamp: deltaTimestamp(),
        eventType,
        props,
        isAuto: automaticEventIds.includes(eventType),
      };

      log.events.push(newEvent);
      push(newEvent);

      updateAt(logIndex, log);
    };

    const unlisten = addListener(eventId, callback);

    return () => {
      unlisten();
    };
  }, [logs]);

  const clearEvents = () => {
    removeJson(cachedStorageId);
    document.location.reload();
  };

  const downloadEvents = () => {
    downloadJson('eventTimeline.json', logs);
  };

  const showHistory = (id: string) => {
    const log = logs.find((log) => log.id === id);

    if (!log) {
      return;
    }

    setLastEvent([...log.events].reverse() as any);
  };

  useEffect(() => {
    setJson(cachedStorageId, logs as any);
  }, [logs]);

  return [
    logs,
    allEvents,
    lastEvent,
    {
      showHistory,
      setLastEvent,
      clearEvents,
      downloadEvents,
    },
  ];
}
