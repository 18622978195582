import { api } from '@ai21/studio-api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDemo } from '../../hooks/useDemo';
import { DemosPinCodePage } from './DemosPinCodePage';

export type DemosPinCodePageContainerProps = {
  isUnlocked: (state: boolean) => void;
};
export function DemosPinCodePageContainer(
  props: DemosPinCodePageContainerProps
) {
  const { isUnlocked } = props;
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const [data, isLoading] = useDemo();
  const [message, setMessage] = useState('');

  async function checkPincode(code: string) {
    const response = await api.pincode.validate({
      pinCode: code,
      demoId: data?.id,
    });

    if (response.isSuccess) {
      setCount(0);
      isUnlocked(true);
    } else {
      setMessage('Wrong pin code, please try again');
      setCount(count + 1);
    }
  }

  const onComplete = (value: string, index: number) => {
    checkPincode(value);
  };

  return <DemosPinCodePage onComplete={onComplete} message={message} />;
}

export default DemosPinCodePageContainer;
