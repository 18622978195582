const map: any = {
  'j2-ultra-instruct': 'j2-ultra',
  'j2-mid-instruct': 'j2-mid',
  'j2-ultra': 'j2-ultra',
  'j2-mid': 'j2-mid',
  'j2-light': 'j2-light',
  'j2-jumbo-instruct': 'j2-ultra',
  'j2-grande-instruct': 'j2-mid',
  'j2-jumbo': 'j2-ultra',
  'j2-grande': 'j2-mid',
  'j2-large': 'j2-light',
  'j1-grande-instruct': 'j2-mid',
  'j1-grande-v2': 'j2-mid',
  'j1-jumbo': 'j2-ultra',
  'j1-grande': 'j2-mid',
  'j1-large': 'j2-light',
  'jamba-instruct-preview': 'jamba-instruct-preview',
  'jamba-1.5-large': 'jamba-1.5-large',
  'jamba-1.5-mini': 'jamba-1.5-mini',
};

export function mapLegacyModels(model?: string) {
  if (!model) {
    return 'j2-light';
  }

  if (model in map) {
    return map[model];
  }

  return model;
}
