import { BlackCircles } from './BlackCircles';
import { BlueCircles } from './BlueCircles';
import { BrownCircles } from './BrownCircles';
import { CompletionApiIcon } from './CompletionApiIcon';
import { GreenCircles } from './GreenCircles';
import { OrangeCircles } from './OrangeCircles';
import { PinkCircles } from './PinkCircles';
import { PlaygroundIcon } from './PlaygroundIcon';
import { PurpleCircles } from './PurpleCircles';
import { RedCircles } from './RedCircles';
import { RewriteApiIcon } from './RewriteApiIcon';
import { SummarizationApiIcon } from './SummarizationApiIcon';
import { YellowCircles } from './YellowCircles';

export const presetCatalogue = {
  BlackCircles: BlackCircles,
  BlueCircles: BlueCircles,
  BrownCircles: BrownCircles,
  CompletionApi: CompletionApiIcon,
  GreenCircles: GreenCircles,
  OrangeCircles: OrangeCircles,
  PinkCircles: PinkCircles,
  Playground: PlaygroundIcon,
  PurpleCircles: PurpleCircles,
  RedCircles: RedCircles,
  RewriteApi: RewriteApiIcon,
  SummarizationApi: SummarizationApiIcon,
  YellowCircles: YellowCircles,
};
