import styled from 'styled-components';
import { Typography } from '../Typography/Typography';

export const Wrapper = styled.div`
  flex: 1;
  width: 560px;
  border-top: 1px solid #e5e5e5;
  margin-top: 10px;

  &.noError {
    .top {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
  }
`;

export const Top = styled.div`
  padding-top: 6px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
`;

export const Content = styled.div`
  margin-top: 10px;
  max-height: 316px;
  overflow-y: auto;
  padding-right: 10px;
`;

export const Header = styled(Typography)``;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  border-top: 1px solid #e5e5e5;
  padding-top: 12px;
`;
