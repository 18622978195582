import { AnalyticsEvent } from '../analytics.types';
import { config } from './globals';

export const parseEvent = (event: AnalyticsEvent, options: Json) => {
  const { app, addPath } = options;

  let output: AnalyticsEvent = {
    eventId: event.eventId,
    props: {
      ...event.props,
      app,
    },
  };

  if (addPath) {
    const { pathname } = window.location;
    output.props.path = pathname;
    output.props.page = findPage(pathname);
  }

  output = transformEvent(output, options);

  return output;
};

export const transformEvent = (event: AnalyticsEvent, options: Json) => {
  const output = cloneEvent(event);
  const relevantTransform = findTransformation(event);

  if (!relevantTransform) {
    return output;
  }

  const { eventId: newEventId, props: renameProps } = relevantTransform.output;

  if (newEventId) {
    output.eventId = newEventId;
  }

  if (renameProps) {
    Object.keys(renameProps).forEach((key) => {
      const currentKey = renameProps[key] as string;
      const value = output.props[currentKey];

      delete output.props[currentKey];
      output.props[key] = value;
    });
  }

  return output;
};

export const cloneEvent = (event: AnalyticsEvent) => ({
  eventId: event.eventId,
  props: { ...event.props },
});

export const findTransformation = (event: AnalyticsEvent) => {
  const { transform = [] } = config;

  return transform.find((condition) => {
    return isEqual(event, condition.input);
  });
};

export const isEqual = (event: AnalyticsEvent, condition: AnalyticsEvent) => {
  const { eventId, props } = condition;

  if (event.eventId !== eventId) {
    return false;
  }

  return Object.keys(props).every((key) => {
    return event.props[key] === props[key];
  });
};

export const findPage = (pathname: string) => {
  let output = config.pages[pathname];

  if (output) {
    return output;
  }

  // is /evaluation/:evaluationId/inbox equals to pathname

  const relevantKey = Object.keys(config.pages).find((key) => {
    const regexText = key.replace(/:[^.*$]+/g, '[^.*$]+');
    const regex = new RegExp(regexText);
    const match = pathname.match(regex);

    return match && match[0] === pathname;
  });

  if (relevantKey) {
    return config.pages[relevantKey];
  }
};
