export const documents: any[] = [
  {
    id: 1,
    fileId: 'c8db2ab5-1ff3-4d33-9c4d-cfe175d6507f',
    text: 'Patient Records',
    label: 'PatientsRecord',
    path: '/temp/Patients-Record.png',
    defaultQuestions: [
      'What is the patient’s major complaint?',
      "What is the patient's oxygen saturation level?",
      'Have the patient’s symptoms been improving?',
      'Does the patient have a family history of asthma?',
    ],
  },
  {
    id: 2,
    fileId: '9454d7f6-f5d4-4b7b-b896-e891016b5f23',
    text: 'Medical Device Documentation',
    label: 'MedicalDevice',
    path: '/temp/Medical-Device.png',
    defaultQuestions: [
      'Is the insulin pump waterproof?',
      'What is the next step after connecting the insulin pump to the infusion set?',
      'Should I use an insulin pump or an insulin pen?',
    ],
  },
  {
    id: 3,
    fileId: '972350d1-2aa6-46d1-8cc0-183798d7850b',
    text: 'Clinical Trial Summary',
    label: 'ClinicalTrial',
    selected: true,
    path: '/temp/Clinical-Trial.png',
    defaultQuestions: [
      'How many people participated in the REBECCA II study, and what were the requirements for participating?',
      'What kind of intervention did the experimental group receive in the REBECCA II study?',
      'Which organizations collaborated on the REBECCA II study?',
      'Can I enroll in the trial if I have dementia?',
    ],
  },
];

export const menuItems: any[] = [
  {
    id: 'settings',
    title: 'Settings',
    iconName: 'Settings',
    action: {
      type: '{parentActionType}',
      verb: 'settings',
      id: '{id}',
    },
  },
  {
    id: 'copyId',
    title: 'Copy job ID',
    iconName: 'Copy',
    action: {
      type: '{parentActionType}',
      verb: 'copyId',
      id: '{id}',
    },
  },
  {
    id: 'deleteJob',
    title: 'Delete job',
    iconName: 'Trash',
    action: {
      type: '{parentActionType}',
      verb: 'delete',
      id: '{id}',
    },
  },
];
