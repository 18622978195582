import evaluationEdit from './json/form.evaluationEdit.json';
import evaluationEditDefaults from './form.evaluationEdit.detaults.json';

export const forms = {
  evaluationEdit,
};

export const formDefaults = {
  evaluationEdit: evaluationEditDefaults,
};
