export function EnterIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.422 1.579v9.224a4.612 4.612 0 0 1-4.612 4.611H2.437m0 0 6.15-6.149m-6.15 6.15 6.15 6.149'
        stroke='#C3C6CE'
        strokeWidth='1.091'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
