export type LanguageButton = {
  id: ProgrammingLanguage;
  title: string;
  iconName: string;
};

export type ProgrammingLanguage = 'python' | 'javascript' | 'curl';

export const buttons: LanguageButton[] = [
  {
    id: 'python',
    title: 'Python',
    iconName: 'Python',
  },
  {
    id: 'javascript',
    title: 'Javascript',
    iconName: 'Javascript',
  },
  {
    id: 'curl',
    title: 'curl',
    iconName: 'Curl',
  },
];

export type Snippet = Record<ProgrammingLanguage, string>;
