import styled from 'styled-components';
import { A } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  line-height: 1.5;
  color: ${tokens.text.secondary};
  margin-top: 10px;
`;

export const TermsLink = styled(A)`
  text-decoration-color: ${tokens.text.secondary};
  color: ${tokens.text.secondary};
`;
