import { api } from '@ai21/studio-api';
import {
  IBox,
  selectors,
  isFeatureFlagEnabled,
  IUser,
} from '@ai21/studio-store';
import { toast } from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, select } from 'saga-ts';
import { guid4, invokeEvent } from 'shared-base';
import { patchBox } from './boxes';

export type ChatActionId = 'jsonChange' | 'messageEdit' | 'messageRegenerate';

export function* clearChatInput() {
  const boxId = 'i-chat-prompt';

  invokeEvent('BaseEditorClear', {
    id: boxId,
  });

  const box = yield* select(selectors.singles.$box, boxId);

  if (!box) {
    return;
  }

  yield* call(patchBox, box, {
    prompt: '',
  });
}

export function* addToChatOutput(newContent: string) {
  const boxId = 'i-chat-output';

  const box = yield* select(selectors.singles.$box, boxId);

  if (!box) {
    return;
  }

  const { values } = box;
  let { content = '' } = values || {};

  content = content + '\n\n' + newContent;

  yield* call(patchBox, box, {
    content,
    isLoading: false,
  });
}

export function* setMessages(box: IBox, messages: Json[]) {
  const editorBox = yield* select(selectors.playground.$editorBox);

  yield* call(patchBox, box, { messages });

  yield* call(patchBox, editorBox, {
    content: JSON.stringify(messages, null, 2),
  });
}

export function* addMessage(box: IBox, message: Json) {
  const { values } = box;
  const { messages = [] } = values;

  const newMessage = {
    ...message,
  };

  if (!newMessage.id) {
    newMessage.id = guid4();
  }

  if (!newMessage.role) {
    newMessage.role = 'user';
  }

  messages.push(newMessage);

  yield call(setMessages, box, messages);
}

export function* updateMessage(
  box: IBox,
  messageId: string,
  change: Json,
  shoudUpdateEditor = true
) {
  const { values } = box;
  const { messages = [] } = values;

  const message = messages.find((m: Json) => m.id === messageId);

  if (!message) {
    return;
  }

  Object.assign(message, change);

  if (shoudUpdateEditor) {
    yield call(setMessages, box, messages);
  }
}

export function* clearMessages(box: IBox) {
  yield call(setMessages, box, []);
}

export function* hideFeedback(
  box: IBox,
  messageIndex: number,
  actionId: ChatActionId
) {
  const messages = get(box, 'values.messages', []);
  const feedbackMap = get(box, 'values.feedbackMap', []);

  const newFeedbackMap = messages.reduce(
    (acc: Json, message: Json, index: number) => {
      const { id } = message;
      let shouldShowFeedback = true;
      if (
        (messages[messageIndex].id === id && actionId === 'messageEdit') ||
        (((feedbackMap[id] !== undefined && !feedbackMap[id]) ||
          index > messageIndex) &&
          messages[messageIndex].id !== id)
      ) {
        shouldShowFeedback = false;
      }
      acc[id] = shouldShowFeedback;
      return acc;
    },
    {} as Json
  );

  yield call(patchBox, box, {
    feedbackMap: newFeedbackMap,
  });
}

export function* hideAllFeedbacks(
  messageIndex: number,
  actionId: ChatActionId
) {
  const chatBox = yield* select(selectors.playground.$chatBox);
  const editorBox = yield* select(selectors.playground.$editorBox);
  if (!chatBox || !editorBox) {
    return;
  }

  yield call(hideFeedback, chatBox, messageIndex, actionId);
  yield call(hideFeedback, editorBox, messageIndex, actionId);
}

export function* generateMessage(messages: []) {
  const instructionsText = yield* select(selectors.playground.$instructions);
  const paramsBox = yield* select(selectors.playground.$paramsBox);

  const user: IUser = yield* select(selectors.raw.$rawUser);
  const isJambaChatEnabled: boolean = isFeatureFlagEnabled(
    user,
    'isJambaChatEnabled'
  );

  const { values } = paramsBox || {};
  const tsStart = Date.now();

  let response;
  if (isJambaChatEnabled) {
    const chatPayloadParams = {
      model: 'jamba-instruct-preview',
      n: 1,
      max_tokens: values.maxTokens,
      temperature: values.temperature,
      top_p: values.topP,
      stop: values.stopSequences,
    };

    const formatedMessages = messages.map((message: any) => {
      return { content: message.text, role: message.role };
    });

    response = yield* call(
      api.completion.chat,
      {
        messages: [
          { content: instructionsText, role: 'system' },
          ...formatedMessages,
        ],
        ...chatPayloadParams,
      },
      { isJambaChatEnabled }
    );
  } else {
    response = yield* call(
      api.completion.chat,
      {
        system: instructionsText,
        messages,
        ...values,
      },
      { isJambaChatEnabled }
    );
  }

  const tsEnd = Date.now();

  const apiDurationMillis = tsEnd - tsStart;

  if (response.isSuccess) {
    const newMessage = get(response, 'data.outputs[0]', {});

    const finishReason = get(newMessage, 'finishReason');
    const reason = get(finishReason, 'length');
    if (reason) {
      toast.show(
        'Text generation has ended early due to the maximum completion length you have set.',
        'info'
      );
    }
  }
  return response;
}
