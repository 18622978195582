import { AskEditor } from '@ai21/studio-editors';
import { actions, selectors } from '@ai21/studio-store';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export type AskEditorContainerProps = {
  data: Json;
  onClose: () => void;
};

export function AskEditorContainer(props: AskEditorContainerProps) {
  const { data } = props;
  const { labels = [] } = data.params ?? {};
  const dispatch = useDispatch();
  const questions = useSelector(selectors.documents.$documentQuestions);
  const fileId = useSelector(selectors.raw.$rawCurrentIds).askSpecificDocumentIds; // prettier-ignore
  const fileName = useSelector(selectors.raw.$rawDocuments)[fileId || ''];
  const [filteredQuestions, setFilteredQuestions] = useState(questions);

  useEffect(() => {
    const filteredQuestions = questions.filter((question) => {
      return question.source === 'demo';
    });
    setFilteredQuestions(filteredQuestions);
  }, [questions]);

  const callbacks = useMemo(
    () => ({
      onAction: (verb: any, params?: Json) => {
        dispatch({
          type: 'DOCUMENT',
          verb,
          params: { ...params, source: 'demo' },
        });
      },
      onPrompt: (params?: Json) => {
        dispatch({
          type: 'DOCUMENT',
          verb: 'ask',
          params: {
            ...params,
            source: 'demo',
            labels,
          },
        });
      },
      askAllLibrary: () => {
        dispatch(
          actions.currentIds.patch({
            askSpecificDocumentIds: '',
          })
        );
        dispatch({ type: 'DOCUMENT', verb: 'clear' });
      },
      onClose: () => {
        props.onClose();

        dispatch(
          actions.currentIds.patch({
            askSpecificDocumentIds: '',
          })
        );
        dispatch({
          type: 'DOCUMENT',
          verb: 'clear',
          params: { source: 'demo' },
        });
      },
    }),
    [labels]
  );

  const ctaBkColor = get(data, 'theme.ctaBkColor', '');
  const ctaColor = get(data, 'theme.ctaColor', '');
  const exampleQuestions = get(data, 'params.questions', '');

  return (
    <AskEditor
      items={filteredQuestions}
      callbacks={callbacks}
      open={true}
      fileName={fileName ? fileName.name : ''}
      ctaBkColor={ctaBkColor}
      ctaColor={ctaColor}
      exampleQuestions={exampleQuestions}
      demo
    />
  );
}
