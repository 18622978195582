import { Flavour, Icon, NameAvatar, Rank, Status, format, toast } from '@ai21/studio-base-ui';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { FC, useMemo } from 'react';
import { CellType, ITableField } from '../../types';
import { noun } from '../../utils/singular';
import TextWithTooltip from '../TextWithTooltip/TextWithTooltip';
import {
  Copy,
  CopyTooltip,
  Date,
  IconWrapper,
  Id,
  Name,
  NameDescription,
  NameDetails,
  NameIcon,
  Number,
  Progress,
  SubText,
  Text,
  Time,
  Wrapper,
} from './TableCell.style';

dayjs.extend(advancedFormat);

export type TableCellProps = {
  id?: string;
  index: number;
  field: ITableField;
  data: Json;
  onRowAction: (actionId: string) => void;
  rowIndex?: number;
};

export function TableCell(props: TableCellProps) {
  const { field, data, index, rowIndex } = props;

  const { id, cellType, mapFields } = field;

  const cellData = useMemo(() => {
    if (!mapFields) {
      return {
        value: data[id],
      };
    }

    return Object.keys(mapFields).reduce((output, key) => {
      const value = mapFields[key];

      output[key] = data[value];
      return output;
    }, {} as Json);
  }, [mapFields, data]);

  const Cmp = map[cellType as CellType];

  if (!Cmp) {
    console.log(`could not find cellType: ${cellType}`);
    return null;
  }

  return (
    <Wrapper className='TableCell-wrapper' data-testid='TableCell-wrapper'>
      <Cmp
        index={index}
        id={id}
        field={field}
        data={cellData}
        onRowAction={props.onRowAction}
        rowIndex={rowIndex}
      />
    </Wrapper>
  );
}

export function TableCellText(props: TableCellProps) {
  const { data } = props;
  const { value, value2 } = data;

  return (
    <>
      <Text>{value}</Text>
      {value2 && <Text>{value2}</Text>}
    </>
  );
}

export function TableCellAvatar(props: TableCellProps) {
  const { data } = props;
  const { value } = data;

  const people = ['David Katz', 'Peter Smith', 'John Doe', 'Jane Doe'];

  return (
    <>
      <NameAvatar people={people} />
    </>
  );
}

export function TableCellTextCopy(props: TableCellProps) {
  const { data } = props;
  const { value } = data;

  function onClick() {
    navigator.clipboard.writeText(value);
    toast.show('ID number copied to clipboard');
  }

  return (
    <CopyTooltip title='Click to copy'>
      <Copy onClick={onClick}>{value}</Copy>
    </CopyTooltip>
  );
}

function renderNameIcon(icon: any) {
  if (!icon) return null;
  return (
    <NameIcon>
      <Icon iconName={icon} />
    </NameIcon>
  );
}

export function TableCellName(props: TableCellProps) {
  const { data, field } = props;
  const { value, subtitle, icon } = data;
  const { params = {} } = field;

  return (
    <Name>
      {renderNameIcon(icon)}
      <NameDetails>
        {params.noTooltip ? value : <TextWithTooltip>{value}</TextWithTooltip>}
        {subtitle && <NameDescription variant='body2'>{subtitle}</NameDescription>}
      </NameDetails>
    </Name>
  );
}

export function TableCellNumber(props: TableCellProps) {
  const { data, field } = props;
  const { params = {} } = field;
  const { flavour, units = '' } = params;
  const { value, quantity } = data;

  const numberText = useMemo(() => {
    switch (flavour) {
      case 'bytes':
        return format.number.bytes(value);
      case 'price':
        return format.number.price(value);
      case 'percent':
        return format.number.percent(value);
      default:
        return isNaN(value) ? '-' : format.number.normal(value);
    }
  }, [value]);

  const unitsText = useMemo(() => {
    if (quantity === undefined) {
      return null;
    }

    if (units) {
      return `(${quantity}  ${noun(quantity, units)})`;
    } else {
      return `(${quantity})`;
    }
  }, []);

  return (
    <>
      <Number>
        {numberText} {unitsText}
      </Number>
    </>
  );
}

export function TableCellStatus(props: TableCellProps) {
  const { data } = props;
  const { value, tooltip, link, progress } = data;
  return (
    <>
      <Status
        value={value}
        tooltip={tooltip}
        link={link}
        progress={progress}
        onClick={() => props.onRowAction('status_clicked')}
      />
    </>
  );
}

export function TableCellFlavour(props: TableCellProps) {
  const { data } = props;
  const { value, color } = data;

  return (
    <>
      <Flavour value={value} color={color} />
    </>
  );
}

export function TableCellIcon(props: TableCellProps) {
  const { data, field } = props;
  const { params = {} } = field;
  const { iconName } = params;
  const { show } = data;

  return <IconWrapper>{show && <Icon iconName={iconName} />}</IconWrapper>;
}

export function TableCellDate(props: TableCellProps) {
  const { data } = props;
  const { value } = data;

  const dateText = useMemo(() => {
    return value !== undefined ? format.date.normal(value) : '-';
  }, [value]);

  return (
    <>
      <Date>{dateText}</Date>
    </>
  );
}

export function TableCellTime(props: TableCellProps) {
  const { data } = props;
  const { value } = data;

  const timeText = value;
  const parts = timeText.split(' ');

  return (
    <Time className='time'>
      {parts[0]}
      <span>{parts[1]}</span>
    </Time>
  );
}

export function TableCellRank(props: TableCellProps) {
  const { data } = props;
  const { value } = data;

  return (
    <>
      <Rank value={value} />
    </>
  );
}

export function TableCellTimeAgo(props: TableCellProps) {
  const { data } = props;
  const { value } = data;

  const dateText = useMemo(() => {
    return format.date.normal(value);
  }, [value]);

  const timeAgoText = useMemo(() => {
    return format.date.timeAgo(value);
  }, [value]);

  return (
    <Text>
      <TextWithTooltip>{timeAgoText}</TextWithTooltip>
    </Text>
  );
}

export function TableCellId(props: TableCellProps) {
  const { data } = props;
  const { value } = data;

  return (
    <>
      <Id>{value}</Id>
    </>
  );
}

export function TableCellProgressBar(props: TableCellProps) {
  const { data } = props;
  const { value } = data;

  return (
    <>
      <Progress variant='determinate' value={value ?? 0} />
    </>
  );
}

export function TableCellSequence(props: TableCellProps) {
  const { rowIndex = 0 } = props;

  return <>{rowIndex + 1}</>;
}

export function TableCellSelection(props: TableCellProps) {
  const { data } = props;
  const { value } = data;

  return (
    <>
      <Text>{value}</Text>
    </>
  );
}

export function TableCellProgress(props: TableCellProps) {
  const { data } = props;
  const { value, currentCount, totalCount } = data; // value

  // 100%
  const progressText = useMemo(() => {
    return format.number.percent(value);
  }, [value]);

  // 10/20 or 10 or ''
  let XofY = currentCount === undefined ? '' : (currentCount as number);
  if (totalCount) {
    XofY += `/${totalCount}`;
  }

  if (value !== undefined) {
    return (
      <Text>
        {progressText} ({XofY})
      </Text>
    );
  } else {
    return <Text>{XofY}</Text>;
  }
}

const map: Record<CellType, FC<TableCellProps>> = {
  id: TableCellId,
  avatar: TableCellAvatar,
  text: TableCellText,
  textCopy: TableCellTextCopy,
  number: TableCellNumber,
  name: TableCellName,
  rank: TableCellRank,
  status: TableCellStatus,
  flavour: TableCellFlavour,
  icon: TableCellIcon,
  sequence: TableCellSequence,
  date: TableCellDate,
  progressBar: TableCellProgressBar,
  progress: TableCellProgress,
  time: TableCellTime,
  timeAgo: TableCellTimeAgo,
  select: TableCellSelection,
};

export default TableCell;
