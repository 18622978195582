import Variant from "./types/Variant";
import Variants from "./types/Variants";
import ExperimentProvider from "./types/ExperimentProvider";
import { Optional } from "./types/types";
import TelemetryClient from "./TelemetryClient";
import {
  allVariantsInvokedCounter,
  getVariantInvokedCounter,
  getVariantReturnedFallbackCounter,
} from "./utils/counters";

/**
 * A client for getting `Variant`s.
 */
class VariantsClient {
  private readonly experimentProvider: ExperimentProvider;

  constructor(experimentProvider: ExperimentProvider) {
    this.experimentProvider = experimentProvider;
  }

  /**
   * Returns a Variant for the relevant flag key.
   *
   * Access the variant from the {@link ExperimentProvider},
   * falling back on the given fallbackVariant.
   *
   * Manages analytics report (if needed) and telemetry.
   *
   * @param {string} flagKey The key to get the variant for.
   * @param {Variant} fallbackVariant The highest priority fallback
   * @return {Variant | undefined} The relevant variant, if exists.
   */
  get(flagKey: string, fallbackVariant?: Variant): Optional<Variant> {
    TelemetryClient.debug("Variants get called", { flagKey });

    getVariantInvokedCounter?.add(1);

    const variant = this.experimentProvider.getVariant(flagKey);
    if (!variant) {
      getVariantReturnedFallbackCounter?.add(1);
      TelemetryClient.debug("Got fallback variant", {
        variant: fallbackVariant?.value,
      });
      TelemetryClient.debug("No variant was found for `flagKey`, returning fallback variant", {
        flagKey,
        fallbackHasValue: !!fallbackVariant,
      });

      return fallbackVariant;
    }

    TelemetryClient.debug("Got variant", { variant: variant.value });

    return variant;
  }

  /**
   * Returns all Variants.
   *
   * Access the variants from the {@link ExperimentProvider}.
   *
   * Manages telemetry.
   *
   * @return {Variants} The relevant variant, if exists.
   */
  all(): Variants {
    TelemetryClient.debug("Variants all called");

    allVariantsInvokedCounter?.add(1);

    const allVariants = this.experimentProvider.allVariants();

    TelemetryClient.debug("Got all variants");

    return allVariants;
  }
}

export default VariantsClient;
