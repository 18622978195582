import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  padding: 0px 16px 16px 16px;
`;

export const Container = styled.div`
  padding: 0 24px;
`;
