import { IconButton, Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';

export const Wrapper = styled.div``;

export const InputAndButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UrlInput = styled.input`
  margin-right: 8px;
  background-color: #e6eaec;
  width: 90%;
  border: 0;
  font-size: 15px;
  color: #787a80;
  padding: 10px;
  border-radius: 8px;
`;

export const CopyButton = styled(IconButton)`
  width: 38px;
  height: 38px;
`;
