export const fixLineContent = (content: Json[]) => {
  return content.map((i) => {
    const output: Json = {};

    Object.keys(acceptableLineFields).forEach((field) => {
      const dest = (acceptableLineFields as any)[field] as string[];
      dest.forEach((possible) => {
        if (i[possible]) {
          output[field] = i[possible];

          if (parsers[field]) {
            output[field] = parsers[field](output[field]);
          }
        }
      });
    });

    return output;
  });
};

const parsers: Record<string, any> = {
  stopSequences: (value: string) => {
    if (typeof value !== 'string') return [];
    return value.split('|').map((i) => i.trim());
  },
};

export const acceptableLineFields = {
  creator: ['creator', 'owner'],
  prompt: ['prompt', 'input', 'source', 'prompt_text', 'promptText'],
  completion: ['completion', 'output', 'target', 'completion_text', 'completionText'],
  comment: ['comment'],
  generationDate: ['generationDate', 'generation_date'],
  modelName: ['modelName', 'model_name', 'model'],
  foundationModelName: ['foundationModelName', 'foundation_model_name'],
  temperature: ['temperature'],
  epoch: ['epoch'],
  topP: ['topP', 'top_p'],
  maxTokens: ['maxTokens', 'max_tokens'],
  minTokens: ['minTokens', 'min_tokens'],
  topKReturn: ['topKReturn', 'top_k_return'],
  presencePenalty: ['presencePenalty', 'presence_penalty'],
  countPenalty: ['countPenalty', 'count_penalty'],
  stopSequences: ['stopSequences', 'stop_sequences'],
  frequencyPenalty: ['frequencyPenalty', 'frequency_penalty'],
  penalizeWhitespaces: ['penalizeWhitespaces', 'penalize_whitespaces'],
  penalizePunctuations: ['penalizePunctuations', 'penalize_punctuations'],
  penalizeNumbers: ['penalizeNumbers', 'penalize_numbers'],
  penalizeStopwords: ['penalizeStopwords', 'penalize_stopwords'],
  penalizeEmojis: ['penalizeEmojis', 'penalize_emojis'],
};
