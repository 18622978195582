// version 0.0.1
export const primitives = {
  purple: {
    0: '#FFFFFF',
    10: '#FAFAFF',
    50: '#F4F3FF',
    100: '#ECE9FE',
    200: '#DBD7FD',
    300: '#C1B6FC',
    400: '#A28DF8',
    500: '#845FF3',
    600: '#753FEA', //Main Primary
    700: '#652BD6',
    800: '#5424B3',
    900: '#461F93',
    950: '#2A1263',
    1000: '#210D52',
    1050: '#18083C',
    1100: '#0F0427',
  },
  blue: {
    0: '#FFFFFF',
    10: '#F4FAFF',
    50: '#EDF7FF',
    100: '#E6F3FF',
    200: '#DBEEFF',
    300: '#BEDFFF',
    400: '#97C7FF',
    500: '#6EA4FF',
    600: '#4C81FF',
    700: '#3D67FF', //Main
    800: '#2046E2',
    900: '#1D3EB6',
    950: '#102568',
    1000: '#132053',
    1050: '#0B153E',
    1100: '#050B24',
  },
  green: {
    0: '#FFFFFF',
    10: '#F8FFF9',
    50: '#F0FDF3',
    100: '#DBFDE4',
    200: '#B9F9C9',
    300: '#8BE9A4',
    400: '#79D693',
    500: '#27C152',
    600: '#11A83C',
    700: '#118432', //Main
    800: '#13682C',
    900: '#125527',
    950: '#033012',
    1000: '#03280F',
    1050: '#011D0A',
    1100: '#011507',
  },
  lime: {
    0: '#FFFFFF',
    10: '#FCFFF3',
    50: '#F7FDE8',
    100: '#EDFBD2',
    200: '#E2F5B9',
    300: '#BCEB6B',
    400: '#AADD3E',
    500: '#96C82E',
    600: '#749F18', //Main
    700: '#5E8014',
    800: '#3E5E19',
    900: '#2F4712',
    950: '#1A2C07',
    1000: '#152405',
    1050: '#0F1B03',
    1100: '#0A1301',
  },
  yellow: {
    0: '#FFFFFF',
    10: '#FFFEF5',
    50: '#FEFCE8',
    100: '#FBF0CA',
    200: '#F6EA91',
    300: '#F8DF60',
    400: '#E9C43E',
    500: '#EFB103',
    600: '#CE8800',
    700: '#A46004', //Main
    800: '#874B0C',
    900: '#733D10',
    950: '#431F05',
    1000: '#3A1B04',
    1050: '#301603',
    1100: '#220F01',
  },
  red: {
    0: '#FFFFFF',
    10: '#FFFAFA',
    50: '#FFF1F0',
    100: '#FFE0DE',
    200: '#FFC6C2',
    300: '#FF9D97',
    400: '#F0675F',
    500: '#E94940',
    600: '#E32E23',
    700: '#D20F03', //Main
    800: '#AD1107',
    900: '#8F150D',
    950: '#4E0501',
    1000: '#440602',
    1050: '#3C0501',
    1100: '#2D0200',
  },
  beige: {
    0: '#FFFFFF',
    10: '#FCFBF8',
    50: '#F8F7F4',
    100: '#EEEDE6',
    200: '#DCD9CC',
    300: '#C5BFAC',
    400: '#AEA489',
    500: '#9D8F72',
    600: '#907F66',
    700: '#786956', //Main
    800: '#635749',
    900: '#51473D',
    950: '#2B251F',
    1000: '#231E19',
    1050: '#1E1914',
    1100: '#15120F',
  },
  grey: {
    0: '#FFFFFF', //White //Overlay background is 50%
    10: '#FBFBFB', //Almost white
    50: '#F4F5F7', //BrightestSuper
    100: '#E6EAEC', //Brightest
    150: '#DADDE1', //Table borders
    200: '#CCD0D5', //Brighter
    300: '#A8AEB8', //Bright
    400: '#7D8693', //Deep
    500: '#626B78', //Deeper //Main Secondary
    600: '#545A66',
    700: '#484D56',
    800: '#40434A',
    900: '#494949', //Deepest
    950: '#202020',
    1000: '#1D1E22', //Almost black primary text
    1050: '#17181C',
    1100: '#0D0D0F',
    1200: '#000000', //Black //Overlay background is 50%
  },
  coal: {
    0: '#FFFFFF',
    10: '#FCFCFB',
    50: '#F7F7F5',
    60: '#F3F3F1',
    75: '#EFEFED',
    90: '#E9E8E7',
    100: '#E1E1E1',
    150: '#DBDBDB',
    200: '#D4D4D4',
    225: '#CCCCCB',
    250: '#C0BFBF',
    300: '#ADADAD',
    400: '#9B9B9B',
    500: '#838383',
    600: '#5A5A5A',
    650: '#494949',
    700: '#373737',
    800: '#313131',
    900: '#2B2B2B',
    950: '#272727',
    1000: '#252525',
    1050: '#202020',
    1075: '#181818',
    1100: '#141414',
    1150: '#0F0F0F',
  },
  pink: {
    600: '#E91E63',
  },

  component: {
    1: '#E7E6EC', // ChatBubbleAssistant
  },

  //Round corners and spacing
  radius: {
    tiny: 2,
    small: 4,
    medium: 8,
    large: 12,
    largest: 16,
    full: 1000,
  },
};
