import { Loader } from '@ai21/studio-base-ui';
import { useEffect, useState } from 'react';
import { RagEngineAskEditorContainer } from '../../containers/RagEngineAskEditor.container';
import { DocumentLibrarySection } from './DocumentLibrarySection';
import Header from './Header/Header';
import { documents } from './RagEngineDemoPage.data';
import {
  ContentSection,
  LeftSection,
  RightSection,
  Wrapper,
} from './RagEngineDemoPage.style';

export type RagEngineDemoPageProps = {
  data: Json;
  isLoading: boolean;
  callbacks: {
    onClick: () => void;
    onClear: () => void;
    onNavigate: () => void;
  };
};

export function RagEngineDemoPage(props: RagEngineDemoPageProps) {
  const { callbacks, data, isLoading } = props;
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>();
  const [exampleQuestions, setExampleQuestions] = useState<string[]>();
  const [labels, setLabels] = useState<string[]>();

  const handleSelecteDocuemnt = (id: string) => {
    if (selectedDocuments?.includes(id)) {
      setSelectedDocuments(selectedDocuments?.filter((doc) => doc !== id));
    } else {
      setSelectedDocuments([...(selectedDocuments || []), id]);
    }
  };

  useEffect(() => {
    if (selectedDocuments && selectedDocuments.length === 1) {
      const selectedDocument = documents.filter(
        (doc) => doc.id === selectedDocuments[0]
      );
      setExampleQuestions(selectedDocument[0].defaultQuestions);
      setLabels([selectedDocument[0].label]);
    } else {
      setExampleQuestions([]);
      if (selectedDocuments && selectedDocuments.length > 1) {
        setLabels(['ragEngineDemo']);
      } else {
        setLabels([]);
      }
    }
  }, [selectedDocuments]);

  if (isLoading) {
    return <Loader full />;
  }
  //
  return (
    <Wrapper>
      <Header
        data={data}
        onClear={callbacks.onClear}
        onNavigate={callbacks.onNavigate}
      />
      <ContentSection>
        <LeftSection id='leftSection'>
          <DocumentLibrarySection
            handleSelecteDocuemnt={handleSelecteDocuemnt}
            selectedDocuments={selectedDocuments || []}
            callbacks={callbacks}
            documents={documents}
          />
        </LeftSection>
        <RightSection id='rightSection'>
          <RagEngineAskEditorContainer
            data={{
              ...data,
              params: {
                ...data.params,
                labels: labels,
                questions: exampleQuestions,
              },
            }}
          />
        </RightSection>
      </ContentSection>
    </Wrapper>
  );
}
