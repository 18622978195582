export function RefreshIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fill='#626B78'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9917 3.28983C16.3945 3.27667 20.1214 6.53707 20.6936 10.9026C20.7475 11.3133 20.4582 11.6899 20.0475 11.7437C19.6368 11.7975 19.2602 11.5082 19.2064 11.0975C18.7322 7.48039 15.6442 4.77892 11.9962 4.78983C8.3481 4.80074 5.27632 7.52063 4.82383 11.1405C4.37134 14.7605 6.67909 18.1528 10.2122 19.0614C13.3697 19.8733 16.627 18.4755 18.2474 15.7501H14.95C14.5358 15.7501 14.2 15.4143 14.2 15.0001C14.2 14.5859 14.5358 14.2501 14.95 14.2501H19.433C19.4444 14.2498 19.4559 14.2498 19.4675 14.2501H19.95C20.3642 14.2501 20.7 14.5859 20.7 15.0001V20.0001C20.7 20.4143 20.3642 20.7501 19.95 20.7501C19.5358 20.7501 19.2 20.4143 19.2 20.0001V17.0399C17.1534 19.9826 13.4422 21.4408 9.83865 20.5141C5.57452 19.4175 2.7893 15.3234 3.33541 10.9545C3.88152 6.58563 7.58884 3.303 11.9917 3.28983Z'
      />
    </svg>
  );
}
