import { Button, Chip, Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: 1px solid;
  border-color: ${tokens.primary.borderDefault};
  background-color: ${tokens.primary.backgroundDefault};
  border-radius: ${tokens.primitives.radius.large}px;
  padding: 16px;
  max-width: 45%;
  cursor: pointer;
  position: relative;
  margin-top: 24px;
  margin-right: 24px;

  &:hover {
    border: 1px solid ${tokens.primary.borderHover};
    background: ${tokens.primitives.grey[0]};
    border-color: ${tokens.primary.borderHover};
    border-radius: 12px;
    box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.06);
    .outLink {
      display: block;
    }

    &:active {
      background: ${tokens.primitives.grey[10]};
      border-color: ${tokens.primary.borderActive};
      box-shadow: none;
      user-select: none;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-right: 16px;
  width: 52px;
  height: 52px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${tokens.neutral.brightestSuper};
  border-radius: 8px;
  svg {
    color: ${tokens.primary.mainDefault};
  }
`;

export const Title = styled(Typography)`
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  color: ${tokens.text.primary};
`;

export const Flavour = styled.span`
  margin-left: 6px;
  color: ${tokens.primary.mainHover};
`;

export const Description = styled(Typography)`
  color: ${tokens.text.secondary};
  flex: 1;
  margin-right: 50px;
`;

export const Beta = styled.span`
  background: ${tokens.neutral.almostBlack};
  border-radius: 2px;
  padding: 2px 4px;
  text-transform: uppercase;
  color: ${tokens.text.contrast};
  font-size: 10px;
  font-weight: 700;
  margin-left: 6px;
`;

export const New = styled.span`
  background: ${tokens.primitives.blue[50]};
  border-radius: 2px;
  padding: 2px 4px;
  text-transform: capitalize;
  color: ${tokens.primitives.blue[900]};
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Cta = styled(Button)``;

export const Details = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const Badge = styled.div`
  margin-left: 6px;
  font-size: 10px;
  color: white;
  padding: 2px 4px;
  border-radius: 5px;
  top: 8px;
  right: 8px;
  z-index: 9999;

  &.AI21 {
    background-color: #e91e63;
  }
`;
