import { FC, useCallback, useContext, useMemo } from 'react';
import { BreadcrumbsContext } from './Breadcrumbs.context';
import { useBreadcrumbsItems } from './Breadcrumbs.hooks';
import { BreadcrumbsMui, Link, Text } from './Breadcrumbs.style';
import { Box } from '@ai21/studio-base-ui';
import { type IMenuItem } from '@ai21/studio-base-ui';
import { BreadcrumbItem } from './BreadcrumbItem';

export interface BreadcrumbsProps {
  titleMap: Record<string, string>;
  defaultRoute: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ titleMap, defaultRoute }) => {
  const { items, onNavigate } = useContext(BreadcrumbsContext);
  const crumbs = useBreadcrumbsItems(items, defaultRoute);

  const onClickBreadCrumbItem = useCallback(
    (item: IMenuItem) => {
      onNavigate(item.path ?? '');
    },
    [onNavigate]
  );

  const BreadCrumbsItems = useMemo(() => {
    return crumbs.map((item: IMenuItem, index: number) => {
      const isLastItem = index === crumbs.length - 1;
      const itemTitle = titleMap[item.title];
      return (
        <BreadcrumbItem
          item={item}
          itemTitle={itemTitle}
          isLastItem={isLastItem}
          onClick={onClickBreadCrumbItem}
        />
      );
    });
  }, [crumbs, titleMap, onClickBreadCrumbItem]);

  return (
    <Box className='Breadcrumbs-wrapper' data-testid='Breadcrumbs-wrapper'>
      <BreadcrumbsMui aria-label='breadcrumb'>{BreadCrumbsItems}</BreadcrumbsMui>
    </Box>
  );
};

export default Breadcrumbs;
