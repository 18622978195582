import { IModel, IUser } from '../types';
import { isFeatureFlagEnabled } from './userUtils';

/**
 * We have gradual rollout of models to beta phase, therefore we want to support
 * feature flags that enable preview mode for some of the model.
 *
 * as of the moment - our only model with preview mode is jamba-instruct-preview
 * so we added hardcoded logic to handle this scenario.
 *
 * in case we'll need to support more models - we'll add a generic logic
 * for this thing
 */
export const isBetaAvailable = (model: IModel, user: IUser) => {
  if (
    model.id === 'jamba-instruct-preview' ||
    model.id === 'jamba-1.5-large' ||
    model.id === 'jamba-1.5-mini'
  ) {
    const isEnabled = isFeatureFlagEnabled(user, 'isJambaChatEnabled');
    return isEnabled;
  }

  return false;
};
