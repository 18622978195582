import { IApp } from '@ai21/studio-store';
import { Icon } from '@ai21/studio-ui';
import { actions } from './AppActions.data';
import { Action, ActionsSection, Wrapper } from './AppActions.style';

export type AppActionsProps = {
  app: IApp;
  onAction: (action: Json) => void;
};

export function AppActions(props: AppActionsProps) {
  const { app } = props || {};
  const { presetUrl, demoUrl } = app;

  function renderAction(action: Json) {
    const { title, iconName, isPrimary, isDisabledOnMobile } = action;
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    if (action.id === 'playground' && !presetUrl) {
      return null;
    }

    if (action.id === 'play' && !demoUrl) {
      return null;
    }

    return (
      <Action
        variant='contained'
        key={action.id}
        data-testid={`${action.analyticsId}`}
        fullWidth
        endIcon={
          (!isMobile || (isMobile && !isDisabledOnMobile)) && (
            <Icon iconName={iconName} />
          )
        }
        onClick={() => props.onAction(action)}
        color={action.color || 'primary'}
        disabled={isMobile && isDisabledOnMobile}
      >
        {title} {isMobile && isDisabledOnMobile && '(Desktop only)'}
      </Action>
    );
  }

  function renderActions(shouldRenderPrimaryAction: boolean) {
    return actions.map((action: Json) => renderAction(action));
  }

  return (
    <Wrapper className='AppActions-wrapper' data-testid='AppActions-wrapper'>
      <ActionsSection>{renderActions(false)}</ActionsSection>
    </Wrapper>
  );
}

export default AppActions;
