import { fireRequest } from './axios';
import { CompletionFailureReason, CustomModelType, PayloadCompletion, PayloadShare } from './types';
import { prepareCompletionPayload } from './utils/completion';

export const complete = (payload: PayloadCompletion, extraParams?: Json) => {
  const { isExperimental, customType, model } = payload;

  if (model === 'turbo') {
    return hub(payload);
  }

  const payloadParsed = prepareCompletionPayload(payload);

  const parts = [];

  if (isExperimental) {
    parts.push('experimental');
  } else if (customType) {
    parts.push(customType);
  }
  parts.push(model, 'complete');

  const isJambaModel =
    model === CustomModelType.JAMBA_INSTRUCT ||
    model === CustomModelType.JAMBA_1_5_LARGE ||
    model === CustomModelType.JAMBA_1_5_MINI;

  const path = isJambaModel ? 'chat/completions' : parts.join('/');

  return fireRequest<Json>({
    path,
    data: {
      ...payloadParsed,
      ...extraParams,
    },
    method: 'post',
    endpoint: 'completion.complete',
  }).then((response) => {
    const { status } = response;
    response.errorMessage = errorMap[status] || CompletionFailureReason.UNKNOWN_ERROR;
    return response;
  });
};

const env = process.env.NODE_ENV;

export const chat = (payload: PayloadCompletion, extraParams?: Json) => {
  const isJambaChatEnabled: boolean = extraParams?.isJambaChatEnabled ?? false;
  let path;
  let payloadParsed = payload;
  if (!isJambaChatEnabled) {
    payloadParsed = prepareCompletionPayload(payload);
    payloadParsed.messages = payloadParsed?.messages?.map((message: any) => {
      let newMessage = { text: message.content, ...message };
      delete newMessage.content;
      return newMessage;
    });
    path = env === 'production' ? 'j2-ultra/chat' : 'j2-mid/chat';
  } else {
    path = 'chat/completions';
    delete extraParams?.isJambaChatEnabled;
  }

  return fireRequest<Json>({
    path,
    data: {
      ...payloadParsed,
      ...extraParams,
    },
    method: 'post',
    endpoint: 'completion.chat',
  }).then((response) => {
    const { status } = response;

    response.errorMessage = errorMap[status] || CompletionFailureReason.UNKNOWN_ERROR;
    return response;
  });
};

export const hubOn = () => {
  return fireRequest<Json>({
    path: '/on',
    method: 'get',
    isHub: true,
    endpoint: 'completion.hubOn',
  });
};

export const hubOptions = () => {
  return fireRequest<Json>({
    path: '/options',
    method: 'get',
    isHub: true,
    endpoint: 'completion.hubOptions',
  });
};

export const hub = (payload: PayloadCompletion) => {
  return fireRequest<Json>({
    path: '/complete',
    data: payload,
    isHub: true,
    method: 'post',
    endpoint: 'completion.hub',
  }).then((response) => {
    const { status } = response;
    response.errorMessage = errorMap[status] || CompletionFailureReason.UNKNOWN_ERROR;

    const output: any = {
      data: {
        completions: [
          {
            data: {
              text: response.data?.content,
            },
          },
        ],
      },
    };

    return output;
  });
};

export const errorMap: Record<number, CompletionFailureReason> = {
  402: CompletionFailureReason.TRIAL_ENDED,
  403: CompletionFailureReason.CREDIT_EXCEEDED,
  422: CompletionFailureReason.TOO_MANY_TOKENS,
  429: CompletionFailureReason.TOO_MANY_REQUESTS,
};

export const createShare = (payload: PayloadShare) => {
  return fireRequest<Json>({
    path: '/prompt-share',
    method: 'post',
    data: payload,
    endpoint: 'completion.createShare',
  });
};

export const getShare = (promptShareId: string) => {
  return fireRequest<Json>({
    path: '/prompt-share',
    config: {
      headers: {
        'prompt-share-id': promptShareId,
      },
    },
    endpoint: 'completion.getShare',
  });
};

export const tokenize = (text: string) => {
  return fireRequest<Json>({
    path: '/tokenize',
    method: 'post',
    data: {
      text,
    },
    endpoint: 'completion.tokenize',
  });
};

export const completion = {
  complete,
  chat,
  createShare,
  getShare,
  tokenize,
  hubOn,
  hubOptions,
};
