import type { IMenuGroup, IMenuItem } from '@ai21/studio-ui';
import { AppRoutes } from './App.routes';

// 1st word capitalized, 2nd word lowercase
export const sideBarItems: IMenuItem[] = [
  {
    id: 'demos',
    title: 'Demos (CA)',
    path: AppRoutes.DEMOS,
    iconName: 'ScreenShare',
    groupId: 'tools',
    badge: 'AI21',
    order: 2,
    isLabs: true,
    noProd: true,
    isChild: true,
  },
];

export const sideBarGroups: IMenuGroup[] = [
  { id: 'tools', title: 'Tools', order: 1 },
];
