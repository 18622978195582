import { Content, Highlight, Wrapper } from './Highlights.style';
import { cleanHighlight } from './Highlights.utils';

export type HighlightsProps = {
  highlights: string[];
};

export function Highlights(props: HighlightsProps) {
  const { highlights } = props;

  function renderHighlight(highlight: string, index: number) {
    return (
      <li key={index}>
        <Highlight variant='body2' className='highlight'>
          {cleanHighlight(highlight).map((line: string, index: number) => (
            <p key={index}>{line}</p>
          ))}
        </Highlight>
      </li>
    );
  }

  function renderHighlights() {
    return highlights.map((highlight: string, index) =>
      renderHighlight(highlight, index)
    );
  }
  return (
    <Wrapper className='Highlights-wrapper' data-testid='Highlights-wrapper'>
      <Content>
        <ol>{renderHighlights()}</ol>
      </Content>
    </Wrapper>
  );
}

export default Highlights;
