import { Icon, toast } from '@ai21/studio-base-ui';
import { CopyButton, InputAndButton, UrlInput, Wrapper } from './PincodeShare.style';

export type PincodeShareProps = {
  code: string;
  callbacks: {
    onCta: () => void;
    onCancel: () => void;
  };
};

export function PincodeShare(props: PincodeShareProps) {
  const { code } = props;

  const copyPincode = () => {
    navigator.clipboard.writeText(code);
    toast.show('Copied to clipboard');
    props.callbacks.onCta();
  };

  return (
    <Wrapper data-testid='copy_pincode_wrapper'>
      <InputAndButton>
        <UrlInput readOnly value={code} data-testid='pincode_4digits' />
        <CopyButton
          size='small'
          onClick={() => {
            copyPincode();
          }}
          data-testid='copy_pincode_btn'
          iconName='Copy'
        />
      </InputAndButton>
    </Wrapper>
  );
}

export default PincodeShare;
