import type { INotification } from '@ai21/studio-ui';

export const notifications: INotification[] = [
  {
    id: 'N1',
    title: '',
    content: 'New API Question Answering - [Check it out](/api)',
    path: '/home',
    iconName: 'Notification',
  },
  {
    id: 'N2',
    title: '',
    content: 'Jack added a new member to the workspace *yuvale@ai21.com*',
    path: '/home',
    iconName: 'Notification',
  },
  {
    id: 'N3',
    title: '',
    content: "Your model finished it's training! check out [ChatModel](/models) ",
    path: '/models/custom',
    iconName: 'Notification',
  },
];
