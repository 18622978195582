import React from 'react';
import { Key, Row, Value, Wrapper } from './JsonTable.style';

export type JsonTableProps = {
  value: Json;
};

export const JsonTable = React.forwardRef((props: JsonTableProps, ref: any) => {
  const { value = {} } = props;

  function renderKey(key: string) {
    const data = value[key];

    return (
      <Row key={key} className='key'>
        <Key>{key}:</Key>
        <Value>{data}</Value>
      </Row>
    );
  }

  function renderKeys() {
    return Object.keys(value).map((key: string) => renderKey(key));
  }

  return (
    <Wrapper className='JsonTable-wrapper' data-testid='JsonTable-wrapper'>
      {renderKeys()}
    </Wrapper>
  );
});

export default JsonTable;
