export function ExpandRowIcon({ style }: { style?: React.CSSProperties }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path d='M7.833 13.833 4 10l3.833-3.833L6.667 5l-5 5 5 5 1.166-1.167zm4.334 0L16 10l-3.833-3.833L13.333 5l5 5-5 5-1.166-1.167z' />
    </svg>
  );
}
