import { Alert, Button, Icon, Markdown, toast } from '@ai21/studio-base-ui';
import classnames from 'classnames';
import { useCallback, useRef } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { DropZone, Input, P, Wrapper } from './FileDrop.style';
import { formatBytes } from './utils';

export type AcceptType = '.csv' | '.json' | '.jsonl' | '.txt' | '.pdf' | '.docx';

export type FileDropProps = {
  acceptTypes: AcceptType[];
  onDrop: (files: File[]) => void;
  ctaButtonText?: string;
  comment?: string;
  warning?: string;
  maxSize?: number;
  maxFiles?: number;
  multiple?: boolean;
};

export function FileDrop(props: FileDropProps) {
  const {
    acceptTypes = [],
    ctaButtonText = 'Select file',
    comment,
    warning,
    maxSize,
    maxFiles,
    multiple,
  } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onChoose = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const files = Array.from(event.target.files);
    props.onDrop(files);
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    props.onDrop(acceptedFiles);
  }, []);

  const onError = useCallback((error: Error) => {
    toast.show(error.message, 'error');
  }, []);

  const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
    let error = rejectedFiles[0].errors[0].message;
    const code = rejectedFiles[0].errors[0].code;

    if (code === 'file-too-large') {
      error = `File size exceeds the maximum file size of ${formatBytes(maxSize, 3)}`;
    }
    toast.show(error, 'error');
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onError,
    onDropRejected,
    accept: prepareAccept(acceptTypes),
    maxSize,
    multiple,
    maxFiles,
  });

  const className = classnames('FileDrop-wrapper', {
    active: isDragActive,
  });

  return (
    <Wrapper className={className} data-testid='FileDrop-wrapper'>
      {warning && <Alert severity='info'>{warning}</Alert>}
      <DropZone {...getRootProps()}>
        <Icon iconName='DropAnywhereModal' />
        <Input {...getInputProps()} />
        {isDragActive ? (
          <P>Drag and release here</P>
        ) : (
          <P>Drag your {prepareNames(acceptTypes)} file to upload or</P>
        )}

        <Button variant='contained' color='primary'>
          <input
            ref={fileInputRef}
            type='file'
            onChange={onChoose}
            accept={acceptTypes.join(',')}
            data-testid='FileInput'
            hidden
          />
          {ctaButtonText}
        </Button>
      </DropZone>
      {comment && <Markdown width={570} markdown={comment} />}
    </Wrapper>
  );
}

export default FileDrop;

const prepareAccept = (acceptTypes: AcceptType[]) => {
  return acceptTypes.reduce((acc, type) => {
    const mimeType = mimeTypes[type];
    acc[mimeType] = acc[mimeType] ?? [];
    acc[mimeType].push(type);
    return acc;
  }, {} as Record<string, AcceptType[]>);
};

const prepareNames = (acceptTypes: AcceptType[]) => {
  const typesArr = acceptTypes.map((type) => names[type]);
  if (typesArr.length === 1) {
    return typesArr[0];
  }

  return typesArr.slice(0, -1).join(', ') + ' or ' + typesArr[typesArr.length - 1];
};

const mimeTypes: Record<AcceptType, string> = {
  '.csv': 'text/csv',
  '.json': 'application/json',
  '.jsonl': 'application/json',
  '.txt': 'text/plain',
  '.pdf': 'application/pdf',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

const names: Record<AcceptType, string> = {
  '.csv': 'CSV',
  '.json': 'JSON',
  '.jsonl': 'JSONL',
  '.txt': 'TXT',
  '.pdf': 'PDF',
  '.docx': 'DOCX',
};
