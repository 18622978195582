export function RightWireIcon() {
  return (
    <svg viewBox='0 0 360 558'>
      <defs>
        <radialGradient
          id='radial0'
          cx='0'
          cy='0'
          r='1'
          fx='0'
          fy='0'
          gradientTransform='matrix(14.05963 64.99378 -45.57289 9.85845 244.064 231.027)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.083' stopColor='#F989CC' />
          <stop offset='0.771' stopColor='#753FEA' />
          <stop offset='1' stopColor='#BF0B48' />
        </radialGradient>
      </defs>
      <g stroke='#626B78'>
        <path fill='none' d='M441.113 1H247.718v493H0' transform='scale(1.11111 1.10934)' />
        <path
          fill='url(#radial0)'
          d='M247.75 220.615c5.452 0 9.871 4.423 9.871 9.877v57.245c0 5.45-4.419 9.873-9.871 9.873-5.453 0-9.876-4.422-9.876-9.873v-57.245c0-5.454 4.423-9.877 9.876-9.877zm0 0'
          transform='scale(1.11111 1.10934)'
        />
        <path
          fill='#FFF'
          d='M242.757 231.517h9.985v9.32h-9.985zm0 14.905h9.985v9.895h-9.985zm0 14.913h9.985v9.898h-9.985zm0 15.486h9.985v9.32h-9.985zm0 0'
          transform='scale(1.11111 1.10934)'
        />
        <path
          fill='#FCFCFC'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M258.026 490.243c0 6.627-5.372 12-11.999 12-6.627 0-11.999-5.373-11.999-12s5.372-12 11.999-12c6.627 0 11.999 5.373 11.999 12zm0 0'
          transform='scale(1.11111 1.10934)'
        />
        <path
          fill='none'
          strokeWidth='3'
          d='M238.532 491.972l.109-.472c.812-3.599 5.526-4.493 7.604-1.444l.274.401c1.972 2.895 6.451 2.046 7.225-1.373'
          transform='scale(1.11111 1.10934)'
        />
        <path
          fill='#231F20'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M248.425 107.75h11.707l-5.85 8.873-5.857 8.873-5.86-8.873-5.858-8.874zm-.338 42.321h7.358l-3.674 5.458-3.684 5.461-3.684-5.461-3.681-5.458zM61.351'
          transform='scale(1.11111 1.10934)'
        />
      </g>
    </svg>
  );
}
