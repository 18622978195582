import { getJson, guid, patchJson, setJson } from 'shared-base';
import { fireRequest } from './axios';
import {
  getCollection,
  listenToCollection,
  removeCollectionItem,
  updateCollectionItem,
} from './firebase';
import { PayloadCreateDocument } from './types';
import { localStorageResponse } from './utils/localStorage';
import { idTransformer } from './utils/transformers';

export const getAll = (_extraParams?: Json) => {
  return fireRequest<Json>({
    path: '/library/files',
    endpoint: 'documents.getAll',
    transformer: idTransformer('fileId'),
  });
};

export const get = (id: string) => {
  return fireRequest<Json>({
    path: `/library/files/${id}`,
    endpoint: 'documents.get',
  });
};

export const create = (payload: PayloadCreateDocument) => {
  const { file, labels, path, publicUrl } = payload;

  const formData = new FormData();

  formData.append('file', file);

  if (labels) {
    labels.forEach((label) => formData.append('labels', label));
  }

  if (path) {
    formData.append('path', path);
  }

  if (publicUrl) {
    formData.append('publicUrl', publicUrl);
  }

  return fireRequest<Json>({
    path: '/library/files',
    method: 'post',
    data: formData,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    endpoint: 'documents.create',
  });
};

export const update = (docId: string, change: Json) => {
  return fireRequest<Json>({
    method: 'put',
    path: `/library/files/${docId}`,
    endpoint: 'documents.update',
    data: change,
  });
};

export const remove = (docId: string) => {
  return fireRequest<Json>({
    method: 'delete',
    path: `/library/files/${docId}`,
    endpoint: 'documents.delete',
  });
};

export const getAllQuestions = (_extraParams?: Json) => {
  return localStorageResponse(Object.values(''));
};

export const createQuestion = (question: Json) => {
  patchJson('DOCUMENT_QUESTIONS', {
    [question.id]: question,
  });

  return localStorageResponse(question);
};

export const updateQuestion = (questionId: string, question: Json) => {
  const questions = getJson('DOCUMENT_QUESTIONS') ?? {};

  patchJson('DOCUMENT_QUESTIONS', {
    ...questions,
    [questionId]: {
      ...(questions[questionId] ?? {}),
      ...question,
    },
  });

  return localStorageResponse({});
};

export const clearQuestions = (source: string = 'library') => {
  const data = getJson('DOCUMENT_QUESTIONS') ?? {};
  const filteredItems = Object.keys(data)
    .filter((key) => data[key].source !== source)
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});

  setJson('DOCUMENT_QUESTIONS', filteredItems);
  return localStorageResponse({});
};

export const ask = (question: string, params: Json = {}) => {
  return fireRequest<Json>({
    path: '/library/answer',
    method: 'post',
    data: {
      question,
      ...params,
    },
    endpoint: 'documents.ask',
  });
};

export const documents = {
  get,
  getAll,
  create,
  update,
  remove,
  getAllQuestions,
  createQuestion,
  updateQuestion,
  clearQuestions,
  ask,
};
