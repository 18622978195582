import {
  IWidgetConfig,
  Input,
  InputToPayload,
  ResponseToOutput,
  WidgetPayload,
  OutputToAnalyticsPayload,
} from '../MiniPlayground.types';

export const config: IWidgetConfig = {
  title: 'Contextual Answers API',
  ctaText: 'Answer My Question',
  tooltipText: 'API supports contexts up to 10,000 characters',
  defaultInput: {
    main: 'In 2020 and 2021, enormous QE — approximately $4.4 trillion, or 18%, of 2021 gross domestic product (GDP) — and enormous fiscal stimulus (which has been and always will be inflationary) — approximately $5 trillion, or 21%, of 2021 GDP — stabilized markets and allowed companies to raise enormous amounts of capital. In addition, this infusion of capital saved many small businesses and put more than $2.5 trillion in the hands of consumers and almost $1 trillion into state and local coffers. These actions led to a rapid decline in unemployment, dropping from 15% to under 4% in 20 months — the magnitude and speed of which were both unprecedented. Additionally, the economy grew 7% in 2021 despite the arrival of the Delta and Omicron variants and the global supply chain shortages, which were largely fueled by the dramatic upswing in consumer spending and the shift in that spend from services to goods.',
    secondary: 'Did the economy shrink after the Omicron variant arrived?',
  },
  labels: {
    mainLabel: 'Context Input',
    secondaryLabel: 'Question Input',
    outputLabel: 'Answer Output',
  },
  outputHeight: 120,
  docsUrl: 'https://docs.ai21.com/reference/contextual-answers-api-ref',
  noSkeletonBorder: true,
  inputType: 'double',
  outputType: 'basic',
  apiMethodName: 'answer',
  snippetMethodName: 'answer',
  rules: [
    {
      ruleType: 'nonEmpty',
      errorMessage: 'Text must not be empty',
    },
    {
      ruleType: 'nonEmpty',
      errorMessage: 'Text must not be empty',
      isSecondary: true,
    },
    {
      ruleType: 'maxLength',
      errorMessage:
        'Demo is limited to {value} characters. Use the API to pass context up to 10,000 characters.',
      value: 1000,
      linkText: 'Use the API',
      linkUrl: 'https://docs.ai21.com/reference/contextual-answers-api-ref',
    },
    {
      ruleType: 'maxLength',
      errorMessage:
        'Demo is limited to {value} characters. Use the API to ask questions up to 150 characters.',
      value: 120,
      isSecondary: true,
      linkText: 'Use the API',
      linkUrl: 'https://docs.ai21.com/reference/contextual-answers-api-ref',
    },
  ],
};

export const inTransformer: InputToPayload = (input: Input) => ({
  context: input.main,
  question: input.secondary,
});

export const analyticsInputPayload = (input: Input) => ({
  textLength: input.secondary?.length,
});

export const outTransformer: ResponseToOutput = (response: Json, input: Input) => {
  const { data, isSuccess } = response;
  return {
    text: data.answerInContext ? data.answer : 'Answer not in documents.',
    errorMessage: data.detail,
    isSuccess,
    originalInput: input,
    count: data.answerInContext ? data.answer.length : undefined,
    unit: 'characters',
  };
};

export const analyticsOutputPayload: OutputToAnalyticsPayload = (response: Json) => {
  const { data, duration, request } = response;
  return {
    generatedTextLength: data.answerInContext
      ? data.answer.length
      : 'Answer not in documents.'.length,
    errorMessage: data.detail,
    requestDuration: duration,
    interactionId: data.id,
    endpoint: request.path,
  };
};

export const payload: WidgetPayload = {
  id: 'answer',
  config,
  inTransformer,
  outTransformer,
  analyticsInputPayload,
  analyticsOutputPayload,
};
