import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { theme } from './theme';

type ThemeProviderProps = {
  children: React.ReactElement;
};

export function StudioTheme(props: ThemeProviderProps) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline>{props.children}</CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
