import React from 'react';

export function UploadFileIcon() {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      xmlns='http://www.w3.org/2000/svg'
      fill='#616262'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.55773 0.89131C7.80181 0.647233 8.19754 0.647233 8.44162 0.89131L12.6083 5.05798C12.8524 5.30205 12.8524 5.69778 12.6083 5.94186C12.3642 6.18594 11.9685 6.18594 11.7244 5.94186L8.62467 2.84214V11.3333C8.62467 11.6784 8.34485 11.9583 7.99967 11.9583C7.6545 11.9583 7.37467 11.6784 7.37467 11.3333V2.84214L4.27495 5.94186C4.03087 6.18594 3.63514 6.18594 3.39107 5.94186C3.14699 5.69778 3.14699 5.30205 3.39107 5.05798L7.55773 0.89131ZM1.33301 11.5416C1.67819 11.5416 1.95801 11.8214 1.95801 12.1666V13.8333C1.95801 14.4085 2.42438 14.8749 2.99967 14.8749H12.9997C13.575 14.8749 14.0413 14.4085 14.0413 13.8333V12.1666C14.0413 11.8214 14.3212 11.5416 14.6663 11.5416C15.0115 11.5416 15.2913 11.8214 15.2913 12.1666V13.8333C15.2913 15.0989 14.2653 16.1249 12.9997 16.1249H2.99967C1.73402 16.1249 0.708008 15.0989 0.708008 13.8333V12.1666C0.708008 11.8214 0.98783 11.5416 1.33301 11.5416Z'
      />
    </svg>
  );
}
