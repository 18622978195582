import classnames from 'classnames';
import React, { ForwardedRef, useMemo } from 'react';
import { invokeEvent } from 'shared-base';
import { WrapperLink, WrapperLinkButton, WrapperRouterLink } from './A.style';

export type AProps = {
  button?: boolean;
  children?: string;
  className?: string;
  disabled?: boolean;
  href?: string;
  isLoading?: boolean;
  persistUrlParams?: true;
  target?: string; // for _blank
  onClick?: (ev: any) => void;
  ['data-testid']?: string;
  source?: string;
};

export const A = React.forwardRef((props: AProps, ref: ForwardedRef<any>) => {
  const {
    href,
    isLoading,
    disabled,
    persistUrlParams,
    button,
    target,
    source,
    ...rest
  } = props;

  const elementType = useMemo(() => {
    if (button) {
      return 'button';
    }

    return target ? 'a' : 'link';
  }, []);

  function onClick(ev: any) {
    invokeEvent('global/click', {
      source,
      ev,
    });

    if (!props.onClick) {
      return;
    }

    return props.onClick(ev);
  }

  const className = classnames('A-wrapper', props.className, {
    disabled,
  });

  const revisedHref = useMemo(() => {
    let output = href ?? '';

    if (persistUrlParams) {
      output += window.location.search;
    }

    return output;
  }, [href, persistUrlParams]);

  switch (elementType) {
    case 'button':
      return (
        <WrapperLinkButton
          {...rest}
          ref={ref}
          disabled={disabled || isLoading}
          className={className}
          onClick={onClick}
          data-testid={rest['data-testid'] ?? 'Button-wrapper'}
        >
          {props.children}
        </WrapperLinkButton>
      );
    case 'a':
      return (
        <WrapperLink
          {...rest}
          ref={ref}
          className={className}
          onClick={onClick}
          href={revisedHref}
          data-testid={rest['data-testid'] ?? 'Link-wrapper'}
        >
          {props.children}
        </WrapperLink>
      );
    default:
      return (
        <WrapperRouterLink
          {...rest}
          ref={ref}
          className={className}
          onClick={onClick}
          to={revisedHref}
          data-testid={rest['data-testid'] ?? 'Link-wrapper'}
        >
          {props.children}
        </WrapperRouterLink>
      );
  }
});

export default A;
