import { createSelector } from 'reselect';
import { IStudioStore } from '../types';
import * as raw from './selectors.raw';
import { sortBy } from 'shared-base';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $documentQuestions = createSelector(raw.$rawDocumentQuestions, (chatItems) => {
  return Object.values(chatItems).sort(sortBy('createdAt'));
});
