import { IAppDefinition } from '@ai21/studio-ui';
import { initRoutes, initSideBar, initTopBar } from './bootstrap/App.init';
import { root } from './sagas/saga.index';

export const app: IAppDefinition = {
  initRoutes,
  initSideBar,
  initTopBar,
  root,
};
