export const DEFAULT_FONT_FAMILY = 'Inter';

export type TypographyVariant =
  | 'h1'
  | 'h1light'
  | 'h1bold'
  | 'h2'
  | 'h2light'
  | 'h2bold'
  | 'h3'
  | 'h3light'
  | 'h3bold'
  | 'h4'
  | 'h4light'
  | 'h4bold'
  | 'h5'
  | 'h5light'
  | 'h5bold'
  | 'h6'
  | 'h6light'
  | 'h6bold'
  | 'subtitle1'
  | 'subtitle1light'
  | 'subtitle1bold'
  | 'subtitle2'
  | 'subtitle2light'
  | 'subtitle2bold'
  | 'body1'
  | 'body1light'
  | 'body1bold'
  | 'body2'
  | 'body2light'
  | 'body2bold'
  | 'caption';

export const typography = {
  fontFamily: DEFAULT_FONT_FAMILY,
  //Headings
  //H1
  h1: {
    fontSize: '100px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.15,
    letterSpacing: '0.1px',
  },
  h1light: {
    fontSize: '100px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.15,
    letterSpacing: '0.1px',
  },
  h1bold: {
    fontSize: '100px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.15,
    letterSpacing: '0.1px',
  },
  //H2
  h2: {
    fontSize: '56px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.12,
    letterSpacing: '0.1px',
  },
  h2light: {
    fontSize: '56px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.12,
    letterSpacing: '0.1px',
  },
  h2bold: {
    fontSize: '56px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.12,
    letterSpacing: '0.1px',
  },
  //H3
  h3: {
    fontSize: '40px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.17,
    letterSpacing: '0.1px',
  },
  h3light: {
    fontSize: '40px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.17,
    letterSpacing: '0.1px',
  },
  h3bold: {
    fontSize: '40px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.17,
    letterSpacing: '0.1px',
  },
  //H4
  h4: {
    fontSize: '32px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.24,
    letterSpacing: '0.1px',
  },
  h4light: {
    fontSize: '32px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.24,
    letterSpacing: '0.1px',
  },
  h4bold: {
    fontSize: '32px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.24,
    letterSpacing: '0.1px',
  },
  //H5
  h5: {
    fontSize: '24px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.35,
    letterSpacing: '0.1px',
  },
  h5light: {
    fontSize: '24px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.35,
    letterSpacing: '0.1px',
  },
  h5bold: {
    fontSize: '24px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.35,
    letterSpacing: '0.1px',
  },
  //H6
  h6: {
    fontSize: '20px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: '0.1px',
  },
  h6light: {
    fontSize: '20px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: '0.1px',
  },
  h6bold: {
    fontSize: '20px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: '0.1px',
  },
  //Subtitles
  //Subtitle1
  subtitle1: {
    fontSize: '18px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: '0.1px',
  },
  subtitle1light: {
    fontSize: '18px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: '0.1px',
  },
  subtitle1bold: {
    fontSize: '18px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: '0.1px',
  },
  //Subtitle2
  subtitle2: {
    fontSize: '16px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.35,
    letterSpacing: '0.1px',
  },
  subtitle2light: {
    fontSize: '16px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.35,
    letterSpacing: '0.1px',
  },
  subtitle2bold: {
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.35,
    letterSpacing: '0.1px',
  },
  // //Body
  // //Body1
  body1: {
    fontSize: '14px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.45,
    letterSpacing: '0.1px',
  },
  body1light: {
    fontSize: '14px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.45,
    letterSpacing: '0.1px',
  },
  body1bold: {
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.45,
    letterSpacing: '0.1px',
  },
  // //Body2
  body2: {
    fontSize: '12px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: '0.1px',
  },
  body2light: {
    fontSize: '12px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: '0.1px',
  },
  body2bold: {
    fontSize: '12px',
    fontWeight: 800,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: '0.1px',
  },
  // //Button
  button: {
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '22px',
    textTransform: 'none' as any,
    letterSpacing: '0.46px',
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.6px',
  },
};
