import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  icons,
} from '@ai21/studio-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MESSAGE_SHOWN } from '../../redux/actionTypes/userActionTypes';
import { ErrorMessageBox } from '../datasets.ErrorMessageBox/ErrorMessageBox';

export interface DatasetValidationChooseColumnsProps {
  datasetName: string | undefined;
  datasetColumns: string[] | undefined;
  setContinueButtonEnabled: CallableFunction;
  setColumnsNames: CallableFunction;
}

const datasetsValidationStyles = makeStyles(() => ({
  datasetsValidationContainer: {
    backgroundColor: '#FCFCFC',
  },
  inputText: {
    fontSize: 16,
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.19,
    letterSpacing: '0.15px',
  },
  errorText: {
    fontSize: 11,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.82,
    letterSpacing: 0.4,
    color: '#B80B00',
  },
}));

export function DatasetValidationChooseColumns({
  datasetName,
  datasetColumns,
  setContinueButtonEnabled,
  setColumnsNames,
}: DatasetValidationChooseColumnsProps) {
  const classes = datasetsValidationStyles();
  const [prompt, setPrompt] = useState('');
  const [sameColumnsSelected, setSameColumnsSelected] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [completion, setCompletion] = useState('');
  const [openCompletion, setOpenCompletion] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: MESSAGE_SHOWN,
      payload: {
        origin: 'datasetUploadError',
        type: 'error',
        text: 'Select the desired columns to continue',
        name: 'COLUMNS_NOT_SPECIFIED',
      },
    });
  }, []);

  const onColumnSelected = useCallback(() => {
    if (prompt === '' || completion === '') {
      setSameColumnsSelected(false);
      return false;
    }
    if (prompt === completion) {
      setSameColumnsSelected(true);
      return false;
    }
    setSameColumnsSelected(false);
    return true;
  }, [completion, prompt]);

  useEffect(() => {
    if (onColumnSelected()) {
      setContinueButtonEnabled(true);
      setColumnsNames({ prompt, completion });
    } else {
      setContinueButtonEnabled(false);
    }
  }, [prompt, completion, setContinueButtonEnabled, onColumnSelected]);

  const handlePromptChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPrompt(event.target.value);
  };
  const handleCompletionChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setCompletion(event.target.value);
  };

  return (
    <Grid container alignItems='center' justifyContent='center' direction='column'>
      <Box style={{ marginTop: '40px' }} />
      <Box marginTop={2} />
      <Grid container justifyContent='center'>
        <icons.DatasetLarge />
        &nbsp;&nbsp;&nbsp;
        <Typography variant='subtitle2'>
          {'Dataset '}
          {datasetName}
        </Typography>
      </Grid>

      <Box marginTop={4} />
      <ErrorMessageBox errorMessage='Could not detect ‘prompt’ and ‘completion’ columns in the file.' />
      <Box style={{ marginTop: 40 }} />
      <Typography variant='h6'>Select the desired columns to continue:</Typography>
      <Box style={{ marginTop: 8 }} />
      <Grid container justifyContent='center'>
        <FormControl variant='standard'>
          <InputLabel
            style={{
              color: sameColumnsSelected ? '#B80B00' : '#626262',
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 1.19,
              letterSpacing: '0.15px',
              paddingLeft: '16px',
            }}
          >
            Prompt
          </InputLabel>
          <Select
            variant='outlined'
            data-testid='select-prompt'
            error={sameColumnsSelected}
            style={{ width: 196, height: 50 }}
            open={openPrompt}
            onClose={() => setOpenPrompt(false)}
            onOpen={() => setOpenPrompt(true)}
            value={prompt}
            label='Prompt'
            onChange={handlePromptChange}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {!datasetColumns?.length
              ? []
              : datasetColumns.map((dc) => (
                  <MenuItem value={dc} data-testid={`select-column-${dc}`}>
                    {dc}
                  </MenuItem>
                ))}
          </Select>
          {sameColumnsSelected && (
            <div className={classes.errorText}>Same column can’t be used twice</div>
          )}
        </FormControl>
        <Box style={{ marginRight: '42px' }} />
        <FormControl variant='standard'>
          <InputLabel
            style={{
              color: sameColumnsSelected ? '#B80B00' : '#626262',
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 1.19,
              letterSpacing: '0.15px',
              paddingLeft: '16px',
            }}
          >
            Completion
          </InputLabel>
          <Select
            variant='outlined'
            data-testid='select-completion'
            error={sameColumnsSelected}
            style={{ width: 196, height: 50 }}
            open={openCompletion}
            onClose={() => setOpenCompletion(false)}
            onOpen={() => setOpenCompletion(true)}
            value={completion}
            label='Completion'
            onChange={handleCompletionChange}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {!datasetColumns?.length
              ? []
              : datasetColumns.map((dc) => (
                  <MenuItem value={dc} data-testid={`select-column-${dc}`}>
                    {dc}
                  </MenuItem>
                ))}
          </Select>
          {sameColumnsSelected && (
            <div className={classes.errorText}>Same column can’t be used twice</div>
          )}
        </FormControl>
      </Grid>
      <Box style={{ marginTop: '40px' }} />
    </Grid>
  );
}
