import { ape } from './api.ape';
import { ax } from './api.ax';
import { billing } from './api.billing';
import { collection } from './api.collection';
import { completion } from './api.completion';
import { demos } from './api.demos';
import { documents } from './api.documents';
import { evaluation } from './api.evaluation';
import { feedback } from './api.feedback';
import { fts } from './api.fts';
import { generation } from './api.generation';
import { lego } from './api.lego';
import { models } from './api.models';
import { organization } from './api.organization';
import { presets } from './api.presets';
import { usage } from './api.usage';
import { user } from './api.user';
import { pincode } from './api.pincode';
import { users } from './api.users';

export const api = {
  ax,
  ape,
  billing,
  collection,
  completion,
  demos,
  documents,
  evaluation,
  feedback,
  fts,
  generation,
  models,
  organization,
  presets,
  usage,
  user,
  users,
  lego,
  pincode,
};
