import type { IMenuItem } from '@ai21/studio-base-ui';
import { Icon } from '@ai21/studio-base-ui';
import classnames from 'classnames';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { sortBy } from 'shared-base';
import { IMenuGroup } from '../../types';
import { useSidebarItems } from './SideBar.hooks';
import { BackToStudioButton } from './SideBar.parts';
import {
  AllGroups,
  BackWrapper,
  ChildGroupTitle,
  ChildGroups,
  Description,
  DropdownIcon,
  ExternalIcon,
  Flex,
  Group,
  GroupTitle,
  Item,
  ItemBadge,
  ItemIcon,
  ItemText,
  MainGroups,
  OnHoverIcon,
  Wrapper,
} from './SideBar.style';

export type SideBarProps = {
  items: IMenuItem[];
  groups: IMenuGroup[];
  onClick: (item: IMenuItem) => void;
  onBack: () => void;
  children?: React.ReactNode;
  isSlim?: boolean;
};

export function SideBar(props: SideBarProps) {
  const { groups, isSlim = false } = props;
  const location = useLocation();
  const { height } = useWindowSize();

  const { mainItems, childItems, isInner } = useSidebarItems(props.items);

  useEffect(() => {
    document.body.classList.add('side-menu');

    return () => {
      document.body.classList.remove('side-menu');
    };
  }, [location.pathname]);

  function onMouseDown(ev: React.MouseEvent<HTMLDivElement>, item: IMenuItem) {
    // only if left button
    if (ev.button !== 0) {
      return;
    }

    if (item.isParent) {
      const isParentSelected = ev.target.closest('.parent').classList.contains('Mui-selected');

      const selectedElements = document.querySelectorAll(`.parent.Mui-selected`);
      selectedElements.forEach((element) => {
        element.classList.remove('Mui-selected');
      });

      if (!isParentSelected) {
        ev.target.closest('.parent').classList.toggle('Mui-selected');
      }
    } else {
      if (!item.parentId) {
        const selectedElements = document.querySelectorAll(`.parent.Mui-selected`);
        selectedElements.forEach((element) => {
          element.classList.remove('Mui-selected');
        });
      }
      props.onClick(item);
    }
  }

  function renderItem(item: IMenuItem, childItems: IMenuItem[]) {
    const { title, iconName, path = '', badge, isParent, description, hoverIconName } = item;

    const childItemsPaths = childItems.length > 0 ? childItems.map((item) => item.path) : [];

    const pathExists = path.length > 0; // is actionType item?
    const selectedInner = location.pathname === path;
    const selected = pathExists && selectedInner;
    const parentSelected = childItemsPaths.includes(window.location.pathname);

    return (
      <>
        <Item
          key={item.id}
          className={`item ${isParent && 'parent'}`}
          onMouseDown={(ev) => onMouseDown(ev, item)}
          selected={isParent ? parentSelected : selected}
          data-testid={`SideBar-item-${item.id}`}
          data-href={item.path}
        >
          <ItemIcon>
            <Icon iconName={iconName} />
          </ItemIcon>
          <ItemText>{title}</ItemText>
          {badge && (
            <ItemBadge className={badge} color='default'>
              {badge}
            </ItemBadge>
          )}
          {isParent && (
            <DropdownIcon>
              <Icon iconName='ChevronDown' />
            </DropdownIcon>
          )}
          {hoverIconName && (
            <OnHoverIcon>
              <Icon iconName={hoverIconName} />
            </OnHoverIcon>
          )}
        </Item>
        {childItems.length > 0 && (
          <Group key={`${item.parentId}_group`} className={`dropdown`}>
            {description && <Description variant='body2'>{description}</Description>}
            {renderDropdownItems(childItems)}
          </Group>
        )}
      </>
    );
  }

  function renderDropdownItems(dropdownItems: IMenuItem[]) {
    return dropdownItems.sort(sortBy('order')).map((item) => {
      const { title, iconName, path = '', badge, href } = item;

      const pathExists = path.length > 0; // is actionType item?
      const selectedInner = location.pathname === path;
      const selected = pathExists && selectedInner;
      const isExternalItem = href !== undefined;

      return (
        <Item
          key={item.id}
          className={`dropdownItem ${isExternalItem && 'external'}`}
          onMouseDown={(ev) => onMouseDown(ev, item)}
          selected={selected}
          data-testid={`SideBar-item-${item.id}`}
          data-href={item.path}
        >
          <ItemIcon>
            <Icon iconName={iconName} />
          </ItemIcon>
          <ItemText>{title}</ItemText>
          {badge && (
            <ItemBadge className={badge} color='default'>
              {badge}
            </ItemBadge>
          )}
          {isExternalItem && (
            <ExternalIcon className={badge} color='default'>
              <Icon iconName='ArrowUpRight' />
            </ExternalIcon>
          )}
        </Item>
      );
    });
  }

  function renderItems(groupItems: IMenuItem[]) {
    const mainItems = groupItems.filter((item) => !item.parentId);
    return mainItems.map((item: IMenuItem) => {
      const childItems = groupItems.filter((child) => child.parentId === item.id);
      return renderItem(item, childItems);
    });
  }

  function renderGroup(group: IMenuGroup) {
    const { id, title, order } = group;
    const itemsForGroup = mainItems
      .filter((i) => i.groupId === id && !i.isHidden)
      .sort(sortBy('order'));

    if (itemsForGroup.length === 0) {
      return null;
    }

    return (
      <Group key={group.id} className={`group ${group.id}`}>
        {title && <GroupTitle>{title.toUpperCase()}</GroupTitle>}
        {renderItems(itemsForGroup)}
      </Group>
    );
  }

  function renderGroups() {
    return groups.sort(sortBy('order')).map((group: IMenuGroup) => renderGroup(group));
  }

  function renderChildItems() {
    const orderedChildrenItems = childItems.sort(sortBy('order'));
    const title = groups.filter((group) => group.id === orderedChildrenItems[0]?.groupId)[0]?.title;

    return (
      <Group className='group children'>
        <BackWrapper>
          <BackToStudioButton title='Back' onClick={props.onBack} />
        </BackWrapper>
        {title && <ChildGroupTitle>{title.toUpperCase()}</ChildGroupTitle>}
        {renderItems(orderedChildrenItems)}
      </Group>
    );
  }

  function renderInnerComponent() {
    if (height < 1000) {
      return null;
    }

    if (!props.children) {
      return null;
    }

    return props.children;
  }

  const className = classnames('SideBar-wrapper', {
    inner: isInner,
    hidden: isSlim,
  });

  return (
    <Wrapper className={className} data-testid='SideBar-wrapper'>
      <AllGroups>
        <MainGroups className='main-group'>{renderGroups()}</MainGroups>
        <ChildGroups className='child-group'>{renderChildItems()}</ChildGroups>
      </AllGroups>
      <Flex />
      {renderInnerComponent()}
    </Wrapper>
  );
}

export default SideBar;
