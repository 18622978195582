import { root as analyticsRoot } from '@ai21/studio-analytics';
import { loginSagas } from '@ai21/studio-auth';
import { fork } from 'saga-ts';
import { initSagas } from '../bootstrap/Web.apps';
import { root as analytics } from './saga.analytics';
import { root as experiment } from './saga.experiment';
import { root as apiKey } from './saga.apiKey';
import { root as apps } from './saga.apps';
import { root as billing } from './saga.billing';
import { root as catalogues } from './saga.catalogues';
import { root as epochs } from './saga.epochs';
import { root as examples } from './saga.examples';
import { root as external } from './saga.external';
import { root as fts } from './saga.fts';
import { root as hub } from './saga.hub';
import { root as intercom } from './saga.intercom';
import { root as legacy } from './saga.legacy';
import { root as logoutAsk } from './saga.logoutAsk';
import { root as models } from './saga.models';
import { root as navigation } from './saga.navigation';
import { root as persist } from './saga.persist';
import { root as presets } from './saga.presets';
import { root as preview } from './saga.preview';
import { root as sidebar } from './saga.sidebar';
import { root as toast } from './saga.toast';

const analyticsEnabled = window.__env__.VITE_ANALYTICS_ENABLED === 'true';
const isTest = window.__env__.NODE_ENV === 'test';
const isDemo = document.location.pathname.startsWith('/demo/');

export function* root() {
  if (!isTest && !isDemo) {
    yield* fork(loginSagas);
    yield* fork(analytics);
  }

  yield* fork(apps);
  yield* fork(apiKey);
  yield* fork(billing);
  yield* fork(catalogues);
  yield* fork(epochs);
  yield* fork(examples);
  yield* fork(external);
  yield* fork(fts);
  yield* fork(hub);
  yield* fork(intercom);
  yield* fork(legacy);
  yield* fork(logoutAsk);
  yield* fork(models);
  yield* fork(navigation);
  yield* fork(sidebar);
  yield* fork(persist);
  yield* fork(presets);
  yield* fork(preview);
  yield* fork(toast);

  // packages
  if (analyticsEnabled) {
    yield* fork(analyticsRoot);
  }

  yield* fork(experiment);
  yield* fork(initSagas);
}
