import { Copy, Icon, IconLink, format } from '@ai21/studio-base-ui';
import classnames from 'classnames';
import React from 'react';
import { Actions, Content, Count, Gap, TextArea, Title, Top, Wrapper } from './IOEditor.style';

export type IOEditorProps = {
  title: string;
  value?: string;
  iconName?: string;
  height?: number;
  copyTestId?: string;
  withBorder?: boolean;
  showHide?: boolean;
  disabled?: boolean;
  onChange?: (content: string, id?: string) => void;
  onAction: (verb: any, params?: Json) => void;
};

export const IOEditor = React.forwardRef((props: IOEditorProps, ref: any) => {
  const {
    title,
    value = '',
    iconName,
    copyTestId = 'Copy',
    withBorder,
    showHide,
    disabled,
    height = 150,
  } = props;

  const style: React.CSSProperties = {
    height: props.height ? props.height + 'px' : 'auto',
  };

  const className = classnames('IOEditor-wrapper', {
    border: withBorder,
    'show-hide': showHide,
  });

  return (
    <Wrapper className={className} data-testid='IOEditor-wrapper' style={style}>
      <Top className='top'>
        <Title>
          <Icon iconName={iconName} />
          {title}
        </Title>
        <Count>({format.number.normal(value.length)} characters)</Count>
        <Gap />
        <Actions>
          <Copy testId={copyTestId} className='action-copy' value={value} />
          <IconLink
            onClick={() => props.onAction('hide')}
            data-testid={copyTestId}
            className='action-hide'
            text='Hide'
            iconName='Eye'
          />
        </Actions>
      </Top>
      <Content className='content'>
        <TextArea
          multiline
          rows={20}
          fullWidth
          disabled={disabled}
          ref={ref}
          inputProps={{
            style: {
              height: height - 60 + 'px',
              border: 'none',
            },
          }}
          value={value.replace(/\\n/g, '\n')}
        />
      </Content>
    </Wrapper>
  );
});

export default IOEditor;
