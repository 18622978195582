import { fork, put } from 'saga-ts';
import { root as api } from './saga.api';
import { root as boxes } from './saga.boxes';
import { root as models } from './saga.models';
import { root as params } from './saga.params';
import { root as pas } from './saga.pas';
import { root as play } from './saga.play';
import { root as presets } from './saga.presets';
import { actions } from '@ai21/studio-store';

export function* root() {
  yield* fork(api);
  yield* fork(boxes);
  yield* fork(models);
  yield* fork(params);
  yield* fork(pas);
  yield* fork(play);
  yield* fork(presets);
}
