import { api, setTokens } from '@ai21/studio-api';
import { actions } from '@ai21/studio-store';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

export function useDemo() {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  async function load() {
    setIsLoading(true);

    const response = await api.demos.getPublicDemo(id);
    const { data } = response;

    if (!data) {
      document.location.href = '/demo/not-found';
      return;
    }

    const apiKey = get(data, 'params.apiKey');

    if (!apiKey) {
      document.location.href = '/demo/no-api-key';
      return;
    }

    setTokens({ studioApiKey: apiKey });

    dispatch(actions.documents.get({}));
    dispatch(actions.documentQuestions.get({}));

    setIsLoading(false);
    setData(data);
  }

  useEffect(() => {
    load();
  }, [id]);

  return [data, isLoading];
}
