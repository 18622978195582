import { Autocomplete, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Wrapper } from './StopSequences.style';

export type StopSequencesProps = {
  value: string[];
  options: string[];
  disabled?: boolean;
  onChange: (items: string[]) => void;
  placeholder?: string;
  autoFocus?: boolean;
  enterAsSymbol?: boolean;
  maxTags?: number;
  className?: string;
};

export const StopSequences = React.forwardRef(
  (props: StopSequencesProps, ref: any) => {
    const {
      value = [],
      options,
      placeholder,
      autoFocus,
      disabled,
      maxTags,
    } = props;
    const refInput = useRef<HTMLInputElement>(null);
    const [text, setText] = useState('');

    function onChange(_event: any, value: any) {
      if (maxTags && value.length > maxTags) {
        return;
      }

      props.onChange(value);
      setText('');
    }

    function onChangeText(ev: any) {
      setText(ev.target.value);
    }

    function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
      switch (event.key) {
        case 'Backspace':
          if (
            event.target instanceof HTMLInputElement &&
            event.target.value === ''
          ) {
            onChange({}, value.slice(0, -1));
          }
          break;
        case 'Tab':
          event.preventDefault();
          if (event.target instanceof HTMLInputElement && event.target.value) {
            onChange({}, value.concat([event.target.value]));
          }
          break;
        case 'Enter':
          event.preventDefault();
          event.stopPropagation();
          if (event.target instanceof HTMLInputElement) {
            event.target.value += '↵';
          }
          break;
        default:
          break;
      }
    }

    function renderInput(params: any) {
      return (
        <TextField
          data-testid='stop_sequences'
          color='secondary'
          ref={refInput}
          style={{ backgroundColor: '#ffffff' }}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={value.length === 0 ? placeholder : ''}
          variant='outlined'
          {...params}
          onChange={onChangeText}
          inputProps={{
            ...params.inputProps,
            value: text,
          }}
        />
      );
    }

    return (
      <Wrapper
        className='StopSequences-wrapper'
        data-testid='StopSequences-wrapper'
      >
        <Autocomplete
          color='secondary'
          multiple
          options={options}
          ref={ref}
          freeSolo
          fullWidth
          value={value}
          onChange={onChange}
          renderInput={renderInput}
        />
      </Wrapper>
    );
  }
);

export default StopSequences;
