import { call, fork, put, takeEvery } from 'saga-ts';
import { invokeEvent } from 'shared-base';
import { AuthErrors, LoginProviderAll } from '../types';
import { authState, firebase } from '../utils/globals';
import { logger } from '../utils/log';
import { checkLimboState } from './saga.email';
import { api } from '@ai21/studio-api';
import { OAuthProvider } from 'firebase/auth';

type ActionEmail = {
  type: 'LOGIN_EMAIL';
  params: {
    email: string;
    password: string;
  };
  resolve: () => void;
  reject: () => void;
};

type ActionGoogle = {
  type: 'LOGIN_GOOGLE';
};

type ActionGithub = {
  type: 'LOGIN_GITHUB';
};
type ActionSSOLogin = {
  type: 'SSO_LOGIN';
  params: {
    email: string;
  };
};

export function* loginGoogle(_action: ActionGoogle) {
  const provider: LoginProviderAll = 'GOOGLE';
  authState.lastProvider = provider;

  const res = yield* call(firebase.signInWithGoogle);

  if (res.err) {
    yield call(handleError, res.err);
    return;
  }

  yield call(handleSuccess, res.user);
}

export function* loginGithub(_action: ActionGithub) {
  const provider: LoginProviderAll = 'GITHUB';
  authState.lastProvider = provider;

  const res = yield* call(firebase.signInWithGithub);

  if (res.err) {
    yield call(handleError, res.err);
    return;
  }

  yield call(handleSuccess, res.user);
}

export function* loginEmail(action: ActionEmail) {
  const provider: LoginProviderAll = 'EMAIL_PW';
  authState.lastProvider = provider;

  const { params, resolve } = action;
  const { email, password } = params;

  const res = yield* call(firebase.signInWithEmailAndPassword, email, password);

  resolve();

  if (res.err) {
    yield* call(handleError, res.err);
    return;
  }

  yield* fork(checkLimboState, res.user, true);
  yield* call(handleSuccess, res.user);
}

export function* handleSuccess(user: any) {
  yield put({ type: 'saga/complete', user });
  logger.AuthProviderSucceeded();
}

export function* handleError(err: any) {
  const { code } = err;

  switch (code) {
    case AuthErrors.popupBlocked:
      // perhaps show instructions to enable popups
      break;
    default:
  }

  logger.AuthProviderFailed(err);
  logger.AuthError(err);

  const userFriendlyError = firebase.parseAuthError(code);

  invokeEvent('auth_error', { code: userFriendlyError });
}

export function* loginSSO(action: ActionSSOLogin) {
  const email = action.params.email;
  authState.lastProvider = 'WORKOS'; // used for analytics.

  const ssoOrgIdResponse = yield* call(api.user.getSSOOrgId, email);
  if (!ssoOrgIdResponse.isSuccess || !ssoOrgIdResponse.data) {
    if (ssoOrgIdResponse.status === 404) {
      invokeEvent('auth_error', {
        code: 'Single sign-on is not set up for your company. Log in with a different method or ask your company administrator for help.',
      });
    } else if (ssoOrgIdResponse.status >= 500) {
      invokeEvent('auth_error', { code: 'Unspecified Network error: Please try again.' });
    } else {
      invokeEvent('auth_error', { code: 'Unknown error occurred. Please try again.' });
    }
    return;
  }

  const ssoOrgId = ssoOrgIdResponse.data;

  const provider = new OAuthProvider('oidc.workos');
  provider.setCustomParameters({ organization: ssoOrgId });

  const SSOSignInResponse = yield* call(firebase.signInWithSSO, provider);

  if (SSOSignInResponse.err) {
    yield call(handleError, SSOSignInResponse.err);
    return;
  }
  yield call(handleSuccess, SSOSignInResponse.user);
}

export function* root() {
  yield takeEvery('LOGIN_GOOGLE', loginGoogle);
  yield takeEvery('LOGIN_GITHUB', loginGithub);
  yield takeEvery('LOGIN_EMAIL', loginEmail);
  yield takeEvery('LOGIN_SSO', loginSSO);
}
