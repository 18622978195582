import { useState } from 'react';
import { IMenuItem } from '../../types';
import { Icon } from '../Icon/Icon';
import { Menu } from '../Menu/Menu';
import { MainButton, Wrapper } from './PopMenu.style';

export type PopMenuProps = {
  iconName: string;
  items?: IMenuItem[];
  onClick: (item: IMenuItem) => void;
  dataTestId?: string;
};

export function PopMenu(props: PopMenuProps) {
  const { dataTestId = '', iconName, items } = props;

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (item: IMenuItem) => {
    handleClose();
    props.onClick(item);
  };

  return (
    <Wrapper className='PopMenu-wrapper' data-testid='PopMenu-wrapper'>
      <MainButton
        size='small'
        onClick={(event) => setAnchorEl(event.currentTarget)}
        data-testid={`popup-menu-${dataTestId}`}
        aria-controls={anchorEl ? 'long-menu' : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-haspopup='true'
        iconName={iconName}
      />
      <Menu
        items={items}
        anchorEl={anchorEl}
        handleClose={handleClose}
        onClick={onClick}
      />
    </Wrapper>
  );
}

export default PopMenu;
