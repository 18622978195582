import { IconButton } from '../IconButton/IconButton';
import { useRef } from 'react';
import { useMount } from 'react-use';
import './Modal.scss';
import { Content, Header, HeaderActions, Title, Wrapper } from './Modal.style';
import classnames from 'classnames';

export type ModalProps = {
  title?: string;
  children: JSX.Element[] | JSX.Element;
  open?: boolean;
  hideBackdrop?: boolean;
  onClose: () => void;
  focusOnClassName?: string;
  header?: JSX.Element[] | JSX.Element;
  headerCta?: React.FC<Json>;
  headerCtaProps?: Json;
  dataTestId?: string;
};

export function Modal(props: ModalProps) {
  const {
    open = false,
    title,
    focusOnClassName,
    hideBackdrop,
    dataTestId,
    headerCta,
    headerCtaProps,
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const mobileClassName = classnames({ mobile: isMobile });

  useMount(() => {
    if (!focusOnClassName) {
      return;
    }

    setTimeout(() => {
      if (!ref.current) {
        return;
      }

      const el: HTMLButtonElement | null =
        ref.current.querySelector(focusOnClassName);

      if (!el) {
        return;
      }

      el.focus();
    }, 50);
  });

  function renderTitle() {
    if (!props.header && !title) return null;
    if (props.header) {
      return props.header;
    }

    return <Title variant='h6bold'>{title}</Title>;
  }
  const HeaderCta = headerCta;

  return (
    <Wrapper
      open={open}
      onClose={props.onClose}
      className='modal-root'
      hideBackdrop={hideBackdrop}
      data-testid={dataTestId}
      ref={ref}
    >
      <Header className='header'>
        {renderTitle()}
        <HeaderActions className={mobileClassName}>
          {typeof HeaderCta === 'function' && <HeaderCta {...headerCtaProps} />}
          <IconButton
            size='small'
            onClick={props.onClose}
            data-testid='close-dialog-btn'
            iconName='Close'
          />
        </HeaderActions>
      </Header>
      <Content>{props.children}</Content>
    </Wrapper>
  );
}

export default Modal;
