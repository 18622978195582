import { Autocomplete, Paper, TextField } from '@mui/material';
import classnames from 'classnames';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Wrapper } from './Tags.style';

export type TagsProps = {
  value: string[];
  options: string[];
  disabled?: boolean;
  onChange: (items: string[]) => void;
  placeholder?: string;
  autoFocus?: boolean;
  enterAsSymbol?: boolean;
  maxTags?: number;
  className?: string;
};

export const Tags = React.forwardRef((props: TagsProps, ref: any) => {
  const {
    value = [],
    options,
    placeholder,
    autoFocus,
    disabled,
    maxTags,
  } = props;

  const refWrapper = useRef<HTMLDivElement>(null);

  function onChange(_event: React.SyntheticEvent, value: any) {
    if (maxTags && value.length > maxTags) {
      return;
    }

    props.onChange(value);
  }

  const CustomPaper = (props: any) => {
    return <Paper elevation={10} {...props} />;
  };

  const openOnFocus = value && value.length === 0;

  const className = classnames('Tags-wrapper', props.className, {});
  return (
    <Wrapper className={className} data-testid='Tags-wrapper' ref={refWrapper}>
      <Autocomplete
        multiple
        autoSelect
        disabled={disabled}
        id='tags-outlined'
        options={options}
        ref={ref}
        openOnFocus={openOnFocus}
        getOptionLabel={(option) => option}
        value={value}
        ChipProps={{
          color: 'default',
        }}
        freeSolo
        PaperComponent={CustomPaper}
        filterSelectedOptions
        onChange={onChange}
        renderInput={(params) => (
          <Input
            ref={ref}
            autoFocus={autoFocus}
            {...params}
            placeholder={placeholder}
          />
        )}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '0',
            padding: '0',
          },
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #CCD0D5',
          },
        }}
      />
    </Wrapper>
  );
});

const Input = styled(TextField)`
  input {
    height: 24px;
  }
`;

export default Tags;
