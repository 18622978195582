import { Icon, IconButton, Lock, Tooltip, useShortKey } from '@ai21/studio-base-ui';
import { useContext } from 'react';
import { InboxContext } from '../../InboxEditor.context';
import { ProgressView } from '../ProgressView/ProgressView';
import { Actions, Saving, Wrapper } from './EditorTopBar.style';

export type EditorTopBarProps = {
  isLocked?: boolean;
  isFullscreen?: boolean;
  onToggleFullScreen: (isFullscreen: boolean) => void;
  onToggleLock: () => void;
};

export function EditorTopBar(props: EditorTopBarProps) {
  const { isLocked, isFullscreen } = props;
  const { state, jobInfo, callbacks } = useContext(InboxContext);

  useShortKey({ key: 'ArrowUp', withAlt: true, preventDefault: true }, () => {
    callbacks.onAction('previous', { keyboard: true });
  });

  useShortKey({ key: 'ArrowDown', withAlt: true, preventDefault: true }, () => {
    callbacks.onAction('next', { keyboard: true });
  });

  function renderSaving() {
    if (state.isSaving) {
      return (
        <Saving className='spin'>
          <Icon iconName='Loader2' />
          Saving...
        </Saving>
      );
    }
  }

  const fullscreenIconName = isFullscreen ? 'ArrowsDiagonalMinimize' : 'ArrowsDiagonal2';

  return (
    <Wrapper className='EditorTopBar-wrapper' data-testid='EditorTopBar-wrapper'>
      <Actions>
        <Tooltip title={'Close drawer'} enterDelay={1000}>
          <IconButton
            data-testid='CloseEvaluationDrawer'
            onClick={() => callbacks.onClose()}
            iconName='ChevronsRight'
          />
        </Tooltip>
        <Tooltip
          data-testid='ExpandEvaluationDrawer'
          title={`${isFullscreen ? 'Close' : 'Enter'} fullscreen`}
          enterDelay={1000}
        >
          <IconButton
            onClick={() => props.onToggleFullScreen(!isFullscreen)}
            iconName={fullscreenIconName}
          />
        </Tooltip>
      </Actions>
      <ProgressView jobInfo={jobInfo} />
      {renderSaving()}
      <Lock isLocked={isLocked} onToggle={props.onToggleLock} />
    </Wrapper>
  );
}

export default EditorTopBar;
