export function SummarizationApiIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.83341 3.95832C5.25812 3.95832 4.79175 4.42469 4.79175 4.99999V16.6667C4.79175 17.242 5.25812 17.7083 5.83341 17.7083H14.1667C14.742 17.7083 15.2084 17.242 15.2084 16.6667V8.12499H12.5001C11.6947 8.12499 11.0417 7.47207 11.0417 6.66666V3.95832H5.83341ZM12.2917 4.84221L14.3245 6.87499H12.5001C12.385 6.87499 12.2917 6.78172 12.2917 6.66666V4.84221ZM3.54175 4.99999C3.54175 3.73434 4.56776 2.70832 5.83341 2.70832H11.6667C11.8325 2.70832 11.9915 2.77417 12.1087 2.89138L16.2754 7.05805C16.3926 7.17526 16.4584 7.33423 16.4584 7.49999V16.6667C16.4584 17.9323 15.4324 18.9583 14.1667 18.9583H5.83341C4.56776 18.9583 3.54175 17.9323 3.54175 16.6667V4.99999Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.45841 13.3333C6.45841 12.9881 6.73824 12.7083 7.08341 12.7083H8.75008C9.09526 12.7083 9.37508 12.9881 9.37508 13.3333C9.37508 13.6785 9.09526 13.9583 8.75008 13.9583H7.08341C6.73824 13.9583 6.45841 13.6785 6.45841 13.3333Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.45841 15.6667C6.45841 15.3215 6.73824 15.0417 7.08341 15.0417H12.9167C13.2619 15.0417 13.5417 15.3215 13.5417 15.6667C13.5417 16.0119 13.2619 16.2917 12.9167 16.2917H7.08341C6.73824 16.2917 6.45841 16.0119 6.45841 15.6667Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.45841 8.33332C6.45841 7.98815 6.73824 7.70832 7.08341 7.70832H8.75008C9.09526 7.70832 9.37508 7.98815 9.37508 8.33332C9.37508 8.6785 9.09526 8.95832 8.75008 8.95832H7.08341C6.73824 8.95832 6.45841 8.6785 6.45841 8.33332Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.45841 10.6667C6.45841 10.3215 6.73824 10.0417 7.08341 10.0417H12.9167C13.2619 10.0417 13.5417 10.3215 13.5417 10.6667C13.5417 11.0119 13.2619 11.2917 12.9167 11.2917H7.08341C6.73824 11.2917 6.45841 11.0119 6.45841 10.6667Z'
        fill='currentColor'
      />
      <path
        d='M18.091 2.26376L16.5215 2.36258L16.0115 1.71355C15.9879 1.64514 15.8859 1.65274 15.878 1.72876L15.6348 2.67426L14.3399 3.57888C14.285 3.61689 14.3086 3.6929 14.3792 3.70051L15.7211 3.80693L16.2783 5.12205C16.3018 5.18287 16.396 5.17527 16.4117 5.10685L16.7334 3.53327L18.146 2.37778C18.1931 2.33978 18.1538 2.25616 18.091 2.26376Z'
        fill='currentColor'
      />
    </svg>
  );
}
