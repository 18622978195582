import { createTheme } from '@mui/material';
import { components } from './theme.components';
import { palette } from './theme.palette';
import { shadows } from './theme.shadows';
import { typography } from './theme.typography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1: React.CSSProperties;
    h1light: React.CSSProperties;
    h1bold: React.CSSProperties;
    h2: React.CSSProperties;
    h2light: React.CSSProperties;
    h2bold: React.CSSProperties;
    h3: React.CSSProperties;
    h3light: React.CSSProperties;
    h3bold: React.CSSProperties;
    h4: React.CSSProperties;
    h4light: React.CSSProperties;
    h4bold: React.CSSProperties;
    h5: React.CSSProperties;
    h5light: React.CSSProperties;
    h5bold: React.CSSProperties;
    h6: React.CSSProperties;
    h6light: React.CSSProperties;
    h6bold: React.CSSProperties;
    subtitle1: React.CSSProperties;
    subtitle1light: React.CSSProperties;
    subtitle1bold: React.CSSProperties;
    subtitle2: React.CSSProperties;
    subtitle2light: React.CSSProperties;
    subtitle2bold: React.CSSProperties;
    body1: React.CSSProperties;
    body1light: React.CSSProperties;
    body1bold: React.CSSProperties;
    body2: React.CSSProperties;
    body2light: React.CSSProperties;
    body2bold: React.CSSProperties;
    caption: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1?: React.CSSProperties;
    h1light?: React.CSSProperties;
    h1bold?: React.CSSProperties;
    h2?: React.CSSProperties;
    h2light?: React.CSSProperties;
    h2bold?: React.CSSProperties;
    h3?: React.CSSProperties;
    h3light?: React.CSSProperties;
    h3bold?: React.CSSProperties;
    h4?: React.CSSProperties;
    h4light?: React.CSSProperties;
    h4bold?: React.CSSProperties;
    h5?: React.CSSProperties;
    h5light?: React.CSSProperties;
    h5bold?: React.CSSProperties;
    h6?: React.CSSProperties;
    h6light?: React.CSSProperties;
    h6bold?: React.CSSProperties;
    subtitle1?: React.CSSProperties;
    subtitle1light?: React.CSSProperties;
    subtitle1bold?: React.CSSProperties;
    subtitle2?: React.CSSProperties;
    subtitle2light?: React.CSSProperties;
    subtitle2bold?: React.CSSProperties;
    body1?: React.CSSProperties;
    body1light?: React.CSSProperties;
    body1bold?: React.CSSProperties;
    body2?: React.CSSProperties;
    body2light?: React.CSSProperties;
    body2bold?: React.CSSProperties;
    caption?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h1light: true;
    h1bold: true;
    h2: true;
    h2light: true;
    h2bold: true;
    h3: true;
    h3light: true;
    h3bold: true;
    h4: true;
    h4light: true;
    h4bold: true;
    h5: true;
    h5light: true;
    h5bold: true;
    h6: true;
    h6light: true;
    h6bold: true;
    subtitle1: true;
    subtitle1light: true;
    subtitle1bold: true;
    subtitle2: true;
    subtitle2light: true;
    subtitle2bold: true;
    body1: true;
    body1light: true;
    body1bold: true;
    body2: true;
    body2light: true;
    body2bold: true;
    caption: true;
  }
}

export const theme = createTheme({
  typography,
  palette,
  shadows,
  components,
});

export const darkTheme = createTheme({
  typography,
  shadows,
  components,
  palette: {
    mode: 'dark',
  },
});
