import amplitude, { AmplitudeClient } from 'amplitude-js';
import { invokeEvent } from 'shared-base';
import { AnalyticsEvent } from './analytics.types';
import { parseEvent } from './utils/events';

let instance: AmplitudeClient | any = {
  logEvent: () => {},
  setUserId: () => {},
  identify: () => {},
  getUserId: () => {},
};

let currentApp = '';

export const initInstance = (apiKey: string, app: string) => {
  instance = amplitude.getInstance();

  instance.init(apiKey, '', {
    includeUtm: true, // utm
    includeFbclid: true, // facebook
    includeGclid: true, // google
    includeReferrer: true, // referrer
  });

  currentApp = app;
};

export const logEvent = (eventId: string, props: Json, addPath?: boolean) => {
  const event: AnalyticsEvent = {
    eventId,
    props,
  };

  const eventParsed = parseEvent(event, {
    app: currentApp,
    addPath,
  });

  if (!eventParsed) {
    return;
  }

  invokeEvent('logs/analytics', {
    eventType: eventParsed.eventId,
    props: eventParsed.props,
  });

  instance.logEvent(eventParsed.eventId, eventParsed.props);
};

export const setUserId = (userId: string) => {
  instance.setUserId(userId);
};

export const setUserProperty = (key: string, value: string | Date | boolean | Json) => {
  const identify = new amplitude.Identify();
  identify.set(key, value);
  instance.identify(identify);
};

export const getUserId = () => {
  return instance.getUserId();
};
