import React from 'react';
import { guid4 } from 'shared-base';
import { Input, Wrapper } from './PincodeInput.style';

type InputType = 'numeric' | 'custom';

interface PinInputProps {
  length: number;
  initialValue?: number | string;
  type?: InputType;
  inputMode?: string;
  secret?: boolean;
  secretDelay?: number;
  disabled?: boolean;
  focus?: boolean;
  onChange?: (value: string, index: number) => void;
  onComplete?: (value: string, index: number) => void;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  inputFocusStyle?: React.CSSProperties;
  validate?: (value: string) => string;
  autoSelect?: boolean;
  regexCriteria?: any;
  ariaLabel?: string;
  placeholder?: string;
}

export type Props = PinInputProps;

export const PincodeInput = React.forwardRef((props: Props, ref: any) => {
  const id = guid4();

  return (
    <Wrapper
      className='PincodeInput-wrapper'
      data-testid='PincodeInput-wrapper'
    >
      <Input {...props} ref={ref} />
    </Wrapper>
  );
});

export default PincodeInput;
