import { LoginProvider } from '@ai21/studio-auth';
import { Form } from '@ai21/studio-forms';
import { DrawerContainer as Drawer, Prompt, StudioTheme, Toast } from '@ai21/studio-ui';
import { BrowserRouter as Router } from 'react-router-dom';
import { Web } from './Web';

const appRoot = window.__env__.VITE_WEB_APP_ROOT ?? '';

export function WebContainer() {
  return (
    <StudioTheme>
      <LoginProvider>
        <Router basename={appRoot}>
          <Web />
        </Router>
        <Toast />
        <Prompt formComponent={Form} />
        <Drawer />
      </LoginProvider>
    </StudioTheme>
  );
}
