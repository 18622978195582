import { RefObject, useEffect, useRef, useState } from 'react';

export type UseEllipsisReturn = [RefObject<HTMLDivElement>, boolean, React.CSSProperties];

export function useEllipsis(text: string): UseEllipsisReturn {
  const ref = useRef<HTMLDivElement>(null);
  const [withEllipsis, setWithEllipsis] = useState(false);
  const [style, setStyle] = useState<React.CSSProperties>({});

  useEffect(() => {
    const { current } = ref as RefObject<HTMLDivElement>;

    if (!current) {
      return;
    }

    const box = current.getBoundingClientRect();
    const maxWidth = findParentWidth(current, 'MuiDataGrid-cell', 120) - 10;

    if (box.width > 40) {
      setWithEllipsis(true);
      setStyle({
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth,
      });
    }
  }, [text]);

  return [ref, withEllipsis, style];
}

export function findParentWidth(
  el: HTMLElement,
  parentClassName: string,
  defaultValue: number
): number {
  let parent = el;

  let i = 0;

  while (parent && !parent.classList.contains(parentClassName) && i < 10) {
    parent = parent.parentElement as HTMLDivElement;
    i++;
  }

  let width = defaultValue;

  if (parent && parent.classList.contains(parentClassName)) {
    width = parent.getBoundingClientRect().width;
  }

  return width;
}
