export function PythonIcon({ style }: { style: React.CSSProperties }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fill='#626B78'
    >
      <path d='M11 5.247c.416 0 .753.337.753.753v.01a.753.753 0 1 1-1.506 0V6c0-.416.337-.753.753-.753zM13.753 18a.753.753 0 1 0-1.506 0v.01a.753.753 0 1 0 1.506 0V18z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.247 8.247V5A2.753 2.753 0 0 1 10 2.247h4A2.753 2.753 0 0 1 16.753 5v1.247H19A2.753 2.753 0 0 1 21.753 9v4A2.752 2.752 0 0 1 19 15.753h-2.247V19A2.753 2.753 0 0 1 14 21.753h-4A2.753 2.753 0 0 1 7.247 19v-1.247H5A2.753 2.753 0 0 1 2.247 15v-4A2.753 2.753 0 0 1 5 8.247h2.247zm1.871-4.129c.234-.234.551-.365.882-.365h4A1.247 1.247 0 0 1 15.247 5v5A1.247 1.247 0 0 1 14 11.247h-4A2.753 2.753 0 0 0 7.247 14v2.247H5A1.247 1.247 0 0 1 3.753 15v-4A1.247 1.247 0 0 1 5 9.753h7a.753.753 0 1 0 0-1.506H8.753V5c0-.33.131-.648.365-.882zm6.13 11.635H12a.753.753 0 1 1 0-1.506h7A1.247 1.247 0 0 0 20.247 13V9A1.247 1.247 0 0 0 19 7.753h-2.247V10A2.753 2.753 0 0 1 14 12.753h-4A1.247 1.247 0 0 0 8.753 14v5A1.247 1.247 0 0 0 10 20.247h4A1.247 1.247 0 0 0 15.247 19v-3.247z'
        style={style}
      />
    </svg>
  );
}
