import { Typography, tokens } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px 0 16px;

  > .icon {
    margin-right: 4px;
  }

  svg {
    height: 20px;
    width: 20px;
    stroke-width: 1.5px;
  }

  &.selected {
    .title {
      color: ${tokens.primary.mainDefault};
    }

    svg.icon-tabler-arrow-up-right {
      color: ${tokens.primary.mainDefault};
    }
  }
`;

export const Title = styled(Typography)`
  margin-right: 10px;
`;
