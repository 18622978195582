import type { IModel } from '@ai21/studio-store';
import { Table } from '@ai21/studio-tables';
import { format } from '@ai21/studio-ui';
import { tables } from '../../_definitions/tables';
import { Json } from '../../types';
import { Content, Details, Header, Subtitle, Title, Wrapper } from './EpochSelection.style';

export type EpochSelectionProps = {
  data: {
    model?: IModel;
    tableModelInfo: Json[];
    tableModelEpochs: Json[];
    loading: boolean;
  };
  callbacks: {
    onTableAction: (tableId: string, actionId: string, params?: Json) => void;
    onRowAction: (tableId: string, actionId: string, rowId: string) => void;
    onCancel: () => void;
  };
};

export function EpochSelection(props: EpochSelectionProps) {
  const { data, callbacks } = props;
  const { name, creationDate } = data.model ?? {};

  const date = format.date.normal(creationDate);

  return (
    <Wrapper className='EpochSelection-wrapper' data-testid='EpochSelection-wrapper'>
      <Header variant='h6' data-testid='name'>
        {name}
      </Header>
      <Details variant='subtitle2' color='text.secondary'>
        Created on {date}
      </Details>
      <Content>
        <Table
          config={tables.modelInfo}
          data={data.tableModelInfo}
          isLoading={data.loading}
          callbacks={callbacks}
          noPadding
          hideFooter
        />
        <Title variant='subtitle2' color='text.primary'>
          Trained Epochs
        </Title>
        <Subtitle variant='body1' color='text.secondary'>
          Choose any epoch from the list, try in playground, copy api code or set as the new model
          default.
        </Subtitle>
        <Table
          config={tables.modelEpochs}
          data={data.tableModelEpochs}
          isLoading={data.loading}
          callbacks={callbacks}
          noPadding
        />
      </Content>
    </Wrapper>
  );
}
export default EpochSelection;
