import { IShortKey } from '../../types';

const isMac = navigator.userAgent.toLowerCase().indexOf('mac') >= 0;
const altKey = isMac ? '⌥' : 'Alt+';

export const parseShortKeyText = (shortKey?: IShortKey) => {
  if (!shortKey) {
    return '';
  }

  const { key, withCommand, withAlt, withShift, withCtrl } = shortKey;

  let keyText = key;

  switch (key) {
    case 'ç':
      keyText = 'C';
      break;
    case 'ArrowLeft':
      keyText = '<-';
      break;
    case 'ArrowRight':
      keyText = '->';
      break;
    case 'Enter':
      keyText = '↵';
      break;
  }

  const cmd = withCommand ? '⌘' : '';
  const alt = withAlt ? altKey : '';
  const shift = withShift ? '⇧' : '';
  const ctrl = withCtrl ? '⌃' : '';

  return `(${cmd}${alt}${shift}${ctrl}${keyText})`;
};
