import { generateReducersForStore } from 'redux-store-generator';
import { actions } from './actions';
import { StoreBuilder } from './builders/StoreBuilder';
import { setStore } from './globals';
import { initialState } from './initialState';
import { middlewareConnected } from './middleware/mid.connected';
import { studioSagas } from './sagas';
import { IStoreOptions, IStudioStore } from './types';

export const reducers = generateReducersForStore<IStudioStore>(initialState);

export const initStore = (options: IStoreOptions) => {
  const storeBuilder = new StoreBuilder('studio');

  storeBuilder
    .withReducers(reducers)
    .withReducers(options.previousRootReducer)
    .withInitialState(initialState)
    .withDevtoolsExtensions(true)
    .withSagas(...studioSagas, options.rootSaga)
    .withPreMiddlewares(middlewareConnected)
    .withMiddlewares(options.middlewares ?? []);

  const store = storeBuilder.build();
  clearState(store);

  setStore(store);
  return store;
};

export const clearState = (store: any) => {
  setTimeout(() => {
    store.dispatch(actions.users.setAll({}));
    store.dispatch(actions.fineTuningSets.setAll({}));
    store.dispatch(actions.completionUsages.clear());
    store.dispatch(actions.specializedUsages.clear());
    store.dispatch(actions.trainingUsages.clear());
    store.dispatch(actions.invoices.setAll({}));
    store.dispatch(actions.evaluationJobs.setAll({}));
    store.dispatch(actions.evaluationLines.setAll({}));
    store.dispatch(actions.generationJobs.setAll({}));
    store.dispatch(actions.generationLines.setAll({}));
    store.dispatch(actions.collectionJobs.setAll({}));
    store.dispatch(actions.collectionLines.setAll({}));
    store.dispatch(actions.documents.setAll({}));
    store.dispatch(actions.documentQuestions.setAll({}));
    store.dispatch(actions.pas.setAll({}));
    store.dispatch(actions.demos.setAll({}));
  });

  return store;
};
