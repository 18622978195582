import { AxiosRequestConfig } from 'axios';
import { FirebaseApp } from 'firebase/app';

export type IApiConfig = {
  identityBaseUrl: string;
  studioApiBaseUrl: string;
  app: FirebaseApp;
  organizationId: string;
  isTest?: boolean;
};

export type IApiTokens = {
  firebaseIdToken: string;
  studioApiKey: string;
};

export type HttpMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';

export type IApiRequest = {
  path: string;
  method?: HttpMethod;
  data?: Json | FormData;
  config?: AxiosRequestConfig;
  isIdentity?: boolean;
  isHub?: boolean;
  isPublic?: boolean;
  errorMessageXPath?: string;
  endpoint: string;
  transformer?: any;
  appFeature?: string;
};

export type ApiResponse<T> = {
  request: IApiRequest;
  status: number;
  statusText: string;
  data: T;
  isSuccess?: boolean;
  isError?: boolean;
  headers: Json;
  errorType?: ApiErrorType;
  errorMessage?: string;
  timestampStart: number;
  timestampEnd: number;
  duration: number;
};

export type ApiErrorType = 'none' | 'timeout' | 'authorization' | 'server' | 'javascript';

// ==================== payloads ====================

export type PayloadOrganizationPatch = {
  name: string;
};

export type PayloadOrganizationUserChangeRole = {
  email: string;
  role: UserRole;
};

export type PayloadCreateCustomModel = {
  modelName: string;
  learningRate: number;
  epochs: number;
  datasetId: string;
  modelType: CustomModelType;
};

export type PayloadCreateFineTuningSet = {
  name: string;
  file: File;
  selectedColumns?: SelectedColumns;
  approveWhitespaceCorrection?: boolean;
  deleteLongRows?: boolean;
};
export type PayloadCreateDocument = {
  name: string;
  file: File;
  labels?: string[];
  path?: string;
  publicUrl?: string;
};

export type IChatMessage = {
  role: 'assistant' | 'user';
  systemMessage: string;
  content: string;
  isEdited?: boolean;
  hash?: string;
};

export type PayloadCompletion = {
  prompt: string;
  messages?: IChatMessage[];
  modelId?: string;
  system?: string;
  numResults: number;
  maxTokens: number;
  temperature: number;
  topP: number;
  stopSequences: string[];
  numStopSequences: number;
  promptLength: number;
  topKReturn?: number | undefined;
  presencePenalty: number;
  countPenalty: number;
  frequencyPenalty: number;
  showAlternativeTokens?: boolean;
  applyToWhitespaces?: boolean;
  applyToPunctuations?: boolean;
  applyToNumbers?: boolean;
  applyToStopwords?: boolean;
  applyToEmojis?: boolean;
  isExperimental?: boolean;
  customType?: string;

  // transient
  model?: string;
};

// lego APIs

export type PublicEnabled = {
  demo?: boolean;
};

export type PayloadParaphrase = {
  text: string;
  style?: string;
  startIndex?: number;
  endIndex?: number;
} & PublicEnabled;

export type PayloadSummarization = {
  sourceType: string;
  source: string;
} & PublicEnabled;

export type PayloadSegmentation = {
  sourceType: string;
  source: string;
} & PublicEnabled;

export type PayloadGEC = {
  text: string;
} & PublicEnabled;

export type PayloadImprovements = {
  text: string;
  types: string[];
} & PublicEnabled;

export type PayloadAnswer = {
  context: string;
  question: string;
} & PublicEnabled;

export type PayloadShare = {
  params: PayloadCompletion;
  text: string;
  apiType: 'completion';
};

export type PayloadCustomModelCalculateCost = {
  modelType: string;
  epochs: number;
  sizeBytes: number;
};

export type PayloadFeedback = {
  requestId: string;
  score: number;
  reasons: string[];
  model: string;
};

export type PayloadUpgradeUser = {
  paymentMethodId: string;
  tier: Tier;
  coupon?: string;
};

export type PayloadCreatePreset = {
  text: string;
  name: string;
  params: PayloadCompletion;
  apiType: string;
};

// ==================== datatypes ====================
export type SelectedColumns = {
  prompt: string;
  completion: string;
};

export enum CustomModelType {
  LIGHT_J2 = 'j2-light',
  MID_J2 = 'j2-mid',
  ULTRA_J2 = 'j2-ultra',
  JAMBA_INSTRUCT = 'jamba-instruct-preview',
  JAMBA_1_5_LARGE = 'jamba-1.5-large',
  JAMBA_1_5_MINI = 'jamba-1.5-mini',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export enum CompletionFailureReason {
  CREDIT_EXCEEDED = 'CREDIT_EXCEEDED',
  TRIAL_ENDED = 'TRIAL_ENDED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  TOO_MANY_TOKENS = 'TOO_MANY_TOKENS',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
}

export enum Tier {
  FREE = 'FREE',
  BASIC = 'BASIC',
  SCALE = 'SCALE',
  NONE = 'NONE',
}

export type Json = Record<string, unknown>;

export type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

export type MockRequest = {
  method: 'get' | 'post' | 'delete';
  url: string;
  data: Json;
};
