import { Button, IconButton, Link, styledTheme, Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';

export const Wrapper = styled.div<{ noBorder: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 640px;
  border: ${(props) => (props.noBorder ? 'none' : '1px solid #d5d8dc')};
  box-shadow: ${(props) => (props.noBorder ? 'none' : '0px 4px 15px rgba(84, 62, 126, 0.13)')};
  border-radius: 20px;
  padding: 24px;
  margin: 24px;

  @media (max-width: 768px) {
    margin: 0;
    box-shadow: none;
    padding: 18px;
  }
`;

export const WidgetTitle = styled(Typography)`
  font-weight: 800;
`;

export const Label = styled(Typography)`
  padding-bottom: 8px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Cta = styled(Button)<{ themeId: string }>`
  border-radius: 12px;
  height: 42px;
  background-color: ${(props) => (props.themeId == 'pink' ? '#E91E63' : '')};
  &:hover,
  &:active {
    background-color: ${(props) => (props.themeId == 'pink' ? 'rgb(210 27 89)' : '')} !important;
  }
`;

export const SnippetButton = styled(IconButton)`
  width: 42px;
  height: 42px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 12px;

  &:hover {
    border-radius: 12px;
  }
`;

export const FooterLink = styledTheme(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const Editor = styled.div`
  margin: 16px 0 8px 0;

  & textarea {
    overflow: auto !important;
    padding: 6px 6px 6px 12px;
    font-size: 16px;
    margin: 6px 6px 6px 0;
    ::-webkit-scrollbar {
      width: 4px;
      height: 10px;
      padding-right: 4px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background: #afafaf;
    }
  }
`;

export const OutputItem = styled.div<{ color: string }>`
  padding: 16px;
  color: ${(props) => props.color || '#000000DE'};

  & + & {
    border-top: 1px solid #e6e8ee;
  }
`;

export const OutputContainer = styled.div<{ padding: number }>`
  height: 216px !important;
  border: 1px solid #c4c4c4;
  border-radius: 12px;
  overflow: auto;
  padding: ${(props) => props.padding || 0}px;
  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
    padding-right: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #afafaf;
  }
`;
