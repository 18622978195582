import { snakeCase } from 'lodash';

export type Meta = {
  layout?: string;
  variables: Json;
  instructions?: string;
  context?: string;
  shots?: Json[];
  isChat?: boolean;
  isMimic?: boolean;
};

export const addVariables = (prompt: string, meta: Meta) => {
  const { variables = {} } = meta;

  let output = prompt;

  Object.keys(variables).forEach((key) => {
    const value = variables[key];
    output = output.replace(new RegExp(`{{${key}}}`, 'g'), value);
    output = output.replace(new RegExp(`{{${snakeCase(key)}}}`, 'g'), value);
  });

  return output;
};

export const shots = [
  {
    input: 'Translate English to French.',
    output: 'Provide a French translation for the following English text.',
  },
  {
    input: 'Tell me about space.',
    output:
      'Give me an overview of topics related to outer space, including astronomy, planets, and the universe.',
  },
  {
    input: 'How to grow tomatoes?',
    output:
      'Explain the step-by-step process of cultivating healthy tomato plants, including soil preparation, planting, watering, and maintenance.',
  },
  {
    input: 'What is climate change?',
    output:
      'Provide a comprehensive explanation of climate change, its causes, effects, and potential solutions.',
  },
  {
    input: 'Who was Einstein?',
    output:
      'Explore the life and contributions of Albert Einstein, a renowned physicist known for his theory of relativity and other key achievements.',
  },
  {
    input: 'Best budget laptop.',
    output:
      'Recommend budget-friendly laptop options, considering factors like performance, build quality, and user reviews.',
  },
  {
    input: 'History of the Renaissance.',
    output:
      'Discuss the historical context, cultural shifts, and significant figures that defined the Renaissance period.',
  },
  {
    input: 'How does meditation work?',
    output:
      'Explore the mechanisms behind meditation, its benefits for mental and physical well-being, and various meditation techniques.',
  },
  {
    input: 'Easy DIY woodworking.',
    output:
      'Provide beginner-friendly woodworking project ideas and instructions, highlighting safety measures and basic woodworking skills.',
  },
  {
    input: 'Homemade vegetable soup.',
    output:
      'Share a simple and nutritious homemade vegetable soup recipe, detailing the ingredients, preparation, and cooking steps.',
  },
];
