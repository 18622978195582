import {
  IWidgetConfig,
  Input,
  InputToPayload,
  ResponseToOutput,
  WidgetPayload,
  OutputToAnalyticsPayload,
} from '../MiniPlayground.types';

export const config: IWidgetConfig = {
  title: 'Paraphrase API',
  ctaText: 'Paraphrase',
  tooltipText: 'API supports texts up to 500 characters',
  defaultInput: {
    main: 'Vikings were Norse seafarers who raided the coast of Europe for centuries.',
  },
  docsUrl: 'https://docs.ai21.com/reference/paraphrase-api-ref',
  inputType: 'basic',
  outputType: 'lines',
  apiMethodName: 'paraphrase',
  snippetMethodName: 'paraphrase',
  rules: [
    {
      ruleType: 'nonEmpty',
      errorMessage: 'Text must not be empty',
    },
    {
      ruleType: 'maxLength',
      errorMessage:
        'Demo is limited to {value} characters. Use the API to paraphrase texts up to 500 characters.',
      value: 200,
      linkText: 'Use the API',
      linkUrl: 'https://docs.ai21.com/reference/paraphrase-api-ref',
    },
  ],
};

export const inTransformer: InputToPayload = (
  input: Input,
  params: Json = { style: 'general' }
) => ({
  text: input.main,
  style: params.style,
});

export const analyticsInputPayload = (input: Input, params: Json = { style: 'general' }) => ({
  textLength: input.main.length,
  style: params.style,
});

export const outTransformer: ResponseToOutput = (response: Json, input: Input) => {
  const { data, isSuccess } = response;

  return {
    text: isSuccess ? data.suggestions.map((suggestion: any) => suggestion.text) : undefined,
    errorMessage: data.detail,
    isSuccess,
    originalInput: input,
    count: isSuccess ? data.suggestions.length : undefined,
    unit: 'results',
  };
};

export const analyticsOutputPayload: OutputToAnalyticsPayload = (response: Json) => {
  const { data, duration, request } = response;
  return {
    numSuggestions: data.suggestions.length,
    errorMessage: data.detail,
    requestDuration: duration,
    interactionId: data.id,
    endpoint: request.path,
  };
};

export const payload: WidgetPayload = {
  id: 'paraphrase',
  config,
  inTransformer,
  outTransformer,
  analyticsInputPayload,
  analyticsOutputPayload,
};
