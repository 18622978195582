import React from 'react';

const PlayHomePage = React.lazy(() => import('./playHome/PlayHome.container'));
const PlayFamilyPage = React.lazy(
  () => import('./playFamily/PlayFamily.container')
);
const PlayPasPage = React.lazy(() => import('./playPas/PlayPas.container'));
const PlayLayoutPage = React.lazy(
  () => import('./playLayout/PlayLayout.container')
);

export const pages: Record<string, React.FC<any>> = {
  playHome: PlayHomePage,
  playFamily: PlayFamilyPage,
  playLayout: PlayLayoutPage,
  playPas: PlayPasPage,
};
