import { ButtonMenu, tokens } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .BoxHeader-wrapper {
    border-bottom: 1px solid ${tokens.component.dividerDefault};
  }
`;

export const Menu = styled(ButtonMenu)`
  margin-left: 6px;

  .MuiButton-root {
    border: none;
    padding: 6px;
  }
`;

export const Actions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > div,
  > button {
    margin-left: 6px;
  }
`;
