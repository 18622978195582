export function NotificationIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3031 3.98812C14.2591 3.96753 14.2073 3.97421 14.17 4.00526L10.4001 7.14683C10.2878 7.24043 10.1462 7.29169 10 7.29169H8.95833V11.0417H10C10.1462 11.0417 10.2878 11.0929 10.4001 11.1866L14.17 14.3281C14.2073 14.3592 14.2591 14.3658 14.3031 14.3453C14.347 14.3247 14.375 14.2805 14.375 14.232V4.10169C14.375 4.05318 14.347 4.00872 14.3031 3.98812ZM15.625 7.39842V10.935C16.3532 10.6776 16.875 9.98311 16.875 9.16672C16.875 8.35034 16.3532 7.65581 15.625 7.39842ZM15.625 12.2292C17.0514 11.9397 18.125 10.6786 18.125 9.16672C18.125 7.65488 17.0514 6.39378 15.625 6.10424V4.10201M15.625 12.2292V14.2314C15.625 14.2313 15.625 14.2315 15.625 14.2314C15.6251 14.7648 15.3167 15.2506 14.8337 15.477C14.3506 15.7035 13.78 15.6301 13.37 15.2886L9.77372 12.2917H8.95833V15.8334C8.95833 16.6388 8.30542 17.2917 7.5 17.2917H6.66667C5.86125 17.2917 5.20833 16.6388 5.20833 15.8334V12.2917H3.33333C2.52792 12.2917 1.875 11.6388 1.875 10.8334V7.50002C1.875 6.69461 2.52792 6.04169 3.33333 6.04169H9.77372L13.3699 3.04488C13.7799 2.70338 14.3506 2.62983 14.8337 2.85634C15.3167 3.0828 15.6251 3.56854 15.625 4.10201M6.45833 12.2917V15.8334C6.45833 15.9485 6.55161 16.0417 6.66667 16.0417H7.5C7.61506 16.0417 7.70833 15.9485 7.70833 15.8334V12.2917H6.45833ZM7.70833 11.0417V7.29169H3.33333C3.21827 7.29169 3.125 7.38496 3.125 7.50002V10.8334C3.125 10.9484 3.21827 11.0417 3.33333 11.0417H7.70833Z'
        fill='#672ED0'
      />
    </svg>
  );
}
