import { Typography, tokens } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const Chevron = styled.div`
  svg {
    color: #999;
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease-in-out;
  }

  &.open {
    svg {
      transform: rotate(90deg);
    }
  }
`;

export const Label = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  /* text-transform: uppercase; */
  font-size: 14px;
  font-weight: 600;
  color: ${tokens.neutral.deepest};
  margin-bottom: 12px;
  margin-top: 16px;
`;

export const Content = styled.div``;
