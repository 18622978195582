import { delay, select, takeEvery } from 'saga-ts';
import { Json } from '../types';
import { $invoice } from '../redux/selectors';

type Verb = 'downloadPdf';

type ActionBilling = {
  type: 'BILLING';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  downloadPdf: downloadPdf,
};

function* downloadPdf(action: ActionBilling) {
  const { id } = action;

  const invoice = yield* select($invoice, id);
  if (!invoice) {
    return;
  }

  window.open(invoice.pdf);
}

export function* billing(action: ActionBilling) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('BILLING', billing);
}
