import { addListener, invokeEvent } from 'shared-base';
import { IOption } from '../../types';

type PromptRequest = {
  title: string;
  label?: string;
  id?: string;
  description?: string;
  defaultValue?: string;
  warning?: string;
  options?: IOption[];
  form?: PromptForm;
  code?: PromptCode;
  placeholder?: string;
  ctaButtonText?: string;
  cancelButtonText?: string;
  allowNewLine?: boolean;
  rows?: number;
  hideCancel?: boolean;
  hideBackdrop?: boolean;
  point?: Json;
  component?: React.FC<any>;
  headerCta?: React.FC<any>;
  headerCtaProps?: Json;
  componentProps?: Json;
  componentCta?: string;
  callbacks?: any;
  intention:
    | 'delete'
    | 'upload'
    | 'upgrade'
    | 'rotate'
    | 'share'
    | 'custom'
    | 'rename'
    | 'code'
    | 'logout';
};

type PromptResponse = {
  didCancel: boolean;
  value?: string | Json;
};

type PromptForm = {
  config: any;
  data?: Json;
  allOptions?: Json;
  allDetails?: Json;
  allMethods?: Json;
  isEdit?: boolean;
};

type PromptCode = {
  python: string;
  javascript: string;
  curl: string;
};

type ICallback = () => void;

let listeners: ICallback[] = [],
  listener: ICallback;

const invokePromptAndListen = (data: Json): Promise<PromptResponse> => {
  const cleanUp = () => {
    listeners.forEach((listener) => {
      if (typeof listener === 'function') {
        listener();
      }
    });
  };

  cleanUp();

  return new Promise((resolve) => {
    invokeEvent('SHOW_PROMPT', data);

    invokeEvent('global/dialog', {
      type: data.flavour,
      action: 'show',
      title: data.title,
      id: data.id,
    });

    listener = addListener('PROMPT_RESPONSE', (ev: any) => {
      const { value } = ev;
      cleanUp();
      resolve({ didCancel: false, value });
    });

    listeners.push(listener);

    listener = addListener('PROMPT_CANCEL', () => {
      cleanUp();
      resolve({ didCancel: true });
    });

    listeners.push(listener);
  });
};

const confirm = (promptRequest: PromptRequest): Promise<PromptResponse> => {
  const {
    title,
    ctaButtonText,
    cancelButtonText,
    hideBackdrop,
    description,
    warning,
    intention,
  } = promptRequest;

  return invokePromptAndListen({
    flavour: 'confirm',
    title,
    ctaButtonText,
    cancelButtonText,
    hideBackdrop,
    params: {
      description,
      warning,
      intention,
    },
  });
};

const input = (
  promptRequest: PromptRequest | string
): Promise<PromptResponse> => {
  if (typeof promptRequest === 'string') {
    promptRequest = {
      title: promptRequest,
      placeholder: 'Enter value',
      ctaButtonText: 'Ok',
      intention: 'rename',
    };
  }

  const {
    title,
    ctaButtonText,
    description,
    defaultValue,
    label,
    placeholder,
    hideBackdrop,
    allowNewLine,
    warning,
    rows,
    intention,
  } = promptRequest as PromptRequest;

  return invokePromptAndListen({
    flavour: 'input',
    title,
    ctaButtonText,
    hideBackdrop,
    params: {
      title,
      label,
      description,
      defaultValue,
      placeholder,
      warning,
      intention,
      allowNewLine,
      rows,
    },
  });
};

const select = (promptRequest: PromptRequest): Promise<PromptResponse> => {
  const {
    title,
    ctaButtonText,
    hideBackdrop,
    description,
    defaultValue,
    options,
    label,
    placeholder,
    warning,
    intention,
  } = promptRequest;

  return invokePromptAndListen({
    flavour: 'select',
    title,
    ctaButtonText,
    hideBackdrop,
    params: {
      title,
      description,
      defaultValue,
      label,
      placeholder,
      options,
      warning,
      intention,
    },
  });
};

const choice = (promptRequest: PromptRequest): Promise<PromptResponse> => {
  const { title, ctaButtonText, hideBackdrop, options, defaultValue } =
    promptRequest;

  return invokePromptAndListen({
    flavour: 'choice',
    title,
    ctaButtonText,
    hideBackdrop,
    params: {
      options,
      defaultValue,
    },
  });
};

const form = (promptRequest: PromptRequest): Promise<PromptResponse> => {
  const { title, form, hideBackdrop, intention } = promptRequest;

  return invokePromptAndListen({
    flavour: 'form',
    title,
    hideBackdrop,
    params: {
      ...form,
      intention,
    },
  });
};

const pie = (promptRequest: PromptRequest): Promise<PromptResponse> => {
  const { options, point, hideBackdrop, intention } = promptRequest;

  return invokePromptAndListen({
    flavour: 'pie',
    hideBackdrop,
    params: {
      options,
      point,
      intention,
    },
  });
};

const code = (promptRequest: PromptRequest): Promise<PromptResponse> => {
  const { title = 'API call', code, hideBackdrop, callbacks } = promptRequest;

  return invokePromptAndListen({
    flavour: 'code',
    title,
    ctaButtonText: 'Copy Code',
    hideBackdrop,
    params: {
      code,
      callbacks,
      description:
        'Use the code below to integrate the API into your application. The current prompt and configuration are applied. You can find your API key in the [account page.](https://studio.ai21.com/account/api-key)',
    },
  });
};

const custom = (promptRequest: PromptRequest): Promise<PromptResponse> => {
  const {
    title,
    component,
    componentProps,
    intention,
    componentCta,
    hideBackdrop,
    headerCta,
    headerCtaProps,
    id,
  } = promptRequest;

  return invokePromptAndListen({
    flavour: 'custom',
    title,
    id,
    hideBackdrop,
    params: {
      component,
      componentProps,
      componentCta,
      intention,
      headerCta,
      headerCtaProps,
    },
  });
};

export type InputPrompt = (
  promptRequest: PromptRequest | string
) => Promise<PromptResponse>;

export const prompt = {
  confirm,
  input,
  select,
  choice,
  form,
  pie,
  custom,
  code,
};
