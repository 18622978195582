import { useEffect, useState } from 'react';
import { Wrapper, WrapperPage } from './Loader.style';
import { Player } from '@lottiefiles/react-lottie-player';
import data from './Loader.data.json';

export type LoaderProps = {
  debounce: number;
  full: boolean;
  height?: string;
};

// const assets = 'https://raw.githubusercontent.com/dht/assets/main';

export function Loader(props: LoaderProps) {
  const { debounce = 0, full, height } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce]);

  if (!show) {
    return null;
  }

  function renderInner() {
    return (
      <Wrapper className='Loader-wrapper' data-testid='Loader-wrapper'>
        <Player
          autoplay
          loop
          // src={assets + '/loader-circle.json'}
          src={data}
          style={{ height: '140px', width: '140px' }}
        ></Player>
      </Wrapper>
    );
  }

  if (full || height) {
    return (
      <WrapperPage className='Loader-wrapper' height={height}>
        {renderInner()}
      </WrapperPage>
    );
  }

  return renderInner();
}

export default Loader;
