import { Button, IconButton, Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 46px;
  background: linear-gradient(0deg, #ffffff, #ffffff), #f6f6f6;
  padding: 4.5px 16px;
  border-bottom: 1px solid #e5e5e5;
`;

export const Actions = styled.div`
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid ${tokens.primary.borderDefault};

  svg {
    stroke-width: 1.5px;
    color: ${tokens.text.primary};
    width: 20px;
    height: 20px;
  }
`;

export const People = styled.div`
  margin-right: 8px;
`;

export const Saving = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;

  &.spin {
    svg {
      width: 20px;
      animation: spin 1s linear infinite;
    }
  }

  svg {
    margin-right: 5px;
    stroke-width: 1.5px;
    color: #333;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
