export function EmptyStarIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.9697 3.46967C14.2626 3.17678 14.7374 3.17678 15.0303 3.46967L20.5303 8.96967C20.8232 9.26256 20.8232 9.73744 20.5303 10.0303C20.2476 10.313 19.7954 10.3229 19.5009 10.0598L16.1452 13.4155L14.7022 17.2633C14.6647 17.3636 14.606 17.4546 14.5303 17.5303L13.0303 19.0303C12.7374 19.3232 12.2626 19.3232 11.9697 19.0303L9 16.0607L5.03033 20.0303C4.73744 20.3232 4.26256 20.3232 3.96967 20.0303C3.67678 19.7374 3.67678 19.2626 3.96967 18.9697L7.93934 15L4.96967 12.0303C4.67678 11.7374 4.67678 11.2626 4.96967 10.9697L6.46967 9.46967C6.54537 9.39396 6.63641 9.33535 6.73666 9.29775L10.5845 7.8548L13.9402 4.49915C13.6771 4.20465 13.687 3.75238 13.9697 3.46967ZM15 5.56066L11.5303 9.03033C11.4546 9.10604 11.3636 9.16465 11.2633 9.20225L7.41546 10.6452L6.56066 11.5L9.53015 14.4695L12.5 17.4393L13.3548 16.5845L14.7978 12.7367C14.8353 12.6364 14.894 12.5454 14.9697 12.4697L18.4393 9L15 5.56066Z'
        fill='#626B78'
      />
    </svg>
  );
}
