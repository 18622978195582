import { Icon, toast } from '@ai21/studio-base-ui';
import { CopyButton, InputAndButton, Instructions, UrlInput, Wrapper } from './UrlShare.style';

export type UrlShareProps = {
  url: string;
  callbacks: {
    onCta: () => void;
    onCancel: () => void;
  };
};

export function UrlShare(props: UrlShareProps) {
  const { url } = props;

  const copyUrl = () => {
    navigator.clipboard.writeText(url);
    toast.show('URL copied to clipboard');
    props.callbacks.onCta();
  };

  return (
    <Wrapper>
      <Instructions variant='body1'>
        Share your prompt and configuration with others via this link:
      </Instructions>
      <InputAndButton>
        <UrlInput readOnly value={url} data-testid='prompt_share_url' />
        <CopyButton
          size='small'
          onClick={() => {
            copyUrl();
          }}
          data-testid='copy_prompt_share_btn'
          iconName='Copy'
        />
      </InputAndButton>
    </Wrapper>
  );
}

export default UrlShare;
