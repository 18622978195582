import { Link } from '@ai21/studio-ui';
import { Link as RouterLink } from 'react-router-dom';
import { DOCS_ROUTE } from './constants';

export const MOBILE_PAGE_SUB_TEXT = (
  <>
    You will be redirected to our{' '}
    <Link color='inherit' href={DOCS_ROUTE} data-testid='link-documentation'>
      documentation
    </Link>{' '}
    pages in
  </>
);
