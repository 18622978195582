import React, { useRef, useImperativeHandle, RefObject } from 'react';
import { icons } from '@ai21/studio-icons';
import { InputAdornment } from '@mui/material';
import { Input, Wrapper } from './Search.style';
import { invokeEvent } from 'shared-base';
import { Icon } from '../Icon/Icon';

export type SearchProps = {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
};

export type SearchHandle = {
  focus: () => void;
};

export const Search = React.forwardRef<SearchHandle, SearchProps>(
  (props, forwardRef) => {
    const { value, placeholder } = props;
    const ref = useRef<HTMLInputElement>(null);

    useImperativeHandle(forwardRef, () => ({
      focus: () => {
        if (ref.current) {
          ref.current.focus();
        }
      },
    }));

    function onClick(ev: React.MouseEvent<HTMLInputElement, MouseEvent>) {
      invokeEvent('global/click', {
        ev,
      });
    }

    return (
      <Wrapper className='Search-wrapper' data-testid='Search-wrapper'>
        <Input
          inputRef={ref}
          size='small'
          id='search'
          fullWidth
          value={value}
          autoComplete='off'
          placeholder={placeholder}
          color='primary'
          onClick={onClick}
          data-testid='Search-input'
          onChange={(searchValue) => props.onChange(searchValue.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icon iconName='Search' />
              </InputAdornment>
            ),
            style: { height: '38px' },
          }}
          variant='outlined'
        />
      </Wrapper>
    );
  }
);

export default Search;
