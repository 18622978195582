export function DropAnywhereModalIcon() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20 15c7.364 0 13.333-2.239 13.333-5S27.363 5 20 5C12.636 5 6.666 7.239 6.666 10s5.97 5 13.334 5z'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.015 24.638c1.54.234 3.221.362 4.985.362 7.363 0 13.333-2.238 13.333-5V10M6.667 10v13.333V10z'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.753 34.978c.41.015.827.022 1.247.022 7.363 0 13.333-2.238 13.333-5V20m-30 11.667H15m0 0-5-5m5 5-5 5'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default DropAnywhereModalIcon;
