import { selectors } from '@ai21/studio-store';
import { get } from 'lodash';
import { call, select } from 'saga-ts';
import { clearBoxes, patchBox } from '../helpers/boxes';

export function* loadParamsAndPrompt(data: any, presetId?: string) {
  yield* call(clearBoxes);

  const inputBox = yield* select(selectors.playground.$inputBox);
  const paramsBox = yield* select(selectors.playground.$paramsBox);
  const params = yield* select(selectors.playground.$params);

  if (!data || !inputBox || !params || !paramsBox) {
    return;
  }

  const { currentOutputId } = params;

  const outputBox = yield* select(
    selectors.playground.$outputBoxForIndex,
    currentOutputId - 1
  );

  if (!outputBox) {
    return;
  }

  const prompt = get(data, 'text', '');

  yield* call(patchBox, inputBox, { prompt });
  yield* call(patchBox, outputBox, data.params);

  yield* call(patchBox, paramsBox, { ...data.params, presetId });
}
export function* loadAnswerInputs(data: any) {
  yield* call(clearBoxes);

  const questionBox = yield* select(selectors.playground.$inputBox);
  const contextBox = yield* select(selectors.playground.$contextBox);

  const contextInput = get(data, 'contextInput', '');
  const questionInput = get(data, 'questionInput', '');

  if (!data || !questionBox || !contextBox) {
    return;
  }

  yield* call(patchBox, contextBox, { prompt: contextInput });
  yield* call(patchBox, questionBox, { prompt: questionInput });
}
