import { invokeEvent } from 'shared-base';
import { AuthLogProps, AuthStage } from '../types';
import { authState } from './globals';
import * as analytics from './analytics';

export class Logger {
  static init() {
    return new Logger();
  }

  constructor() {}

  // auth stages
  CheckRequestedRoute() {
    const { pathname } = document.location;
    const { routeType, isNewUser } = authState;

    logStage(AuthStage.CheckRequestedRoute, {
      pathname,
      routeType,
      isNewUser,
    });

    analytics.AuthStarted();
  }

  CheckCurrentUser() {
    const { user, isNewUser } = authState;

    logStage(AuthStage.CheckCurrentUser, {
      isNewUser,
      user,
    });
  }

  Navigation(props: Json) {
    logStage(AuthStage.Navigation, props);
  }

  AuthSuccess() {
    const { isNewUser } = authState;

    logStage(AuthStage.AuthSuccess, {
      isNewUser,
    });

    analytics.AuthCompleted();
  }

  AuthError(err: any) {
    const { isNewUser } = authState;

    logStage(AuthStage.AuthError, {
      isNewUser,
    });

    if (isNewUser) {
      analytics.SignUpFailed(err);
    } else {
      analytics.LoginFailed(err);
    }
  }

  GetExistingUser(props: Json) {
    logStage(AuthStage.GetExistingUser, props);
  }

  CreateNewUser(props: Json) {
    logStage(AuthStage.CreateNewUser, props);
  }

  RestoreRequestedRoute(props: Json) {
    logStage(AuthStage.RestoreRequestedRoute, props);
  }

  AuthChange() {
    const { user } = authState;

    logStage(AuthStage.AuthChange, {
      user,
    });
  }

  ManualAuthSucceeded() {
    const { authType } = authState;
    logStage(AuthStage.ManualAuthSucceeded);

    if (authType === 'LOGIN') {
      analytics.LoginSucceeded();
    } else {
      analytics.SignUpSucceeded();
    }
  }

  AuthProviderSucceeded() {
    analytics.AuthProviderSucceeded();
  }

  AuthProviderFailed(err: any) {
    analytics.AuthProviderFailed(err);
  }

  AuthCompleted() {
    logStage(AuthStage.AuthCompleted);
  }
}

export const logger = Logger.init();

export const logStage = (eventId: string, props?: Json) => {
  const { options } = authState;

  if (!options.logEnabled) {
    return;
  }

  invokeEvent('logs/auth', {
    eventType: eventId,
    props,
    timestamp: Date.now(),
  });
};

export const logAnalytics = (eventId: string, props?: AuthLogProps) => {
  const { options } = authState;

  if (!options.logEnabled) {
    return;
  }

  invokeEvent('global/auth', {
    eventType: eventId,
    props,
    timestamp: Date.now(),
  });
};
