import { delay, put, takeLatest } from 'saga-ts';
import { actions } from '../actions';

export function* fetchRoot() {
  yield delay(100);

  const promises1 = [
    yield* put(actions.presets.get({})),
    yield* put(actions.fineTuningSets.get({})),
    yield* put(actions.models.get({})),
  ];

  yield Promise.all(promises1);

  const promises2 = [
    yield* put(actions.billingInfo.get()),
    yield* put(actions.invoices.get({})),
    yield* put(actions.organization.get()),
    yield* put(actions.paymentsInfo.get()),
    yield* put(actions.specializedUsages.get({})),
    yield* put(actions.trainingUsages.get({})),
    yield* put(actions.completionUsages.get({})),
    yield* put(actions.user.get()),
    yield* put(actions.users.get({})),
  ];
  yield Promise.all(promises2);

  yield delay(100);
}

export function* root() {
  yield takeLatest('FETCH_ROOT', fetchRoot);
}
