import { TextField } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;

  svg {
    position: relative;
    left: 10px;
  }
`;

export const Input = styled(TextField)`
  max-height: 38px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
