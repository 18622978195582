export function CurlIcon({ style }: { style: React.CSSProperties }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      fill='#626B78'
      style={style}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.103 3.333c-.679 0-1.228.55-1.228 1.228 0 .145.037.28.082.408l-5.761 9.873c-.557.117-.985.587-.985 1.18a1.228 1.228 0 1 0 2.456 0c0-.137-.036-.261-.077-.384l5.789-9.914c.54-.13.95-.592.95-1.171 0-.679-.548-1.22-1.226-1.22zm3.776 1.228a1.228 1.228 0 0 1 2.455-.008c0 .579-.41 1.042-.95 1.171l-5.788 9.914c.04.123.077.247.077.384a1.229 1.229 0 0 1-2.456 0c0-.593.426-1.063.983-1.18l5.761-9.873a1.21 1.21 0 0 1-.082-.408zM2.906 9.176c.68 0 1.228-.55 1.228-1.228 0-.136-.037-.261-.077-.385a1.215 1.215 0 0 0-1.151-.845.618.618 0 0 0-.167.03 1.312 1.312 0 0 1-.078.02c-.557.116-.983.587-.983 1.178 0 .679.55 1.228 1.228 1.228v.002zm1.14 2.686c.04.123.078.247.078.384l-.001-.001a1.228 1.228 0 1 1-2.456 0c0-.593.426-1.063.983-1.18.027-.005.054-.012.08-.02.053-.014.107-.029.165-.029.542 0 .989.357 1.151.846z'
      />
    </svg>
  );
}
