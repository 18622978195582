import { takeEvery } from 'saga-ts';
import { logEvent } from '../analytics.amplitude';
import { IncomingAuthEventWrapped } from '../analytics.types';
import { FireGoogleTagManagerEvent } from '../utils/tagManager';
import { customEvenChannel } from './channels/channel.customEvent';

export function* listenToAuth(event: IncomingAuthEventWrapped) {
  const { data } = event;
  const { eventType, props } = data;

  switch (eventType) {
    case 'UserLoginSucceeded':
      FireGoogleTagManagerEvent('SignIn');
      break;
    case 'UserSignUpSucceeded':
      FireGoogleTagManagerEvent('SignUp');
      break;
    default:
  }

  // handle errors
  logEvent(eventType, props, true);
}

export function* root() {
  const channel = customEvenChannel('global/auth');
  yield takeEvery(channel, listenToAuth);
}
