import { Action, ApiInfo } from 'redux-store-generator';
import { IConnectedResult, INodeInfo } from './connected.types';
import { serverMap } from './serverMap';

export const connectedPatch = async (
  info: INodeInfo,
  action: Action
): Promise<IConnectedResult> => {
  const { nodeName } = info;

  const node = serverMap[nodeName];

  if (!node || !node.patch || !action.id) {
    return {
      shouldReachStore: true,
      response: undefined,
    };
  }

  const response = node.patch(action.id, action.payload);

  return {
    shouldReachStore: true,
    shouldReachStoreReason: 'success',
  };
};
