interface PlayIconProps {
  style: React.CSSProperties;
}

export function PlayIcon({ style }: PlayIconProps) {
  return (
    <svg
      style={style}
      width='20'
      height='20'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
    >
      <path d='M7.47131 5.19067V14.8093L15.0288 10L7.47131 5.19067Z' />
    </svg>
  );
}
