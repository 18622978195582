import { ChatIcon } from './ChatIcon';
import { ClassificationIcon } from './ClassificationIcon';
import { CodeIcon } from './CodeIcon';
import { InfoExtractionIcon } from './InfoExtractionIcon';
import { LongFormIcon } from './LongFormIcon';
import { NamedEntityRecognitionIcon } from './NamedEntityRecognitionIcon';
import { QAndAIcon } from './QAndAIcon';
import { RewriteIcon } from './RewriteIcon';
import { ShortFormIcon } from './ShortFormIcon';
import { SummarizeIcon } from './SummarizeIcon';

export const presetCatalogue = {
  Chat: ChatIcon,
  Classification: ClassificationIcon,
  Code: CodeIcon,
  InfoExtraction: InfoExtractionIcon,
  LongForm: LongFormIcon,
  NamedEntityRecognition: NamedEntityRecognitionIcon,
  QAndA: QAndAIcon,
  Rewrite: RewriteIcon,
  ShortForm: ShortFormIcon,
  Summarize: SummarizeIcon,
};
