import evaluationOverview from './json/table.evaluationOverview.json';
import fts from './json/table.fts.json';
import ftsOverview from './json/table.ftsOverview.json';
import ftsSelect from './json/table.ftsSelect.json';
import invoices from './json/table.invoices.json';
import modelEpochs from './json/table.modelEpochs.json';
import modelInfo from './json/table.modelInfo.json';
import models from './json/table.models.json';
import modelsOverview from './json/table.modelsOverview.json';
import presets from './json/table.presets.json';
import presetsOverview from './json/table.presetsOverview.json';
import usage from './json/table.usage.json';
import users from './json/table.users.json';

export const tables: any = {
  evaluationOverview,
  fts,
  ftsOverview,
  ftsSelect,
  invoices,
  models,
  modelsOverview,
  modelEpochs,
  modelInfo,
  presets,
  presetsOverview,
  usage,
  users,
};
