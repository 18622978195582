import { IApp } from '@ai21/studio-store';
import { Button, Icon } from '@ai21/studio-ui';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ShareButton } from './AppHeader.style';

export type AppHeaderProps = {
  app: IApp;
  onBack: () => void;
  onAction: (action: Json) => void;
};

export function AppPageShareButton(props: AppHeaderProps) {
  const dispatch = useDispatch();

  const { app } = props;
  const onClick = useCallback(() => {
    dispatch({
      type: 'SHOWROOM',
      verb: 'share',
      params: { app },
    });
  }, []);

  return (
    <ShareButton
      variant='outlined'
      color='secondary'
      data-testid={app?.title?.replace(/\s/g, '') || ''}
      startIcon={<Icon iconName='Share' />}
      onClick={onClick}
    >
      Share
    </ShareButton>
  );
}

export default AppPageShareButton;
