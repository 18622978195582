import { createContext, useEffect, useRef } from 'react';
import { styled } from '@ai21/studio-base-ui';
import { useScroll } from './Content.hooks';
import classnames from 'classnames';

export const ContentContext = createContext<number>(0);

export const ContentContextProvider = (props: any) => {
  const { isSlim } = props;
  const ref = props.contentRef;
  const scroll = useScroll(ref);

  const className = classnames('site-content', {
    slim: isSlim,
  });

  useEffect(() => {
    if (isSlim) {
      document.body.classList.add('slim');
    } else {
      document.body.classList.remove('slim');
    }
  }, [isSlim]);

  return (
    <ContentContext.Provider value={scroll}>
      <Wrapper ref={ref} className={className}>
        {props.children}
      </Wrapper>
    </ContentContext.Provider>
  );
};

export const Wrapper = styled.div`
  margin-top: 54px;
  margin-left: 240px;
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 54px);
  transition: margin-left 0.2s linear;
  transition-delay: 0s;
  scrollbar-gutter: stable;

  &.slim {
    margin-left: 0;
    transition-delay: 0s;
  }
`;
