import {
  IWidgetConfig,
  Input,
  InputToPayload,
  ResponseToOutput,
  WidgetPayload,
  OutputToAnalyticsPayload,
} from '../MiniPlayground.types';

export const config: IWidgetConfig = {
  title: 'Text Improvements API',
  ctaText: 'Improve My Text',
  tooltipText: 'API supports texts up to 500 characters',
  defaultInput: { main: 'I ate a good and awesome pizza last night.' },
  docsUrl: 'https://docs.ai21.com/reference/text-improvements-api-ref',
  inputType: 'basic',
  outputType: 'replace',
  apiMethodName: 'improvements',
  snippetMethodName: 'improvements',
  rules: [
    {
      ruleType: 'nonEmpty',
      errorMessage: 'Text must not be empty',
    },
    {
      ruleType: 'maxLength',
      errorMessage:
        'Demo is limited to {value} characters. Use the API to improve texts up to 500 characters.',
      value: 200,
      linkText: 'Use the API',
      linkUrl: 'https://docs.ai21.com/reference/text-improvements-api-ref',
    },
  ],
};

export const inTransformer: InputToPayload = (
  input: Input,
  params: Json = {
    improvementTypes: [
      'fluency',
      'vocabulary/specificity',
      'vocabulary/variety',
      'clarity/short-sentences',
      'clarity/conciseness',
    ],
  }
) => {
  return {
    text: input.main,
    types: params.improvementTypes,
  };
};

export const analyticsInputPayload = (
  input: Input,
  params: Json = {
    improvementTypes: [
      'fluency',
      'vocabulary/specificity',
      'vocabulary/variety',
      'clarity/short-sentences',
      'clarity/conciseness',
    ],
  }
) => ({
  types: params.improvementTypes,
  textLength: input.main.length,
});

export const outTransformer: ResponseToOutput = (response: Json, input: Input) => {
  const { data, isSuccess, duration, request } = response;

  return {
    replacements: data.improvements.map((improvement: any) => {
      return {
        originalText: improvement.originalText,
        suggestions: improvement.suggestions,
        startIndex: improvement.startIndex,
        endIndex: improvement.endIndex,
      };
    }),
    originalInput: input,
    errorMessage: data.detail,
    isSuccess,
    count: data.improvements.length,
    unit: 'results',
  };
};

export const analyticsOutputPayload: OutputToAnalyticsPayload = (response: Json) => {
  const { data, duration, request } = response;
  return {
    numSuggestions: data.improvements.length,
    errorMessage: data.detail,
    requestDuration: duration,
    interactionId: data.id,
    endpoint: request.path,
  };
};

export const payload: WidgetPayload = {
  id: 'improvements',
  config,
  inTransformer,
  outTransformer,
  analyticsInputPayload,
  analyticsOutputPayload,
};
