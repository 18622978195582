import { call, takeEvery } from 'saga-ts';
import { patchBox, clearAlert, showAlertSync } from './helpers/boxes';
import { get } from 'lodash';
import { IBox } from '@ai21/studio-store';

type PromptValidation = 'whitespaces';

type ValidateResponse = {
  prompt: string;
  didCancel: boolean;
  wasFixed?: boolean;
};

const mapValidateToSaga: Record<PromptValidation, any> = {
  whitespaces: whitespaces,
};

function* whitespaces(inputBox: IBox, output: ValidateResponse) {
  const endsWithNewLine = output.prompt.endsWith('\n');
  const sameLength = output.prompt.length === output.prompt.trimEnd().length;

  if (endsWithNewLine || sameLength) {
    return output;
  }

  const { didCancel, ctaIndex } = yield* showAlertSync(inputBox, {
    title: 'Ending the prompt with a whitespace may harm output quality.',
    type: 'warning',
    ctas: ['Fix & Generate', 'Ignore & Generate'],
  });

  yield* clearAlert(inputBox);

  if (didCancel) {
    output.didCancel = true;
    return output;
  }

  if (ctaIndex === 0) {
    output.prompt = output.prompt.trimEnd();
    output.wasFixed = true;
  }

  return output;
}

export function* validatePrompt(
  inputBox: IBox,
  validations: PromptValidation[]
) {
  const prompt = get(inputBox, 'values.prompt', '');

  let output: ValidateResponse = {
    prompt,
    didCancel: false,
  };

  for (const verb of validations) {
    const saga = mapValidateToSaga[verb];

    if (!saga) {
      console.warn(`No validation saga found for ${verb}`);
      return output;
    }

    output = yield* saga(inputBox, output);
  }

  if (output.wasFixed) {
    yield* call(patchBox, inputBox, {
      prompt: output.prompt,
    });
  }
  yield* clearAlert(inputBox);

  return output;
}
