import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { ResponseBuilder } from './builders/ResponseBuilder';
import { identityPaths } from './config';
import { tokens } from './globals';
import { ApiResponse, IApiConfig, IApiRequest } from './types';
import { guid4 } from './utils/guid';
import { get } from 'lodash';
import { invokeEvent } from 'shared-base';

export let instanceApi: AxiosInstance;
export let instanceIdentity: AxiosInstance;
export let instanceHub: AxiosInstance;

export const setInstances = (api: AxiosInstance, identity: AxiosInstance) => {
  instanceApi = api;
  instanceIdentity = identity;
};

export const getRequestIdHeader = () => {
  return {
    'X-Request-Id': 'new-' + guid4(),
  };
};

export const initAxios = (config: IApiConfig) => {
  const { identityBaseUrl, studioApiBaseUrl } = config;

  instanceApi = axios.create({
    baseURL: studioApiBaseUrl,
    headers: {
      'Content-Type': 'application/json',
      ...getRequestIdHeader(),
    },
  });

  instanceApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers.set({
      ...config.headers,
      Authorization: `Bearer ${tokens.studioApiKey}`,
    });

    return config;
  });

  instanceIdentity = axios.create({
    baseURL: identityBaseUrl,
    headers: {
      'Content-Type': 'application/json',
      ...getRequestIdHeader(),
    },
  });

  instanceIdentity.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers.set({
      ...config.headers,
      'firebase-token': tokens.firebaseIdToken,
    });

    return config;
  });

  instanceHub = axios.create({
    baseURL: 'http://localhost:3001',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const fireRequest = <T = Json>(req: IApiRequest) => {
  let {
    method = 'get',
    path,
    config = {},
    isIdentity,
    isHub,
    data,
    errorMessageXPath = 'detail',
    endpoint,
    transformer,
    appFeature,
  } = req;

  invokeEvent('global/network', {
    eventType: 'request',
    endpoint,
    appFeature,
    data: data,
  });

  if (identityPaths.find((p) => path.startsWith(p))) {
    isIdentity = true;
  }

  let instance = isIdentity ? instanceIdentity : instanceApi;

  if (isHub) {
    instance = instanceHub;
  }

  if (data) {
    if (method === 'get') {
      config.params = data;
    } else {
      config.data = data;
    }
  }

  return new Promise<ApiResponse<T>>((resolve) => {
    const axiosPromise = instance({
      method,
      url: path,
      ...config,
    });

    const response = new ResponseBuilder(req);

    axiosPromise
      .then((res: AxiosResponse) => {
        response.withIsSuccess(true).withAxiosResponse(res);
        response.withData(res.data).withTransformer(transformer);

        invokeEvent('global/network', {
          eventType: 'success',
          endpoint,
          appFeature,
          response: response.build(),
        });

        resolve(response.build<T>());
      })
      .catch(function (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { data, status } = error.response;

          const errorType = status === 401 ? 'authorization' : 'server';

          if (errorMessageXPath) {
            const errorMessage = get(data, errorMessageXPath);

            response.withErrorMessage(errorMessage);

            invokeEvent('global/network', {
              eventType: 'error',
              endpoint,
              errorMessage,
              status,
              appFeature,
              response: response.build(),
            });
          }

          response //
            .withIsSuccess(false)
            .withErrorType(errorType)
            .withAxiosResponse(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          response.withIsSuccess(false).withErrorType('timeout');
        } else {
          // Something happened in setting up the request that triggered an Error
          response.withIsSuccess(false).withErrorType('javascript').withErrorMessage(error.message);
        }

        resolve(response.build<T>());
      });
  });
};
