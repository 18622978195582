import { Divider, SxProps, Theme } from '@mui/material';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Item, ItemIcon, PopupMenu, Text, Wrapper } from './ButtonMenu.style';
import { IMenuItem } from '../../types';
import { invokeEvent } from 'shared-base';

export type ButtonMenuProps = {
  title?: string;
  iconName?: string;
  testId?: string;
  items: IMenuItem[];
  onClick: (item: IMenuItem) => void;
  onToggle: (isOpen: boolean) => void;
  className?: string;
  anchorOrigin?: 'top' | 'bottom';
  sx?: SxProps<Theme>;
};

export function ButtonMenu(props: ButtonMenuProps) {
  const {
    items,
    title,
    iconName,
    testId = 'DotMenu',
    anchorOrigin = 'bottom',
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const isOpen = Boolean(anchorEl);

    if (props.onToggle) {
      props.onToggle(isOpen);
    }
  }, [anchorEl]);

  function onClick(
    ev: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item: IMenuItem
  ) {
    if (!props.onClick) {
      return;
    }
    props.onClick(item);
    setAnchorEl(null);
    invokeEvent('global/click', {
      ev,
    });
  }

  function renderItem(item: IMenuItem) {
    const { title, iconName, hasDivider, disabled } = item;

    return [
      <Item
        data-testid={`ButtonMenu-item-${item.id}`}
        key={item.id}
        onClick={(ev) => onClick(ev, item)}
        data-href={item.path}
        disabled={disabled}
      >
        {iconName && (
          <ItemIcon>
            <Icon iconName={iconName} />
          </ItemIcon>
        )}
        <Text textAlign='center'>{title}</Text>
      </Item>,
      hasDivider ? <Divider /> : null,
    ];
  }

  function renderItems() {
    return items.map((item: IMenuItem) => renderItem(item));
  }

  function renderMenu() {
    return (
      <PopupMenu
        className='button-menu'
        data-testid='button-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: anchorOrigin,
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: anchorOrigin === 'top' ? 'bottom' : 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={props.sx}
      >
        {renderItems()}
      </PopupMenu>
    );
  }

  const className = classnames('ButtonMenu-wrapper', props.className, {
    iconButton: !title,
  });

  return (
    <Wrapper className={className} data-testid='ButtonMenu-wrapper'>
      <Button
        variant='outlined'
        color='secondary'
        size='medium'
        data-testid={testId}
        startIcon={<Icon iconName={iconName} />}
        onClick={(ev) => setAnchorEl(ev.currentTarget)}
      >
        {title}
      </Button>
      {renderMenu()}
    </Wrapper>
  );
}

export default ButtonMenu;
