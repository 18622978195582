import { takeEvery } from 'saga-ts';
import { customEvenChannel } from './channels/channel.customEvent';
import { logEvent } from '../analytics.amplitude';

export function* listenToForms(event: any) {
  const { data } = event;
  const { action, actionValue, entity } = data;

  const log = {
    entity,
    action,
    actionValue,
  };

  logEvent('Form', log, true);
}

export function* root() {
  const channel = customEvenChannel('global/form');
  yield takeEvery(channel, listenToForms);
}
