import { Button } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
`;

export const Actions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 24px 16px;
  height: 42px;
  border-top: 1px solid #e5e5e5;

  &:first-child {
    border-bottom: 1px solid #e5e5e5;
  }

  &:last-child {
    justify-content: flex-end;
  }
`;

export const Cta = styled(Button)`
  width: 130px;
`;
