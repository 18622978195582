import { complete } from './api.completion';
import { addToCollection, getCollection, removeCollection, updateCollectionItem } from './firebase';
import { Meta, addVariables, shots } from './utils/ape';

export const getPas = (_extraParams?: Json) => {
  return getCollection('/apePas', 'organization');
};

export const createPa = (pa: Json) => {
  return addToCollection('/apePas', pa, 'organization');
};

export const updatePa = (paId: string, change: Json) => {
  return updateCollectionItem(`/apePas/${paId}`, change, 'organization');
};

export const removePa = (paId: string) => {
  return removeCollection(`/apePas/${paId}`, 'organization');
};

export const improve = (prompt: string, pa: Json, vars: Json = {}) => {
  const { systemMessage, userPrompt } = pa;

  const shotsCombined =
    shots
      .reduce(
        (acc, shot) => {
          const { input, output } = shot;

          acc.push(`input: ${input}`);
          acc.push(`output: ${output}`);
          acc.push('\n===');

          return acc;
        },
        ['==='] as string[]
      )
      .join('\n') + '===';

  const meta: Meta = {
    variables: {
      ...vars,
      shots: shotsCombined,
      prompt,
    },
  };

  const system = addVariables(systemMessage, meta);
  const user = addVariables(userPrompt, meta);

  const payload = [system, user].join('\n');

  return complete({
    prompt: payload,
    model: 'j2-mid',
    numResults: 1,
    maxTokens: 50,
    temperature: 0.7,
    topP: 1,
    countPenalty: 0,
    frequencyPenalty: 0,
    presencePenalty: 0,
    numStopSequences: 0,
    promptLength: payload.length,
    stopSequences: ['==='],
  });
};

export const ape = {
  improve,
  getPas,
  createPa,
  updatePa,
  removePa,
};
