import { Typography, tokens } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10px;
`;

export const IconWrapper = styled.div`
  color: ${tokens.text.primary};
  margin-right: 16px;
  svg {
    height: 32px;
    width: 32px;
    stroke-width: 1.5px;
  }
`;

export const Details = styled.div``;

export const Title = styled(Typography)``;

export const Description = styled(Typography)``;

export const Wrapper = styled.div`
  border-radius: ${tokens.primitives.radius.large}px;
  border: 1px solid ${tokens.primary.borderDefault};
  background: ${tokens.primary.backgroundDefault};
  flex: 1 1 40%;
  display: flex;
  padding: 16px;
  margin: 12px;
  align-items: center;
  /* height: 76px; */

  &:hover {
    box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.06);
  }

  &:active {
    background: ${tokens.primary.backgroundHover};
    border-color: ${tokens.primary.borderHover};
    box-shadow: none;
  }

  &.main {
    background: linear-gradient(90deg, #f8f5ff 7.82%, #f5fbff 46.38%, #f8f5ff 123.48%),
      ${tokens.primary.backgroundDefault};
    border-color: ${tokens.primary.mainDefault};

    &:hover {
      box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.06);
    }
  }
`;

export const ActionIcon = styled.div`
  color: ${tokens.text.placeholder};
  svg {
    height: 18px;
    width: 18px;
  }
`;
