import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  align-items: center;
`;

export const BasedOn = styled.div`
  margin-top: 24px;
`;

export const P = styled.p`
  line-height: 160%;
  font-weight: 400;
  font-size: 12px;
  color: ${tokens.text.secondary};
  padding: 0;
  margin: 0;
`;

export const Sources = styled.div``;

export const IconWrapper = styled.div`
  svg {
    color: ${tokens.text.secondary};
    width: 15px;
    margin-top: 6px;
    margin-right: 4px;
  }
`;

export const Link = styled.div`
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  color: ${tokens.text.secondary};
  margin-right: 4px;
  margin-left: 4px;
  white-space: nowrap;
`;

export const Source = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: ${tokens.text.secondary};

  &.suggest-to-upload {
    @media (max-width: 1000px) {
      display: none;
    }
  }
`;
