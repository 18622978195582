import { ax } from '@ai21/studio-analytics';
import { api, generateCode } from '@ai21/studio-api';
import {
  actions,
  IBox,
  ICollectionLine,
  mapLegacyModels,
  selectors,
} from '@ai21/studio-store';
import { prompt as dialog, toast } from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, delay, fork, put, select, take, takeEvery } from 'saga-ts';
import {
  downloadJson as downloadFile,
  downloadText,
  invokeEvent,
} from 'shared-base';
import { Json } from '../types';
import { getGenerationParams } from '../utils/boxes';
import {
  clearAlert,
  clearBoxes,
  patchBox,
  warnCollectionImport,
} from './helpers/boxes';
import { getResponseData, prepareRequestParams } from './helpers/completion';
import { ape, apeUndo } from './saga.ape';
import { optimizePrompt, optimizePromptUndo } from './saga.apeSimple';
import { chatClear, mapVerbToSaga as mapVerbToSagaChat, Verb as VerbChat } from './saga.chat'; // prettier-ignore
import {
  addToCollection,
  deleteAllLines,
  deleteLine,
  fetchCollectionLines,
} from './saga.collection';
import { codeLego, generateAllLego, generateSingleLego } from './saga.lego';
import { presetLoad, presetNew, presetSave, promptShare } from './saga.presets';
import { validatePrompt } from './saga.validation';

import {
  duplicateJob,
  newJob,
} from '../../../collections/src/sagas/saga.collectionJobs';
import { jsonToCsv } from '../../../collections/src/utils/file';
import { CollectionLinesModal } from '../../../collections/src/_part/CollectionLinesModal/CollectionLinesModal';
import { analytics } from './helpers/analytics';
import { customEvenChannel } from './channels/channel.customEvent';

type Verb =
  | 'ape'
  | 'apeUndo'
  | 'change'
  | 'copy'
  | 'code'
  | 'codeLego'
  | 'collect'
  | 'generateSingle'
  | 'generateAll'
  | 'generateSingleLego'
  | 'generateAllLego'
  | 'jsonChange'
  | 'clear'
  | 'clearAll'
  | 'regenerate'
  | 'regenerateLego'
  | 'feedbackScore'
  | 'feedbackTags'
  | 'documentation'
  | 'optimizePrompt'
  | 'optimizePromptUndo'
  | 'optimizeStart'
  | 'alertCta'
  | 'presetNew'
  | 'presetSave'
  | 'presetLoad'
  | 'promptShare'
  | 'optimizeStart'
  | 'changeOutput'
  | 'dismissAlert'
  | 'clearPromptWhitespaces'
  | 'getCollectionLines'
  | 'newCollection'
  | 'duplicateCollection'
  | 'evaluateCollection'
  | 'downloadCollectionJson'
  | 'downloadCollectionCsv'
  | 'deleteCollection'
  | 'deleteCollectionLine'
  | 'deleteAllCollectionLines'
  | 'select'
  | 'openCollectionModal'
  | 'navigateCollectionPage'
  | VerbChat;

export type ActionPlay = {
  type: 'PLAY2';
  verb: Verb;
  id: string;
  params?: Json;
};

const MAX_LINES_FOR_EVALUATION = 1000;

const mapVerbToSaga: Record<Verb, any> = {
  ape: ape,
  apeUndo: apeUndo,
  change: change,
  copy: copy,
  code: code,
  codeLego: codeLego,
  collect: addCompletionToCollection,
  generateSingle: generateSingle,
  generateAll: generateAll,
  generateSingleLego: generateSingleLego,
  generateAllLego: generateAllLego,
  clear: clear,
  clearAll: clearAll,
  regenerate: regenerate,
  regenerateLego: regenerateLego,
  optimizeStart: optimizeStart,
  optimizePrompt: optimizePrompt,
  feedbackScore: feedbackScore,
  feedbackTags: feedbackTags,
  documentation: documentation,
  optimizePromptUndo: optimizePromptUndo,
  alertCta: alertCta,
  clearPromptWhitespaces: clearPromptWhitespaces,
  presetNew: presetNew,
  presetSave: presetSave,
  presetLoad: presetLoad,
  promptShare: promptShare,
  changeOutput: changeOutput,
  dismissAlert: dismissAlert,
  getCollectionLines: getCollectionLines,
  newCollection: newCollectionJob,
  duplicateCollection: duplicateCollectionJob,
  evaluateCollection: evaluateCollectionJob,
  downloadCollectionJson: downloadCollectionJson,
  downloadCollectionCsv: downloadCollectionCsv,
  deleteCollection: deleteCollectionJob,
  deleteCollectionLine: deleteCollectionLine,
  deleteAllCollectionLines: deleteAllCollectionLines,
  select: selectCollection,
  openCollectionModal: openCollectionModal,
  navigateCollectionPage: navigateCollectionPage,
  ...mapVerbToSagaChat,
};

export function* change(action: ActionPlay, box: IBox) {
  const { params } = action;
  const { change } = params ?? {};

  yield* call(patchBox, box, change);

}

export function* copy(action: ActionPlay, box: IBox) {
  const value =
    get(action, 'params.value') || // copy button
    get(box, 'values.prompt') || // input
    get(box, 'values.content'); // output

  if (!value) {
    toast.show('Nothing to copy', 'warning');
    return;
  }

  navigator.clipboard.writeText(value as string);
  toast.show('Copied to clipboard');
}

export function* code(_action: ActionPlay, box: IBox) {
  const currentIds = yield* select(selectors.raw.$rawCurrentIds);
  const { layoutId = '' } = currentIds;

  const prompt = yield* select(selectors.playground.$prompt);
  const params = yield* select(selectors.playground.$params);

  const model = yield* select(selectors.singles.$model, params.modelId);

  let flavour: any = layoutId.replace(/[0-9]+$/g, '').replace(/Exp/g, '');

  const code = generateCode({
    flavour,
    prompt: prompt.main.replace(/\n/g, '\\n'),
    controllerParams: getGenerationParams(params),
    customModelType: mapLegacyModels(model?.customModelType || undefined),
    modelName: model?.name || params.modelId,
    context: 'playground',
  });

  dialog.code({
    title: 'API call',
    flavour,
    code,
  });
}

export function* generateSingle(action: ActionPlay, outputBox: IBox) {
  const { layoutId, values } = outputBox;

  const showAlternative = layoutId.includes('Alt');

  const inputBoxes = yield* select(selectors.playground.$inputBoxes);
  const generationParams = getGenerationParams(values);
  const inputBox = inputBoxes[0];

  if (!inputBox) {
    return;
  }

  const { prompt, didCancel } = yield* validatePrompt(inputBox, [
    'whitespaces',
  ]);

  if (didCancel) {
    return;
  }

  const models = yield* select(selectors.raw.$rawModels);
  const parsedParams = prepareRequestParams(
    prompt,
    generationParams,
    models,
    showAlternative
  );

  yield* call(patchBox, outputBox, {
    isLoading: true,
    alertData: null,
    showFeedback: false,
    content: '',
  });

  const tsStart = Date.now();
  const response = yield* call(api.completion.complete, parsedParams);
  const tsEnd = Date.now();
  const apiDurationMillis = tsEnd - tsStart;

  if (!response.isSuccess) {
    console.error(response.errorType, response.errorMessage);

    const errorDetail = get(
      response,
      'data.detail',
      'Something went wrong check your internet connection and try again'
    );
    yield* call(patchBox, outputBox, {
      alertData: {
        title: errorDetail,
        type: 'error',
      },
      isLoading: false,
    });
    yield* call(patchBox, inputBox, {
      isLoading: false,
    });
    return;
  }

  const responseData = getResponseData(
    values.modelId,
    response,
    showAlternative
  );

  const {
    completion,
    completionId,
    promptTokens,
    tokensCount,
    finishReason,
    reason,
  } = responseData;

  if (reason) {
    toast.show(
      `Text generation has ended early due to a maximum completion length of ${finishReason.length} tokens.`,
      'info'
    );
  }

  yield* call(patchBox, outputBox, {
    content: completion,
    promptTokens,
    completionId,
    tokensCount,
    showFeedback: true,
    isLoading: false,
    apiDurationMillis,
    alertData: null,
  });
}

export function* generateAll(action: ActionPlay, box: IBox) {
  const outputBoxes = yield* select(selectors.playground.$outputBoxes);
  for (let box of outputBoxes) {
    if (box.id.includes('-params')) continue;
    yield* fork(generateSingle, { ...action, id: box.id }, box);
  }
}

export function* dismissAlert(action: ActionPlay, box: IBox) {
  yield* call(clearAlert, box);
}

export function* clear(_action: ActionPlay, box: IBox) {
  const { id } = box;

  if (id.includes('input')) {
    if (box.values.isDouble) {
      yield* call(patchBox, box, { secondaryPrompt: '' });
    } else {
      yield* call(patchBox, box, { prompt: '' });
    }
  } else {
    yield* call(patchBox, box, { content: '' });
  }
}

export function* clearAll(_action: ActionPlay, box: IBox) {
  const layout = yield* select(selectors.playground.$layout);

  if (layout.includes('chat')) {
    yield* call(chatClear, _action, box);
    return;
  }

  yield* call(clearBoxes);
}

export function* regenerate(action: ActionPlay, box: IBox) {
  yield* fork(generateAll, action, box);
}

export function* regenerateLego(action: ActionPlay, box: IBox) {
  yield* fork(generateAllLego, action, box);
}

export function* alertCta(action: ActionPlay, box: IBox) {
  yield* call(patchBox, box, { alertData: null });
}

export function* clearPromptWhitespaces(action: ActionPlay, box: IBox) {
  const prompt = get(box, 'values.prompt', '');
  const newPrompt = prompt.trimEnd();
  yield* call(patchBox, box, { alertData: null, prompt: newPrompt });
  yield* fork(
    generateSingle,
    { ...action, id: box.id },
    action?.params?.outputBox
  );
}

export function* documentation(action: ActionPlay, box: IBox) {
  const { params } = action;
  const { url } = params ?? {};

  yield put({
    type: 'NAVIGATE',
    to: url,
  });
}

export function* feedbackScore(action: ActionPlay, box: IBox) {
  const { params } = action;
  const completionId = get(box, 'values.completionId', '');
  const model = get(box, 'values.modelId', '');

  const { score } = params ?? {};

  const response: any = yield* call(api.feedback.send, {
    requestId: completionId,
    score,
    model,
  });

  if (!response.isSuccess) {
    console.error('feedback', 'something went wrong, cannot send feedback');
  }

  yield* call(patchBox, box, { feedbackScore: score });
}

export function* feedbackTags(action: ActionPlay, box: IBox) {
  const { params } = action;
  const completionId = get(box, 'values.completionId', '');
  const feedbackScore = get(box, 'values.feedbackScore', '');
  const model = get(box, 'values.modelId', '');

  const { tags } = params ?? {};

  const response: any = yield* call(api.feedback.send, {
    requestId: completionId,
    score: feedbackScore,
    reasons: tags,
    model,
  });

  if (!response.isSuccess) {
    console.error('feedback', 'something went wrong, cannot send feedback');
  }

  yield* call(patchBox, box, { showFeedback: false });
}

export function* optimizeStart(action: ActionPlay, box: IBox) {
  const inputBoxes = yield* select(selectors.playground.$inputBoxes);

  if (!inputBoxes || !inputBoxes.length) {
    return;
  }

  const inputBox = inputBoxes[0];

  const input = get(inputBox, 'values.prompt', '');

  if (!input) {
  }

  yield* call(patchBox, inputBox, { isLoading: true });

  /*
  Tooltip
  Rename => settings

  */

  const response = yield* call(api.completion.complete, {
    prompt: `can you try and improve my prompt?\nprompt: "${input}"`,
    model: 'turbo',
    system:
      'You are a helpful prompt engineer trying to improve a given prompt. return only the prompt.',
  });

  const suggestion = get(response, 'data.completions[0].data.text', '');

  yield* call(patchBox, inputBox, { suggestion, isLoading: false });
}

export function* changeOutput(action: ActionPlay, box: IBox) {
  const { params } = action;
  const { outputId, openDrawer } = params ?? {};

  yield delay(10);

  const outputBox = yield* select(selectors.singles.$outputBox, outputId);
  const paramBox = yield* select(selectors.playground.$paramsBox);

  if (!outputBox || !paramBox) {
    return;
  }

  const { values } = outputBox;

  const generationParams = getGenerationParams(values);

  yield* call(patchBox, paramBox, {
    ...generationParams,
    currentOutputId: outputId,
  });

  if (openDrawer) {
    invokeEvent('OpenAccordion', { id: paramBox.id });
  }
}

// data collections

export function* getCollectionLines(action: ActionPlay, box: IBox) {
  const collectionLines = yield* call(
    fetchCollectionLines,
    action.params?.collectionId as string
  );
  yield* call(patchBox, box, { collectionLines });
}

export function* newCollectionJob(action: ActionPlay, box: IBox) {
  const collectionId = yield* call(newJob, action, box);

  if (collectionId) {
    yield* call(patchBox, box, { collectionId });

    const collectionLines = yield* call(
      fetchCollectionLines,
      collectionId as string
    );
    yield* call(patchBox, box, { collectionLines, collectionId });
  }
}

export function* duplicateCollectionJob(action: ActionPlay, box: IBox) {
  const collectionId = yield* call(duplicateJob, action, box);
  if (collectionId) {
    yield* call(patchBox, box, { collectionId });

    const collectionLines = yield* call(
      fetchCollectionLines,
      collectionId as string
    );
    yield* call(patchBox, box, { collectionLines, collectionId });
  }
}

export function* downloadCollectionCsv(action: ActionPlay, box: IBox) {
  const { collectionId } = box.values;

  yield* put(actions.collectionLines.get({ setId: collectionId }));
  yield take('SET_COLLECTIONLINES');

  const lines = yield* select(selectors.raw.$rawCollectionLines);
  const filtersLines = Object.values(lines).filter(
    (line) => line.setId === collectionId && !line.isDeleted
  );

  const csv = jsonToCsv(filtersLines);

  if (csv === null) {
    toast.show('Failed to convert to CSV', 'error');
    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: 'Failed to convert to CSV',
      eventId: 'collectionSetDownloadFailed',
    });
    return;
  }

  try {
    const fileName = `job-${collectionId}.csv`;
    downloadText(fileName, csv);

    ax.nudge('collectionSets.downloads');

    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: fileName,
      eventId: 'CollectionSetDownloaded',
    });
  } catch (err) {
    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: `Failed to download CSV, error: ${err}`,
      eventId: 'CollectionSetDownloadFailed',
    });
  }
}

export function* downloadCollectionJson(action: ActionPlay, box: IBox) {
  const { collectionId } = box.values;

  yield* put(actions.collectionLines.get({ setId: collectionId }));
  yield take('SET_COLLECTIONLINES');

  try {
    const lines = yield* select(selectors.raw.$rawCollectionLines);
    const filtersLines = Object.values(lines).filter(
      (line: any) => line.setId === collectionId && !line.isDeleted
    );

    const fileName = `job-${collectionId}.json`;

    downloadFile(`job-${collectionId}.json`, filtersLines);

    ax.nudge('collectionSets.downloads');

    yield* call(analytics, {
      action: 'downloadJson',
      actionValue: fileName,
      eventId: 'CollectionSetDownloaded',
    });
  } catch (err) {
    yield* call(analytics, {
      action: 'downloadJson',
      actionValue: `Failed to download to json, error: ${err}`,
      eventId: 'CollectionSetDownloadFailed',
    });
  }
}

export function* deleteCollectionJob(action: ActionPlay, box: IBox) {
  const { collectionId } = box.values;

  const job = yield* select(selectors.singles.$collectionJob, collectionId);

  const { name } = job;

  const { didCancel } = yield dialog.confirm({
    title: 'Delete collection',
    description: `Are you sure you want to delete collection set "${name}"?`,
    ctaButtonText: 'Delete',
    intention: 'delete',
  });

  if (didCancel) {
    return;
  }

  yield put(
    actions.collectionJobs.patch(collectionId, {
      isDeleted: true,
    })
  );

  yield* call(patchBox, box, { collectionLines: [], collectionId: undefined });

  toast.show(`Collection ${name} has been deleted successfully`, 'success');
}

export function* evaluateCollectionJob(action: ActionPlay, box: IBox) {
  const { collectionId } = box.values;

  if (!collectionId) {
    return;
  }

  const lines = yield* select(selectors.singles.$collectionLines, collectionId);
  const linesCount = lines.length;

  if (linesCount > MAX_LINES_FOR_EVALUATION) {
    toast.show(
      `Evaluating sets with more than ${MAX_LINES_FOR_EVALUATION} lines is not supported`,
      'error'
    );
    return;
  }

  const response: any = yield* call(
    api.collection.transformToEvaluationSet,
    collectionId
  );

  if (!response?.isSuccess) {
    toast.show('Failed to create evaluation job for collection set', 'error');
    return;
  }

  yield put(actions.evaluationJobs.get({}));
  yield delay(500);

  yield put({
    type: 'NAVIGATE',
    to: `/tools/evaluation/${response.data.id}/inbox?openSettings=true`,
  });
}

export function* openCollectionModal(action: ActionPlay, box: IBox) {
  const collectionLines = yield* call(
    fetchCollectionLines,
    box.values.collectionId as string
  );

  const tableSets = Object.values(collectionLines)
    .filter((line) => line.setId === box.values.collectionId && !line.isDeleted)
    .map((line) => {
      return {
        id: line.id,
        index: line.index,
        guest: line.lastOpenedBy,
        input: line.input,
        inputShort: line.input.substring(0, 240),
        output: line.output,
        outputShort: line.output.substring(0, 240),
        modelName: line.modelName || (line as any).modelId,
        temperature: line.temperature,
        minTokens: line.minTokens,
        maxTokens: line.maxTokens,
        topP: line.topP,
        timeStamp: line.dateCreated,
        source: line.setSource,
        status: line.status,
        comment: line.comment,
        outputSuggestion: line.outputEdited,
        isLocked: line.isLocked,
        entityIcon: 'GenerationSet',
      };
    });

  const { value, didCancel } = yield dialog.custom({
    title: 'Data collections',
    component: CollectionLinesModal,
    componentProps: {
      tableSets: tableSets,
      collectionId: box.values.collectionId,
    },
  });

  if (didCancel || !value || value.length === 0) {
    return;
  }
}

export function* selectCollection(action: ActionPlay, box: IBox) {
  const lineData = yield* select(
    selectors.singles.$collectionLine,
    action.params?.rowId
  );

  (document.activeElement as HTMLElement).blur();

  const shouldSelectCollection = yield* call(warnCollectionImport);

  // user wants to cancel import
  if (!shouldSelectCollection) {
    return;
  }

  const inputBoxes = yield* select(selectors.playground.$inputBoxes);
  const inputBox = inputBoxes[0];
  const outputBoxes = yield* select(selectors.playground.$outputBoxes);
  const hasSelectedOutput =
    outputBoxes.filter((box) => box.values.isSelected === true).length > 0;
  const outputBox = hasSelectedOutput
    ? outputBoxes.filter((box) => box.values.isSelected === true)[0]
    : outputBoxes[0];
  const paramsBox = yield* select(selectors.playground.$paramsBox);

  yield* call(patchBox, inputBox, {
    prompt: lineData.input,
  });

  yield* call(patchBox, outputBox, {
    content: lineData.output,
    maxTokens: lineData.maxTokens,
    modelId: lineData.modelName?.toLowerCase(),
    temperature: lineData.temperature,
  });

  yield* call(patchBox, paramsBox, {
    maxTokens: lineData.maxTokens,
    modelId: lineData.modelName?.toLowerCase(),
    temperature: lineData.temperature,
    minTokens: lineData.minTokens,
    frequencyPenalty: lineData.frequencyPenalty,
  });

  if (outputBoxes.length > 1) {
    toast.show(
      `Imported to output ${outputBox.id.split('-')[2].replace('output', '')}`,
      'success'
    );
  }
}

export function* deleteCollectionLine(action: ActionPlay, box: IBox) {
  const shouldDeleteLine = yield* call(
    deleteLine,
    box.values.collectionId,
    action.params?.rowId as string
  );
  if (shouldDeleteLine) {
    const newCollectionLines = box.values.collectionLines.filter(
      (line: ICollectionLine) => line.id !== action.params?.rowId
    );

    yield* call(patchBox, box, { collectionLines: newCollectionLines });

    toast.show(`Item successfully removed.`, 'success');
  }
}

export function* deleteAllCollectionLines(action: ActionPlay, box: IBox) {
  const shouldDeleteLines = yield* call(
    deleteAllLines,
    box.values.collectionId
  );

  if (shouldDeleteLines) {
    yield* call(patchBox, box, { collectionLines: [] });

    toast.show(`All Items successfully removed.`, 'success');
  }
}

export function* addCompletionToCollection(action: ActionPlay, box: IBox) {
  yield* call(patchBox, box, {
    isAddingCollection: true,
  });

  delay(200);

  yield* call(addToCollection, action, box);
  const collection = yield* select(selectors.playground.$collection);
  const { collectionId } = collection ?? {};
  const collectionBox = yield* select(
    selectors.singles.$box,
    collection?.boxId
  );

  if (collectionId !== undefined) {
    yield put(actions.collectionLines.get({ setId: collectionId }));
    yield take('SET_COLLECTIONLINES');

    const collectionLinesRaw = yield* select(selectors.raw.$rawCollectionLines),
      collectionLines = Object.values(collectionLinesRaw).filter(l => l.setId === collectionId && !l.isDeleted); // prettier-ignore

    yield* call(patchBox, collectionBox, {
      collectionLines,
    });
  }

  delay(500);

  yield* call(patchBox, box, {
    isAddingCollection: false,
  });
}

export function* loadCollection(event: any) {
  const { data } = event;
  const { collectionId } = data;

  yield delay(200);

  const collection = yield* select(selectors.playground.$collection);
  const collectionBox = yield* select(
    selectors.singles.$box,
    collection?.boxId
  );

  invokeEvent('closeAccordion');
  invokeEvent('OpenAccordion', { id: collection.boxId });

  yield* call(patchBox, collectionBox, { collectionId });

  const collectionLines = yield* call(
    fetchCollectionLines,
    collectionId as string
  );
  yield* call(patchBox, collectionBox, { collectionLines, collectionId });
}

export function* navigateCollectionPage(action: ActionPlay, box: IBox) {
  yield put({
    type: 'NAVIGATE',
    to: `/datasets/data-collections/${box.values.collectionId}/lines`,
  });
}

// end data collections

export function* play2(action: ActionPlay) {
  const { verb, id } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  const box = yield* select(selectors.singles.$box, id);
  const isGeneralVerb = ['clearAll', 'documentation', 'code'].includes(verb);

  if (!saga) {
    return;
  }

  if (!box && !isGeneralVerb) {
    return;
  }

  yield* saga(action, box);
}

export function* root() {
  yield takeEvery('PLAY2', play2);

  const channel3 = customEvenChannel('collection/load');
  yield takeEvery(channel3, loadCollection);
}
