import styled from 'styled-components';
import { Skeleton } from '@mui/material';

export const Wrapper = styled.div<{ padding: number; height?: number }>`
  height: ${(props) => props.height || 215}px !important;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  padding: ${(props) => props.padding || 0}px;
`;

export const Border = styled.div`
  padding: 14px 16px;
  border-bottom: 1px solid #e6e8ee;
`;

export const Line = styled(Skeleton)``;
