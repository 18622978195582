import { fireRequest } from './axios';
import { PayloadCreateCustomModel, PayloadCustomModelCalculateCost } from './types';

export const getAll = () => {
  return fireRequest<Json>({
    path: '/custom-model?includeMetadata=true',
    endpoint: 'models.getAll',
  });
};

export const create = (payload: PayloadCreateCustomModel) => {
  const { modelName, learningRate, epochs, datasetId, modelType } = payload;
  return fireRequest<Json>({
    path: '/custom-model',
    method: 'post',
    data: {
      model_name: modelName,
      learning_rate: learningRate,
      num_epochs: epochs,
      dataset_id: datasetId,
      model_type: modelType,
    },
    endpoint: 'models.create',
  });
};

export const calculateCost = (payload: PayloadCustomModelCalculateCost) => {
  const { modelType, epochs, sizeBytes } = payload;

  return fireRequest<Json>({
    path: '/custom-model/train-model-cost',
    method: 'get',
    data: {
      modelType,
      numEpochs: epochs,
      sizeBytes,
    },
    endpoint: 'models.calculateCost',
  });
};
export const remove = (modelId: string) => {
  return fireRequest<Json>({
    method: 'delete',
    path: `/custom-model/${modelId}`,
    endpoint: 'models.remove',
  });
};

export const getEpochs = (modelId: string) => {
  return fireRequest<Json>({
    method: 'get',
    path: `/custom-model/${modelId}`,
    endpoint: 'models.getEpochs',
  });
};

export const changeDefaultEpoch = (modelId: string, defaultEpoch: number) => {
  return fireRequest<Json>({
    method: 'put',
    path: `/custom-model/${modelId}`,
    data: {
      defaultEpoch,
    },
    endpoint: 'models.changeDefaultEpoch',
  });
};

export const models = {
  getAll,
  create,
  calculateCost,
  remove,
  getEpochs,
  changeDefaultEpoch,
};
