export function DismissIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        fill='#909299'
        d='M12.722 3.778L11.944 3H8.056l-.778.778H4.556v1.555h10.888V3.778h-2.722zM5.333 15.444c0 .856.7 1.556 1.556 1.556h6.222c.856 0 1.556-.7 1.556-1.556V6.111H5.333v9.333zM6.89 7.667L8 7.666h5.111v7.778H6.89V7.667zM8 7.666v7.773h1.25V7.667L8 7.666zm2.75 0v7.773H12V7.667l-1.25-.001z'
      />
    </svg>
  );
}
