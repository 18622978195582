interface StudioIconProps {
  style: React.CSSProperties;
}

export function StudioIcon({ style }: StudioIconProps) {
  return (
    <svg
      style={style}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M10.7746 3.20934L15.5154 5.94003L15.5167 5.94076C15.9938 6.2101 16.2892 6.71958 16.2892 7.28017V12.7199C16.2892 13.2805 15.9938 13.79 15.5167 14.0593L15.5154 14.06L10.7756 16.7901L10.7746 16.7907C10.5336 16.9307 10.2711 17 9.99997 17C9.72878 17 9.4663 16.9307 9.22532 16.7907L4.48454 14.06L4.48324 14.0593C4.00614 13.79 3.71075 13.2805 3.71075 12.7199V7.28017C3.71075 6.72064 4.00618 6.20866 4.48687 5.92921L9.22383 3.2102L9.22532 3.20934C9.4663 3.06941 9.72878 3.00006 9.99997 3.00006C10.2711 3.00006 10.5336 3.06941 10.7746 3.20934ZM4.9169 12.9193L9.39689 15.4953V10.3421L4.9169 7.73255V12.9193ZM5.55984 6.70961L9.99997 9.29968L14.4401 6.70961L9.99997 4.15317L5.55984 6.70961Z'
      />
    </svg>
  );
}
