import { Markdown } from '@ai21/studio-base-ui';
import { IApp, IModel } from '@ai21/studio-store';
import { Icon, Link, Button } from '@ai21/studio-ui';
import { actions } from './AppActions.data';
import {
  Action,
  ActionsSection,
  Disclaimer,
  DisclaimerHeader,
  Header,
  Wrapper,
} from './ModelsUsage.style';

export type ModelsUsageProps = {
  app: IApp;
  onAction: (action: Json) => void;
};

export function ModelsUsage(props: ModelsUsageProps) {
  const { app } = props || {};
  const { presetUrl, modelsUsage } = app;
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  function renderModelUsage(modelUsage: Json) {
    return (
      <Disclaimer variant='body1'>
        <DisclaimerHeader variant='body1bold'>
          {modelUsage.modelName}
        </DisclaimerHeader>
        <Markdown markdown={modelUsage.description} />
        {!isMobile && presetUrl && (
          <Button
            variant='outlined'
            color='secondary'
            size='small'
            endIcon={<Icon iconName='ArrowRight' />}
            onClick={() =>
              props.onAction({
                id: 'playground',
                app: { presetUrl: modelUsage.presetUrl },
                isDisabled: isMobile,
              })
            }
          >
            Try in playground
          </Button>
        )}
      </Disclaimer>
    );
  }

  function renderModelsUsage() {
    return modelsUsage?.map((modelUsage: Json) => renderModelUsage(modelUsage));
  }

  return (
    <Wrapper className='ModelsUsage-wrapper' data-testid='ModelsUsage-wrapper'>
      {modelsUsage && modelsUsage.length > 0 && (
        <ActionsSection>
          <Header variant='body1bold'>Built with</Header>
          {renderModelsUsage()}
        </ActionsSection>
      )}
    </Wrapper>
  );
}

export default ModelsUsage;
