import { put, select } from 'saga-ts';
import { guid4 } from 'shared-base';
import { actions } from './actions';
import {
  ICollectionJobConfig,
  ICollectionLine,
  IEvaluationJobConfig,
  IEvaluationLine,
  IGenerationJobConfig,
} from './types.sets';
import { removeUndefinedValues } from './utils/object';
import { selectors } from './selectors/selectors.index';

export function* createEvaluationJob(dataset: Json[], data: IEvaluationJobConfig) {
  try {
    const setId = data.id || guid4();

    const {
      name = 'Eval #' + setId,
      description = '',
      creator,
      setSource,
      setSourceId = '',
      badLabels = [],
      evaluationGoal = '',
      evaluationMethod = 'qualitative',
      greatLabels = [],
      okLabels = [],
    } = data;

    yield put(
      actions.evaluationJobs.add({
        id: setId,
        name,
        status: 'New',
        description,
        dateCreated: Date.now(),
        creator,
        setType: 'evaluation',
        setSource,
        setSourceId,
        fileSizeInBytes: JSON.stringify(dataset).length,
        linesCount: dataset.length,
        evaluationGoal,
        evaluationMethod,
        badLabels,
        okLabels,
        greatLabels,
        linesCompleted: 0,
        tags: [],
        isDeleted: false,
        badCount: 0,
        badRate: 0,
        okCount: 0,
        okRate: 0,
        greatCount: 0,
        greatRate: 0,
      })
    );

    let index = 1;

    const linesToAdd = dataset.filter((i) => i.prompt && i.completion);

    for (let i of linesToAdd) {
      const id = guid4();

      const {
        prompt,
        completion,
        comment = '',
        generationDate = Date.now(),
        modelName,
        foundationModelName = '',
        temperature,
        topP,
        maxTokens,
        minTokens,
      } = i;

      yield put(
        actions.evaluationLines.add(
          removeUndefinedValues({
            id,
            setId,
            index: index++,
            dateCreated: generationDate,
            creator: creator ?? data.creator,
            input: prompt,
            output: completion,
            status: 'Pending',
            comment,
            inputEdited: '',
            outputEdited: '',
            isLocked: false,
            isSkipped: false,
            setSource,
            setType: 'evaluation',
            modelName,
            evaluator: '',
            foundationModelName,
            maxTokens,
            minTokens,
            temperature,
            topP,
          })
        )
      );
    }
  } catch (err) {
    console.log('err ->', err);
  }
}

export function* createEvaluationLine(i: Partial<IEvaluationLine>) {
  const user = yield* select(selectors.raw.$rawUser);
  const { userName: creator } = user;

  try {
    const id = guid4();

    yield put(
      actions.evaluationLines.add(
        removeUndefinedValues({
          id,
          dateCreated: Date.now(),
          creator,
          status: 'Pending',
          inputEdited: '',
          outputEdited: '',
          isLocked: false,
          isSkipped: false,
          setType: 'evaluation',
          evaluator: '',
          ...i,
        })
      )
    );
  } catch (err) {
    console.log('err ->', err);
  }
}

export function* createGenerationJob(dataset: Json[], data: IGenerationJobConfig) {
  try {
    const setId = data.id || guid4();

    const linesToAdd = dataset.filter((i) => i.prompt);

    const {
      name = 'Batch #' + setId,
      description = '',
      creator,
      setSource,
      setSourceId = '',
    } = data;

    yield put(
      actions.generationJobs.add({
        id: setId,
        name,
        status: 'New',
        description,
        dateCreated: Date.now(),
        creator,
        setType: 'generation',
        setSource,
        setSourceId,
        fileSizeInBytes: JSON.stringify(linesToAdd).length,
        linesCount: linesToAdd.length,
        linesCompleted: 0,
        tags: [],
        isDeleted: false,
      })
    );

    let index = 1;

    for (let i of linesToAdd) {
      const id = guid4();

      let {
        prompt,
        completion = '',
        comment = '',
        generationDate = Date.now(),
        modelName,
        foundationModelName,
        temperature = i.temperature ?? 0.7,
        maxTokens = i.maxTokens ?? 200,
        minTokens = i.minTokens ?? 10,
        topP = i.topP ?? 1,
        stopSequences = i.stopSequences ?? [],
        frequencyPenalty = i.frequencyPenalty ?? 0,
      } = i;

      if (!modelName) {
        modelName = 'j2-mid';
      }

      if (!foundationModelName) {
        foundationModelName = modelName;
      }

      yield put(
        actions.generationLines.add(
          removeUndefinedValues({
            id,
            setId,
            index: index++,
            dateCreated: generationDate,
            creator: creator ?? data.creator,
            input: prompt,
            output: completion,
            status: 'Pending',
            comment,
            inputEdited: '',
            outputEdited: '',
            isLocked: false,
            isSkipped: false,
            setSource: setSource || 'unknown',
            setType: 'generation',
            modelName,
            foundationModelName,
            maxTokens,
            minTokens,
            temperature,
            topP,
            stopSequences,
            frequencyPenalty,
          })
        )
      );
    }
  } catch (err) {
    console.log('err ->', err);
  }
}

export function* createCollectionJob(dataset: Json[], data: ICollectionJobConfig) {
  try {
    const setId = data.id || guid4();

    const linesToAdd = dataset.filter((i) => i.prompt);

    const {
      name = 'Batch #' + setId,
      description = '',
      creator,
      setSource,
      setSourceId = '',
    } = data;

    yield put(
      actions.collectionJobs.add({
        id: setId,
        name,
        status: 'New',
        description,
        dateCreated: Date.now(),
        creator,
        setType: 'collection',
        setSource,
        setSourceId,
        fileSizeInBytes: JSON.stringify(linesToAdd).length,
        linesCount: linesToAdd.length,
        linesCompleted: 0,
        tags: [],
        isDeleted: false,
      })
    );

    let index = 1;

    for (let i of linesToAdd) {
      const id = guid4();

      let {
        prompt,
        completion = '',
        comment = '',
        generationDate = Date.now(),
        modelName,
        foundationModelName,
        temperature = i.temperature ?? 0.7,
        maxTokens = i.maxTokens ?? 200,
        minTokens = i.minTokens ?? 10,
        topP = i.topP ?? 1,
        stopSequences = i.stopSequences ?? [],
        frequencyPenalty = i.frequencyPenalty ?? 0,
      } = i;

      if (!modelName) {
        modelName = 'j2-mid';
      }

      if (!foundationModelName) {
        foundationModelName = modelName;
      }

      yield put(
        actions.collectionLines.add(
          removeUndefinedValues({
            id,
            setId,
            index: index++,
            dateCreated: generationDate,
            creator: creator ?? data.creator,
            input: prompt,
            output: completion,
            status: 'Pending',
            comment,
            inputEdited: '',
            outputEdited: '',
            isLocked: false,
            isSkipped: false,
            setSource: setSource || 'unknown',
            setType: 'generation',
            modelName,
            foundationModelName,
            maxTokens,
            minTokens,
            temperature,
            topP,
            stopSequences,
            frequencyPenalty,
          })
        )
      );
    }
  } catch (err) {
    console.log('err ->', err);
  }
}

export function* createCollectionLine(i: Partial<ICollectionLine>) {
  const user = yield* select(selectors.raw.$rawUser);
  const { userName: creator } = user;

  try {
    const id = guid4();

    yield put(
      actions.collectionLines.add(
        removeUndefinedValues({
          id,
          dateCreated: Date.now(),
          creator,
          status: 'Pending',
          inputEdited: '',
          outputEdited: '',
          isLocked: false,
          isSkipped: false,
          setType: 'collection',
          evaluator: '',
          ...i,
        })
      )
    );
  } catch (err) {
    console.log('err ->', err);
  }
}
