import styled from 'styled-components';
import { IconButton, tokens } from '../..';

export const Wrapper = styled.div`
  position: relative;
`;

export const CarouselWrapper = styled.div`
  flex: 1;
  display: flex;
  padding: 24px 0;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &.mobile {
    height: 166px;
    margin: 24px 0px 24px;
    border: 1px solid ${tokens.primary.borderDefault};
    border-radius: 8px;
    overflow: hidden;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
  background-size: cover;
  background-position: center;
  margin-right: 16px;
  border-radius: 8px;
  background-repeat: no-repeat;
  border: 1px solid ${tokens.primary.borderActive};

  &:last-of-type {
    margin-right: 0;
  }

  &.mobile {
    background-size: contain;
    min-width: 296px;
    height: 166px;
    border: none;
  }
`;

export const Arrows = styled.div`
  padding: 0 6px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 45%;
`;

export const Arrow = styled(IconButton)`
  background-color: ${tokens.primary.backgroundDefault};
  border: 1px solid ${tokens.primary.borderDefault};

  &.disabled {
    background-color: ${tokens.disabled.background};
    border: 1px solid ${tokens.disabled.border};
    color: ${tokens.disabled.text};
    cursor: default;
  }
`;
