import React from 'react';
import { authPages } from '@ai21/studio-auth';
import { RoutePageId } from '../../../packages/studio-ui/dist/dts/platform/platform.types';

const AccountPage = React.lazy(() => import('./account/AccountPage.container'));
const ApiKeyPage = React.lazy(() => import('./apiKey/ApiKeyPage.container'));
const BillingPage = React.lazy(() => import('./billing/BillingPage.container'));
const ChangePaymentMethodPage = React.lazy(
  () => import('./payment/ChangePaymentMethodPage.container')
);
const ExamplesPage = React.lazy(() => import('./examples/ExamplesPage.container'));
const FineTuningSetsPage = React.lazy(
  () => import('./fineTuningSets/FineTuningSetsPage.container')
);
const OurModelsPage = React.lazy(() => import('./ourModels/OurModelsPage.container'));
const FoundationPage = React.lazy(() => import('./foundation/FoundationPage.container'));
const GenericPage = React.lazy(() => import('./generic/GenericPage'));
const LegoPage = React.lazy(() => import('./lego/LegoPage.container'));
const MobilePage = React.lazy(() => import('./mobile/MobilePage.container'));
const CustomModelsPage = React.lazy(() => import('./customModels/CustomModelsPage.container'));
const ModelUsagePage = React.lazy(() => import('./usage/ModelUsagePage.container'));
const NotFoundPage = React.lazy(() => import('./notFound/NotFoundPage.container'));
const OnBoardingPage = React.lazy(() => import('./onBoarding/OnBoardingPage.container'));
const OrganizationPage = React.lazy(() => import('./organization/OrganizationPage.container'));
const OverviewPage = React.lazy(() => import('./overview/OverviewPage.container'));
const HomePage = React.lazy(() => import('./home/HomePage.container'));
const Payment = React.lazy(() => import('../components/payment.Payment/Payment'));
const PresetsPage = React.lazy(() => import('./presets/PresetsPage.container'));
const PrivacyPolicyPage = React.lazy(() => import('./privacy/PrivacyPolicyPage'));
const ReleaseLogsPage = React.lazy(() => import('./releaseLogs/ReleaseLogsPage.container'));
const UiComponentsPage = React.lazy(() => import('./uiComponents/UiComponentsPage.container'));
const TermsOfUserPage = React.lazy(() => import('./terms/TermsOfUsePage'));
const TrainingPage = React.lazy(() => import('./training/TrainingPage.container'));
const WhatsNewPage = React.lazy(() => import('./whatsNew/WhatsNewPage.container'));
const WidgetPage = React.lazy(() => import('./widget/WidgetPage.container'));
const ToolsPage = React.lazy(() => import('./tools/ToolsPage.container'));
const DatasetsPage = React.lazy(() => import('./datasets/DatasetsPage.container'));

export const pages: Record<RoutePageId, React.FC<any>> = {
  ...authPages,
  account: AccountPage,
  apiKey: ApiKeyPage,
  billing: BillingPage,
  changePayment: ChangePaymentMethodPage,
  examples: ExamplesPage,
  fineTuningSets: FineTuningSetsPage,
  foundation: FoundationPage,
  generic: GenericPage,
  mobile: MobilePage,
  onBoarding: OnBoardingPage,
  payment: Payment,
  privacy: PrivacyPolicyPage,
  terms: TermsOfUserPage,
  termsFullPage: TermsOfUserPage,
  lego: LegoPage,
  customModels: CustomModelsPage,
  notFound: NotFoundPage,
  organization: OrganizationPage,
  overview: OverviewPage,
  home: HomePage,
  presets: PresetsPage,
  releaseLogs: ReleaseLogsPage,
  uiComponents: UiComponentsPage,
  training: TrainingPage,
  usage: ModelUsagePage,
  whatNew: WhatsNewPage,
  widget: WidgetPage,
  external: GenericPage,
  public: GenericPage,
  tools: ToolsPage,
  datasets: DatasetsPage,
  ourModels: OurModelsPage,
};
