import React, { useEffect } from 'react';
import { Subtitle, Title, Wrapper } from './GenerationProgress.style';
import { Loader } from '@ai21/studio-ui';
import { addListener } from 'shared-base';

export type GenerationProgressProps = {
  total: number;
  completed: number;
  onCancel: () => void;
};

export function GenerationProgress(props: GenerationProgressProps) {
  const { total, completed } = props;

  useEffect(() => {
    const unlisten = addListener('closeProgress', () => {
      props.onCancel();
    });

    return () => unlisten();
  }, []);

  const progress = total ? Math.ceil(completed / total) * 100 : '-';

  return (
    <Wrapper
      className='GenerationProgress-wrapper'
      data-testid='GenerationProgress-wrapper'
    >
      <Title variant='h6'>Generating completions</Title>
      <Subtitle variant='body1'>
        Line {completed}/{total} ({progress}%)
      </Subtitle>
      <Loader />
    </Wrapper>
  );
}

export default GenerationProgress;
