import styled from 'styled-components';

export const Wrapper = styled.div``;

export const WrapperPage = styled.div<{ height?: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${(props) =>
    props.height ? props.height : 'calc(100vh - 100px)'};
  padding-left: 220px;
`;
