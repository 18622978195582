interface CancelIconProps {
  style: React.CSSProperties;
}

export function BackIcon({ style }: CancelIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      fill='none'
      viewBox='0 0 21 20'
      style={style}
    >
      <path
        fill='#A0A2A8'
        d='M16.5 9.25H7.373l4.192-4.193L10.5 4l-6 6 6 6 1.058-1.057-4.185-4.193H16.5v-1.5z'
      />
    </svg>
  );
}
