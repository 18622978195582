import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;

  & .MuiDivider-root {
    margin: 24px 0;
  }
`;
