import { useEffect } from 'react';
import { useLocalStorage, useToggle } from 'react-use';
import { addListener } from 'shared-base';
import EventLogs from './EventLogs';
import { useEventData } from './EventLogs.hooks';
import { LoggerConfig } from './EventLogs.types';

export type EventLogsProps = {
  config: LoggerConfig;
  open?: boolean;
  compact?: boolean;
};

export function EventLogsContainer(props: EventLogsProps) {
  const { config, open = false, compact } = props;
  const { id } = config;
  const [logs, allEvents, lastEvent, { setLastEvent, clearEvents, showHistory, downloadEvents }] =
    useEventData(config);

  const [show, setShow] = useLocalStorage(`show_event_logs_${id}`, open);
  const [isMinimized, setMinimize] = useLocalStorage(`minimize_event_logs_${id}`, false);

  function toggleShow() {
    setShow(!show);
  }

  function toggleMinimize() {
    setMinimize(!isMinimized);
  }

  useEffect(() => {
    const callback = () => {
      toggleShow();
    };

    const toggleEventId = `toggle_event_logs_${id}`;
    const unlisten = addListener(toggleEventId, callback);

    return () => unlisten();
  }, []);

  if (!show) {
    return null;
  }

  return (
    <EventLogs
      items={logs}
      lastEvent={lastEvent}
      allEvents={allEvents}
      compact={compact}
      setLastEvent={setLastEvent}
      clearEvents={clearEvents}
      downloadEvents={downloadEvents}
      showHistory={showHistory}
      toggleShow={toggleShow}
      toggleMinimize={toggleMinimize}
      isMinimized={isMinimized}
    />
  );
}

export default EventLogsContainer;
