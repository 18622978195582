/**
 * sign up events
 * * */
// stage one
export const ANALYTICS_SIGN_IN_SUCCESS = 'SignInSuccess';
export const ANALYTICS_SIGN_IN_FAILED = 'SignInFailed';
// stage two
export const ANALYTICS_SMS_SEND_SUCCESS = 'SmsSendSuccess';
export const ANALYTICS_SMS_SEND_FAILED = 'SmsSendFailed';
export const ANALYTICS_SMS_CODE_RESEND_PRESSED = 'SmsCodeResendPressed';
export const ANALYTICS_EMAIL_VERIFICATION_RESEND_PRESSED = 'EmailVerificationResendPressed';
export const ANALYTICS_FIREBASE_EMAIL_VERIFIED = 'FirebaseEmailVerified';
// stage three
export const ANALYTICS_SMS_CODE_SUCCESS = 'SmsCodeSuccess';
export const ANALYTICS_SMS_CODE_FAILED = 'SmsCodeFailed';
export const ANALYTICS_USER_SIGNIN_SUCCESS = 'UserSigininSuccess';
// final sign in stage
export const APP_USER_CREATION_SUCCESS = 'AppUserCreationSuccess';
export const APP_USER_CREATION_FAILED = 'AppUserCreationFailed';
// final login stage
export const APP_USER_FETCH_SUCCESS = 'AppUserFetchSuccess';
export const APP_USER_FETCH_FAILED = 'AppUserFetchFailed';
// logout
export const ANALYTICS_LOGOUT_SUCCESS = 'LogoutSuccess';
// generic entered/exited events
export const ANALYTICS_PAGE_ENTERED = 'PageEntered';
export const ANALYTICS_PAGE_EXITED = 'PageExited';
export const ANALYTICS_MOBILE_PAGE_SHOWN = 'MobilePageShown';
// playground events
export const ANALYTICS_COMPLETION_REQUESTED = 'CompletionRequested';
export const ANALYTICS_COMPLETION_GENERATED = 'CompletionGenerated';
export const ANALYTICS_COMPLETION_ERROR = 'CompletionError';
export const ANALYTICS_PRESET_SELECTED = 'PresetSelected';
export const ANALYTICS_PRESET_SAVED = 'PresetSaved';
export const ANALYTICS_PRESET_DELETED = 'PresetDeleted';
export const ANALYTICS_PRESET_LABEL_CLICKED = 'LableFilterPressed';
export const ANALYTICS_PRESET_LABELS_CLEARED = 'LableFilterCleared';
export const ANALYTICS_NAVIGATOR_PRESSED = 'NavigatorPressed';
export const ANALYTICS_POST_PROMPT_SHARE_STARTED = 'PostPromptShareStarted';
export const ANALYTICS_POST_PROMPT_SHARE_SUCCESS = 'PostPromptShareSuccess';
export const ANALYTICS_POST_PROMPT_SHARE_FAILED = 'PostPromptShareError';
export const ANALYTICS_GET_PROMPT_SHARE_STARTED = 'GetPromptShareStarted';
export const ANALYTICS_GET_PROMPT_SHARE_SUCCESS = 'GetPromptShareSuccess';
export const ANALYTICS_GET_PROMPT_SHARE_FAILED = 'GetPromptShareError';
export const ANALYTICS_PROMPT_SHARE_COPIED = 'PromptShareCopied';
export const ANALYTICS_API_SNIPPET_SHOWN = 'APISnippetShown';
export const ANALYTICS_API_SNIPPET_COPIED = 'APISnippetCopied';
export const ANALYTICS_ONBOARDING_VIDEO_PRESSED = 'OnboardingVideoPressed';
export const ANALYTICS_CANVAS_CLEARED = 'CanvasCleared';
export const ANALYTICS_NEW_PRESET_PRESSED = 'NewPresetPressed';
export const ANALYTICS_PRESET_EXPORT_DATA_PRESSED = 'PresetExportDataPressed';
export const ANALYTICS_CUSTOM_MODELS_EXPORT_DATA_PRESSED = 'CustomModelsExportDataPressed';
export const ANALYTICS_INAPPROPRIATE_CONTENT_FLAGGED = 'InappropriateContentFlagged';
export const ANALYTICS_MODEL_CONFIG_CHANGED = 'ModelConfigChanged';
export const ANALYTICS_ALTERNATIVE_TOKENS_ACTIVATED = 'AlternativeTokensActivated';
export const ANALYTICS_ALTERNATIVE_TOKENS_DEACTIVATED = 'AlternativeTokensDeactivated';
export const ANALYTICS_COMPLETION_CLEARED = 'CompletionCleared';
export const ANALYTICS_COMPLETION_CANCELLED = 'CompletionCancelled';
export const ANALYTICS_TRAIN_SETTINGS_CHANGED = 'TrainSettingsChanged';
export const ANALYTICS_MODEL_COST_CHANGED = 'ModelCostChanged';
export const ANALYTICS_REGENERATED_PRESSED = 'RegeneratePressed';
export const ANALYTICS_WHITESPACE_MESSAGE_SHOWN = 'MessageShown';
export const ANALYTICS_DOCS_OPENED = 'DocsOpened';
export const ANALYTICS_WHITESPACE_MESSAGE_DISMISSED = 'MessageDismissed';
// payment events
export const ANALYTICS_PAYMENT_SUCCESS = 'AppPaymentSuccess';
export const ANALYTICS_PAYMENT_FAILED = 'AppPaymentFailed';
export const ANALYTICS_PAYMENT_SUBMITTED = 'AppPaymentSubmitted';
export const ANALYTICS_PLAN_CANCELED_PRESSED = 'PlanCancelPressed';
export const ANALYTICS_CONFIRM_CANCEL_PRESSED = 'ConfirmCancelPressed';
export const ANALYTICS_PLAN_PRESSED = 'PlanPressed';
export const ANALYTICS_PLAN_UPGRADE_PRESSED = 'PlanUpgradePressed';
// custom models
export const ANALYTICS_CUSTOM_MODELS_CTA_PRESSED = 'CustomModelsCTAPressed';
export const NEW_CUSTOM_MODEL_PRESSED = 'NewCustomModelPressed';
export const CHOOSE_HYPER_PARAMETERS_SHOWN = 'ChooseHyperParametersShown';
export const CUSTOM_MODEL_TRAIN_SUBMITTED = 'CustomModelTrainSubmitted';
export const CUSTOM_MODEL_TRY_IN_PLAYGROUND = 'CustomModelTryInPlaygroundPressed';
// datasets
export const ANALYTICS_DATASET_EXPORT_DATA_PRESSED = 'DatasetExportDataPressed';
export const ANALYTICS_DATASET_EXAMPLE_DOWNLOADED = 'DatasetExampleDownloaded';
export const ANALYTICS_TECHNICAL_DOCUMENTATION_PRESSED = 'TechnicalDocumentationPressed';
export const ANALYTICS_DATASET_SELECT_FILE_PRESSED = 'DatasetSelectFilePressed';
export const ANALYTICS_DATASET_SAVE_PRESSED = 'SaveDatasetPressed';
export const ANALYTICS_DATASET_COPY_ID = 'DatasetCopyIDPressed';
export const ANALYTICS_UPLOAD_DATASET_PRESSED = 'DatasetUploadPressed';
export const DATASET_UPLOADED = 'DatasetUploaded';
export const DATASET_UPLOAD_ERROR = 'DatasetUploadError';
export const DATASET_DOWNLOAD_PRESSED = 'DatasetDownloadPressed';
export const DATASET_DELETE_SUCCEEDED = 'DatasetDeleteSucceeded';
export const DATASET_DELETE_PRESSED = 'DatasetDeletePressed';
export const DATASET_DELETE_ERROR = 'DatasetDeletedError';
export const MODEL_DELETE_SUCCEEDED = 'ModelDeleteSucceeded';
export const MODEL_DELETE_PRESSED = 'ModelDeletePressed';
export const MODEL_DELETE_ERROR = 'ModelDeletedError';
// organization events
export const ANALYTICS_SEND_INVITE_ORGANIZATION_STARTED = 'InviteMemberPressed';
export const ANALYTICS_SEND_INVITE_ORGANIZATION_SUCCESS = 'InviteMemberSent';
export const ANALYTICS_FETCH_USER_DETAILS_WITH_INVITE_SUCCESS = 'MemberAddToOrganizationSuccess';
export const ANALYTICS_UPGRADE_PLAN_PRESSED = 'UpgradePlanCTAPressed';
// feedback
export const ANALYTICS_FEEDBACK_SUBMITTED = 'FeedbackSubmitted';
export const ANALYTICS_FEEDBACK_DISMISSED = 'FeedbackDismissed';
export const ANALYTICS_FEEDBACK_FAILED = 'FeedbackSubmitError';
export const ANALYTICS_FEEDBACK_DEACTIVATED = 'FeedbackDeactivated';
export const ANALYTICS_FEEDBACK_ACTIVATED = 'FeedbackActivated';
export const ANALYTICS_FEEDBACK_SHOWN = 'FeedbackShown';
export const ANALYTICS_MESSAGE_SHOWN = 'MessageShown';
export const ANALYTICS_MESSAGE_DISMISSED = 'MessageDismissed';
export const ANALYTICS_MODAL_SHOWN = 'ModalShown';
export const ANALYTICS_MODAL_DISMISSED = 'ModalDismissed';
export const ANALYTICS_API_KEY_COPIED = 'APIKeyCopied';
// overview events
export const ANALYTICS_OVERVIEW_API_SNIPPET_SHOWN = 'OverviewAPISnippetShown';
export const ANALYTICS_OVERVIEW_API_SNIPPET_COPIED = 'OverviewPISnippetCopied';
// summarization
export const ANALYTICS_SUMMARIZATION_REQUESTED = 'SummarizationRequested';
export const ANALYTICS_SUMMARIZATION_GENERATED = 'SummarizationGenerated';
export const ANALYTICS_SUMMARIZATION_ERROR = 'SummarizationError';
export const ANALYTICS_SUMMARIZATION_CLEARED = 'SummarizationCleared';
export const ANALYTICS_SUMMARIZATION_CANCELLED = 'SummarizationCancelled';
// rewrite
export const ANALYTICS_REWRITE_REQUESTED = 'RewriteRequested';
export const ANALYTICS_REWRITE_GENERATED = 'RewriteGenerated';
export const ANALYTICS_REWRITE_CANCLED = 'RewriteCancled';
export const ANALYTICS_SREWRITE_ERROR = 'RewriteError';
export const ANALYTICS_REWRITE_CLEARED = 'RewriteCleared';
export const ANALYTICS_REWRITE_CANCELED = 'RewriteCanceled';
