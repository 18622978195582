import { TextField } from '@mui/material';
import React from 'react';
import { Wrapper } from './Input.style';
import classnames from 'classnames';

export type InputProps = {
  name?: string;
  placeholder?: string;
  value?: string;
  label?: string;
  onChange: (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void; // prettier-ignore
  onBlur?: () => void;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  rows?: number;
  multiline?: boolean;
  isNumeric?: boolean;
  isPassword?: boolean;
  isDisabled?: boolean;
  className?: string;
  preventArrows?: boolean;
  isError?: boolean;
  'data-testid'?: string;
};

export const Input = React.forwardRef((props: InputProps, ref: any) => {
  const {
    placeholder,
    value = '',
    multiline,
    rows,
    isNumeric,
    isPassword,
    preventArrows,
    label,
    name,
    isError,
    autoFocus,
    isDisabled,
  } = props;

  let type = 'text';

  if (isNumeric) {
    type = 'number';
  }

  if (isPassword) {
    type = 'password';
  }

  function onKeyDown(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (isArrowKey(ev.key) && preventArrows) {
      ev.preventDefault();
    }
    if (props.onKeyDown) {
      props.onKeyDown(ev);
    }
  }

  function onChange(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    _newValue?: string
  ) {
    props.onChange(event);
  }

  const className = classnames('Input-wrapper', props.className, {});
  let isTextarea = multiline;

  if (rows && rows > 1) {
    isTextarea = true;
  }

  return (
    <Wrapper className={className} data-testid='Input-wrapper'>
      <TextField
        variant='outlined'
        color='secondary'
        type={type}
        placeholder={placeholder}
        rows={rows}
        label={label}
        value={value}
        disabled={isDisabled}
        multiline={isTextarea}
        autoFocus={autoFocus}
        onBlur={props.onBlur}
        error={isError}
        onKeyDown={onKeyDown}
        onChange={onChange}
        name={name}
        inputProps={{
          'data-testid': props['data-testid'],
        }}
        fullWidth
      />
    </Wrapper>
  );
});

export default Input;

const isArrowKey = (key: string) => {
  return (
    key === 'ArrowUp' ||
    key === 'ArrowRight' ||
    key === 'ArrowDown' ||
    key === 'ArrowLeft'
  );
};
