import { ListItemButtonProps as Props } from '@mui/material';
import React, { ForwardedRef } from 'react';
import { invokeEvent } from 'shared-base';
import { Wrapper } from './ListItemButton.style';

export type ListItemButtonProps = Props & {
  className?: string;
};

export const ListItemButton = React.forwardRef(
  (props: ListItemButtonProps, ref: ForwardedRef<HTMLDivElement>) => {
    function onMouseDown(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      if (!props.onMouseDown) {
        return;
      }

      invokeEvent('global/click', {
        ev,
      });

      return props.onMouseDown(ev);
    }

    return (
      <Wrapper
        className='ListItemButton-wrapper'
        data-testid='ListItemButton-wrapper'
        ref={ref}
        {...props}
        onMouseDown={onMouseDown}
      />
    );
  }
);

export default ListItemButton;
