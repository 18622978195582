import { GENERIC_ERROR_MESSAGE } from '../../constants/constants';
import { PricingInfo } from '../../data-types/Payment';
import { SnackbarSeverity } from '../../data-types/Snackbar';
import { addDays } from '../../utils/utils';
import {
  PAYMENT_FAILURE,
  PAYMENT_STARTED,
  PAYMENT_SUCCESS,
  PAYMENT_UPDATE_FAILURE,
  PAYMENT_UPDATE_STARTED,
  PAYMENT_UPDATE_SUCCESS,
  PaymentActionTypes,
  SET_PAYMENT_PAGE_DETAILS,
} from '../actionTypes/PaymentActionTypes';
import { CLEAR_SNACKBAR_MESSAGE } from '../actionTypes/presetActionTypes';

export interface StripeError {
  message: string | null;
  code: string | null;
}

export interface PriceInfo {
  key: string;
  priceCents: number;
  interval: string;
  type?: string;
}

export interface PaymentState {
  prices: PriceInfo[] | undefined;
  error: StripeError | undefined;
  inProgress: boolean;
  fetchStartTime: number | undefined;
  fetchDuration: number | undefined;
  hadPaymentSuccess: boolean;
  paymentSuccess: boolean;
  paymentPageDetails: PricingInfo | undefined;
  stripeCoupon: string | undefined;
  stripeCouponExpiration: Date | undefined;
  snackbarMessage: string | undefined;
  snackbarSeverity: SnackbarSeverity;
}

const initialState: PaymentState = {
  prices: undefined,
  error: undefined,
  inProgress: false,
  fetchStartTime: undefined,
  fetchDuration: undefined,
  hadPaymentSuccess: false,
  paymentSuccess: false,
  paymentPageDetails: undefined,
  stripeCoupon: undefined,
  stripeCouponExpiration: undefined,
  snackbarSeverity: SnackbarSeverity.INFO,
  snackbarMessage: undefined,
};

export function reducer(state = initialState, action: PaymentActionTypes): PaymentState {
  switch (action.type) {
    case PAYMENT_STARTED:
    case PAYMENT_UPDATE_STARTED:
      return {
        ...state,
        error: undefined,
        inProgress: true,
        fetchStartTime: action.fetchStartTime,
        snackbarSeverity: SnackbarSeverity.INFO,
        snackbarMessage: undefined,
      };
    case PAYMENT_FAILURE:
    case PAYMENT_UPDATE_FAILURE:
      return {
        ...state,
        error: { message: action.message, code: action.code } || {
          message: GENERIC_ERROR_MESSAGE,
          code: 'generic_code',
        },
        inProgress: false,
        fetchDuration: state.fetchStartTime ? Date.now() - state.fetchStartTime : undefined,
        snackbarSeverity: SnackbarSeverity.ERROR,
        snackbarMessage: 'Payment failed please try again',
      };
    case PAYMENT_SUCCESS:
    case PAYMENT_UPDATE_SUCCESS:
      return {
        ...state,
        error: undefined,
        inProgress: false,
        fetchDuration: state.fetchStartTime ? Date.now() - state.fetchStartTime : undefined,
        paymentSuccess: true,
        snackbarSeverity: SnackbarSeverity.SUCCESS,
        snackbarMessage: 'User Upgraded successfully',
      };
    case 'LOAD_COUPON' as any:
      return {
        ...state,
        stripeCoupon: (action as any).stripeCoupon,
        stripeCouponExpiration: addDays(new Date(), 1),
      };
    case SET_PAYMENT_PAGE_DETAILS:
      if (action.stripeCoupon) {
        // got a fresh coupon value from query-string
        return {
          ...state,
          paymentPageDetails: action.details,
          stripeCoupon: action.stripeCoupon,
          stripeCouponExpiration: addDays(new Date(), 1),
        };
      }
      if (state.stripeCouponExpiration && state.stripeCouponExpiration < new Date()) {
        return {
          ...state,
          paymentPageDetails: action.details,
          stripeCoupon: undefined,
          stripeCouponExpiration: undefined,
        };
      }
      return {
        ...state,
        paymentPageDetails: action.details,
      };
    case CLEAR_SNACKBAR_MESSAGE:
      return {
        ...state,
        snackbarSeverity: SnackbarSeverity.INFO,
        snackbarMessage: undefined,
      };
    default:
      return state;
  }
}
