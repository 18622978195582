import { Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  max-width: 710px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
`;

export const Highlight = styled(Typography)`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.4;
`;

export const Content = styled.div`
  max-height: 340px;
  overflow-y: scroll;
`;
