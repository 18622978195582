import { Icon } from '@ai21/studio-base-ui';
import { Link, Logo, Text, Wrapper } from './Footer.style';

export type FooterProps = {
  data: Json;
};

export function Footer(props: FooterProps) {
  const { data } = props;

  const { company = '' } = data ?? {};

  const href = `https://www.ai21.com/studio?utm_source=ai21&utm_medium=demo&utm_campaign=${company.toLowerCase()}_demo`;

  function onClick() {
    window.open(href, '_blank');
  }

  return (
    <Wrapper
      className='Footer-wrapper'
      data-testid='Footer-wrapper'
      onClick={onClick}
    >
      <Text variant='body1bold'>Powered by</Text>
      <Logo>
        <Icon className='logo' iconName='Logo' />
      </Logo>
      <Link href={href} target='_blank'>
        <Icon iconName='ExternalLink' className='reference' />
      </Link>
    </Wrapper>
  );
}

export default Footer;
