import React from 'react';

const DemosPage = React.lazy(() => import('./demosPage/DemosPage.container'));

const DemoFramePage = React.lazy(
  () => import('./demosFramePage/DemosFramePage.container')
);
const DemoNotFoundPage = React.lazy(
  () => import('./demosNotFound/DemosNotFoundPage')
);
const DemoNoApiKeyPage = React.lazy(
  () => import('./demosNoApiKey/DemosNoApiKeyPage')
);

export const pages: Record<string, React.FC<any>> = {
  demos: DemosPage,
  demoFrame: DemoFramePage,
  demoNotFound: DemoNotFoundPage,
  demoNoApiKey: DemoNoApiKeyPage,
};
