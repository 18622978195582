import { useCallback, useEffect, useState } from 'react';

export const useScroll = (ref: React.RefObject<HTMLDivElement>) => {
  const [scroll, setScroll] = useState(0);

  const onScroll = useCallback(() => {
    setScroll(ref.current?.scrollTop ?? 0);
  }, []);

  useEffect(() => {
    const that = ref.current;

    if (!that) {
      return;
    }

    that.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      that.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return scroll;
};
