import { IDocumentQuestions, IDocuments } from './types';

export const documents: IDocuments = {
  d123: {
    id: 'd123',
    name: 'Document 1',
    createdBy: 'Jimmy Bill',
    status: 'Completed',
    path: '/evaluation-jobs/1',
    creationDate: String(Date.now()),
    lastUpdated: String(Date.now()),
    labels: [],
    publicUrl: 'https://www.google.com',
    sizeBytes: 100,
    documentType: 'document',
  },
};

export const documentQuestions: IDocumentQuestions = {
  '1': {
    id: '1',
    prompt: 'What is the meaning of life?',
    answer: '42',
    answerInContext: true,
    createdAt: 1,
    status: 'waiting',
    sources: [
      {
        id: '1',
        name: 'Wikipedia',
        fileId: '',
        publicUrl: '',
        highlights: ['42'],
      },
    ],
  },
};
