import { Box, Button, Grid, Typography, icons } from '@ai21/studio-ui';
import { useDispatch, useSelector } from 'react-redux';
import { postDataset } from '../../redux/actions/actions.post';
import {
  getApiKey,
  getApproveWhitespaceCorrection,
  getDatasetFile,
  getSelectedColumns,
} from '../../redux/selectors';
import { DOCS_ROUTE } from '../../constants/constants';

export function DatasetValidationDeleteLongRows({
  datasetName,
}: {
  datasetName: string | undefined;
}) {
  const file = useSelector(getDatasetFile);
  const selectedColumns = useSelector(getSelectedColumns);
  const approveWhitespace = useSelector(getApproveWhitespaceCorrection);

  const dispatch = useDispatch();

  const onContinue = (deleteLongRows: boolean) => {
    if (datasetName && file) {
      dispatch(postDataset(datasetName, file, selectedColumns, approveWhitespace, deleteLongRows));
    }
  };

  return (
    <Grid container alignItems='center' justifyContent='center' direction='column'>
      <Box style={{ marginTop: '20px' }} />
      <icons.UploadingDataset />
      <Box marginTop={2} />
      <Typography variant='subtitle1'>
        {'Dataset '}
        datasetName
      </Typography>
      <Box marginTop={3} />
      <Grid container alignItems='center' justifyContent='center'>
        <Box
          sx={{
            width: 520,
            height: 52,
            bgcolor: '#fff6f0',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
          }}
        >
          <Box style={{ marginTop: '15px' }} />
          <Grid container alignItems='center' justifyContent='center'>
            <icons.Models style={{ marginRight: '4px', fill: 'inherit' }} />
            <Box style={{ marginRight: '8px' }} />
            <Typography color='textPrimary' variant='body1'>
              Looks like your Data can be improved
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Box marginTop={3} />
      <Typography variant='body1' align='center'>
        Your dataset contains examples that are too long. The combined
        <br />
        length of the prompt and completion must be less than 2048 tokens.&nbsp;&nbsp;
        <br />
        <a href={DOCS_ROUTE}>Read guidelines in documentation</a>
      </Typography>
      <Box marginTop={4} />
      <Typography variant='body1'>
        If you want, we can delete the long rows for you.
        <br />
        but the quality of your model may be affected
      </Typography>
    </Grid>
  );
}
