import { createContext, useMemo } from 'react';
import type { IMenuItem } from '@ai21/studio-base-ui';
import { IBreadcrumbsContextValue } from '../../types';

export type BreadcrumbsProviderProps = {
  items: IMenuItem[];
  onNavigate: (path: string) => void;
  children: React.ReactNode;
};

export const BreadcrumbsContext = createContext<IBreadcrumbsContextValue>({
  items: [],
  onNavigate: (path: string) => {},
});

export const BreadcrumbsProvider = ({ items, onNavigate, children }: BreadcrumbsProviderProps) => {
  const contextValue = useMemo<IBreadcrumbsContextValue>(() => {
    return {
      items,
      onNavigate,
    };
  }, []);

  return <BreadcrumbsContext.Provider value={contextValue}>{children}</BreadcrumbsContext.Provider>;
};
