export function GenerationSetIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 6.75C2 7.7165 2.7835 8.5 3.75 8.5H16.75C17.7165 8.5 18.5 7.7165 18.5 6.75V4.75C18.5 3.7835 17.7165 3 16.75 3H3.75C2.7835 3 2 3.7835 2 4.75V6.75ZM3.75 7C3.61193 7 3.5 6.88807 3.5 6.75V4.75C3.5 4.61193 3.61193 4.5 3.75 4.5H16.75C16.8881 4.5 17 4.61193 17 4.75V6.75C17 6.88807 16.8881 7 16.75 7H3.75Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.5 19.25C5.5 20.2165 6.2835 21 7.25 21H20.25C21.2165 21 22 20.2165 22 19.25V17.25C22 16.2835 21.2165 15.5 20.25 15.5H7.25C6.2835 15.5 5.5 16.2835 5.5 17.25V19.25ZM7.25 19.5C7.11193 19.5 7 19.3881 7 19.25V17.25C7 17.1119 7.11193 17 7.25 17H20.25C20.3881 17 20.5 17.1119 20.5 17.25V19.25C20.5 19.3881 20.3881 19.5 20.25 19.5H7.25Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H6C5.58579 12.75 5.25 12.4142 5.25 12Z'
      />
    </svg>
  );
}
