import { TextField, Typography, styled, tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .MuiTextField-root {
    background-color: transparent !important;
  }
`;

export const TextArea = styled(TextField)`
  max-height: 100px;

  fieldset {
    border: none;
  }

  /* textarea {
    font-size: 14px;
    line-height: 1.4;
    padding: 16px;
    outline: none;
    box-sizing: border-box;
  } */
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px 0 8px;

  &.large {
    height: 60px;

    button {
      margin-left: 8px;
    }
  }
`;

export const Count = styled(Typography)`
  flex: 1;
  margin-left: 3px;
`;

export const ExtraActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  border-top: 1px solid transparent;

  &:hover,
  &:active {
    border-color: ${tokens.component.dividerDefault};

    & + .PromptBottomActions-wrapper {
      border-top-color: ${tokens.component.dividerDefault};
    }
  }
`;
