import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  width: 605px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;

  svg {
    stroke-width: 1px;
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 8px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  height: calc(100% - 126px);
  overflow-y: auto;
  display: flex;
`;

export const Bottom = styled.div`
  height: 74px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  justify-content: flex-end;
  box-sizing: border-box;
  border-top: 1px solid #e5e5e5;

  button {
    margin-left: 12px;
    width: 130px;
  }
`;

export const Actions = styled.div`
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid #e5e5e5;

  svg {
    width: 20px;
    height: 20px;
  }
`;
