import { ax } from '@ai21/studio-analytics';
import {
  actions,
  createGenerationJob,
  IGenerationJobConfig,
  IGenerationLine,
  selectors,
} from '@ai21/studio-store';
import { get } from 'lodash';
import { call, delay, put, select } from 'saga-ts';
import { guid4 } from 'shared-base';
import { analytics } from './analytics';

export function* createJob(dataset: Json[], data: IGenerationJobConfig) {
  const setId = data.id || guid4();

  const isSample = get(dataset, '[0].prompt', '').includes(
    'The following sentences contain business jargon.'
  );

  try {
    const datasetClean = dataset.map((i) => {
      delete i['completion'];
      return i;
    });

    const linesCount = datasetClean.length;

    yield* call(createGenerationJob, datasetClean, {
      ...data,
      id: setId,
      isSample,
    });

    const linesToAdd = datasetClean.filter((i) => i.prompt);

    yield* call(analytics, {
      action: 'upload',
      actionValue: linesToAdd.length,
      eventId: 'BatchGenerateUploadSuccess',
      extra: { isSample },
    });

    yield delay(500);
    yield put(actions.generationJobs.get({}));

    yield put({
      type: 'NAVIGATE',
      to: `/tools/generation-sets/${setId}/lines`,
    });

    yield delay(500);

    ax.nudge('generationSets.total');

    if (isSample) {
      ax.nudge('generationSets.sample');
    } else {
      ax.nudge('generationSets.customStarted');
      ax.nudge('generationSets.custom');
      ax.nudge('generationSets.customTotalLines', linesCount);
    }

    ax.set(`generationSetsProgress.${setId}`, {
      status: 'New',
      linesCount,
      isSample,
    });

    // auto-start generation
    yield put({
      type: 'GENERATION_JOB',
      verb: 'start',
      id: setId,
    });
  } catch (err: any) {
    yield* call(analytics, {
      action: 'upload',
      actionValue: err.message,
      eventId: 'BatchGenerateUploadError',
      extra: { isSample },
    });
  }
}

export function* updateCompletedLinesCount(id: string) {
  const job = yield* select(selectors.singles.$generationJob, id);
  const lines = yield* select(selectors.singles.$generationLines, id);

  if (!job) {
    return;
  }

  let status = '';

  const linesCount = lines.length;
  const linesCompleted = lines.filter(
    (line: IGenerationLine) => line.output
  ).length;

  if (linesCompleted === linesCount) {
    status = 'Completed';
  } else {
    status = linesCompleted === 0 ? 'New' : 'Incomplete';
  }

  yield put(
    actions.generationJobs.patch(id, {
      linesCompleted,
      status,
    })
  );
}
