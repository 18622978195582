import demo from './json/form.demo.json';
import demoDefault from './form.demo.default.json';
import demoEdit from './json/form.demoEdit.json';
import inspector from './json/form.inspector.json';

export const forms = {
  demo,
  demoEdit,
  inspector,
};

export const formDefaults = {
  demo: demoDefault,
  inspector: {},
};
