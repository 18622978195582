import { useNavigate } from 'react-router-dom';
import { setContentRef, setNavigate } from '../sagas/saga.navigation';
import { useIntercom } from 'react-use-intercom';
import { setShowIntercomMethod } from '../sagas/saga.intercom';

export function useSaveGlobals(contentRef: React.RefObject<HTMLDivElement>) {
  const navigate = useNavigate();
  const { show } = useIntercom();

  setNavigate(navigate);

  (window as any)._navigate = navigate;

  setContentRef(contentRef);
  setShowIntercomMethod(show);
}
