import { select, delay, takeEvery, put } from 'saga-ts';
import { Json } from '../types';
import { IApp, selectors } from '@ai21/studio-store';
import { prompt as dialog, toast } from '@ai21/studio-ui';
import AppPage from '../pages/app/AppPage';
import AppPageContainer from '../pages/app/AppPage.container';
import AppPageShareButton from '../components/AppHeader/AppPageShareButton';

type Verb =
  | 'play' //
  | 'playground'
  | 'embed'
  | 'support'
  | 'openApp'
  | 'share'
  | 'documentation';

export type ActionShowroom = {
  type: 'SHOWROOM';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  play: play,
  playground: playground,
  embed: embed,
  support: support,
  documentation: documentation,
  openApp: openApp,
  share: share,
};

function* share(action: ActionShowroom, app: IApp) {
  const { title, id } = action.params?.app || {};
  if (!title || !id) return;
  const to = title.toLowerCase().replace(/ /g, '-');
  const url = `${window.__env__.VITE_APP_SERVING_UR || 'https://studio.ai21.com'}/lp/use-cases/${to}/${id}`; // prettier-ignore
  navigator.clipboard.writeText(url);
  toast.show('Link copied to clipboard', { type: 'success' });
}

function* play(action: ActionShowroom, app: IApp) {
  const { demoUrl: to } = app || {};
  if (to && to.includes('http')) {
    window.open(to, '_blank');
    return;
  }

  const url = `${window.__env__.VITE_APP_SERVING_URL || 'https://studio.ai21.com'}/${to}`; // prettier-ignore

  window.open(url, '_blank');
}

function* playground(action: ActionShowroom, app: IApp) {
  const { presetUrl: to } = app || {};
  const url = `${window.__env__.VITE_APP_SERVING_URL || 'https://studio.ai21.com'}/${to}`; // prettier-ignore

  window.open(url, '_blank');
}

function* embed(action: ActionShowroom, app: IApp) {
  console.log('embed', action);
  const { presetUrl: to } = app || {};

  const url = `${window.__env__.VITE_APP_SERVING_URL || 'https://studio.ai21.com'}/${to}&embed=code`; // prettier-ignore

  window.open(url, '_blank');
}

function* support(action: ActionShowroom, app: IApp) {
  window.open('https://www.ai21.com/meeting/contact', '_blank');
}

function* documentation(action: ActionShowroom, app: IApp) {
  const { docsUrl } = app || {};
  window.open(docsUrl, '_blank');
}

function* openApp(action: ActionShowroom, app: IApp) {
  const { value, didCancel } = yield dialog.custom({
    id: action.params?.app?.title || '',
    component: AppPageContainer,
    headerCta: AppPageShareButton,
    headerCtaProps: { app: action.params?.app },
    componentProps: {
      appId: action.params?.appId,
      app: action.params?.app,
    },
  });
}

export function* showroom(action: ActionShowroom) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];
  if (!saga) return;

  const app = yield* select(selectors.showroom.$currentApp);

  const currentApp = app || action.params?.app;

  yield* saga(action, currentApp);
}

export function* root() {
  yield takeEvery('SHOWROOM', showroom);
}
