import { Alert } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';

export const Wrapper = styled(Alert)`
  margin: 12px 0 0px;
  color: #3d67ff;
  background: #edf7ff;
  border-radius: 8px;
  align-items: center;
`;
