import styled from 'styled-components';

export const Wrapper = styled.div`
  color: rgba(31, 32, 32, 0.4);
  position: relative;
  height: 30px;
  margin-top: 20px;
`;

export const Line = styled.div`
  border-top: 1px solid rgba(31, 32, 32, 0.2);
  position: absolute;
  top: 13px;
  left: 0;
  right: 0;
`;

export const Text = styled.div`
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0 10px;
`;
