import { IconButton } from '../IconButton/IconButton';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Button = styled(IconButton)`
  padding: 0;
  background-color: transparent;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: transparent;

    svg {
      color: #fff;
    }
  }
`;
