import { fork } from 'saga-ts';
import { root as api } from './saga.api';
import { root as generationJobs } from './saga.generationJobs';
import { root as generationLines } from './saga.generationLines';
import { root as generationStatus } from './saga.generationStatus';
import { root as listener } from './saga.listener';

export function* root() {
  yield* fork(api);
  yield* fork(generationJobs);
  yield* fork(generationLines);
  yield* fork(generationStatus);
  yield* fork(listener);
}
