import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;

  input {
    min-height: 24px;
  }

  .MuiTextField-root {
    background-color: white;
    border-radius: 12px;
  }
`;
