import { Feedback, Skeleton } from '@ai21/studio-base-ui';
import { IDocumentQuestion } from '@ai21/studio-store';
import classNames from 'classnames';
import {
  Answer,
  BasedOn,
  Content,
  Error,
  FeedbackWrapper,
  Letter,
  P,
  Question,
  Sources,
  Wrapper,
} from './AskLineBase.style';
import { Verb } from '../../AskEditor.types';

export type AskLineBaseProps = {
  item: IDocumentQuestion;
  forcedAnswer?: string;
  children: React.ReactNode;
  callbacks: {
    onAction: (verb: Verb, params?: Json) => void;
  };
};

const isMobile = /Mobi|Android/i.test(navigator.userAgent);

export function AskLineBase(props: AskLineBaseProps) {
  const { item, forcedAnswer } = props;
  const {
    prompt,
    status,
    answer,
    answerInContext,
    errorMessage = 'Could not receive an action',
  } = item;

  function renderSources() {
    if (status !== 'answered') {
      return null;
    }

    return (
      <BasedOn>
        <P>Sources:</P>
        <Sources>{props.children}</Sources>
      </BasedOn>
    );
  }

  function renderError() {
    return <Error>Could not complete request: {errorMessage}</Error>;
  }

  function renderAnswer() {
    if (status === 'waiting') {
      return (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      );
    }

    if (status === 'error') {
      return renderError();
    }

    return answer ? answer : forcedAnswer;
  }

  function renderFeedback() {
    if (status !== 'answered' || !answerInContext || isMobile) {
      return null;
    }

    return (
      <FeedbackWrapper>
        <Feedback id={item.id} />
      </FeedbackWrapper>
    );
  }

  const className = classNames('AskLineBase-wrapper', {
    notInContext: answerInContext === false,
  });

  return (
    <Wrapper className={className} data-testid='AskLineBase-wrapper'>
      <Question className='question'>
        <Letter>Q</Letter>
        <Content>{prompt}</Content>
      </Question>
      <Answer className='answer'>
        <Letter className='white'>A</Letter>
        <Content>
          {renderAnswer()}
          {renderSources()}
        </Content>
        {renderFeedback()}
      </Answer>
    </Wrapper>
  );
}

export default AskLineBase;
