import { IApi, IApis, IModels, ModelStatus, PresetLabel, Tier } from './types';

export const apis: Record<string, IApi> = {
  contextual_answers_api: {
    id: 'contextual_answers_api',
    name: 'Contextual Answers',
    primaryLabel: PresetLabel.Q_AND_A,
    familyName: 'taskSpecificModel',
    secondaryLabels: [],
    description:
      'Provides answers based solely on a specific context. Context can be provided as part of the request or automatically retrieved from the document library.',
    docsUrl: 'https://docs.ai21.com/docs/contextual-answers-api',
    playgroundUrl: '/home/task-specific-models/contextual-answers',
    endpoint: '',
    imageUrl: '/task-specific/task-specific-illustrations/illus.playground.contextualanswers.svg',
  },
  summarization_api: {
    id: 'summarization_api',
    name: 'Summarize',
    familyName: 'taskSpecificModel',
    primaryLabel: PresetLabel.SUMMARIZE,
    secondaryLabels: [],
    description: 'Takes a document (raw text or URL) and returns a summary of the text.',
    docsUrl: 'https://docs.ai21.com/docs/summarize-api',
    playgroundUrl: '/home/task-specific-models/summarize',
    endpoint: '',
    imageUrl: '/task-specific/task-specific-illustrations/illus.playground.summarize.svg',
  },
  summarization_by_segment_api: {
    id: 'summarization_by_segment_api',
    name: 'Summarize by Segment',
    familyName: 'taskSpecificModel',
    primaryLabel: PresetLabel.SUMMARIZE,
    secondaryLabels: [],
    description: 'Segments a text into distinct topics and generates a summary for each segment.',
    docsUrl: 'https://docs.ai21.com/docs/summarize-by-segment-api',
    playgroundUrl: '',
    endpoint: '',
    imageUrl: '/task-specific/task-specific-illustrations/illus.playground.summarizebysegment.svg',
  },
  summarization_by_conversation_api: {
    id: 'summarization_by_conversation_api',
    name: 'Summarize Conversation',
    familyName: 'taskSpecificModel',
    primaryLabel: PresetLabel.SUMMARIZE,
    secondaryLabels: [],
    description:
      'takes a text (conversation or chat transcript) and generates a grounded summary that contains the subject and the main topics that were discussed.',
    docsUrl: 'https://docs.ai21.com/docs/summarize-conversation-api',
    playgroundUrl: '',
    endpoint: '',
    imageUrl:
      '/task-specific/task-specific-illustrations/illus.playground.summarizeconversation.svg',
  },
  paraphrase_api: {
    id: 'paraphrase_api',
    name: 'Paraphrase',
    familyName: 'taskSpecificModel',
    primaryLabel: PresetLabel.REWRITE,
    secondaryLabels: [PresetLabel.COPY_WRITING, PresetLabel.CONTENT_WRITING],
    description:
      'Takes a piece of text and returns a list of paraphrases that convey the same meaning using different words.',
    docsUrl: 'https://docs.ai21.com/docs/paraphrase-api',
    playgroundUrl: '/home/task-specific-models/paraphrase',
    endpoint: '',
    imageUrl: '/task-specific/task-specific-illustrations/illus.playground.paraphrase.svg',
  },
  grammar_correction_api: {
    id: 'grammar_correction_api',
    name: 'Grammatical Error Corrections',
    familyName: 'taskSpecificModel',
    primaryLabel: PresetLabel.REWRITE,
    secondaryLabels: [PresetLabel.COPY_WRITING, PresetLabel.CONTENT_WRITING],
    description:
      'Detects grammar, spelling, punctuation, and word choice errors in text, and suggests how to correct them.',
    docsUrl: 'https://docs.ai21.com/docs/gec-api',
    playgroundUrl: '/home/task-specific-models/grammatical-error-correction',
    endpoint: '',
    imageUrl: '/task-specific/task-specific-illustrations/illus.playground.grammatical.svg',
  },
  text_improvements_api: {
    id: 'text_improvements_api',
    name: 'Text Improvements',
    familyName: 'taskSpecificModel',
    primaryLabel: PresetLabel.REWRITE,
    secondaryLabels: [PresetLabel.COPY_WRITING, PresetLabel.CONTENT_WRITING],
    description:
      'Provides rewrite recommendations for improving the fluency, clarity and style of the input text.',
    docsUrl: 'https://docs.ai21.com/docs/text-improvements-api',
    playgroundUrl: '/home/task-specific-models/text-improvements',
    endpoint: '',
    imageUrl: '/task-specific/task-specific-illustrations/illus.playground.textimprovements.svg',
  },
  semantic_search_api: {
    id: 'semantic_search_api',
    name: 'Semantic Search',
    familyName: 'taskSpecificModel',
    primaryLabel: PresetLabel.INFO_EXTRACTION,
    secondaryLabels: [],
    description:
      'Understands the intent and contextual meaning of a query and retrieves the most relevant text segments from your documents.',
    docsUrl: 'https://docs.ai21.com/docs/semantic-search-api',
    playgroundUrl: '',
    endpoint: '',
    imageUrl: '/task-specific/task-specific-illustrations/illus.playground.semanticsearch.svg',
  },
  text_segmentation_api: {
    id: 'text_segmentation_api',
    name: 'Text Segmentation',
    familyName: 'taskSpecificModel',
    primaryLabel: PresetLabel.INFO_EXTRACTION,
    secondaryLabels: [PresetLabel.CLASSIFICATION, PresetLabel.SUMMARIZE],
    description:
      'Takes a document (raw text or URL) and returns segmentation into paragraphs by topic.',
    docsUrl: 'https://docs.ai21.com/docs/text-segmentation-api',
    playgroundUrl: '',
    endpoint: '',
    imageUrl: '/task-specific/task-specific-illustrations/illus.playground.segmentation.svg',
  },
  embeddings_api: {
    id: 'embeddings_api',
    name: 'Embeddings',
    familyName: 'taskSpecificModel',
    primaryLabel: PresetLabel.CHAT,
    secondaryLabels: [],
    description:
      'Transforms texts into fixed-size vectors that capture their semantic meanings in a high-dimensional space.',
    docsUrl: 'https://docs.ai21.com/docs/embeddings-api',
    playgroundUrl: '',
    endpoint: '',
    imageUrl: '/task-specific/task-specific-illustrations/illus.playground.embeddings.svg',
  },
  pdf_parser: {
    id: 'pdf_parser',
    name: 'PDF Parser',
    familyName: 'taskSpecificModel',
    primaryLabel: PresetLabel.INFO_EXTRACTION,
    secondaryLabels: [],
    description: 'Seamlessly processes and extracts content from PDF documents.',
    docsUrl: 'https://docs.ai21.com/docs/rag-engine-pdf-parser',
    playgroundUrl: '',
    endpoint: '',
    imageUrl: '/task-specific/task-specific-illustrations/illus.playground.summarizebysegment.svg',
  },
};
