import {
  FileDownloadOutlined,
  FileUploadOutlined,
  OpenInNewOutlined,
  PictureAsPdf,
  PlayArrow,
  PlayArrowOutlined,
  ScienceOutlined,
  WarningOutlined,
} from '@mui/icons-material';
import {
  AdjustmentsHorizontal,
  Archive,
  ArrowBackUp,
  ArrowBigLeft,
  ArrowDownRight,
  ArrowForwardUp,
  ArrowNarrowLeft,
  ArrowNarrowRight,
  ArrowRight,
  ArrowLeft,
  ArrowUpRight,
  ArrowWaveRightUp,
  ArrowsDiagonal,
  ArrowsDiagonal2,
  ArrowsDiagonalMinimize,
  ArrowsSort,
  Bell,
  BorderAll,
  Bulb,
  Check,
  Checklist,
  Checks,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsLeft,
  ChevronsRight,
  CircleCheck,
  Plug,
  ClockHour4,
  ClockHour5,
  ColorSwatch,
  Copy,
  DeviceFloppy,
  Directions,
  DotsVertical,
  EditCircle,
  Eraser,
  ExternalLink,
  Eye,
  File,
  FileAnalytics,
  FileDescription,
  FilePlus,
  FileSymlink,
  FileText,
  Help,
  History,
  Home,
  InfoSquare,
  Link,
  List,
  ListCheck,
  Loader2,
  Lock,
  LockOpen,
  Mail,
  Maximize,
  MaximizeOff,
  Menu2,
  Message,
  MessageCircle,
  MessageDots,
  MoodEmpty,
  OneTwoThree,
  PlayerPlay,
  Presentation,
  Puzzle,
  Pyramid,
  QuestionMark,
  Rainbow,
  Refresh,
  RefreshDot,
  Robot,
  Rotate2,
  Scale,
  ScreenShare,
  Send,
  Settings,
  SettingsAutomation,
  Square,
  SquareCheck,
  SquarePlus,
  Table,
  Tags,
  Template,
  ThumbDown,
  ThumbUp,
  Trash,
  Upload,
  Users,
  Wand,
  Writing,
  ZoomQuestion,
  ListSearch,
  ArrowUpLeft,
  Click,
  FileImport,
  UserCheck,
  Folder,
  Tool,
  Book2,
  Apps,
} from 'tabler-icons-react';
import { AccountIcon } from './base/AccountIcon';
import { AccountIconSmall } from './base/AccountIconSmall';
import { AddExpertIcon } from './base/AddExpertIcon';
import { AddIcon } from './base/AddIcon';
import { AlertIcon } from './base/AlertIcon';
import { AlertWarningIcon } from './base/AlertWarningIcon';
import { ArrowDropIcon } from './base/ArrowDropIcon';
import { ArrowForwardIcon } from './base/ArrowForwardIcon';
import { ArrowUpRightIcon } from './base/ArrowUpRight';
import { BackChevronIcon } from './base/BackChevronIcon';
import { BackIcon } from './base/BackIcon';
import { BasicTierIcon } from './base/BasicTierIcon';
import { BoxIcon } from './base/BoxIcon';
import { CalculatorIcon } from './base/CalculatorIcon';
import { CancelIcon } from './base/CancelIcon';
import { CelebrationIcon } from './base/CelebrationIcon';
import { CheckCircleIcon } from './base/CheckCircleIcon';
import { CheckIcon } from './base/CheckIcon';
import { CheckMarkIcon } from './base/CheckMarkIcon';
import { ClearGenerationIcon } from './base/ClearGenerationIcon';
import { ClearIcon } from './base/ClearIcon';
import { CloseIcon } from './base/CloseIcon';
import { CodeIcon } from './base/CodeIcon';
import { CollapseQuestionIcon } from './base/CollapseQuestionIcon';
import { CompletionApiIcon } from './base/CompletionApiIcon';
import { ConversationalRagIcon } from './base/ConversationalRagIcon';
import { CopyIcon } from './base/CopyIcon';
import { CreditCardIcon } from './base/CreditCardIcon';
import { CurlIcon } from './base/CurlIcon';
import { CurrencyIcon } from './base/CurrencyIcon';
import { CustomModelsIcon } from './base/CustomModelsIcon';
import { DatabaseIcon } from './base/DatabaseIcon';
import { DatasetIcon } from './base/DatasetIcon';
import { DatasetLargeIcon } from './base/DatasetLargeIcon';
import { DbTableIcon } from './base/DbTableIcon';
import { DismissIcon } from './base/DismissIcon';
import { DocsIcon } from './base/DocsIcon';
import { DoubleThumbUpIcon } from './base/DoubleThumbUpIcon';
import { DownloadIcon } from './base/DownloadIcon';
import { DropAnywhereModalIcon } from './base/DropAnywhereModalIcon';
import { EditIcon } from './base/EditIcon';
import { EmptyStarIcon } from './base/EmptyStarIcon';
import { EnterIcon } from './base/EnterIcon';
import { ExclamationMarkIcon } from './base/ExclamationMarkIcon';
import { ExclamationMarkIcon2 } from './base/ExclamationMarkIcon2';
import { ExpandQuestionIcon } from './base/ExpandQuestionIcon';
import { QuestionMarkIcon } from './base/QuestionMarkIcon';
import { ExpandRowIcon } from './base/ExpandRowIcon';
import { EyeIcon } from './base/EyeIcon';
import { FeedbackBadIcon } from './base/FeedbackBadIcon';
import { FeedbackCheckIcon } from './base/FeedbackCheckIcon';
import { FeedbackGreatIcon } from './base/FeedbackGreatIcon';
import { FeedbackOkayIcon } from './base/FeedbackOkayIcon';
import { FlagIcon } from './base/FlagIcon';
import { ForwardChevronIcon } from './base/ForwardChevronIcon';
import { Frame2Icon } from './base/Frame2Icon';
import { FreeTierIcon } from './base/FreeTierIcon';
import { GenerateIcon } from './base/GenerateIcon';
import { GenerateLoadingIcon } from './base/GenerateLoadingIcon';
import { GenerationSetIcon } from './base/GenerationSetIcon';
import { GithubIcon } from './base/GithubIcon';
import { GoogleIcon } from './base/GoogleIcon';
import { InfoCircleIcon } from './base/InfoCircleIcon';
import { InfoIcon } from './base/InfoIcon';
import { InfoLiteIcon } from './base/InfoLiteIcon';
import { JavascriptIcon } from './base/JavascriptIcon';
import { LMIcon } from './base/LMIcon';
import { LatitudeLogo } from './base/LatitudeLogo';
import { LeftNavigationIcon } from './base/LeftNavigationIcon';
import { LeftWireIcon } from './base/LeftWire';
import { LinkOutIcon } from './base/LinkOutIcon';
import { LogoIcon } from './base/LogoIcon';
import { LogoDarkIcon } from './base/LogoDarkIcon';
import { MapIcon } from './base/Map';
import { MastercardIcon } from './base/MastercardLogoSmall';
import { menu } from './base/MenuIcons';
import { ModelsIcon } from './base/ModelsIcon';
import { ModelsWireIcon } from './base/ModelsWire';
import { NavigationBack } from './base/NavigationBack';
import { NavigationBackGrey } from './base/NavigationBackGrey';
import { NavigationOutIcon } from './base/NavigationOutIcon';
import { NewTabIcon } from './base/NewTabIcon';
import { NotificationIcon } from './base/NotificationIcon';
import { OutLinkIcon } from './base/OutLinkIcon';
import { PlayIcon } from './base/PlayIcon';
import { PlusIcon } from './base/PlusIcon';
import { PresetIcon } from './base/PresetIcon';
import { PricingHistogramIcon } from './base/PricingHistogram';
import { PricingIcon } from './base/PricingIcon';
import { PythonIcon } from './base/PythonIcon';
import { QuickStartIcon } from './base/QuickStartIcon';
import { RefreshIcon } from './base/RefreshIcon';
import { RewriteApiIcon } from './base/RewriteApiIcon';
import { RightNavigationIcon } from './base/RightNavigationIcon';
import { RightWireIcon } from './base/RightWire';
import { RocketIcon } from './base/RocketIcon';
import { RoutingIcon } from './base/RoutingIcon';
import { RowNameIcon } from './base/RowNameIcon';
import { SaveIcon } from './base/SaveIcon';
import { ScaleIcon } from './base/ScaleIcon';
import { ScaleTierIcon } from './base/ScaleTierIcon';
import { SearchIcon } from './base/SearchIcon';
import { ShareIcon } from './base/ShareIcon';
import { ShowMoreIcon } from './base/ShowMoreIcon';
import { ShowMoreIcon2 } from './base/ShowMoreIcon2';
import { SnippetIcon } from './base/SnippetIcon';
import { StarIcon } from './base/StarIcon';
import { StripeIcon } from './base/StripeIcon';
import { StudioIcon } from './base/StudioIcon';
import { StudioLogoIcon } from './base/StudioLogoIcon';
import { SummarizationApiIcon } from './base/SummarizationApiIcon';
import { ThreeDotsIcon } from './base/ThreeDotsIcon';
import { TrainIcon } from './base/TrainIcon';
import { TrainingIcon } from './base/TrainingIcon';
import { TrainingSmall } from './base/TrainingSmall';
import { TrashIcon } from './base/TrashIcon';
import { UpRightWireIcon } from './base/UpRightWire';
import { UploadFileAnimation } from './base/UploadFileAnimation';
import { UploadFileIcon } from './base/UploadFileIcon';
import { UploadingDatasetIcon } from './base/UploadingDatasetIcon';
import { VisaIcon } from './base/VisaLogoSmall';
import { WeatherIcon } from './base/WeatherIcon';
import { WikiIcon } from './base/WikiIcon';
import { XCircleIcon } from './base/XCircleIcon';
import { overview } from './overview';
import { presetCatalogue } from './presetCatalogue';
import { SparklesIcon } from './base/SparklesIcon';
import { DividerIcon } from './base/DividerIcon';
import { PlaygroundIcon } from './base/PlaygroundIcon';
import { KeyIcon } from './overview/KeyIcon';

export const icons = {
  presetCatalogue,
  overview,
  menu,
  Account: AccountIcon,
  AccountSmall: AccountIconSmall,
  ArrowsDiagonal2,
  ArrowsDiagonal,
  ArrowsDiagonalMinimize,
  ChevronDown,
  ChevronUp,
  ArrowForwardUp,
  ChevronsRight,
  ChevronsLeft,
  Eye,
  FileDescription,
  Menu2,
  FileText,
  ArrowDownRight,
  Puzzle,
  ArrowUpRight,
  File,
  FilePlus,
  Template,
  SquarePlus,
  Help,
  InfoSquare,
  CircleCheck,
  Plug,
  History,
  ListCheck,
  ExternalLink,
  Checks,
  Loader2,
  FileSymlink,
  Settings,
  MoodEmpty,
  ThumbUp,
  ThumbDown,
  LockOpen,
  Lock,
  Maximize,
  MaximizeOff,
  QuestionMark,
  Link,
  GenerationSet: GenerationSetIcon,
  Map: MapIcon,
  Pyramid,
  ArrowNarrowLeft,
  Archive,
  ArrowNarrowRight,
  ColorSwatch,
  ClockHour4,
  Scale,
  Upload,
  PlayerPlay,
  Send,
  Message,
  AdjustmentsHorizontal,
  ArrowBigLeft,
  ArrowRight,
  ArrowLeft,
  DotsVertical,
  Presentation,
  ScreenShare,
  Rotate2,
  MessageDots,
  MessageCircle,
  Writing,
  Copy2: Copy,
  Trash2: Trash,
  Check2: Check,
  List,
  Table,
  Rainbow,
  ZoomQuestion,
  RefreshDot,
  Refresh,
  DeviceFloppy,
  Directions,
  ListSearch,
  ArrowUpLeft,
  Click,
  FileImport,
  UserCheck,
  Folder,
  Tool,
  Book2,
  Apps,
  AddExpert: AddExpertIcon,
  Add: AddIcon,
  Alert: AlertIcon,
  AlertWarning: AlertWarningIcon,
  ArrowDrop: ArrowDropIcon,
  Wand,
  ArrowForward: ArrowForwardIcon,
  Bulb,
  ArrowUpRight2: ArrowUpRightIcon,
  Eraser,
  BackChevron: BackChevronIcon,
  Back: BackIcon,
  Mail,
  BasicTier: BasicTierIcon,
  Box: BoxIcon,
  Code: CodeIcon,
  ChevronRight,
  ArrowWaveRightUp,
  ChevronLeft,
  Calculator: CalculatorIcon,
  Cancel: CancelIcon,
  ArrowBackUp,
  EditCircle,
  ArrowsSort,
  Celebration: CelebrationIcon,
  CheckCircle: CheckCircleIcon,
  Check: CheckIcon,
  CheckMark: CheckMarkIcon,
  Robot,
  ClearGeneration: ClearGenerationIcon,
  Clear: ClearIcon,
  Close: CloseIcon,
  CollapseQuestion: CollapseQuestionIcon,
  CompletionApi: CompletionApiIcon,
  Bell,
  Copy: CopyIcon,
  CreditCard: CreditCardIcon,
  SettingsAutomation,
  Curl: CurlIcon,
  Currency: CurrencyIcon,
  CustomModels: CustomModelsIcon,
  Database: DatabaseIcon,
  Dataset: DatasetIcon,
  Home,
  FileAnalytics,
  Users,
  ClockHour5,
  Checklist,
  Tags,
  DatasetLarge: DatasetLargeIcon,
  DbTable: DbTableIcon,
  Dismiss: DismissIcon,
  Docs: DocsIcon,
  Download: DownloadIcon,
  DropAnywhereModal: DropAnywhereModalIcon,
  Edit: EditIcon,
  SquareCheck,
  Square,
  EmptyStar: EmptyStarIcon,
  Enter: EnterIcon,
  ExclamationMark: ExclamationMarkIcon,
  ExclamationMark2: ExclamationMarkIcon2,
  ExpandQuestion: ExpandQuestionIcon,
  QuestionMarkIcon: QuestionMarkIcon,
  OneTwoThree,
  BorderAll,
  ExpandRow: ExpandRowIcon,
  Eye2: EyeIcon,
  FeedbackBad: FeedbackBadIcon,
  FeedbackCheck: FeedbackCheckIcon,
  FeedbackGreat: FeedbackGreatIcon,
  FeedbackOkay: FeedbackOkayIcon,
  Flag: FlagIcon,
  ForwardChevron: ForwardChevronIcon,
  Frame2: Frame2Icon,
  FreeTier: FreeTierIcon,
  Generate: GenerateIcon,
  GenerateLoading: GenerateLoadingIcon,
  Github: GithubIcon,
  Google: GoogleIcon,
  Info: InfoIcon,
  InfoCircle: InfoCircleIcon,
  InfoLite: InfoLiteIcon,
  Javascript: JavascriptIcon,
  LM: LMIcon,
  Scale2: ScaleIcon,
  LatitudeLogo: LatitudeLogo,
  LeftNavigation: LeftNavigationIcon,
  LeftWire: LeftWireIcon,
  LinkOut: LinkOutIcon,
  Logo: LogoIcon,
  LogoDark: LogoDarkIcon,
  Mastercard: MastercardIcon,
  Models: ModelsIcon,
  ModelsWire: ModelsWireIcon,
  NavigationBack: NavigationBack,
  NavigationBackGrey: NavigationBackGrey,
  NavigationOut: NavigationOutIcon,
  NewTab: NewTabIcon,
  Notification: NotificationIcon,
  OutLink: OutLinkIcon,
  Play: PlayIcon,
  Plus: PlusIcon,
  Pricing: PricingIcon,
  Preset: PresetIcon,
  PricingHistogram: PricingHistogramIcon,
  Python: PythonIcon,
  QuickStart: QuickStartIcon,
  RefreshIcon: RefreshIcon,
  RewriteApi: RewriteApiIcon,
  RightNavigation: RightNavigationIcon,
  RightWire: RightWireIcon,
  Routing: RoutingIcon,
  Rocket: RocketIcon,
  RowName: RowNameIcon,
  Save: SaveIcon,
  ScaleTier: ScaleTierIcon,
  Science: ScienceOutlined,
  Search: SearchIcon,
  Share: ShareIcon,
  ShowMore: ShowMoreIcon,
  ShowMore2: ShowMoreIcon2,
  Snippet: SnippetIcon,
  Star: StarIcon,
  Stripe: StripeIcon,
  Studio: StudioIcon,
  StudioLogo: StudioLogoIcon,
  SummarizationApi: SummarizationApiIcon,
  ThreeDots: ThreeDotsIcon,
  Train: TrainIcon,
  Training: TrainingIcon,
  TrainingSmall: TrainingSmall,
  Trash: TrashIcon,
  UploadFile: UploadFileIcon,
  UploadFileAnimation: UploadFileAnimation,
  UploadingDataset: UploadingDatasetIcon,
  UpRightWire: UpRightWireIcon,
  Visa: VisaIcon,
  Weather: WeatherIcon,
  Wiki: WikiIcon,
  XCircle: XCircleIcon,
  DoubleThumbUp: DoubleThumbUpIcon,
  Sparkles: SparklesIcon,
  Divider: DividerIcon,
  Playground: PlaygroundIcon,
  Key: KeyIcon,
  ConversationalRag: ConversationalRagIcon,

  // material icons
  FileDownloadOutlined,
  FileUploadOutlined,
  PictureAsPdf,
  OpenInNewOutlined,
  PlayArrowOutlined,
  WarningOutlined,
  PlayArrow,
};
