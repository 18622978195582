import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const WrapperLinkButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: underline;
  color: #753fea;
  text-decoration-color: #753fea;

  &.disabled {
    cursor: not-allowed;
    color: #a8aeb8;
    pointer-events: none;
    text-decoration: none;
  }
`;

export const WrapperLink = styled.a`
  text-decoration: underline;
  color: #753fea;
  text-decoration-color: #753fea;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    color: #a8aeb8;
    pointer-events: none;
    text-decoration: none;
  }
`;

export const WrapperRouterLink = styled(Link)`
  text-decoration: underline;
  color: #753fea;
  text-decoration-color: #753fea;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    color: #a8aeb8;
    pointer-events: none;
    text-decoration: none;
  }
`;
