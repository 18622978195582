import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f5f5f5;
  height: 60px;
  background-color: ${tokens.primary.backgroundHover};
  flex-wrap: wrap;
`;

export const Field = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: #666;
  border-right: 1px solid #aaa;
  justify-content: center;
  min-width: 33%;
  border-top: 1px solid #aaa;

  &:nth-child(3n) {
    border-right: none;
  }
`;

export const Key = styled.div``;

export const Value = styled.div`
  text-align: center;
  border-radius: 3px;
  padding-left: 7px;
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
`;

export const Duration = styled.div`
  position: absolute;
  top: 63px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-weight: 500;
  padding: 1px 6px;
  right: 2px;
  border-radius: 3px;
`;
