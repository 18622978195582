import { GENERIC_ERROR_MESSAGE } from '../../constants/constants';
import { CustomModelType, ModelEndpointListElement } from '../../data-types/Model';
import {
  CHOOSE_HYPER_PARAMETERS,
  CustomModelsActionTypes,
  DELETE_MODEL_SUCCESS,
  FETCH_MODELS_FAILURE,
  FETCH_MODELS_STARTED,
  FETCH_MODELS_SUCCESS,
  FETCH_MODEL_COST_FAILURE,
  FETCH_MODEL_COST_STARTED,
  FETCH_MODEL_COST_SUCCESS,
  ModelsUploadingErrorType,
  NEW_CUSTOM_MODEL,
  POST_MODEL_FAILURE,
  POST_MODEL_STARTED,
  POST_MODEL_SUCCESS,
  RESET_MODELS_PAGE_STATE,
  SET_SELECTED_CUSTOM_MODEL,
  SHOW_DELETE_MODEL_DIALOG,
} from '../actionTypes/customModelsActionTypes';

export enum ModelsPageState {
  EMPTY_STATE = 'EMPTY_STATE',
  SHOW_MODELS = 'SHOW_MODELS',
  UPLOADING_MODEL_IN_PROGRESS = 'UPLOADING_MODEL_IN_PROGRESS',
  UPLOADING_DATASET_IN_PROGRESS = 'UPLOADING_DATASET_IN_PROGRESS',
  DATASET_UNKNOWN_EXTENSION_ERROR = 'DATASET_UNKNOWN_EXTENSION_ERROR',
  DATASET_ILLEGAL_FORMAT_ERROR = 'DATASET_ILLEGAL_FORMAT_ERROR',
  DATASET_MIN_ROWS_ERROR = 'DATASET_MIN_ROWS_ERROR',
  DATASET_COLUMNS_NAME_NOT_EXIST_ERROR = 'DATASET_COLUMNS_NAME_NOT_EXIST_ERROR',
  DATASET_MULTIPLE_WHITESPACE_ERROR = 'DATASET_MULTIPLE_WHITESPACE_ERROR',
  CHOOSE_DATASET = 'CHOOSE_DATASET',
  SET_PARAMETERS = 'SET_PARAMETERS',
  MODEL_GENERAL_ERROR = 'MODEL_GENERAL_ERROR',
  DATASET_TO_LONG_ROWS_ERROR = 'DATASET_TO_LONG_ROWS_ERROR',
  DATASET_COLUMNS_NOT_SPECIFIED_ERROR = 'DATASET_COLUMNS_NOT_SPECIFIED_ERROR',
  INVALID_DATASET_NAME_ERROR = 'INVALID_DATASET_NAME_ERROR',
  DUPLICATE_DATASET_NAME_ERROR = 'DUPLICATE_DATASET_NAME_ERROR',
  CREDIT_EXCEEDED = 'CREDIT_EXCEEDED',
  TRIAL_ENDED = 'TRIAL_ENDED',
  EMPTY_COMPLETION = 'EMPTY_COMPLETION',
}

export interface CustomModelsState {
  modelsEndpointResponse: ModelEndpointListElement[] | null;
  fetchModelsError: string | undefined;
  fetchModelsInProgress: boolean;
  uploadModelInProgress: boolean;
  fetchModelCostInProgress: boolean;
  uploadModelError: string | undefined;
  datasetName: string | undefined;
  datasetId: string | undefined;
  modelType: CustomModelType | undefined;
  sizeBytes: number | undefined;
  modelCost: number | undefined;
  modelEpochs: number | undefined;
  state: ModelsPageState | undefined;
  showDeleteModelDialog: boolean;
}

const initialState: CustomModelsState = {
  modelsEndpointResponse: null,
  fetchModelsError: undefined,
  fetchModelsInProgress: false,
  uploadModelInProgress: false,
  fetchModelCostInProgress: false,
  uploadModelError: undefined,
  datasetName: undefined,
  datasetId: undefined,
  modelType: undefined,
  sizeBytes: undefined,
  modelCost: undefined,
  modelEpochs: undefined,
  state: undefined,
  showDeleteModelDialog: false,
};

export function reducer(state = initialState, action: CustomModelsActionTypes): CustomModelsState {
  function getUploadModelFailureState(modelsUploadingError: ModelsUploadingErrorType | undefined) {
    switch (modelsUploadingError) {
      case ModelsUploadingErrorType.CREDIT_EXCEEDED:
        return ModelsPageState.CREDIT_EXCEEDED;
      case ModelsUploadingErrorType.TRIAL_ENDED:
        return ModelsPageState.TRIAL_ENDED;
      default:
        return ModelsPageState.MODEL_GENERAL_ERROR;
    }
  }

  switch (action.type) {
    case FETCH_MODELS_STARTED:
      return { ...state, fetchModelsError: undefined, fetchModelsInProgress: true };
    case FETCH_MODELS_FAILURE:
      return {
        ...state,
        fetchModelsError: action.error || GENERIC_ERROR_MESSAGE,
        fetchModelsInProgress: false,
      };
    case FETCH_MODELS_SUCCESS: {
      const stateByLength = action.payload.length
        ? ModelsPageState.SHOW_MODELS
        : ModelsPageState.EMPTY_STATE;
      return {
        ...state,
        modelsEndpointResponse: action.payload.filter(
          (i: ModelEndpointListElement) => i.modelMetadata !== null
        ),
        fetchModelsError: undefined,
        fetchModelsInProgress: false,
        state: action.shouldApplyStateChange ? stateByLength : state.state,
      };
    }
    case POST_MODEL_STARTED:
      return {
        ...state,
        uploadModelInProgress: true,
        uploadModelError: undefined,
        state: ModelsPageState.UPLOADING_MODEL_IN_PROGRESS,
      };
    case POST_MODEL_SUCCESS:
      return {
        ...state,
        uploadModelInProgress: false,
        uploadModelError: undefined,
        state: ModelsPageState.SHOW_MODELS,
        modelsEndpointResponse:
          state.modelsEndpointResponse != null && state.modelsEndpointResponse.length > 0
            ? [...state.modelsEndpointResponse, action.payload]
            : [action.payload],
      };
    case POST_MODEL_FAILURE:
      return {
        ...state,
        uploadModelInProgress: false,
        uploadModelError: action.uploadModelError,
        state: getUploadModelFailureState(action.uploadModelError),
      };
    case RESET_MODELS_PAGE_STATE:
      return {
        ...state,
        uploadModelInProgress: false,
        uploadModelError: undefined,
        state:
          state.modelsEndpointResponse != null && state.modelsEndpointResponse.length > 0
            ? ModelsPageState.SHOW_MODELS
            : ModelsPageState.EMPTY_STATE,
      };
    case NEW_CUSTOM_MODEL:
      return {
        ...state,
        state: ModelsPageState.CHOOSE_DATASET,
      };
    case CHOOSE_HYPER_PARAMETERS:
      return {
        ...state,
        datasetName: action.datasetDetails.datasetName,
        datasetId: action.datasetDetails.datasetId,
        modelType: action.datasetDetails.modelType,
        sizeBytes: action.datasetDetails.sizeBytes,
        state: ModelsPageState.SET_PARAMETERS,
      };
    case FETCH_MODEL_COST_STARTED:
      return {
        ...state,
        fetchModelCostInProgress: true,
      };
    case FETCH_MODEL_COST_FAILURE:
      return {
        ...state,
        fetchModelCostInProgress: false,
        state: ModelsPageState.MODEL_GENERAL_ERROR,
      };
    case FETCH_MODEL_COST_SUCCESS:
      return {
        ...state,
        modelCost: action.cost,
        modelEpochs: action.modelEpochs,
        fetchModelCostInProgress: false,
      };
    case SET_SELECTED_CUSTOM_MODEL:
      return {
        ...state,
        modelType: action.customModelType,
      };
    case SHOW_DELETE_MODEL_DIALOG: {
      const { showDeleteModelDialog } = action;
      return {
        ...state,
        showDeleteModelDialog,
      };
    }
    case DELETE_MODEL_SUCCESS: {
      const filteredModels = state.modelsEndpointResponse
        ? state.modelsEndpointResponse.filter((model) => model.name !== action.name)
        : [];
      return {
        ...state,
        modelsEndpointResponse: filteredModels,
        state:
          filteredModels?.length > 0 ? ModelsPageState.SHOW_MODELS : ModelsPageState.EMPTY_STATE,
      };
    }
    default:
      return state;
  }
}
