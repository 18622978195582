import { actions, selectors } from '@ai21/studio-store';
import { useTableCallbacks } from '@ai21/studio-tables';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { tables } from '../../_definitions/tables';
import { EpochSelection } from './EpochSelection';

export type EpochSelectionContainerProps = {
  id: string;
  onCancel: () => void;
};

export function EpochSelectionContainer(props: EpochSelectionContainerProps) {
  const { id } = props;
  const dispatch = useDispatch();
  const model = useSelector(selectors.base.$currentModel);
  const tableModelInfo = useSelector(selectors.tables.$modelsInfo);
  const tableModelEpochs = useSelector(selectors.tables.$epochs);
  const loading = useSelector(selectors.raw.$rawLoading);

  useMount(() => {
    dispatch(actions.currentIds.patch({ modelId: id }));

    dispatch({
      type: 'EPOCHS',
      verb: 'getEpochs',
      id,
    });
  });

  const callbacks = useTableCallbacks(dispatch, tables, {
    onCancel: props.onCancel,
  });

  const data = useMemo(
    () => ({
      model,
      tableModelInfo,
      tableModelEpochs,
      loading: loading.epochs,
    }),
    [model, tableModelInfo, tableModelEpochs, loading]
  );

  return <EpochSelection data={data} callbacks={callbacks} />;
}

export default EpochSelectionContainer;
