export function PricingHistogramIcon() {
  return (
    <svg
      width='161'
      height='96'
      viewBox='0 0 161 96'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path stroke='#C3C6CE' d='M0 95.5h161' />
      <path
        d='M80 95H64V5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v90zM98 95H82V31a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v64z'
        fill='#753FEA'
      />
      <path
        d='M62 95H46V40a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v55zM116 95h-16V46a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v49z'
        fill='#ED4B82'
      />
      <path
        d='M44 95H28V27a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v68zM133.687 95h-16V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v93z'
        fill='#F8B5CC'
      />
      <path
        d='M26 95H10V48a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v47zM152 95h-16V16a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v79z'
        fill='#FDEBF1'
      />
    </svg>
  );
}
