import { get } from 'lodash';
import { IncomingNetworkEvent } from '../../analytics.types';
import { logEvent } from '../../analytics.amplitude';

function* ApiSuccess(data: IncomingNetworkEvent) {
  const endpoint = get(data, 'endpoint', '');
  const params = get(data, 'response.request.data', {});
  logEvent(
    'FeedbackSubmitted',
    {
      endpoint,
      ...params,
    },
    true
  );
}

export const root = {
  'feedback.send': {
    success: ApiSuccess,
  },
};
