import { PricingInfo } from '../../data-types/Payment';
import {
  FETCH_PRICING_FAILED,
  FETCH_PRICING_STARTED,
  FETCH_PRICING_SUCCESS,
  PricingActionTypes,
} from '../actionTypes/pricingActionTypes';

export interface PricingState {
  pricingInfo: PricingInfo | null;
  errorMessage: string | null;
}

const initialState: PricingState = {
  pricingInfo: null,
  errorMessage: null,
};

export function reducer(state = initialState, action: PricingActionTypes): PricingState {
  const { type } = action;
  switch (type) {
    case FETCH_PRICING_FAILED: {
      const { error } = action;
      return { ...initialState, errorMessage: error };
    }
    case FETCH_PRICING_STARTED: {
      return { ...initialState, errorMessage: null };
    }
    case FETCH_PRICING_SUCCESS: {
      const { pricingInfo } = action;
      return { ...initialState, errorMessage: null, pricingInfo };
    }
    default: {
      return state;
    }
  }
}
