import styled from 'styled-components';
import IconButton from '../IconButton/IconButton';

export const Wrapper = styled.div`
  flex: 1;
`;

export const Button = styled(IconButton)`
  border: 1px solid #ccd0d5;
  background-color: none;
  width: 36px;
  height: 35px;
  margin: 4px;
  &.hover {
    background-color: #f4f5f7;
  }

  &.selected {
    background-color: #e6eaec;
  }

  svg {
    width: 20px;
    stroke-width: 1.5px;
    color: #626b78;
  }
`;
