import { Components, Theme } from '@mui/material';
import { tokens } from './theme.tokens';
import { primitives } from '../theme/theme.primitives';

// version 0.0.1
// : Components<Omit<Theme, 'components'>>
export const components: any = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        color: tokens.text.primary,
        backgroundColor: tokens.component.pageBackgroundDefault,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        boxSizing: 'content-box',
        fontWeight: 'normal',
        webkitFontSmoothing: 'antialiased',
        width: '100vw',
        overflowX: 'hidden',
      },
    },
  },

  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: primitives.radius.medium,
            borderColor: tokens.primary.borderDefault,
          },
        },

        '& .MuiOutlinedInput-input::placeholder': {
          color: tokens.text.tertiary,
          opacity: 1,
        },
      },
    },
  },
  Input: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: primitives.radius.medium,
          },
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiLink: {
    styleOverrides: {
      underlineNone: {
        '&:hover': {
          opacity: 0.75,
        },
      },
      underlineHover: {
        '&:hover': {
          opacity: 0.75,
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        transition: ['all 120ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'].join(','),
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'Inter',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textTransform: 'none',
        textAlign: 'center',
        borderRadius: primitives.radius.medium,
        '.MuiButton-startIcon': {
          marginLeft: 0,
        },
        '.MuiButton-endIcon': {
          marginRight: 0,
        },
      },

      //----SMALL BUTTON----
      sizeSmall: {
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '125%',
        letterSpacing: 'normal',
        padding: '6px 8px',
        '.MuiButton-startIcon': {
          marginRight: '4px',
          svg: {
            width: '18px',
            height: '18px',
          },
        },
        '.MuiButton-endIcon': {
          marginLeft: '4px',
          svg: {
            width: '18px',
            height: '18px',
          },
        },
      },
      //----MEDIUM BUTTON----
      sizeMedium: {
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '170%',
        letterSpacing: 'normal',
        padding: '6px 10px',
        '.MuiButton-startIcon': {
          marginRight: '6px',
          svg: {
            width: '20px',
            height: '20px',
          },
        },
        '.MuiButton-endIcon': {
          marginLeft: '6px',
          svg: {
            width: '20px',
            height: '20px',
          },
        },
      },
      //----LARGE BUTTON----
      sizeLarge: {
        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '150%',
        letterSpacing: 'normal',
        padding: '8px 10px',
        '.MuiButton-startIcon': {
          marginRight: '8px',
          svg: {
            width: '24px',
            height: '24px',
          },
        },
        '.MuiButton-endIcon': {
          marginLeft: '8px',
          svg: {
            width: '24px',
            height: '24px',
          },
        },
      },
      //----X Large BUTTON----
      sizeXLarge: {
        fontSize: '18px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '135%',
        letterSpacing: 'normal',
        padding: '12px 12px',
        '.MuiButton-startIcon': {
          marginRight: '8px',
          svg: {
            width: '24px',
            height: '24px',
          },
        },
        '.MuiButton-endIcon': {
          marginLeft: '8px',
          svg: {
            width: '24px',
            height: '24px',
          },
        },
      },

      //----PRIMARY-------------------------------------------------------------------------------------------------------------------
      //----Primary/Contained----
      containedPrimary: {
        backgroundColor: tokens.primary.mainDefault,
        color: tokens.primary.mainContrast,
        '&:hover': {
          backgroundColor: tokens.primary.mainHover,
        },
        '&:active': {
          backgroundColor: tokens.primary.mainActive,
        },
        '&:disabled': {
          color: tokens.disabled.text,
          backgroundColor: tokens.disabled.background,
        },
      },

      //----Primary/Outlined----
      outlinedPrimary: {
        backgroundColor: tokens.primary.backgroundDefault,
        color: tokens.primary.mainDefault,
        border: `1px solid ${tokens.primary.borderDefault}`,
        '&:hover': {
          backgroundColor: tokens.primary.backgroundHover,
          border: `1px solid ${tokens.primary.borderHover}`,
        },
        '&:active': {
          backgroundColor: tokens.primary.backgroundActive,
          border: `1px solid ${tokens.primary.borderActive}`,
        },
        '&:disabled': {
          border: `1px solid ${tokens.disabled.border}`,
          color: tokens.disabled.text,
          backgroundColor: tokens.disabled.background,
        },
      },

      //----Primary/Text Flat button----
      textPrimary: {
        backgroundColor: tokens.primary.backgroundDefault,
        color: tokens.primary.mainDefault,
        '&:hover': {
          backgroundColor: tokens.primary.backgroundHover,
        },
        '&:active': {
          backgroundColor: tokens.primary.backgroundActive,
        },
        '&:disabled': {
          color: tokens.disabled.text,
          backgroundColor: tokens.disabled.background,
        },
      },

      //----SECONDARY-------------------------------------------------------------------------------------------------------------------
      //----Secondary/Contained----
      containedSecondary: {
        backgroundColor: tokens.secondary.mainDefault,
        color: tokens.secondary.mainContrast,
        border: `1px solid ${tokens.secondary.mainDefault}`,
        boxSizing: 'border-box',
        borderColor: tokens.secondary.mainDefault,
        '&:hover': {
          backgroundColor: tokens.secondary.mainHover,
        },
        '&:active': {
          backgroundColor: tokens.secondary.mainActive,
        },
        '&:disabled': {
          color: tokens.disabled.text,
          backgroundColor: tokens.disabled.background,
        },
      },

      //----Secondary/Outlined----
      outlinedSecondary: {
        backgroundColor: tokens.primary.backgroundDefault,
        color: tokens.primary.mainDefault,
        borderColor: tokens.primary.borderDefault,
        '&:hover': {
          backgroundColor: tokens.primary.backgroundHover,
          borderColor: tokens.primary.borderHover,
        },
        '&:active': {
          backgroundColor: tokens.primary.backgroundActive,
          borderColor: tokens.primary.borderActive,
        },
        '&:disabled': {
          border: `1px solid ${tokens.disabled.border}`,
          color: tokens.disabled.text,
          backgroundColor: tokens.disabled.background,
        },
      },

      //----Secondary/Text Flat button----
      textSecondary: {
        backgroundColor: tokens.primary.backgroundDefault,
        color: tokens.primary.mainDefault,
        '&:hover': {
          backgroundColor: tokens.primary.backgroundHover,
        },
        '&:active': {
          backgroundColor: tokens.primary.backgroundActive,
        },
        '&:disabled': {
          color: tokens.disabled.text,
          backgroundColor: tokens.disabled.background,
        },
      },
    },
  },
  //-----------------------------------------------------------------------------------------------------------------------
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: tokens.primary.mainDefault,
        borderRadius: primitives.radius.medium,

        '&:hover': {
          backgroundColor: tokens.primary.backgroundHover,
          // border: `1.5px solid ${tokens.primary.borderHover}`,
        },
        '&:active': {
          backgroundColor: tokens.primary.backgroundActive,
          // border: `1.5px solid ${tokens.primary.borderActive}`,
        },
        '&:disabled': {
          color: tokens.disabled.text,
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paperWidthSm: {
        maxWidth: '100%',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        padding: 12,
      },
      root: {
        padding: '2px !important',
        '&:hover': {
          '& fieldset': {
            borderColor: `${tokens.primary.borderActive} !important`,
            borderWidth: '1px !important',
          },
        },
        '&:focus-within': {
          '& fieldset': {
            borderColor: `${tokens.primary.mainDefault} !important`,
            borderWidth: '2px !important',
          },
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        top: -3,
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        padding: '8px 8px 8px 12px',
        '&:focus': {
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        '&::placeholder': {
          color: tokens.text.tertiary,
          opacity: 1,
        },
      },
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: primitives.radius.medium,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: tokens.text.primary,
        backgroundColor: tokens.primary.backgroundDefault,
        height: 38,
        '&:hover': {
          backgroundColor: tokens.primary.backgroundHover,
          // borderRadius: primitives.radius.medium,
        },
        '&:active': {
          backgroundColor: tokens.primary.backgroundActive,
          // borderRadius: primitives.radius.medium,
        },
        '&.Mui-selected': {
          // borderRadius: primitives.radius.medium,
          backgroundColor: tokens.primary.backgroundDefault,
          color: tokens.primary.mainDefault,
          '&:hover': {
            // backgroundColor: tokens.primary.backgroundDefault,
            backgroundColor: tokens.primary.backgroundActive,
          },
          '&:focus': {
            backgroundColor: tokens.primary.backgroundHover,
          },
          '&:active': {
            backgroundColor: tokens.primary.backgroundHover,
          },
        },
      },
    },
  },
  MuiPopper: {
    styleOverrides: {
      root: {
        '.MuiPaper-root': {
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.08), 0px 3px 14px 2px rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      option: {
        color: tokens.text.primary,
        backgroundColor: tokens.primary.backgroundDefault,
        height: 38,
        '&:hover': {
          backgroundColor: tokens.primary.backgroundHover,
        },
        '&:active': {
          backgroundColor: tokens.primary.backgroundActive,
        },
        '&.Mui-selected': {
          backgroundColor: tokens.primary.backgroundDefault,
          color: tokens.primary.mainDefault,
          '&:hover': {
            backgroundColor: tokens.primary.backgroundActive,
          },
          '&:focus': {
            backgroundColor: tokens.primary.backgroundHover,
          },
          '&:active': {
            backgroundColor: tokens.primary.backgroundHover,
          },
        },
        '&[aria-selected="true"]': {
          // padding: 44,
          color: tokens.primary.mainDefault,
          backgroundColor: tokens.primary.backgroundHover,
          '&:hover': {
            backgroundColor: tokens.primary.backgroundHover,
          },
          '&:focus': {
            backgroundColor: tokens.primary.backgroundHover,
          },
          '&:active': {
            backgroundColor: tokens.primary.backgroundHover,
          },
        },
      },
    },
  },

  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 12,
        // This will add shadow to the autocomplete dropdown menu container, but also to other components - how do we customize only for specific component?
        // boxShadow:
        //   '0px 5px 5px -3px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.08), 0px 3px 14px 2px rgba(0, 0, 0, 0.04)',
      },

      elevation1: {},
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {},
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: 0,
      },
      content: {
        margin: 'none',
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: 'none',
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        color: tokens.text.primary,
        backgroundColor: tokens.primary.backgroundDefault,
        '&:hover': {
          backgroundColor: tokens.primary.backgroundHover,
        },
        '&:active': {
          backgroundColor: tokens.primary.backgroundActive,
        },
        '&.Mui-selected': {
          backgroundColor: tokens.primary.backgroundActive,
          '&:hover': {
            backgroundColor: tokens.primary.backgroundActive,
          },
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
        margin: '8px 0px 8px 0px',
        // backgroundColor: tokens.primary.backgroundDefault,
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h1: 'h1',
        h1light: 'h1',
        h1bold: 'h1',
        h2: 'h2',
        h2light: 'h2',
        h2bold: 'h2',
        h3: 'h3',
        h3light: 'h3',
        h3bold: 'h3',
        h4: 'h4',
        h4light: 'h4',
        h4bold: 'h4',
        h5: 'h5',
        h5light: 'h5',
        h5bold: 'h5',
        h6: 'h6',
        h6light: 'h6',
        h6bold: 'h6',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      gutters: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      regular: {
        minHeight: '56px !important',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: 'black',
        fontSize: '14px',
      },
      tooltipPlacementTop: {
        margin: '6px 0 !important',
      },
    },
    defaultProps: {
      placement: 'top',
      enterDelay: '1000',
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        svg: {
          width: '21px',
          height: '21px',
          strokeWidth: '1.5px',
        },
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderCollapse: 'inherit',
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        borderLeft: 'none',
        padding: '10px 20px',
        fontSize: 15,
        fontFamily: 'Inter',
      },
    },
  },

  MuiChip: {
    styleOverrides: {
      root: {
        fontFamily: 'Inter',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 500,
        // backgroundColor: '#E6EAEC',
        backgroundColor: tokens.primary.backgroundHover,
        border: `1px solid ${tokens.primary.borderDefault}`,
      },
      outlined: {
        border: `1px solid ${tokens.primary.borderDefault}`,
      },
      sizeSmall: {
        fontSize: '10px',
        fontFamily: 'Inter',
        lineHeight: '16px',
        fontWeight: 500,
        backgroundColor: '#E6EAEC',
      },
    },
  },
};
