import { alpha } from '@mui/material';
import { tokens } from './theme.tokens';

// version 0.0.1

export const palette: Json = {
  mode: 'light',
  primary: {
    main: tokens.primary.mainDefault,
    dark: tokens.primary.mainDark,
    light: tokens.primary.mainLight,
    contrastText: tokens.primary.mainContrast,
    ...tokens.primary,
  },
  secondary: {
    main: tokens.primary.mainDefault,
    dark: tokens.primary.mainDark,
    light: tokens.primary.mainLight,
    contrastText: tokens.primary.mainContrast,
    ...tokens.secondary,
  },
  text: {
    primary: tokens.text.primary,
    secondary: tokens.text.secondary,
    disabled: tokens.text.disabled,
    tertiary: tokens.text.tertiary,
    placeholder: tokens.text.placeholder,
  },
  neutral: {
    ...tokens.neutral,
  },
  beige: {
    ...tokens.info,
  },
  action: {
    active: '#A4A4AC',
    hover: alpha('#A4A4AC', 0.12),
    focus: alpha('#5554641F', 0.12),
  },
  background: {
    paper: tokens.component.pageBackgroundDefault,
    default: tokens.component.pageBackgroundDefault,
  },
  divider: tokens.component.dividerDefault,
  error: {
    main: tokens.error.mainDefault,
    dark: tokens.error.mainDark,
    light: tokens.error.mainLight,
    contrastText: tokens.error.mainContrast,
    ...tokens.error,
  },
  warning: {
    main: tokens.warning.mainDefault,
    dark: tokens.warning.mainDark,
    light: tokens.warning.mainLight,
    contrastText: tokens.warning.mainContrast,
    ...tokens.warning,
  },
  info: {
    main: tokens.info.mainDefault,
    dark: tokens.info.mainDark,
    light: tokens.info.mainLight,
    contrastText: tokens.warning.mainContrast,
    ...tokens.info,
  },
  success: {
    main: tokens.success.mainDefault,
    dark: tokens.success.mainDark,
    light: tokens.success.mainLight,
    contrastText: tokens.success.mainContrast,
    ...tokens.success,
  },
  disabled: {
    ...tokens.disabled,
  },
  pink: {
    main: tokens.pink.mainDefault,
    dark: tokens.pink.mainDefault,
  },
};
