import { fork } from 'saga-ts';
import { root as navigation } from './helpers/navigation';
import { root as auth } from './saga.auth';
import { root as complete } from './saga.complete';
import { root as reset } from './saga.reset';
import { root as signUpWithEmail } from './saga.email';
import { root as login } from './saga.login';
import { root as logout } from './saga.logout';
import { root as start } from './saga.start';

export function* loginSagas() {
  yield* fork(auth);
  yield* fork(complete);
  yield* fork(login);
  yield* fork(logout);
  yield* fork(reset);
  yield* fork(signUpWithEmail);
  yield* fork(start);
  yield* fork(navigation);
}
