export function QuickStartIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5 2.25v1.5H12v-1.5H6v1.5H4.5v-1.5H3v13.5h1.5v-1.5H6v1.5h6v-1.5h1.5v1.5H15V2.25h-1.5zM6 12.75H4.5v-1.5H6v1.5zm0-3H4.5v-1.5H6v1.5zm0-3H4.5v-1.5H6v1.5zm7.5 6H12v-1.5h1.5v1.5zm0-3H12v-1.5h1.5v1.5zm0-3H12v-1.5h1.5v1.5z'
        fill='#2979FF'
      />
    </svg>
  );
}
