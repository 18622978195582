export function CompletionApiIcon() {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='42' height='42' rx='4' fill='#FDEBF1' />
      <path
        d='M7 35H14L32.375 16.625C34.308 14.692 34.308 11.558 32.375 9.62504C30.442 7.69204 27.308 7.69204 25.375 9.62504L7 28V35'
        stroke='#D00B4E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.625 11.375L30.625 18.375'
        stroke='#D00B4E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
