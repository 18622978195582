import { createSelector } from 'reselect';
import { IStudioStore } from '../types';
import * as raw from './selectors.raw';
import { get } from 'lodash';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};
export type Json = Record<string, any>;

export const $layout = createSelector(raw.$rawCurrentIds, (currentIds) => {
  const { layoutId = '' } = currentIds;
  return layoutId;
});

export const $family = createSelector(raw.$rawCurrentIds, (currentIds) => {
  const { familyId = '' } = currentIds;
  return familyId;
});

export const $isLego = createSelector($family, (family) => {
  return family === 'task-specific-models';
});

export const $boxes = createSelector(raw.$rawBoxes, $layout, (boxes, layout) => {
  return Object.values(boxes).filter((box) => box.layoutId === layout);
});

export const $outputBoxes = createSelector($boxes, (boxes) => {
  return boxes.filter((box) => {
    const { id } = box;
    return id.includes('-output');
  });
});

export const $outputBox = createSelector($outputBoxes, (boxes) => {
  return boxes[0];
});

export const $inputBoxes = createSelector($boxes, (boxes) => {
  return boxes.filter((box) => {
    const { id, values } = box;
    return values && 'prompt' in values && id.includes('-input');
  });
});

export const $inputBox = createSelector($inputBoxes, (boxes) => {
  return boxes[0];
});

export const $contextBoxes = createSelector($boxes, (boxes) => {
  return boxes.filter((box) => {
    const { id } = box;
    return id.includes('-context');
  });
});

export const $contextBox = createSelector($contextBoxes, (boxes) => {
  return boxes[0];
});

export const $context = createSelector($contextBox, (box) => {
  return get(box, 'values.prompt', '');
});

export const $loggerBoxes = createSelector($boxes, (boxes) => {
  return boxes.filter((box) => {
    const { id, values } = box;
    return values && 'logs' in values && id.includes('-logger');
  });
});

export const $paramsBox = createSelector($boxes, (boxes) => {
  return boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-params');
  });
});

export const $params = createSelector($paramsBox, (box) => {
  if (!box) {
    return {};
  }

  return get(box, 'values', {});
});

export const $legoParamsBox = createSelector($boxes, (boxes) => {
  return boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-type');
  });
});

export const $legoParams = createSelector($legoParamsBox, (box) => {
  if (!box) {
    return {};
  }
  return get(box, 'values', {});
});

export const $editorBox = createSelector($boxes, (boxes) => {
  return boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-editor');
  });
});

export const $chatBox = createSelector($boxes, (boxes) => {
  return boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-main');
  });
});

export const $instructionBox = createSelector($boxes, (boxes) => {
  return boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-systemInstructions');
  });
});

export const $ape = createSelector($boxes, raw.$rawPas, (boxes, pas) => {
  const box = boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-ape');
  });

  if (!box) {
    return undefined;
  }

  const { id: boxId, values } = box;
  const { paId } = values ?? {};

  const pa = pas[paId];

  return {
    boxId,
    ...values,
    pa,
  };
});

export const $collection = createSelector($boxes, (boxes) => {
  const box = boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-collection');
  });

  if (!box) {
    return undefined;
  }

  const { id: boxId, values } = box;

  return {
    boxId,
    ...values,
  };
});

export const $optimizeBox = createSelector($boxes, (boxes) => {
  return boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-optimize');
  });
});

export const $variablesBox = createSelector($boxes, (boxes) => {
  return boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-variables');
  });
});

export const $variables = createSelector($variablesBox, (box) => {
  if (!box) {
    return undefined;
  }

  const { values } = box;
  return values;
});

export const $instructions = createSelector($boxes, (boxes) => {
  const box = boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-systemInstructions');
  });

  if (!box) {
    return undefined;
  }

  const { values } = box;
  return values?.content;
});

export const $mimic = createSelector($boxes, (boxes) => {
  const box = boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-mimic');
  });

  if (!box) {
    return undefined;
  }

  const { values } = box;
  return values?.content;
});

export const $output = createSelector($outputBoxes, (boxes) => {
  const box = boxes[0];

  if (!box) {
    return undefined;
  }

  const { values } = box;
  return values?.content;
});

export const $shots = createSelector($boxes, (boxes) => {
  const box = boxes.find((box) => {
    const { id } = box;
    return id.endsWith('-shots');
  });

  if (!box) {
    return undefined;
  }

  const { values } = box;
  const { rows = [] } = values ?? {};

  return rows.map((row: Json) => {
    const { input, output } = row;
    return ['input: ', input, '\n', 'output: ', output, '\n'].join('');
  });
});

export const $isChat = createSelector($layout, (layout) => {
  return layout.includes('chat');
});

export const $isMimic = createSelector($layout, (layout) => {
  return layout.includes('mimic');
});

export const $meta = createSelector(
  $layout,
  $family,
  $variables,
  $instructions,
  $shots,
  $isChat,
  $isMimic,
  $legoParams,
  (layout, family, variables, instructions, shots, isChat, isMimic, legoParams) => {
    return {
      layout,
      family,
      variables,
      instructions,
      shots,
      isChat,
      isMimic,
      legoParams,
    };
  }
);

export const $box = createSelector([$boxes, (_state, id) => id], (boxes, id: string) => {
  return boxes.find((box) => box.id === id);
});

export const $inputSummary = createSelector(
  $layout,
  $legoParams,
  raw.$rawBoxes,
  (layoutId, params, boxes) => {
    const { sourceType } = params;

    const inputBoxId = ['i', layoutId, sourceType, 'input'].join('-');
    return boxes[inputBoxId];
  }
);

export const $inputBoxLego = createSelector(
  $layout,
  raw.$rawBoxes,
  $inputSummary,
  (layoutId, boxes, inputSummaryBox) => {
    if (layoutId === 'summarize') {
      return inputSummaryBox;
    }

    const inputBoxId = `i-${layoutId}-input`;

    return boxes[inputBoxId];
  }
);

export const $prompt = createSelector(
  $layout,
  $context,
  $inputBoxLego,
  (layoutId, context, input) => {
    const prompt = get(input, 'values.prompt', '');

    let main = prompt,
      secondary = undefined;

    if (layoutId === 'answer') {
      main = context;
      secondary = prompt;
    }

    return {
      main,
      secondary,
    };
  }
);

export const $outputBoxForIndex = createSelector(
  [$outputBoxes, (_state, index) => index],
  (boxes, index: number) => {
    return boxes[index];
  }
);

export const $layoutGroups = createSelector(raw.$o, () => {
  return {
    complete: {
      id: 'complete',
      title: 'Foundation Models',
      description:
        'Experiment with our foundation models, model parameters and prompts, using an empowering suite of layouts and tools.',
      url: '/home/complete/single-input-1-output',
    },
    'task-specific-models': {
      id: 'task-specific-models',
      title: 'Task-Specific Models',
      description: 'Experiment with our plug-and-play models and tailor them to your application.',
      url: '/home/task-specific-models/paraphrase',
    },
  };
});

export const $layoutItems = createSelector($layoutGroups, raw.$rawUser, (groups, user) => {
  const { userEmail } = user;

  const isEmployee = userEmail?.endsWith('ai21.com');

  return [
    {
      id: 'complete',
      text: 'Complete',
      description:
        'Our foundational generative text model that generates natural language in response to a natural language prompt.',
      imageUrl: '/play/playground-illustrations/illus.playground.complete.svg',
      groupId: 'complete',
      url: '/home/complete/single-input-2-outputs',
      links: [
        { text: '1 Output', id: '1-output' },
        { text: '2 Outputs', id: '2-outputs' },
        { text: '3 Outputs', id: '3-outputs' },
      ],
    },
    {
      id: 'chat',
      text: 'Chat',
      description: 'Our chat model that allows you to have a conversation with an AI.',
      imageUrl: '/play/playground-illustrations/illus.playground.chat.svg',
      groupId: 'complete',
      url: '/home/chat/single-chat',
    },
    {
      id: 'alternative',
      text: 'Alternative Tokens',
      description:
        'Our alternative tokens playground for testing new models and features. This is a work in progress.',
      imageUrl: '/play/playground-illustrations/illus.playground.ai21internal.svg',
      groupId: 'complete',
      url: '/home/complete/alternative',
      forEmployees: true,
      badge: 'AI21',
      links: [{ text: 'Only for AI21 Employees', id: 'no-action' }],
    },
    {
      id: 'answer',
      text: 'Contextual Answers',
      description:
        'Receives a question and a document and returns an answer based on the document context.',
      imageUrl: '/task-specific/task-specific-illustrations/illus.playground.contextualanswers.svg',
      groupId: 'task-specific-models',
      url: '/home/task-specific-models/contextual-answers',
      links: [
        { text: 'Free text', id: 'no-action' },
        { text: 'Document Library', id: 'no-action' },
      ],
    },
    {
      id: 'paraphrase',
      text: 'Paraphrase',
      description:
        'Takes a piece of text and returns a list of paraphrases that convey the same meaning in other words.',
      imageUrl: '/task-specific/task-specific-illustrations/illus.playground.paraphrase.svg',
      groupId: 'task-specific-models',
      url: '/home/task-specific-models/paraphrase',
      links: [
        { text: 'General', id: 'general' },
        { text: 'Casual', id: 'casual' },
        { text: 'Formal', id: 'formal' },
        { text: 'Long', id: 'long' },
        { text: 'Short', id: 'short' },
      ],
    },
    {
      id: 'summarize',
      text: 'Summarize',
      description: 'Takes a document (raw text or URL) and returns a summary of the text.',
      imageUrl: '/task-specific/task-specific-illustrations/illus.playground.summarize.svg',
      groupId: 'task-specific-models',
      url: '/home/task-specific-models/summarize',
      links: [
        { text: 'Free text', id: 'text' },
        { text: 'URL link', id: 'url' },
      ],
    },
    {
      id: 'gec',
      text: 'Grammatical Error Corrections',
      description:
        'Detects grammar, spelling, punctuation, and others, and suggests how to correct them.',
      imageUrl: '/task-specific/task-specific-illustrations/illus.playground.grammatical.svg',
      groupId: 'task-specific-models',
      url: '/home/task-specific-models/grammatical-error-correction',
      links: [{ text: 'Free text', id: 'no-action' }],
    },
    {
      id: 'textImprovements',
      text: 'Text Improvements',
      description:
        'Recommends rewrites for improving the fluency, clarity and style of a given text.',
      imageUrl: '/task-specific/task-specific-illustrations/illus.playground.textimprovements.svg',
      groupId: 'task-specific-models',
      url: '/home/task-specific-models/text-improvements',
      links: [{ text: 'Variety of improvements types', id: 'no-action' }],
    },
  ]
    .filter((item) => !item.forEmployees || isEmployee)
    .map((item) => {
      return {
        ...item,
        groupTitle: (groups as any)[item.groupId].title,
      };
    });
});

export const $layoutFlavours = createSelector(raw.$rawUser, (user) => {
  const { userEmail } = user;
  const isEmployee = userEmail?.endsWith('ai21.com');

  return [
    {
      id: 'complete1',
      text: 'Single input (1 output)',
      familyId: 'complete',
      url: '/home/complete/single-input-1-output',
    },
    {
      id: 'complete2',
      text: 'Single input (2 outputs)',
      familyId: 'complete',
      url: '/home/complete/single-input-2-outputs',
    },
    {
      id: 'complete3',
      text: 'Single input (3 outputs)',
      familyId: 'complete',
      url: '/home/complete/single-input-3-outputs',
    },
    {
      id: 'completeAlt',
      text: 'Alternative tokens',
      familyId: 'complete',
      url: '/home/complete/alternative',
      forEmployees: true,
    },
  ].filter((item) => !item.forEmployees || isEmployee);
});
