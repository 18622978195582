import {
  ButtonMenu,
  IconButton,
  styled,
  TextField,
  tokens,
  Typography,
} from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Actions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Editor = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  background: ${tokens.primary.backgroundHover};
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${tokens.primary.borderDefault};
  border-bottom: 1px solid ${tokens.primary.borderDefault};

  & svg {
    height: 24px;
    width: 24px;
  }
`;

export const Duration = styled.div`
  padding-left: 15px;
  position: absolute;
  bottom: 65px;
  right: 10px;
  opacity: 0.3;
`;

export const Undo = styled(IconButton)`
  margin-right: 5px;
`;

export const Menu = styled(ButtonMenu)`
  margin-left: 5px;

  .MuiButton-root {
    border: none;
    padding: 6px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px 0 8px;

  &.large {
    height: 60px;

    button {
      margin-left: 8px;
    }
  }
`;

export const Count = styled(Typography)`
  flex: 1;
  margin-left: 3px;
`;

export const ExtraActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InputDescription = styled(Typography)`
  padding: 16px 0;
`;

export const InputField = styled(TextField)`
  &.MuiFormControl-root {
    border-radius: 8px;
  }
  & .MuiOutlinedInput-root {
    height: 36px;
    width: 500px;
    background: #fff;
  }
`;
