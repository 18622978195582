type MockDataField =
  | 'string'
  | 'date'
  | 'number'
  | 'size'
  | 'lorem'
  | 'boolean'
  | 'status'
  | 'float'
  | 'person'
  | 'percent'
  | 'guid'
  | string;

type MockConfig = Record<string, MockDataField>;

export const randomItem = (config: MockConfig, index: number) => {
  const data = Object.keys(config).reduce((acc, key) => {
    const type = config[key];
    switch (type) {
      case 'index':
        acc[key] = index + 1;
        break;
      case 'string':
        acc[key] = Math.random().toString(36).substring(2, 15);
        break;
      case 'date':
        acc[key] = randomDate().toISOString();
        break;
      case 'number':
        acc[key] = Math.floor(Math.random() * 500);
        break;
      case 'size':
        acc[key] = Math.floor(Math.random() * 10000);
        break;
      case 'lorem':
        const size = r(10, 200);
        const offset = r(0, size / 2);
        acc[key] = generateLorem(size, offset);
        break;
      case 'score':
        const score = randomScore();
        if (score) {
          acc[key] = score;
        }
        break;
      case 'boolean':
        acc[key] = Math.random() > 0.5;
        break;
      case 'float':
        acc[key] = Math.random() * 100;
        break;
      case 'guid':
        acc[key] = guid();
        break;
      case 'percent':
        acc[key] = Math.random();
        break;
      case 'person':
        acc[key] = randomPerson();
        break;
      case 'status':
        acc = {
          ...acc,
          ...randomStatus(acc),
        };
        break;

      default:
        acc[key] = type.replace('{index}', index.toString());
    }

    return acc;
  }, {} as Record<string, string | number | boolean | Json>);

  return data;
};

const randomStatus = (data: Json) => {
  const statuses = ['Evaluating', 'Completed', 'Incomplete'];
  const status = statuses[Math.floor(Math.random() * statuses.length)];
  const linesCount = data.linesCount;
  const linesCompleted = Math.round(Math.random() * linesCount);

  return {
    status,
    linesCompleted,
  };
};

export const randomScore = () => {
  if (Math.random() > 0.5) {
    return undefined;
  }

  let value = Math.ceil(Math.random() * 3);

  let category = '';

  if (value === 1) {
    value = 10;
    category = 'great';
  }

  if (value === 2) {
    value = 5;
    category = 'ok';
  }

  if (value === 3) {
    value = 0;
    category = 'bad';
  }

  const filteredOptions = options
    .filter((option) => {
      return category === option.category;
    })
    .map((option) => option.id);

  return {
    value,
    tags: randomItemsFromArray(filteredOptions),
  };
};

const randomItemsFromArray = (arr: any[]) => {
  let output = [];

  for (let i = 0; i < arr.length; i++) {
    if (Math.random() > 0.5) {
      output.push(arr[i]);
    }
  }

  return output;
};

const randomPerson = () => {
  const firstNames = [
    'John',
    'Jane',
    'Bob',
    'Alice',
    'Joe',
    'Mary',
    'Sally',
    'Sam',
    'Sue',
    'Tom',
    'Tim',
    'Tina',
    'Terry',
    'Tiffany',
    'Trevor',
    'Troy',
    'Tracy',
    'Travis',
    'Tristan',
  ];
  const lastNames = [
    'Smith',
    'Jones',
    'Williams',
    'Brown',
    'Davis',
    'Miller',
    'Wilson',
    'Moore',
    'Taylor',
    'Anderson',
    'Thomas',
    'Jackson',
    'White',
    'Harris',
    'Martin',
    'Thompson',
    'Garcia',
    'Martinez',
    'Robinson',
    'Clark',
    'Rodriguez',
    'Lewis',
    'Lee',
    'Walker',
    'Hall',
    'Allen',
    'Young',
  ];

  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];

  return `${firstName} ${lastName}`;
};

export const generateRandomData = (count: number, config: MockConfig) => {
  const data = Array.from({ length: count }, (_, index) =>
    randomItem(config, index)
  );
  return arrayToObject(data);
};

const arrayToObject = (arr: Json[]) => {
  return arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
};

const randomDate = () => {
  const now = new Date();
  const start = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());

  return new Date(
    start.getTime() + Math.random() * (now.getTime() - start.getTime())
  );
};

const generateLorem = (size: number, start: number = 0) => {
  const lorem =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin semper congue pharetra. Nunc viverra eu ex eu tempus. Sed nulla purus, hendrerit nec tempus eget, tincidunt vehicula elit. Donec non ipsum a lorem vehicula posuere a at mi. Cras quis ullamcorper tortor, id mollis nisl. Nulla facilisi. Phasellus a porta eros. Etiam mattis mauris neque, ut ornare nunc malesuada sed. Nam non scelerisque nunc, id aliquam libero. Nullam fermentum vehicula rutrum. Ut ac urna a quam porttitor tristique non ac magna. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam accumsan porta arcu, eget tempus diam fringilla sed.';

  return lorem.repeat(r(1, 4)).slice(start, size);
};

const r = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const guid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
};

export const generateMockItem = (id: string) => {};

export const options = [
  {
    id: 'relevant',
    title: 'Relevant',
    definition:
      "Evaluating whether the output is relevant to the desired task or query. Does it address the user's request or provide meaningful information?",
    category: 'great',
  },
  {
    id: 'coherent',
    title: 'Coherent',
    definition:
      'Assessing the logical flow and coherence of the output. Does it make sense and convey information in a coherent manner?',
    category: 'great',
  },
  {
    id: 'concise',
    title: 'Concise',
    definition:
      'Determining if the output is concise and avoids unnecessary verbosity. Is it clear and to the point?',
    category: 'great',
  },
  {
    id: 'grammatical',
    title: 'Grammatical',
    definition:
      'Checking if the output adheres to proper grammar rules and is free from grammatical errors.',
    category: 'great',
  },
  {
    id: 'natural',
    title: 'Natural',
    definition:
      'Evaluating if the output sounds natural and human-like. Does it mimic natural language and sound like something a human would say?',
    category: 'great',
  },
  {
    id: 'informative',
    title: 'Informative',
    definition:
      "Assessing the extent to which the output provides useful and informative content. Does it answer the user's query adequately?",
    category: 'great',
  },
  {
    id: 'engaging',
    title: 'Engaging',
    definition:
      "Evaluating the ability of the output to capture the reader's attention and engage them. Does it spark interest and encourage further interaction?",
    category: 'great',
  },
  {
    id: 'creative',
    title: 'Creative',
    definition:
      'Assessing the level of creativity in the output. Does it offer unique or innovative approaches, suggestions, or solutions?',
    category: 'great',
  },

  {
    id: 'irrelevant',
    title: 'Irrelevant',
    definition:
      "Indicating that the output is not relevant to the desired task or query. It fails to address the user's request or provide meaningful information.",
    category: 'bad',
  },
  {
    id: 'incoherent',
    title: 'Incoherent',
    definition:
      'Suggesting that the output lacks logical flow and coherence. It may not make sense or convey information in a clear and organized manner.',
    category: 'bad',
  },
  {
    id: 'confusing',
    title: 'Confusing',
    definition:
      'Describing an output that is unclear or difficult to understand. It may contain ambiguous or convoluted language.',
    category: 'bad',
  },
  {
    id: 'grammatical',
    title: 'Grammatically Incorrect',
    definition:
      'Highlighting grammatical errors or incorrect language usage in the output.',
    category: 'bad',
    isHidden: true,
  },
  {
    id: 'unnatural',
    title: 'Unnatural',
    definition:
      'Describing an output that sounds unnatural or robotic, lacking the nuances and naturalness of human language.',
    category: 'bad',
    isHidden: true,
  },
  {
    id: 'insufficient',
    title: 'Insufficient',
    definition:
      "Indicating that the output does not provide enough information or fails to adequately answer the user's query.",
    category: 'bad',
  },
  {
    id: 'bland',
    title: 'Bland',
    definition:
      'Suggesting that the output is uninteresting or lacks creativity, failing to engage the reader or capture their attention.',
    category: 'bad',
    isHidden: true,
  },
  {
    id: 'repetitive',
    title: 'Repetitive',
    definition:
      'Describing an output that repeats information unnecessarily or lacks variety in expression.',
    category: 'bad',
  },
  {
    id: 'misleading',
    title: 'Misleading',
    definition:
      'Indicating that the output provides incorrect or misleading information, leading the reader in the wrong direction.',
    category: 'bad',
  },
  {
    id: 'lack-depth',
    title: 'Lack of Depth',
    definition:
      'Suggesting that the output is shallow or lacks depth in its content, not providing insightful or meaningful information.',
    category: 'bad',
    isHidden: true,
  },
  {
    id: 'offensive',
    title: 'Offensive',
    category: 'bad',
    definition: 'The completion is offensive and inappropriate.',
  },
  {
    id: 'false',
    title: 'False',
    category: 'bad',
    definition: 'The output provided false information.',
  },
].filter((option) => !option.isHidden);
