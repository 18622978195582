import { ax } from '@ai21/studio-analytics';
import { actions, ICollectionLine, selectors } from '@ai21/studio-store';
import { drawer, prompt as dialog, toast } from '@ai21/studio-ui';
import { call, delay, put, select, take, takeEvery } from 'saga-ts';
import { downloadJson as downloadFile, downloadText } from 'shared-base';
import { patchBox } from '../../../play/src/sagas/helpers/boxes';
import { deleteLine } from '../../../play/src/sagas/saga.collection';
import CompletionInspectorContainer from '../containers/CompletionInspector.container';
import { Json } from '../types';
import { jsonToCsv } from '../utils/file';
import CollectionLinesModal from '../_part/CollectionLinesModal/CollectionLinesModal';
import { analytics } from './helpers/analytics';
import { predicateCurrentId } from './predicates';

type Verb =
  | 'select'
  | 'previous'
  | 'next'
  | 'deleteCollectionLine'
  | 'navigateCollectionPage'
  | 'downloadCollectionCsv'
  | 'downloadCollectionJson'
  | 'playground';

type ActionJob = {
  type: 'COLLECTION_LINE';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  select: openDrawer,
  previous: previousLine,
  next: nextLine,
  deleteCollectionLine: deleteCollectionLine,
  navigateCollectionPage: navigateCollectionPage,
  downloadCollectionJson: downloadCollectionJson,
  downloadCollectionCsv: downloadCollectionCsv,
  playground: openInPlayground,
};

export function* openDrawer(action: ActionJob) {
  const { id } = action;

  // resets the form
  yield put(actions.currentIds.patch({ collectionLineId: '' }));

  yield put(
    actions.currentIds.patch({
      collectionLineId: id,
    })
  );

  drawer.open({
    component: CompletionInspectorContainer,
  });
}
export function* previousLine(_action: ActionJob) {
  const jobInfo = yield* select(selectors.collection.$collectionJobInfo);
  const { previousDot } = jobInfo;

  if (!previousDot) {
    return;
  }

  yield put(actions.currentIds.patch({ collectionLineId: '' })); // refreshes the form
  yield put(
    actions.currentIds.patch({
      collectionLineId: previousDot.id,
    })
  );
}

export function* nextLine(_action: ActionJob) {
  const jobInfo = yield* select(selectors.collection.$collectionJobInfo);
  const { nextDot } = jobInfo;

  if (!nextDot) {
    return;
  }

  yield put(actions.currentIds.patch({ collectionLineId: '' })); // refreshes the form
  yield put(
    actions.currentIds.patch({
      collectionLineId: nextDot.id,
    })
  );
}

export function* onLineChange(action: any) {
  const { payload } = action;
  const { collectionLineId } = payload;

  // document.location.hash = collectionLineId;
}

export function* deleteCollectionLine(action: ActionJob) {
  const collection = yield* select(selectors.playground.$collection);
  const collectionBox = yield* select(
    selectors.singles.$box,
    collection?.boxId
  );
  const collectionId = collectionBox.values.collectionId;

  const shouldDeleteLine = yield* call(
    deleteLine,
    collectionId,
    action.id as string
  );
  let newCollectionLines = collectionBox.values.collectionLines;
  if (shouldDeleteLine) {
    newCollectionLines = collectionBox.values.collectionLines.filter(
      (line: ICollectionLine) => line.id !== action.id
    );
    yield* call(patchBox, collectionBox, {
      collectionLines: newCollectionLines,
    });
  }

  const tableSets = Object.values(newCollectionLines)
    .filter((line: any) => line.setId === collectionId && !line.isDeleted)
    .map((line: any) => {
      return {
        id: line.id,
        index: line.index,
        guest: line.lastOpenedBy,
        input: line.input,
        inputShort: line.input.substring(0, 240),
        output: line.output,
        outputShort: line.output.substring(0, 240),
        modelName: line.modelName || (line as any).modelId,
        temperature: line.temperature,
        minTokens: line.minTokens,
        maxTokens: line.maxTokens,
        topP: line.topP,
        timeStamp: line.dateCreated,
        source: line.setSource,
        status: line.status,
        comment: line.comment,
        outputSuggestion: line.outputEdited,
        isLocked: line.isLocked,
        entityIcon: 'SquarePlus',
      };
    });

  const { value, didCancel } = yield dialog.custom({
    title: 'Data collections',
    component: CollectionLinesModal,
    componentProps: {
      tableSets: tableSets,
      collectionId: collectionId,
    },
  });

  if (didCancel || !value || value.length === 0) {
    return;
  }
}

export function* navigateCollectionPage(action: ActionJob) {
  yield put({
    type: 'NAVIGATE',
    to: `/datasets/data-collections/${action.id}/lines`,
  });
}

export function* openInPlayground(action: ActionJob) {
  const jobInfo = yield* select(selectors.collection.$collectionJobInfo);
  yield put({
    type: 'NAVIGATE',
    to: '/home/complete/single-input-1-output?collectionId=' + jobInfo.job?.id,
  });
}

export function* downloadCollectionJson(action: ActionJob) {
  const { id } = action;

  yield* put(actions.collectionLines.get({ setId: id }));
  yield take('SET_COLLECTIONLINES');

  try {
    const lines = yield* select(selectors.raw.$rawCollectionLines);
    const filtersLines = Object.values(lines).filter(
      (line: any) => line.setId === id && !line.isDeleted
    );

    const fileName = `job-${id}.json`;

    downloadFile(`job-${id}.json`, filtersLines);

    ax.nudge('collectionSets.downloads');

    yield* call(analytics, {
      action: 'downloadJson',
      actionValue: fileName,
      eventId: 'CollectionSetDownloaded',
    });
  } catch (err) {
    yield* call(analytics, {
      action: 'downloadJson',
      actionValue: `Failed to download to json, error: ${err}`,
      eventId: 'CollectionSetDownloadFailed',
    });
  }
}

export function* downloadCollectionCsv(action: ActionJob) {
  const { id } = action;

  yield* put(actions.collectionLines.get({ setId: id }));
  yield take('SET_COLLECTIONLINES');

  const lines = yield* select(selectors.raw.$rawCollectionLines);
  const filtersLines = Object.values(lines).filter(
    (line) => line.setId === id && !line.isDeleted
  );

  const csv = jsonToCsv(filtersLines);

  if (csv === null) {
    toast.show('Failed to convert to CSV', 'error');
    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: 'Failed to convert to CSV',
      eventId: 'collectionSetDownloadFailed',
    });
    return;
  }

  try {
    const fileName = `job-${id}.csv`;
    downloadText(fileName, csv);

    ax.nudge('collectionSets.downloads');

    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: fileName,
      eventId: 'CollectionSetDownloaded',
    });
  } catch (err) {
    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: `Failed to download CSV, error: ${err}`,
      eventId: 'CollectionSetDownloadFailed',
    });
  }
}

export function* collectionLine(action: ActionJob) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('COLLECTION_LINE', collectionLine);
  yield takeEvery(predicateCurrentId('collectionLineId'), onLineChange);
}
