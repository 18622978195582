import { getSingle, setSingle } from './firebase';

export const get = (_extraParams?: Json) => {
  return getSingle('/', 'user');
};

export const set = (change: Json) => {
  return setSingle('/', change, 'user');
};

export const ax = {
  get,
  set,
};
