export const DEFAULT_PENALTY: Penalty = {
  scale: 0,
  applyToWhitespaces: false,
  applyToPunctuations: false,
  applyToNumbers: false,
  applyToStopwords: false,
  applyToEmojis: false,
};

export const DEFAULT_CONTROLLER_PARAMS = {
  MAX_TOKENS: 200,
  TEMPERATURE: 0.7,
  EPOCH: 0,
  TOP_P: 1.0,
  STOP_SEQ: [],
  MODEL: 'j2-mid',
  SHOW_ALT_TOKENS: false,
  PROMPT: '',
};

export interface TokenData {
  token: string;
  logprob: number;
}

export interface TokenTextRange {
  start: number;
  end: number;
}

export interface Token {
  generatedToken: TokenData;
  topTokens?: TokenData[];
  textRange: TokenTextRange;
}

export interface TextData {
  text: string;
  tokens: Token[];
}

export interface Completion {
  data: TextData;
  finishReason: { reason: string; length?: number; sequence?: string };
}

export interface Penalty {
  scale: number;
  applyToWhitespaces: boolean;
  applyToPunctuations: boolean;
  applyToNumbers: boolean;
  applyToStopwords: boolean;
  applyToEmojis: boolean;
}

export interface ControllerParams {
  maxTokens: number;
  epoch: number;
  temperature: number;
  topP: number;
  stopSequences: string[];
  model: string;
  showAlternativeTokens: boolean;
  prompt: string;
  presencePenalty: Penalty;
  countPenalty: Penalty;
  frequencyPenalty: Penalty;
}

export interface TokenClickedParams {
  clicked: boolean;
  token: Token | null;
  renderAt: { left: number; top: number } | null;
}

export interface CompletionRequestParams {
  prompt: string;
  model: string;
  numResults: number;
  maxTokens: number;
  epoch: number | undefined;
  temperature: number;
  topP: number;
  stopSequences: string[];
  numStopSequences: number;
  promptLength: number;
  topKReturn?: number | undefined;
  frequencyPenalty: Penalty;
  presencePenalty: Penalty;
  countPenalty: Penalty;
}

export interface CompletionConfigChanged {
  newModel: string | null;
  newMaxTokens: number | null;
  newTemperature: number | null;
  newTopP: number | null;
  newStopSequences: string[] | null;
  isPresencePenaltyChanged: boolean | null;
  isCountPenaltyChanged: boolean | null;
  isFrequencyPenaltyChanged: boolean | null;
  eventFireDelaySeconds: number | null;
  propertyName: string;
}

export interface ModelTrainSettingsChanged {
  newModel: string | null;
  newLearningRate: number | null;
  newNumOfEpochs: number | null;
  currentCost: number | null;
  eventFireDelaySeconds: number | null;
  propertyName: string;
}

export interface ModelCostChanged {
  modelType: string | null;
  numOfEpochs: number | null;
  cost: number | null;
}

export interface CompletionResponse {
  id: string;
  prompt: TextData;
  completions: Completion[];
}

export enum CompletionFailureReason {
  CREDIT_EXCEEDED,
  TRIAL_ENDED,
  UNKNOWN_ERROR,
  TOO_MANY_TOKENS,
  TOO_MANY_REQUESTS,
}
