import { IMenuConfig, IMenuGroup } from '../types';
import type { IMenuItem } from '@ai21/studio-base-ui';
import { RouterBuilder } from './RouterBuilder';
import { MenuBuilder } from './MenuBuilder';

export enum UserStatus {
  INITIAL = 'INITIAL',
  VERIFIED = 'VERIFIED',
  REVOKED = 'REVOKED',
  PENDING = 'PENDING',
  NOT_LOADED = 'NOT_LOADED',
}

export type Json = Record<string, unknown>;

export type IAppConfig = {
  publicRoutes: IRouteMap;
  privateRoutes: IRouteMap;
  externalRoutes: IRouteMap;
  defaultRoute: string;
  redirects: Json;
  pages: IPages;
};

export type IPages = Record<string, React.FC<any>>;

export interface IRouterBuilder {
  withAppConfig(appConfig: IAppConfig): IRouterBuilder;
  withPublicRoutes(routes: IRouteMap): IRouterBuilder;
  withPrivateRoutes(routes: IRouteMap): IRouterBuilder;
  withExternalRoutes(routes: IRouteMap): IRouterBuilder;
  withDefaultRoute(path: string): IRouterBuilder;
  withPages(pages: Json): IRouterBuilder;
  withRedirects(redirects: Json): IRouterBuilder;
  with404PageId(value: string): IRouterBuilder;
  build(): JSX.Element[];
}

export type RoutePath = string;
export type RoutePageId = string;
export type IRouteMap = Record<RoutePath, RoutePageId>;

export type ILinks = Record<string, string>;

export type IRoute = {
  path: string;
  pageId: string;
  isPrivate?: boolean;
  isExternal?: boolean;
  redirectPath?: string;
};

export interface IMenuBuilder {
  withItems(items: IMenuItem[]): IMenuBuilder;
  withEmail(email: string): IMenuBuilder;
  withGroups(groups: IMenuGroup[]): IMenuBuilder;
  build(): IMenuConfig;
}

export type IAppBuilders = {
  routerBuilder: IRouterBuilder;
  sideBarBuilder: IMenuBuilder;
  topBarBuilder: IMenuBuilder;
};

export type IAppDefinition = {
  initRoutes: (routerBuilder: RouterBuilder) => void;
  initSideBar: (menuBuilder: MenuBuilder) => void;
  initTopBar: (menuBuilder: MenuBuilder) => void;
  root: any;
};
