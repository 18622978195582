import { ButtonMenu, Typography, styled, tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  margin-left: 4px;
  color: ${tokens.text.tertiary};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Menu = styled(ButtonMenu)`
  position: relative;
  left: 0px;

  .MuiButton-root {
    border: none;
  }

  svg {
    width: 16px !important;
    height: 16px !important;
  }
`;
