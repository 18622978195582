import { Button, Icon, IconButton, Tooltip } from '@ai21/studio-base-ui';
import { Form } from '@ai21/studio-forms';
import { useKey } from 'react-use';
import { forms } from '../../_definitions/forms';
import { Actions, Bottom, Content, Top, Wrapper } from './CompletionInspector.style';

export type CompletionInspectorProps = {
  line: Json;
  total: number;
  callbacks: {
    onNext: () => void;
    onPrev: () => void;
    onClose: () => void;
  };
};

export function CompletionInspector(props: CompletionInspectorProps) {
  const { line, total, callbacks } = props;
  const { index } = line;

  useKey('ArrowRight', callbacks.onNext);
  useKey('ArrowLeft', callbacks.onPrev);

  function renderTop() {
    return (
      <Top>
        <Actions>
          <Tooltip title='Close drawer' enterDelay={1000}>
            <IconButton
              iconName='ChevronsRight'
              data-testid='CloseEvaluationDrawer'
              onClick={() => callbacks.onClose()}
            />
          </Tooltip>
        </Actions>
        <>
          Item {index} out of {total}
        </>
      </Top>
    );
  }

  function renderActions() {
    return (
      <>
        <Button
          variant='outlined'
          color='secondary'
          startIcon={<Icon iconName='ArrowNarrowLeft' />}
          data-testid='CompletionInspectorPrev'
          onMouseDown={callbacks.onPrev}
        >
          Prev
        </Button>
        <Button
          variant='outlined'
          color='secondary'
          endIcon={<Icon iconName='ArrowNarrowRight' />}
          data-testid='CompletionInspectorNext'
          onMouseDown={callbacks.onNext}
        >
          Next
        </Button>
      </>
    );
  }

  return (
    <Wrapper className='CompletionInspector-wrapper' data-testid='CompletionInspector-wrapper'>
      {renderTop()}
      <Content>
        <Form
          config={forms.inspector as any}
          data={line}
          allOptions={{}}
          onChange={() => {}}
          onSubmit={(_change: Json, allData: Json) => Promise.resolve(true)}
        />
      </Content>
      <Bottom>{renderActions()}</Bottom>
    </Wrapper>
  );
}

export default CompletionInspector;
