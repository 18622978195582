import { Box, Grid, Typography, Link, icons } from '@ai21/studio-ui';
import { TRAINING_DATA_LINK } from '../../constants/constants';

const errorMessageBox = {
  padding: 2,
  width: '100%',
  height: 'auto',
  backgroundColor: '#ffede1',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow:
    '0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 1px 0 rgba(0, 0, 0, 0.08), 0 2px 1px -1px rgba(0, 0, 0, 0.1)',
};

export function ErrorMessageBox({ errorMessage }: { errorMessage: string }) {
  return (
    <Grid container>
      <Box sx={errorMessageBox}>
        <Grid container direction='row' alignItems='flex-start'>
          <Grid style={{ width: '6%' }}>
            <icons.ExclamationMark />
          </Grid>
          <Grid style={{ width: '94%' }}>
            <Typography
              variant='body1'
              style={{ color: '#B80B00' }}
              data-testid='dataset-error-msg'
            >
              {errorMessage}
              &nbsp;&nbsp;&nbsp;
              <Link
                style={{ cursor: 'pointer' }} // moved the custom color to be primary color outside the style - Yuval
                data-testid='link-learn-more'
                color='primary'
                variant='inherit'
                underline='none'
                target='_blank'
                href={TRAINING_DATA_LINK}
              >
                Learn more.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
