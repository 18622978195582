export function NewTabIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.4375 5.25C5.4375 4.93934 5.68934 4.6875 6 4.6875H12.75C13.0607 4.6875 13.3125 4.93934 13.3125 5.25V12C13.3125 12.3107 13.0607 12.5625 12.75 12.5625C12.4393 12.5625 12.1875 12.3107 12.1875 12V6.60799L5.64775 13.1477C5.42808 13.3674 5.07192 13.3674 4.85225 13.1477C4.63258 12.9281 4.63258 12.5719 4.85225 12.3523L11.392 5.8125H6C5.68934 5.8125 5.4375 5.56066 5.4375 5.25Z'
        fill='currentColor'
      />
    </svg>
  );
}
