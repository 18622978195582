import { IBox, actions, selectors } from '@ai21/studio-store';
import { call, put, select } from 'saga-ts';
import { get } from 'lodash';
import { patchBox } from './boxes';

export function* clearLogger() {
  const loggerBoxes = yield* select(selectors.playground.$loggerBoxes);

  if (!loggerBoxes[0]) {
    return;
  }

  yield call(patchBox, loggerBoxes[0], { logs: [] });
}

export function* log(data: Json) {
  const loggerBoxes = yield* select(selectors.playground.$loggerBoxes);

  if (!loggerBoxes[0]) {
    return;
  }

  const logs = get(loggerBoxes[0], 'values.logs', []);
  yield call(patchBox, loggerBoxes[0], { logs: [...logs, data] });
}
