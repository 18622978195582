export const cleanHighlight = (highlight: string) => {
  return highlight
    .trim()
    .replace(/[\n\r]+/g, '\n')
    .split('\n')
    .map((line) => line.trim())
    .filter((i) => {
      return i && i.charCodeAt(0) !== 65532;
    });
};
