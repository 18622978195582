import { Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const DropZone = styled.div`
  flex: 1;

  padding: 50px;
  border: 1px dashed #a8aeb8;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  justify-content: center;

  &.active {
    background-color: #8a46ef1c;
  }
`;

export const Input = styled.input``;

export const P = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0;
`;
