import { ax } from '@ai21/studio-analytics';
import {
  ICollectionJobConfig,
  ICollectionLine,
  actions,
  createCollectionJob,
  selectors,
} from '@ai21/studio-store';
import { get } from 'lodash';
import { call, delay, put, select } from 'saga-ts';
import { guid4 } from 'shared-base';
import { analytics } from './analytics';

export function* createJob(
  dataset: Json[],
  data: ICollectionJobConfig,
  shouldNavigate: boolean
) {
  const setId = data.id || guid4();

  const isSample = get(dataset, '[0].prompt', '').includes(
    'The following sentences contain business jargon.'
  );

  try {
    const linesCount = dataset.length;

    yield* call(createCollectionJob, dataset, {
      ...data,
      id: setId,
      isSample,
    });

    const linesToAdd = dataset.filter((i) => i.prompt);

    yield* call(analytics, {
      action: 'upload',
      actionValue: linesToAdd.length,
      eventId: 'BatchGenerateUploadSuccess',
      extra: { isSample },
    });

    yield delay(500);
    yield put(actions.collectionJobs.get({}));

    if (shouldNavigate) {
      yield put({
        type: 'NAVIGATE',
        to: `/datasets/data-collections/${setId}/lines`,
      });
    }

    yield delay(500);

    ax.nudge('collectionSets.total');

    if (isSample) {
      ax.nudge('collectionSets.sample');
    } else {
      ax.nudge('collectionSets.customStarted');
      ax.nudge('collectionSets.custom');
      ax.nudge('collectionSets.customTotalLines', linesCount);
    }

    ax.set(`collectionSetsProgress.${setId}`, {
      status: 'New',
      linesCount,
      isSample,
    });

    // auto-start collection
    yield put({
      type: 'COLLECTION_JOB',
      verb: 'start',
      id: setId,
    });
  } catch (err: any) {
    yield* call(analytics, {
      action: 'upload',
      actionValue: err.message,
      eventId: 'DataCollectionUploadError',
      extra: { isSample },
    });
  }
}
