import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  &.iconButton {
    button {
      min-width: 30px;
      min-height: 30px;
      width: 36px;
      height: 36px;

      .MuiButton-startIcon {
        margin-right: 0;
      }
    }
  }
`;

export const PopupMenu = styled(Menu)``;

export const Item = styled(MenuItem)`
  height: 36px;
  padding: 10px;
  margin: 1px 8px;
  border-radius: 8px;

  &:hover {
    background: #f4f5f7;
  }

  svg {
    color: #1d1e22;
  }
`;

export const Text = styled(Typography)``;

export const ItemIcon = styled(ListItemIcon)`
  min-width: 30px !important;

  svg {
    margin: 0 !important;
  }
`;
