import { apiUrl } from '../globals';
import { sanitizeCode } from '../utils/snippets';
import {
  getAnswerCurlCodeSnippet,
  getAnswerJsCodeSnippet,
  getAnswerPythonCodeSnippet,
} from './codeBlocks';
import { GenerateSnippetsParams } from './snippets.types';

const url = (apiHost: string) => `${apiHost}/answer`;

export const generate = (params: GenerateSnippetsParams) => {
  return {
    python: python(params),
    javascript: javascript(params),
    curl: curl(params),
  };
};

const python = (params: GenerateSnippetsParams) => {
  const { main, secondary } = params as any;
  return getAnswerPythonCodeSnippet(url(apiUrl), sanitizeCode(main), sanitizeCode(secondary));
};

const javascript = (params: GenerateSnippetsParams) => {
  const { main, secondary } = params as any;
  return getAnswerJsCodeSnippet(url(apiUrl), sanitizeCode(main), sanitizeCode(secondary));
};

const curl = (params: GenerateSnippetsParams) => {
  const { main, secondary } = params as any;
  return getAnswerCurlCodeSnippet(url(apiUrl), sanitizeCode(main), sanitizeCode(secondary));
};
