import { fireRequest } from './axios';
import { tokens } from './globals';
import { ApiResponse, PayloadUpgradeUser } from './types';

export const get = (extraParams?: Json) => {
  return fireRequest<Json>({
    path: '/user',
    data: extraParams,
    endpoint: 'user.get',
  });
};

export const create = (extraParams?: Json) => {
  return fireRequest<Json>({
    path: '/user',
    data: extraParams,
    endpoint: 'user.create',
  });
};

export const getApiKey = () => {
  return fireRequest<Json>({
    path: '/apikey',
    endpoint: 'user.getApiKey',
  });
};

export const refreshKey = () => {
  return fireRequest<Json>({
    path: '/user',
    method: 'post',
    data: {
      regenerateKey: true,
    },
    endpoint: 'user.refreshKey',
  });
};

export const updateUserPremium = (payload: PayloadUpgradeUser) => {
  return fireRequest<Json>({
    path: '/tier',
    method: 'post',
    data: payload,
    config: {
      headers: {
        'firebase-token': tokens.firebaseIdToken,
      },
    },
    endpoint: 'user.updateUserPremium',
  });
};

export const getSSOOrgId = (email: string): Promise<ApiResponse<string>> => {
  return fireRequest<string>({
    path: '/auth/sso/organization',
    method: 'get',
    data: { email },
    config: {},
    endpoint: 'user.getSSOOrgId',
  });
};

export const user = {
  get,
  getApiKey,
  create,
  refreshKey,
  updateUserPremium,
  getSSOOrgId,
};
