import { fork } from 'saga-ts';
import { root as amplitude } from './analytics.amplitude';
import { root as auth } from './analytics.auth';
import { root as clicks } from './analytics.clicks';
import { root as dialogs } from './analytics.dialogs';
import { root as flows } from './analytics.flows';
import { root as forms } from './analytics.forms';
import { root as navigation } from './analytics.navigation';
import { root as network } from './analytics.network';
import { root as tables } from './analytics.tables';
import { config } from '../utils/globals';
import { delay } from 'shared-base';

export function* root() {
  yield delay(300);
  const { automatic = [] } = config ?? {};

  yield* fork(amplitude);

  if (automatic.includes('auth')) { yield* fork(auth) } // prettier-ignore
  if (automatic.includes('clicks')) { yield* fork(clicks) } // prettier-ignore
  if (automatic.includes('dialogs')) { yield* fork(dialogs) } // prettier-ignore
  if (automatic.includes('flows')) { yield* fork(flows) } // prettier-ignore
  if (automatic.includes('forms')) { yield* fork(forms) } // prettier-ignore
  if (automatic.includes('navigation')) { yield* fork(navigation) } // prettier-ignore
  if (automatic.includes('network')) { yield* fork(network) } // prettier-ignore
  if (automatic.includes('tables')) { yield* fork(tables) } // prettier-ignore
}
