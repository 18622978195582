import { Tooltip } from '@ai21/studio-base-ui';
import { CheckboxProps } from '@mui/material';
import React from 'react';
import { guid4 } from 'shared-base';
import { Input, Label, Wrapper } from './Checkbox.style';

export type Props = CheckboxProps & {
  label?: string;
  value?: boolean;
  tooltip?: string;
};

export const Checkbox = React.forwardRef((props: Props, ref: any) => {
  const { label, value, tooltip } = props;

  const id = guid4();

  return (
    <Tooltip key={id} title={tooltip}>
      <Wrapper className='Checkbox-wrapper' data-testid='Checkbox-wrapper'>
        <Input id={id} checked={value} ref={ref} {...props} />
        {label && <Label htmlFor={id}>{label}</Label>}
      </Wrapper>
    </Tooltip>
  );
});

export default Checkbox;
