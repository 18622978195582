import { get } from 'lodash';
import { call, takeEvery } from 'saga-ts';
import { getBoolean, setBoolean } from 'shared-base';
import { featureFlags, team } from '../config/hash.json';
import { Json } from '../types';
import { hash } from '../utils/hash';
import { customEvenChannel } from './channels/channel.customEvent';

const appsTeam = (window.__env__.VITE_TEAM_APPS ?? '').split(',');
const appsCompany = (window.__env__.VITE_AI21_APPS ?? '').split(',');
const appsGlobal = (window.__env__.VITE_GLOBAL_APPS ?? '').split(',');
const appsFeatureFlags = (window.__env__.VITE_FEATURE_FLAG_APPS ?? '')
  .split(',')
  .filter((i: string) => i);

type ActionToggleApp = {
  type: 'app_toggle';
  data: {
    appId: string;
  };
};

export function* toggleApp(action: ActionToggleApp) {
  const { data } = action;
  const { appId } = data;

  try {
    const key = `app_${appId}_enabled`;

    const isOn = getBoolean(key);
    setBoolean(key, !isOn);
    document.location.reload();
  } catch (err) {
    console.log('err ->', err);
  }
}

export function* bootstrapGeneral() {
  let count = 0;

  for (let appId of appsGlobal) {
    const key = `app_${appId}_enabled`;

    if (!keyExists(key)) {
      count++;
      setBoolean(key, true);
    }
  }

  return count;
}

export function* bootstrapFeatureFlags(hashedUserEmail: string) {
  let count = 0;

  for (let appId of appsFeatureFlags) {
    const key = `app_${appId}_enabled`;
    const featureFlagsForApp = (featureFlags as Json)[appId];

    const hasFeatureFlag = (featureFlagsForApp as string[]).includes(hashedUserEmail);

    if (hasFeatureFlag) {
      if (!keyExists(key)) {
        count++;
        setBoolean(key, true);
      }
    }
  }

  return count;
}

export function* bootstrapCompany() {
  let count = 0;

  for (let appId of appsCompany) {
    const key = `app_${appId}_enabled`;

    if (!keyExists(key)) {
      count++;
      setBoolean(key, true);
    }
  }

  return count;
}

export function* bootstrapTeam() {
  let count = 0;

  for (let appId of appsTeam) {
    const key = `app_${appId}_enabled`;

    if (!keyExists(key)) {
      count++;
      setBoolean(key, true);
    }
  }

  return count;
}

export function* bootstrapApps(event: any) {
  const email = get(event, 'data.user.email');

  const hashedUserEmail = yield* call(hash, email);

  const isInTeam = team.includes(hashedUserEmail);
  const isInCompany = email.match(/@ai21.com$/);

  let appsEnabledCount = 0;

  appsEnabledCount += yield* call(bootstrapGeneral);
  appsEnabledCount += yield* call(bootstrapFeatureFlags, hashedUserEmail);

  if (isInCompany) {
    appsEnabledCount += yield* call(bootstrapCompany);
  }

  if (isInTeam) {
    appsEnabledCount += yield* call(bootstrapTeam);
  }
}

export function* root() {
  let channel;

  channel = customEvenChannel('app_toggle');
  yield takeEvery(channel, toggleApp);

  channel = customEvenChannel('user');
  yield takeEvery(channel, bootstrapApps);
}

export const keyExists = (key: string) => {
  const value = localStorage.getItem(key);
  return value !== null;
};
