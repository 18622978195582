import React from 'react';
import { Done, Name, OutOf, Row, Wrapper } from './ProgressView.style';
import { IJobInfo } from '../../InboxEditor.types';

export type ProgressViewProps = {
  jobInfo?: IJobInfo;
};

export function ProgressView(props: ProgressViewProps) {
  const { jobInfo } = props;

  const { countCompleted, countTotal, currentIndex = -1 } = jobInfo ?? {};

  const currentIndexText = currentIndex === -1 ? 'N/A' : currentIndex + 1;

  return (
    <Wrapper className='ProgressView-wrapper' data-testid='ProgressView-wrapper'>
      <Row>
        <Name>Item {currentIndexText}</Name>
        <OutOf>out of {countTotal}</OutOf>
      </Row>
      <Done>({countCompleted} evaluated)</Done>
    </Wrapper>
  );
}

export default ProgressView;
