import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;

  .MuiInputBase-root {
    background-color: white;
    border-radius: 8px;
  }
`;

export const GroupHeader = styled(Typography)`
  position: sticky;
  top: -8px;
  padding: 8px 10px;
  font-weight: 600;
  font-size: 12;
  color: #1d1e22;
  background: white;
`;

export const GroupItems = styled('ul')`
  padding: 0;
  font-size: 14;
  color: #1d1e22;
`;
