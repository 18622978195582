import { Button, IconButton, Tooltip } from '@ai21/studio-base-ui';
import { useContext } from 'react';
import { AskContext } from '../../AskEditor.context';
import { Actions, Title, Wrapper } from './AskTopBar.style';

export type AskTopBarProps = {
  isFullscreen?: boolean;
  fileName?: string;
  onToggleFullScreen: (isFullscreen: boolean) => void;
};

export function AskTopBar(props: AskTopBarProps) {
  const { isFullscreen, fileName } = props;
  const { state, callbacks, patchState } = useContext(AskContext);
  const altKey = state.isMac ? '⌥' : 'Alt+';

  let title = 'Ask selected documents';

  if (fileName) {
    title = `Ask your document: "${fileName}"`;
  }

  return (
    <Wrapper className='AskTopBar-wrapper' data-testid='AskTopBar-wrapper'>
      <Actions>
        <Tooltip title={`Close drawer (${altKey}Esc)`} enterDelay={1000}>
          <IconButton
            onClick={() => callbacks.onClose()}
            data-testid='CloseAskQuestionDrawer'
            iconName='ChevronsRight'
          />
        </Tooltip>
      </Actions>
      <Title>{title}</Title>
      <Button
        variant='outlined'
        color='secondary'
        onClick={() => callbacks.onAction('clear')}
        data-testid='ClearAskQuestionDrawer'
      >
        Clear result screen
      </Button>
    </Wrapper>
  );
}

export default AskTopBar;
