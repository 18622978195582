import { IApp, actions, selectors } from '@ai21/studio-store';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppPage from './AppPage';
import { toast } from '@ai21/studio-ui';

export type AppPageContainerProps = { appId?: string; app?: IApp };

export function AppPageContainer(props: AppPageContainerProps) {
  const dispatch = useDispatch();
  const isLpPage = window.location.href.includes('lp');
  const { appId } = isLpPage ? props : useParams();
  const currentApp = useSelector(selectors.showroom.$currentApp) || props.app;

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  useEffect(() => {
    dispatch(
      actions.currentIds.patch({
        appId,
      })
    );
    document.getElementsByClassName('AppPage-wrapper')[0]?.scrollIntoView();
  }, [appId]);

  const callbacks = useMemo(
    () => ({
      onTagClicked: (tag: string) => {
        const encodedArray = encodeURIComponent(JSON.stringify([tag]));
        dispatch({
          type: 'NAVIGATE',
          to: `${isLpPage ? '/lp' : ''}/use-cases?filter=${encodedArray}`,
        });
      },
      onAction: (action: Json) => {
        if (action.isDisabled) {
          toast.show('Available only on desktop');
          return;
        }
        dispatch({
          type: 'SHOWROOM',
          verb: action.id,
          params: { app: action.app || currentApp },
        });
      },
      onBack: () => {
        dispatch({
          type: 'NAVIGATE',
          to: isLpPage ? '/lp/use-cases' : '/use-cases',
        });
      },
    }),
    []
  );

  if (!currentApp) {
    return null;
  }

  return <AppPage app={currentApp} callbacks={callbacks} />;
}

export default AppPageContainer;
