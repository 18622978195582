export function ScaleTierIcon() {
  return (
    <svg width='50' height='34' viewBox='0 0 50 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16.3909' cy='17' r='13.8909' fill='#FCE5C2' stroke='black' />
      <circle cx='16.3668' cy='16.9757' r='8' fill='url(#paint0_radial_12:3658)' stroke='black' />
      <circle cx='43.9259' cy='17' r='3.57407' fill='url(#paint1_radial_12:3658)' stroke='black' />
      <defs>
        <radialGradient
          id='paint0_radial_12:3658'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(10.4168 13.5757) rotate(26.565) scale(12.3543 13.2528)'
        >
          <stop stopColor='#FCE5C2' />
          <stop offset='0.55138' stopColor='#FFB672' />
          <stop offset='1' stopColor='#FF881A' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_12:3658'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(41.0741 15.3704) rotate(26.565) scale(5.92144 6.35209)'
        >
          <stop stopColor='#FCE5C2' />
          <stop offset='0.55138' stopColor='#FFB672' />
          <stop offset='1' stopColor='#FF881A' />
        </radialGradient>
      </defs>
    </svg>
  );
}
