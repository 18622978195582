import { get, last } from 'lodash';
import { IncomingNetworkEvent } from '../../analytics.types';
import { logEvent } from '../../analytics.amplitude';

function* CompleteApiError(data: IncomingNetworkEvent) {
  const endpoint = get(data, 'endpoint', '');
  const errorMessage = get(data, 'errorMessage', '');
  const status = get(data, 'status', '');
  let requestParams = get(data, 'response.request.data', {});
  requestParams = {
    ...requestParams,
    promptLength: get(requestParams, 'prompt', '').length,
    prompt: undefined,
    frequencyPenalty: get(requestParams, 'frequencyPenalty.scale', 0),
    presencePenalty: get(requestParams, 'presencePenalty.scale', 0),
    countPenalty: get(requestParams, 'countPenalty.scale', 0),
  };
  logEvent(
    'CompletionError',
    {
      endpoint,
      ...requestParams,
      errorMessage,
      status,
    },
    true
  );
}

function* CompleteApiSuccess(data: IncomingNetworkEvent) {
  const endpoint = get(data, 'endpoint', '');
  const duration = get(data, 'response.duration', 0);
  let requestParams = get(data, 'response.request.data', {});
  const outputData = get(data, 'response.data', {});

  requestParams = {
    ...requestParams,
    prompt: undefined,
    frequencyPenalty: get(requestParams, 'frequencyPenalty.scale', 0),
    presencePenalty: get(requestParams, 'presencePenalty.scale', 0),
    countPenalty: get(requestParams, 'countPenalty.scale', 0),
  };
  requestParams = {
    ...requestParams,
    interactionId: get(outputData, 'id', ''),
    generatedTextLength: get(outputData, 'completions[0].data.text', '').length,
    requestDuration: duration,
  };

  logEvent(
    'CompletionGenerated',
    {
      endpoint,
      ...requestParams,
    },
    true
  );
}

function* ChatApiSuccess(data: IncomingNetworkEvent) {
  const endpoint = get(data, 'endpoint', '');
  const duration = get(data, 'response.duration', 0);
  let requestParams = get(data, 'response.request.data', {});
  const outputData = get(data, 'response.data', {});
  const messages = get(requestParams, 'messages', []);

  requestParams = {
    ...requestParams,
    role: get(outputData, 'outputs[0].role', ''),
    messages: undefined,
    system: undefined,
    content: undefined,
    numOfMessages: get(requestParams, 'messages', []).length,
    frequencyPenalty: get(requestParams, 'frequencyPenalty.scale', 0),
    presencePenalty: get(requestParams, 'presencePenalty.scale', 0),
    countPenalty: get(requestParams, 'countPenalty.scale', 0),
  };
  requestParams = {
    ...requestParams,
    interactionId: get(outputData, 'id', ''),
    generatedTextLength: get(outputData, 'outputs[0].text', '').length,
    requestDuration: duration,
  };

  logEvent(
    'ChatGenerated',
    {
      endpoint,
      ...requestParams,
    },
    true
  );
}

function* ChatApiRequest(data: IncomingNetworkEvent) {
  const endpoint = get(data, 'endpoint', '');
  let requestParams = get(data, 'data', {});
  const messages = get(requestParams, 'messages', []);
  const message = last(messages);

  requestParams = {
    ...requestParams,
    messages: undefined,
    system: undefined,
    content: undefined,
    numOfMessages: messages.length,
    role: message?.role || 'user',
    frequencyPenalty: get(requestParams, 'frequencyPenalty.scale', 0),
    presencePenalty: get(requestParams, 'presencePenalty.scale', 0),
    countPenalty: get(requestParams, 'countPenalty.scale', 0),
  };

  logEvent(
    'ChatRequested',
    {
      endpoint,
      ...requestParams,
    },
    true
  );
}

export const root = {
  'completion.complete': {
    success: CompleteApiSuccess,
    error: CompleteApiError,
  },
  'completion.chat': {
    request: ChatApiRequest,
    success: ChatApiSuccess,
  },
};
