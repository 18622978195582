export const setTableClassName = (ev: any, id: string) => {
  // find className With TableWrapper

  let cursor = ev.target;

  try {
    document.querySelectorAll('.TableRowActions-wrapper').forEach((el) => {
      const dataId = el.getAttribute('data-id');

      if (dataId === id) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });

    while (cursor) {
      if (typeof cursor?.className === 'string' && cursor.className.includes('Table-wrapper')) {
        break;
      }

      cursor = cursor.parentNode;
    }
  } catch (err) {}
};
