export function CopyIcon() {
  return (
    <svg
      fill='currentColor'
      width='20'
      height='21'
      viewBox='0 0 20 21'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.70837 5.413C2.70837 4.14735 3.73439 3.12134 5.00004 3.12134H11.6667C12.9324 3.12134 13.9584 4.14735 13.9584 5.413V6.45467H15C16.2657 6.45467 17.2917 7.48069 17.2917 8.74634V15.413C17.2917 16.6787 16.2657 17.7047 15 17.7047H8.33337C7.06772 17.7047 6.04171 16.6787 6.04171 15.413V14.3713H5.00004C3.73439 14.3713 2.70837 13.3453 2.70837 12.0797V5.413ZM7.29171 15.413C7.29171 15.9883 7.75808 16.4547 8.33337 16.4547H15C15.5753 16.4547 16.0417 15.9883 16.0417 15.413V8.74634C16.0417 8.17104 15.5753 7.70467 15 7.70467H8.33337C7.75808 7.70467 7.29171 8.17104 7.29171 8.74634V15.413ZM12.7084 6.45467H8.33337C7.06772 6.45467 6.04171 7.48069 6.04171 8.74634V13.1213H5.00004C4.42474 13.1213 3.95837 12.655 3.95837 12.0797V5.413C3.95837 4.83771 4.42474 4.37134 5.00004 4.37134H11.6667C12.242 4.37134 12.7084 4.83771 12.7084 5.413V6.45467Z'
      />
    </svg>
  );
}
