import StackdriverErrorReporter from 'stackdriver-errors-js';

export const errorHandler = new StackdriverErrorReporter();
const ERROR_REPORTING_ENV = window.__env__.VITE_ERROR_REPORTING_ENV || '';

if (ERROR_REPORTING_ENV) {
  errorHandler.start({
    key: window.__env__.VITE_ERROR_REPORTING_KEY,
    projectId: window.__env__.VITE_ERROR_REPORTING_PROJECT,
    service: `studio-webapp-${ERROR_REPORTING_ENV}`,
    disabled: ['development', 'test'].includes(window.__env__.NODE_ENV ?? ''),
  });
}
