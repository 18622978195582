import { AuthRoutes, AuthStageRoutes, GroupedRoutes, Location, RouteType } from '../types';

export const getRouteType = (location: Location, routes: GroupedRoutes): RouteType => {
  const {
    publicRoutes = {},
    externalRoutes = {},
    privateRoutes = {},
    authRoutes = {},
    authStageRoutes = {},
  } = routes;

  let route: string | undefined;

  route = Object.keys(authStageRoutes).find((route) => isSameRoute(location, route));
  if (route) {
    return 'authStage';
  }

  route = Object.keys(authRoutes).find((route) => isSameRoute(location, route));
  if (route) {
    return 'auth';
  }

  route = Object.keys(publicRoutes).find((route) => isSameRoute(location, route));
  if (route) {
    return 'public';
  }

  route = Object.keys(externalRoutes).find((route) => isSameRoute(location, route));
  if (route) {
    return 'external';
  }

  route = Object.keys(privateRoutes).find((route) => isSameRoute(location, route));

  if (route) {
    return 'private';
  }

  return 'unknown';
};

export const isSameRoute = (location: Location, routeWithVariables: string) => {
  const hasVariables = routeWithVariables.includes(':');

  if (hasVariables) {
    const regex = new RegExp(routeWithVariables.replace(/:[^/]+/g, '[^/]+'));
    return regex.test(location.pathname);
  }

  return location.pathname === routeWithVariables;
};

export const getLocation = (): Location => {
  const { pathname, search, hash } = document.location;

  return {
    pathname,
    search,
    hash,
  };
};
