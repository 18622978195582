import { api } from '@ai21/studio-api';
import { call } from 'saga-ts';
import { authState } from '../../utils/globals';
import { logger } from '../../utils/log';

export function* getUser(_user: any) {
  const { lastProvider } = authState;

  const extraParams = getExtraParams();

  const response = yield* call(api.user.get, extraParams);
  const { errorMessage = '', errorType = '' } = response;

  logger.GetExistingUser({ lastProvider, errorMessage, errorType });

  return response;
}

// step #11
export function* createUser(_user: any) {
  const { lastProvider } = authState;

  const extraParams = getExtraParams();

  const response = yield* call(api.user.create, extraParams);
  const { errorMessage = '', errorType = '' } = response;
  logger.CreateNewUser({ lastProvider, errorMessage, errorType });

  return response;
}

export const getExtraParams = () => {
  const invitation_id = getInvitationCodeFromSearch();

  return {
    invitation_id,
  };
};

export const getInvitationCodeFromSearch = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const code = searchParams.get('invitation_id');

  return code;
};
