import generations from './json/form.generations.json';
import generationsEdit from './json/form.generationsEdit.json';
import inspector from './json/form.inspector.json';
import generationsDefault from './form.generations.detaults.json';

export const forms = {
  generations,
  generationsEdit,
  inspector,
};

export const formDefaults = {
  generationsDefault,
  inspector: {},
};
