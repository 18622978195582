import styled from 'styled-components';

export const Wrapper = styled.div<{ size: number }>`
  svg {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;

    path {
      border: 1px solid #333;
    }
  }

  position: relative;

  &:after {
    content: '';

    // radial gradient
    background: radial-gradient(
      circle at 50% 50%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
`;
