import { Icon, Tooltip } from '@ai21/studio-base-ui';
import classnames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TableContext } from '../../context/Table.context';
import { ITableRowAction } from '../../types';
import { checkShowIf } from '../../utils/relations';
import { TableRowActionsMenu } from '../TableRowActionsMenu/TableRowActionsMenu';
import { Gap, MainAction, MenuIcon, Row, Wrapper } from './TableRowActionsCell.style';

export type TableRowActionsCellProps = {
  rowIndex: number;
  actions?: ITableRowAction[];
  onRowAction: (itemId: string, actionId: string) => void;
  item: Json;
};

export function TableRowActionsCell(props: TableRowActionsCellProps) {
  const { rowIndex, actions = [], item } = props;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { callbacks, state, isMobile } = useContext(TableContext);
  const { id } = item;
  const { hoveredRowId } = state;

  const isSelected = hoveredRowId === item.id;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onActionClick = (actionId: string) => {
    handleClose();
    props.onRowAction(item.id, actionId);
  };

  useEffect(() => {
    const nextValue = anchorEl ? rowIndex : undefined;
    callbacks.onSetActiveRowIndex(nextValue);
  }, [anchorEl, rowIndex]);

  const menuActions = useMemo(() => {
    return actions
      .filter((action) => !action.showIf)
      .filter((action) => !action.isMain || isMobile);
  }, [actions]);

  function renderMainAction() {
    const mainAction = actions.find((action) => action.isMain);

    if (!mainAction) {
      return null;
    }

    const { id, iconName, title, tooltip, showIf } = mainAction;

    const shouldShow = checkShowIf(item, showIf);

    if (!shouldShow || isMobile) {
      return null;
    }

    const className = classnames('main-button', {
      isHidden: !isSelected,
    });

    return (
      <>
        <Tooltip
          title={tooltip}
          enterDelay={500}
          enterNextDelay={500}
          className={className}
          key={id}
        >
          <MainAction
            size='small'
            variant='outlined'
            color='secondary'
            onClick={() => onActionClick(mainAction.id)}
            style={{ cursor: 'pointer', fontSize: 14, height: 30 }}
            data-testid='action-main'
          >
            {title}
          </MainAction>
        </Tooltip>
        <Gap />
      </>
    );
  }

  function renderMenuIcon() {
    if (menuActions.length === 0) {
      return null;
    }

    return (
      <MenuIcon
        aria-label='more'
        size='small'
        aria-controls={anchorEl ? 'long-menu' : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-haspopup='true'
        data-testid='actions-menu'
        onMouseDown={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        iconName='DotsVertical'
      />
    );
  }

  function renderActions() {
    if (!anchorEl) {
      return null;
    }

    return (
      <TableRowActionsMenu
        actions={menuActions}
        onRowAction={onActionClick}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    );
  }

  return (
    <Wrapper className='TableRowActions-wrapper' data-id={id} data-testid='TableRowActions-wrapper'>
      <Row className='actions'>
        {renderMainAction()}
        {renderMenuIcon()}
        {renderActions()}
      </Row>
    </Wrapper>
  );
}

export default TableRowActionsCell;
