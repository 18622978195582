export function PlaygroundIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.25 7.20005C11.25 6.28878 10.5113 5.55005 9.60002 5.55005H8.40002C7.98581 5.55005 7.65002 5.21426 7.65002 4.80005C7.65002 4.38584 7.98581 4.05005 8.40002 4.05005H9.60002C10.5614 4.05005 11.4222 4.48075 12 5.15973C12.5778 4.48075 13.4386 4.05005 14.4 4.05005H15.6C16.0142 4.05005 16.35 4.38584 16.35 4.80005C16.35 5.21426 16.0142 5.55005 15.6 5.55005H14.4C13.4888 5.55005 12.75 6.28878 12.75 7.20005V16.8C12.75 17.7113 13.4888 18.45 14.4 18.45H15.6C16.0142 18.45 16.35 18.7858 16.35 19.2C16.35 19.6143 16.0142 19.95 15.6 19.95H14.4C13.4386 19.95 12.5778 19.5193 12 18.8404C11.4222 19.5193 10.5614 19.95 9.60002 19.95H8.40002C7.98581 19.95 7.65002 19.6143 7.65002 19.2C7.65002 18.7858 7.98581 18.45 8.40002 18.45H9.60002C10.5113 18.45 11.25 17.7113 11.25 16.8V7.20005ZM17.4696 7.86957C17.1767 8.16247 17.1767 8.63734 17.4696 8.93023L20.5392 11.9999L17.4696 15.0696C17.1767 15.3625 17.1767 15.8373 17.4696 16.1302C17.7625 16.4231 18.2373 16.4231 18.5302 16.1302L22.1302 12.5302C22.4231 12.2373 22.4231 11.7625 22.1302 11.4696L18.5302 7.86957C18.2373 7.57668 17.7625 7.57668 17.4696 7.86957ZM6.53023 8.93023C6.82313 8.63734 6.82313 8.16247 6.53023 7.86957C6.23734 7.57668 5.76247 7.57668 5.46957 7.86957L1.86957 11.4696C1.57668 11.7625 1.57668 12.2373 1.86957 12.5302L5.46957 16.1302C5.76247 16.4231 6.23734 16.4231 6.53023 16.1302C6.82313 15.8373 6.82313 15.3625 6.53023 15.0696L3.46056 11.9999L6.53023 8.93023Z'
        fill='currentColor'
      />
    </svg>
  );
}
