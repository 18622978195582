import { PayloadCompletion } from '../types';

export const prepareCompletionPayload = (payload: PayloadCompletion) => {
  const output: any = { ...payload };

  if (output.epoch === '0' || output.epoch === 0) {
    delete output.epoch;
  }

  delete output.customType;

  const {
    presencePenalty,
    countPenalty,
    frequencyPenalty,
    applyToWhitespaces,
    applyToPunctuations,
    applyToNumbers,
    applyToStopwords,
    applyToEmojis,
  } = output;

  return {
    ...output,
    presencePenalty: {
      scale: presencePenalty.scale !== undefined ? presencePenalty.scale : presencePenalty,
      applyToWhitespaces: applyToWhitespaces || presencePenalty.applyToWhitespaces,
      applyToPunctuations: applyToPunctuations || presencePenalty.applyToPunctuations,
      applyToNumbers: applyToNumbers || presencePenalty.applyToNumbers,
      applyToStopwords: applyToStopwords || presencePenalty.applyToStopwords,
      applyToEmojis: applyToEmojis || presencePenalty.applyToEmojis,
    },
    countPenalty: {
      scale: countPenalty.scale !== undefined ? countPenalty.scale : countPenalty,
      applyToWhitespaces: applyToWhitespaces || countPenalty.applyToWhitespaces,
      applyToPunctuations: applyToPunctuations || countPenalty.applyToPunctuations,
      applyToNumbers: applyToNumbers || countPenalty.applyToNumbers,
      applyToStopwords: applyToStopwords || countPenalty.applyToStopwords,
      applyToEmojis: applyToEmojis || countPenalty.applyToEmojis,
    },
    frequencyPenalty: {
      scale: frequencyPenalty.scale !== undefined ? frequencyPenalty.scale : frequencyPenalty,
      applyToWhitespaces: applyToWhitespaces || frequencyPenalty.applyToWhitespaces,
      applyToPunctuations: applyToPunctuations || frequencyPenalty.applyToPunctuations,
      applyToNumbers: applyToNumbers || frequencyPenalty.applyToNumbers,
      applyToStopwords: applyToStopwords || frequencyPenalty.applyToStopwords,
      applyToEmojis: applyToEmojis || frequencyPenalty.applyToEmojis,
    },
  };
};
