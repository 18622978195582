import { apiUrl } from '../globals';
import { sanitizeCode } from '../utils/snippets';
import {
  getParaphraseCurlCodeSnippet,
  getParaphraseJsCodeSnippet,
  getParaphrasePythonCodeSnippet,
} from './codeBlocks';
import { GenerateSnippetsParams } from './snippets.types';

const url = (apiHost: string) => `${apiHost}/paraphrase`;

export const generate = (params: GenerateSnippetsParams) => {
  return {
    python: python(params),
    javascript: javascript(params),
    curl: curl(params),
  };
};

const python = (params: GenerateSnippetsParams) => {
  const { main, apiParams } = params as any;
  return getParaphrasePythonCodeSnippet(url(apiUrl), sanitizeCode(main), apiParams?.style);
};

const javascript = (params: GenerateSnippetsParams) => {
  const { main, apiParams } = params as any;
  return getParaphraseJsCodeSnippet(url(apiUrl), sanitizeCode(main), apiParams?.style);
};

const curl = (params: GenerateSnippetsParams) => {
  const { main, apiParams } = params as any;
  return getParaphraseCurlCodeSnippet(url(apiUrl), sanitizeCode(main), apiParams?.style);
};
