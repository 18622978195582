import { actions } from '@ai21/studio-store';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDemo } from '../../hooks/useDemo';
import { DemosFramePage } from './DemosFramePage';
import DemosPinCodePageContainer from '../demosPinCodePage/DemosPinCodePage.container';
import { RagEngineDemoPage } from '../RagEngineDemoPage/RagEngineDemoPage';

export function DemosFramePageContainer() {
  const dispatch = useDispatch();
  const [data, isLoading] = useDemo();
  const [isUnlocked, setIsUnlocked] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      dispatch(actions.appState.patch({ isIntercomVisible: false }));
    }, 500);

    return () => {
      dispatch(actions.appState.patch({ isIntercomVisible: true }));
    };
  }, []);

  const callbacks = useMemo(
    () => ({
      onNavigate: (to: string) => {
        dispatch({
          type: 'NAVIGATE',
          to,
        });
      },
      onClick: () => {
        dispatch({
          type: 'DEMOS',
          verb: 'showDocLibrary',
        });
      },
      onClear: () => {
        dispatch({
          type: 'DOCUMENT',
          verb: 'clear',
          params: { source: 'demo' },
        });
      },
    }),
    []
  );

  if (data?.isPrivate && !isUnlocked) {
    return <DemosPinCodePageContainer isUnlocked={setIsUnlocked} />;
  }

  if (data?.company === 'RagEngineDemo') {
    return (
      <RagEngineDemoPage
        data={data}
        isLoading={isLoading}
        callbacks={callbacks}
      />
    );
  }

  return (
    <DemosFramePage data={data} isLoading={isLoading} callbacks={callbacks} />
  );
}

export default DemosFramePageContainer;
