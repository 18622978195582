import { ChooseHyperParametersOrigin } from '../../data-types/ChooseHyperParametersOrigin';
import { CodeSnippetModalContext, ProgrammingLanguage } from '../../data-types/CodeSnippet';
import {
  CompletionRequestParams,
  ModelCostChanged,
  ModelTrainSettingsChanged,
} from '../../data-types/Completion';
import { CustomModelType, ModelEndpointListElement } from '../../data-types/Model';
import {
  DatasetsUploadingErrorType,
  POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE,
  POST_DATASET_FAILURE,
  POST_DATASET_STARTED,
  PostDatasetSuccess,
} from './datasetsActionTypes';

export const FETCH_MODELS_STARTED = 'FETCH_MODELS_STARTED';
export const FETCH_MODELS_SUCCESS = 'FETCH_MODELS_SUCCESS';
export const FETCH_MODELS_FAILURE = 'FETCH_MODELS_FAILURE';
export const POST_MODEL_STARTED = 'POST_MODEL_STARTED';
export const POST_MODEL_SUCCESS = 'POST_MODEL_SUCCESS';
export const POST_MODEL_FAILURE = 'POST_MODEL_FAILURE';
export const RESET_MODELS_PAGE_STATE = 'RESET_MODELS_PAGE_STATE';
export const NEW_CUSTOM_MODEL = 'NEW_CUSTOM_MODEL';
export const CHOOSE_HYPER_PARAMETERS = 'CHOOSE_HYPER_PARAMETERS';
export const FETCH_MODEL_COST_STARTED = 'FETCH_MODEL_COST_STARTED';
export const FETCH_MODEL_COST_FAILURE = 'FETCH_MODEL_COST_FAILURE';
export const FETCH_MODEL_COST_SUCCESS = 'FETCH_MODEL_COST_SUCCESS';
export const API_MODAL_SHOWN = 'API_MODAL_SHOWN';
export const API_MODAL_COPIED = 'API_MODAL_COPIED';
export const CUSTOM_MODEL_TRY_IN_PLAYGROUND_PRESSED = 'CUSTOM_MODEL_TRY_IN_PLAYGROUND_PRESSED';
export const SET_SELECTED_CUSTOM_MODEL = 'SET_SELECTED_CUSTOM_MODEL';
export const TRAIN_SETTINGS_CHANGED = 'TRAIN_SETTINGS_CHANGED';
export const MODEL_COST_CHANGED = 'MODEL_COST_CHANGED';
export const DELETE_MODEL_STARTED = 'DELETE_MODEL_STARTED';
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS';
export const DELETE_MODEL_PRESSED = 'DELETE_MODEL_PRESSED';
export const DELETE_MODEL_FAILED = 'DELETE_MODEL_FAILED';
export const SHOW_DELETE_MODEL_DIALOG = 'SHOW_DELETE_MODEL_DIALOG';

export interface CustomModelTryInPlaygroundPressed {
  type: typeof CUSTOM_MODEL_TRY_IN_PLAYGROUND_PRESSED;
  customModelId: string;
}

export interface ApiModalShown {
  type: typeof API_MODAL_SHOWN;
  context: CodeSnippetModalContext;
  customModelId: string;
  language: ProgrammingLanguage;
  completionParams: CompletionRequestParams;
}

export interface ApiModalCopied {
  type: typeof API_MODAL_COPIED;
  context: CodeSnippetModalContext;
  customModelId: string;
  language: ProgrammingLanguage;
  completionParams: CompletionRequestParams;
}

export enum ModelsUploadingErrorType {
  MODEL_ERROR_1 = 'MODEL_ERROR_1',
  CREDIT_EXCEEDED = 'CREDIT_EXCEEDED',
  TRIAL_ENDED = 'TRIAL_ENDED',
}

export interface FetchModelCostStarted {
  type: typeof FETCH_MODEL_COST_STARTED;
}

export interface FetchModelCostFailure {
  type: typeof FETCH_MODEL_COST_FAILURE;
}

export interface FetchModelCostSuccess {
  type: typeof FETCH_MODEL_COST_SUCCESS;
  cost: number;
  modelEpochs: number;
}

export interface ChooseHyperParameters {
  type: typeof CHOOSE_HYPER_PARAMETERS;
  origin: ChooseHyperParametersOrigin;
  datasetDetails: {
    datasetName: string;
    sizeBytes: number;
    modelType: CustomModelType;
    datasetId: string;
  };
}

export interface CreateNewCustomModel {
  type: typeof NEW_CUSTOM_MODEL;
}

export interface ResetModelsPageState {
  type: typeof RESET_MODELS_PAGE_STATE;
}

export interface GetModelsStarted {
  type: typeof FETCH_MODELS_STARTED;
}

export interface GetModelsFailed {
  type: typeof FETCH_MODELS_FAILURE;
  error: string;
}

export interface GetModelsSuccess {
  type: typeof FETCH_MODELS_SUCCESS;
  shouldApplyStateChange: boolean;
  payload: ModelEndpointListElement[];
}

export interface PostModelStarted {
  type: typeof POST_MODEL_STARTED;
}

export interface PostModelFailed {
  type: typeof POST_MODEL_FAILURE;
  uploadModelError: ModelsUploadingErrorType | undefined;
}

export interface PostModelSuccess {
  type: typeof POST_MODEL_SUCCESS;
  payload: ModelEndpointListElement;
}

export interface PostDatasetStarted {
  type: typeof POST_DATASET_STARTED;
  datasetName: string;
  datasetFile: File;
}

export interface PostDatasetFailed {
  type: typeof POST_DATASET_FAILURE;
  uploadDatasetError: DatasetsUploadingErrorType | undefined;
  uploadDatasetErrorDetails?: string | undefined;
}

export interface PostDatasetColumnsNotSpecifiedError {
  type: typeof POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE;
}

export interface SetSelectedCustomModel {
  type: typeof SET_SELECTED_CUSTOM_MODEL;
  customModelType: CustomModelType | undefined;
}

export interface TrainSettingsChanged {
  type: typeof TRAIN_SETTINGS_CHANGED;
  payload: Partial<ModelTrainSettingsChanged>;
}

export interface CostChanged {
  type: typeof MODEL_COST_CHANGED;
  payload: Partial<ModelCostChanged>;
}

export interface DeleteModelStarted {
  type: typeof DELETE_MODEL_STARTED;
  name: string;
}

export interface DeleteModelFailed {
  type: typeof DELETE_MODEL_FAILED;
  name: string;
  reason: string;
}

export interface DeleteModelSuccess {
  type: typeof DELETE_MODEL_SUCCESS;
  name: string;
  id: string;
  requestDuration: number;
}

export interface DeleteModelPressed {
  type: typeof DELETE_MODEL_PRESSED;
  modelId: string;
}

export interface ShowDeleteModelDialog {
  type: typeof SHOW_DELETE_MODEL_DIALOG;
  showDeleteModelDialog: boolean;
}

export type CustomModelsActionTypes =
  | GetModelsStarted
  | GetModelsSuccess
  | GetModelsFailed
  | PostModelStarted
  | PostModelSuccess
  | PostModelFailed
  | PostDatasetStarted
  | PostDatasetSuccess
  | PostDatasetFailed
  | PostDatasetColumnsNotSpecifiedError
  | ResetModelsPageState
  | ChooseHyperParameters
  | FetchModelCostStarted
  | FetchModelCostFailure
  | FetchModelCostSuccess
  | ApiModalShown
  | ApiModalCopied
  | CustomModelTryInPlaygroundPressed
  | CreateNewCustomModel
  | SetSelectedCustomModel
  | TrainSettingsChanged
  | CostChanged
  | DeleteModelFailed
  | DeleteModelStarted
  | DeleteModelSuccess
  | ShowDeleteModelDialog
  | DeleteModelPressed;
