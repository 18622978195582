import { Box, Button, Dialog, DialogActions, Grid, Link, Typography } from '@ai21/studio-ui';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TRAINING_DATA_LINK } from '../../constants/constants';
import { MODAL_DISMISSED, MODAL_SHOWN } from '../../redux/actionTypes/userActionTypes';
import { Dropzone } from '../upload.Dropzone/Dropzone';
import { DatasetUploadStyles } from './DatasetUploadStyles';

interface UploadDatasetDialogInitialStateProps {
  onClose: () => void;
  onFileSelected: CallableFunction;
  downloadExampleDataset: () => void;
  onTechnicalDocumentationClick: () => void;
}

export function UploadDatasetDialogInitialState({
  onClose,
  onFileSelected,
  downloadExampleDataset,
  onTechnicalDocumentationClick,
}: UploadDatasetDialogInitialStateProps) {
  const classes = DatasetUploadStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: MODAL_SHOWN,
      payload: { origin: 'dataset', text: 'Upload dataset file', name: 'datasetUpload' },
    });

    return () => {
      dispatch({
        type: MODAL_DISMISSED,
        payload: { origin: 'dataset', reason: 'next step', name: 'datasetUpload' },
      });
    };
  }, []);

  return (
    <div className={classes.dialogContainer}>
      <Grid container justifyContent='space-between'>
        <Typography className={classes.title} variant='h5'>
          Upload dataset file
        </Typography>
      </Grid>
      <Box marginTop='16px' />
      <Dropzone onFileSelected={onFileSelected} error={null} useFullDimensions={false} />
      <Typography variant='body1' style={{ marginTop: 20 }}>
        JSONL or CSV files up to 1GB are allowed.{' '}
        <Link
          data-testid='link-download-example-dataset'
          className={classes.link}
          variant='inherit'
          underline='none'
          onClick={downloadExampleDataset}
        >
          Download the example CSV file.
        </Link>
        <br />
        See our{' '}
        <Link
          className={classes.link}
          data-testid='link-technical-documentation'
          variant='inherit'
          underline='none'
          target='_blank'
          href={TRAINING_DATA_LINK}
          onClick={onTechnicalDocumentationClick}
        >
          technical documentation
        </Link>{' '}
        for more information.
      </Typography>

      <Box marginTop='16px' />
      <DialogActions sx={{ marginBottom: 1 }}>
        <Button
          data-testid='dataset-cancel-dialog-btn'
          variant='outlined'
          size='medium'
          color='secondary'
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
}
function dispatch(arg0: { type: any }) {
  throw new Error('Function not implemented.');
}
