import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(Dialog)`
  padding-bottom: 10px;
  min-width: 450px;

  @media (max-width: 768px) {
    min-width: 350px;
  }

  > div > div {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
`;

export const Header = styled(DialogTitle)`
  flex: 1;
  display: flex;
  height: 40px;
  line-height: 30px;
  padding: 16px 16px 16px 24px;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled(Typography)`
  flex: 2;
  color: #262123;
  font-size: 20px;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  flex: 1;

  &.mobile {
    justify-content: space-between;
  }

  i {
    color: rgba(255, 255, 255, 0.4);
    cursor: pointer;

    &:hover {
      color: gold;
    }

    &:active {
      bottom: 1px;
      left: 1px;
      position: relative;
    }
  }
`;

export const Content = styled(DialogContent)`
  padding: 0;
`;
