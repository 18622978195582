import * as jose from 'jose';

export async function getReadmeToken(email: string, name: string, apiKey: string) {
  const key = window.__env__.VITE_README_JWT_SECRET;
  const secret = new TextEncoder().encode(key);
  const alg = 'HS256';

  const jwt = await new jose.SignJWT({
    name,
    email,
    apiKey,
    Authorization: apiKey,
  })
    .setProtectedHeader({ alg })
    .sign(secret);

  return jwt;
}
