import { all } from './snippets.all';
import { GenerateSnippetsParams } from './snippets.types';

export const generateCode = (params: GenerateSnippetsParams) => {
  const { flavour } = params;

  const generateMethod = (all as any)[flavour];

  return generateMethod(params);
};
