import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const Content = styled.div`
  flex: 1;
  height: 0;
  overflow: auto;
`;

export const Bottom = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #e6eaec;
`;
