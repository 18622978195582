export const resolveCtaButtonVariant = (
  isDeprecated: boolean,
  isBeta: boolean,
  isBetaEnabled: boolean
) => {
  if (isDeprecated || !isBeta) {
    return 'outlined';
  }

  return isBetaEnabled ? 'outlined' : 'contained';
};

export const resolveCtaButtonColorPalette = (
  isDeprecated: boolean,
  isBeta: boolean,
  isBetaEnabled: boolean
) => {
  if (isDeprecated || !isBeta) {
    return 'secondary';
  }

  return isBetaEnabled ? 'secondary' : 'primary';
};
