import { ListItemIcon, Menu, MenuItem } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled(Menu)`
  width: 300px;
`;

export const ListIcon = styled(ListItemIcon)`
  min-width: 32px !important;

  svg {
    color: ${tokens.text.primary};
  }
`;

export const Action = styled(MenuItem)`
  color: ${tokens.text.primary};
  height: 36px;
  padding: 10px;
  margin: 1px 8px;
  border-radius: 8px;

  &:hover {
    background: ${tokens.primary.backgroundHover};
  }

  &.delete {
    color: ${tokens.error.mainDefault};

    svg {
      fill: ${tokens.error.mainDefault};
    }
  }
`;
