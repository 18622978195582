import { actions } from '@ai21/studio-store';
import { fork, put, takeEvery } from 'saga-ts';
import { getBoolean, setBoolean } from 'shared-base';
import { predicateAppState } from './predicates';
import { get } from 'lodash';

const isMobile = /Mobi|Android/i.test(navigator.userAgent);

function* saveSidebar(action: any) {
  const slimSideBar = get(action, 'payload.slimSideBar');
  setBoolean('SIDEBAR_SLIM', slimSideBar);
}

export function* loadSidebar() {
  yield put(actions.appState.patch({ slimSideBar: isMobile }));
}

export function* root() {
  yield* fork(loadSidebar);
  yield takeEvery(predicateAppState('slimSideBar'), saveSidebar);
}
