import { actions, selectors } from '@ai21/studio-store';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntercom as useIntercomOriginal } from 'react-use-intercom';
import { hideIntercomPaths } from '../bootstrap/Web.routes';
import { LoginContext } from '@ai21/studio-auth';

export function useIntercom() {
  const { update } = useIntercomOriginal();
  const { userStatus, userEmail } = useContext(LoginContext).state;
  const location = useLocation();
  const appState = useSelector(selectors.raw.$rawAppState);
  const dispatch = useDispatch();
  const { isIntercomVisible } = appState;

  useEffect(() => {
    if (!window.Intercom) {
      return;
    }

    update({
      hideDefaultLauncher: !isIntercomVisible,
    });
  }, [isIntercomVisible]);

  useEffect(() => {
    if (userStatus !== 'VERIFIED' || !userEmail) {
      return;
    }

    update({
      email: userEmail,
      userId: userEmail,
    });
  }, [userStatus, userEmail]);

  // TODO: this should be in the future-global navigation saga
  useEffect(() => {
    const isIntercomHidden = hideIntercomPaths.find((p) => location.pathname.startsWith(p));

    dispatch(
      actions.appState.patch({
        isIntercomVisible: !isIntercomHidden,
      })
    );
  }, [location.pathname]);
}
