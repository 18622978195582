import styled from 'styled-components';
import { Button, Typography } from '@ai21/studio-ui';
import { tokens } from '@ai21/studio-ui';

export const Wrapper = styled.div`
  flex: 1;

  button {
    margin-bottom: 8px;
  }
`;

export const Action = styled(Button)`
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 6px 10px;

  svg {
    stroke-width: 1.5;
    fill: currentColor;
  }

  &.primary {
    color: ${tokens.neutral.brightest};
    background-color: ${tokens.primitives.grey[900]};

    &:hover {
      background-color: ${tokens.primitives.grey[1000]};
    }
  }
`;

export const ActionsSection = styled.div`
  margin-bottom: 24px;
`;
