import { Button, IconButton } from '@ai21/studio-base-ui';
import { TextField } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Cta = styled(Button)``;

export const IconCta = styled(IconButton)`
  margin-left: 8px;
  svg {
    stroke-width: 1.5px;
    height: 18px;
    width: 18px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px 0 8px;
  height: 48px;
  border-top: 1px solid transparent;

  &.input {
    height: 60px;

    button {
      margin-left: 8px;
    }

    ${Cta}, ${IconCta} {
      height: 36px;
    }
  }
`;

export const TextArea = styled(TextField)`
  max-height: 100px;

  fieldset {
    border: none;
  }

  textarea {
    font-size: 14px;
    line-height: 1.4;
    padding: 16px;
    outline: none;
    box-sizing: border-box;
  }
`;

export const ExtraActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
`;

export const Copy = styled(IconButton)`
  margin-right: 8px;
`;
