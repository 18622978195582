import { Drawer as DrawerMui } from '@mui/material';
import { invokeEvent } from 'shared-base';

export type DrawerProps = {
  id: string;
  show: boolean;
  hideBackdrop?: boolean;
  variant: 'persistent' | 'temporary';
  params: Json;
  onClose: () => void;
};

export function Drawer(props: DrawerProps) {
  const { params, variant, hideBackdrop, show } = props;

  const { id } = params;

  function onClose() {
    invokeEvent('global/drawer', {
      type: 'basic',
      action: 'close',
      id,
    });

    props.onClose();
  }

  function renderInner() {
    const Custom = params.component;

    if (typeof Custom !== 'function') {
      return null;
    }

    return <Custom {...params.componentProps} onClose={onClose} />;
  }

  return (
    <DrawerMui
      anchor='right'
      open={show}
      hideBackdrop={hideBackdrop}
      variant={variant}
      ModalProps={{ onBackdropClick: onClose }}
      PaperProps={{
        sx: {
          borderRadius: 0,
          boxShadow:
            '0px 10px 13px -6px rgba(0, 0, 0, 0.20), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
        },
      }}
    >
      {renderInner()}
    </DrawerMui>
  );
}

export default Drawer;
