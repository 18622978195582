import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  TextField,
  Typography,
  Icon,
} from '@ai21/studio-ui';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DUPLICATE_DATASET_NAME_ERROR_MESSAGE,
  INVALID_DATASET_NAME_ERROR_MESSAGE,
} from '../../constants/constants';
import { SelectedColumns } from '../../data-types/Dataset';
import { getDatasetsList } from '../../redux/selectors';
import { tokens } from '@ai21/studio-ui';
import DatasetUploadStyles from './DatasetUploadStyles';
import { MODAL_DISMISSED, MODAL_SHOWN } from '../../redux/actionTypes/userActionTypes';

interface UploadDatasetDialogNamingStateProps {
  onClose: () => void;
  datasetName: string | undefined;
  saveDataset: (props: {
    name?: string;
    selectedColumns?: SelectedColumns;
    approveWhitespaceCorrection?: boolean;
    deleteLongRows?: boolean;
  }) => void;
}

export function UploadDatasetDialogNamingState({
  onClose,
  datasetName,
  saveDataset,
}: UploadDatasetDialogNamingStateProps) {
  const dispatch = useDispatch();
  const datasetsList = useSelector(getDatasetsList);
  const [fileExtension, setFileExtension] = useState(datasetName?.split('.').pop());
  const [fileName, setFileName] = useState(datasetName?.split('.').slice(0, -1).join('.') || '');
  const [datasetNameViolatesRules, setDatasetNameViolatesRules] = useState(false);
  const [duplicateDatasetName, setDuplicateDatasetName] = useState(false);

  useEffect(() => {
    dispatch({
      type: MODAL_SHOWN,
      payload: { origin: 'dataset', text: 'Name your dataset', name: 'nameDataset' },
    });

    return () => {
      dispatch({
        type: MODAL_DISMISSED,
        payload: { origin: 'dataset', reason: 'next step', name: 'nameDataset' },
      });
    };
  }, []);

  useEffect(() => {
    if (fileName.includes(' ')) {
      setFileName(fileName.replace(' ', '-'));
    }
    if (fileName.length > 30 || !/^[a-zA-Z0-9_.-]+$/.test(fileName)) {
      setDatasetNameViolatesRules(true);
      return;
    }
    setDatasetNameViolatesRules(false);

    const datasetWithSameName = datasetsList?.find((e) => e.datasetName === fileName);
    if (datasetWithSameName) {
      setDuplicateDatasetName(true);
    } else {
      setDuplicateDatasetName(false);
    }
  }, [fileName]);

  const helperText = useCallback(() => {
    if (datasetNameViolatesRules) {
      return INVALID_DATASET_NAME_ERROR_MESSAGE;
    }
    if (duplicateDatasetName) {
      return DUPLICATE_DATASET_NAME_ERROR_MESSAGE;
    }
    return '';
  }, [datasetNameViolatesRules, duplicateDatasetName]);

  const classes = DatasetUploadStyles();
  const endAdornment = (
    <div style={{ display: 'flex', alignSelf: 'center', marginRight: 16 }}>
      <Icon iconName='Edit' />
    </div>
  );
  const startAdornment = (
    <IconButton
      onClick={() => {}}
      style={{ padding: 2, marginLeft: 16, marginRight: -6 }}
      size='large'
      iconName='Database'
    />
  );

  return (
    <div className={classes.dialogContainer}>
      <Grid container justifyContent='space-between'>
        <Typography className={classes.title} variant='h5'>
          Name your dataset
        </Typography>
      </Grid>
      <Box marginTop='16px' />
      <Typography
        variant='body1'
        style={{
          marginTop: 20,
          fontSize: 20,
          fontWeight: 300,
          color: `${tokens?.text?.secondary ? tokens?.text?.secondary : '#676465'}`,
        }}
      >
        Your dataset is almost ready for your next steps! Let’s first choose name for you dataset:
      </Typography>
      <Box marginTop='36px' />
      <TextField
        InputLabelProps={{
          style: { fontSize: '16px' },
        }}
        autoFocus
        style={{ height: 80 }}
        label='Dataset name'
        value={fileName}
        placeholder={fileName}
        data-testid='dataset-choose-name-input'
        fullWidth
        helperText={helperText()}
        FormHelperTextProps={{
          style: {
            fontSize: '12px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.5,
            letterSpacing: '0.15px',
            color: '#D20F03',
            width: '100%',
          },
        }}
        InputProps={{
          style: {
            height: 56,
            fontSize: '15px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.43,
            letterSpacing: '0.15px',
            paddingTop: 24,
            paddingBottom: 24,
          },
          startAdornment,
          endAdornment,
        }}
        onChange={(e) => {
          setFileName(e.target.value);
        }}
      />
      <Box marginTop='50px' />
      <DialogActions sx={{ marginBottom: 1 }}>
        <Button
          data-testid='dataset-cancel-dialog-btn'
          variant='outlined'
          size='medium'
          color='secondary'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid='dataset-continue-dialog-btn'
          variant='contained'
          size='medium'
          color='primary'
          disabled={datasetNameViolatesRules || duplicateDatasetName}
          onClick={() => saveDataset({ name: `${fileName}.${fileExtension}` })}
        >
          Save and continue
        </Button>
      </DialogActions>
    </div>
  );
}
