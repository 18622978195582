export function NamedEntityRecognitionIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='6' fill='white' />
      <rect width='52' height='52' rx='6' fill='#595CA0' fillOpacity='0.1' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.8699 18.4166C24.0749 18.4166 22.6199 19.8717 22.6199 21.6666C22.6199 23.4616 24.0749 24.9166 25.8699 24.9166C27.6648 24.9166 29.1199 23.4616 29.1199 21.6666C29.1199 19.8717 27.6648 18.4166 25.8699 18.4166ZM21.1199 21.6666C21.1199 19.0433 23.2465 16.9166 25.8699 16.9166C28.4932 16.9166 30.6199 19.0433 30.6199 21.6666C30.6199 24.29 28.4932 26.4166 25.8699 26.4166C23.2465 26.4166 21.1199 24.29 21.1199 21.6666ZM23.8699 30.4166C22.0749 30.4166 20.6199 31.8717 20.6199 33.6666V35.6666C20.6199 36.0808 20.2841 36.4166 19.8699 36.4166C19.4557 36.4166 19.1199 36.0808 19.1199 35.6666V33.6666C19.1199 31.0433 21.2465 28.9166 23.8699 28.9166H27.8699C30.4932 28.9166 32.6199 31.0433 32.6199 33.6666V35.6666C32.6199 36.0808 32.2841 36.4166 31.8699 36.4166C31.4557 36.4166 31.1199 36.0808 31.1199 35.6666V33.6666C31.1199 31.8717 29.6648 30.4166 27.8699 30.4166H23.8699Z'
        fill='black'
      />
    </svg>
  );
}
