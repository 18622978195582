export enum TierType {
  FREE = 'FREE',
  BASIC = 'BASIC',
  SCALE = 'SCALE',
  NONE = 'NONE',
}

export enum PlanUpgradeOrigin {
  PLAN_TYPE_SECTION = 'PLAN_TYPE_SECTION',
  OVERVIEW_PAGE_SECTION = 'OVERVIEW_PAGE_SECTION',
  COMPLETION_MAX_CREDIT_ERROR = 'COMPLETION_MAX_CREDIT_ERROR',
}
