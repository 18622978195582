export enum CustomModelType {
  LIGHT_J2 = 'j2-light',
  MID_J2 = 'j2-mid',
  ULTRA_J2 = 'j2-ultra',
  JAMBA_1_5_LARGE = 'jamba-1.5-large',
  JAMBA_1_5_MINI = 'jamba-1.5-mini',
  JAMBA_INSTRUCT = 'jamba-instruct-preview',
}

export enum ModelType {
  GENERAL = 'GENERAL',
  CUSTOM = 'CUSTOM',
}

export enum ModelStatus {
  ACTIVE = 'ACTIVE',
  DISCONTINUED = 'DISCONTINUED',
  PENDING = 'PENDING',
  IN_TRAINING = 'IN_TRAINING',
}

export interface ModelMetadata {
  numEpochs: number;
  learningRate: number;
  cost: number;
  evalLoss: number;
  defaultEpoch: number;
}

export interface ModelEndpointListElement {
  id: string;
  name: string;
  status: string;
  datasetName: string;
  modelMetadata: ModelMetadata;
  creationDate: number;
  customModelType: CustomModelType | undefined;
  currentEpoch?: number;
  datasetId: string;
  modelType: string;
}
