import { InboxEditor } from '@ai21/studio-editors';
import { selectors } from '@ai21/studio-store';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type InboxEditorContainerProps = {
  onClose: () => void;
};

export function InboxEditorContainer(props: InboxEditorContainerProps) {
  const dispatch = useDispatch();
  const jobInfo = useSelector(selectors.evaluation.$evaluationJobInfo);
  const line = useSelector(selectors.evaluation.$evaluationLine);

  const callbacks = useMemo(
    () => ({
      onAction: (verb: string, params?: Json) => {
        dispatch({
          type: 'EVALUATION_INBOX',
          verb,
          params,
        });
      },
      onEvaluate: (score: number, lineId: string, params?: Json) => {
        dispatch({
          type: 'EVALUATION_INBOX',
          verb: 'evaluate',
          params: {
            score,
            lineId,
            ...params,
          },
        });
      },
      onTags: (tags: string[], lineId: string) => {
        dispatch({
          type: 'EVALUATION_INBOX',
          verb: 'evaluateTags',
          params: {
            tags,
            lineId,
          },
        });
      },
      onComment: (comment: string, lineId: string) => {
        dispatch({
          type: 'EVALUATION_INBOX',
          verb: 'evaluateComment',
          params: {
            comment,
            lineId,
          },
        });
      },
      onClose: (params?: Json) => {
        props.onClose();

        dispatch({
          type: 'EVALUATION_INBOX',
          verb: 'closeDrawer',
          params,
        });
      },
    }),
    []
  );

  return (
    <InboxEditor
      jobInfo={jobInfo}
      line={line}
      callbacks={callbacks}
      open={true}
    />
  );
}
