import { primitives } from '../theme/theme.primitives';
// version 0.0.1

export const tokenMaster = {
  PageBackground: {
    Default: primitives.grey[0], //Default page background
  },
};

export const tokens = {
  primary: {
    mainDefault: primitives.grey[800],
    mainHover: primitives.grey[950],
    mainActive: primitives.grey[1200],
    mainDark: primitives.grey[1000],
    mainLight: primitives.grey[300],
    mainContrast: primitives.grey[10],
    backgroundDefault: tokenMaster.PageBackground.Default,
    backgroundHover: primitives.grey[50],
    backgroundActive: primitives.grey[100],
    borderDefault: primitives.grey[200],
    borderHover: primitives.grey[300],
    borderActive: primitives.grey[300],
  },
  secondary: {
    mainDefault: primitives.grey[100],
    mainHover: primitives.grey[150],
    mainActive: primitives.grey[200],
    mainDark: primitives.grey[400],
    mainLight: primitives.grey[50],
    mainContrast: primitives.grey[1000],
    backgroundDefault: tokenMaster.PageBackground.Default,
    backgroundHover: primitives.grey[50],
    backgroundActive: primitives.grey[100],
    borderDefault: primitives.grey[200],
    borderHover: primitives.grey[300],
    borderActive: primitives.grey[300],
  },
  neutral: {
    white: primitives.grey[0],
    almostWhite: primitives.grey[10],
    brightestSuper: primitives.grey[50],
    brightest: primitives.grey[100],
    brighter: primitives.grey[200],
    bright: primitives.grey[300],
    deep: primitives.grey[400],
    deeper: primitives.grey[500],
    deepest: primitives.grey[900],
    almostBlack: primitives.grey[1000],
    black: primitives.grey[1200],
  },
  error: {
    mainDefault: primitives.red[700],
    mainHover: primitives.red[800],
    mainActive: primitives.red[900],
    mainDark: primitives.red[950],
    mainLight: primitives.red[400],
    mainContrast: primitives.red[0],
    backgroundDefault: tokenMaster.PageBackground.Default,
    backgroundHover: primitives.red[50],
    backgroundActive: primitives.red[100],
    borderDefault: primitives.red[400],
    borderHover: primitives.red[500],
    borderActive: primitives.red[500],
  },
  warning: {
    mainDefault: primitives.yellow[700],
    mainHover: primitives.yellow[800],
    mainActive: primitives.yellow[900],
    mainDark: primitives.yellow[950],
    mainLight: primitives.yellow[400],
    mainContrast: primitives.yellow[0],
    backgroundDefault: tokenMaster.PageBackground.Default,
    backgroundHover: primitives.yellow[50],
    backgroundActive: primitives.yellow[100],
    borderDefault: primitives.yellow[400],
    borderHover: primitives.yellow[500],
    borderActive: primitives.yellow[500],
  },
  success: {
    mainDefault: primitives.green[700],
    mainHover: primitives.green[800],
    mainActive: primitives.green[900],
    mainDark: primitives.green[950],
    mainLight: primitives.green[400],
    mainContrast: primitives.green[0],
    backgroundDefault: tokenMaster.PageBackground.Default,
    backgroundHover: primitives.green[50],
    backgroundActive: primitives.green[100],
    borderDefault: primitives.green[400],
    borderHover: primitives.green[500],
    borderActive: primitives.green[500],
  },
  disabled: {
    background: primitives.grey[50],
    text: primitives.grey[300],
    border: primitives.grey[100],
  },
  text: {
    primary: primitives.grey[1000],
    secondary: primitives.grey[500],
    tertiary: primitives.grey[400],
    disabled: primitives.grey[300],
    placeholder: primitives.grey[300],
    contrast: primitives.grey[0],
  },
  info: {
    mainDefault: primitives.blue[700],
    mainHover: primitives.blue[800],
    mainActive: primitives.blue[900],
    mainDark: primitives.blue[950],
    mainLight: primitives.blue[400],
    mainContrast: primitives.blue[0],
    backgroundDefault: tokenMaster.PageBackground.Default,
    backgroundHover: primitives.blue[50],
    backgroundActive: primitives.blue[100],
    borderDefault: primitives.blue[200],
    borderHover: primitives.blue[300],
    borderActive: primitives.blue[400],
  },
  component: {
    tableHeader: primitives.grey[10],
    tableRowDefault: tokenMaster.PageBackground.Default,
    tableRowHover: primitives.grey[50],
    tableBorders: primitives.grey[150],
    stickyBorder: primitives.grey[100],
    borderGlobal: primitives.grey[200],
    pageBackgroundDefault: tokenMaster.PageBackground.Default,
    spotlightNotification: primitives.blue[700],
    dividerDefault: primitives.grey[100],
    chatBubbleAssistant: primitives.component[1],
  },
  brand: {
    mainDefault: primitives.purple[600],
    mainHover: primitives.purple[700],
    mainActive: primitives.purple[800],
    mainDark: primitives.purple[950],
    mainLight: primitives.purple[300],
    mainContrast: primitives.purple[0],
    backgroundDefault: tokenMaster.PageBackground.Default,
    backgroundHover: primitives.purple[50],
    backgroundActive: primitives.purple[100],
    borderDefault: primitives.purple[300],
    borderHover: primitives.purple[400],
    borderActive: primitives.purple[500],
  },
  pink: {
    mainDefault: primitives.pink[600],
  },
  primitives: {
    ...primitives,
  },
  // Mui styles
  scrollerDefault: primitives.grey[200],
  divider: primitives.grey[100],
};
