export function TrainingIcon({
  height = 24,
  width = 24,
  light = false,
  style,
}: {
  height?: number;
  width?: number;
  light?: boolean;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.83 5.17a1 1 0 0 0-.366 1.366l1.5 2.598-6.928 4-1.5-2.598a1 1 0 0 0-1.366-.366l-2.598 1.5a1 1 0 0 0-.366 1.366l1.5 2.598a1 1 0 1 0 1 1.732l1.5 2.598a1 1 0 0 0 1.366.366l2.598-1.5a1 1 0 0 0 .366-1.366l-1.5-2.598 6.928-4 1.5 2.598a1 1 0 0 0 1.366.366l2.598-1.5a1 1 0 0 0 .366-1.366l-1.5-2.598a1 1 0 1 0-1-1.732l-1.5-2.598a1 1 0 0 0-1.366-.366l-2.598 1.5zm1.183 1.049 3.5 6.062 1.732-1-3.5-6.062-1.732 1zM7.255 18.78l-3.5-6.062 1.732-1 3.5 6.062-1.732 1z'
        fill={light ? '#9E9E9E' : '#753FEA'}
        // updated some of the button styles to fit design - Yuval
        style={style}
      />
    </svg>
  );
}
