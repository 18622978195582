export function TrainingSmall() {
  return (
    <svg width='14' height='14' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.22 3.447a.667.667 0 0 0-.244.91l1 1.732-4.619 2.667-1-1.732a.667.667 0 0 0-.91-.244l-1.732 1a.667.667 0 0 0-.245.91l1 1.733a.667.667 0 0 0 .667 1.154l1 1.732a.667.667 0 0 0 .91.244l1.733-1a.667.667 0 0 0 .244-.91l-1-1.732 4.619-2.667 1 1.732a.667.667 0 0 0 .91.244l1.732-1a.667.667 0 0 0 .245-.91l-1-1.733a.667.667 0 0 0-.667-1.154l-1-1.732a.667.667 0 0 0-.91-.244l-1.733 1zm.789.699 2.333 4.041 1.155-.666-2.334-4.042-1.154.667zM4.837 12.52 2.503 8.479l1.155-.666 2.333 4.041-1.154.667z'
        fill='#626B78'
      />
    </svg>
  );
}
