import { Location, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSetState, useUnmount } from 'react-use';
import type { IMenuItem } from '@ai21/studio-base-ui';
import { sortBy } from 'shared-base';

export type GroupedSideBarItems = {
  mainItems: IMenuItem[];
  childItems: IMenuItem[];
  isInner: boolean;
};

export function useSidebarItems(items: IMenuItem[]): GroupedSideBarItems {
  const location = useLocation();
  const timeout = useRef<number>();
  const [output, patch] = useSetState<GroupedSideBarItems>({
    mainItems: [],
    childItems: [],
    isInner: false,
  });

  useEffect(() => {
    const currentItem = findRoot(location, items);

    const mainItems = items.filter((item) => !item.isChild).sort(sortBy('order'));

    const childItems = items.filter(
      (item) => item.isChild && (item.path ?? '').startsWith(currentItem?.path ?? '')
    );

    const isInner = currentItem && childItems.length > 0;

    patch({
      mainItems,
      isInner,
    });

    if (childItems.length > 0) {
      patch({
        childItems,
      });
    } else {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        patch({
          childItems,
        });
      }, 600);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [location, items]);

  return output;
}

const findRoot = (location: Location, items: IMenuItem[]) => {
  const currentPath = location.pathname;

  const current = items
    .filter((item) => !item.isChild)
    .filter((item) => item.path)
    .find((item) => {
      return currentPath.startsWith(item.path ?? '');
    });

  return current;
};
