import { CircularProgress, IconButtonProps as Props } from '@mui/material';
import classnames from 'classnames';
import React, { ForwardedRef } from 'react';
import { invokeEvent } from 'shared-base';
import { Icon } from '../Icon/Icon';
import { Dot, Text, Wrapper } from './IconButton.style';

export type IconButtonProps = Props & {
  iconName: string;
  iconText?: string;
  className?: string;
  isLoading?: boolean;
  showDot?: boolean;
  source?: string;
  children?: any;
};

export const IconButton = React.forwardRef(
  (props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { iconName, iconText, isLoading, showDot, source, ...rest } = props;

    function onClick(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      invokeEvent('global/click', {
        source,
        ev,
      });

      if (!props.onClick) {
        return;
      }

      return props.onClick(ev);
    }

    function renderInner() {
      if (isLoading) {
        return <CircularProgress color='primary' size={18} />;
      }

      return (
        <>
          {showDot && <Dot />}
          <Icon iconName={iconName} />
          {iconText && <Text>{iconText}</Text>}
        </>
      );
    }

    const className = classnames('IconButton-wrapper', props.className, {});

    return (
      <Wrapper
        className={className}
        data-testid='IconButton-wrapper'
        ref={ref}
        {...rest}
        onClick={onClick}
      >
        {renderInner()}
      </Wrapper>
    );
  }
);

export default IconButton;
