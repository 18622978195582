export const identityPaths = [
  '/apikey',
  '/user',
  '/usage',
  '/usage/credit',
  '/usage/custom-models-training',
  '/usage/task-specific',
  '/usage/rewrite',
  '/organization',
  '/organization/users',
  '/organization/users/rotate',
  '/organization/invite',
  '/prompt-share',
  '/tier',
  '/billing',
  '/billing/payment-method',
  '/billing/update-payment-method',
  '/billing/invoices',
  '/preset',
  '/dataset/dataset-preview',
  '/dataset/example-dataset',
  '/custom-model/train-model-cost',
  '/jurassic-x',
  '/identity-jurassic-x/preset',
  '/sets',
  '/demo-pin-code',
  '/auth/sso/organization',
];

export const generalModels = [
  'j2-ultra',
  'j2-mid',
  'j2-light',
  'jamba-instruct-preview',
  'jamba-1.5-large',
  'jamba-1.5-mini',
];
