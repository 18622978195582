import classnames from 'classnames';
import IconButton from '../IconButton/IconButton';
import { Wrapper } from './IconLink.style';

export type IconLinkProps = {
  iconName: string;
  text?: string;
  onClick: () => void;
  testId?: string;
  className?: string;
};

export function IconLink(props: IconLinkProps) {
  const { iconName, text, testId } = props;
  const className = classnames('IconLink-wrapper', props.className, {});

  return (
    <Wrapper className={className} data-testid='IconLink-wrapper'>
      <IconButton
        onClick={props.onClick}
        data-testid={testId}
        className='action-hide'
        iconName={iconName}
        iconText={text}
      />
    </Wrapper>
  );
}

export default IconLink;
