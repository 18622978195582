export function NavigationOutIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4999 2.58331C12.0857 2.58331 11.7499 2.9191 11.7499 3.33331C11.7499 3.74753 12.0857 4.08331 12.4999 4.08331H14.8559L7.80292 11.1363C7.51003 11.4292 7.51003 11.9041 7.80292 12.197C8.09582 12.4899 8.57069 12.4899 8.86358 12.197L15.9166 5.14397V7.49998C15.9166 7.91419 16.2524 8.24998 16.6666 8.24998C17.0808 8.24998 17.4166 7.91419 17.4166 7.49998V3.33331C17.4166 3.14137 17.3434 2.94943 17.1969 2.80298C17.125 2.73108 17.0421 2.67682 16.9537 2.64022C16.8652 2.60355 16.7683 2.58331 16.6666 2.58331H12.4999ZM2.58325 7.49998C2.58325 6.16529 3.66523 5.08331 4.99992 5.08331H9.16659C9.5808 5.08331 9.91659 5.4191 9.91659 5.83331C9.91659 6.24753 9.5808 6.58331 9.16659 6.58331H4.99992C4.49366 6.58331 4.08325 6.99372 4.08325 7.49998V15C4.08325 15.5062 4.49366 15.9166 4.99992 15.9166H12.4999C13.0062 15.9166 13.4166 15.5062 13.4166 15V10.8333C13.4166 10.4191 13.7524 10.0833 14.1666 10.0833C14.5808 10.0833 14.9166 10.4191 14.9166 10.8333V15C14.9166 16.3347 13.8346 17.4166 12.4999 17.4166H4.99992C3.66523 17.4166 2.58325 16.3347 2.58325 15V7.49998Z'
        fill='#676465'
      />
    </svg>
  );
}
