import { Ax } from '@ai21/studio-store';
import { invokeEvent } from 'shared-base';

export const ax = {
  nudge: (xpath: string, value?: number) => {
    invokeEvent('ax/nudge', { xpath, value });
  },
  set: (xpath: string, value?: number | string | boolean) => {
    invokeEvent('ax/set', { xpath, value });
  },
};

export const emptyAx: Ax = {
  fbId: '',
  sizeOfCompany: 0,
  isEmployee: false,
  engagementLevel: 1,
  badges: {
    asker: false,
    taster: false,
    trainer: false,
    transformer: false,
    uploader: false,
    king: false,
  },
  playground: {
    completions: 0,
  },
  fts: {
    total: 0,
    sample: 0,
    custom: 0,
    training: 0,
    transformations: 0,
  },
  generationSets: {
    total: 0,
    sample: 0,
    custom: 0,
    customTotalLines: 0,
    customStarted: 0,
    customCompleted: 0,
    downloads: 0,
    transformations: 0,
  },
  evaluationSets: {
    total: 0,
    sample: 0,
    custom: 0,
    customTotalLines: 0,
    customStarted: 0,
    customCompleted: 0,
    downloads: 0,
    transformations: 0,
  },
  epochSelection: {
    views: 0,
    changes: 0,
  },
  documentLibrary: {
    total: 0,
    custom: 0,
    questions: 0,
  },
  weeks: {},
  days: {},
  generationSetsProgress: {},
  evaluationSetsProgress: {},
  lifeCycle: {
    dateJoined: '',
    weekFirst: '',
    dateLastLogin: '',
    weekLast: '',
    numberOfDays: 0,
    numberOfWeeks: 0,
    ageWeeks: 0,
  },
};
