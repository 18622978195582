import { put, takeEvery } from 'saga-ts';
import { getUserId } from '../analytics.amplitude';
import { customEvenChannel } from './channels/channel.customEvent';

export function* copyUserIdToClipboard() {
  // copy to clipboard
  const userId = getUserId();
  navigator.clipboard.writeText(userId).catch(() => {});
  yield put({ type: 'TOAST', message: 'Copied to clipboard' });
}

export function* openUserInAmplitude() {
  // copy to clipboard
  const userId = getUserId();
  window.open(import.meta.env.VITE_AMPLITUDE_DASHBOARD + userId, '_blank');
}

export function* root() {
  const channelCopy = customEvenChannel('copy_amplitude_user_id_to_clipboard');
  yield takeEvery(channelCopy, copyUserIdToClipboard);

  const channelOpen = customEvenChannel('open_amplitude_dashboard');
  yield takeEvery(channelOpen, openUserInAmplitude);
}
