export const actions: Json[] = [
  {
    id: 'play',
    analyticsId: 'TryInPlayground',
    title: 'Try interactive demo',
    iconName: 'PlayerPlay',
    color: 'primary',
    isDisabledOnMobile: true,
  },
  {
    id: 'support',
    analyticsId: 'ContactSales',
    title: 'Talk to an Expert',
    iconName: 'ArrowRight',
    color: 'primary',
    isDisabledOnMobile: false,
  },
];
