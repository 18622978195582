import {
  ICollectionJobs,
  ICollectionLines,
  IEvaluationJobs,
  IEvaluationLines,
  IFineTuningSets,
  IGenerationJobs,
  IGenerationLines,
} from './types.sets';

export type IStudioStore = {
  appState: IAppState;
  currentIds: ICurrentIds;
  loading: ILoading;
  demos: IDemos;
  user: IUser;
  organization: IOrganization;
  users: Record<string, IOrganizationUser>;
  paymentsInfo: IPaymentInfo;
  billingInfo: IBillingInfo;
  completionUsages: ICompletionUsage[];
  specializedUsages: ISpecializedUsage[];
  trainingUsages: ITrainModelsUsage[];
  invoices: IInvoices;
  playground: IPlayground;
  presets: IPresets;
  fineTuningSets: IFineTuningSets;
  models: IModels;
  completion: ICompletion;
  apis: IApis;
  evaluationJobs: IEvaluationJobs;
  evaluationLines: IEvaluationLines;
  sheetCells: ISheetCells;
  documents: IDocuments;
  documentQuestions: IDocumentQuestions;
  generationJobs: IGenerationJobs;
  generationLines: IGenerationLines;
  collectionJobs: ICollectionJobs;
  collectionLines: ICollectionLines;
  boxes: IBoxes;
  pas: IPas;
  apps: IApps;
};

export type IAppState = {
  isAuthenticated: boolean;
  isIntercomVisible: boolean;
  isDataFetched: boolean;
  showAnalyticsLogs: boolean;
  progressTotal?: number;
  progressCompleted?: number;
  playModels: string[];
  slimSideBar?: boolean;
};

export type IUser = {
  apiKey: string;
  customerId: string;
  featureFlags: Json;
  organizationId: string;
  organizationName: string;
  subscriptionCancellationDate?: string;
  subscriptionExpirationDate?: string;
  userEmail?: string;
  userId: string;
  userName: string;
  userRole: UserRole;
  userStatus: UserStatus;
  userTier: Tier;
  organizationDbId: number;
};

export type ICurrentIds = {
  modelId?: string;
  datasetId?: string;
  presetId?: string;
  appId?: string;
  evaluationJobId?: string;
  evaluationLineId?: string;
  generationJobId?: string;
  generationLineId?: string;
  collectionJobId?: string;
  collectionLineId?: string;
  demoId?: string;
  demoFrameId?: string;
  cellId?: string;
  docId?: string;
  askSpecificDocumentIds?: string[];
  layoutId?: string;
  familyId?: string;
};

export type IOrganization = {
  organizationId: string;
  name: string;
};

export type ILoading = {
  user: boolean;
  organization: boolean;
  users: boolean;
  paymentsInfo: boolean;
  billingInfo: boolean;
  completionUsages: boolean;
  specializedUsages: boolean;
  trainingUsages: boolean;
  invoices: boolean;
  playground: boolean;
  presets: boolean;
  fineTuningSets: boolean;
  models: boolean;
  completion: boolean;
  apis: boolean;
  evaluationJobs: boolean;
  evaluationLines: boolean;
};

export type IOrganizationUser = {
  id: string;
  email: string;
  name: string;
  role: UserRole;
  added_on: string;
};

export type IPaymentInfo = {
  brand: string;
  last4: number;
};

export type IBillingInfo = {
  upcomingBilling: IBillingChange;
  lastBilling: IBillingChange;
  minimumPrice?: number;
};

export type IBillingChange = {
  amount: number;
  date: number;
};

export type ICompletionUsage = {
  id: string;
  modelName: string;
  modelType: string;
  customModelType: string;
  generatedTokensUsed: number;
  promptTokensUsed: number;
  numRequests: number;
  usageDate: string;
  tierType: Tier;
  periodEnd?: number;
  periodStart?: number;
  isFreeModel?: boolean;
  status?: ModelStatus;
  totalCost: number;
};

export type ISpecializedUsage = {
  id: string;
  numRequests: number;
  displayName: string;
  totalCost: number;
  periodStart?: number;
  periodEnd?: number;
};

export type ITrainModelsUsage = {
  id: string;
  customModelType: string;
  quantity: number;
  periodEnd?: number;
  periodStart?: number;
  cost: number;
};

export type IInvoice = {
  id: string;
  invoiceId: string;
  periodStart: number;
  pdf: string;
  status: string;
  amount: number;
};

export type IInvoices = Record<string, IInvoice>;

export type IApp = {
  id: string;
  title: string;
  titleLong: string;
  presetUrl?: string;
  demoUrl?: string;
  docsUrl?: string;
  subtitle: string;
  description?: string;
  descriptionFull: string;
  overviewDescription?: string;
  quoteText?: string;
  imageUrl: string;
  tags: { model: string[]; industry: string[]; metric: string[] };
  relatedTags: string[];
  screenshots: string[];
  isHidden?: boolean;
};

export type IApps = Record<string, IApp>;
export type IModelUsage = {
  modelName: string;
  description: string;
  presetUrl: string;
};

export type ICompletionParams = {
  maxTokens: number;
  temperature: number;
  topP: number;
  stopSequences: string[];
  modelId: string;
  prompt: string;
  presencePenalty: number;
  countPenalty: number;
  frequencyPenalty: number;
  showAlternativeTokens?: boolean;
  applyToWhitespaces?: boolean;
  applyToPunctuations?: boolean;
  applyToNumbers?: boolean;
  applyToStopwords?: boolean;
  applyToEmojis?: boolean;
  // transient
  topKReturn?: number;
  modelsUsage?: IModelUsage[];
};

export type ICompletionStats = {
  numStopSequences: number;
  promptLength: number;
  numResults: number;
  length: number;
};

export type IPlayground = ICompletionParams;

export type IPreset = {
  id: string;
  name: string;
  text: string;
  description?: string;
  apiType: string;
  params: ICompletionParams & Partial<ICompletionStats>;
  primaryLabel: string;
  secondaryLabels?: string[];
  isUserPreset?: boolean;
  iconName: string;
  isHidden?: boolean;
  contextInput?: string;
  questionInput?: string;
};

export type IPresets = Record<string, IPreset>;

export type IModel = {
  id: string;
  name: string;
  title: string;
  description: string;
  status: string;
  groupName: string;
  familyName?: string;
  tier: Tier;
  size: string;
  modelType: string;
  datasetName: string;
  iconName?: string;
  modelMetadata: IModelMetaData;
  creationDate: string;
  slogan?: string;
  customModelType?: string;
  currentEpoch?: number;
  datasetId: number;
  isFoundationModel?: boolean;
  isUserModel?: boolean;
  isInstruct?: boolean;
  isDefault?: boolean;
  order?: number;
  orderCatalogue?: number;
  layoutId?: string;
  forEmployees?: boolean;
  isDeprecated: boolean;
  isBeta?: boolean;
  docsUrl?: string;
};

export type IModels = Record<string, IModel>;

export type IModelMetaData = {
  numEpochs: number;
  learningRate: number;
  cost: number;
  evalLoss: number;
  defaultEpoch: number;
  epochs: IEpochs;
  docsUrl?: string;
};

export type IEpoch = {
  id: string;
  validationLoss: number;
  isDefault?: boolean;

  // transient
  isLowest?: boolean;
};

export type IEpochs = Record<string, IEpoch>;

export type IBox = {
  id: string;
  values: Json;
  layoutId: string;
};

export type IBoxes = Record<string, IBox>;

export type IApi = {
  id: string;
  name: string;
  familyName?: string;
  primaryLabel: string;
  secondaryLabels: string[];
  description: string;
  docsUrl: string;
  playgroundUrl: string;
  endpoint: string;
  iconName?: string;
  isBeta?: boolean;
  isNew?: boolean;
  imageUrl?: string;
};

export type IApis = Record<string, IApi>;

export type ICompletion = {
  id: string;
  prompt: TextData;
  completions: Completion[];
};

export type IDocument = {
  id: string;
  name: string;
  createdBy: string;
  status: string;
  path: string;
  creationDate: string;
  lastUpdated: string;
  documentType: string;
  labels: string[];
  publicUrl: string;
  sizeBytes: number;
};

export type IDocuments = Record<string, IDocument>;

export type IDocumentSource = {
  id: string;
  name: string;
  publicUrl?: string;
  highlights: string[];
  fileId: string;
};

export type IDocumentAnswer = {
  answer: string;
  answerInContext: boolean;
  sources: IDocumentSource[];
  errorMessage?: string;
};

export type QuestionStatus = 'waiting' | 'answered' | 'error';

export type IDocumentQuestion = {
  id: string;
  prompt: string;
  status: QuestionStatus;
  createdAt: number;
} & Partial<IDocumentAnswer>;

export type IDocumentQuestions = Record<string, IDocumentQuestion>;

export type IEvaluationScore = {
  value: number;
  tags: string[];
  timestamp?: number;
};

export type IGenerationPrice = {
  value: number;
  tags: string[];
  timestamp?: number;
};

export type ISheetCell = {
  id: string;
  value: string;
  cellType: 'model' | 'topP' | 'temperature' | 'maxTokens' | 'prompt' | 'value' | 'label';

  // transient
  x?: number;
  y?: number;
  isLoading?: boolean;
};

export type ISheetCells = Record<string, ISheetCell>;

export type IPa = {
  id: string;
  name: string;
  description?: string;
  dateCreated: number;
  creator: string;
  labels: string[];
  systemMessage: string;
  userPrompt: string;
  isDeleted?: boolean;
};

export type IPas = Record<string, IPa>;

export type IDemo = {
  id: string;
  name: string;
  isPrivate?: boolean;
  description?: string;
  company?: string;
  dateCreated: number;
  creator: string;
  flavour: DemoFlavour;
  params: Json;
  theme: Json;
  isDeleted?: boolean;
};

export type IDemos = Record<string, IDemo>;

// ================== data types ==================
export enum UserRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export enum UserStatus {
  INITIAL = 'INITIAL',
  VERIFIED = 'VERIFIED',
  REVOKED = 'REVOKED',
  PENDING = 'PENDING',
  NOT_LOADED = 'NOT_LOADED',
}

export enum Tier {
  FREE = 'FREE',
  BASIC = 'BASIC',
  SCALE = 'SCALE',
  NONE = 'NONE',
}

export enum ModelStatus {
  ACTIVE = 'ACTIVE',
  DISCONTINUED = 'DISCONTINUED',
  PENDING = 'PENDING',
  IN_TRAINING = 'IN_TRAINING',
}

export type TokenData = {
  token: string;
  logprob: number;
};

export type TokenTextRange = {
  start: number;
  end: number;
};

export type Token = {
  generatedToken: TokenData;
  topTokens?: TokenData[];
  textRange: TokenTextRange;
};

export type TextData = {
  text: string;
  tokens: Token[];
};

export type Completion = {
  data: TextData;
  finishReason: {
    reason: string;
    length?: number;
    sequence?: string;
  };
};

export enum PresetLabel {
  MY_PRESETS = 'My Presets',
  CHAT = 'Chat',
  CONTENT_WRITING = 'Content Writing',
  COPY_WRITING = 'Copywriting',
  CLASSIFICATION = 'Classification',
  SUMMARIZE = 'Summarize',
  Q_AND_A = 'Q&A',
  REWRITE = 'Rewrite',
  REWRITE_API_EXAMPLES = 'Rewrite Examples',
  SUMMARIZATION_API_EXAMPLES = 'Summarize Examples',
  CODE = 'Code',
  RETAIL = 'Retail',
  MARKETING = 'Marketing',
  COMMON_SENSE = 'Common Sense',
  SHORT_FORM = 'Short form',
  LONG_FORM = 'Long form',
  NAMED_ENTITY_RECOGNITION = 'Named entity recognition',
  SENTIMENT_ANALYSIS = 'Sentiment analysis',
  INFO_EXTRACTION = 'Info Extraction',
  FINANCE = 'Finance',
  NEW_PRESET = 'New Preset',
}

export enum DemoFlavour {
  CHAT = 'chat',
  CONTEXTUAL_ANSWERS = 'contextual_answers',
  SUMMARIZATION = 'summarization',
}

// ================== utilities ==================
export interface StoreDefinition {
  name: string;
  initialState: Json;
  reducers: any;
  middlewares: any;
  enhancers: any;
  sagas: any;
  sagasContext: any;
  enableDevtoolsExtension: boolean;
  sagaMonitor: any;
}

export type IStoreOptions = {
  rootSaga: any;
  previousRootReducer?: any;
  middlewares?: any[];
};

export type AxBadges = {
  asker: boolean;
  taster: boolean;
  trainer: boolean;
  transformer: boolean;
  uploader: boolean;
  king: boolean;
};

export type Ax = {
  fbId: string;
  engagementLevel: number;
  badges: AxBadges;
  isEmployee: boolean;
  playground: {
    completions: number;
  };
  fts: {
    total: number;
    sample: number;
    custom: number;
    training: number;
    transformations: number;
  };
  generationSets: {
    total: number;
    sample: number;
    custom: number;
    customTotalLines: number;
    customStarted: number;
    customCompleted: number;
    downloads: number;
    transformations: number;
  };
  evaluationSets: {
    total: number;
    sample: number;
    custom: number;
    customTotalLines: number;
    customStarted: number;
    customCompleted: number;
    downloads: number;
    transformations: number;
  };
  epochSelection: {
    views: number;
    changes: number;
  };
  documentLibrary: {
    total: number;
    custom: number;
    questions: number;
  };
  generationSetsProgress: Record<string, Json>;
  evaluationSetsProgress: Record<string, Json>;
  weeks: Record<string, boolean>;
  days: Record<string, boolean>;
  lifeCycle: {
    dateJoined: string;
    weekFirst: string;
    dateLastLogin: string;
    weekLast: string;
    numberOfDays: number;
    numberOfWeeks: number;
    ageWeeks: number;
  };
};
