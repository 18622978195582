import { IEvaluationScore } from '../types';

export const calcIsEvaluated = (score?: IEvaluationScore) => {
  if (!score) {
    return false;
  }

  const { value } = score;

  if ((value as any) === '' || value === undefined) {
    return false;
  }

  return value >= 0;
};
