import { APIType } from '../../constants/constants';
import {
  CompletionConfigChanged,
  CompletionFailureReason,
  CompletionRequestParams,
  CompletionResponse,
  ControllerParams,
  TokenClickedParams,
} from '../../data-types/Completion';
import { Preset, PresetOrigin, RewritePresetParams } from '../../data-types/PresetParams';

export const API_SELECTED = 'API_SELECTED';

export const SET_PLAYGROUND_TO_DEFAULT_STATE = 'SET_PLAYGROUND_TO_DEFAULT_STATE';

export const SET_DEFAULT_COMPLETION_PRESET = 'SET_DEFAULT_COMPLETION_PRESET';

// complete
export const SUBMIT_PROMPT_PRESSED = 'SUBMIT_PROMPT_PRESSED';
export const FETCH_COMPLETION_STARTED = 'FETCH_COMPLETION_STARTED';
export const FETCH_COMPLETION_SUCCESS = 'FETCH_COMPLETION_SUCCESS';
export const FETCH_COMPLETION_FAILED = 'FETCH_COMPLETION_FAILED';
export const FETCH_COMPLETION_CANCELED = 'FETCH_COMPLETION_CANCELED';
export const CLEAR_COMPLETION_RESPONSE = 'CLEAR_COMPLETION_RESPONSE';
export const SET_COMPLETION_IN_PROGRESS = 'SET_COMPLETION_IN_PROGRESS';
export const POST_PROMPT_SHARE_STARTED = 'POST_PROMPT_SHARE_STARTED';
export const POST_PROMPT_SHARE_FAILED = 'POST_PROMPT_SHARE_FAILED';
export const POST_PROMPT_SHARE_SUCCESS = 'POST_PROMPT_SHARE_SUCCESS';
export const PROMPT_SHARE_CLOSED = 'PROMPT_SHARE_CLOSED';
export const GET_PROMPT_SHARE_STARTED = 'GET_PROMPT_SHARE_STARTED';
export const GET_PROMPT_SHARE_FAILED = 'GET_PROMPT_SHARE_FAILED';
export const GET_PROMPT_SHARE_SUCCESS = 'GET_PROMPT_SHARE_SUCCESS';
export const MODEL_CONFIG_CHANGED = 'MODEL_CONFIG_CHANGED';

// tokenize
export const FETCH_TOKENIZE_STARTED = 'FETCH_TOKENIZE_STARTED';
export const FETCH_TOKENIZE_SUCCESS = 'FETCH_TOKENIZE_SUCCESS';
export const FETCH_TOKENIZE_FAILED = 'FETCH_TOKENIZE_FAILED';
// presets
export const PRESET_SELECTED = 'PRESET_SELECTED';
export const CUSTOM_PRESET_SELECTED = 'CUSTOM_PRESET_SELECTED';
export const SET_CONTROLLER_PARAMS = 'SET_CONTROLLER_PARAMS';
export const SET_SHOW_SAVE_PRESET_DIALOG = 'SET_SHOW_SAVE_PRESET_DIALOG';
export const SET_SHOW_SAVE_PRESET_ON_EXIT_DIALOG = 'SET_SHOW_SAVE_PRESET_ON_EXIT_DIALOG';
export const PRESET_CLICKED = 'PRESET_CLICKED';
export const SET_PRESET_TO_DELETE = 'SET_PRESET_TO_DELETE';
// custom models cta
export const CUSTOM_MODELS_CTA_PRESSED = 'CUSTOM_MODELS_CTA_PRESSED';
export const PROMPT_SHARE_COPIED = 'PROMPT_SHARE_COPIED';

// alternative tokens
export const SET_TOKEN_CLICKED = 'SET_TOKEN_CLICKED';
export const PLAYGROUND_CURSOR_MOVE = 'PLAYGROUND_CURSOR_MOVE';

// buttons pressed
export const ONBOARDING_VIDEO_PRESSED = 'ONBOARDING_VIDEO_PRESSED';
export const INAPPROPRIATE_CONTENT_FLAGGED = 'INAPPROPRIATE_CONTENT_FLAGGED';
export const ALTERNATIVE_TOKENS_ACTIVATED = 'ALTERNATIVE_TOKENS_ACTIVATED';
export const ALTERNATIVE_TOKENS_DEACTIVATED = 'ALTERNATIVE_TOKENS_DEACTIVATED';
export const COMPLETION_CLEARED = 'COMPLETION_CLEARED';
export const CANVAS_CLEARED = 'CANVAS_CLEARED';
export const NEW_PRESET_PRESSED = 'NEW_PRESET_PRESSED';
export const COMPLETION_CANCELLED = 'COMPLETION_CANCELLED';
export const EMAIL_VERIFICATION_RESEND_PRESSED = 'EMAIL_VERIFICATION_RESEND_PRESSED';
export const SMS_CODE_RESEND_PRESSED = 'SMS_CODE_RESEND_PRESSED';
export const REGENERATED_PRESSED = 'REGENERATED_PRESSED';

// rewrite
export const SET_REWRITE_PARAMS = 'SET_REWRITE_PARAMS';

export const SHOW_TRAILING_WHITESPACE_ALERT = 'SHOW_TRAILING_WHITESPACE_ALERT';
export const CTA_TRAILING_WHITESPACE_ALERT = 'CTA_TRAILING_WHITESPACE_ALERT';
export const DISMISS_TRAILING_WHITESPACE_ALERT = 'DISMISS_TRAILING_WHITESPACE_ALERT';

export interface SetRewriteParams {
  type: typeof SET_REWRITE_PARAMS;
  delta: Partial<RewritePresetParams>;
}

export enum PromptShareFailureReason {
  UNKNOWN_ERROR,
}

export interface SubmitPromptPressed {
  type: typeof SUBMIT_PROMPT_PRESSED;
}

export interface ClearCompletionResponse {
  type: typeof CLEAR_COMPLETION_RESPONSE;
}

export interface FetchCompletionStarted {
  type: typeof FETCH_COMPLETION_STARTED;
  completionParams: CompletionRequestParams;
}

export interface FetchCompletionSuccess {
  type: typeof FETCH_COMPLETION_SUCCESS;
  completionResponse: CompletionResponse;
  completionParams: CompletionRequestParams;
  requestDuration: number;
}

export interface FetchCompletionFailed {
  type: typeof FETCH_COMPLETION_FAILED;
  completionParams: CompletionRequestParams;
  reason: CompletionFailureReason;
}

export interface FetchCompletionCanceled {
  type: typeof FETCH_COMPLETION_CANCELED;
}

export interface FetchTokenizeStarted {
  type: typeof FETCH_TOKENIZE_STARTED;
}

export interface FetchTokenizeSuccess {
  type: typeof FETCH_TOKENIZE_SUCCESS;
  numTokens: number;
}

export interface FetchTokenizeFailed {
  type: typeof FETCH_TOKENIZE_FAILED;
}

export interface PresetSelected {
  type: typeof PRESET_SELECTED;
  preset: Preset;
  origin: PresetOrigin;
}
export interface CustomPresetSelected {
  type: typeof CUSTOM_PRESET_SELECTED;
  preset: Preset;
  origin: PresetOrigin;
}

export interface CustomModelsCtaPressed {
  type: typeof CUSTOM_MODELS_CTA_PRESSED;
  origin: string;
  buttonText: string;
}

export interface SetControllerParams {
  type: typeof SET_CONTROLLER_PARAMS;
  delta: Partial<ControllerParams>;
}

export interface SetShowSavePresetDialog {
  type: typeof SET_SHOW_SAVE_PRESET_DIALOG;
  showSavePresetDialog: boolean;
}

export interface SetShowSavePresetOnExitDialog {
  type: typeof SET_SHOW_SAVE_PRESET_ON_EXIT_DIALOG;
  showSavePresetOnExitDialog: boolean;
}

export interface PresetClicked {
  type: typeof PRESET_CLICKED;
  nextPreset: Preset | null;
}

export interface SetPresetToDelete {
  type: typeof SET_PRESET_TO_DELETE;
  presetToDelete: string;
}

export interface SetTokenClicked {
  type: typeof SET_TOKEN_CLICKED;
  tokenClickedParams: TokenClickedParams;
}

export interface PlaygroundCursorMove {
  type: typeof PLAYGROUND_CURSOR_MOVE;
  position: number;
}

export interface SetCompletionInProgress {
  type: typeof SET_COMPLETION_IN_PROGRESS;
  inProgress: boolean;
}

export interface PostPromptShareStarted {
  type: typeof POST_PROMPT_SHARE_STARTED;
  payload: CompletionRequestParams | { prompt: string };
  apiType: APIType;
}

export interface PostPromptShareFailed {
  type: typeof POST_PROMPT_SHARE_FAILED;
  payload: CompletionRequestParams | { prompt: string };
  reason: string;
  apiType: APIType;
}

export interface PostPromptShareSuccess {
  type: typeof POST_PROMPT_SHARE_SUCCESS;
  payload: CompletionRequestParams | { prompt: string };
  requestDuration: number;
  promptShareId: string;
  apiType: APIType;
}

export interface GetPromptShareStarted {
  type: typeof GET_PROMPT_SHARE_STARTED;
  promptShareId: string;
}

export interface GetPromptShareFailed {
  type: typeof GET_PROMPT_SHARE_FAILED;
  promptShareId: string;
  reason: string;
}

export interface ShowTrailingWhitespacesAlert {
  type: typeof SHOW_TRAILING_WHITESPACE_ALERT;
}

export interface DismissTrailingWhitespacesAlert {
  type: typeof DISMISS_TRAILING_WHITESPACE_ALERT;
}

export interface CtaTrailingWhitespacesAlert {
  type: typeof CTA_TRAILING_WHITESPACE_ALERT;
}

export interface GetPromptShareSuccess {
  type: typeof GET_PROMPT_SHARE_SUCCESS;
  promptShareId: string;
  text: string;
  apiType: APIType;
  params: CompletionRequestParams;
  requestDuration: number;
}

export interface PromptShareCopied {
  type: typeof PROMPT_SHARE_COPIED;
  promptShareUrl: string;
}

export interface PromptShareClosed {
  type: typeof PROMPT_SHARE_CLOSED;
}

export interface ModelConfigChanged {
  type: typeof MODEL_CONFIG_CHANGED;
  payload: CompletionConfigChanged;
}

export interface OnboardingVideoPressed {
  type: typeof ONBOARDING_VIDEO_PRESSED;
  path: string;
}

export interface InappropriateContentFlagged {
  type: typeof INAPPROPRIATE_CONTENT_FLAGGED;
  path: string;
}

export interface AlternativeTokensActivated {
  type: typeof ALTERNATIVE_TOKENS_ACTIVATED;
}

export interface AlternativeTokensDeactivated {
  type: typeof ALTERNATIVE_TOKENS_DEACTIVATED;
}

export interface SetPlaygroundToDefaultState {
  type: typeof SET_PLAYGROUND_TO_DEFAULT_STATE;
}

export interface CompletionCleared {
  type: typeof COMPLETION_CLEARED;
  path: string;
}

export interface CanvasCleared {
  type: typeof CANVAS_CLEARED;
  path: string;
}

export interface NewPresetPressed {
  type: typeof NEW_PRESET_PRESSED;
  origin: string;
  api_type: APIType;
}

export interface CompletionCancelled {
  type: typeof COMPLETION_CANCELLED;
  path: string;
}

export interface EmailVerificationResendPressed {
  type: typeof EMAIL_VERIFICATION_RESEND_PRESSED;
  path: string;
}

export interface SmsCodeResendPressed {
  type: typeof SMS_CODE_RESEND_PRESSED;
  path: string;
}

export interface ApiSelected {
  type: typeof API_SELECTED;
  selectedApi: APIType;
}

export interface SetDefaultCompletionPreset {
  type: typeof SET_DEFAULT_COMPLETION_PRESET;
}

export interface RegeneratePressed {
  type: typeof REGENERATED_PRESSED;
  apiType: APIType;
  interactionId: string;
}

export type PlaygroundActionTypes =
  | SubmitPromptPressed
  | SetDefaultCompletionPreset
  | FetchCompletionStarted
  | FetchCompletionFailed
  | FetchCompletionSuccess
  | FetchCompletionCanceled
  | SetCompletionInProgress
  | FetchTokenizeStarted
  | FetchTokenizeSuccess
  | FetchTokenizeFailed
  | PresetSelected
  | CustomPresetSelected
  | SetControllerParams
  | CustomModelsCtaPressed
  | ClearCompletionResponse
  | SetTokenClicked
  | PlaygroundCursorMove
  | PostPromptShareStarted
  | PostPromptShareFailed
  | PostPromptShareSuccess
  | GetPromptShareStarted
  | GetPromptShareFailed
  | PromptShareCopied
  | GetPromptShareSuccess
  | ShowTrailingWhitespacesAlert
  | CtaTrailingWhitespacesAlert
  | DismissTrailingWhitespacesAlert
  | PromptShareClosed
  | ModelConfigChanged
  | OnboardingVideoPressed
  | InappropriateContentFlagged
  | AlternativeTokensActivated
  | AlternativeTokensDeactivated
  | CompletionCleared
  | CanvasCleared
  | CompletionCancelled
  | EmailVerificationResendPressed
  | SmsCodeResendPressed
  | SetPlaygroundToDefaultState
  | NewPresetPressed
  | SetRewriteParams
  | ApiSelected
  | SetShowSavePresetDialog
  | SetShowSavePresetOnExitDialog
  | PresetClicked
  | SetPresetToDelete
  | RegeneratePressed;
