import type { IRouteMap } from '@ai21/studio-ui';

export enum AppRoutes {
  DEMOS = '/tools/demos',
  DEMO_NOT_FOUND = '/demo/not-found',
  DEMO_NO_API_KEY = '/demo/no-api-key',
  DEMO_FRAME = '/demo/:company/:id',
}

export const publicRoutes: IRouteMap = {
  [AppRoutes.DEMO_FRAME]: 'demoFrame',
  [AppRoutes.DEMO_NOT_FOUND]: 'demoNotFound',
  [AppRoutes.DEMO_NO_API_KEY]: 'demoNoApiKey',
};

export const privateRoutes: IRouteMap = {
  [AppRoutes.DEMOS]: 'demos',
};
