import React, { FC } from 'react';
import { BreadcrumbsMui, Link, Text } from './Breadcrumbs.style';
import { IMenuItem } from '@ai21/studio-base-ui';

interface IBreadcrumItemProps {
  onClick: (item: IMenuItem) => void;
  item: IMenuItem;
  itemTitle?: string;
  isLastItem: boolean;
}

export const BreadcrumbItem: FC<IBreadcrumItemProps> = ({
  onClick,
  item,
  itemTitle,
  isLastItem,
}) => {
  const title = itemTitle || item.title;

  if (isLastItem) {
    return (
      <Text key={item.id} color='text.primary'>
        {title}
      </Text>
    );
  }

  return (
    <Link
      key={item.id}
      underline='hover'
      data-testid='link-breadcrumbs'
      onClick={() => onClick(item)}
    >
      {title}
    </Link>
  );
};
