import { IPas } from './types';

export const pas: IPas = {
  '1': {
    id: '1',
    name: 'PA 1',
    description: 'PA 1 Description',
    dateCreated: Date.now(),
    creator: 'John Doe',
    labels: [],
    systemMessage: '',
    userPrompt: '',
  },
};
