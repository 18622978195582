import { createSelector } from 'reselect';
import { IPreset, IPresets, IStudioStore } from '../types';
import { get, set, mapValues } from 'lodash';
import { mapLegacyModels } from '../utils/modelTransition';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $rawAppState = createSelector($i, (state: IStudioStore) =>state.appState); // prettier-ignore
export const $rawCurrentIds = createSelector($i, (state: IStudioStore) => state.currentIds); // prettier-ignore
export const $rawUser = createSelector($i, (state: IStudioStore) => state.user); // prettier-ignore
export const $rawDocuments = createSelector($i, (state: IStudioStore) => state.documents); // prettier-ignore
export const $rawDocumentQuestions = createSelector($i, (state: IStudioStore) => state.documentQuestions); // prettier-ignore
export const $rawDemos = createSelector($i, (state: IStudioStore) => state.demos); // prettier-ignore
export const $rawOrganization = createSelector($i, (state: IStudioStore) => state.organization); // prettier-ignore
export const $rawUsers = createSelector($i, (state: IStudioStore) => state.users); // prettier-ignore
export const $rawPaymentsInfo = createSelector($i, (state: IStudioStore) => state.paymentsInfo); // prettier-ignore
export const $rawBillingInfo = createSelector($i, (state: IStudioStore) => state.billingInfo); // prettier-ignore
export const $rawCompletionUsages = createSelector($i, (state: IStudioStore) => state.completionUsages); // prettier-ignore
export const $rawSpecializedUsages = createSelector($i, (state: IStudioStore) => state.specializedUsages); // prettier-ignore
export const $rawTrainingUsages = createSelector($i, (state: IStudioStore) => state.trainingUsages); // prettier-ignore
export const $rawInvoices = createSelector($i, (state: IStudioStore) => state.invoices); // prettier-ignore
export const $rawPlayground = createSelector($i, (state: IStudioStore) => state.playground); // prettier-ignore
export const $rawFineTuningSets = createSelector($i, (state: IStudioStore) => state.fineTuningSets); // prettier-ignore
export const $rawModels = createSelector($i, (state: IStudioStore) => state.models); // prettier-ignore
export const $rawCompletion = createSelector($i, (state: IStudioStore) => state.completion); // prettier-ignore
export const $rawApis = createSelector($i, (state: IStudioStore) => state.apis); // prettier-ignore
export const $rawEvaluationJobs = createSelector($i, (state: IStudioStore) => state.evaluationJobs); // prettier-ignore
export const $rawEvaluationLines = createSelector($i, (state: IStudioStore) => state.evaluationLines); // prettier-ignore
export const $rawLoading = createSelector($i, (state: IStudioStore) => state.loading); // prettier-ignore
export const $rawSheetsCells = createSelector($i, (state: IStudioStore) => state.sheetCells); // prettier-ignore
export const $rawGenerationJobs = createSelector($i, (state: IStudioStore) => state.generationJobs); // prettier-ignore
export const $rawGenerationLines = createSelector($i, (state: IStudioStore) => state.generationLines); // prettier-ignore
export const $rawCollectionJobs = createSelector($i, (state: IStudioStore) => state.collectionJobs); // prettier-ignore
export const $rawCollectionLines = createSelector($i, (state: IStudioStore) => state.collectionLines); // prettier-ignore
export const $rawBoxes = createSelector($i, (state: IStudioStore) => state.boxes); // prettier-ignore
export const $rawPas = createSelector($i, (state: IStudioStore) => state.pas); // prettier-ignore
export const $rawApps = createSelector($i, (state: IStudioStore) => state.apps); // prettier-ignore

// export const $rawPresets = createSelector($i, (state: IStudioStore) => state.presets); // prettier-ignore
export const $rawPresets = createSelector($i, (state) => {
  return mapValues(state.presets, (preset) => {
    const modelId = get(preset, 'params.modelId');

    const newModelsId = mapLegacyModels(modelId);

    return {
      ...preset,
      params: {
        ...preset.params,
        modelId: newModelsId,
      },
    };
  });
});
