import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(IconButton)`
  position: relative;
`;

export const Text = styled.div`
  color: #626b78;
  font-size: 14px;
  margin-left: 5px;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #753fea;
  position: absolute;
  top: 7px;
  right: 7px;
  border: 2px solid white;
`;
