import { ExperimentClientConfig } from "./types/ExperimentClientConfig";
import ExperimentProvider from "./types/ExperimentProvider";
import AmplitudeExperimentProvider from "./AmplitudeExperimentProvider";
import VariantsClient from "./VariantsClient";
import TelemetryClient from "./TelemetryClient";
import {
  fetchVariantsInvokedCounter,
  fetchVariantsFailedCounter,
  initCounters,
  initExperimentProviderInvokedCounter,
  initExperimentProviderFailedCounter,
  initSdkCounter,
} from "./utils/counters";

/**
 * A client used to fetch Experiments / Feature flags variations.
 */
export class ExperimentClient {
  private static _instance: ExperimentClient;
  private readonly experimentProvider: ExperimentProvider;
  private readonly clientIdentifier: string;
  private didInitExperimentProvider: boolean = false;

  private constructor(config: ExperimentClientConfig) {
    this.clientIdentifier = config.clientIdentifier;
    this.experimentProvider = this.getExperimentProvider(config);
  }

  static getInstance(config: ExperimentClientConfig) {
    if (!ExperimentClient._instance) {
      ExperimentClient._instance = new ExperimentClient(config);
    }

    return ExperimentClient._instance;
  }

  private getExperimentProvider(config: ExperimentClientConfig): ExperimentProvider {
    const {
      experimentProvider,
      amplitudeExperimentApiKey,
      trackEvent,
      debug,
      amplitudeServerUrl,
      amplitudeFlagsServerUrl,
    } = config;
    if (experimentProvider) {
      return experimentProvider;
    }

    return new AmplitudeExperimentProvider({
      amplitudeExperimentApiKey,
      debug: !!debug,
      trackEvent,
      amplitudeServerUrl,
      amplitudeFlagsServerUrl,
    });
  }

  private async initExperimentProvider() {
    try {
      TelemetryClient.debug("initExperimentProvider called");

      initExperimentProviderInvokedCounter?.add(1);

      await this.experimentProvider.init();
      this.didInitExperimentProvider = true;
    } catch (e: any) {
      TelemetryClient.error("Failed initializing experiment provider", e);
      initExperimentProviderFailedCounter?.add(1);
      throw e;
    }
  }

  /**
   * Returns a Variants client.
   *
   * On the first call, initializes relevant experiment provider.
   *
   * @return {VariantsClient} Variants client
   * @see VariantsClient
   */
  async fetchVariants() {
    try {
      TelemetryClient.debug("fetchVariants called");

      if (!this.didInitExperimentProvider) {
        await this.initExperimentProvider();
      }

      fetchVariantsInvokedCounter?.add(1);
      await this.experimentProvider.fetch();
    } catch (e: any) {
      TelemetryClient.error("Failed fetching experiment provider variants", e);
      fetchVariantsFailedCounter?.add(1);
    }

    return new VariantsClient(this.experimentProvider);
  }
}

export const createExperimentClient = (config: ExperimentClientConfig): ExperimentClient => {
  const { clientIdentifier, telemetryInstance, debug } = config;

  if (telemetryInstance) {
    TelemetryClient.init(telemetryInstance, { clientIdentifier, debug });
    initCounters();
  }

  initSdkCounter?.add(1);

  return ExperimentClient.getInstance(config);
};
