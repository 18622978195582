import { delay, fork, put, select, take, takeEvery } from 'saga-ts';
import * as get from '../redux/actions/actions.get';
import { getUserRole, getUserTier } from '../redux/selectors';
import { TierType } from '../data-types/Tier';
import { UserRole } from '@ai21/studio-store';
import { customEvenChannel } from './channels/channel.customEvent';
import {
  CUSTOM_MODELS_EXPORT_DATA_PRESSED,
  DATASET_EXPORT_DATA_PRESSED,
  PRESET_EXPORT_DATA_PRESSED,
} from '../redux/actionTypes/datasetsActionTypes';

export function* legacy() {
  yield take('AUTHENTICATION_COMPLETED');
  yield delay(500);

  const userTier = yield* select(getUserTier);
  const userRole = yield* select(getUserRole);

  yield put((get.fetchCreditInfo as any)());
  yield put((get.getPresets as any)());
  yield put((get.fetchUsageInfo as any)());
  yield put((get.fetchSpecializedModelUsageInfo as any)());
  yield put((get.fetchOrganizationDetails as any)());
  yield put((get.fetchOrganizationUsersDetails as any)());
  yield put((get.fetchInvoiceHistory as any)());
  yield put((get.fetchTrainModelsUsageInfo as any)());

  if (!userTier) {
    return;
  }

  if (userTier === TierType.FREE) {
    yield put((get.fetchCreditInfo as any)());
  } else if (userRole === UserRole.ADMIN) {
    yield put((get.fetchPaymentInfo as any)());
    yield put((get.fetchBillingInfo as any)());
  }
}

export function* exportDataAnalytics(action: any) {
  const { pathname } = document.location;

  if (pathname.startsWith('/fine-tuning-sets')) {
    yield put({ type: DATASET_EXPORT_DATA_PRESSED, origin: 'fineTuningSetsPage' });
    return;
  }
  if (pathname.startsWith('/presets')) {
    yield put({ type: PRESET_EXPORT_DATA_PRESSED, origin: 'PresetPage' });
    return;
  }
  if (pathname.startsWith('/custom-models')) {
    yield put({ type: CUSTOM_MODELS_EXPORT_DATA_PRESSED, origin: 'CustomModelsPage' });
    return;
  }
}

export function* root() {
  yield* fork(legacy);
  const channel = customEvenChannel('export_data');
  yield takeEvery(channel, exportDataAnalytics);
}
