interface ArrowForwardIconProps {
  width?: string;
  height?: string;
}

export function ArrowForwardIcon({ height = '12', width = '12' }: ArrowForwardIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='m6 2-.705.705L8.085 5.5H2v1h6.085l-2.79 2.795L6 10l4-4-4-4z' fill='#626B78' />
    </svg>
  );
}
