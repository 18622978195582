import { IconButton, Tooltip } from '@mui/material';
import { Icon } from '../Icon/Icon';
import { Wrapper } from './Tip.style';

export type TipProps = {
  value?: string;
};

export function Tip(props: TipProps) {
  const { value } = props;

  if (!value) {
    return null;
  }

  return (
    <Wrapper className='Tip-wrapper' data-testid='Tip-wrapper'>
      <Tooltip title={value} enterDelay={500}>
        <span>
          <Icon iconName='Info' />
        </span>
      </Tooltip>
    </Wrapper>
  );
}

export default Tip;
