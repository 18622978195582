export function GenerateIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M18.75 12C18.75 14.0711 17.0711 15.75 15 15.75H6.81066L9.53033 18.4697C9.82322 18.7626 9.82322 19.2374 9.53033 19.5303C9.23744 19.8232 8.76256 19.8232 8.46967 19.5303L4.46967 15.5303C4.17678 15.2374 4.17678 14.7626 4.46967 14.4697L8.46967 10.4697C8.76256 10.1768 9.23744 10.1768 9.53033 10.4697C9.82322 10.7626 9.82322 11.2374 9.53033 11.5303L6.81066 14.25H15C16.2426 14.25 17.25 13.2426 17.25 12V9C17.25 7.75736 16.2426 6.75 15 6.75H12C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25H15C17.0711 5.25 18.75 6.92893 18.75 9V12Z' />
    </svg>
  );
}
