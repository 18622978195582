import { actions, selectors } from '@ai21/studio-store';
import { call, delay, select, put, takeLatest, takeEvery } from 'saga-ts';
import { Json } from '../types';
import { getGenerationParams } from '../utils/boxes';
import { patchBox } from './helpers/boxes';
import { predicateBox } from './predicates';

export type ActionParams = {
  type: 'PATCH_BOX';
  id: string;
  payload: {
    values: Json;
  };
};

export function* onParamsChange(action: ActionParams) {
  yield delay(50);

  const { id, payload } = action;
  const { values } = payload;
  const { modelId, currentOutputId } = values;

  if (modelId) {
    yield put(actions.currentIds.patch({ modelId: modelId as string }));
  }

  const paramsBox = yield* select(selectors.playground.$box, id);
  const outputBox = yield* select(selectors.singles.$outputBox, currentOutputId); // prettier-ignore

  if (!paramsBox || !outputBox) {
    return;
  }

  const generationParams = getGenerationParams(values);

  yield* call(patchBox, outputBox, { ...generationParams });
}

let lastCurrentOutputId = '';

export function* onFocusChange(action: any) {
  const { payload } = action;
  const { values } = payload;
  const { currentOutputId } = values;

  if (lastCurrentOutputId === currentOutputId) {
    return;
  }

  const outputBoxes = yield* select(selectors.playground.$outputBoxes);

  let index = 1;

  for (const output of outputBoxes) {
    const isSelected = String(index) === currentOutputId;
    yield* call(patchBox, output, { isSelected });
    index++;
  }

  lastCurrentOutputId = currentOutputId;
}

export function* root() {
  yield takeLatest(predicateBox('-params'), onParamsChange);
  yield takeEvery(predicateBox('-params'), onFocusChange);
}
