import { PricingInfo } from '../../data-types/Payment';

export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_STARTED = 'PAYMENT_STARTED';

export const PAYMENT_UPDATE_FAILURE = 'PAYMENT_UPDATE_FAILURE';
export const PAYMENT_UPDATE_SUCCESS = 'PAYMENT_UPDATE_SUCCESS';
export const PAYMENT_UPDATE_STARTED = 'PAYMENT_UPDATE_STARTED';

export const SET_PAYMENT_PAGE_DETAILS = 'SET_PAYMENT_PAGE_DETAILS';
export const CLEAR_SNACKBAR_MESSAGE = 'CLEAR_SNACKBAR_MESSAGE';

export interface PaymentStarted {
  type: typeof PAYMENT_STARTED;
  fetchStartTime: number;
}

export interface PaymentFailure {
  type: typeof PAYMENT_FAILURE;
  message: string | null;
  code: string | null;
}

export interface PaymentSuccess {
  type: typeof PAYMENT_SUCCESS;
}

export interface PaymentUpdateStarted {
  type: typeof PAYMENT_UPDATE_STARTED;
  fetchStartTime: number;
}

export interface PaymentUpdateFailure {
  type: typeof PAYMENT_UPDATE_FAILURE;
  message: string | null;
  code: string | null;
}

export interface PaymentUpdateSuccess {
  type: typeof PAYMENT_UPDATE_SUCCESS;
}

export interface SetPaymentPageDetails {
  type: typeof SET_PAYMENT_PAGE_DETAILS;
  details: PricingInfo;
  stripeCoupon: string;
}

export interface ClearSnackbarMessage {
  type: typeof CLEAR_SNACKBAR_MESSAGE;
}

export type PaymentActionTypes =
  | PaymentStarted
  | PaymentSuccess
  | PaymentFailure
  | PaymentUpdateStarted
  | PaymentUpdateSuccess
  | PaymentUpdateFailure
  | SetPaymentPageDetails
  | ClearSnackbarMessage;
