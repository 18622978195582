import auth from './json/analytics.auth.json';
import clicks from './json/analytics.clicks.json';
import dialogs from './json/analytics.dialogs.json';
import flows from './json/analytics.flows.json';
import network from './json/analytics.network.json';
import tables from './json/analytics.tables.json';
import transform from './json/analytics.transform.json';
import { pages } from '../../bootstrap/Web.routes';

const automaticAnalytics = (window.__env__.VITE_AUTOMATIC_ANALYTICS ?? '').split(',');

export const analytics: any = {
  automatic: automaticAnalytics,
  auth,
  clicks,
  dialogs,
  flows,
  network,
  tables,
  transform,
  pages,
};
