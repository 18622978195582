import { api, ApiResponse, generateCode } from '@ai21/studio-api';
import { actions, selectors } from '@ai21/studio-store';
import { prompt, UrlShare } from '@ai21/studio-ui';
import { call, delay, put, select, takeEvery } from 'saga-ts';
import { WebRoutes } from '../bootstrap/Web.routes';
import { APIType } from '../constants/constants';
import { PresetOrigin } from '../data-types/PresetParams';
import { onPresetSelected } from '../redux/actions/actions.others';
import { API_MODAL_SHOWN } from '../redux/actionTypes/customModelsActionTypes';
import {
  NEW_PRESET_PRESSED,
  POST_PROMPT_SHARE_FAILED,
  POST_PROMPT_SHARE_STARTED,
  POST_PROMPT_SHARE_SUCCESS,
  PROMPT_SHARE_CLOSED,
  PROMPT_SHARE_COPIED,
} from '../redux/actionTypes/playgroundActionTypes';
import { MESSAGE_DISMISSED, MESSAGE_SHOWN } from '../redux/actionTypes/userActionTypes';
import { store } from '../redux/store';
import { Json } from '../types';
import { iPresetToPreset } from '../utils/temporaryTransformers';
import { navigate } from './saga.navigation';

type Verb = 'playground' | 'api' | 'share' | 'delete' | 'new' | 'navigate';

type ActionPreset = {
  type: 'PRESET';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  api: apiForPreset,
  playground: playgroundForPreset,
  share: sharePreset,
  delete: deletePreset,
  navigate: myPresets,
  new: newPreset,
};
function getOrigin() {
  const { pathname } = document.location;

  if (pathname.startsWith('/overview')) {
    return PresetOrigin.OVERVIEW;
  }

  if (pathname.startsWith('/presets')) {
    return PresetOrigin.PRESETS;
  }

  return PresetOrigin.PLAYGROUND;
}

function* playgroundForPreset(action: ActionPreset) {
  const { id } = action;

  navigate('/home/complete/single-input-1-output?presetId=' + id);
}

function* presetNavigate(_action: ActionPreset) {
  navigate('/home/complete/single-input-1-output');
}
function* newPreset(_action: ActionPreset) {
  navigate('/home/complete/single-input-1-output');
}

function* myPresets(_action: ActionPreset) {
  yield* put({
    type: NEW_PRESET_PRESSED,
    origin: window.location.pathname.split('/')[1],
    apiType: APIType.COMPLETION,
  });
  navigate(WebRoutes.presets);
}

function* apiForPreset(action: ActionPreset) {
  const { id } = action;
  const preset = yield* select(selectors.singles.$preset, id);

  const code = generateCode({
    flavour: 'completion',
    prompt: preset.text,
    controllerParams: preset.params,
    modelName: preset.params.model,
  });

  yield* put({
    type: API_MODAL_SHOWN,
    context: window.location.pathname.split('/')[1],
    customModelId: preset.id,
    language: 'python',
    controllerParams: preset.params,
  });

  yield prompt.code({
    title: 'API call',
    hideBackdrop: true,
    code,
  });
}

function* sharePreset(action: ActionPreset) {
  const { id } = action;
  const preset = yield* select(selectors.singles.$preset, id);
  const { text, name, params } = preset;
  store.dispatch({ type: POST_PROMPT_SHARE_STARTED, payload: params, apiType: 'completion' });
  const start = new Date();
  const response: any = yield* call(api.completion.createShare as any, {
    params,
    text,
    apiType: 'completion',
  });

  if (!response.isSuccess) {
    store.dispatch({
      type: POST_PROMPT_SHARE_FAILED,
      payload: params,
      reason: response.detail || 'UNKNOWN_ERROR',
      apiType: 'completion',
    });
  } else {
    store.dispatch({
      type: POST_PROMPT_SHARE_SUCCESS,
      payload: params,
      requestDuration: new Date().getTime() - start.getTime(),
      promptShareId: response.data?.id,
      apiType: 'completion',
    });
  }

  const url = `${
    window.__env__.VITE_APP_SERVING_URL || 'https://studio.ai21.com'
  }/home/complete?promptShare=${response.data?.id}`;
  const { didCancel } = yield prompt.custom({
    title: 'Prompt Share',
    component: UrlShare,
    componentProps: {
      url,
      callbacks: {
        onCta: () => {
          store.dispatch({
            type: PROMPT_SHARE_COPIED,
            promptShareUrl: url,
          });
        },
      },
    },
  });

  if (didCancel) {
    store.dispatch({ type: PROMPT_SHARE_CLOSED });
    return;
  }
}

function* deletePreset(action: ActionPreset) {
  const { id } = action;

  const preset = yield* select(selectors.singles.$preset, id);
  const { name } = preset;

  store.dispatch({
    type: MESSAGE_SHOWN,
    payload: {
      origin: window.location.pathname.split('/')[1],
      type: 'question',
      text: `Are you sure you want to delete the preset "${name}"?`,
      name: 'deletePreset',
    },
  });

  const { didCancel } = yield prompt.confirm({
    title: 'Delete preset',
    description: `Are you sure you want to delete the preset "${name}"?`,
    ctaButtonText: 'Delete Preset',
    intention: 'logout',
  });

  store.dispatch({
    type: MESSAGE_DISMISSED,
    payload: {
      origin: window.location.pathname.split('/')[1],
      type: 'question',
      reason: 'close',
      text: `Are you sure you want to delete the preset "${name}"?`,
      name: 'deletePreset',
    },
  });

  if (didCancel) {
    return;
  }

  const promise: any = yield* put(actions.presets.delete(id));
  const response: ApiResponse<Json> = yield promise;
  if (!response.isSuccess) {
    return;
  }

  yield put({
    type: 'ANALYTICS_PRESET_DELETED',
    preset,
  });
}

export function* preset(action: ActionPreset) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('PRESET', preset);
}
