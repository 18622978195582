import { PresetLabel } from '../../data-types/PresetParams';
import {
  OverviewActionTypes,
  PRESET_LABELS_CLEARED,
  PRESET_LABEL_CLICKED,
  PRESET_SEARCH_VALUE_CHANGED,
  PRESET_SEARCH_VALUE_CLEARED,
} from '../actionTypes/overviewActionTypes';

export interface OverviewState {
  clickedPresetLabels: PresetLabel[];
  presetSearchValue: string;
}

const initialState: OverviewState = {
  clickedPresetLabels: [],
  presetSearchValue: '',
};

export function reducer(state = initialState, action: OverviewActionTypes): OverviewState {
  switch (action.type) {
    case PRESET_LABEL_CLICKED: {
      const { presetLabel } = action;
      const clickedPresetLabels = state.clickedPresetLabels.includes(presetLabel)
        ? state.clickedPresetLabels.filter((c) => c !== presetLabel)
        : [...state.clickedPresetLabels, presetLabel];
      return { ...state, clickedPresetLabels };
    }
    case PRESET_LABELS_CLEARED: {
      return { ...state, clickedPresetLabels: [] };
    }
    case PRESET_SEARCH_VALUE_CHANGED: {
      const { searchValue } = action;
      return { ...state, presetSearchValue: searchValue };
    }
    case PRESET_SEARCH_VALUE_CLEARED:
      return { ...state, presetSearchValue: '' };
    default:
      return state;
  }
}
