import { completion } from './empty/empty.completion';
import { pas } from './initialState.ape';
import { apis } from './initialState.apis';
import { boxes } from './initialState.boxes';
import { collectionJobs, collectionLines } from './initialState.collection';
import { demos } from './initialState.demos';
import { documentQuestions, documents } from './initialState.documents';
import { evaluationJobs, evaluationLines } from './initialState.evaluation';
import { generationJobs, generationLines } from './initialState.generation';
import { models } from './initialState.models';
import { presets } from './initialState.presets';
import { apps } from './initialState.apps';
import { sheetCells } from './initialState.sheets';
import { IStudioStore, ModelStatus, Tier, UserRole, UserStatus } from './types';

const isMobile = /Mobi|Android/i.test(navigator.userAgent);

export const initialState: IStudioStore = {
  appState: {
    isAuthenticated: false,
    isIntercomVisible: true,
    isDataFetched: false,
    showAnalyticsLogs: false,
    progressTotal: 0,
    progressCompleted: 0,
    playModels: [
      'jamba-instruct-preview',
      'j2-light',
      'j2-mid',
      'j2-ultra',
      'jamba-1.5-large',
      'jamba-1.5-mini',
    ],
    slimSideBar: isMobile ? true : false,
  },
  currentIds: {
    modelId: '',
    datasetId: '',
    presetId: '',
    evaluationLineId: '',
    evaluationJobId: '',
    generationJobId: '',
    generationLineId: '',
    collectionJobId: '',
    collectionLineId: '',
    demoId: '',
    appId: '',
    demoFrameId: '',
    cellId: '',
    layoutId: '',
    familyId: '',
    docId: '1',
    askSpecificDocumentIds: [],
  },
  loading: {
    user: true,
    organization: true,
    users: true,
    paymentsInfo: true,
    billingInfo: true,
    completionUsages: true,
    specializedUsages: true,
    trainingUsages: true,
    invoices: true,
    playground: true,
    presets: true,
    fineTuningSets: true,
    models: true,
    completion: true,
    apis: true,
    evaluationJobs: true,
    evaluationLines: true,
  },
  user: {
    userId: 'U1',
    organizationId: 'O1',
    organizationDbId: 513,
    featureFlags: {},
    customerId: 'cus_1',
    apiKey: 'KEY',
    userStatus: UserStatus.INITIAL,
    userTier: Tier.BASIC,
    userRole: UserRole.MEMBER,
    subscriptionExpirationDate: '2023-03-14T10:04:56+00:00',
    subscriptionCancellationDate: '2023-03-14T10:04:56+00:00',
    userName: '',
    organizationName: '',
  },
  organization: {
    organizationId: 'O1',
    name: 'My organization',
  },
  users: {
    U1: {
      id: 'U1',
      name: 'James McAvoy',
      email: 'james@company.com',
      role: UserRole.ADMIN,
      added_on: '2023-02-12T13:25:27.786010+00:00',
    },
  },
  paymentsInfo: {
    brand: 'visa',
    last4: 1234,
  },
  billingInfo: {
    upcomingBilling: {
      date: 1678788296,
      amount: 29,
    },
    lastBilling: {
      date: 1678702180,
      amount: 29,
    },
    minimumPrice: 29,
  },
  completionUsages: [
    {
      id: '1',
      modelName: 'j1-light',
      modelType: 'GENERAL',
      customModelType: '',
      generatedTokensUsed: 0,
      promptTokensUsed: 0,
      usageDate: '',
      numRequests: 0,
      tierType: Tier.BASIC,
      periodEnd: 1678788296000,
      periodStart: 1678701896000,
      isFreeModel: false,
      status: ModelStatus.ACTIVE,
      totalCost: 0,
    },
  ],
  specializedUsages: [
    {
      id: '1',
      numRequests: 0,
      totalCost: 0,
      periodStart: 1678701896000,
      periodEnd: 1678788296000,
      displayName: 'Paraphrase',
    },
  ],
  trainingUsages: [
    {
      id: '1',
      customModelType: 'j1-light',
      quantity: 0,
      cost: 0,
      periodStart: 1678701881000,
      periodEnd: 1678787996000,
    },
  ],
  invoices: {
    I1: {
      id: 'I1',
      invoiceId: 'I1',
      periodStart: 1678615496,
      status: 'paid',
      amount: 2900,
      pdf: 'https://pay.stripe.com/123',
    },
  },
  playground: {
    prompt: '',
    maxTokens: 200,
    temperature: 0.7,
    topP: 1,
    stopSequences: [],
    modelId: 'j2-ultra',
    topKReturn: 0,
    presencePenalty: 0,
    countPenalty: 0,
    frequencyPenalty: 0,
  },
  presets,
  fineTuningSets: {
    'fe4e96b0-6387-4b2f-8650-42327f3cffbb': {
      id: 'fe4e96b0-6387-4b2f-8650-42327f3cffbb',
      name: 'dataset-example.csv',
      fileSizeInBytes: 12048,
      dateCreated: 1,
      linesCount: 51,
      validationNumExamples: 6,
      trainNumExamples: 45,
      numModelsUsed: 3,
      creator: '',
      dateCompleted: '',
      description: '',
      linesCompleted: 0,
      status: '',
      setSource: 'upload',
      setType: 'fts',
      tags: [],
    },
  },
  models,
  completion,
  apis,
  evaluationJobs,
  evaluationLines,
  sheetCells,
  documents,
  documentQuestions,
  generationJobs,
  generationLines,
  collectionJobs,
  collectionLines,
  boxes,
  demos,
  pas,
  apps,
};
