import { api } from '@ai21/studio-api';
import {
  IBox,
  actions,
  selectors,
  createEvaluationLine,
  IEvaluationLine,
} from '@ai21/studio-store';
import { toast } from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, put, select, take } from 'saga-ts';
import { Json } from '../types';
import { addVariables } from '../utils/prompt';
import { patchBox } from './helpers/boxes';
import type { ActionPlay } from './saga.play';
import { clearLogger, log } from './helpers/logger';
import { prepareRequestParams } from './helpers/completion';
import { invokeEvent } from 'shared-base';

export function* ape(_action: ActionPlay, box: IBox) {
  const pas = yield* select(selectors.raw.$rawPas);
  const ape = yield* select(selectors.playground.$ape);

  const rawUser = yield* select(selectors.raw.$rawUser);
  const { userName = '' } = rawUser;
  const firstName = userName.split(' ')[0];

  let { pa } = ape;

  if (!pa) {
    pa = Object.values(pas)[0];
  }

  if (!pa) {
    invokeEvent('OpenAccordion', { id: ape.boxId });
    toast.show('Please select a strategy', 'warning');

    return;
  }

  const promptRaw = get(box, 'values.prompt', '');

  if (!promptRaw) {
    toast.show('Please enter a prompt', 'warning');
    return;
  }

  let now = Date.now(),
    duration = 0;

  yield call(clearLogger);

  yield* call(patchBox, box, {
    isLoading: true,
    duration: '',
    promptBackup: promptRaw,
  });

  const variables: Json = {
    firstName,
  };

  const response = yield* call(api.ape.improve, promptRaw, pa, variables);

  const suggestionRaw = get(response, 'data.completions[0].data.text', '');
  const suggestion = cleanSuggestion(suggestionRaw);

  duration = Date.now() - now;

  yield* call(patchBox, box, {
    prompt: suggestion,
    isLoading: false,
  });

  invokeEvent('BaseEditorChange', { id: box.id, value: suggestion });
}

export function* generateApeCompletion(_action: ActionPlay, box: IBox) {
  const outputBoxes = yield* select(selectors.playground.$outputBoxes);
  const promptRaw = get(box, 'values.prompt', '');
  const modelId = get(outputBoxes, '[1].values.modelId', '');
  const paramValues = yield* select(selectors.playground.$params);

  if (!modelId || !promptRaw || !paramValues) {
    return;
  }

  yield* call(patchBox, box, {
    generateLoading: true,
  });

  yield* call(patchBox, outputBoxes[1], { isLoading: true });

  const params = {
    modelId,
    ...paramValues,
  };

  const parsedParams = prepareRequestParams(promptRaw, params, []);

  const response = yield* call(api.completion.complete, parsedParams);

  const completion = get(response, 'data.completions[0].data.text', '').replace(
    '\n',
    ''
  );

  yield* call(patchBox, outputBoxes[1], {
    content: completion,
    isLoading: false,
  });

  yield* call(patchBox, box, {
    generateLoading: false,
  });
}

export function* apeUndo(_action: ActionPlay, box: IBox) {
  const promptBackup = get(box, 'values.promptBackup', '');

  yield* call(patchBox, box, {
    prompt: promptBackup,
    promptBackup: '',
  });
}

export const cleanSuggestion = (suggestionRaw: string) => {
  let output = suggestionRaw;

  let regex = /Suggested Prompt:/;

  let index = output.search(regex);

  if (index >= 0) {
    output = output.slice(index + 17);
  }

  return output;
};
