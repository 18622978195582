import { Button, Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  background-color: #f9fafa;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #dce3ec;
  margin: 12px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
`;

export const Title = styled(Typography)`
  color: #1d1e22;
  font-weight: 500;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Details = styled.div`
  margin-bottom: 14px;
`;

export const Text = styled(Typography)``;

export const UpgradeButton = styled(Button)`
  color: ${tokens.text.secondary};
`;
