import { Action } from 'redux-store-generator';
import { IConnectedResult, INodeInfo } from './connected.types';
import { serverMap } from './serverMap';
import { get } from 'lodash';

export const connectedAdd = async (info: INodeInfo, action: Action): Promise<IConnectedResult> => {
  const { nodeName } = info;

  const node = serverMap[nodeName];

  if (!node || !node.add) {
    return {
      shouldReachStore: false,
      shouldReachStoreReason: 'no definition for ADD method',
      response: undefined,
    };
  }

  // API call
  const response = await node.add(action.payload);

  const { isSuccess, errorMessage } = response;

  let nextAction;

  if (isSuccess) {
    const id = get(response, 'data.id') || get(action, 'payload.id');
    const { payload = {} } = action;

    if (!payload.id) {
      payload.id = id;
    }

    nextAction = node.set(id, payload);
  } else {
    console.warn(errorMessage);
  }

  return {
    shouldReachStore: isSuccess,
    shouldReachStoreReason: 'success',
    response,
    nextAction,
  };
};
