import { selectors } from '@ai21/studio-store';
import { useSelector } from 'react-redux';
import { GenerationProgress } from './GenerationProgress';

export type GenerationProgressContainerProps = {
  onCancel: () => void;
};

export function GenerationProgressContainer(
  props: GenerationProgressContainerProps
) {
  const appState = useSelector(selectors.raw.$rawAppState);

  const { progressTotal, progressCompleted } = appState;

  return (
    <GenerationProgress
      total={progressTotal}
      completed={progressCompleted}
      onCancel={props.onCancel}
    />
  );
}

export default GenerationProgressContainer;
