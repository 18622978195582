export function CreditCardIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.50145 7.85846C3.50145 6.55184 4.55122 5.50229 5.8343 5.50229H18.1657C19.4488 5.50229 20.4986 6.55184 20.4986 7.85846V9.17924H3.50145V7.85846ZM2.00145 9.92924V7.85846C2.00145 5.73409 3.71215 4.00229 5.8343 4.00229H18.1657C20.2878 4.00229 21.9986 5.73409 21.9986 7.85846V9.92924V16.1416C21.9986 18.2659 20.2878 19.9977 18.1657 19.9977H5.8343C3.71215 19.9977 2.00145 18.2659 2.00145 16.1416V9.92924ZM20.4986 10.6792V16.1416C20.4986 17.4482 19.4488 18.4977 18.1657 18.4977H5.8343C4.55122 18.4977 3.50145 17.4482 3.50145 16.1416V10.6792H20.4986ZM6.11191 15.1062C6.11191 14.692 6.4477 14.3562 6.86191 14.3562H6.87191C7.28613 14.3562 7.62191 14.692 7.62191 15.1062C7.62191 15.5204 7.28613 15.8562 6.87191 15.8562H6.86191C6.4477 15.8562 6.11191 15.5204 6.11191 15.1062ZM10.9724 14.3562C10.5582 14.3562 10.2224 14.692 10.2224 15.1062C10.2224 15.5204 10.5582 15.8562 10.9724 15.8562H13.0276C13.4418 15.8562 13.7776 15.5204 13.7776 15.1062C13.7776 14.692 13.4418 14.3562 13.0276 14.3562H10.9724Z'
        fill='#C3C6CE'
      />
    </svg>
  );
}

export default CreditCardIcon;
