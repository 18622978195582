import { CompletionFailureReason } from '../data-types/Completion';

export const FILE_MAX_SIZE = 1073741824;
export const IDENTITY_HOST = window.__env__.VITE_IDENTITY_SERVER_URL || '';
export const API_HOST = window.__env__.VITE_API_SERVER_URL || '';
export const APP_HOST = window.__env__.VITE_APP_SERVING_URL || '';

// ANCHORS
export const PRICING_PAGE_REWRITE_ANCHOR = '#rewrite';
export const PRICING_PAGE_FAQS_ANCHOR = '#FAQs';

// SOURCE
export const DOCS_SRC = 'docs';

// ROUTES
export const DOCS_ROUTE = 'https://docs.ai21.com';
export const DOCS_EPOCHS_ROUTE = 'https://docs.ai21.com/docs/tips-tricks';
export const DOCS_DEFAULT_EPOCHS_ROUTE = 'https://docs.ai21.com/reference/update-default-epoch';
export const MARKETING_PRICING = 'https://www.ai21.com/studio/pricing';
export const RESPONSIBLE_USE_LINK = 'https://docs.ai21.com/docs/responsible-use';
export const TRAINING_DATA_LINK = 'https://docs.ai21.com/docs/training-data';
export const FOUNDATION_DOCS_LINK = 'https://docs.ai21.com/docs/jurassic-2-models';
export const LEGO_DOCS_LINK = 'https://docs.ai21.com/docs/task-specific';
export const ROOT_ROUTE = '/';
export const DISCORD_INVITE_LINK = 'https://discord.gg/cKzg6GEAyB';
export const MARKETING_PRICING_ROUTE = 'https://www.ai21.com/studio/pricing';
export const RELEASE_NOTES_MARKDOWN =
  'https://raw.githubusercontent.com/dht/assets/main/release-notes.md';
export const MRKL_PAPER_LINK = 'https://arxiv.org/pdf/2205.00445.pdf';
export const JURASSIC_X_EARLY_ACCESS_LINK = 'https://dghsiym3kjj.typeform.com/to/rnIzTBQ0';
export const FROZEN_MODEL_PAPER_LINK =
  'https://storage.cloud.google.com/ai21-public-data/publications/Standing_on_the_shoulders_of_giant_LMs.pdf';

// FORMS
export const WELCOME_FORM = window.__env__.VITE_SIGN_UP_JOTFORM || '';
export const CONTACT_JOTFORM = window.__env__.VITE_CONTACT_JOTFORM;

export const GENERATE_TEXT = 'Generate';
export const SUMMARIZE_TEXT = 'Generate';
export const PLAYGROUND_PLACEHOLDER_TEXT = 'Write text and click Generate (Ctrl+Enter or ⌘+Enter)';
export const SUMMARIZATION_PLACEHOLDER_TEXT =
  'Write text and click Generate (Ctrl+Enter or ⌘+Enter)';
export const REWRITE_PLACEHOLDER_TEXT = 'Write text and click Generate (Ctrl+Enter or ⌘+Enter)';

// playground panel tooltips
export const RESPONSE_LENGTH_TOOLTIP_TEXT =
  'By setting a maximum number of tokens to generate, you can limit the length of the model output. J2 models support 8192 tokens including prompt and completion, while all J1 models support 2048 tokens.';
export const TEMPERATURE_TOOLTIP_TEXT =
  'Controls sampling randomness. Increasing the temperature tends to result in more varied and creative completions, whereas decreasing it produces more stable and repetitive completions. A temperature of zero effectively disables random sampling and makes the completions deterministic.';
export const TOP_P_TOOLTIP_TEXT =
  'The percentile of probability from which tokens are sampled. A value lower than 1.0 means that only the corresponding top percentile of options are considered, and that less likely options will not be generated, resulting in more stable and repetitive completions.';
export const STOP_SEQUENCES_TOOLTIP_TEXT =
  'Generation is terminated as soon as the model generates any of the stop sequences. Choosing appropriate stop sequences can help you avoid generating unnecessary tokens. Hit ↵ Enter to insert a newline character in a stop sequence. Hit the Tab key to finish inserting a stop sequence.';

export const COMPLETION_FAILURES = {
  [CompletionFailureReason.CREDIT_EXCEEDED]:
    'Your credit limit has been exceeded. Upgrade plan to continue.',
  [CompletionFailureReason.TRIAL_ENDED]: 'Trial period has ended. Upgrade plan to continue.',
  [CompletionFailureReason.UNKNOWN_ERROR]: 'Something went wrong... Please try again',
  [CompletionFailureReason.TOO_MANY_TOKENS]:
    'Prompt + completion length must be less than {X} tokens',
  [CompletionFailureReason.TOO_MANY_REQUESTS]:
    'Rate limit for completion requests has been exceeded',
};

export const PLAYGROUND_EDITOR_WHITESPACE_WARNING =
  'Ending the prompt with a whitespace may harm output quality.';

// API
export const TOKENIZE_ENDPOINT = 'studio/v1/tokenize';
export const PROMPT_SHARE_ENDPOINT = '/identity/prompt-share';
export const TIER_ENDPOINT = '/identity/tier';
export const BILLING_ENDPOINT = '/identity/billing';
export const PRESET_ENDPOINT = '/identity/preset';
export const DATASET_ENDPOINT = '/dataset';
export const JURASSIC_X_PRESET_ENDPOINT = '/identity/jurassic-x/preset';
export const JURASSIC_X_COMPLETION_ENDPOINT = 'studio/alpha/jurassic-x/complete';
export const JURASSIC_X_DATABASE_ENDPOINT = 'studio/alpha/jurassic-x/database';
export const MODEL_ENDPOINT = '/custom-model';

// FIREBASE ERRORS
export const FIREBASE_EMAIL_INCORRECT_MESSAGE = 'Your email or password is incorrect.';
export const FIREBASE_UNKNOWN_ERROR_MESSAGE = 'Something went wrong... Please try again';

export const INVALID_PHONE_NUMBER_MESSAGE = 'The phone number entered is incorrect';
export const NO_PHONE_NUMBER_MESSAGE = 'No phone number was provided';
export const REQUIRE_RE_LOGIN_MESSAGE =
  'Operation requires recent authentication, please log in again';
export const INVALID_VERIFICATION_CODE_MESSAGE = 'The verification code entered is incorrect';
export const EMAIL_PLUS_SIGN_CODE_MESSAGE = 'Email must not contain + sign';
export const GENERIC_ERROR_MESSAGE = 'Something went wrong... Please try again';

export const GENERATE_SHORTCUT_KEY = 'Enter';
export const PROMPT_SHARE_KEY = 'promptShare';

export const MODEL_STATUS_ACTIVE = 'ACTIVE';

export const MID_INSTRUCT_FEATURE_FLAG = 'midInstructFeatureEnabled';
export const SUMMARIZATION_FEATURE_FLAG = 'summarizationFeatureEnabled';
export const REWRITE_FEATURE_FLAG = 'rewriteFeatureEnabled';

export enum APIType {
  COMPLETION = 'completion',
}

export const MODELS_DISPLAY_NAMES: { [k: string]: string } = {
  'j2-ultra-instruct': 'j2-ultra',
  'j2-mid-instruct': 'j2-mid',
  'j2-ultra': 'j2-ultra',
  'j2-mid': 'j2-mid',
  'j2-light': 'j2-light',
  'jamba-instruct-preview': 'jamba-instruct-preview',
  'jamba-1.5-large': 'jamba-1.5-large',
  'jamba-1.5-mini': 'jamba-1.5-mini',
};

export const MAP_MODELS_TO_J2_MODELS: { [k: string]: string } = {
  'j2-ultra-instruct': 'j2-ultra',
  'j2-mid-instruct': 'j2-mid',
  'j2-ultra': 'j2-ultra',
  'j2-mid': 'j2-mid',
  'j2-light': 'j2-light',
  'jamba-instruct-preview': 'jamba-instruct-preview',
  'jamba-1.5-large': 'jamba-1.5-large',
  'jamba-1.5-mini': 'jamba-1.5-mini',
  'j2-jumbo-instruct': 'j2-ultra',
  'j2-grande-instruct': 'j2-mid',
  'j2-jumbo': 'j2-ultra',
  'j2-grande': 'j2-mid',
  'j2-large': 'j2-light',
  'j1-grande-instruct': 'j2-mid',
  'j1-grande-v2': 'j2-mid',
  'j1-jumbo': 'j2-ultra',
  'j1-grande': 'j2-mid',
  'j1-large': 'j2-light',
};

export const MODELS_GROUPS_DISPLAY_NAMES: { [k: string]: string } = {
  foundation: 'Foundation models',
  custom: 'My custom models',
};

export const MODELS_VALUES_BY_DISPLAY: { [k: string]: string } = Object.fromEntries(
  Object.entries(MODELS_DISPLAY_NAMES).map(([key, value]) => [value, key])
);

export const TIERS_DISPLAY_NAMES: { [k: string]: string } = {
  BASIC: 'Starter',
  FREE: 'FREE Plan',
};

export const SAFETY_EMAIL = 'safety@ai21.com';
export const CONTACT_EMAIL = 'studio@ai21.com';
export const SAFETY_EMAIL_SUBJECT = 'Reporting inappropriate content generated in AI21 Studio';

export const BASE_TYPING_SPEED_MS = 20;
export const VARIANCE_TYPING_SPEED_MS = 30;
export const GENERATION_TOKENS_SPEEDUP_THRESHOLD = 128;

export const DEFAULT_J2_LIGHT_LEARNING_RATE = 0.03;
export const DEFAULT_J2_MID_LEARNING_RATE = 0.03;
export const DEFAULT_J2_ULTRA_LEARNING_RATE = 0.001;

// overview page
export const MAX_ITEMS_IN_OVERVIEW_TABLE = 5;
// mobilePage
export const MOBILE_PAGE_MAIN_TEXT =
  'Hello there! AI21 Studio app is not quite ready for mobile devices.';

// error codes
export const NO_PHONE_NUMBER_PROVIDED_CODE = 'auth/no-phone-number-provided';
export const REQUIRE_RE_LOGIN = 'auth/requires-recent-login';

// org tab names
export enum OrganizationTabs {
  ACCOUNT,
  ORGANIZATION,
  MODEL_USAGE,
  BILLING_AND_PLANS,
}

// account page
export const CANCEL_BUTTON_TEXT = 'Cancel Plan';
export const UPGRADE_BUTTON_TEXT = 'Upgrade Now';

export const INVALID_DATASET_NAME_ERROR_MESSAGE =
  'File name must be shorter than 30 characters and include only digits, letters or one of the following characters: - . _';
export const DUPLICATE_DATASET_NAME_ERROR_MESSAGE =
  'File name must be unique, please rename the file and upload again';
export const DATASET_UNKNOWN_EXTENSION_ERROR_MESSAGE =
  'Could not process the file. Please upload a valid JSONL or CSV file';
export const DATASET_ILLEGAL_FORMAT_ERROR_MESSAGE =
  'Could not process the file. Please upload a valid JSONL or CSV file';
export const DATASET_MIN_ROWS_ERROR_MESSAGE =
  'File contains too few examples. At least 50 examples are required.';
export const GENERAL_EXCEPTION_ERROR_MESSAGE =
  'Oops, something went wrong. Please contact studio@ai21.com for assistance.';
export const EMPTY_COMPLETION_ERROR_MESSAGE = 'Dataset contains empty completion';
export const CREDIT_EXCEEDED_ERROR_MESSAGE =
  'Not enough credit available for the selected custom model training. Please upgrade your account to continue.';
export const TRIAL_ENDED_ERROR_MESSAGE =
  'Trial period ended. Please upgrade your account to continue.';
export const FEEDBACK_ENABLED = 'feedbackEnabled';
export const STARRED_DATASETS = 'starredDatasets';
export const STARRED_MODELS = 'starredModels';
export const FEEDBACK_DISMISS_COUNTER = 'feedbackDismissCounter';
export const MAX_DISMISS_CLICKS = 4;
export const MICTO_REVIEWS_TOOLTIP_TEXT =
  'Help us make our models better by telling us how well they did.';

// pricing page

export const PLAYGROUND_SETTINGS_CHANGE_INTERVAL_MILLIS = 5000;
export const PLAYGROUND_SETTINGS_CHANGE_INTERVAL_SECONDS =
  PLAYGROUND_SETTINGS_CHANGE_INTERVAL_MILLIS / 1000;

export const MICTO_REVIEWS_CONTAINER_TITLE: { [k: string]: string } = {
  INITIAL: 'How was the completion?',
  OKAY: 'What would you like us to improve?',
  GREAT: 'Amazing! Can you elaborate?',
  BAD: "What's bad about the generated text?",
};

export const MICTO_REVIEWS_OPTION_BADGES: { [k: string]: string[] } = {
  INITIAL: ['Great', 'Okay', 'Bad'],
  OKAY: ['Relevance', 'Coherence', 'Length issue', 'Creativity', 'Style', 'Truthfulness', 'Other'],
  GREAT: ['Relevant', 'Creative', 'Style', 'Coherent', 'Truthful', 'Other'],
  BAD: ['Irrelevant', 'Incoherent', 'False', 'Length issue', 'Repetitive', 'Offensive', 'Other'],
};

export const FEEDBACK_SCORE: { [k: string]: number } = {
  BAD: 0,
  OKAY: 5,
  GREAT: 10,
};

export enum IntentType {
  LONG = 'long',
  SHORT = 'short',
  FORMAL = 'formal',
  CASUAL = 'casual',
  GENERAL = 'general',
}
