import { IShowIf } from '../types';

export const checkShowIf = (item: Json, showIf?: IShowIf) => {
  if (!showIf) {
    return true;
  }

  const { fieldId, relation, value } = showIf;

  const cellValue = item[fieldId];

  switch (relation) {
    case 'eq':
      return cellValue === value;
    case 'neq':
      return cellValue !== value;
    case 'gt':
      return cellValue > value;
    case 'gte':
      return cellValue >= value;
    case 'lt':
      return cellValue < value;
    case 'lte':
      return cellValue <= value;
    default:
      return false;
  }
};
