import { ax } from '@ai21/studio-analytics';
import { api } from '@ai21/studio-api';
import { Form } from '@ai21/studio-forms';
import {
  ICollectionLine,
  actions,
  fixLineContent,
  selectors,
  IBox,
} from '@ai21/studio-store';
import { FileDrop, prompt, toast } from '@ai21/studio-ui';
import { call, delay, put, select, take, takeEvery } from 'saga-ts';
import { downloadJson as downloadFile, downloadText, guid4 } from 'shared-base';
import { formDefaults, forms } from '../_definitions/forms';
import { Json } from '../types';
import { jsonToCsv, readFile } from '../utils/file';
import { analytics } from './helpers/analytics';
import { createJob } from './helpers/job';

const MAX_COLLECTION_JOB_LINES = 1000;

type Verb =
  | 'new'
  | 'import'
  | 'delete'
  | 'copyId'
  | 'view'
  | 'settings'
  | 'evaluate'
  | 'start'
  | 'downloadJson'
  | 'downloadCsv'
  | 'navigate'
  | 'playground';

type ActionJob = {
  type: 'COLLECTION_JOB';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  new: newJob,
  import: importJob,
  delete: deleteJob,
  copyId: copyId,
  settings: openJobSettings,
  view: viewJob,
  downloadJson: downloadJson,
  downloadCsv: downloadCsv,
  start: start,
  navigate: navigate,
  evaluate: evaluateJob,
  playground: openInPlayground,
};
const MAX_LINES_FOR_EVALUATION = 1000;

export function* newJob(action: ActionJob, box: IBox) {
  const content: any = [];

  const allOptions = yield* select(selectors.options.$allOptions);
  const user = yield* select(selectors.raw.$rawUser);

  const newJobId = guid4();

  const { didCancel: didCancel2, value: value2 } = yield prompt.form({
    component: Form,
    title: 'Name your collection set',
    form: {
      config: forms.collections,
      data: {
        ...formDefaults.collectionsDefault,
        uploader: user.userName,
        name: `Set #${newJobId}`,
        id: newJobId,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
    },
  });

  if (didCancel2) {
    return false;
  }

  const { id, name, description } = value2;

  yield call(
    createJob,
    content as Json[],
    {
      id,
      name,
      description,
      creator: user.userName,
      setSource: 'upload',
      setSourceId: '',
    } as any,
    action.type === 'COLLECTION_JOB'
  );

  return newJobId;
}

export function* importJob(action: ActionJob, box: IBox) {
  const { value, didCancel } = yield prompt.custom({
    title: 'Upload set',
    component: FileDrop,
    componentProps: {
      acceptTypes: ['.csv', '.jsonl'],
      ctaButtonText: 'Select file',
      warning:
        'Using data-collections is subject to the API usage pricing associated with your account.',
      comment: `The acceptable formats are JSONL or CSV only, max items of 1,000 in one batch and max file size can't be more than 10MB. Download the [example CSV file](/collection.example.csv) or get help in our [Technical documentation](https://docs.ai21.com/).`,
    },
    componentCta: 'onDrop',
    intention: 'upload',
  });

  if (didCancel || !value) {
    return false;
  }

  const file = value[0];
  const fileName = file.name;
  let content: any = yield* call(readFile, file);

  if (!content) {
    yield call(newJobError, "Failed to read file's content.");
    return false;
  }

  if (!Array.isArray(content)) {
    yield call(newJobError, 'File must be an array of lines.');
    return false;
  }

  if (content.length >= MAX_COLLECTION_JOB_LINES) {
    yield call(
      newJobError,
      `File must have less than ${MAX_COLLECTION_JOB_LINES} lines.`
    );
    return false;
  }

  content = fixLineContent(content);
  if (!content[0].prompt) {
    yield call(
      newJobError,
      'Missing field names: file must have a "prompt" and "model" field in each line.'
    );
    return false;
  }

  const allOptions = yield* select(selectors.options.$allOptions);
  const user = yield* select(selectors.raw.$rawUser);

  const newJobId = guid4();

  const { didCancel: didCancel2, value: value2 } = yield prompt.form({
    component: Form,
    title: 'Name your collection set',
    form: {
      config: forms.collections,
      data: {
        ...formDefaults.collectionsDefault,
        uploader: user.userName,
        name: `Set #${newJobId}`,
        id: newJobId,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
    },
  });

  if (didCancel2) {
    return false;
  }

  const { id, name, description } = value2;

  yield call(
    createJob,
    content as Json[],
    {
      id,
      name,
      description,
      creator: user.userName,
      setSource: 'upload',
      setSourceId: '',
    } as any,
    action.type === 'COLLECTION_JOB'
  );

  return newJobId;
}

export function* duplicateJob(action: ActionJob, box: IBox) {
  yield* put(actions.collectionLines.get({ setId: box.values.collectionId }));
  yield take('SET_COLLECTIONLINES');

  const lines = yield* select(selectors.raw.$rawCollectionLines);
  const filtersLines = Object.values(lines).filter(
    (line: any) => line.setId === box.values.collectionId && !line.isDeleted
  );

  let content = filtersLines;

  content = fixLineContent(content);

  if (content.length >= MAX_COLLECTION_JOB_LINES) {
    yield call(
      newJobError,
      `File must have less than ${MAX_COLLECTION_JOB_LINES} lines.`
    );
    return false;
  }

  const allOptions = yield* select(selectors.options.$allOptions);
  const user = yield* select(selectors.raw.$rawUser);

  const newJobId = guid4();

  const { didCancel: didCancel2, value: value2 } = yield prompt.form({
    component: Form,
    title: 'Name your collection set',
    form: {
      config: forms.collections,
      data: {
        ...formDefaults.collectionsDefault,
        uploader: user.userName,
        name: `Set #${newJobId}`,
        id: newJobId,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
    },
  });

  if (didCancel2) {
    return false;
  }

  const { id, name, description } = value2;

  yield call(
    createJob,
    content as Json[],
    {
      id,
      name,
      description,
      creator: user.userName,
      setSource: 'upload',
      setSourceId: '',
    } as any,
    action.type === 'COLLECTION_JOB'
  );

  return newJobId;
}

function* start(action: ActionJob) {
  const { id } = action;

  const job = yield* select(selectors.singles.$collectionJob, id);
  const lines = yield* select(selectors.singles.$collectionLines, id);

  if (!job) {
    return;
  }

  const emptyLines = lines.filter((line: ICollectionLine) => !line.output);

  if (emptyLines.length === 0) {
    toast.show('Collection was created successfully');
    return;
  }

  yield put(
    actions.appState.patch({
      progressTotal: emptyLines.length,
    })
  );
}

function* viewJob(action: ActionJob) {
  yield put({
    type: 'NAVIGATE',
    to: `/datasets/data-collections/${action.id}/lines`,
  });
}

function* navigate(action: ActionJob) {
  const { to } = action.params || {};

  yield put({
    type: 'NAVIGATE',
    to,
  });
}

function* newJobError(reason: string) {
  toast.show(reason, 'error');

  yield* call(analytics, {
    action: 'upload',
    actionValue: reason,
    eventId: 'CollectionUploadError',
  });
}

export function* evaluateJob(action: ActionJob) {
  const { id } = action;

  if (!id) {
    return;
  }

  const lines = yield* select(selectors.singles.$collectionLines, id);
  const linesCount = lines.filter((line) => !line.isDeleted).length;

  if (linesCount > MAX_LINES_FOR_EVALUATION) {
    toast.show(
      `Evaluating sets with more than ${MAX_LINES_FOR_EVALUATION} lines is not supported`,
      'error'
    );
    return;
  }

  const response: any = yield* call(
    api.collection.transformToEvaluationSet,
    id
  );

  if (!response?.isSuccess) {
    toast.show('Failed to create evaluation job for collection set', 'error');
    return;
  }

  yield put(actions.evaluationJobs.get({}));
  yield delay(500);

  yield put({
    type: 'NAVIGATE',
    to: `/tools/evaluation/${response.data.id}/inbox?openSettings=true`,
  });
}

export function* deleteJob(action: ActionJob) {
  const { id } = action;

  const job = yield* select(selectors.singles.$collectionJob, id);

  const { name } = job;

  const { didCancel } = yield prompt.confirm({
    title: 'Delete Collection',
    description: `Are you sure you want to delete collection set "${name}"?`,
    ctaButtonText: 'Delete Job',
    intention: 'delete',
  });

  if (didCancel) {
    return;
  }

  yield put(
    actions.collectionJobs.patch(id, {
      isDeleted: true,
    })
  );

  if (document.location.pathname.includes('lines')) {
    yield put({
      type: 'NAVIGATE',
      to: 'datasets/data-collections',
    });
  }
}

function* copyId(action: ActionJob) {
  const { id } = action;
  navigator.clipboard.writeText(id);
  toast.show('Copied to clipboard', { type: 'success' });
}

export function* downloadJson(action: ActionJob) {
  const { id } = action;

  yield* put(actions.collectionLines.get({ setId: id }));
  yield take('SET_COLLECTIONLINES');

  try {
    const lines = yield* select(selectors.raw.$rawCollectionLines);
    const filtersLines = Object.values(lines).filter(
      (line: any) => line.setId === id && !line.isDeleted
    );

    const fileName = `job-${id}.json`;

    downloadFile(`job-${id}.json`, filtersLines);

    ax.nudge('collectionSets.downloads');

    yield* call(analytics, {
      action: 'downloadJson',
      actionValue: fileName,
      eventId: 'CollectionSetDownloaded',
    });
  } catch (err) {
    yield* call(analytics, {
      action: 'downloadJson',
      actionValue: `Failed to download to json, error: ${err}`,
      eventId: 'CollectionSetDownloadFailed',
    });
  }
}

export function* downloadCsv(action: ActionJob) {
  const { id } = action;

  yield* put(actions.collectionLines.get({ setId: id }));
  yield take('SET_COLLECTIONLINES');

  const lines = yield* select(selectors.raw.$rawCollectionLines);
  const filtersLines = Object.values(lines).filter(
    (line) => line.setId === id && !line.isDeleted
  );

  const csv = jsonToCsv(filtersLines);

  if (!csv) {
    toast.show('Failed to convert to CSV', 'error');
    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: 'Failed to convert to CSV',
      eventId: 'collectionSetDownloadFailed',
    });
    return;
  }

  try {
    const fileName = `job-${id}.csv`;
    downloadText(fileName, csv);

    ax.nudge('collectionSets.downloads');

    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: fileName,
      eventId: 'CollectionSetDownloaded',
    });
  } catch (err) {
    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: `Failed to download CSV, error: ${err}`,
      eventId: 'CollectionSetDownloadFailed',
    });
  }
}

export function* openJobSettings(action: ActionJob) {
  const { id } = action;
  const allOptions = yield* select(selectors.options.$allOptions);
  const job = yield* select(selectors.singles.$collectionJob, id);

  if (!job) {
    return;
  }

  const { didCancel, value } = yield prompt.form({
    component: Form,
    title: 'Name your collection set',
    form: {
      config: forms.collectionsEdit,
      data: {
        ...job,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
      isEdit: true,
    },
  });

  if (didCancel) {
    return;
  }

  yield put(actions.collectionJobs.patch(id, value));

  toast.show('Job settings updated', 'success');
}

export function* openInPlayground(action: ActionJob) {
  const { id } = action;
  yield put({
    type: 'NAVIGATE',
    to: '/home/complete/single-input-1-output?collectionId=' + id,
  });
}

export function* collectionJob(action: ActionJob) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('COLLECTION_JOB', collectionJob);
}
