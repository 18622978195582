import classnames from 'classnames';
import { Wrapper } from './Rank.style';

export type RankProps = {
  value: number;
};

const map: Record<number, string> = {
  10: 'Great',
  5: 'Okay',
  0: 'Bad',
  '-1': 'Skipped',
};

export function Rank(props: RankProps) {
  const { value } = props;

  const className = classnames('Rank-wrapper', `rank-${value}`, {});

  const text = map[value];

  return (
    <Wrapper className={className} data-testid='Rank-wrapper'>
      {text}
    </Wrapper>
  );
}

export default Rank;
