import { api } from '@ai21/studio-api';
import { FirebaseError } from '@firebase/util';
import { Dispatch } from 'react';
import { APIType, MODELS_VALUES_BY_DISPLAY } from '../../constants/constants';
import { ApiKeyEndpointResponse } from '../../data-types/ApiKey';
import { AuthenticationPageType } from '../../data-types/AuthenticationPageType';
import {
  CompletionFailureReason,
  CompletionRequestParams,
  ControllerParams,
} from '../../data-types/Completion';
import { DatasetEndpointListElement, DatasetPreview } from '../../data-types/Dataset';
import { ResponseError } from '../../data-types/Error';
import { ModelEndpointListElement } from '../../data-types/Model';
import {
  OrganizationDetailsResponse,
  OrganizationUserDetailsListElement,
} from '../../data-types/Organization';
import {
  BillingInfoEndpointResponse,
  InvoiceInfo,
  PaymentMethodEndpointResponse,
  PricingInfo,
} from '../../data-types/Payment';
import { CustomPreset, RewritePresetParams } from '../../data-types/PresetParams';
import {
  CreditInfoEndpointResponse,
  SpecializedModelUsageEndpointResponse,
  TrainModelsUsageEndpointListElement,
  UsageEndpointListElement,
} from '../../data-types/Usage';
import { UserEndpointResponse } from '../../data-types/User';
import { User } from '../../types';
import { errorHandler } from '../../utils/error-reporting';
import { guid4 } from '../../utils/guid';
import { toCamelCase } from '../../utils/utils';
import {
  CustomModelsActionTypes,
  FETCH_MODELS_FAILURE,
  FETCH_MODELS_STARTED,
  FETCH_MODELS_SUCCESS,
  FETCH_MODEL_COST_FAILURE,
  FETCH_MODEL_COST_STARTED,
  FETCH_MODEL_COST_SUCCESS,
  MODEL_COST_CHANGED,
} from '../actionTypes/customModelsActionTypes';
import {
  DatasetsActionTypes,
  DatasetsType,
  FETCH_DATASETS_FAILURE,
  FETCH_DATASETS_PREVIEW_FAILURE,
  FETCH_DATASETS_PREVIEW_STARTED,
  FETCH_DATASETS_PREVIEW_SUCCESS,
  FETCH_DATASETS_STARTED,
  FETCH_DATASETS_SUCCESS,
  FETCH_DATASET_URL_FAILURE,
  FETCH_DATASET_URL_STARTED,
  FETCH_DATASET_URL_SUCCESS,
  FETCH_EXAMPLE_DATASET_URL_FAILURE,
  FETCH_EXAMPLE_DATASET_URL_STARTED,
  FETCH_EXAMPLE_DATASET_URL_SUCCESS,
} from '../actionTypes/datasetsActionTypes';
import {
  GET_PROMPT_SHARE_FAILED,
  GET_PROMPT_SHARE_STARTED,
  GET_PROMPT_SHARE_SUCCESS,
  PlaygroundActionTypes,
} from '../actionTypes/playgroundActionTypes';
import {
  GET_PRESETS_FAILED,
  GET_PRESETS_STARTED,
  GET_PRESETS_SUCCESS,
  PresetActionTypes,
} from '../actionTypes/presetActionTypes';
import {
  FETCH_PRICING_FAILED,
  FETCH_PRICING_STARTED,
  FETCH_PRICING_SUCCESS,
  PricingActionTypes,
} from '../actionTypes/pricingActionTypes';
import {
  AUTH_STATE_CHANGE,
  FETCH_API_INFO_FAILED,
  FETCH_API_INFO_STARTED,
  FETCH_API_INFO_SUCCESS,
  FETCH_BILLING_INFO_FAILURE,
  FETCH_BILLING_INFO_STARTED,
  FETCH_BILLING_INFO_SUCCESS,
  FETCH_CREDIT_INFO_FAILURE,
  FETCH_CREDIT_INFO_STARTED,
  FETCH_CREDIT_INFO_SUCCESS,
  FETCH_INVOICE_HISTORY_FAILURE,
  FETCH_INVOICE_HISTORY_STARTED,
  FETCH_INVOICE_HISTORY_SUCCESS,
  FETCH_ORGANIZATION_DETAILS_FAILURE,
  FETCH_ORGANIZATION_DETAILS_STARTED,
  FETCH_ORGANIZATION_DETAILS_SUCCESS,
  FETCH_ORGANIZATION_USERS_DETAILS_FAILURE,
  FETCH_ORGANIZATION_USERS_DETAILS_STARTED,
  FETCH_ORGANIZATION_USERS_DETAILS_SUCCESS,
  FETCH_PAYMENT_INFO_FAILURE,
  FETCH_PAYMENT_INFO_STARTED,
  FETCH_PAYMENT_INFO_SUCCESS,
  FETCH_SPECIALIZED_MODEL_USAGE_INFO_FAILURE,
  FETCH_SPECIALIZED_MODEL_USAGE_INFO_STARTED,
  FETCH_SPECIALIZED_MODEL_USAGE_INFO_SUCCESS,
  FETCH_TRAIN_MODELS_USAGE_FAILURE,
  FETCH_TRAIN_MODELS_USAGE_STARTED,
  FETCH_TRAIN_MODELS_USAGE_SUCCESS,
  FETCH_USAGE_INFO_FAILURE,
  FETCH_USAGE_INFO_STARTED,
  FETCH_USAGE_INFO_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_RETRY_FAILURE,
  FETCH_USER_DETAILS_RETRY_SUCCESS,
  FETCH_USER_DETAILS_STARTED,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_WITH_INVITE_SUCCESS,
  PASSWORD_RESET_FAILED,
  PASSWORD_RESET_STARTED,
  PASSWORD_RESET_SUCCESS,
  SET_USER,
  SignInMethod,
  UserActionTypes,
} from '../actionTypes/userActionTypes';
import { RootState } from '../store';
import { WebRoutes } from '../../bootstrap/Web.routes';

export const getRequestIdHeader = () => {
  return {
    'X-Request-Id': 'legacy-' + guid4(),
  };
};

export function getPresets() {
  return async (dispatch: Dispatch<PresetActionTypes>) => {
    dispatch({ type: GET_PRESETS_STARTED });
    try {
      const start = new Date();
      const response = await api.presets.getAll();

      if (response.isSuccess) {
        const payload = Object.values(response.data) as CustomPreset[];
        return dispatch({
          type: GET_PRESETS_SUCCESS,
          payload,
          requestDuration: new Date().getTime() - start.getTime(),
        });
      }
      errorHandler.report('get presets failed');
      return dispatch({
        type: GET_PRESETS_FAILED,
        reason: (response.data as ResponseError).detail || 'UNKNOWN_ERROR',
      });
    } catch (err: unknown) {
      errorHandler.report(err);
      const reason = err instanceof Error ? err.message : 'UNKNOWN_ERROR';
      return dispatch({
        type: GET_PRESETS_FAILED,
        reason,
      });
    }
  };
}

export async function fetchPrices(dispatch: Dispatch<PricingActionTypes>) {
  dispatch({ type: FETCH_PRICING_STARTED });
  const response = await api.pricing.getAll();

  if (response.isSuccess) {
    const pricingInfo = toCamelCase(response.data) as PricingInfo[];
    dispatch({
      type: FETCH_PRICING_SUCCESS,
      pricingInfo: { ...pricingInfo[0] },
    });
  } else {
    errorHandler.report(`Refresh user details failed!, message: ${response.statusText}`);
    dispatch({
      type: FETCH_PRICING_FAILED,
      error: response.statusText,
    });
  }
}

export async function fetchUserDetails(
  dispatch: Dispatch<UserActionTypes>,
  firebaseToken: string,
  signInType: AuthenticationPageType,
  signInMethod: SignInMethod | null,
  inviteId: string | null,
  gclid: string | null,
  gbraid: string | null,
  wbraid: string | null
) {
  dispatch({ type: FETCH_USER_DETAILS_STARTED, signInType, signInMethod });

  const response = await api.user.get(inviteId);

  if (response.isSuccess) {
    const payload = response.data as UserEndpointResponse;
    if (inviteId) {
      dispatch({
        type: FETCH_USER_DETAILS_WITH_INVITE_SUCCESS,
        payload: { newMemberUserId: payload.userId, organizationId: payload.organizationId },
      });
    }
    return dispatch({
      type: FETCH_USER_DETAILS_SUCCESS,
      payload,
      signInType,
      signInMethod,
      gclid,
      gbraid,
      wbraid,
    });
  }
  errorHandler.report(
    `fetch user details failed!, message: ${response.statusText}, firebaseToken: ${
      firebaseToken ? firebaseToken.length : '00'
    }, signInType:${signInType || 'null'}, signInMethod: ${
      signInMethod ? signInMethod.toString() : 'null'
    }, inviteId:${inviteId || 'null'}`
  );
  return dispatch({
    type: FETCH_USER_DETAILS_FAILURE,
    error: response.statusText,
    signInType,
    signInMethod,
  });
}

export function refreshUserDetails() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    const response = await api.user.get();

    if (response.isSuccess) {
      const payload = response.data as UserEndpointResponse;
      dispatch({ type: SET_USER, payload });
      dispatch({
        type: FETCH_USER_DETAILS_RETRY_SUCCESS,
        payload,
      });
      dispatch({ type: AUTH_STATE_CHANGE });
    } else {
      errorHandler.report(`Refresh user details failed!, message: ${response.statusText}`);
      dispatch({
        type: FETCH_USER_DETAILS_RETRY_FAILURE,
        error: response.statusText,
      });
    }
  };
}

export function fetchOrganizationDetails() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_ORGANIZATION_DETAILS_STARTED });
    const response = await api.organization.get();

    if (response.isSuccess) {
      const payload = response.data as OrganizationDetailsResponse;
      dispatch({
        type: FETCH_ORGANIZATION_DETAILS_SUCCESS,
        payload,
      });
    } else {
      errorHandler.report(`Fetch organization details failed!, message: ${response.statusText}`);
      dispatch({
        type: FETCH_ORGANIZATION_DETAILS_FAILURE,
        error: response.statusText,
      });
    }
  };
}

export function fetchOrganizationDetailsFromInvite(inviteId: string) {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_ORGANIZATION_DETAILS_STARTED });
    const response = await api.organization.getByInvite(inviteId);

    if (response.isSuccess) {
      const payload = response.data as OrganizationDetailsResponse;
      dispatch({
        type: FETCH_ORGANIZATION_DETAILS_SUCCESS,
        payload,
      });
    } else {
      errorHandler.report(`Fetch organization details failed!, message: ${response.statusText}`);
      dispatch({
        type: FETCH_ORGANIZATION_DETAILS_FAILURE,
        error: response.statusText,
      });
    }
  };
}

export function fetchOrganizationUsersDetails() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_ORGANIZATION_USERS_DETAILS_STARTED });
    const response = await api.users.getAll();

    if (response.isSuccess) {
      const payload = response.data as OrganizationUserDetailsListElement[];
      dispatch({
        type: FETCH_ORGANIZATION_USERS_DETAILS_SUCCESS,
        payload,
      });
    } else {
      errorHandler.report(
        `Fetch organization users details failed!, message: ${response.statusText}`
      );
      dispatch({
        type: FETCH_ORGANIZATION_USERS_DETAILS_FAILURE,
        error: response.statusText,
      });
    }
  };
}

export function fetchDatasets() {
  return async function inner(dispatch: Dispatch<DatasetsActionTypes>) {
    dispatch({ type: FETCH_DATASETS_STARTED });
    const response = await api.fts.getAll();

    if (response.isSuccess) {
      const payload = response.data as DatasetEndpointListElement[];
      return dispatch({
        type: FETCH_DATASETS_SUCCESS,
        payload,
      });
    }
    errorHandler.report(`fetch datasets failed!, message: ${response.statusText}`);
    return dispatch({
      type: FETCH_DATASETS_FAILURE,
      error: await response.data,
    });
  };
}

export function fetchCustomModels(shouldApplyStateChange: boolean) {
  return async function inner(dispatch: Dispatch<CustomModelsActionTypes>) {
    dispatch({ type: FETCH_MODELS_STARTED });
    const response = await api.models.getAll();

    if (response.isSuccess) {
      const payload = response.data as ModelEndpointListElement[];
      return dispatch({
        type: FETCH_MODELS_SUCCESS,
        shouldApplyStateChange,
        payload,
      });
    }
    errorHandler.report(`fetch models failed!, message: ${response.statusText}`);
    return dispatch({
      type: FETCH_MODELS_FAILURE,
      error: JSON.stringify(response.data),
    });
  };
}

export function buildCompletionRequestParams(controllerParams: ControllerParams, prompt: string) {
  const selectedModel = MODELS_VALUES_BY_DISPLAY[controllerParams.model] || controllerParams.model;

  const completionRequestParams: CompletionRequestParams = {
    prompt,
    model: selectedModel,
    temperature: controllerParams.temperature,
    topP: controllerParams.topP,
    epoch: controllerParams.epoch,
    maxTokens: controllerParams.maxTokens,
    stopSequences: controllerParams.stopSequences,
    numStopSequences: controllerParams.stopSequences?.length,
    promptLength: prompt.length,
    numResults: 1,
    topKReturn: controllerParams.showAlternativeTokens ? 10 : 0,
    frequencyPenalty: controllerParams.frequencyPenalty.scale,
    presencePenalty: controllerParams.presencePenalty.scale,
    countPenalty: controllerParams.countPenalty.scale,
  };
  return completionRequestParams;
}

export function buildRewriteRequestParams(
  rewritePresetParams: RewritePresetParams,
  prompt: string
) {
  return {
    prompt,
    intent: rewritePresetParams.intent,
    spanStart: rewritePresetParams.spanStart,
    spanEnd: rewritePresetParams.spanEnd,
  };
}

export function fetchApiKeyInfo() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_API_INFO_STARTED });
    // try {
    //   const response = await api.user.getApiKey();

    //   if (response.isSuccess) {
    //     const payload = response.data as ApiKeyEndpointResponse;
    //     dispatch({ type: FETCH_API_INFO_SUCCESS, payload });
    //   } else {
    //     errorHandler.report(`api info fetch failed: ${response.statusText}`);
    //     dispatch({ type: FETCH_API_INFO_FAILED });
    //   }
    // } catch (err: any) {
    //   errorHandler.report(err);
    //   dispatch({ type: FETCH_API_INFO_FAILED });
    // }
  };
}

export function calculateCustomModelCost(epochs: number, sizeBytes: number, modelType: string) {
  return async function inner(
    dispatch: Dispatch<CustomModelsActionTypes>,
    getState: () => RootState
  ) {
    dispatch({ type: FETCH_MODEL_COST_STARTED });
    try {
      const response = await api.models.calculateCost({
        epochs,
        sizeBytes,
        modelType,
      });

      if (response.isSuccess) {
        type ModelCostResponse = { cost: number };
        const payload = response.data as ModelCostResponse;
        dispatch({
          type: MODEL_COST_CHANGED,
          payload: { cost: payload.cost, numOfEpochs: epochs, modelType },
        });

        dispatch({ type: FETCH_MODEL_COST_SUCCESS, cost: payload.cost, modelEpochs: epochs });
      } else {
        errorHandler.report(`calculateCustomModelCost failed: ${response.statusText}`);
        dispatch({ type: FETCH_MODEL_COST_FAILURE });
      }
    } catch (err: any) {
      errorHandler.report(err);
      dispatch({ type: FETCH_MODEL_COST_FAILURE });
    }
  };
}

export function getDatasetPreview(datasetId: string, numExamples: number) {
  return async function inner(dispatch: Dispatch<DatasetsActionTypes>) {
    try {
      dispatch({ type: FETCH_DATASETS_PREVIEW_STARTED });
      const response = await api.fts.preview(datasetId, numExamples);

      if (response.isSuccess) {
        const payload = response.data as DatasetPreview[] | undefined;
        dispatch({ type: FETCH_DATASETS_PREVIEW_SUCCESS, payload });
      } else {
        errorHandler.report(`getDatasetPreview failed: ${response.statusText}`);
        dispatch({ type: FETCH_DATASETS_PREVIEW_FAILURE });
      }
    } catch (err: any) {
      errorHandler.report(err);
      dispatch({ type: FETCH_DATASETS_PREVIEW_FAILURE });
    }
  };
}

const delay = (millis: number) => new Promise((res) => setTimeout(res, millis));

export function getPromptShare(promptShareId: string) {
  return async function inner(dispatch: Dispatch<PlaygroundActionTypes>) {
    await delay(2000);

    dispatch({ type: GET_PROMPT_SHARE_STARTED, promptShareId });
    try {
      const start = new Date();
      const response = await api.completion.getShare(promptShareId);

      if (response.isSuccess) {
        type PromptShareGetResponse = {
          text: string;
          apiType: APIType;
          params: CompletionRequestParams;
        };
        const payload = response.data as PromptShareGetResponse;
        return dispatch({
          type: GET_PROMPT_SHARE_SUCCESS,
          promptShareId,
          text: payload.text,
          params: payload.params,
          apiType: payload.apiType,
          requestDuration: new Date().getTime() - start.getTime(),
        });
      }
      errorHandler.report('get prompt share failed');
      return dispatch({
        type: GET_PROMPT_SHARE_FAILED,
        promptShareId,
        reason: JSON.stringify(response.data) || 'UNKNOWN_ERROR',
      });
    } catch (err: any) {
      errorHandler.report(err);
      const message = err instanceof Error ? err.message : 'UNKNOWN_ERROR';
      return dispatch({
        type: GET_PROMPT_SHARE_FAILED,
        promptShareId,
        reason: message,
      });
    }
  };
}

export function fetchUsageInfo() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_USAGE_INFO_STARTED });
    try {
      const response = await api.usage.get();

      if (response.isSuccess) {
        const payload = response.data as UsageEndpointListElement[];
        dispatch({ type: FETCH_USAGE_INFO_SUCCESS, payload });
      } else {
        errorHandler.report('Fetch usage info failed!');
        dispatch({ type: FETCH_USAGE_INFO_FAILURE, error: Error(JSON.stringify(response.data)) });
      }
    } catch (err: any) {
      errorHandler.report(err);
      dispatch({ type: FETCH_USAGE_INFO_FAILURE, error: err as Error });
    }
  };
}

export function fetchSpecializedModelUsageInfo() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_SPECIALIZED_MODEL_USAGE_INFO_STARTED });
    try {
      const response = await api.usage.getLego();

      if (response.isSuccess) {
        const responseJson: { [s: string]: SpecializedModelUsageEndpointResponse } =
          response.data as { [s: string]: SpecializedModelUsageEndpointResponse };
        const payload: SpecializedModelUsageEndpointResponse[] = Object.values(responseJson);
        dispatch({ type: FETCH_SPECIALIZED_MODEL_USAGE_INFO_SUCCESS, payload });
      } else {
        errorHandler.report('Fetch specialized models usage info failed!');
        dispatch({
          type: FETCH_SPECIALIZED_MODEL_USAGE_INFO_FAILURE,
          error: Error(JSON.stringify(response.data)),
        });
      }
    } catch (err: any) {
      errorHandler.report(err);
      dispatch({ type: FETCH_SPECIALIZED_MODEL_USAGE_INFO_FAILURE, error: err as Error });
    }
  };
}

export function fetchCreditInfo() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_CREDIT_INFO_STARTED });
    try {
      const response = await api.usage.getCredits();

      if (response.isSuccess) {
        const payload = response.data as CreditInfoEndpointResponse;
        dispatch({ type: FETCH_CREDIT_INFO_SUCCESS, payload });
      } else {
        errorHandler.report('Fetch credit info failed!');
        dispatch({ type: FETCH_CREDIT_INFO_FAILURE, error: Error(JSON.stringify(response.data)) });
      }
    } catch (err: any) {
      errorHandler.report(err);
      dispatch({ type: FETCH_CREDIT_INFO_FAILURE, error: err as Error });
    }
  };
}

export function fetchTrainModelsUsageInfo() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_TRAIN_MODELS_USAGE_STARTED });
    try {
      const response = await api.usage.getTraining();

      if (response.isSuccess) {
        const payload = response.data as TrainModelsUsageEndpointListElement[];
        dispatch({ type: FETCH_TRAIN_MODELS_USAGE_SUCCESS, payload });
      } else {
        errorHandler.report('Fetch train models usage info failed!');
        dispatch({ type: FETCH_TRAIN_MODELS_USAGE_FAILURE, error: JSON.stringify(response.data) });
      }
    } catch (err: any) {
      errorHandler.report(err);
      const message = err instanceof Error ? err.message : 'unknown';
      dispatch({ type: FETCH_TRAIN_MODELS_USAGE_FAILURE, error: message });
    }
  };
}

export function fetchPaymentInfo() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_PAYMENT_INFO_STARTED });
    try {
      const response = await api.billing.getPaymentInfo();

      if (response.isSuccess) {
        const payload = response.data as PaymentMethodEndpointResponse;
        dispatch({ type: FETCH_PAYMENT_INFO_SUCCESS, payload });
      } else {
        errorHandler.report('Fetch payment method failed!');
        dispatch({ type: FETCH_PAYMENT_INFO_FAILURE, error: JSON.stringify(response.data) });
      }
    } catch (err: any) {
      errorHandler.report(err);
      const message = err instanceof Error ? err.message : 'unknown';
      dispatch({ type: FETCH_PAYMENT_INFO_FAILURE, error: message });
    }
  };
}

export function fetchBillingInfo() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_BILLING_INFO_STARTED });
    try {
      const response = await api.billing.getBillingInfo();

      if (response.isSuccess) {
        const billings = toCamelCase(response.data) as BillingInfoEndpointResponse;

        dispatch({ type: FETCH_BILLING_INFO_SUCCESS, payload: billings });
      } else {
        errorHandler.report('Fetch billing info failed!');
        dispatch({ type: FETCH_BILLING_INFO_FAILURE, error: JSON.stringify(response.data) });
      }
    } catch (err: any) {
      errorHandler.report(err);
      const message = err instanceof Error ? err.message : 'unknown';
      dispatch({ type: FETCH_BILLING_INFO_FAILURE, error: message });
    }
  };
}

export function fetchInvoiceHistory() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_INVOICE_HISTORY_STARTED });
    try {
      const response = await api.billing.getInvoices();

      if (response.isSuccess) {
        const payload = response.data as InvoiceInfo[];
        dispatch({ type: FETCH_INVOICE_HISTORY_SUCCESS, invoiceHistory: payload });
      } else {
        errorHandler.report('Fetch billing info failed!');
        dispatch({ type: FETCH_INVOICE_HISTORY_FAILURE, error: JSON.stringify(response.data) });
      }
    } catch (err: any) {
      errorHandler.report(err);
      const message = err instanceof Error ? err.message : 'unknown';
      dispatch({ type: FETCH_INVOICE_HISTORY_FAILURE, error: message });
    }
  };
}

export function getDatasetDownloadUrl(datasetId: string, datasetName: string) {
  return async function inner(dispatch: Dispatch<DatasetsActionTypes>) {
    dispatch({ type: FETCH_DATASET_URL_STARTED });
    const response = await api.fts.getDownloadUrl(datasetId, datasetName);

    if (response.isSuccess) {
      type DatasetUrlGetResponse = { dataset_url: string };
      const payload = response.data as DatasetUrlGetResponse;
      const datasetType = (function datasetTypeFromName() {
        switch (datasetName) {
          case 'dev.jsonl':
            return DatasetsType.VALIDATION;
          case 'train.jsonl':
            return DatasetsType.TRAINING;
          default:
            return DatasetsType.FULL_DATASET;
        }
      })();

      return dispatch({
        type: FETCH_DATASET_URL_SUCCESS,
        url: payload.dataset_url,
        datasetType,
        datasetId,
      });
    }
    errorHandler.report(`fetch dataset url failed!, message: ${response.statusText}`);
    return dispatch({
      type: FETCH_DATASET_URL_FAILURE,
      error: JSON.stringify(response.data),
    });
  };
}

export function downloadExampleDatasetUrl() {
  return async function inner(dispatch: Dispatch<DatasetsActionTypes>) {
    dispatch({ type: FETCH_EXAMPLE_DATASET_URL_STARTED });
    const response = await api.fts.downloadExampleSetUrl();

    if (response.isSuccess) {
      const payload = response.data as { example_dataset_url: string };

      return dispatch({
        type: FETCH_EXAMPLE_DATASET_URL_SUCCESS,
        url: payload.example_dataset_url,
      });
    }
    errorHandler.report(`fetch example dataset url failed!, message: ${response.statusText}`);
    return dispatch({
      type: FETCH_EXAMPLE_DATASET_URL_FAILURE,
      error: JSON.stringify(response.data),
    });
  };
}
