import styled from 'styled-components';
import { ButtonMenu, tokens } from '@ai21/studio-base-ui';
import { IconButton } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;

  &.selected {
  }
`;

export const Header = styled.div``;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const Settings = styled(IconButton)`
  margin-left: 8px;
  height: 36px;
`;

export const Duration = styled.div`
  padding-left: 15px;
  position: absolute;
  bottom: 36px;
  right: 10px;
  opacity: 0.3;
`;

export const Menu = styled(ButtonMenu)`
  .MuiButton-root {
    border: none;
    padding: 6px;
    height: 36px;
    margin-left: 8px;
  }
`;

export const AlertWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
`;
