import { withStyles, styled } from '@ai21/studio-base-ui';
import { Table as MuiTable } from '@ai21/studio-base-ui';

import { Theme, Tooltip } from '@ai21/studio-base-ui';

export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#000',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
    fontSize: 14,
    padding: 8,
    fontWeight: 400,
    fontFamily: 'Inter',
  },
}))(Tooltip);

export const Table = styled(MuiTable)`
  tr {
    &:last-child {
      background: rgba(46, 46, 46, 0.04);
      height: 32px;
      box-shadow: inset 0px 1px 0px #e5e5e5;

      td {
        div {
          color: 'rgba(0, 0, 0, 0.38);';
        }
      }
    }
  }
`;
