import { PincodeInput } from '@ai21/studio-base-ui';
import { Message, Wrapper } from './DemosPinCodePage.style';
import { useRef } from 'react';

export type DemosPageProps = {
  onComplete: (value: string, index: number) => void;
  message?: string;
};

export function DemosPinCodePage(props: DemosPageProps) {
  const { onComplete, message: text } = props;
  const refInput = useRef<any>(null);

  if (text) {
    refInput.current.clear();
  }

  return (
    <Wrapper>
      Please enter pin code
      <PincodeInput
        ref={refInput}
        length={4}
        initialValue=''
        type='numeric'
        inputMode='number'
        style={{ padding: '12px' }}
        inputFocusStyle={{ borderColor: 'blue' }}
        onComplete={onComplete}
        autoSelect={true}
        focus={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
      <Message>{text}</Message>
    </Wrapper>
  );
}
