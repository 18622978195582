import { Dispatch, Store } from 'redux';
import { Action } from 'redux-store-generator';
import { connectedAll } from '../connected/connected.all';

export const middlewareConnected = (store: Store) => (next: any) => async (action: Action) => {
  const outcome = await connectedAll(action);

  const { shouldReachStore, nextAction } = outcome;

  if (shouldReachStore) {
    next(action);
  }

  if (nextAction) {
    // example: after ADD_PRESET => SET_PRESET
    store.dispatch(nextAction);
  }

  return outcome.response;
};
