import styled from 'styled-components';
import { Button, Typography, tokens } from '@ai21/studio-ui';

export const Wrapper = styled.div`
  flex: 1;

  button {
  }
`;

export const Action = styled(Button)`
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 6px 10px;

  svg {
    stroke-width: 1;
  }

  &.primary {
    color: ${tokens.neutral.brightest};
    background-color: ${tokens.primitives.grey[900]};

    &:hover {
      background-color: ${tokens.primitives.grey[1000]};
    }
  }
`;

export const Header = styled(Typography)`
  color: ${tokens.text.tertiary};
  padding-bottom: 12px;
  display: block;
  font-size: 12px;
`;
export const DisclaimerHeader = styled(Typography)`
  color: ${tokens.text.primary};
  display: block;
`;

export const ActionsSection = styled.div`
  margin-bottom: 24px;
`;

export const Disclaimer = styled(Typography)`
  border-radius: ${tokens.primitives.radius.large}px;
  background: ${tokens.primitives.beige[50]};
  padding: ${tokens.primitives.radius.large}px;
  gap: 10px;
  color: ${tokens.text.secondary};
  margin-bottom: 16px;

  p {
    flex-wrap: wrap;
    margin-top: 12px;
    margin-bottom: 0px;
    color: ${tokens.text.secondary};
  }

  span {
    margin-bottom: 12px;
  }

  a {
    color: ${tokens.text.secondary};
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid;

    &:hover {
      color: ${tokens.primitives.grey[900]};
      text-decoration-color: ${tokens.primitives.grey[900]};
    }

    svg {
      height: 14px;
      width: 14px;
      margin-bottom: -2px;
    }
  }

  button {
    background-color: ${tokens.primitives.beige[50]};

    &:hover {
      background-color: ${tokens.primitives.beige[100]};
    }
  }
`;
