import { createSelector } from 'reselect';
import { sortBy } from 'shared-base';
import { IStudioStore } from '../types';
import { sortByTwo } from '../utils/sort';
import * as base from './selectors.base';
import * as raw from './selectors.raw';
import { isFeatureFlagEnabled } from '../utils/userUtils';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

type IOption = any;

export const $demoFlavours = createSelector($i, (): IOption[] => {
  return [
    {
      id: 'contextual_answers',
      text: 'Contextual answers',
    },
  ];
});

export const $evaluationMethods = createSelector($i, (): IOption[] => {
  return [
    {
      id: 'qualitative',
      text: 'Qualitative (Great, OK, Bad)',
    },
  ];
});

export const $evaluationGoals = createSelector($i, (): IOption[] => {
  return [
    {
      id: 'craftingFts',
      text: 'Crafting a fine-tuning set',
    },
    {
      id: 'evaluationPerformance',
      text: 'Evaluating performance',
    },
  ];
});

export const $models = createSelector($i, (): IOption[] => {
  return [
    {
      id: 'jamba-1.5-large',
      text: 'jamba-1.5-large',
    },
    {
      id: 'jamba-1.5-mini',
      text: 'jamba-1.5-mini',
    },
    {
      id: 'jamba-instruct-preview',
      text: 'jamba-instruct-preview',
    },
    {
      id: 'j2-light',
      text: 'j2-light',
    },
    {
      id: 'j2-mid',
      text: 'j2-mid',
    },
    {
      id: 'j2-ultra',
      text: 'j2-ultra',
    },
  ];
});

export const $apeFlavours = createSelector($i, (): IOption[] => {
  return [
    {
      id: 'writePrompt',
      text: 'Write a new prompt',
    },
  ];
});

export const $pasMain = createSelector(raw.$rawPas, (pas): IOption[] => {
  return Object.values(pas)
    .filter((pas) => !pas.isDeleted)
    .map((pa) => ({
      id: pa.id,
      text: pa.name,
    }))
    .sort(sortBy('text'));
});

export const $pasImprove = createSelector(raw.$rawPas, (pas): IOption[] => {
  const output = Object.values(pas)
    .filter((pas) => !pas.isDeleted && (pas.labels ?? []).includes('improve'))
    .map((pa) => ({
      id: pa.id,
      text: pa.name,
    }))
    .sort(sortBy('text'));

  return [
    {
      id: '',
      text: 'None',
    },
    ...output,
  ];
});

export const $evaluationJobs = createSelector(raw.$rawEvaluationJobs, (jobs): IOption[] => {
  return Object.values(jobs)
    .filter((job) => !job.isDeleted)
    .map((job) => ({
      id: job.id,
      text: job.name,
    }))
    .sort(sortBy('text'));
});

export const $collectionJobs = createSelector(raw.$rawCollectionJobs, (jobs): IOption[] => {
  return Object.values(jobs)
    .filter((job) => !job.isDeleted)
    .map((job) => ({
      id: job.id,
      text: job.name,
    }))
    .sort(sortBy('text'));
});

export const $playOutputs = createSelector($i, (): IOption[] => {
  return [
    {
      id: 'output1',
      text: 'Output 1',
    },
    {
      id: 'output2',
      text: 'Output 2',
    },
    {
      id: 'output3',
      text: 'Output 3',
    },
    {
      id: 'output4',
      text: 'Output 4',
    },
  ];
});

export const $playFoundation = createSelector($i, (): IOption[] => {
  return [
    {
      id: 'jamba-instruct-preview',
      text: 'jamba-instruct-preview',
      groupId: 'Foundation',
    },
    {
      id: 'jamba-1.5-large',
      text: 'jamba-1.5-large',
      groupId: 'Foundation',
    },
    {
      id: 'jamba-1.5-mini',
      text: 'jamba-1.5-mini',
      groupId: 'Foundation',
    },
    {
      id: 'j2-ultra',
      text: 'j2-ultra',
      groupId: 'Foundation',
    },
    {
      id: 'j2-mid',
      text: 'j2-mid',
      groupId: 'Foundation',
    },
    {
      id: 'j2-light',
      text: 'j2-light',
      groupId: 'Foundation',
    },
  ];
});

export const $playModelsAll = createSelector(
  $playFoundation,
  raw.$rawModels,
  raw.$rawUser,
  (foundationModels, models, user): IOption[] => {
    const isJambaChatEnabled = isFeatureFlagEnabled(user, 'isJambaChatEnabled');
    const customModels = Object.values(models)
      .filter(
        (model) =>
          ![
            'j2-light',
            'j2-mid',
            'j2-ultra',
            'jamba-instruct-preview',
            'jamba-1.5-large',
            'jamba-1.5-mini',
          ].includes(model.id) && !['chat'].includes(model.layoutId || '')
      )
      .map((model) => {
        return {
          id: model.id,
          text: model.name,
          groupId: 'Custom',
        };
      })
      .sort(sortBy('text'));

    const filterdModels = [...foundationModels, ...customModels].filter(
      (model) =>
        isJambaChatEnabled ||
        (model.id !== 'jamba-instruct-preview' &&
          model.id !== 'jamba-1.5-large' &&
          model.id !== 'jamba-1.5-mini')
    );

    const latestModels = filterdModels.filter((model) =>
      ['jamba-1.5-large', 'jamba-1.5-mini'].includes(model.id)
    );
    const otherModels = filterdModels.filter(
      (model) => !['jamba-1.5-large', 'jamba-1.5-mini'].includes(model.id)
    );

    return [...latestModels, ...otherModels];
  }
);

export const $playPresets = createSelector(raw.$rawPresets, (presets): IOption[] => {
  const newPreset = Object.values(presets)
    .filter((preset) => preset.isHidden)
    .map((preset) => ({
      id: preset.id,
      text: preset.name,
      groupId: preset.primaryLabel,
    }));

  const custom = Object.values(presets)
    .filter((preset) => preset.isUserPreset)
    .map((preset) => ({
      id: preset.id,
      text: preset.name,
      groupId: 'My Presets',
    }))
    .sort(sortBy('text'));

  const other = Object.values(presets)
    .filter((preset) => !preset.isUserPreset && !preset.isHidden)
    .map((preset) => ({
      id: preset.id,
      text: preset.name,
      groupId: preset.primaryLabel,
    }))
    .sort(sortByTwo('groupId', 'text'));

  return [...newPreset, ...custom, ...other];
});

export const $playEpochs = createSelector(base.$currentEpochs, (epochs): IOption[] => {
  const output: IOption[] = [
    {
      id: '0',
      text: 'Default',
    },
  ];

  Object.keys(epochs).forEach((id) => {
    const epoch = epochs[id];
    const { validationLoss, isDefault, isLowest } = epoch;

    let text = `${id} (${validationLoss.toFixed(2)} val loss)`;

    if (isDefault) {
      text += ' default';
    }

    if (isLowest) {
      text += ' lowest';
    }

    output.push({
      id,
      text,
    });
  });

  return output;
});

export const $playLegoSummarizationTypes = createSelector($i, (): IOption[] => {
  return [
    {
      id: 'text',
      text: 'Free text',
    },
    {
      id: 'url',
      text: 'URL link',
    },
  ];
});

export const $playLegoParaphraseTypes = createSelector($i, (): IOption[] => {
  return [
    {
      id: 'general',
      text: 'General',
    },
    {
      id: 'casual',
      text: 'Casual',
    },
    {
      id: 'formal',
      text: 'Formal',
    },
    {
      id: 'long',
      text: 'Long',
    },
    {
      id: 'short',
      text: 'Short',
    },
  ];
});

export const $playLegoImprovementsTypes = createSelector($i, (): IOption[] => {
  return [
    {
      id: 'fluency',
      text: 'fluency',
    },
    {
      id: 'vocabulary/specificity',
      text: 'vocabulary/specificity',
    },
    {
      id: 'vocabulary/variety',
      text: 'vocabulary/variety',
    },
    {
      id: 'clarity/short-sentences',
      text: 'clarity/short-sentences',
    },
    {
      id: 'clarity/conciseness',
      text: 'clarity/conciseness',
    },
  ];
});

export const $allOptions = createSelector(
  $evaluationGoals,
  $evaluationMethods,
  $models,
  $apeFlavours,
  $demoFlavours,
  $pasMain,
  $pasImprove,
  $evaluationJobs,
  $collectionJobs,
  $playEpochs,
  $playModelsAll,
  $playPresets,
  $playOutputs,
  $playLegoSummarizationTypes,
  $playLegoParaphraseTypes,
  $playLegoImprovementsTypes,
  (
    goals,
    methods,
    models,
    apeFlavours,
    demoFlavours,
    pasMain,
    pasImprove,
    evaluationJobs,
    collectionJobs,
    playEpochs,
    playModelsAll,
    playPresets,
    playOutputs,
    playLegoSummarizationTypes,
    playLegoParaphraseTypes,
    playLegoImprovementsTypes
  ) => {
    return {
      $evaluationGoals: goals,
      $evaluationMethods: methods,
      $demoFlavours: demoFlavours,
      $models: models,
      $apeFlavours: apeFlavours,
      $pasMain: pasMain,
      $pasImprove: pasImprove,
      $evaluationJobs: evaluationJobs,
      $collectionJobs: collectionJobs,
      $playEpochs: playEpochs,
      $playModelsAll: playModelsAll,
      $playPresets: playPresets,
      $playOutputs: playOutputs,
      $playLegoSummarizationTypes: playLegoSummarizationTypes,
      $playLegoParaphraseTypes: playLegoParaphraseTypes,
      $playLegoImprovementsTypes: playLegoImprovementsTypes,
    };
  }
);
