import {
  DatasetEndpointListElement,
  DatasetPreview,
  SelectedColumns,
} from '../../data-types/Dataset';

export const FETCH_DATASETS_STARTED = 'FETCH_DATASETS_STARTED';
export const FETCH_DATASETS_SUCCESS = 'FETCH_DATASETS_SUCCESS';
export const FETCH_DATASETS_FAILURE = 'FETCH_DATASETS_FAILURE';
export const FETCH_DATASET_URL_STARTED = 'FETCH_DATASET_URL_STARTED';
export const DATASET_COPY_ID = 'DATASET_COPY_ID';
export const FETCH_DATASET_URL_SUCCESS = 'FETCH_DATASET_URL_SUCCESS';
export const FETCH_DATASET_URL_FAILURE = 'FETCH_DATASET_URL_FAILURE';
export const FETCH_EXAMPLE_DATASET_URL_STARTED = 'FETCH_EXAMPLE_DATASET_URL_STARTED';
export const FETCH_EXAMPLE_DATASET_URL_SUCCESS = 'FETCH_EXAMPLE_DATASET_URL_SUCCESS';
export const FETCH_EXAMPLE_DATASET_URL_FAILURE = 'FETCH_EXAMPLE_DATASET_URL_FAILURE';
export const RESET_EXAMPLE_DATASET_URL = 'RESET_EXAMPLE_DATASET_URL';
export const FETCH_DATASETS_PREVIEW_STARTED = 'FETCH_DATASETS_PREVIEW_STARTED';
export const FETCH_DATASETS_PREVIEW_SUCCESS = 'FETCH_DATASETS_PREVIEW_SUCCESS';
export const FETCH_DATASETS_PREVIEW_FAILURE = 'FETCH_DATASETS_PREVIEW_FAILURE';
export const POST_DATASET_STARTED = 'POST_DATASET_STARTED';
export const ADD_DATASET_FILE = 'ADD_DATASET_FILE';
export const POST_DATASET_SUCCESS = 'POST_DATASET_SUCCESS';
export const POST_DATASET_FAILURE = 'POST_DATASET_FAILURE';
export const RESET_DATASETS_PAGE_STATE = 'RESET_DATASETS_PAGE_STATE';
export const TECHNICAL_DOCUMENTATION_PRESSED = 'TECHNICAL_DOCUMENTATION_PRESSED';
export const POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE =
  'POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE';
export const UPLOAD_DATASET = 'UPLOAD_DATASET';
export const DATASET_EXPORT_DATA_PRESSED = 'DATASET_EXPORT_DATA_PRESSED';
export const PRESET_EXPORT_DATA_PRESSED = 'PRESET_EXPORT_DATA_PRESSED';
export const CUSTOM_MODELS_EXPORT_DATA_PRESSED = 'CUSTOM_MODELS_EXPORT_DATA_PRESSED';
export const DELETE_DATASET_PRESSED = 'DELETE_DATASET_PRESSED';
export const UPLOAD_DATASET_PRESSED = 'UPLOAD_DATASET_PRESSED';
export const DELETE_DATASET_STARTED = 'DELETE_DATASET_STARTED';
export const DELETE_DATASET_SUCCESS = 'DELETE_DATASET_SUCCESS';
export const DELETE_DATASET_FAILED = 'DELETE_DATASET_FAILED';
export const SHOW_DELETE_DATASET_DIALOG = 'SHOW_DELETE_DATASET_DIALOG';

export enum DatasetsType {
  TRAINING = 'training',
  VALIDATION = 'validation',
  FULL_DATASET = 'fullDataset',
}

export enum DatasetsUploadingErrorType {
  UNKNOWN_EXTENSION = 'UNKNOWN_EXTENSION',
  ILLEGAL_FORMAT = 'ILLEGAL_FORMAT',
  MIN_ROWS = 'DATASET_MIN_ROWS_ERROR',
  COLUMNS_NOT_SPECIFIED = 'COLUMNS_NOT_SPECIFIED',
  COLUMNS_NAME_NOT_EXIST = 'COLUMNS_NAME_NOT_EXIST',
  MULTIPLE_WHITESPACE = 'MULTIPLE_WHITESPACE_ERROR',
  GENERAL_EXCEPTION = 'GENERAL_EXCEPTION',
  TO_LONG_ROWS = 'TOO_LONG_ROWS',
  INVALID_DATASET_NAME = 'INVALID_DATASET_NAME',
  DUPLICATE_DATASET_NAME = 'DUPLICATE_DATASET_NAME',
  DATASET_MIN_ROWS_ERROR = 'DATASET_MIN_ROWS_ERROR',
  EMPTY_COMPLETION = 'EMPTY_COMPLETION',
}

export interface FetchDatasetPreviewStarted {
  type: typeof FETCH_DATASETS_PREVIEW_STARTED;
}

export interface FetchDatasetPreviewSuccess {
  type: typeof FETCH_DATASETS_PREVIEW_SUCCESS;
  payload: DatasetPreview[] | undefined;
}

export interface FetchDatasetPreviewFailure {
  type: typeof FETCH_DATASETS_PREVIEW_FAILURE;
}

export interface ResetDatasetsPageState {
  type: typeof RESET_DATASETS_PAGE_STATE;
}
export interface TechnicalDocumentationPressed {
  type: typeof TECHNICAL_DOCUMENTATION_PRESSED;
}

export interface UploadDataset {
  type: typeof UPLOAD_DATASET;
}

export interface GetDatasetsStarted {
  type: typeof FETCH_DATASETS_STARTED;
}

export interface GetDatasetsFailed {
  type: typeof FETCH_DATASETS_FAILURE;
  error: string;
}

export interface GetDatasetsSuccess {
  type: typeof FETCH_DATASETS_SUCCESS;
  payload: DatasetEndpointListElement[];
}

export interface FetchDatasetUrlStarted {
  type: typeof FETCH_DATASET_URL_STARTED;
}

export interface FetchDatasetUrlFailed {
  type: typeof FETCH_DATASET_URL_FAILURE;
  error: string;
}

export interface FetchDatasetUrlSuccess {
  type: typeof FETCH_DATASET_URL_SUCCESS;
  url: string;
  datasetType: DatasetsType;
  datasetId: string;
}
export interface DatasetCopyId {
  type: typeof DATASET_COPY_ID;
  datasetId: string;
}

export interface FetchExampleDatasetUrlStarted {
  type: typeof FETCH_EXAMPLE_DATASET_URL_STARTED;
}

export interface ResetExampleDatasetUrl {
  type: typeof RESET_EXAMPLE_DATASET_URL;
}

export interface FetchExampleDatasetUrlFailed {
  type: typeof FETCH_EXAMPLE_DATASET_URL_FAILURE;
  error: string;
}

export interface FetchExampleDatasetUrlSuccess {
  type: typeof FETCH_EXAMPLE_DATASET_URL_SUCCESS;
  url: string;
}

export interface PostDatasetStarted {
  type: typeof POST_DATASET_STARTED;
  datasetName: string;
  datasetFile: File;
}
export interface AddDatasetFile {
  type: typeof ADD_DATASET_FILE;
  datasetFile: File;
}

export interface PostDatasetFailed {
  type: typeof POST_DATASET_FAILURE;
  uploadDatasetError: DatasetsUploadingErrorType | undefined;
  approveWhitespaceCorrection: boolean | undefined;
  deleteLongRows: boolean | undefined;
  selectedColumns: SelectedColumns | undefined;
  uploadDatasetErrorDetails?: string | undefined;
}

export interface PostDatasetColumnsNotSpecifiedFailure {
  type: typeof POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE;
  uploadDatasetError: DatasetsUploadingErrorType | undefined;
  uploadDatasetColumnsName: string[] | undefined;
}

export interface PostDatasetSuccess {
  type: typeof POST_DATASET_SUCCESS;
  payload: DatasetEndpointListElement;
  approveWhitespaceCorrection: boolean | undefined;
  deleteLongRows: boolean | undefined;
  selectedColumns: SelectedColumns | undefined;
}

export interface DeleteDatasetStarted {
  type: typeof DELETE_DATASET_STARTED;
  name: string;
}

export interface DeleteDatasetFailed {
  type: typeof DELETE_DATASET_FAILED;
  name: string;
  reason: string;
}

export interface DeleteDatasetSuccess {
  type: typeof DELETE_DATASET_SUCCESS;
  datasetId: string;
  requestDuration: number;
}

export interface DatasetExportDataPressed {
  type: typeof DATASET_EXPORT_DATA_PRESSED;
  origin: string;
}
export interface PresetExportDataPressed {
  type: typeof PRESET_EXPORT_DATA_PRESSED;
  origin: string;
}
export interface CustomModelsExportDataPressed {
  type: typeof CUSTOM_MODELS_EXPORT_DATA_PRESSED;
  origin: string;
}
export interface DatasetDeletePressed {
  type: typeof DELETE_DATASET_PRESSED;
  datasetId: string;
}
export interface DatasetUploadPressed {
  type: typeof UPLOAD_DATASET_PRESSED;
}

export interface ShowDeleteDatasetDialog {
  type: typeof SHOW_DELETE_DATASET_DIALOG;
  showDeleteDatasetDialog: boolean;
}

export type DatasetsActionTypes =
  | GetDatasetsStarted
  | GetDatasetsSuccess
  | GetDatasetsFailed
  | ResetDatasetsPageState
  | PostDatasetStarted
  | PostDatasetSuccess
  | PostDatasetColumnsNotSpecifiedFailure
  | PostDatasetFailed
  | UploadDataset
  | FetchDatasetPreviewStarted
  | FetchDatasetPreviewSuccess
  | FetchDatasetPreviewFailure
  | FetchDatasetUrlFailed
  | FetchDatasetUrlStarted
  | FetchDatasetUrlSuccess
  | FetchExampleDatasetUrlStarted
  | FetchExampleDatasetUrlFailed
  | FetchExampleDatasetUrlSuccess
  | DeleteDatasetFailed
  | DeleteDatasetStarted
  | DeleteDatasetSuccess
  | ShowDeleteDatasetDialog
  | ResetExampleDatasetUrl
  | DatasetDeletePressed
  | AddDatasetFile
  | DatasetCopyId
  | TechnicalDocumentationPressed
  | PresetExportDataPressed
  | DatasetExportDataPressed
  | CustomModelsExportDataPressed;
