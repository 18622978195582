import { Icon } from '@ai21/studio-base-ui';
import { Content, Header, P, Wrapper } from './AuthMessage.style';
import Page from '../Page/Page';

export type AuthMessageProps = {
  title: string;
  message: string;
  children?: React.ReactNode;
};

export function AuthMessage(props: AuthMessageProps) {
  const { title, message } = props;

  return (
    <Page>
      <Wrapper className='AuthMessage-wrapper' data-testid='AuthMessage-wrapper'>
        <Header align='center' variant='h4'>
          {title}
        </Header>
        <Content>
          <P>
            {message} {props.children}
          </P>
        </Content>
      </Wrapper>
    </Page>
  );
}

export default AuthMessage;
