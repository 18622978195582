import { api, snippets } from '@ai21/studio-api';
import { actions, selectors } from '@ai21/studio-store';
import {
  FileDrop,
  UploadSummary,
  prompt,
  toast,
  IconButton,
} from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, delay, put, select, takeEvery } from 'saga-ts';
import { guid4, invokeEvent } from 'shared-base';
import Highlights from '../components/Highlights/Highlights';
import UploadProgressContainer from '../components/UploadProgress/UploadProgress.container';
import { Json } from '../types';
import { store } from '../utils/globals';
import { IUploadResponse, UploadQueue } from '../utils/queue';
import { createDocument } from './helpers/document';

type Verb = 'api';

type ActionJob = {
  type: 'LIBRARY';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  api: apiForLibrary,
};

function* apiForLibrary(action: ActionJob) {
  const { id } = action;
  const params: any = { main: '' };
  const code = snippets.library(params);

  yield prompt.code({
    title: 'API call',
    hideBackdrop: true,
    code,
    intention: 'code',
  });
}
// ======= generic part =======
export function* library(action: ActionJob) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('LIBRARY', library);
}
