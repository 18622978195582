export const arrayToObject = (array: Json[] = [], key: string = 'id') => {
  return (array ?? []).reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

export const addDataToItems = (array: Json[] = [], change: Json = {}) => {
  return (array ?? []).map((item) => {
    return {
      ...item,
      ...change,
    };
  });
};

export const replaceId = (array: Json[] = [], idKey: string = 'id') => {
  if (idKey === 'id') {
    return array;
  }

  return array.map((item) => {
    return {
      ...item,
      id: item[idKey],
    };
  });
};

export const removeUndefinedValues = (obj: Json) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== undefined) {
      acc[key] = obj[key];
    }
    return acc;
  }, {} as Json);
};
