import { ApiKeyEndpointResponse } from '../../data-types/ApiKey';
import { ModelEndpointListElement } from '../../data-types/Model';
import {
  OrganizationDetailsResponse,
  OrganizationUserDetailsListElement,
  UserRole,
} from '../../data-types/Organization';
import {
  BillingInfoEndpointResponse,
  InvoiceInfo,
  PaymentMethodEndpointResponse,
} from '../../data-types/Payment';
import { SnackbarSeverity } from '../../data-types/Snackbar';
import { TierType } from '../../data-types/Tier';
import {
  CreditInfoEndpointResponse,
  SpecializedModelUsageEndpointResponse,
  TrainModelsUsageEndpointListElement,
  UsageEndpointListElement,
} from '../../data-types/Usage';
import { UserStatus } from '../../data-types/User';
import { errorHandler } from '../../utils/error-reporting';
import {
  CANCEL_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_STARTED,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CHANGE_USER_ROLE_FAILURE,
  CHANGE_USER_ROLE_SUCCESS,
  CLEAR_SNACKBAR_MESSAGE,
  EDIT_ORGANIZATION_NAME_FAILURE,
  FEEDBACK_SUBMIT_FAILURE,
  FEEDBACK_SUBMIT_STARTED,
  FEEDBACK_SUBMIT_SUCCESS,
  FETCH_API_INFO_SUCCESS,
  FETCH_BILLING_INFO_SUCCESS,
  FETCH_CREDIT_INFO_SUCCESS,
  FETCH_INVOICE_HISTORY_FAILURE,
  FETCH_INVOICE_HISTORY_SUCCESS,
  FETCH_ORGANIZATION_DETAILS_FAILURE,
  FETCH_ORGANIZATION_DETAILS_SUCCESS,
  FETCH_ORGANIZATION_USERS_DETAILS_FAILURE,
  FETCH_ORGANIZATION_USERS_DETAILS_SUCCESS,
  FETCH_PAYMENT_INFO_SUCCESS,
  FETCH_REFRESH_API_KEY_STARTED,
  FETCH_REFRESH_API_KEY_SUCCESS,
  FETCH_SPECIALIZED_MODEL_USAGE_INFO_SUCCESS,
  FETCH_TRAIN_MODELS_USAGE_SUCCESS,
  FETCH_USAGE_INFO_SUCCESS,
  FETCH_USER_DETAILS_RETRY_SUCCESS,
  FETCH_USER_DETAILS_SUCCESS,
  ROTATE_API_KEY_FAILURE,
  ROTATE_API_KEY_SUCCESS,
  SEND_INVITE_ORGANIZATION_FAILURE,
  SEND_INVITE_ORGANIZATION_SUCCESS,
  SIGN_OUT_SUCCESS,
  UserActionTypes,
} from '../actionTypes/userActionTypes';

export interface UserState {
  apiKey: string | null;
  apiEndpointResponse: ApiKeyEndpointResponse | null;
  quotaEndpointResponse: ModelEndpointListElement[] | null;
  usageEndpointResponse: UsageEndpointListElement[] | null;
  specializedModelUsageEndpointResponse: SpecializedModelUsageEndpointResponse[] | null;
  creditInfoEndpointResponse: CreditInfoEndpointResponse | null;
  trainModelsUsageEndpointResponse: TrainModelsUsageEndpointListElement[] | null;
  organizationDetailsResponse: OrganizationDetailsResponse | null;
  organizationUserDetailsResponse: OrganizationUserDetailsListElement[] | null;
  invoiceHistoryResponse: InvoiceInfo[] | null;
  paymentMethodEndpointResponse: PaymentMethodEndpointResponse | null;
  billingInfoEndpointResponse: BillingInfoEndpointResponse | null;
  snackbarMessage: string | undefined;
  snackbarSeverity: SnackbarSeverity;
  openSnackbar: boolean;
  fetchingApiKey: boolean;
  userStatus: UserStatus | null;
  userTier: TierType | null;
  userRole: UserRole | null;
  cancelSubscriptionInProgress: boolean;
  subscriptionExpirationDate: string | null;
  subscriptionCancellationDate: string | null;
  featureFlags: { [key: string]: string } | null;
  postFeedBackInProgress: boolean;
}

const initialState: UserState = {
  apiKey: null,
  apiEndpointResponse: null,
  quotaEndpointResponse: null,
  usageEndpointResponse: null,
  specializedModelUsageEndpointResponse: null,
  creditInfoEndpointResponse: null,
  trainModelsUsageEndpointResponse: null,
  organizationDetailsResponse: null,
  organizationUserDetailsResponse: null,
  paymentMethodEndpointResponse: null,
  billingInfoEndpointResponse: null,
  fetchingApiKey: false,
  userStatus: null,
  userTier: null,
  userRole: null,
  cancelSubscriptionInProgress: false,
  subscriptionExpirationDate: null,
  subscriptionCancellationDate: null,
  invoiceHistoryResponse: null,
  featureFlags: null,
  snackbarMessage: undefined,
  snackbarSeverity: SnackbarSeverity.INFO,
  openSnackbar: false,
  postFeedBackInProgress: false,
};

export function reducer(state = initialState, action: UserActionTypes) {
  switch (action.type) {
    case FETCH_USER_DETAILS_SUCCESS:
      if (action.payload.userId) {
        errorHandler.setUser(action.payload.userId);
        errorHandler.report(`FETCH_USER_DETAILS_SUCCESS user: ${action.payload.userId}`);
      }
      return {
        ...state,
        apiKey: action.payload.apiKey,
        userStatus: action.payload.userStatus,
        userTier: action.payload.userTier,
        userRole: action.payload.userRole,
        featureFlags: action.payload.featureFlags,
        subscriptionExpirationDate: action.payload.subscriptionExpirationDate,
        subscriptionCancellationDate: action.payload.subscriptionCancellationDate,
      };
    case 'LOAD_USER' as any:
      return {
        ...state,
        apiKey: (action as any).payload.apiKey,
        userStatus: (action as any).payload.userStatus,
        userTier: (action as any).payload.userTier,
        userRole: (action as any).payload.userRole,
        featureFlags: (action as any).payload.featureFlags,
      };
    case CANCEL_SUBSCRIPTION_STARTED:
      return {
        ...state,
        cancelSubscriptionInProgress: true,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
    case CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        cancelSubscriptionInProgress: false,
      };
    case FETCH_USER_DETAILS_RETRY_SUCCESS:
      return {
        ...state,
        apiKey: action.payload.apiKey,
        userStatus: action.payload.userStatus,
        userTier: action.payload.userTier,
        userRole: action.payload.userRole,
        featureFlags: action.payload.featureFlags,
        subscriptionExpirationDate: action.payload.subscriptionExpirationDate,
        subscriptionCancellationDate: action.payload.subscriptionCancellationDate,
      };
    case SIGN_OUT_SUCCESS:
      return { ...state, apiKey: null };
    case FETCH_API_INFO_SUCCESS:
      return { ...state, apiEndpointResponse: action.payload };
    case FETCH_USAGE_INFO_SUCCESS:
      return { ...state, usageEndpointResponse: action.payload };
    case FETCH_SPECIALIZED_MODEL_USAGE_INFO_SUCCESS:
      return { ...state, specializedModelUsageEndpointResponse: action.payload };
    case FETCH_CREDIT_INFO_SUCCESS:
      return { ...state, creditInfoEndpointResponse: action.payload };
    case FETCH_TRAIN_MODELS_USAGE_SUCCESS:
      return { ...state, trainModelsUsageEndpointResponse: action.payload };
    case FETCH_REFRESH_API_KEY_SUCCESS:
      return {
        ...state,
        apiKey: action.payload.apiKey,
        fetchingApiKey: false,
        featureFlags: action.payload.featureFlags,
      };
    case FETCH_REFRESH_API_KEY_STARTED:
      return { ...state, fetchingApiKey: true };
    case FETCH_PAYMENT_INFO_SUCCESS:
      return { ...state, paymentMethodEndpointResponse: action.payload };
    case FETCH_BILLING_INFO_SUCCESS:
      return { ...state, billingInfoEndpointResponse: action.payload };
    case FETCH_INVOICE_HISTORY_SUCCESS:
      return { ...state, invoiceHistoryResponse: action.invoiceHistory };
    case FETCH_INVOICE_HISTORY_FAILURE:
      return { ...state, invoiceHistoryResponse: null };
    case FETCH_ORGANIZATION_DETAILS_SUCCESS:
      return { ...state, organizationDetailsResponse: action.payload };
    case FETCH_ORGANIZATION_DETAILS_FAILURE:
      return {
        ...state,
        organizationDetailsResponse: null,
        snackbarMessage: action.error,
        snackbarSeverity: SnackbarSeverity.ERROR,
      };
    case FETCH_ORGANIZATION_USERS_DETAILS_SUCCESS:
      return { ...state, organizationUserDetailsResponse: action.payload };
    case FETCH_ORGANIZATION_USERS_DETAILS_FAILURE:
      return {
        ...state,
        organizationUserDetailsResponse: null,
        snackbarMessage: action.error,
        snackbarSeverity: SnackbarSeverity.ERROR,
      };
    case EDIT_ORGANIZATION_NAME_FAILURE:
      return {
        ...state,
        snackbarMessage: action.error,
        snackbarSeverity: SnackbarSeverity.ERROR,
      };
    case SEND_INVITE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        snackbarMessage: 'Invitation sent',
        snackbarSeverity: SnackbarSeverity.INFO,
      };
    case SEND_INVITE_ORGANIZATION_FAILURE:
      return {
        ...state,
        snackbarMessage: action.error,
        snackbarSeverity: SnackbarSeverity.ERROR,
      };
    case CLEAR_SNACKBAR_MESSAGE:
      return {
        ...state,
        snackbarSeverity: SnackbarSeverity.INFO,
        snackbarMessage: undefined,
      };
    case CHANGE_USER_ROLE_SUCCESS:
      return {
        ...state,
        snackbarSeverity: SnackbarSeverity.INFO,
        snackbarMessage: 'User role changed',
      };
    case CHANGE_USER_ROLE_FAILURE:
      return {
        ...state,
        snackbarSeverity: SnackbarSeverity.ERROR,
        snackbarMessage: action.error,
      };
    case ROTATE_API_KEY_SUCCESS:
      return {
        ...state,
        snackbarSeverity: SnackbarSeverity.INFO,
        snackbarMessage: 'API Key Rotated',
      };
    case ROTATE_API_KEY_FAILURE:
      return {
        ...state,
        snackbarSeverity: SnackbarSeverity.ERROR,
        snackbarMessage: action.error,
      };
    case FEEDBACK_SUBMIT_STARTED:
      return {
        ...state,
        postFeedBackInProgress: true,
      };
    case FEEDBACK_SUBMIT_SUCCESS:
      return {
        ...state,
        postFeedBackInProgress: false,
      };
    case FEEDBACK_SUBMIT_FAILURE:
      return {
        ...state,
        postFeedBackInProgress: false,
      };
    default:
      return state;
  }
}
