import { IMenuItem, IconButton, toast } from '@ai21/studio-ui';
import React from 'react';
import { useShare } from '../../hooks/useShare';
import { Actions, ActionsRow, Logo, Title, Wrapper } from './Header.style';

export type HeaderProps = {
  data: Json;
  onClear: () => void;
};

export function Header(props: HeaderProps) {
  const { data = {} } = props;
  const {
    headerBkColor,
    logoUrl,
    title = 'Ask',
    titleColor = 'white',
    menuColor = 'white',
    logoMaxWidth = 400,
    logoMaxWidthMobile = 160,
  } = data.theme ?? {};
  const share = useShare();

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  function onClear(menu: IMenuItem) {
    props.onClear();
  }

  function onShare() {
    navigator.clipboard.writeText(window.location.href);
    toast.show('Copied to clipboard', { type: 'success' });
  }

  const style: React.CSSProperties = {
    backgroundColor: headerBkColor,
  };

  const styleTitle: React.CSSProperties = {
    color: titleColor,
  };

  const styleLogo: React.CSSProperties = {
    maxWidth: isMobile ? logoMaxWidthMobile : logoMaxWidth,
  };

  return (
    <Wrapper
      style={style}
      className='Header-wrapper'
      data-testid='Header-wrapper'
    >
      <ActionsRow>
        <Actions className='left'>
          <IconButton
            data-testid='ClearMessages'
            variant='contained'
            iconName='Eraser'
            onClick={onClear}
          />
        </Actions>

        <Actions className='right'>
          <IconButton
            data-testid='ShareDemo'
            iconName='Share'
            onClick={onShare}
          />
        </Actions>
      </ActionsRow>
      <Logo style={styleLogo} src={logoUrl} />
      <Title style={styleTitle} variant='h4bold'>
        {title}
      </Title>
    </Wrapper>
  );
}

export default Header;
