import { app } from './empty/empty.app';
import { IApps } from './types';

export const apps: IApps = {
  // ###### Financial Doc Q&A ############################################################
  '1': {
    id: '1',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Financial Doc Q&A',
    titleLong: 'Financial Doc Q&A',
    subtitle: 'Instantly receive synthesized answers about complex financial documents.',
    screenshots: ['/showroom/Covers/Cover-FinancialDocQA.png'],
    overviewDescription: `## Using the tool
  Simplify information retrieval across your organization’s financial documents, from annual 10-K reports to regulatory guidelines and loan applications. This Q&A tool serves as an invaluable resource for employees in banking, advisory or investment services, increasing workflow efficiency and ensuring no important detail is overlooked in a manual search.
  `,
    descriptionFull: `## Improve key metrics
  - Risk mitigation
  - Task automation
  - Employee productivity
    `,
    tags: {
      model: ['Contextual Answers'],
      industry: ['Finance'],
      metric: ['Employee productivity', 'Task automation', 'Risk mitigation'],
    },
    modelsUsage: [
      {
        modelName: 'Contextual Answers',
        description:
          'A Task-Specific Model for instant Q&A. By grounding all answers solely in the content of your organization’s documents, and indicating when an answer is unavailable, the model avoids hallucinations, inaccuracies, and potential liability and compliance issues.',
        // presetUrl:'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },

  // ###### Term Sheet Generator ############################################################
  '2': {
    id: '2',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Term Sheet Generator',
    titleLong: 'Term Sheet Generator',
    subtitle:
      'Output term sheets from short summaries of client calls, with all numbers already filled in. ',
    screenshots: ['/showroom/Covers/Cover-TermSheetGenerator.png'],
    overviewDescription: `## Using the tool
  Automate repetitive manual reporting and impress clients with quick turnaround on post-call term sheets. The Term Sheet Generator tool structures all agreement details and numbers into a standard term sheet format that’s ready to share. It can also be used to produce other documents from call transcripts or summaries, such as investor updates and loan agreements.
  `,
    descriptionFull: `## Improve key metrics
  - Personalized customer support
  - Task automation
  - Content generation
    `,
    tags: {
      model: ['Jamba-Instruct'],
      industry: ['Finance'],
      metric: ['Personalized customer support', 'Task automation', 'Content generation'],
    },
    modelsUsage: [
      {
        modelName: 'Jamba-Instruct',
        description:
          'A sophisticated foundational model that can analyze and extract key details from client calls and then reformat them into shareable documents.',
        // presetUrl:'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },

  // ###### Customer Chatbot ############################################################
  '3': {
    id: '3',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Customer Chatbot',
    titleLong: 'Customer Chatbot',
    subtitle:
      'Provide customers with instant and accurate answers to their questions, both big and small. ',
    screenshots: ['/showroom/Covers/Cover-CustomerChatbot.png'],
    overviewDescription: `## Using the tool
  Improve customer satisfaction, and reduce customer support workloads, with a reliable Q&A tool that answers customer queries based solely on your company’s documents, records, or product database. Built for use across multiple industries—including financial services, insurance, retail, and healthcare—the chatbot generates privacy-sensitive answers to both common and complex questions.
  `,
    descriptionFull: `## Improve key metrics
  - Personalized customer support
  - Task automation
  - Employee productivity
    `,
    tags: {
      model: ['Contextual Answers'],
      industry: ['Finance', 'Retail', 'Healthcare'],
      metric: ['Personalized customer support', 'Task automation', 'Employee productivity'],
    },
    modelsUsage: [
      {
        modelName: 'Contextual Answers',
        description:
          'A Task-Specific Model for instant Q&A. By grounding all answers solely in the content of your organization’s documents, and indicating when an answer is unavailable, the model avoids hallucinations, inaccuracies, and potential liability and compliance issues.',
        // presetUrl:'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },

  // ###### Earnings Call Analyst ############################################################
  '4': {
    id: '4',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Earnings Call Analyst',
    titleLong: 'Earnings Call Analyst',
    subtitle:
      'Extract and summarize the key highlights from quarterly or annual company earnings calls. ',
    screenshots: ['/showroom/Covers/Cover-EarningsCallAnalyst.png'],
    overviewDescription: `## Using the tool
  Eliminate the tedious and time-consuming process of manually reviewing and summarizing call transcripts. This summarization tool transforms earnings calls into digestible bites that contain the most need-to-know points for investors, analysts, and the wider financial community.
    `,
    descriptionFull: `## Improve key metrics
  - Risk mitigation
  - Task automation
  - Employee productivity
      `,
    tags: {
      model: ['Summarize Conversation'],
      industry: ['Finance'],
      metric: ['Risk mitigation', 'Task automation', 'Employee productivity'],
    },
    modelsUsage: [
      {
        modelName: 'Summarize Conversation',
        description:
          'A Task-Specific Model that can capture the key points of detailed transcripts and use them to generate a true-to-source summary of the call.',
        // presetUrl: 'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },

  // ###### Product Description Generator ############################################################
  '5': {
    id: '5',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Product Description Generator',
    titleLong: 'Product Description Generator',
    subtitle:
      'Automatically generate product descriptions for every item, written using your brand voice guidelines.',
    screenshots: ['/showroom/Covers/Cover-ProductDescriptionGenerator.png'],
    overviewDescription: `## Using the tool
  Keep up with an ever-expanding inventory and help shoppers make purchase decisions more confidently by creating thousands of brand-aligned product descriptions at scale. Simply input a list of attributes for each product and define your target personas, and this powerful generation tool will produce a tailored description that captures the style, tone, length, and structure you want.
      `,
    descriptionFull: `## Improve key metrics
  - Sales conversion
  - Task automation
  - Content generation
        `,
    tags: {
      model: ['Jamba-Instruct'],
      industry: ['Retail'],
      metric: ['Sales conversion', 'Task automation', 'Content generation'],
    },
    modelsUsage: [
      {
        modelName: 'Jamba-Instruct',
        description:
          'A sophisticated foundational model that can analyze all available information about a product to generate engaging, website-ready descriptions.',
        // presetUrl: 'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },
  // ###### Customer Chatbot ############################################################
  '6': {
    id: '6',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Research Assistant',
    titleLong: 'Research Assistant',
    subtitle: 'Access the most relevant, cited material, curated from trusted scholarly sources.',
    screenshots: ['/showroom/Covers/Cover-ResearchAssistant.png'],
    overviewDescription: `## Using the tool
  Accelerate research with a powerful tool that instantly scans billions of vetted articles for the most relevant results, complete with article snippets and citations. From conducting literature reviews to combing medical research for new insights, the tool's intuitive interface drastically reduces research time for researchers working across a variety of fields.
  `,
    descriptionFull: `## Improve key metrics
  - Accelerated research
  - Risk mitigation
  - Task automation
    `,
    tags: {
      model: ['Contextual Answers'],
      industry: ['Education'],
      metric: ['Accelerated research', 'Risk mitigation', 'Task automation'],
    },
    modelsUsage: [
      {
        modelName: 'Contextual Answers',
        description:
          'A Task-Specific Model for instant Q&A. By grounding all answers solely in the content of your organization’s own library database, and indicating when an answer is unavailable, the model avoids hallucinations and inaccuracies. ',
        // presetUrl:'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },
  // ###### Virtual Health Assistant ############################################################
  '7': {
    id: '7',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Virtual Health Assistant',
    titleLong: 'Virtual Health Assistant',
    subtitle:
      'Assess and respond to patient questions in real time with accurate medical knowledge.',
    screenshots: ['/showroom/Covers/Cover-VirtualHealthAssistant.png'],
    overviewDescription: `## Using the tool
  Instantly answer health-related questions from patients and health care customers with medically accurate responses, grounded in your organization’s own medical knowledge database. Whether available directly to health care patients and customers, or used to support customer service representatives, this tool connects people to the medical knowledge and care recommendations they need, faster.
    `,
    descriptionFull: `## Improve key metrics
  - Personalized customer support
  - Task automation
  - Risk mitigation
      `,
    tags: {
      model: ['Contextual Answers', 'Jamba-Instruct'],
      industry: ['Healthcare'],
      metric: ['Personalized customer support', 'Task automation', 'Risk mitigation'],
    },
    modelsUsage: [
      {
        modelName: 'Jamba-Instruct',
        description:
          'A sophisticated foundation model used to summarize this knowledge into a digestible format and apply safety guardrails to ensure responses are suitable for customers.',
        // presetUrl: 'home/complete/single-input-1-output?presetId=retail_faq_contextual_completion',
      },
      {
        modelName: 'Contextual Answers',
        description:
          'A Task-Specific Model used to generate initial responses to questions, grounded solely in your organization’s vetted knowledge.',
        // presetUrl:'home/task-specific-models/contextual-answers?presetId=retail_faq_contextual_answers',
      },
    ],
  },
  // ###### Agent Assist ############################################################
  '8': {
    id: '8',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Agent Assist',
    titleLong: 'Agent Assist',
    subtitle: 'Enable customer support representatives to instantly access accurate answers.',
    screenshots: ['/showroom/Covers/Cover-AgentAssist.png'],
    overviewDescription: `## Using the tool
  Improve customer service efficiency with a reliable Q&A tool that answers queries based solely on your company’s documents and records. For use internally by customer service representatives across a variety of sectors—including financial services, insurance, healthcare, and retail—the chatbot allows them to seamlessly access the information they need to answer customer questions and successfully resolve tickets.
    `,
    descriptionFull: `## Improve key metrics
  - Personalized customer support
  - Task automation
  - Employee productivity
      `,
    tags: {
      model: ['Contextual Answers'],
      industry: ['Retail', 'Finance', 'Healthcare'],
      metric: ['Personalized customer support', 'Task automation', 'Employee productivity'],
    },
    modelsUsage: [
      {
        modelName: 'Contextual Answers',
        description:
          'A Task-Specific Model for instant Q&A. By grounding all answers solely in the content of your organization’s documents, and indicating when an answer is unavailable, the model avoids hallucinations, inaccuracies, and potential liability and compliance issues.',
        // presetUrl: 'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },
  // ###### Shopping Assistant ############################################################
  '9': {
    id: '9',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Shopping Assistant',
    titleLong: 'Shopping Assistant',
    subtitle: 'Accurately answer customer questions about any product in real time.',
    screenshots: ['/showroom/Covers/Cover-ShoppingAssistant.png'],
    overviewDescription: `## Using the tool
  Offer customers a way to ask questions about any product. By automatically generating answers from the product page description, the assistant tool connects customers to the information they need to make a purchase decision, all without interrupting their buyer's journey.
      `,
    descriptionFull: `## Improve key metrics
  - Sales conversion
  - Employee productivity
  - Personalized customer support
        `,
    tags: {
      model: ['Contextual Answers'],
      industry: ['Retail'],
      metric: ['Personalized customer support', 'Sales conversion', 'Employee productivity'],
    },
    modelsUsage: [
      {
        modelName: 'Contextual Answers',
        description:
          'A Task-Specific Model for instant Q&A. By grounding all answers solely in the content of your product pages and always indicating when an answer is unavailable, the model avoids hallucinations and inaccuracies. ',
        // presetUrl: 'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },
  // ###### Insurance Policy Expert ############################################################
  '10': {
    id: '10',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Insurance Policy Expert',
    titleLong: 'Insurance Policy Expert',
    subtitle: 'Instantly receive synthesized answers about complex policies and documents.',
    screenshots: ['/showroom/Covers/Cover-InsurancePolicyExpert.png'],
    overviewDescription: `## Using the tool
  Verify customer questions about their insurance policies on the spot. This Q&A tool seamlessly integrates into your customer service department, empowering representatives to quickly retrieve accurate answers about coverage, contract terms, and policy plans while on calls with customers.
      `,
    descriptionFull: `## Improve key metrics
- Risk mitigation
- Task automation
- Personalized customer support
        `,
    tags: {
      model: ['Contextual Answers'],
      industry: ['Insurance'],
      metric: ['Personalized customer support', 'Risk mitigation', 'Task automation'],
    },
    modelsUsage: [
      {
        modelName: 'Contextual Answers',
        description:
          'A Task-Specific Model for instant Q&A. By grounding all answers solely in the content of your organization’s documents, and indicating when an answer is unavailable, the model avoids hallucinations, inaccuracies, and potential liability and compliance issues. ',
        // presetUrl: 'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },
  // ###### Article Ghostwriter ############################################################
  '11': {
    id: '11',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Article Ghostwriter',
    titleLong: 'Article Ghostwriter',
    subtitle: 'Quickly transform your notes into structured articles that are ready to publish.',
    screenshots: ['/showroom/Covers/Cover-ArticleGhostwriter.png'],
    overviewDescription: `## Using the tool
  Skip the outline phase and produce organized articles from unstructured notes, including raw text, statistics, and lists—reducing content creation time and costs by up to 90%. The assistant tool will instantly identify key themes and build defined sections around each one, ready to publish or to continue elaborating upon.
      `,
    descriptionFull: `## Improve key metrics
  - Employee productivity
  - Task automation
  - Content generation
        `,
    tags: {
      model: ['Jamba-Instruct'],
      industry: ['Education', 'Retail', 'Healthcare'],
      metric: ['Employee productivity', 'Task automation', 'Content generation'],
    },
    modelsUsage: [
      {
        modelName: 'Jamba-Instruct',
        description:
          'A sophisticated foundational model that can analyze your notes and any other inputted information to generate structured, publish-ready articles.',
        // presetUrl: 'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },
  // ###### Financial Document Summarizer ############################################################
  '12': {
    id: '12',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Financial Document Summarizer',
    titleLong: 'Financial Document Summarizer',
    subtitle: 'Instantly summarize the key points from complex and lengthy financial documents.',
    screenshots: ['/showroom/Covers/Cover-FinancialDocumentSummarizer.png'],
    overviewDescription: `## Using the tool
  Enable financial research teams to review documents faster and avoid overlooking critical information. This summarization tool intakes vast amounts of complex information, such as market analysis, company reports, and meeting notes, and then automatically distills and summarizes every key point.
    `,
    descriptionFull: `## Improve key metrics
  - Risk mitigation
  - Task automation
  - Employee productivity
      `,
    tags: {
      model: ['Summarize'],
      industry: ['Finance'],
      metric: ['Risk mitigation', 'Task automation', 'Employee productivity'],
    },
    modelsUsage: [
      {
        modelName: 'Summarize',
        description:
          'A Task-Specific Model that can capture the key points of complex financial documents and use them to generate true-to-source summaries.',
        // presetUrl:'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },
  // ###### Google Ads Copywriter ############################################################
  '13': {
    id: '13',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Google Ads Copywriter',
    titleLong: 'Google Ads Copywriter',
    subtitle: 'Generate conversion-optimized Google Ads copy that captures your brand voice.',
    screenshots: ['/showroom/Covers/Cover-GoogleAdsCopywriter.png'],
    overviewDescription: `## Using the tool
  Promote your company’s products with highly personalized Google Ads creatives—reducing content creation time and costs by up to 90%. Use the tool to generate headline and body ad copy for all of your products, or request variations on each to test performance. Simply share an example of one of your top performing ads to help the tool understand your criteria and brand voice, and then start creating ads.
      `,
    descriptionFull: `## Improve key metrics
  - Task automation
  - Content generation
  - Traffic generation
        `,
    tags: {
      model: ['Jamba-Instruct'],
      industry: ['Retail'],
      metric: ['Task automation', 'Content generation', 'Traffic generation'],
    },
    modelsUsage: [
      {
        modelName: 'Jamba-Instruct',
        description:
          'A sophisticated foundational model that can analyze your brand voice and top-performing ads to generate engaging, optimized copy for your Google Ads.',
        // presetUrl: 'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },
  // ###### Executive Insights Analyst  ############################################################
  '14': {
    id: '14',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Executive Insights Analyst ',
    titleLong: 'Executive Insights Analyst ',
    subtitle: 'Instantly transform industry research and reports into high-level, concise memos.',
    screenshots: ['/showroom/Covers/Cover-ExecutiveInsightsAnalyst.png'],
    overviewDescription: `## Using the tool
  Stay on top of the latest industry advancements and thought leadership amidst a non-stop schedule. This summarization tool makes it possible to read more in less time by intaking lengthy reports and papers and producing summaries of the key insights, statistics, and trends.
    `,
    descriptionFull: `## Improve key metrics
  - Task automation
  - Content generation
  - Accelerated research
      `,
    tags: {
      model: ['Summarize'],
      industry: ['Finance', 'Insurance', 'Healthcare', 'Education'],
      metric: ['Task automation', 'Content generation', 'Accelerated research'],
    },
    modelsUsage: [
      {
        modelName: 'Summarize',
        description:
          'A Task-Specific Model that can capture the key points of detailed articles and use them to generate true-to-source summaries.',
        // presetUrl:'home/task-specific-models/contextual-answers?presetId=contextual_answers_product_assistant',
      },
    ],
  },
  // ###### Product FAQ Generator ############################################################
  '15': {
    id: '15',
    imageUrl: '/showroom/Thumbnails/',
    title: 'Product FAQ Generator',
    titleLong: 'Product FAQ Generator',
    subtitle: 'Automatically build FAQ sections tailored to each product page.',
    screenshots: ['/showroom/Covers/Cover-ProductFAQGenerator.png'],
    overviewDescription: `## Using the tool
  Enhance your product pages and deliver valuable information to potential customers with comprehensive FAQs. Instead of customizing FAQs for each product, this powerful generation tool can be used at scale to automatically generate accurate and SEO-optimized questions and answers across all product pages, tailored to each product’s unique information.
    `,
    descriptionFull: `## Improve key metrics
  - Employee productivity
  - Traffic generation
  - Task automation
      `,
    tags: {
      model: ['Contextual Answers', 'Jamba-Instruct'],
      industry: ['Retail'],
      metric: ['Employee productivity', 'Task automation', 'Task generation'],
    },
    modelsUsage: [
      {
        modelName: 'Jamba-Instruct',
        description:
          'A sophisticated foundational model used to analyze all available information about a product to generate relevant questions. ',
        // presetUrl: 'home/complete/single-input-1-output?presetId=retail_faq_contextual_completion',
      },
      {
        modelName: 'Task-Specific',
        description:
          'A Task-Specific model used to accurately answer the generated questions, with content grounded solely in your product pages.',
        // presetUrl: 'home/task-specific-models/contextual-answers?presetId=retail_faq_contextual_answers',
      },
    ],
  },
};
