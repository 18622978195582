import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Auth,
  GithubAuthProvider,
  GoogleAuthProvider,
  RecaptchaVerifier,
  User,
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  getIdToken,
  signOut,
  connectAuthEmulator,
  OAuthProvider,
} from 'firebase/auth';
import { AuthOptions } from '../types';
import { setTokens } from '@ai21/studio-api';

export class Firebase {
  public app: FirebaseApp;
  private auth: Auth;
  private googleProvider: GoogleAuthProvider;
  private githubProvider: GithubAuthProvider;
  private authListeners: AuthListener[] = [];
  private captchaToken: string = '';

  public uid: string;
  public clientId: string;

  constructor(private authOptions: AuthOptions) {
    const { firebaseConfig } = authOptions;

    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);

    if (process.env.FIREBASE_AUTH_EMULATOR_HOST) {
      connectAuthEmulator(this.auth, process.env.FIREBASE_AUTH_EMULATOR_HOST);
    }

    this.googleProvider = new GoogleAuthProvider();
    this.githubProvider = new GithubAuthProvider();

    this.clientId = firebaseConfig.appId;
    this.uid = '';

    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.uid = user.uid;
      }

      this.authListeners.forEach((listener) => {
        listener(user);
      });
    });

    onIdTokenChanged(this.auth, (user) => {
      if (user) {
        getIdToken(user).then((token) => {
          setTokens({
            firebaseIdToken: token,
          });
        });

        this.uid = user.uid;
      }
    });
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }

  addAuthListener(listener: AuthListener) {
    this.authListeners.push(listener);
  }

  removeAuthListener(listener: AuthListener) {
    const index = this.authListeners.indexOf(listener);
    this.authListeners.splice(index, 1);
  }

  getIdToken(user: User, forceRefresh: boolean = true) {
    return getIdToken(user, forceRefresh);
  }

  invisibleCaptcha() {
    if (this.captchaToken) {
      return Promise.resolve(this.captchaToken);
    }

    const captcha = new RecaptchaVerifier(
      'captcha',
      {
        size: 'invisible',
      },
      this.auth
    );

    return captcha.verify().then((token) => {
      this.captchaToken = token;
      return token;
    });
  }

  isNewUser(user: User) {
    const createdAt = (user.metadata as any).createdAt;
    const lastLoginAt = (user.metadata as any).lastLoginAt;

    const delta = parseInt(lastLoginAt) - parseInt(createdAt);

    return delta < 3 * 60 * 1000;
  }

  signInWithGoogle = (): Promise<SignInResponse> => {
    const options = this.authOptions;

    return new Promise((resolve) => {
      if (options.captcha.providers && !this.captchaToken) {
        resolve({
          user: null,
          err: {
            code: 'auth/captcha-token-missing',
          },
        });
        return;
      }

      signInWithPopup(this.auth, this.googleProvider)
        .then((res) => {
          const { user } = res;
          const isNewUser = this.isNewUser(user);
          resolve({ user, isNewUser, err: null });
          this.uid = user?.uid;
        })
        .catch((err) => {
          resolve({ user: null, err });
        });
    });
  };

  signInWithSSO = (provider: OAuthProvider): Promise<SignInResponse> => {
    const options = this.authOptions;
    return new Promise((resolve) => {
      if (options.captcha.providers && !this.captchaToken) {
        resolve({
          user: null,
          err: {
            code: 'auth/captcha-token-missing',
          },
        });
        return;
      }
      signInWithPopup(this.auth, provider)
        .then((res) => {
          const { user } = res;
          resolve({ user, err: null });
          this.uid = user?.uid;
        })
        .catch((err) => {
          resolve({ user: null, err });
        });
    });
  };

  calculateRedirectUrl = (path: string) => {
    let output = `${window.__env__.VITE_APP_SERVING_URL}${path}`;

    // add query params from document search
    const search = window.location.search;

    if (search) {
      output += search;
    }

    return output;
  };

  sendPasswordResetEmail = (email: string): Promise<SignInResponse> => {
    const url = this.calculateRedirectUrl('/forgot-password-done');

    return new Promise((resolve) => {
      sendPasswordResetEmail(this.auth, email, {
        url,
      })
        .then(() => {
          resolve({ user: null, err: null });
        })
        .catch((err) => {
          resolve({ user: null, err });
        });
    });
  };

  signInWithGithub = (): Promise<SignInResponse> => {
    const options = this.authOptions;

    return new Promise((resolve) => {
      if (options.captcha.providers && !this.captchaToken) {
        resolve({
          user: null,
          err: {
            code: 'auth/captcha-token-missing',
          },
        });
        return;
      }

      return signInWithPopup(this.auth, this.githubProvider)
        .then((res) => {
          const { user } = res;
          resolve({ user, err: null });
          this.uid = user?.uid;
        })
        .catch((err) => {
          resolve({ user: null, err });
        });
    });
  };

  signUpWithEmailAndPassword = (email: string, password: string): Promise<SignInResponse> => {
    return new Promise((resolve) => {
      if (!this.captchaToken) {
        resolve({
          user: null,
          err: {
            code: 'auth/captcha-token-missing',
          },
        });
        return;
      }

      const url = this.calculateRedirectUrl('/check-email-done');

      createUserWithEmailAndPassword(this.auth, email, password)
        .then((res) => {
          const { user } = res;
          sendEmailVerification(user, {
            url,
          });
          resolve({ user, err: null });
          this.uid = user?.uid;
        })
        .catch((err) => {
          resolve({ user: null, err });
        });
    });
  };

  resendVerificationEmail = () => {
    if (!this.auth.currentUser) {
      return;
    }

    const url = this.calculateRedirectUrl('/check-email-done');

    sendEmailVerification(this.auth.currentUser, {
      url,
    });
  };

  signInWithEmailAndPassword = (email: string, password: string): Promise<SignInResponse> => {
    const options = this.authOptions;

    return new Promise((resolve) => {
      if (options.captcha.email && !this.captchaToken) {
        resolve({
          user: null,
          err: {
            code: 'auth/captcha-token-missing',
          },
        });
        return;
      }

      signInWithEmailAndPassword(this.auth, email, password)
        .then((res) => {
          const { user } = res;
          resolve({ user, err: null });
          this.uid = user?.uid;
        })
        .catch((err) => {
          resolve({ user: null, err });
        });
    });
  };

  signOut() {
    return signOut(this.auth);
  }

  parseAuthError(code: string) {
    return authErrors[code] || code;
  }

  get value() {
    return {
      app: this.app,
      auth: this.auth,
    };
  }
}

type AuthListener = (user: User | null) => void;

type SignInResponse = {
  user: User | null;
  err: any;
  isNewUser?: boolean;
};

export const authErrors: Json = {
  'auth/user-not-found': 'There is no existing user record corresponding to the provided identifier.', // prettier-ignore
  'auth/invalid-user-import': 'There was an error with the user import. Please check the information and try again.', // prettier-ignore
  'auth/invalid-provider-id': 'The provided provider ID is invalid. Please check it and try again.',
  'auth/invalid-email': 'Please ensure that you enter a valid email address.',
  'auth/user-token-expired': 'Your user token has expired. Please log in again.',
  'auth/invalid-password': 'The provided value for the password user property is invalid. It must be a string with at least six characters.', // prettier-ignore
  'auth/credential-already-in-use': 'The credential you entered is already associated with another user. Please try with a different credential.', // prettier-ignore
  'auth/email-already-exists': 'There is an existing account using the provided email. If this is your email address, please login with it. Otherwise, please enter a unique email address.', // prettier-ignore
  'auth/phone-number-already-exists': 'The phone number you entered is already in use. Please try with a different phone number.', // prettier-ignore
  'auth/project-not-found': "The project you're looking for cannot be found. Please check your information and try again.", // prettier-ignore
  'auth/insufficient-permission': 'You do not have the necessary permissions to perform this action. Please check your permissions and try again.', // prettier-ignore
  'auth/internal-error': 'The Authentication server encountered an unexpected error while trying to process the request.', // prettier-ignore
  'auth/invalid-continue-uri': 'The provided continue URL is invalid. Please check it and try again.', // prettier-ignore
  'auth/invalid-dynamic-link-domain': 'The provided dynamic link domain is invalid. Please check it and try again.', // prettier-ignore
  'auth/argument-error': "There's an error with the arguments provided. Please check and try again.", // prettier-ignore
  'auth/invalid-persistence-type': 'The provided persistence type is invalid. Please check and try again.', // prettier-ignore
  'auth/unsupported-persistence-type': 'The provided persistence type is not supported. Please check and try again.', // prettier-ignore
  'auth/invalid-credential': 'The provided credential is invalid. Please check and try again.',
  'auth/wrong-password': 'The password you provided is incorrect. Please try again.',
  'auth/invalid-verification-code': 'The provided verification code is invalid. Please check and try again.', // prettier-ignore
  'auth/invalid-verification-id': 'The provided verification ID is invalid. Please check and try again.', // prettier-ignore
  'auth/custom-token-mismatch': "The provided custom token doesn't match our records. Please check and try again.", // prettier-ignore
  'auth/invalid-custom-token': 'The provided custom token is invalid. Please check and try again.',
  'auth/captcha-check-failed': 'CAPTCHA check failed. Please try again.',
  'auth/invalid-phone-number': 'The provided phone number is invalid. Please check and try again.',
  'auth/missing-phone-number': 'A phone number is required but was not provided. Please check and try again.', // prettier-ignore
  'auth/missing-password': 'A password is required for creating an account', // prettier-ignore
  'auth/quota-exceeded': "You've exceeded your quota. Please wait and try again later.",
  'auth/account-exists-with-different-credential': 'An account already exists with the same email address but different sign-in credentials. Please sign in using the associated method.', // prettier-ignore
  'auth/missing-verification-code': 'A verification code is required but was not provided. Please check and try again.', // prettier-ignore
  'auth/missing-android-pkg-name': 'An Android package name is required but was not provided. Please check and try again.', // prettier-ignore
  'auth/missing-continue-uri': 'A continue URL is required but was not provided. Please check and try again.', // prettier-ignore
  'auth/unauthorized-continue-uri': "You're not authorized to access the provided continue URL. Please check your permissions and try again.", // prettier-ignore
  'auth/invalid-oauth-provider': 'The provided OAuth provider is invalid. Please check it and try again.', // prettier-ignore
  'auth/invalid-oauth-client-id': 'The provided OAuth client ID is invalid. Please check it and try again.', // prettier-ignore
  'auth/unauthorized-domain': "You're not authorized to access the provided domain. Please check your permissions and try again.", // prettier-ignore
  'auth/invalid-action-code': 'The provided action code is invalid. Please check it and try again.',
  'auth/weak-password': 'The password you provided is too weak. Please use a stronger password.',
  'auth/expired-action-code': 'The action code has expired. Please request a new one.',
  'auth/invalid-identifier': 'The provided identifier is invalid. Please check it and try again.',
  'auth/rejected-credential': 'The provided credential was rejected. Please check it and try again.', // prettier-ignore
  'auth/invalid-tenant-id': 'The provided tenant ID is invalid. Please check it and try again.',
  'auth/tenant-id-mismatch': "The provided tenant ID doesn't match our records. Please check and try again.", // prettier-ignore
  'auth/admin-restricted-operation': 'This operation is restricted to administrators. Please check your permissions and try again.', // prettier-ignore
  'auth/unverified-email': 'The email provided has not been verified. Please verify your email and try again.', // prettier-ignore
};
