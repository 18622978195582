import { Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
`;

export const Tip = styled.div`
  border: 1px solid ${tokens.primary.borderDefault};
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export const IconWrapper = styled.div`
  margin-right: 6px;

  svg {
    stroke-width: 1.5px;
    position: relative;
    top: 2px;
  }
`;

export const Title = styled(Typography)``;

export const Content = styled(Typography)``;
