import { Button, IconButton, styled } from '@ai21/studio-base-ui';

export const Wrapper = styled('div')`
  flex: 1;

  &.active {
    .main-button {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  // flex :hover parent
  .main-button {
    opacity: 0;
    pointer-events: none;
  }
`;

export const MenuIcon = styled(IconButton)`
  padding: 6px;
  margin: 0;
  height: 30px;
  width: 30px;

  svg {
    height: 18px;
    width: 18px;
    fill: #626b78;
  }
`;

export const Gap = styled('div')`
  width: 10px;
`;

export const MainAction = styled(Button)`
  white-space: nowrap;

  &.isHidden {
    opacity: 0;
  }
`;
