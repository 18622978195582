import { UserRole } from './Organization';
import { TierType } from './Tier';

export enum UserStatus {
  INITIAL = 'INITIAL',
  VERIFIED = 'VERIFIED',
  REVOKED = 'REVOKED',
  PENDING = 'PENDING',
  NOT_LOADED = 'NOT_LOADED',
}

export interface UserEndpointResponse {
  apiKey: string;
  customerId: string;
  featureFlags: { [key: string]: string };
  organizationId: string;
  organizationName: string;
  subscriptionCancellationDate: string | null;
  subscriptionExpirationDate: string | null;
  userEmail?: string;
  userId: string;
  userName: string;
  userRole: UserRole;
  userStatus: UserStatus;
  userTier: TierType;
}
export interface FeedbackSubmittedPayload {
  origin: string;
  reason: string;
  type: string;
  interactionId: string;
  questionText: string;
  answer: string[] | null;
  score: number;
  model: string;
  promptLength: number;
  completionLength: number;
  endpoint: string;
}
export interface FeedbackShownPayload {
  origin: string;
  interactionId: string;
  model: string;
  promptLength: number;
  completionLength: number;
}
export interface MessageShownPayload {
  origin: string;
  type: string;
  text: string;
  name: string;
}
export interface MassageDismissedPayload {
  origin: string;
  type: string;
  reason: string;
  name: string;
  text: string;
}

export interface ModalShownPayload {
  origin: string;
  text: string;
  name: string;
}
export interface ModalDismissedPayload {
  origin: string;
  reason: string;
  name: string;
}

export interface FeedbackDismissedPayload {
  origin: string;
  interactionId: string;
  reason: string;
  type: string;
}
export interface FeedbackDeactivatedPayload {
  origin: string;
  deactivatePeriod: string;
  interactionId: string | null;
}

export interface FeedbackActivatedPayload {
  origin: string;
  activatePeriod: string;
}
export interface DocsOpenedPayload {
  title: string;
  destination: string;
  description: string;
}
