import { Icon } from '@ai21/studio-base-ui';
import { IDocumentQuestion } from '@ai21/studio-store';
import classNames from 'classnames';
import AskLineBase from '../AskLineBase/AskLineBase';
import { IconWrapper, Link, P, Source, Wrapper } from './AskLineEmpty.style';
import { Verb } from '../../AskEditor.types';

export type AskLineEmptyProps = {
  item: IDocumentQuestion;
  fileName: string;
  callbacks: {
    onAction: (verb: Verb, params?: Json) => void;
    askAllLibrary: () => void;
  };
};

export function AskLineEmpty(props: AskLineEmptyProps) {
  const { fileName, callbacks } = props;
  function uploadFiles() {
    callbacks.onAction('uploadFiles');
  }

  function renderSources() {
    if (fileName === '') {
      return (
        <Source className='suggest-to-upload'>
          <IconWrapper>
            <Icon iconName='ExternalLink' />
          </IconWrapper>
          <Link onClick={uploadFiles}>Upload files</Link>
          that contain the content of this question's answer and try again.
        </Source>
      );
    } else {
      return (
        <Source className='suggest-to-upload'>
          <IconWrapper>
            <Icon iconName='ExternalLink' />
          </IconWrapper>
          <Link onClick={callbacks.askAllLibrary}>Ask all your library</Link>
          or
          <Link onClick={uploadFiles}>Upload files</Link>
          that contain the content.
        </Source>
      );
    }
  }

  const className = classNames('AskLineEmpty-wrapper');

  return (
    <Wrapper className={className} data-testid='AskLineEmpty-wrapper'>
      <AskLineBase {...props} forcedAnswer='Answer not in documents.'>
        {renderSources()}
      </AskLineBase>
    </Wrapper>
  );
}

export default AskLineEmpty;
