import { createSelector } from 'reselect';
import { IStudioStore } from '../types';
import * as raw from './selectors.raw';
import * as playground from './selectors.playground';
import { get } from 'lodash';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $fts = createSelector(
  [raw.$rawFineTuningSets, (_state, id) => id],
  (sets, id: number) => {
    return sets[id];
  }
);

export const $preset = createSelector(
  [raw.$rawPresets, (_state, id) => id],
  (presets, id: number) => {
    return presets[id];
  }
);

export const $taskSpecificApi = createSelector(
  [raw.$rawApis, (_state, id) => id],
  (apis, id: number) => {
    return apis[id];
  }
);

export const $evaluationJob = createSelector(
  [raw.$rawEvaluationJobs, (_state, id) => id],
  (jobs, id: number) => {
    return jobs[id];
  }
);

export const $evaluationLines = createSelector(
  [raw.$rawGenerationLines, (_state, setId) => setId],
  (lines, setId: string) => {
    return Object.values(lines).filter((line) => line.setId === setId);
  }
);

export const $evaluationLine = createSelector(
  [raw.$rawEvaluationLines, (_state, id) => id],
  (lines, id: number) => {
    return lines[id];
  }
);

export const $generationJob = createSelector(
  [raw.$rawGenerationJobs, (_state, id) => id],
  (jobs, id: number) => {
    return jobs[id];
  }
);

export const $generationLine = createSelector(
  [raw.$rawGenerationLines, (_state, id) => id],
  (lines, id: number) => {
    return lines[id];
  }
);

export const $generationLines = createSelector(
  [raw.$rawGenerationLines, (_state, setId) => setId],
  (lines, setId: string) => {
    return Object.values(lines).filter((line) => line.setId === setId);
  }
);

export const $demo = createSelector([raw.$rawDemos, (_state, id) => id], (demos, id: number) => {
  return demos[id];
});

export const $collectionJob = createSelector(
  [raw.$rawCollectionJobs, (_state, id) => id],
  (jobs, id: number) => {
    return jobs[id];
  }
);

export const $collectionLine = createSelector(
  [raw.$rawCollectionLines, (_state, id) => id],
  (lines, id: number) => {
    return lines[id];
  }
);

export const $collectionLines = createSelector(
  [raw.$rawCollectionLines, (_state, setId) => setId],
  (lines, setId: string) => {
    return Object.values(lines).filter((line) => line.setId === setId && !line.isDeleted);
  }
);

export const $document = createSelector(
  [raw.$rawDocuments, (_state, id) => id],
  (docs, id: number) => {
    return docs[id];
  }
);

export const $model = createSelector([raw.$rawModels, (_state, id) => id], (models, id: number) => {
  return models[id];
});

export const $box = createSelector([raw.$rawBoxes, (_state, id) => id], (boxes, id: number) => {
  return boxes[id];
});

export const $pa = createSelector([raw.$rawPas, (_state, id) => id], (pas, id: number) => {
  return pas[id];
});

export const $outputBox = createSelector(
  [playground.$outputBoxes, (_state, id) => id],
  (boxes, id: string) => {
    return boxes.find((box) => get(box, 'values.outputId') === id);
  }
);

export const $boxByType = createSelector(
  [playground.$outputBoxes, (_state, id) => id],
  (boxes, id: string) => {
    return boxes.find((box) => box.id.endsWith(id));
  }
);
