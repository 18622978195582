import { IModels, ModelStatus, Tier } from './types';

const data = {
  ux: {
    texts: {
      deprecatedModel:
        'The Jurassic family models, our legacy foundation models are now deprecated. Try our new Jamba model.',
      foundationModel:
        'The aligned version of the novel Jamba SSM-Transformer architecture. Fine-tuned and ready for implementation, the model delivers remarkable throughput and efficiency on core enterprise use cases.',
    },
  },
};

export const models: IModels = {
  'jamba-instruct-preview': {
    id: 'jamba-instruct-preview',
    name: 'jamba-instruct-preview',
    title: 'Jamba-Instruct in Preview',
    groupName: 'Foundation models',
    familyName: 'Jamba',
    description: data.ux.texts.foundationModel,
    iconName: 'overview.J2',
    tier: Tier.FREE,
    status: ModelStatus.ACTIVE,
    size: '',
    modelType: 'jamba-instruct-preview',
    datasetId: 0,
    datasetName: '',
    modelMetadata: {
      numEpochs: 0,
      learningRate: 0.01,
      defaultEpoch: 0,
      epochs: {},
      cost: 0,
      evalLoss: 0,
    },
    creationDate: '',
    customModelType: '',
    isFoundationModel: true,
    isInstruct: true,
    isUserModel: false,
    order: 3,
    orderCatalogue: 1,
    layoutId: 'chat',
    isDeprecated: false,
    docsUrl: 'https://lp.ai21.com/jamba-instruct',
    isBeta: true,
  },
  'jamba-1.5-large': {
    id: 'jamba-1.5-large',
    name: 'jamba-1.5-large',
    title: 'Jamba 1.5 Large in Preview',
    groupName: 'Foundation models',
    familyName: 'Jamba',
    description: data.ux.texts.foundationModel,
    iconName: 'overview.J2',
    tier: Tier.FREE,
    status: ModelStatus.ACTIVE,
    size: '',
    modelType: 'jamba-1.5-large',
    datasetId: 0,
    datasetName: '',
    modelMetadata: {
      numEpochs: 0,
      learningRate: 0.01,
      defaultEpoch: 0,
      epochs: {},
      cost: 0,
      evalLoss: 0,
    },
    creationDate: '',
    customModelType: '',
    isFoundationModel: true,
    isInstruct: true,
    isUserModel: false,
    order: 3,
    orderCatalogue: 1,
    layoutId: 'chat',
    isDeprecated: false,
    docsUrl: 'https://docs.ai21.com/reference/jamba-15-api-ref',
  },
  'jamba-1.5-mini': {
    id: 'jamba-1.5-mini',
    name: 'jamba-1.5-mini',
    title: 'Jamba 1.5 Mini in Preview',
    groupName: 'Foundation models',
    familyName: 'Jamba',
    description: data.ux.texts.foundationModel,
    iconName: 'overview.J2',
    tier: Tier.FREE,
    status: ModelStatus.ACTIVE,
    size: '',
    modelType: 'jamba-1.5-mini',
    datasetId: 0,
    datasetName: '',
    modelMetadata: {
      numEpochs: 0,
      learningRate: 0.01,
      defaultEpoch: 0,
      epochs: {},
      cost: 0,
      evalLoss: 0,
    },
    creationDate: '',
    customModelType: '',
    isFoundationModel: true,
    isInstruct: true,
    isUserModel: false,
    order: 3,
    orderCatalogue: 1,
    layoutId: 'chat',
    isDeprecated: false,
    docsUrl: 'https://docs.ai21.com/reference/jamba-15-api-ref',
  },
  'j2-ultra': {
    id: 'j2-ultra',
    name: 'j2-ultra',
    title: 'Jurassic 2 - Ultra',
    groupName: 'Deprecated Foundation models',
    familyName: 'J2',
    description: data.ux.texts.deprecatedModel,
    iconName: 'overview.J2',
    tier: Tier.FREE,
    status: ModelStatus.ACTIVE,
    size: '',
    modelType: 'j2-ultra',
    datasetId: 0,
    datasetName: '',
    modelMetadata: {
      numEpochs: 0,
      learningRate: 0.01,
      defaultEpoch: 0,
      epochs: {},
      cost: 0,
      evalLoss: 0,
    },
    creationDate: '',
    customModelType: '',
    isFoundationModel: true,
    isUserModel: false,
    order: 3,
    orderCatalogue: 1,
    layoutId: 'complete',
    isDeprecated: true,
  },
  'j2-mid': {
    id: 'j2-mid',
    name: 'j2-mid',
    title: 'Jurassic 2 - Mid',
    groupName: 'Deprecated Foundation models',
    familyName: 'J2',
    description: data.ux.texts.deprecatedModel,
    iconName: 'overview.J2',
    tier: Tier.FREE,
    status: ModelStatus.ACTIVE,
    size: '',
    modelType: 'j2-mid',
    datasetId: 0,
    datasetName: '',
    modelMetadata: {
      defaultEpoch: 0,
      epochs: {},
      numEpochs: 0,
      learningRate: 0.03,
      cost: 0,
      evalLoss: 0,
    },
    creationDate: '',
    customModelType: '',
    isUserModel: false,
    isFoundationModel: true,
    order: 4,
    orderCatalogue: 3,
    layoutId: 'complete',
    isDeprecated: true,
  },
  'j2-light': {
    id: 'j2-light',
    name: 'j2-light',
    title: 'Jurassic 2 - Light',
    groupName: 'Deprecated Foundation models',
    familyName: 'J2',
    description: data.ux.texts.deprecatedModel,
    iconName: 'overview.J2',
    tier: Tier.FREE,
    status: ModelStatus.ACTIVE,
    size: '',
    modelType: 'j2-light',
    datasetId: 0,
    datasetName: '',
    modelMetadata: {
      numEpochs: 0,
      defaultEpoch: 0,
      epochs: {},
      learningRate: 0.3,
      cost: 0,
      evalLoss: 0,
    },
    creationDate: '',
    customModelType: '',
    isUserModel: false,
    isFoundationModel: true,
    orderCatalogue: 5,
    order: 5,
    layoutId: 'complete',
    isDeprecated: true,
  },
  chat: {
    id: 'chat',
    name: 'chat',
    title: 'Chat',
    groupName: 'Deprecated Foundation models',
    familyName: 'J2',
    description: data.ux.texts.deprecatedModel,
    iconName: 'overview.J2',
    tier: Tier.FREE,
    status: ModelStatus.ACTIVE,
    size: '',
    modelType: 'j2-light',
    datasetId: 0,
    datasetName: '',
    modelMetadata: {
      numEpochs: 0,
      defaultEpoch: 0,
      epochs: {},
      learningRate: 0.3,
      cost: 0,
      evalLoss: 0,
    },
    creationDate: '',
    customModelType: '',
    isUserModel: false,
    isFoundationModel: true,
    orderCatalogue: 6,
    order: 6,
    layoutId: 'chat',
    isDeprecated: true,
  },
};
