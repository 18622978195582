import { useEffect } from 'react';

type Callback = (ev?: KeyboardEvent) => void;

export function useEscape(
  callback: Callback,
  enabled: boolean,
  depArray: any[] = []
) {
  useEffect(() => {
    const onKeyPress = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        callback(ev);
      }
    };

    if (enabled) {
      document.addEventListener('keyup', onKeyPress);
    }

    return () => {
      document.removeEventListener('keyup', onKeyPress);
    };
  }, depArray);
}
