import { CSSProperties } from 'react';

interface InfoLiteIconProps {
  style?: CSSProperties;
}

export function InfoLiteIcon({ style }: InfoLiteIconProps) {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='currentColor'
      style={style}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5.73 4.396h1.04v1.042H5.73V4.396zm0 2.083h1.04v3.125H5.73V6.48zm.52-4.687A5.21 5.21 0 0 0 1.042 7 5.21 5.21 0 0 0 6.25 12.21 5.21 5.21 0 0 0 11.458 7 5.21 5.21 0 0 0 6.25 1.792zm0 9.375A4.172 4.172 0 0 1 2.083 7 4.172 4.172 0 0 1 6.25 2.834 4.172 4.172 0 0 1 10.417 7a4.172 4.172 0 0 1-4.167 4.167z' />
    </svg>
  );
}
