import { Icon } from '@ai21/studio-base-ui';
import classnames from 'classnames';
import Draggable from 'react-draggable';
import {
  Action,
  Checkmark,
  Close,
  Content,
  Count,
  Dot,
  Header,
  History,
  Index,
  Item,
  ItemCheckmark,
  ItemCount,
  ItemTitle,
  Items,
  LastEvent,
  List,
  Pre,
  Title,
  Wrapper,
} from './EventLogs.style';
import { Event, EventLog } from './EventLogs.types';

export type EventLogsProps = {
  items: EventLog[];
  allEvents: Event[];
  lastEvent?: Event;
  clearEvents: () => void;
  toggleShow: () => void;
  toggleMinimize: () => void;
  showHistory: (id: string) => void;
  setLastEvent: (event: Event) => void;
  downloadEvents: () => void;
  compact?: boolean;
  isMinimized?: boolean;
};

export function EventLogs(props: EventLogsProps) {
  const { items, allEvents = [], lastEvent, compact, isMinimized } = props;

  function renderItem(item: EventLog, index: number) {
    const { title, count } = item;

    const className = classnames('log-item', {
      focused: lastEvent?.eventType === item.id,
    });

    const classNameCheckmark = classnames({
      done: count > 0,
    });

    return (
      <Item key={item.id} onClick={() => props.showHistory(item.id)} className={className}>
        <Index>{index + 1}</Index>
        <ItemTitle>{title}</ItemTitle>
        <ItemCount>
          <Count>{count}</Count>
        </ItemCount>
        <ItemCheckmark className={classNameCheckmark}>
          <Checkmark>
            <Icon iconName='Check' />
          </Checkmark>
        </ItemCheckmark>
      </Item>
    );
  }

  function renderItems() {
    return items.map((item: EventLog, index) => renderItem(item, index));
  }

  function renderHistoryItem(item: Event, index: number) {
    const className = classnames('item', item.eventType);

    return <Dot key={index} className={className} onMouseOver={() => props.setLastEvent(item)} />;
  }

  function renderHistoryItems() {
    return allEvents.map((item, index) => renderHistoryItem(item, index));
  }

  function renderInner() {
    const className = classnames('EventLogs-wrapper', {
      compact,
      minimized: isMinimized,
    });

    return (
      <Wrapper className={className} data-testid='EventLogs-wrapper'>
        <LastEvent className='lastEvent'>
          <Pre>{JSON.stringify(lastEvent ?? {}, null, 1)}</Pre>
          <Action onClick={props.downloadEvents}>Download</Action>
          <Action onClick={props.clearEvents}>Clear</Action>
        </LastEvent>
        <List>
          <Header className='draggable-handle'>
            <Title className='title'>Event Logs</Title>
            <Close onClick={() => props.toggleMinimize()}>
              <Icon iconName='Maximize' />
            </Close>
            <Close onClick={() => props.toggleShow()}>
              <Icon iconName='Close' />
            </Close>
          </Header>
          <Content className='content'>
            <Items>{renderItems()}</Items>
            <History>{renderHistoryItems()}</History>
          </Content>
        </List>
      </Wrapper>
    );
  }

  return <Draggable handle='.draggable-handle'>{renderInner()}</Draggable>;
}

export default EventLogs;
