export function StatusFailedIcon() {
  return (
    <svg
      width='12'
      height='13'
      viewBox='0 0 12 13'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.79393 3.19824C5.03661 2.76262 5.49623 2.49243 5.9951 2.49243C6.49396 2.49243 6.95358 2.76262 7.19626 3.19823L10.7445 9.32026C10.7534 9.3355 10.7611 9.35135 10.7677 9.36767C10.9386 9.78993 10.8892 10.2694 10.6359 10.648C10.3826 11.0265 9.95822 11.2551 9.50273 11.2583L9.5001 11.2583H2.5001C2.4909 11.2583 2.48178 11.258 2.47275 11.2573C2.45732 11.2584 2.44165 11.2586 2.42579 11.2578C1.98135 11.2349 1.57548 10.9983 1.33655 10.6228C1.09762 10.2474 1.05519 9.7795 1.22267 9.36719C1.22923 9.35103 1.23691 9.33535 1.24565 9.32026L4.79393 3.19824ZM2.4725 10.5093C2.48161 10.5086 2.49082 10.5083 2.5001 10.5083H9.49861C9.70522 10.5065 9.89761 10.4027 10.0125 10.2309C10.1233 10.0653 10.1483 9.85726 10.0808 9.67078L6.54565 3.57135L6.54197 3.56485C6.4319 3.36591 6.22247 3.24243 5.9951 3.24243C5.76773 3.24243 5.5583 3.36591 5.44822 3.56485L5.44454 3.57135L1.90922 9.67103C1.84301 9.85305 1.86485 10.056 1.9693 10.2202C2.0779 10.3908 2.26239 10.4984 2.46441 10.5088C2.46711 10.5089 2.46981 10.5091 2.4725 10.5093ZM6.0001 5.00831C6.20721 5.00831 6.3751 5.1762 6.3751 5.38331V7.88331C6.3751 8.09042 6.20721 8.25831 6.0001 8.25831C5.79299 8.25831 5.6251 8.09042 5.6251 7.88331V5.38331C5.6251 5.1762 5.79299 5.00831 6.0001 5.00831ZM5.6251 9.38331C5.6251 9.1762 5.79299 9.00831 6.0001 9.00831H6.0051C6.21221 9.00831 6.3801 9.1762 6.3801 9.38331C6.3801 9.59042 6.21221 9.75831 6.0051 9.75831H6.0001C5.79299 9.75831 5.6251 9.59042 5.6251 9.38331Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.79393 3.19824C5.03661 2.76262 5.49623 2.49243 5.9951 2.49243C6.49396 2.49243 6.95358 2.76262 7.19626 3.19823L10.7445 9.32026C10.7534 9.3355 10.7611 9.35135 10.7677 9.36767C10.9386 9.78993 10.8892 10.2694 10.6359 10.648C10.3826 11.0265 9.95822 11.2551 9.50273 11.2583L9.5001 11.2583H2.5001C2.4909 11.2583 2.48178 11.258 2.47275 11.2573C2.45732 11.2584 2.44165 11.2586 2.42579 11.2578C1.98135 11.2349 1.57548 10.9983 1.33655 10.6228C1.09762 10.2474 1.05519 9.7795 1.22267 9.36719C1.22923 9.35103 1.23691 9.33535 1.24565 9.32026L4.79393 3.19824ZM2.4725 10.5093C2.48161 10.5086 2.49082 10.5083 2.5001 10.5083H9.49861C9.70522 10.5065 9.89761 10.4027 10.0125 10.2309C10.1233 10.0653 10.1483 9.85726 10.0808 9.67078L6.54565 3.57135L6.54197 3.56485C6.4319 3.36591 6.22247 3.24243 5.9951 3.24243C5.76773 3.24243 5.5583 3.36591 5.44822 3.56485L5.44454 3.57135L1.90922 9.67103C1.84301 9.85305 1.86485 10.056 1.9693 10.2202C2.0779 10.3908 2.26239 10.4984 2.46441 10.5088C2.46711 10.5089 2.46981 10.5091 2.4725 10.5093ZM6.0001 5.00831C6.20721 5.00831 6.3751 5.1762 6.3751 5.38331V7.88331C6.3751 8.09042 6.20721 8.25831 6.0001 8.25831C5.79299 8.25831 5.6251 8.09042 5.6251 7.88331V5.38331C5.6251 5.1762 5.79299 5.00831 6.0001 5.00831ZM5.6251 9.38331C5.6251 9.1762 5.79299 9.00831 6.0001 9.00831H6.0051C6.21221 9.00831 6.3801 9.1762 6.3801 9.38331C6.3801 9.59042 6.21221 9.75831 6.0051 9.75831H6.0001C5.79299 9.75831 5.6251 9.59042 5.6251 9.38331Z'
        fill='black'
        fillOpacity='0.8'
      />
    </svg>
  );
}
