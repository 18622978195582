import type { IMenuItem } from '@ai21/studio-base-ui';
import { Button, Icon, IconButton, UserButton, icons } from '@ai21/studio-base-ui';
import classnames from 'classnames';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { INotification } from '../../types';
import { AccountMenu } from '../AccountMenu/AccountMenu';
import {
  BreadcrumbsWrapper,
  Home,
  Item,
  ItemBadge,
  ItemText,
  Items,
  MenuToggle,
  Navigation,
  NavigationItems,
  Toolbar,
  Wrapper,
} from './TopBar.style';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';

export type TopBarProps = {
  userName?: string;
  userEmail?: string;
  isSlim?: boolean;
  items: IMenuItem[];
  notifications: INotification[];
  callbacks: {
    onHome: () => void;
    onClick: (item: IMenuItem) => void;
    onToggleSlim: (isSlim: boolean) => void;
  };
};

export function TopBar(props: TopBarProps) {
  const { userName, userEmail, items, isSlim, callbacks } = props;
  const [anchor, setAnchor] = useState<null | Element>(null);
  const location = useLocation();

  const accountItems = useMemo(() => {
    return items.filter((item) => item.groupId === 'menu' && !item.isHidden);
  }, [items]);

  function renderItem(item: IMenuItem) {
    const { title, path, iconName, badge } = item;

    const className = classnames('item', {
      on: path && location.pathname.includes(path),
    });

    return (
      <Item
        key={item.id}
        data-testid={'top-menu-' + item.id}
        className={className}
        color={path && location.pathname.includes(path) ? 'primary' : 'secondary'}
        onClick={() => callbacks.onClick(item)}
        size='medium'
        startIcon={iconName && <Icon iconName={iconName} />}
        data-href={item.path}
      >
        <ItemText>{title}</ItemText>
        {badge && (
          <ItemBadge className={badge} color='default'>
            {badge}
          </ItemBadge>
        )}
      </Item>
    );
  }

  function renderItems() {
    return items
      .filter((item) => !item.isHidden)
      .filter((item) => item.groupId === 'top')
      .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))
      .map((item: IMenuItem) => renderItem(item));
  }

  function renderBreadcrumbs() {
    return <Breadcrumbs defaultRoute='/home' titleMap={{ Home: 'Home' }} />;
  }

  function renderMenuToggle() {
    if (location.pathname === '/form') {
      return null;
    }

    return (
      <MenuToggle
        variant='outlined'
        color='secondary'
        onClick={() => callbacks.onToggleSlim(!isSlim)}
      >
        <Icon iconName={isSlim ? 'Menu2' : 'ChevronsLeft'} />
      </MenuToggle>
    );
  }

  return (
    <Wrapper className='TopBar-wrapper' data-testid='TopBar-wrapper'>
      <Toolbar>
        <Home
          aria-label='Logo'
          className='Logo-wrapper'
          data-testid='btn-topbar-logo'
          onClick={callbacks.onHome}
        >
          <Icon iconName='Logo' />
        </Home>
        <Navigation>
          <BreadcrumbsWrapper>{renderBreadcrumbs()}</BreadcrumbsWrapper>
          <NavigationItems>
            <Items className='items'>{renderItems()}</Items>
            <UserButton onClick={(ev?: any) => setAnchor(ev.currentTarget)} />
            <AccountMenu
              userEmail={userEmail}
              items={accountItems} //
              anchor={anchor}
              onClick={(item) => {
                setAnchor(null);
                callbacks.onClick(item);
              }}
              onClose={() => setAnchor(null)}
            />
          </NavigationItems>
        </Navigation>
      </Toolbar>
    </Wrapper>
  );
}

export default TopBar;
