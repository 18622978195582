import { IApiTokens } from './types';

export const tokens: IApiTokens = {
  firebaseIdToken: '',
  studioApiKey: '',
};

export let apiUrl: string = '',
  organizationId: string = 'org1',
  userId: string = 'user1';

export const setTokens = (value: Partial<IApiTokens>) => {
  Object.assign(tokens, value);
};

export const setApiUrl = (value: string) => {
  apiUrl = value;
};

export const setOrganizationId = (value: string) => {
  if (!value) {
    return;
  }

  organizationId = value;
};

export const setUserId = (value: string) => {
  if (!value) {
    return;
  }

  userId = value;
};
