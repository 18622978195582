import { fireRequest } from './axios';
import { PayloadOrganizationPatch } from './types';

export const get = () => {
  return fireRequest<Json>({
    path: '/organization',
    endpoint: 'organization.get',
  });
};

export const patch = (payload: PayloadOrganizationPatch) => {
  return fireRequest<Json>({
    path: '/organization',
    method: 'post',
    data: payload,
    endpoint: 'organization.patch',
  });
};

export const getByInvite = (inviteId: string) => {
  return fireRequest<Json>({
    path: `/organization/invite/${inviteId}`,
    endpoint: 'organization.getByInvite',
  });
};

export const sendInvite = (email: string) => {
  return fireRequest<Json>({
    method: 'post',
    path: '/organization/invite',
    data: {
      email,
    },
    endpoint: 'organization.sendInvite',
  });
};

export const organization = {
  get,
  patch,
  getByInvite,
  sendInvite,
};
