import { Alert, AlertTitle } from '@mui/material';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';
import { IconButtonDriver } from '../IconButton/IconButton.driver';
import IconButton from '../IconButton/IconButton';

export const Wrapper = styled(Alert)`
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0;
  height: 46px;

  .MuiAlert-icon {
  }

  .MuiAlert-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    > button {
      margin: 0 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    > div {
      :first-child {
        flex: 1;
      }
    }
  }
`;

export const Title = styled(AlertTitle)`
  margin-bottom: 0;
  margin-top: 0;
`;

export const Close = styled(IconButton)``;
