export function PlaygroundIcon() {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='48' height='48' rx='2.49351' fill='#FDEBF1' />
      <g filter='url(#filter0_ddd_3_2552)'>
        <path
          d='M37.1584 23.9772C37.1584 24.4309 36.7907 24.7986 36.337 24.7986H26.7502C26.2966 24.7986 25.9288 25.1664 25.9288 25.62V35.2068C25.9288 35.6605 25.5611 36.0282 25.1074 36.0282H23.007C22.5534 36.0282 22.1856 35.6605 22.1856 35.2068V25.62C22.1856 25.1664 21.8179 24.7986 21.3642 24.7986H11.7774C11.3238 24.7986 10.9561 24.4309 10.9561 23.9772V21.8768C10.9561 21.4232 11.3238 21.0554 11.7774 21.0554H21.3642C21.8179 21.0554 22.1856 20.6877 22.1856 20.2341V10.6473C22.1856 10.1936 22.5534 9.82587 23.007 9.82587H25.1074C25.5611 9.82587 25.9288 10.1936 25.9288 10.6473V20.2341C25.9288 20.6877 26.2966 21.0554 26.7502 21.0554H36.337C36.7907 21.0554 37.1584 21.4232 37.1584 21.8768V23.9772Z'
          fill='#D21B59'
        />
      </g>
      <defs>
        <filter
          id='filter0_ddd_3_2552'
          x='1.92076'
          y='1.6941'
          width='44.273'
          height='44.2729'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='0.903529' />
          <feGaussianBlur stdDeviation='4.51765' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3_2552' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3.61412' />
          <feGaussianBlur stdDeviation='2.25882' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_3_2552'
            result='effect2_dropShadow_3_2552'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='0.903529'
            operator='erode'
            in='SourceAlpha'
            result='effect3_dropShadow_3_2552'
          />
          <feOffset dy='1.80706' />
          <feGaussianBlur stdDeviation='1.80706' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend
            mode='normal'
            in2='effect2_dropShadow_3_2552'
            result='effect3_dropShadow_3_2552'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect3_dropShadow_3_2552'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
