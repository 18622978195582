import { api } from '@ai21/studio-api';

export class UploadQueue {
  private queue: any[] = [];
  private runningRequests = 0;
  private completedRequests = 0;
  private total = 0;
  private resolve: any;
  private onProgress?: OnProgress;
  private onResponse: OnResponse = () => {};

  private summary: IBatchSummary = {
    countTotal: 0,
    countCompleted: 0,
    countFailed: 0,
    responses: [],
    errors: [],
  };

  constructor(private maxRequests: number) {}

  addRequest(id: string, params: IUploadParams) {
    this.queue.push({ id, params });
  }

  async fireRequest(queueItem: any) {
    const { id, params } = queueItem;
    const { file, name, labels, path, publicUrl } = params;

    this.runningRequests++;

    let response: any;

    const uploadResponse: IUploadResponse = {
      id,
      docId: '',
      sizeBytes: file.size,

      // params
      name,
      labels,
      path,
      publicUrl,

      // transient
      isSuccess: false,
    };

    try {
      response = await api.documents.create({
        file,
        name,
        labels,
        path,
        publicUrl,
      });

      const docId = response.data as any;

      if (!response.isSuccess || !docId) {
        throw new Error(response.errorMessage);
      }

      uploadResponse.docId = docId;
      uploadResponse.isSuccess = true;
    } catch (err: any) {
      uploadResponse.isSuccess = false;
      uploadResponse.error = err.message;
    } finally {
      this.runningRequests--;
      this.completedRequests++;
    }

    this.summary.responses.push(uploadResponse);

    this.onResponse(id, uploadResponse);

    if (this.onProgress) {
      this.onProgress(this.completedRequests);
    }
  }

  prepareSummary() {
    const { responses } = this.summary;

    this.summary.countCompleted = responses.filter((r) => r.isSuccess).length;
    this.summary.countFailed = responses.filter((r) => !r.isSuccess).length;

    this.summary.errors = responses
      .filter((r) => !r.isSuccess)
      .map((response) => ({
        title: `${response.name} upload failed`,
        description: response.error,
      }));
  }

  done() {
    if (this.onProgress) {
      this.onProgress(this.total);
    }

    if (this.resolve) {
      this.prepareSummary();
      this.resolve(this.summary);
    }
  }

  process() {
    const availableSlots = this.maxRequests - this.runningRequests;

    if (availableSlots <= 0) {
      return;
    }

    const queueItems = this.queue.splice(0, availableSlots);

    queueItems.forEach((queueItem) => {
      this.fireRequest(queueItem);
    });

    if (this.queue.length === 0 && this.runningRequests === 0) {
      this.done();
      return;
    }

    setTimeout(() => {
      this.process();
    }, 1000);
  }

  start = (callbacks: Callbacks) => {
    this.summary.countTotal = this.queue.length;

    this.onResponse = callbacks.onResponse;
    this.onProgress = callbacks.onProgress;

    return new Promise<IBatchSummary>((resolve) => {
      this.total = this.queue.length;
      this.resolve = resolve;
      setTimeout(() => {
        this.process();
      }, 100);
    });
  };
}

type OnProgress = (completed: number) => void;
type OnResponse = (id: string, uploadResponse: IUploadResponse) => void;

type Callbacks = {
  onResponse: OnResponse;
  onProgress?: OnProgress;
};

export type IUploadParams = {
  file: File;
  name?: string;
  labels?: string[];
  path?: string;
  publicUrl?: string;
};

export type IUploadResponse = {
  id: string;
  docId: string;
  sizeBytes?: number;

  // params
  name?: string;
  labels?: string[];
  path?: string;
  publicUrl?: string;

  // transient
  isSuccess: boolean;
  error?: string;
};

export type IBatchError = {
  title: string;
  description?: string;
};

export type IBatchSummary = {
  countTotal: number;
  countCompleted: number;
  countFailed: number;
  responses: IUploadResponse[];
  errors: IBatchError[];
};
