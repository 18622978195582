import { Loader } from '@ai21/studio-base-ui';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { AskEditorContainer } from '../../containers/AskEditor.container';
import { Content, Wrapper } from './DemosFramePage.style';

export type DemosFramePageProps = {
  data: Json;
  isLoading: boolean;
  callbacks: {
    onClick: () => void;
    onClear: () => void;
  };
};

export function DemosFramePage(props: DemosFramePageProps) {
  const { callbacks, data, isLoading } = props;

  if (isLoading) {
    return <Loader full />;
  }

  return (
    <Wrapper>
      <Header data={data} onClear={callbacks.onClear} />
      <Content>
        <AskEditorContainer data={data} />
      </Content>
      <Footer data={data} />
    </Wrapper>
  );
}
