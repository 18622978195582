import { OrganizationTabs } from '../../constants/constants';
import {
  CANCEL_PLAN_DIALOG_OPEN,
  CURRENT_TAB,
  INVITE_USER_DIALOG_OPEN,
  MANAGE_PLAN_DIALOG_OPEN,
  OrganizationActionTypes,
} from '../actionTypes/organizationActionTypes';

export interface OrganizationState {
  inviteUserDialogOpen: boolean;
  currentTab: OrganizationTabs;
  managePlanDialogOpen: boolean;
  cancelPlanDialogOpen: boolean;
}

const initialState: OrganizationState = {
  inviteUserDialogOpen: false,
  currentTab: OrganizationTabs.ACCOUNT,
  managePlanDialogOpen: false,
  cancelPlanDialogOpen: false,
};

export function reducer(state = initialState, action: OrganizationActionTypes): OrganizationState {
  switch (action.type) {
    case INVITE_USER_DIALOG_OPEN:
      return { ...state, inviteUserDialogOpen: action.open };
    case MANAGE_PLAN_DIALOG_OPEN:
      return { ...state, managePlanDialogOpen: action.open };
    case CANCEL_PLAN_DIALOG_OPEN:
      return { ...state, cancelPlanDialogOpen: action.open };
    case CURRENT_TAB:
      return { ...state, currentTab: action.currentTab };
    default:
      return state;
  }
}
