import { Loader } from '@ai21/studio-ui';
import { useEffect } from 'react';
import { addListener } from 'shared-base';
import { Subtitle, Title, Wrapper } from './UploadProgress.style';

export type UploadProgressProps = {
  total: number;
  completed: number;
  onCancel: () => void;
};

export function UploadProgress(props: UploadProgressProps) {
  const { total, completed } = props;

  useEffect(() => {
    const unlisten = addListener('closeProgress', () => {
      props.onCancel();
    });

    return () => unlisten();
  }, []);

  const progress = total ? Math.ceil(completed / total) * 100 : '-';

  return (
    <Wrapper
      className='UploadProgress-wrapper'
      data-testid='UploadProgress-wrapper'
    >
      <Title variant='h6'>Uploading documents</Title>
      <Subtitle variant='body1'>
        Files {completed}/{total} ({progress}%)
      </Subtitle>
      <Loader />
    </Wrapper>
  );
}

export default UploadProgress;
