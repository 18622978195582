export function BasicTierIcon() {
  return (
    <svg width='50' height='34' viewBox='0 0 50 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='14.5' cy='17' r='12' fill='url(#paint0_radial_12:3652)' stroke='black' />
      <circle cx='40' cy='17' r='7.5' fill='#F1CDD9' stroke='black' />
      <circle cx='40' cy='17' r='3.5' fill='url(#paint1_radial_12:3652)' stroke='black' />
      <defs>
        <radialGradient
          id='paint0_radial_12:3652'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(5.75 12) rotate(26.565) scale(18.1681 19.4894)'
        >
          <stop stopColor='#F1CDD9' />
          <stop offset='0.55138' stopColor='#F47DA5' />
          <stop offset='1' stopColor='#E92064' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_12:3652'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(37.2 15.4) rotate(26.565) scale(5.81378 6.23659)'
        >
          <stop stopColor='#F1CDD9' />
          <stop offset='0.55138' stopColor='#F47DA5' />
          <stop offset='1' stopColor='#E92064' />
        </radialGradient>
      </defs>
    </svg>
  );
}
