import { createSelector } from 'reselect';
import { Ax, AxBadges, IStudioStore } from '../types';
import * as raw from './selectors.raw';
import { get } from 'lodash';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $engagementLevel = createSelector([$o, (_state, ax) => ax], (_o, ax: Ax) => {
  try {
    const { days } = ax;

    const daysVisited = Object.keys(days).filter((day) => days[day]).length;

    const numberOfCompletions = get(ax, 'playground.completions', 0);

    const numberOfCustoms =
      get(ax, 'generationSets.custom', 0) +
      get(ax, 'evaluationSets.custom', 0) +
      get(ax, 'documentLibrary.custom', 0);

    const numberOfTransformations =
      get(ax, 'generationSets.transformations', 0) + //
      get(ax, 'evaluationSets.transformations', 0) +
      get(ax, 'fts.transformations', 0);

    const ftsAndTraining =
      get(ax, 'fts.custom', 0) + //
      get(ax, 'fts.training', 0);

    const numberOfQuestions = get(ax, 'documentLibrary.questions', 0);

    const asker = numberOfQuestions > 4;
    const trainer = ftsAndTraining > 4;
    const taster = numberOfCompletions > 4;
    const transformer = numberOfTransformations > 4;
    const uploader = numberOfCustoms > 4;
    const king = asker && trainer && taster && transformer && uploader;

    const badges: AxBadges = {
      asker,
      trainer,
      taster,
      transformer,
      uploader,
      king,
    };

    let engagementLevel = 1;

    if (daysVisited > 4) {
      engagementLevel = 2;
      const badgesCount = Object.keys(badges).filter((badge) => (badges as any)[badge]).length;
      engagementLevel += badgesCount;
    }

    return {
      engagementLevel,
      badges,
    };
  } catch (error) {
    return null;
  }
});
