export function KeyIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='33' viewBox='0 0 32 33' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.0404 5.12623C26.4309 5.51675 26.4309 6.14992 26.0404 6.54044L25.4142 7.16665L27.3738 9.12623C27.7643 9.51675 27.7643 10.1499 27.3738 10.5404C26.9832 10.931 26.3501 10.931 25.9596 10.5404L24 8.58087L21.4142 11.1667L23.3738 13.1262C23.7643 13.5168 23.7643 14.1499 23.3738 14.5404C22.9832 14.931 22.3501 14.931 21.9596 14.5404L20 12.5809L15.7962 16.7846C16.5535 17.8282 17 19.112 17 20.5C17 23.9978 14.1645 26.8333 10.6667 26.8333C7.16886 26.8333 4.33333 23.9978 4.33333 20.5C4.33333 17.0022 7.16886 14.1667 10.6667 14.1667C12.0547 14.1667 13.3384 14.6132 14.382 15.3704L24.6262 5.12623C25.0167 4.7357 25.6499 4.7357 26.0404 5.12623ZM10.6667 16.1667C8.27343 16.1667 6.33333 18.1068 6.33333 20.5C6.33333 22.8932 8.27343 24.8333 10.6667 24.8333C13.0599 24.8333 15 22.8932 15 20.5C15 18.1068 13.0599 16.1667 10.6667 16.1667Z'
        fill='#1D1E22'
      />
    </svg>
  );
}
