import {
  Button,
  IconButton,
  styled,
  Typography,
  tokens,
  ButtonMenu,
} from '@ai21/studio-ui';

export const IconCta = styled(IconButton)`
  height: 36px;
  width: 36px;
  border: 1px solid ${tokens.primary.borderDefault};

  &:hover {
    background-color: ${tokens.primary.backgroundHover};
    border: 1px solid ${tokens.primary.borderHover};
  }

  svg {
    color: ${tokens.primary.mainDefault};
  }
`;
export const Cta = styled(Button)`
  svg {
    position: relative;
    top: 1px;
    left: 4px;
  }
`;

export const Wrapper = styled('div')`
  height: 515px;
  width: 1000px;
  .MuiDataGrid-pinnedColumns [data-field='actions'] {
    padding: 12px;
  }
`;

export const Subtitle = styled(Typography)`
  position: absolute;
  margin-top: -20px;
`;

export const Menu = styled(ButtonMenu)`
  .MuiButton-root {
    padding: 6px;
    height: 36px;
    margin-left: 8px;

    border: 1px solid ${tokens.primary.borderDefault};

    &:hover {
      background-color: ${tokens.primary.backgroundHover};
      border: 1px solid ${tokens.primary.borderHover};
    }

    svg {
      color: ${tokens.primary.mainDefault};
    }
  }
`;
