import classnames from 'classnames';
import { useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { breakpoints } from './Container.data';
import { Wrapper } from './Container.style';

export type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  row?: boolean;
};

export function Container(props: ContainerProps) {
  const { row } = props;

  const breakpoint = useCurrentBreakPoint();

  const className = classnames(
    'wrapper',
    props.className,
    '_' + breakpoint?.id,
    {
      row,
    }
  );

  return (
    <Wrapper className={className} data-testid='Container-wrapper'>
      {props.children}
    </Wrapper>
  );
}

// function ContainerInfo() {
//     return <WrapperInfo />;
// }

function useCurrentBreakPoint() {
  const { width: screenWidth } = useWindowSize();

  const breakpoint = useMemo(() => {
    return Object.values(breakpoints).find(
      (bp) =>
        bp.minWidth <= screenWidth &&
        (!bp.maxWidth || bp.maxWidth > screenWidth),
      [screenWidth]
    );
  }, [screenWidth]);

  return breakpoint;
}

export default Container;
