import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  padding: 24px 35px;
  font-size: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 2;
  padding: 6px 10px;

  &:nth-child(odd) {
    border-radius: ${tokens.primitives.radius.medium}px;
    background-color: ${tokens.disabled.background};
  }
`;

export const Key = styled.div`
  width: 120px;
  font-weight: 500;
`;

export const Value = styled.div``;
