import { IBoxes } from './types';

const isMac = navigator.userAgent.toLocaleLowerCase().indexOf('mac') >= 0;
const cmdKey = isMac ? '⌘' : 'Ctrl';

const complete1_1_model = window.__env__.VITE_COMPLETE1_1;
const complete2_1_model = window.__env__.VITE_COMPLETE2_1;
const complete2_2_model = window.__env__.VITE_COMPLETE2_2;

export const boxes: IBoxes = {
  'i-summarize-text-input': {
    id: 'i-summarize-text-input',
    values: {
      prompt: ``,
      flavour: 'text',
      placeholder: `Write text and click Generate (or press ${cmdKey}+Enter)`,
    },
    layoutId: 'summarize',
  },
  'i-summarize-url-input': {
    id: 'i-summarize-url-input',
    values: {
      prompt: ``,
      placeholder: `Enter long text and click Generate (${cmdKey}+Enter) to summarize it`,
      flavour: 'url',
    },
    layoutId: 'summarize',
  },
  'i-gec-input': {
    id: 'i-gec-input',
    values: {
      prompt: ``,
      placeholder: `Enter text and click Generate (${cmdKey}+Enter) for correction recommendations`,
    },
    layoutId: 'gec',
  },
  'i-segmentation-input': {
    id: 'i-segmentation-input',
    values: {
      prompt: ``,
      placeholder: `Write text and click Generate (or press ${cmdKey}+Enter)`,
    },
    layoutId: 'segmentation',
  },
  'i-paraphrase-input': {
    id: 'i-paraphrase-input',
    values: {
      prompt: ``,
      placeholder: `Enter text and click Generate (${cmdKey}+Enter) to paraphrase it`,
    },
    layoutId: 'paraphrase',
  },
  'i-textImprovements-input': {
    id: 'i-textImprovements-input',
    values: {
      prompt: ``,
      placeholder: `Enter text and click Generate (${cmdKey}+Enter) for improvement recommendations`,
    },
    layoutId: 'textImprovements',
  },
  'i-answer-context': {
    id: 'i-answer-context',
    values: {
      prompt: ``,
      placeholder: `Paste or type context text in here`,
      hideCtas: true,
    },
    layoutId: 'answer',
  },
  'i-answer-input': {
    id: 'i-answer-input',
    values: {
      prompt: '',
      placeholder: `Type question and click Generate (or press ${cmdKey}+Enter)`,
    },
    layoutId: 'answer',
  },
  'i-summarize-output': {
    id: 'i-summarize-output',
    values: {
      apiName: 'summarize',
      widgetId: 'summarization',
      content: '',
    },
    layoutId: 'summarize',
  },
  'i-gec-output': {
    id: 'i-gec-output',
    values: {
      apiName: 'gec',
      widgetId: 'gec',
      content: '',
      output: {
        text: '',
        replacements: [],
        errorMessage: '',
        isSuccess: true,
        originalInput: {
          main: '',
          secondary: '',
        },
      },
      placeholder: '',
      height: 200,
      themeId: 'playgroundPrimary',
    },
    layoutId: 'gec',
  },
  'i-paraphrase-output': {
    id: 'i-paraphrase-output',
    values: {
      apiName: 'paraphrase',
      widgetId: 'paraphrase',
      content: '',
    },
    layoutId: 'paraphrase',
  },
  'i-textImprovements-output': {
    id: 'i-textImprovements-output',
    values: {
      apiName: 'improvements',
      widgetId: 'improvements',
      content: '',
      themeId: 'playgroundPrimary',
    },
    layoutId: 'textImprovements',
  },
  'i-answer-output': {
    id: 'i-answer-output',
    values: {
      apiName: 'answer',
      widgetId: 'answer',
      content: '',
    },
    layoutId: 'answer',
  },
  'i-complete1-input': {
    id: 'i-complete1-input',
    values: {
      prompt: '',
      placeholder: `Write text and click Generate (or press ${cmdKey}+Enter)`,
    },
    layoutId: 'complete1',
  },
  'i-complete1-output': {
    id: 'i-complete1-output',
    values: {
      outputId: '1',
      modelId: complete1_1_model,
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1,
      hideGenerateCta: true,
    },
    layoutId: 'complete1',
  },
  'i-complete1-params': {
    id: 'i-complete1-params',
    values: {
      outputCount: 1,
      presetId: '',
      currentOutputId: '1',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1.0,
      presencePenalty: 0.0,
      frequencyPenalty: 0.0,
      countPenalty: 0.0,
      modelId: complete1_1_model,
      stopSequences: [],
    },
    layoutId: 'complete1',
  },
  'i-complete1-systemInstructions': {
    id: 'i-complete1-systemInstructions',
    values: {
      content: '',
    },
    layoutId: 'complete1',
  },
  'i-complete1-variables': {
    id: 'i-complete1-variables',
    values: {
      fields: [],
    },
    layoutId: 'complete1',
  },
  'i-complete1-optimize': {
    id: 'i-complete1-optimize',
    values: {
      suggestion: '',
    },
    layoutId: 'complete1',
  },
  'i-complete1-description': {
    id: 'i-complete1-description',
    values: {
      content: '',
    },
    layoutId: 'complete1',
  },
  'i-complete2-input': {
    id: 'i-complete2-input',
    values: {
      prompt: '',
      placeholder: `Write text and click Generate (or press ${cmdKey}+Enter)`,
    },
    layoutId: 'complete2',
  },
  'i-complete2-output1': {
    id: 'i-complete2-output1',
    values: {
      outputId: '1',
      modelId: complete2_1_model,
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1,
    },
    layoutId: 'complete2',
  },
  'i-complete2-output2': {
    id: 'i-complete2-output2',
    values: {
      outputId: '2',
      modelId: complete2_2_model,
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1,
    },
    layoutId: 'complete2',
  },
  'i-complete2-params': {
    id: 'i-complete2-params',
    values: {
      outputId: '3',
      outputCount: 2,
      presetId: '',
      currentOutputId: '1',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1.0,
      presencePenalty: 0.0,
      frequencyPenalty: 0.0,
      countPenalty: 0.0,
      modelId: complete2_1_model,
      stopSequences: [],
    },
    layoutId: 'complete2',
  },
  'i-complete2-systemInstructions': {
    id: 'i-complete2-systemInstructions',
    values: {
      content: '',
    },
    layoutId: 'complete2',
  },
  'i-complete2-variables': {
    id: 'i-complete2-variables',
    values: {
      fields: [],
    },
    layoutId: 'complete2',
  },
  'i-complete2-optimize': {
    id: 'i-complete2-optimize',
    values: {
      suggestion: '',
    },
    layoutId: 'complete2',
  },
  'i-complete2-description': {
    id: 'i-complete2-description',
    values: {
      content: '',
    },
    layoutId: 'complete2',
  },
  'i-complete3-input': {
    id: 'i-complete3-input',
    values: {
      prompt: '',
      placeholder: `Write text and click Generate (or press ${cmdKey}+Enter)`,
    },
    layoutId: 'complete3',
  },
  'i-complete3-output1': {
    id: 'i-complete3-output1',
    values: {
      outputId: '1',
      modelId: 'j2-light',
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1,
    },
    layoutId: 'complete3',
  },
  'i-complete3-output2': {
    id: 'i-complete3-output2',
    values: {
      outputId: '2',
      modelId: 'j2-mid',
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1,
    },
    layoutId: 'complete3',
  },
  'i-complete3-output3': {
    id: 'i-complete3-output3',
    values: {
      outputId: '3',
      modelId: 'j2-ultra',
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1,
    },
    layoutId: 'complete3',
  },
  'i-complete3-params': {
    id: 'i-complete3-params',
    values: {
      outputCount: 3,
      presetId: '',
      currentOutputId: '1',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1.0,
      presencePenalty: 0.0,
      frequencyPenalty: 0.0,
      countPenalty: 0.0,
      modelId: 'j2-mid',
      stopSequences: [],
    },
    layoutId: 'complete3',
  },
  'i-complete3-systemInstructions': {
    id: 'i-complete3-systemInstructions',
    values: {
      content: '',
    },
    layoutId: 'complete3',
  },
  'i-complete3-variables': {
    id: 'i-complete3-variables',
    values: {
      fields: [],
    },
    layoutId: 'complete3',
  },
  'i-complete3-optimize': {
    id: 'i-complete3-optimize',
    values: {
      suggestion: '',
    },
    layoutId: 'complete3',
  },
  'i-complete3-description': {
    id: 'i-complete3-description',
    values: {
      content: '',
    },
    layoutId: 'complete3',
  },
  'i-ape-input1': {
    id: 'i-ape-input1',
    values: {
      prompt:
        'write a prompt about creating a product descriptions for a large retail ecommerce website',
      hideOptimize: true,
      generateText: 'Generate prompt',
      generateVerb: 'generateApe',
    },
    layoutId: 'ape',
  },
  'i-ape-input2': {
    id: 'i-ape-input2',
    values: {
      prompt: '',
      hideOptimize: true,
      generateText: 'Generate Completion',
      generateVerb: 'generateApeCompletion',
    },
    layoutId: 'ape',
  },
  'i-ape-output1': {
    id: 'i-ape-output1',
    values: {
      modelId: 'j2-ultra',
      content: '',
    },
    layoutId: 'ape',
  },
  'i-ape-output2': {
    id: 'i-ape-output2',
    values: {
      modelId: 'j2-mid',
      content: '',
      showEvaluation: true,
    },
    layoutId: 'ape',
  },
  'i-ape-params': {
    id: 'i-ape-params',
    values: {
      maxTokens: 200,
      temperature: 0.7,
      topP: 1,
    },
    layoutId: 'ape',
  },
  'i-ape-ape': {
    id: 'i-ape-ape',
    values: {
      flavour: 'writePrompt',
      mainPaId: '8d29',
      secondaryPaId: '7f69',
      evaluationJobId: '0276',
    },
    layoutId: 'ape',
  },
  'i-ape-logger': {
    id: 'i-ape-logger',
    values: {
      logs: [],
    },
    layoutId: 'ape',
  },
  'i-summarize-type': {
    id: 'i-summarize-type',
    values: {
      sourceType: 'text',
    },
    layoutId: 'summarize',
  },
  'i-paraphrase-type': {
    id: 'i-paraphrase-type',
    values: {
      style: 'general',
    },
    layoutId: 'paraphrase',
  },
  'i-textImprovements-type': {
    id: 'i-textImprovements-type',
    values: {
      improvementTypes: [
        'fluency',
        'vocabulary/specificity',
        'vocabulary/variety',
        'clarity/short-sentences',
        'clarity/conciseness',
      ],
    },
    layoutId: 'textImprovements',
  },
  'i-chat1-params': {
    id: 'i-chat1-params',
    values: {
      hideSelectors: true,
      outputId: '1',
      modelId: 'jamba-instruct-preview',
      content: '',
      maxTokens: 1024,
      temperature: 0.7,
      topP: 1,
      stopSequences: [],
      presencePenalty: 0,
      frequencyPenalty: 0,
      countPenalty: 0,
    },
    layoutId: 'chat1',
  },
  'i-chat1-systemInstructions': {
    id: 'i-chat1-systemInstructions',
    values: {
      content: '',
      placeholderContent: 'You are a helpful, respectful and honest AI assistant…',
      label: 'What does the assistant need to know?',
      tooltipText: 'Enter context instruction',
    },
    layoutId: 'chat1',
  },
  'i-chat1-main': {
    id: 'i-chat1-main',
    values: {
      messages: [],
      modelId: 'jamba-instruct-preview',
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 0.9,
      stopSequences: [],
      presencePenalty: 0,
      frequencyPenalty: 0,
      countPenalty: 0,
    },
    layoutId: 'chat1',
  },
  'i-chat1-editor': {
    id: 'i-chat1-editor',
    values: {
      content: JSON.stringify([], null, 2),
    },
    layoutId: 'chat1',
  },
  'i-chatHistory-params': {
    id: 'i-chatHistory-params',
    values: {
      hideSelectors: true,
      outputId: '1',
      modelId: 'jamba-instruct-preview',
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 0.9,
      stopSequences: [],
      presencePenalty: 0,
      frequencyPenalty: 0,
      countPenalty: 0,
    },
    layoutId: 'chatHistory',
  },
  'i-chatHistory-systemInstructions': {
    id: 'i-chatHistory-systemInstructions',
    values: {
      content: 'You are a superstitious yiddish grandma',
    },
    layoutId: 'chatHistory',
  },
  'i-chatHistory-main': {
    id: 'i-chatHistory-main',
    values: {
      messages: [],
      modelId: 'j2-light',
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 0.9,
      stopSequences: [],
      presencePenalty: 0,
      frequencyPenalty: 0,
      countPenalty: 0,
      showModeButton: true,
    },
    layoutId: 'chatHistory',
  },
  'i-chatHistory-editor': {
    id: 'i-chatHistory-editor',
    values: {
      content: JSON.stringify([], null, 2),
      showModeButton: true,
    },
    layoutId: 'chatHistory',
  },
  'i-chatHistory-history': {
    id: 'i-chatHistory-history',
    values: {},
    layoutId: 'chatHistory',
  },
  'i-completeExp-input': {
    id: 'i-completeExp-input',
    values: {
      prompt: 'Write a poem about ancient rome',
      placeholder: `Write text and click Generate (or press ${cmdKey}+Enter)`,
    },
    layoutId: 'completeExp',
  },
  'i-completeExp-output': {
    id: 'i-completeExp-output',
    values: {
      outputId: '1',
      modelId: 'j2-light',
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1,
      hideGenerateCta: true,
    },
    layoutId: 'completeExp',
  },
  'i-completeExp-params': {
    id: 'i-completeExp-params',
    values: {
      outputCount: 1,
      presetId: '',
      currentOutputId: '1',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1.0,
      presencePenalty: 0.0,
      frequencyPenalty: 0.0,
      countPenalty: 0.0,
      modelId: 'j2-light',
      stopSequences: [],
    },
    layoutId: 'completeExp',
  },
  'i-completeExp-systemInstructions': {
    id: 'i-completeExp-systemInstructions',
    values: {
      content: '',
    },
    layoutId: 'completeExp',
  },
  'i-completeExp-variables': {
    id: 'i-completeExp-variables',
    values: {
      fields: [],
    },
    layoutId: 'completeExp',
  },
  'i-completeExp-optimize': {
    id: 'i-completeExp-optimize',
    values: {
      suggestion: '',
    },
    layoutId: 'completeExp',
  },
  'i-completeExp-description': {
    id: 'i-completeExp-description',
    values: {
      content: '',
    },
    layoutId: 'completeExp',
  },
  'i-completeExp-ape': {
    id: 'i-completeExp-ape',
    values: {
      content: '',
    },
    layoutId: 'completeExp',
  },
  'i-completeExp-collection': {
    id: 'i-completeExp-collection',
    values: {
      content: '',
    },
    layoutId: 'completeExp',
  },
  'i-complete1-collection': {
    id: 'i-complete1-collection',
    values: {
      content: '',
    },
    layoutId: 'complete1',
  },
  'i-complete2-collection': {
    id: 'i-complete2-collection',
    values: {
      content: '',
    },
    layoutId: 'complete2',
  },
  'i-complete3-collection': {
    id: 'i-complete3-collection',
    values: {
      content: '',
    },
    layoutId: 'complete3',
  },
  'i-completeAlt-input': {
    id: 'i-completeAlt-input',
    values: {
      prompt: 'Write a poem about ancient rome',
      placeholder: `Write text and click Generate (or press ${cmdKey}+Enter)`,
    },
    layoutId: 'completeAlt',
  },
  'i-completeAlt-output': {
    id: 'i-completeAlt-output',
    values: {
      outputId: '1',
      modelId: 'j2-light',
      content: '',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1,
      hideGenerateCta: true,
    },
    layoutId: 'completeAlt',
  },
  'i-completeAlt-params': {
    id: 'i-completeAlt-params',
    values: {
      outputCount: 1,
      presetId: '',
      currentOutputId: '1',
      maxTokens: 200,
      temperature: 0.7,
      topP: 1.0,
      presencePenalty: 0.0,
      frequencyPenalty: 0.0,
      countPenalty: 0.0,
      modelId: 'j2-light',
      stopSequences: [],
    },
    layoutId: 'completeAlt',
  },
  'i-completeAlt-systemInstructions': {
    id: 'i-completeAlt-systemInstructions',
    values: {
      content: '',
    },
    layoutId: 'completeAlt',
  },
  'i-completeAlt-variables': {
    id: 'i-completeAlt-variables',
    values: {
      fields: [],
    },
    layoutId: 'completeExp',
  },
  'i-completeAlt-optimize': {
    id: 'i-completeAlt-optimize',
    values: {
      suggestion: '',
    },
    layoutId: 'completeAlt',
  },
  'i-completeAlt-description': {
    id: 'i-completeAlt-description',
    values: {
      content: '',
    },
    layoutId: 'completeAlt',
  },
  'i-completeAlt-ape': {
    id: 'i-completeAlt-ape',
    values: {
      content: '',
    },
    layoutId: 'completeAlt',
  },
  'i-completeAlt-collection': {
    id: 'i-completeAlt-collection',
    values: {
      content: '',
    },
    layoutId: 'completeAlt',
  },
};
