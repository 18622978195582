import { Icon } from '@ai21/studio-ui';
import { Instructions, Text, Wrapper } from './DemosNoApiKeyPage.style';

export type DemosNoApiKeyPageProps = {};

export function DemosNoApiKeyPage(props: DemosNoApiKeyPageProps) {
  return (
    <Wrapper>
      <Icon iconName='Database' />
      <Text>Could not found API key</Text>
      <Instructions>Make sure you define a valid value</Instructions>
    </Wrapper>
  );
}

export default DemosNoApiKeyPage;
