import { ICompletion } from '../types';

export const completion: ICompletion = {
  id: '',
  prompt: {
    text: '',
    tokens: [
      {
        generatedToken: {
          logprob: 0,
          token: '',
        },
        topTokens: [
          {
            logprob: 0,
            token: '',
          },
        ],
        textRange: {
          start: 0,
          end: 0,
        },
      },
    ],
  },
  completions: [
    {
      data: {
        text: '',
        tokens: [
          {
            generatedToken: {
              logprob: 0,
              token: '',
            },
            topTokens: [
              {
                logprob: 0,
                token: '',
              },
            ],
            textRange: {
              start: 0,
              end: 0,
            },
          },
        ],
      },
      finishReason: {
        reason: '',
        length: 0,
        sequence: '',
      },
    },
  ],
};
