import { Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Icon } from '../Icon/Icon';
import { CopyButton, Wrapper } from './Copy.style';
import { useUnmount } from 'react-use';
import classnames from 'classnames';

export type CopyProps = {
  value: string;
  title: string;
  onCopy?: (value: string) => void;
  className?: string;
  testId?: string;
  delay?: number;
  withBorder?: boolean;
};

export function Copy(props: CopyProps) {
  const {
    value,
    title = 'Copy value',
    testId = 'Copy',
    delay = 5000,
    withBorder,
  } = props;
  const [copied, setCopied] = useState(false);

  const timeout = useRef<any>(null);

  const iconName = copied ? 'Check' : 'Copy';
  const tooltipText = copied ? 'Copied!' : title;

  useEffect(() => {
    setCopied(false);
  }, [value]);

  function onCopy() {
    navigator.clipboard
      .writeText(value || '')
      .then(() => {
        setCopied(true);

        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => setCopied(false), delay);

        if (props.onCopy) {
          props.onCopy(value);
        }
      })
      .catch(() => {});
  }

  useUnmount(() => {
    clearTimeout(timeout.current);
  });

  const className = classnames('Copy-wrapper', props.className, {});

  return (
    <Wrapper className={className} data-testid={testId}>
      <Tooltip title={tooltipText} placement='top'>
        <CopyButton
          className={withBorder ? 'with-border' : ''}
          size='small'
          onClick={onCopy}
          data-testid={testId}
          iconName={iconName}
        />
      </Tooltip>
    </Wrapper>
  );
}

export default Copy;
