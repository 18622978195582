import { takeEvery } from 'saga-ts';
import { setIdentity } from '@ai21/studio-analytics';

import { createExperimentClient, experimentConfig } from '@ai21/studio-experiment';

export function* setExperiment(action: any) {
  const { organizationId, userId, userRole, userTier, isNewUser } = action.payload;
  if (userId) {
    setIdentity({
      userId,
      organizationId,
      organizationPlanType: userTier,
      userRole,
      isNewUser,
    });
    // init Amplitude experiment
    createExperimentClient(experimentConfig);
  }
}

export function* root() {
  yield takeEvery('SET_USER', setExperiment);
}
