export function CustomModelsIcon() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 31.5L12 21.5C12.6667 21.1667 13.7933 20.6221 16.0234 20.9407C18.0033 21.2235 18.3927 22.4427 20.3927 22.4427C21.9927 22.4427 23.1667 22 23.5 21.5L31 31.5H5Z'
        fill='#FF80A4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4003 6.91671C10.9122 6.91671 10.6411 7.47318 10.9462 7.8614L13.2503 10.7458V17.8667L3.53401 30.8162C2.83936 31.7527 3.5078 33.0834 4.667 33.0834H31.3337C32.4929 33.0834 33.161 31.7522 32.4663 30.8157L22.7503 17.8667V10.7458L25.0544 7.8614C25.3596 7.47318 25.0885 6.91671 24.6003 6.91671H11.4003ZM19.917 9.75004H16.0837V18.8001L7.50048 30.25H28.5002L19.917 18.8001V9.75004Z'
        fill='#1F2127'
        stroke='white'
        strokeWidth='0.833333'
      />
      <path
        d='M9.05588 15.3548L5.8436 15.5605L4.79961 12.4753C4.75142 12.3329 4.54261 12.3488 4.52655 12.507L4.02865 16.2091L1.37852 18.0919C1.26609 18.171 1.31428 18.3292 1.45883 18.345L4.20533 18.5665L5.34569 21.3036C5.39387 21.4301 5.5866 21.4143 5.61873 21.2719L6.27725 17.9969L9.1683 15.5921C9.26467 15.513 9.18437 15.339 9.05588 15.3548Z'
        fill='#1F2127'
      />
      <path
        d='M17.9041 24.7655L15.9142 24.8929L15.2675 22.9818C15.2377 22.8936 15.1083 22.9034 15.0984 23.0014L14.79 25.2947L13.1483 26.461C13.0787 26.51 13.1085 26.608 13.1981 26.6178L14.8994 26.755L15.6058 28.4505C15.6357 28.5289 15.755 28.5191 15.7749 28.4309L16.1829 26.4022L17.9737 24.9125C18.0334 24.8635 17.9837 24.7557 17.9041 24.7655Z'
        fill='#1F2127'
      />
      <path
        d='M35.4157 10.4475L31.4038 10.7174L30.0926 6.85226C30.0339 6.66913 29.7697 6.69804 29.7403 6.88118L29.1141 11.527L25.7774 13.8982C25.6404 13.9945 25.6991 14.2066 25.8655 14.2162L29.3196 14.4861L30.7482 17.9175C30.8167 18.0814 31.0613 18.0524 31.0907 17.8886L31.9126 13.7729L35.5331 10.7559C35.6799 10.6499 35.5918 10.4379 35.4157 10.4475Z'
        fill='#1F2127'
      />
    </svg>
  );
}
