import { IEvaluationJobs, IEvaluationLines } from './types.sets';

export const evaluationJobs: IEvaluationJobs = {
  '1': {
    id: '1',
    name: 'Evaluation Job 1',
    status: 'New',
    description: 'Evaluation Job 1 Description',
    dateCreated: Date.now(),
    creator: 'John Doe',
    setType: 'evaluation',
    setSource: 'generation',
    fileSizeInBytes: 100,
    linesCount: 100,
    linesCompleted: 99,
    tags: [],
    isDeleted: false,
    evaluationGoal: 'Evaluation Goal 1',
    evaluationMethod: 'Evaluation Method 1',
    badCount: 0,
    badRate: 0.9,
    badLabels: [],
    okCount: 0,
    okRate: 0.9,
    okLabels: [],
    greatCount: 0,
    greatRate: 0.8,
    greatLabels: [],
  },
};

export const evaluationLines: IEvaluationLines = {
  '1': {
    id: '1',
    setId: '',
    index: 1,
    dateCreated: Date.now(),
    creator: 'Jimmy Bill',
    input: 'Input 1',
    output: 'Output 1',
    status: 'Completed',
    dateCompleted: Date.now(),
    comment: 'Comment 1',
    inputEdited: '',
    outputEdited: 'Output Suggestion 1',
    lastOpenedAt: Date.now(),
    lastOpenedBy: 'Jimmy Bill',
    isLocked: false,
    isSkipped: false,
    setSource: 'upload',
    setType: 'evaluation',
    modelName: 'Model 1',
    quality: {
      value: 0.9,
      tags: ['Tag 1'],
    },
    evaluator: '',
    foundationModelName: '',
    maxTokens: 200,
    minTokens: 0,
    temperature: 0.9,
    topP: 0.9,
  },
};
