export function ChatIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='6' fill='white' />
      <rect width='52' height='52' rx='6' fill='#599AAE' fillOpacity='0.1' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.6285 21.444C28.6386 18.8938 23.9536 18.6991 20.7036 21.0048C17.487 23.2868 16.7112 27.2705 18.7912 30.3467C18.9218 30.5399 18.9551 30.7828 18.8814 31.004L17.9959 33.6605L21.4138 32.9333C21.5771 32.8985 21.7473 32.9192 21.8974 32.9921C25.5673 34.7738 30.1343 33.8458 32.565 30.8969C34.962 27.9889 34.5829 23.964 31.6285 21.444ZM19.8357 19.7814C23.6375 17.0842 29.0816 17.3002 32.6019 20.3028C36.1576 23.3356 36.658 28.2897 33.7225 31.851C30.8744 35.3063 25.6873 36.365 21.4793 34.4529L17.026 35.4004C16.7629 35.4564 16.4899 35.3672 16.3107 35.1666C16.1314 34.9661 16.0733 34.6849 16.1584 34.4297L17.3446 30.8709C15.0391 27.1218 16.1061 22.4274 19.8357 19.7814ZM21.8699 25.9166C22.2841 25.9166 22.6199 26.2524 22.6199 26.6666V26.6766C22.6199 27.0908 22.2841 27.4266 21.8699 27.4266C21.4557 27.4266 21.1199 27.0908 21.1199 26.6766V26.6666C21.1199 26.2524 21.4557 25.9166 21.8699 25.9166ZM25.8699 25.9166C26.2841 25.9166 26.6199 26.2524 26.6199 26.6666V26.6766C26.6199 27.0908 26.2841 27.4266 25.8699 27.4266C25.4557 27.4266 25.1199 27.0908 25.1199 26.6766V26.6666C25.1199 26.2524 25.4557 25.9166 25.8699 25.9166ZM29.8699 25.9166C30.2841 25.9166 30.6199 26.2524 30.6199 26.6666V26.6766C30.6199 27.0908 30.2841 27.4266 29.8699 27.4266C29.4557 27.4266 29.1199 27.0908 29.1199 26.6766V26.6666C29.1199 26.2524 29.4557 25.9166 29.8699 25.9166Z'
        fill='black'
      />
    </svg>
  );
}
