import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
`;

export const IconWrapper = styled.div`
  margin-right: 6px;

  svg {
    stroke-width: 1.5px;
    position: relative;
    top: 2px;
    height: 16px;
    width: 16px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Tags = styled.div`
  padding-left: 10px;

  > div {
    margin: 0 5px;
  }
`;
