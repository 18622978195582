import { Button, styled } from '@ai21/studio-ui';
import { layout } from '@ai21/studio-ui';

export const Wrapper = styled('div')`
  font-size: 14px;
  flex: 1;
  max-height: calc(100vh);
  overflow: hidden;
`;

export const ContentSection = styled('div')`
  display: flex;
  flex-direction: row;
  min-height: calc(100vh);
`;
export const RightSection = styled('div')`
  font-size: 14px;
  max-height: calc(100vh);
  flex: 1;
`;
export const LeftSection = styled('div')`
  font-size: 14px;
  width: 400px;
`;

export const Container = styled(layout.Container)``;

export const Cta = styled(Button)`
  svg {
    position: relative;
    top: 1px;
    left: 4px;
  }
`;
