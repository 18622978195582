import { takeEvery } from 'saga-ts';
import { logEvent } from '../analytics.amplitude';
import { customEvenChannel } from './channels/channel.customEvent';

export function* listenToFlows(event: any) {
  const { data } = event;
  const { action, actionValue, eventId = 'Flow', flow, itemId, parentId, extra } = data;

  const log = {
    flow,
    action,
    actionValue,
    itemId,
    parentId,
  };

  if (extra) {
    Object.assign(log, extra);
  }

  logEvent(eventId, log, true);
}

export function* root() {
  const channel = customEvenChannel('global/flow');
  yield takeEvery(channel, listenToFlows);
}
