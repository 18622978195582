import { api } from '@ai21/studio-api';
import { Form } from '@ai21/studio-forms';
import { actions, selectors } from '@ai21/studio-store';
import {
  FileDrop,
  UploadSummary,
  drawer,
  prompt,
  toast,
} from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, cancel, delay, fork, put, select, takeEvery } from 'saga-ts';
import { guid4, invokeEvent } from 'shared-base';
import { formDefaults, forms } from '../_definitions/forms';
import Highlights from '../components/Highlights/Highlights';
import UploadProgressContainer from '../components/UploadProgress/UploadProgress.container';
import { AskEditorContainer } from '../containers/AskEditor.container';
import { Json } from '../types';
import { store } from '../utils/globals';
import { IUploadResponse, UploadQueue } from '../utils/queue';
import {
  AddLabelsToDocuments,
  DeleteDocuments,
  ShowAddLabelPrompt,
  createDocument,
  showToastOnAddLabelsResult,
  showToastOnDeleteDocuments,
} from './helpers/document';

const MAX_FILES = 50;

type Verb =
  | 'new'
  | 'download'
  | 'ask'
  | 'openDrawer'
  | 'manageLabels'
  | 'addLabels'
  | 'clearLabels'
  | 'uploadFiles'
  | 'seeHighlights'
  | 'copyId'
  | 'delete'
  | 'bulkDelete'
  | 'clear';

type ActionJob = {
  type: 'DOCUMENT';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  new: newDocument,
  ask: askAQuestion,
  manageLabels: manageLabels,
  addLabels: addLabels,
  clearLabels: clearLabels,
  copyId: copyId,
  delete: deleteDocument,
  bulkDelete: deleteDocuments,
  openDrawer: openDrawer,
  download: downloadDocument,
  clear: clearQuestions,
  uploadFiles: uploadFiles,
  seeHighlights: seeHighlights,
};

function* askAQuestion(action: ActionJob) {
  const { params } = action;
  const { prompt: value, labels, source } = params ?? {};

  if (!params) {
    return;
  }
  const currentIds = yield* select(selectors.raw.$rawCurrentIds);
  const { askSpecificDocumentIds } = currentIds;

  const id = guid4();

  yield put(
    actions.documentQuestions.patch(id, {
      id,
      prompt: value as string,
      status: 'waiting',
      createdAt: Date.now(),
      source: source || 'library',
    })
  );

  const response: any = yield* call(api.documents.ask, value, {
    fileIds: askSpecificDocumentIds,
    labels,
  });

  if (!response.isSuccess) {
    const { errorMessage } = response;

    yield put(
      actions.documentQuestions.patch(id, {
        status: 'error',
        errorMessage,
        source: source || 'library',
      })
    );

    return;
  }

  let { answer, answerInContext, sources } = response.data;

  yield put(
    actions.documentQuestions.patch(id, {
      source: source || 'library',
      answer,
      answerInContext,
      sources,
      status: 'answered',
    })
  );
}

function* manageLabels(action: ActionJob) {
  const { id } = action;

  const document = yield* select(selectors.singles.$document, id);

  if (!document) {
    return;
  }

  const { labels } = document;
  const { didCancel, value } = yield prompt.form({
    component: Form,
    title: 'Manage labels',
    form: {
      config: forms.docEdit,
      data: {
        ...formDefaults.docEdit,
        labels: labels || [],
      },
      allOptions: {},
      allDetails: {},
      allMethods: {},
    },
  });

  if (didCancel || !value) {
    return;
  }

  yield put(actions.documents.patch(id, value));
}

function* addLabels(action: ActionJob) {
  const { params } = action;
  const { ids } = params || {};
  if (!ids || !Array.isArray(ids) || ids.length === 0) {
    toast.show('No documents selected', 'info');
    return;
  }

  if (ids.length === 1) {
    yield* fork(manageLabels, { ...action, id: ids[0] });
    return;
  }

  const { didCancel, value = {} } = yield call(ShowAddLabelPrompt, ids.length);

  if (didCancel || !value) {
    return;
  }

  const { success, failed = {} } = yield call(
    AddLabelsToDocuments,
    value.labels,
    ids
  );

  showToastOnAddLabelsResult(success, failed);

  yield put(actions.documents.get({}));
}

function* clearLabels(action: ActionJob) {
  const { params } = action;
  const { ids } = params || {};
  if (!ids || !Array.isArray(ids) || ids.length === 0) {
    toast.show('No documents selected', 'info');
    return;
  }

  const description =
    ids.length === 1
      ? 'Are you sure you want to remove the labels from the selected document?'
      : `Are you sure you want to remove the labels from the ${ids.length} selected documents?`;

  const { didCancel } = yield prompt.confirm({
    title: 'Clear Labels',
    description,
    ctaButtonText: 'Clear Labels',
    intention: 'clearLabels',
  });

  if (didCancel) {
    return;
  }

  let success = 0;
  let failed = 0;

  for (const id of ids) {
    const doc = yield* select(selectors.singles.$document, id);

    if (!doc) {
      return;
    }

    const { labels = [] } = doc || {};
    const response = yield* call(api.documents.update, id, {
      labels: [],
    });

    if (response.isSuccess) {
      success++;
    } else {
      failed++;
    }
  }

  if (failed > 0) {
    toast.show(
      `Failed to remove labels from the selected document(s), please try again.`,
      'error'
    );
  } else {
    toast.show(
      `All labels have been removed from the selected document(s).`,
      'success'
    );
  }
  yield put(actions.documents.get({}));
}

function* copyId(action: ActionJob) {
  const { id } = action;
  navigator.clipboard.writeText(id);
  toast.show('Copied to clipboard', { type: 'success' });
}

function* deleteDocument(action: ActionJob) {
  const { id } = action;
  const doc = yield* select(selectors.singles.$document, id);

  if (!doc) {
    return;
  }

  const { name } = doc;

  const { didCancel } = yield prompt.confirm({
    title: 'Delete Document',
    description: `Are you sure you want to delete the selected document ${name}? Models will no longer be able to use this information to generate responses.`,
    ctaButtonText: 'Delete',
    intention: 'delete',
  });

  if (didCancel) {
    return;
  }

  yield put(actions.documents.delete(id));

  toast.show(['Delete document...', 'Document deleted'], 'promise', {
    delay: 1000,
  });
}
function* deleteDocuments(action: ActionJob) {
  const { params } = action;
  const { ids } = params || {};
  if (!ids || !Array.isArray(ids) || ids.length === 0) {
    toast.show('No files selected', 'info');
    return;
  }

  if (ids.length === 1) {
    yield* fork(deleteDocument, { ...action, id: ids[0] });
    return;
  }

  const { didCancel } = yield prompt.confirm({
    title: 'Delete',
    description: `Are you sure you want to delete the ${ids.length} selected documents from your RAG Engine? Models will no longer be able to use this information to generate responses.`,
    ctaButtonText: 'Delete',
    intention: 'delete',
  });

  if (didCancel) {
    return;
  }
  const { success, failed = {} } = yield call(DeleteDocuments, ids);

  showToastOnDeleteDocuments(success, failed);

  yield put(actions.documents.get({}));
}

function* downloadDocument(action: ActionJob) {
  const { id } = action;

  const doc = yield* select(selectors.singles.$document, id);

  if (!doc) {
    return;
  }
}

function* openDrawer(action: ActionJob) {
  const { params } = action;
  const { ids } = params;

  yield put(
    actions.currentIds.patch({
      askSpecificDocumentIds: ids,
    })
  );

  yield drawer.open({
    component: AskEditorContainer,
  });
}

function* newDocument(action: ActionJob) {
  const isDemo = window.location.pathname.includes('/demo/');

  if (isDemo) {
    toast.show("Please upload a document from the studio's webapp", 'warning');
    return;
  }

  const { value, didCancel } = yield prompt.custom({
    title: 'Upload documents',
    component: FileDrop,
    componentProps: {
      warning: 'Please note: Uploaded files will be shared organization-wide.',
      acceptTypes: ['.pdf', '.docx', '.html', '.txt'],
      comment: `Upload PDF, DOCX, HTML and TXT files of up to 100MB in up to 50 files (with a total storage capacity of 1GB). For further details see our Technical documentation.[Documentation](https://docs.ai21.com/docs/rag-engine-overview).`,
      maxSize: 100 * 1000 * 1000, // 100Mb
      multiple: true,
      maxFiles: MAX_FILES,
      ctaButtonText: 'Select files',
    },
    componentCta: 'onDrop',
    intention: 'Upload',
  });

  if (didCancel || !value || value.length === 0) {
    return;
  }

  const { didCancel: didCanceLabels, value: labelsValue } = yield prompt.form({
    component: Form,
    title: 'Label Your Documents (Optional)',
    form: {
      config: forms.docLabel,
      data: {
        ...formDefaults.docLabel,
        labels: [],
      },
      allOptions: {},
      allDetails: {},
      allMethods: {},
    },
  });

  if (didCanceLabels) {
    toast.show('Upload canceled.', 'info');
    return;
  }

  if (value.length > 1) {
    yield call(uploadFileMultiple, value, labelsValue?.labels);
  } else {
    yield call(uploadFileSingle, value[0], labelsValue?.labels);
  }
  yield put(actions.documents.get({}));
}

function* uploadFileSingle(file: File, labels: string[]) {
  const { name = file.name } = file;

  try {
    yield call(createDocument, {
      file,
      name,
      labels: labels || [],
      path: '',
      publicUrl: '',
    });
  } catch (err) {
    toast.show('Somthing went wrong, please try again.', { type: 'error' });
    console.log('err ->', err);
  }
}

function* uploadFileMultiple(files: File[], labels: string[]) {
  yield put(
    actions.appState.patch({
      progressTotal: files.length,
    })
  );

  prompt.custom({
    component: UploadProgressContainer,
  });

  const queue = new UploadQueue(MAX_FILES);

  const id = guid4();

  for (let file of files) {
    queue.addRequest(id, {
      file,
      name: file.name,
      labels: labels || [],
      path: '',
      publicUrl: '',
    });
  }

  const summary = yield* call(queue.start, {
    onResponse: (_id: string, _uploadResponse: IUploadResponse) => {},
    onProgress: (completedCount: number) => {
      store.dispatch(
        actions.appState.patch({ progressCompleted: completedCount })
      );
    },
  });

  invokeEvent('closeProgress');

  yield put(
    actions.appState.patch({
      progressCompleted: 0,
      progressTotal: 0,
    })
  );

  const { countCompleted, countTotal, errors } = summary;

  yield* put(actions.documents.get({}));

  yield prompt.custom({
    title: 'Upload summary',
    component: UploadSummary,
    componentProps: {
      topMessage: `${countCompleted}/${countTotal} files uploaded successfully!`,
      topMessageFlavour: countCompleted === 0 ? 'error' : 'success',
      errors,
    },
    intention: 'upload',
  });

  if (countCompleted === 0) {
    return;
  }
}

function* clearQuestions(action: ActionJob) {
  yield call(api.documents.clearQuestions, action.params?.source);
  yield put(actions.documentQuestions.setAll({}));
}

function* uploadFiles(action: ActionJob) {
  yield delay(400);

  yield call(newDocument, action);
}

function* seeHighlights(action: ActionJob) {
  const highlights = get(action, 'params.sourceFile.highlights', []);

  if (!Array.isArray(highlights) || !highlights.length) {
    return;
  }

  yield prompt.custom({
    title: 'Highlights',
    component: Highlights,
    componentProps: {
      highlights,
    },
  });
}

// ======= generic part =======
export function* document(action: ActionJob) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* listenToDocument() {
  yield takeEvery('DOCUMENT', document);
}

let task: any;

export function* root() {
  if (task) {
    yield cancel(task);
  }

  task = yield* fork(listenToDocument);
}
