import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ApiValue = styled(Typography)`
  margin-right: 10px;
  color: #626b78;
  font-size: 14px;
`;
