const start = Date.now();

export const deltaTimestamp = () => {
  const now = Date.now();

  return (now - start) / 1000;
};

export const formattedTimestamp = () => {
  const now = Date.now();
  const delta = (now - start) / 1000;

  const formatted = delta.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatted;
};
