import { ax } from '@ai21/studio-analytics';
import { api } from '@ai21/studio-api';
import { actions } from '@ai21/studio-store';
import { Dispatch } from 'react';
import { APIType, API_HOST } from '../../constants/constants';
import { CompletionFailureReason, CompletionResponse } from '../../data-types/Completion';
import { DatasetEndpointListElement, SelectedColumns } from '../../data-types/Dataset';
import { ResponseError, ValidationResponseError } from '../../data-types/Error';
import { CustomModelType, ModelEndpointListElement, ModelStatus } from '../../data-types/Model';
import { OrganizationInviteResponse, UserRole } from '../../data-types/Organization';
import { Preset, PresetLabel, PresetOrigin } from '../../data-types/PresetParams';
import { TierType } from '../../data-types/Tier';
import { UserEndpointResponse } from '../../data-types/User';
import { errorHandler } from '../../utils/error-reporting';
import { ActionTypes } from '../actionTypes/actionTypes';
import {
  CustomModelsActionTypes,
  ModelsUploadingErrorType,
  POST_MODEL_FAILURE,
  POST_MODEL_STARTED,
  POST_MODEL_SUCCESS,
} from '../actionTypes/customModelsActionTypes';
import {
  DatasetsActionTypes,
  DatasetsUploadingErrorType,
  POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE,
  POST_DATASET_FAILURE,
  POST_DATASET_STARTED,
  POST_DATASET_SUCCESS,
} from '../actionTypes/datasetsActionTypes';
import {
  CUSTOM_PRESET_SELECTED,
  FETCH_COMPLETION_FAILED,
  FETCH_COMPLETION_STARTED,
  FETCH_COMPLETION_SUCCESS,
  FETCH_TOKENIZE_FAILED,
  FETCH_TOKENIZE_STARTED,
  FETCH_TOKENIZE_SUCCESS,
  POST_PROMPT_SHARE_FAILED,
  POST_PROMPT_SHARE_STARTED,
  POST_PROMPT_SHARE_SUCCESS,
  PlaygroundActionTypes,
} from '../actionTypes/playgroundActionTypes';
import {
  POST_PRESET_FAILED,
  POST_PRESET_STARTED,
  POST_PRESET_SUCCESS,
  PostPresetPayload,
  PresetActionTypes,
} from '../actionTypes/presetActionTypes';
import {
  CHANGE_USER_ROLE_FAILURE,
  CHANGE_USER_ROLE_STARTED,
  EDIT_ORGANIZATION_NAME_FAILURE,
  EDIT_ORGANIZATION_NAME_STARTED,
  FEEDBACK_SUBMIT_FAILURE,
  FEEDBACK_SUBMIT_STARTED,
  FEEDBACK_SUBMIT_SUCCESS,
  FETCH_REFRESH_API_KEY_FAILURE,
  FETCH_REFRESH_API_KEY_STARTED,
  FETCH_REFRESH_API_KEY_SUCCESS,
  MESSAGE_SHOWN,
  ROTATE_API_KEY_FAILURE,
  ROTATE_API_KEY_STARTED,
  ROTATE_API_KEY_SUCCESS,
  SEND_INVITE_ORGANIZATION_FAILURE,
  SEND_INVITE_ORGANIZATION_STARTED,
  SEND_INVITE_ORGANIZATION_SUCCESS,
  UserActionTypes,
} from '../actionTypes/userActionTypes';
import { RootState } from '../store';
import {
  buildCompletionRequestParams,
  fetchOrganizationDetails,
  fetchOrganizationUsersDetails,
  getRequestIdHeader,
} from './actions.get';
import { paymentFailure } from './actions.others';

export async function updatePaymentMethodIdentity(paymentMethodId: string) {
  try {
    const response = await api.billing.updatePaymentMethod(paymentMethodId);

    if (!response || !response.isSuccess) {
      errorHandler.report(response);
      return false;
    }
  } catch (err: any) {
    errorHandler.report(err);
    return false;
  }

  return true;
}

export async function updateUserPremium(
  dispatch: Dispatch<ActionTypes>,
  paymentMethodId: string,
  tier: TierType,
  stripeCoupon: string | undefined
) {
  const params = {
    paymentMethodId,
    tier,
  };
  try {
    const response = await api.user.updateUserPremium({
      paymentMethodId,
      tier: tier as any,
      coupon: stripeCoupon,
    });
    if (!response.isSuccess) {
      const payload = response.data as ResponseError;
      dispatch(paymentFailure(payload.message));
      return false;
    }
  } catch (err: any) {
    errorHandler.report(err);
    dispatch(paymentFailure('Network error... Please try again'));
    return false;
  }

  return true;
}

export function editOrganizationName(name: string) {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: EDIT_ORGANIZATION_NAME_STARTED });
    const editOrganizationNameInput = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getRequestIdHeader(),
      },
      body: JSON.stringify({ name }),
    };
    const response = await api.organization.patch({ name });

    if (response.isSuccess) {
      await fetchOrganizationDetails()(dispatch);
    } else {
      errorHandler.report(`Change organization name failed!, message: ${response.statusText}`);
      dispatch({
        type: EDIT_ORGANIZATION_NAME_FAILURE,
        error: response.statusText || 'User does not have sufficient privileges',
      });
      dispatch({
        type: MESSAGE_SHOWN,
        payload: {
          origin: 'editOrganizationName',
          type: 'question',
          text: 'User does not have sufficient privileges',
          name: 'editOrganizationNameError',
        },
      });
    }
  };
}

export function sendOrganizationInvite(
  email: string,
  organizationId: string,
  userTier: TierType,
  method: string,
  origin: string
) {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: SEND_INVITE_ORGANIZATION_STARTED, payload: { origin } });
    const sendInviteInput = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getRequestIdHeader(),
      },
      body: JSON.stringify({ email }),
    };
    const response = await api.organization.sendInvite(email);

    if (response.isSuccess) {
      dispatch({
        type: SEND_INVITE_ORGANIZATION_SUCCESS,
        payload: { origin, method },
      });
      await fetchOrganizationUsersDetails()(dispatch);
    } else {
      errorHandler.report(`Invite to organization failed!, message: ${response.statusText}`);
      const body = response.data as OrganizationInviteResponse;
      dispatch({
        type: SEND_INVITE_ORGANIZATION_FAILURE,
        error: body.detail,
      });
    }
  };
}

export function changeUserRole(newRole: UserRole, email: string) {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: CHANGE_USER_ROLE_STARTED });
    const changeUserRoleInput = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getRequestIdHeader(),
      },
      body: JSON.stringify({ newRole, email }),
    };
    const response = await api.users.changeRole(email, newRole);

    if (response.isSuccess) {
      await fetchOrganizationUsersDetails()(dispatch);
    } else {
      errorHandler.report(`Change user role failed!, message: ${response.statusText}`);
      dispatch({
        type: CHANGE_USER_ROLE_FAILURE,
        error: response.statusText,
      });
    }
  };
}

export function postCustomModel(
  modelName: string,
  epochs: number,
  learningRate: number,
  datasetId: string,
  modelType: CustomModelType
) {
  return async function inner(dispatch: Dispatch<CustomModelsActionTypes>) {
    dispatch({ type: POST_MODEL_STARTED });
    try {
      const customModelInput = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...getRequestIdHeader(),
        },
        body: JSON.stringify({
          model_name: modelName,
          learning_rate: learningRate,
          num_epochs: epochs,
          dataset_id: datasetId,
          model_type: modelType,
        }),
      };

      const response = await api.models.create({
        modelName,
        learningRate,
        epochs,
        datasetId,
        modelType,
      });

      if (response.isSuccess) {
        const payload = response.data as ModelEndpointListElement;
        return dispatch({
          type: POST_MODEL_SUCCESS,
          payload: { ...payload, status: ModelStatus.PENDING },
        });
      }
      errorHandler.report(`postCustomModel failed: ${response.statusText}`);
      if (response.status === 403) {
        return dispatch({
          type: POST_MODEL_FAILURE,
          uploadModelError: ModelsUploadingErrorType.CREDIT_EXCEEDED,
        });
      }
      if (response.status === 402) {
        return dispatch({
          type: POST_MODEL_FAILURE,
          uploadModelError: ModelsUploadingErrorType.TRIAL_ENDED,
        });
      }
      return dispatch({
        type: POST_MODEL_FAILURE,
        uploadModelError: ModelsUploadingErrorType.MODEL_ERROR_1,
      });
    } catch (err: any) {
      const message = err instanceof Error ? err.message : 'unknown';
      errorHandler.report(`postCustomModel failed: ${message}`);
      return dispatch({
        type: POST_MODEL_FAILURE,
        uploadModelError: ModelsUploadingErrorType.MODEL_ERROR_1,
      });
    }
  };
}

export function postDataset(
  datasetName: string,
  datasetFile: File,
  selectedColumns?: SelectedColumns,
  approveWhitespaceCorrection?: boolean,
  deleteLongRows?: boolean
) {
  return async function inner(dispatch: Dispatch<DatasetsActionTypes>) {
    const formData = new FormData();
    formData.append('dataset_name', datasetName);
    formData.append('dataset_file', datasetFile);
    if (selectedColumns) {
      formData.append('selected_columns', JSON.stringify(selectedColumns));
    }
    if (approveWhitespaceCorrection !== undefined) {
      formData.append('approve_whitespace_correction', String(approveWhitespaceCorrection));
    }
    if (deleteLongRows !== undefined) {
      formData.append('delete_long_rows', String(deleteLongRows));
    }
    dispatch({ type: POST_DATASET_STARTED, datasetName, datasetFile });
    const response = await api.fts.create({
      name: datasetName,
      file: datasetFile,
      selectedColumns,
      approveWhitespaceCorrection,
      deleteLongRows,
    });
    if (response.isSuccess) {
      const payload = response.data[0] as DatasetEndpointListElement;
      dispatch(actions.fineTuningSets.set(payload.id, payload));
      return dispatch({
        type: POST_DATASET_SUCCESS,
        payload,
        approveWhitespaceCorrection,
        selectedColumns,
        deleteLongRows,
      });
    }
    const payload = response.data as ValidationResponseError;
    if (response.status === 422 || response.status === 400) {
      if (
        payload.detail &&
        payload.detail.validation_message === DatasetsUploadingErrorType.COLUMNS_NOT_SPECIFIED
      ) {
        return dispatch({
          type: POST_DATASET_COLUMNS_NOT_SPECIFIED_FAILURE,
          uploadDatasetError: DatasetsUploadingErrorType.COLUMNS_NOT_SPECIFIED,
          uploadDatasetColumnsName: payload.detail.columns_names,
        });
      }
      return dispatch({
        type: POST_DATASET_FAILURE,
        approveWhitespaceCorrection,
        deleteLongRows,
        selectedColumns,
        uploadDatasetError: payload.detail?.validation_message,
        uploadDatasetErrorDetails:
          payload.detail?.validation_message === DatasetsUploadingErrorType.ILLEGAL_FORMAT
            ? payload.detail.error_message
            : undefined,
      });
    }
    await errorHandler.report(`post datasets failed!, message: ${response.statusText}`);
    return dispatch({
      type: POST_DATASET_FAILURE,
      approveWhitespaceCorrection,
      selectedColumns,
      deleteLongRows,
      uploadDatasetError: DatasetsUploadingErrorType.GENERAL_EXCEPTION,
    });
  };
}

export function postPromptShare(text: string) {
  return async function inner(
    dispatch: Dispatch<PlaygroundActionTypes>,
    getState: () => RootState
  ) {
    const { currentApi } = getState()._playground;
    const parameters = buildCompletionRequestParams(getState()._playground.controllerParams, text);
    const { prompt, ...params } = parameters;
    try {
      const payload = {
        params: { ...params, length: prompt.length, prompt: text },
        text: prompt,
        apiType: currentApi,
      };
      dispatch({ type: POST_PROMPT_SHARE_STARTED, payload: parameters, apiType: currentApi });
      const start = new Date();
      const response = await api.completion.createShare(payload);

      if (response.isSuccess) {
        type PromptSharePostResponse = {
          id: string;
          apiType: APIType;
        };
        const responseJson = response.data as PromptSharePostResponse;
        return dispatch({
          type: POST_PROMPT_SHARE_SUCCESS,
          payload: parameters,
          requestDuration: new Date().getTime() - start.getTime(),
          promptShareId: responseJson.id,
          apiType: responseJson.apiType,
        });
      }
      errorHandler.report('post prompt share failed');
      const responseJson = response.data as ResponseError;
      return dispatch({
        type: POST_PROMPT_SHARE_FAILED,
        payload: parameters,
        reason: responseJson?.detail || 'UNKNOWN_ERROR',
        apiType: currentApi,
      });
    } catch (err: any) {
      errorHandler.report(err);
      const message = err instanceof Error ? err.message : 'UNKNOWN_ERROR';
      return dispatch({
        type: POST_PROMPT_SHARE_FAILED,
        payload: parameters,
        reason: message,
        apiType: currentApi,
      });
    }
  };
}

export function refreshApiKey() {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FETCH_REFRESH_API_KEY_STARTED });
    try {
      const settings = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...getRequestIdHeader(),
        },
        body: JSON.stringify({ regenerateKey: true }),
      };
      const response = await api.user.refreshKey();
      if (response.isSuccess) {
        const payload = response.data as UserEndpointResponse;
        dispatch({ type: FETCH_REFRESH_API_KEY_SUCCESS, payload });
      } else {
        errorHandler.report('Fetch api key failed!');
        dispatch({ type: FETCH_REFRESH_API_KEY_FAILURE, error: JSON.stringify(response.data) });
      }
    } catch (err: any) {
      errorHandler.report(err);
      const message = err instanceof Error ? err.message : 'unknown';
      dispatch({ type: FETCH_REFRESH_API_KEY_FAILURE, error: message });
    }
  };
}

export function rotateApiKey(email: string) {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: ROTATE_API_KEY_STARTED });
    try {
      const settings = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...getRequestIdHeader(),
        },
        body: JSON.stringify({ regenerateKey: true, email }),
      };
      const response = await api.users.rotateApiKey(email);
      if (response.isSuccess) {
        dispatch({ type: ROTATE_API_KEY_SUCCESS });
      } else {
        errorHandler.report('Rotate api key failed!');
        dispatch({ type: ROTATE_API_KEY_FAILURE, error: JSON.stringify(response.data) });
      }
    } catch (err: any) {
      errorHandler.report(err);
      const message = err instanceof Error ? err.message : 'unknown';
      dispatch({ type: ROTATE_API_KEY_FAILURE, error: message });
    }
  };
}

export function fetchTokenizeRequest(text: string) {
  return async function inner(dispatch: Dispatch<PlaygroundActionTypes>) {
    dispatch({ type: FETCH_TOKENIZE_STARTED });
    try {
      const settings = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${apiKey || ''}`,
          ...getRequestIdHeader(),
        },
        body: JSON.stringify({
          text,
        }),
      };

      const response = await api.completion.tokenize(text);
      if (response.isSuccess) {
        type TokenizeResponse = { tokens: string[] };
        const payload = response.data as TokenizeResponse;
        const numTokens = payload.tokens.length;
        dispatch({ type: FETCH_TOKENIZE_SUCCESS, numTokens });
      }
      if (response.status === 429) {
        dispatch({ type: FETCH_TOKENIZE_FAILED });
      }
    } catch (err: any) {
      dispatch({ type: FETCH_TOKENIZE_FAILED });
    }
  };
}

export function sendFeedback(
  requestId: string,
  feedbackScore: number,
  feedbackReason: string[],
  origin: string,
  type: string,
  questionText: string,
  reason: string,
  model: string,
  promptLength: number,
  completionLength: number
) {
  return async function inner(dispatch: Dispatch<UserActionTypes>) {
    dispatch({ type: FEEDBACK_SUBMIT_STARTED });
    const payload = {
      origin,
      reason,
      type,
      interactionId: requestId,
      questionText,
      answer: feedbackReason,
      score: feedbackScore,
      model,
      promptLength,
      completionLength,
      endpoint: `${API_HOST}/studio/v1/experimental/feedback`,
    };
    try {
      const response = await api.feedback.send({
        requestId,
        score: feedbackScore,
        reasons: feedbackReason,
        model,
      });
      if (response.isSuccess) {
        return dispatch({
          type: FEEDBACK_SUBMIT_SUCCESS,
          payload,
        });
      }
      errorHandler.report(`send feedback failed response: ${response.statusText}`);
      return dispatch({
        type: FEEDBACK_SUBMIT_FAILURE,
        payload,
        errorMessage: response.statusText,
      });
    } catch (err: any) {
      const message = err instanceof Error ? err.message : 'unknown';
      errorHandler.report(`send feedback failed errorMessage: ${message}`);
      return dispatch({
        type: FEEDBACK_SUBMIT_FAILURE,
        payload,
        errorMessage: message,
      });
    }
  };
}

export function fetchCompletionRequest(text: string) {
  return async function inner(
    dispatch: Dispatch<PlaygroundActionTypes>,
    getState: () => RootState
  ) {
    const completionParams = buildCompletionRequestParams(
      getState()._playground.controllerParams,
      text
    );

    const modelSpecifications = getState()._customModels.modelsEndpointResponse?.find(
      (m) => m.name === completionParams.model
    );

    let params;
    if (modelSpecifications) {
      params = { ...completionParams, customType: modelSpecifications.customModelType };
    }

    ax.nudge('playground.completions');

    dispatch({ type: FETCH_COMPLETION_STARTED, completionParams });
    try {
      const start = new Date();

      if (params?.epoch === 0) {
        delete params.epoch;
      }

      const response = await api.completion.complete(params || completionParams);
      if (response.isSuccess) {
        const payload = response.data as CompletionResponse;
        return dispatch({
          type: FETCH_COMPLETION_SUCCESS,
          completionResponse: payload,
          completionParams,
          requestDuration: new Date().getTime() - start.getTime(),
        });
      }
      if (response.status === 422) {
        return dispatch({
          type: FETCH_COMPLETION_FAILED,
          reason: CompletionFailureReason.TOO_MANY_TOKENS,
          completionParams,
        });
      }
      if (response.status === 429) {
        return dispatch({
          type: FETCH_COMPLETION_FAILED,
          reason: CompletionFailureReason.TOO_MANY_REQUESTS,
          completionParams,
        });
      }
      if (response.status === 403) {
        return dispatch({
          type: FETCH_COMPLETION_FAILED,
          reason: CompletionFailureReason.CREDIT_EXCEEDED,
          completionParams,
        });
      }
      if (response.status === 402) {
        return dispatch({
          type: FETCH_COMPLETION_FAILED,
          reason: CompletionFailureReason.TRIAL_ENDED,
          completionParams,
        });
      }
      errorHandler.report(`playground completion fetch failed: ${response.statusText}`);
      return dispatch({
        type: FETCH_COMPLETION_FAILED,
        reason: CompletionFailureReason.UNKNOWN_ERROR,
        completionParams,
      });
    } catch (err: any) {
      const message = err instanceof Error ? err.message : 'unknown';
      errorHandler.report(`playground completion fetch failed: ${message}`);
      return dispatch({
        type: FETCH_COMPLETION_FAILED,
        reason: CompletionFailureReason.UNKNOWN_ERROR,
        completionParams,
      });
    }
  };
}

export function postPreset(name: string, text: string) {
  return async (
    dispatch: Dispatch<PresetActionTypes | PlaygroundActionTypes>,
    getState: () => RootState
  ) => {
    const { currentApi } = getState()._playground;
    const parameters = buildCompletionRequestParams(getState()._playground.controllerParams, text);
    const { prompt, ...params } = parameters;
    const payload = {
      text: prompt,
      name,
      params: { ...params, length: prompt.length, prompt },
      apiType: currentApi,
    };
    try {
      dispatch({
        type: POST_PRESET_STARTED,
        payload: { name, params: parameters, apiType: currentApi },
      });
      const start = new Date();

      const response = await api.presets.create(payload);

      if (response.isSuccess) {
        const responseJson = response.data as PostPresetPayload;
        const savedPreset: Preset = {
          name: responseJson.name,
          text: prompt,
          apiType: currentApi,
          params: { ...params, length: prompt.length },
          primaryLabel: PresetLabel.MY_PRESETS,
          secondaryLabels: [],
        };
        dispatch({
          type: CUSTOM_PRESET_SELECTED,
          preset: savedPreset,
          origin: PresetOrigin.PLAYGROUND,
        });

        dispatch(actions.presets.get({}));
        return dispatch({
          type: POST_PRESET_SUCCESS,
          payload: responseJson,
          requestDuration: new Date().getTime() - start.getTime(),
          prompt,
        });
      }
      errorHandler.report('post preset failed');
      const responseJson = response.data as ResponseError;
      return dispatch({
        type: POST_PRESET_FAILED,
        payload: { name, params: parameters, apiType: currentApi },
        reason: responseJson?.detail || 'UNKNOWN_ERROR',
      });
    } catch (err: any) {
      errorHandler.report(err);
      const reason = err instanceof Error ? err.message : 'UNKNOWN_ERROR';
      return dispatch({
        type: POST_PRESET_FAILED,
        payload: { name, params: parameters, apiType: currentApi },
        reason,
      });
    }
  };
}
