import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { get } from 'lodash';
import { useMemo } from 'react';
import { ITableConfig, ITableField } from '../../types';
import TableCell from '../TableCell/TableCell';
import TableRowActionsCell from '../TableRowActionsCell/TableRowActionsCell';
import { mapCellTypes } from './Table.columns';

export function useColumns(config: ITableConfig, onRowAction: any) {
  const { fields, rowActions, misc } = config;
  const { actionsWidth = 180 } = misc ?? {};

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  function renderCell(cellParams: GridRenderCellParams, field: ITableField) {
    const { row } = cellParams;

    return (
      <TableCell
        index={row.id}
        key={cellParams.field}
        field={field}
        data={row}
        onRowAction={() => {}}
        rowIndex={row.id}
      />
    );
  }

  function renderActionCell(cellParams: GridRenderCellParams) {
    const { row } = cellParams;

    return (
      <TableRowActionsCell actions={rowActions} onRowAction={onRowAction} rowIndex={0} item={row} />
    );
  }

  const columns: GridColDef[] = useMemo(() => {
    const output: GridColDef[] = fields.map((field) => {
      const { id, title, width, minWidth = 0, cellType, filterOptions = {} } = field;

      const valueOptions: any = Object.keys(filterOptions).map((o) => ({
        value: o,
        label: filterOptions[o],
      }));

      return {
        field: id,
        headerName: title,
        headerAlign: 'left',
        width,
        minWidth,
        align: 'left',
        type: (mapCellTypes as any)[cellType],
        valueGetter: (params: any) => {
          const value = get(params, `row.${id}`);

          switch (cellType) {
            case 'date':
            case 'dateTime':
              return new Date(value);
            default:
              return value;
          }
        },
        valueOptions,
        renderCell: (cellParams: GridRenderCellParams) => renderCell(cellParams, field),
      };
    });

    if (!rowActions?.length) {
      return output;
    }

    output.push({
      field: 'actions',
      headerName: '',
      type: 'actions',
      align: 'left',
      width: isMobile ? 80 : actionsWidth,
      hideable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (cellParams: GridRenderCellParams) => renderActionCell(cellParams),
    });

    return output;
  }, []);

  return columns;
}
