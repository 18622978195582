export function CheckIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <g clipPath='url(#clip0)'>
        <path
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='.652'
          d='M7.491 13.051L4.689 10.25l-.955.947 3.757 3.757 8.064-8.064-.947-.948-7.117 7.11z'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <path fill='#fff' d='M0 0H20V20H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
