import { ax } from '@ai21/studio-analytics';
import { api } from '@ai21/studio-api';
import { Form } from '@ai21/studio-forms';
import {
  IGenerationLine,
  actions,
  fixLineContent,
  selectors,
} from '@ai21/studio-store';
import { FileDrop, prompt, toast } from '@ai21/studio-ui';
import { call, delay, fork, put, select, take, takeEvery } from 'saga-ts';
import {
  downloadJson as downloadFile,
  downloadText,
  guid4,
  invokeEvent,
} from 'shared-base';
import { formDefaults, forms } from '../_definitions/forms';
import { GenerationProgressContainer } from '../components/GenerationProgress/GenerationProgress.container';
import { Json } from '../types';
import { jsonToCsv, readFile } from '../utils/file';
import { store } from '../utils/globals';
import { CompletionQueue } from '../utils/queue';
import { analytics } from './helpers/analytics';
import { createJob, updateCompletedLinesCount } from './helpers/job';

const MAX_GENERATION_JOB_LINES = 1000;

type Verb =
  | 'new'
  | 'delete'
  | 'copyId'
  | 'view'
  | 'settings'
  | 'evaluate'
  | 'start'
  | 'downloadJson'
  | 'downloadCsv'
  | 'navigate';

type ActionJob = {
  type: 'GENERATION_JOB';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  new: newJob,
  delete: deleteJob,
  copyId: copyId,
  settings: openJobSettings,
  view: viewJob,
  downloadJson: downloadJson,
  downloadCsv: downloadCsv,
  start: start,
  navigate: navigate,
  evaluate: evaluateJob,
};
const MAX_LINES_FOR_EVALUATION = 1000;

function* newJob(action: ActionJob) {
  const { value, didCancel } = yield prompt.custom({
    title: 'Upload set',
    component: FileDrop,
    componentProps: {
      acceptTypes: ['.csv', '.jsonl'],
      ctaButtonText: 'Select file',
      warning:
        'Using generation-sets is subject to the API usage pricing associated with your account.',
      comment: `The acceptable formats are JSONL or CSV only, max items of 1,000 in one batch and max file size can't be more than 10MB. Download the [example CSV file](/generation.example.csv) or get help in our [Technical documentation](https://docs.ai21.com/docs/generation-sets-handbook).`,
    },
    componentCta: 'onDrop',
    intention: 'upload',
  });

  if (didCancel || !value) {
    return;
  }

  const file = value[0];
  const fileName = file.name;
  let content: any = yield* call(readFile, file);

  if (!content) {
    yield call(newJobError, "Failed to read file's content.");
    return;
  }

  if (!Array.isArray(content)) {
    yield call(newJobError, 'File must be an array of lines.');
    return;
  }

  if (content.length >= MAX_GENERATION_JOB_LINES) {
    yield call(
      newJobError,
      `File must have less than ${MAX_GENERATION_JOB_LINES} lines.`
    );
    return;
  }

  content = fixLineContent(content);

  if (!content[0].prompt) {
    yield call(
      newJobError,
      'Missing field names: file must have a "prompt" and "model" field in each line.'
    );
    return;
  }

  const allOptions = yield* select(selectors.options.$allOptions);
  const user = yield* select(selectors.raw.$rawUser);

  const newJobId = guid4();

  const { didCancel: didCancel2, value: value2 } = yield prompt.form({
    component: Form,
    title: 'Name your generation set',
    form: {
      config: forms.generations,
      data: {
        ...formDefaults.generationsDefault,
        uploader: user.userName,
        name: `Set #${newJobId}`,
        id: newJobId,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
    },
  });

  if (didCancel2) {
    return;
  }

  const { id, name, description } = value2;

  yield call(
    createJob,
    content as Json[],
    {
      id,
      name,
      description,
      creator: user.userName,
      setSource: 'upload',
      setSourceId: '',
    } as any
  );
}

function* start(action: ActionJob) {
  const { id } = action;

  const job = yield* select(selectors.singles.$generationJob, id);
  const lines = yield* select(selectors.singles.$generationLines, id);
  const customModelsMap = yield* select(selectors.generation.$customModelsMap);

  if (!job) {
    return;
  }

  const emptyLines = lines.filter((line: IGenerationLine) => !line.output);

  if (emptyLines.length === 0) {
    toast.show('All lines have been generated');
    return;
  }

  yield put(
    actions.appState.patch({
      progressTotal: emptyLines.length,
    })
  );

  const queue = new CompletionQueue(10, id, customModelsMap);

  for (let line of emptyLines) {
    queue.addRequest(line.id, line.input, {
      modelName: line.modelName ?? job.modelName,
      temperature: line.temperature ?? job.temperature,
      maxTokens: line.maxTokens ?? job.maxTokens,
      topP: line.topP ?? job.topP,
      frequencyPenalty: line.frequencyPenalty ?? job.frequencyPenalty,
      stopSequences: line.stopSequences ?? job.stopSequences,
    });
  }

  yield* fork(showProgress, id);

  yield queue.start({
    onResponse: (lineId: string, completion: string) => {
      store.dispatch(
        actions.generationLines.patch(lineId, {
          output: completion,
          setId: id,
        })
      );
    },
    onProgress: (completedCount: number) => {
      store.dispatch(
        actions.appState.patch({ progressCompleted: completedCount })
      );
    },
  });

  invokeEvent('closeProgress');

  yield put(
    actions.appState.patch({
      progressCompleted: 0,
      progressTotal: 0,
    })
  );

  yield* fork(updateCompletedLinesCount, id);
}

function* showProgress(jobId: string) {
  yield prompt.custom({
    component: GenerationProgressContainer,
  });

  const lines = yield* select(selectors.singles.$generationLines, jobId);
  const emptyLines = lines.filter((line: IGenerationLine) => !line.output);

  const status = emptyLines.length === 0 ? 'Completed' : 'Incomplete';

  yield put(actions.generationJobs.patch(jobId, { status }));
}

function* viewJob(action: ActionJob) {
  yield put({
    type: 'NAVIGATE',
    to: `/tools/generation-sets/${action.id}/lines`,
  });
}

function* navigate(action: ActionJob) {
  const { to } = action.params || {};

  yield put({
    type: 'NAVIGATE',
    to,
  });
}

function* newJobError(reason: string) {
  toast.show(reason, 'error');

  yield* call(analytics, {
    action: 'upload',
    actionValue: reason,
    eventId: 'GenerationUploadError',
  });
}

function* evaluateJob(action: ActionJob) {
  const { id } = action;

  if (!id) {
    return;
  }

  const lines = yield* select(selectors.singles.$generationLines, id);
  const linesCount = lines.length;

  if (linesCount > MAX_LINES_FOR_EVALUATION) {
    toast.show(
      `Evaluating sets with more than ${MAX_LINES_FOR_EVALUATION} lines is not supported`,
      'error'
    );
    return;
  }

  const response: any = yield* call(
    api.generation.transformToEvaluationSet,
    id
  );

  if (!response?.isSuccess) {
    toast.show('Failed to create evaluation job for generation set', 'error');
    return;
  }

  yield put(actions.evaluationJobs.get({}));
  yield delay(500);

  yield put({
    type: 'NAVIGATE',
    to: `tools/evaluation/${response.data.id}/inbox`,
  });
}

function* deleteJob(action: ActionJob) {
  const { id } = action;

  const job = yield* select(selectors.singles.$generationJob, id);

  const { name } = job;

  const { didCancel } = yield prompt.confirm({
    title: 'Delete set',
    description: `Are you sure you want to delete generation set "${name}"?`,
    ctaButtonText: 'Delete Job',
    intention: 'delete',
  });

  if (didCancel) {
    return;
  }

  yield put(
    actions.generationJobs.patch(id, {
      isDeleted: true,
    })
  );

  if (document.location.pathname.includes('lines')) {
    yield put({
      type: 'NAVIGATE',
      to: '/tools/generation-sets',
    });
  }
}

function* copyId(action: ActionJob) {
  const { id } = action;
  navigator.clipboard.writeText(id);
  toast.show('Copied to clipboard', { type: 'success' });
}

function* downloadJson(action: ActionJob) {
  const { id } = action;

  yield* put(actions.generationLines.get({ setId: id }));
  yield take('SET_GENERATIONLINES');

  try {
    const lines = yield* select(selectors.raw.$rawGenerationLines);
    const filtersLines = Object.values(lines).filter(
      (line: any) => line.setId === id
    );

    const fileName = `job-${id}.json`;

    downloadFile(`job-${id}.json`, filtersLines);

    ax.nudge('generationSets.downloads');

    yield* call(analytics, {
      action: 'downloadJson',
      actionValue: fileName,
      eventId: 'GenerationSetDownloaded',
    });
  } catch (err) {
    yield* call(analytics, {
      action: 'downloadJson',
      actionValue: `Failed to download to json, error: ${err}`,
      eventId: 'GenerationSetDownloadFailed',
    });
  }
}

function* downloadCsv(action: ActionJob) {
  const { id } = action;

  yield* put(actions.generationLines.get({ setId: id }));
  yield take('SET_GENERATIONLINES');

  const lines = yield* select(selectors.raw.$rawGenerationLines);
  const filtersLines = Object.values(lines).filter((line) => line.setId === id);

  const csv = jsonToCsv(filtersLines);

  if (!csv) {
    toast.show('Failed to convert to CSV', 'error');
    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: 'Failed to convert to CSV',
      eventId: 'generationSetDownloadFailed',
    });
    return;
  }

  try {
    const fileName = `job-${id}.csv`;
    downloadText(fileName, csv);

    ax.nudge('generationSets.downloads');

    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: fileName,
      eventId: 'GenerationSetDownloaded',
    });
  } catch (err) {
    yield* call(analytics, {
      action: 'downloadCsv',
      actionValue: `Failed to download CSV, error: ${err}`,
      eventId: 'GenerationSetDownloadFailed',
    });
  }
}

export function* openJobSettings(action: ActionJob) {
  const { id } = action;
  const allOptions = yield* select(selectors.options.$allOptions);
  const job = yield* select(selectors.singles.$generationJob, id);

  if (!job) {
    return;
  }

  const { didCancel, value } = yield prompt.form({
    component: Form,
    title: 'Name your generation set',
    form: {
      config: forms.generationsEdit,
      data: {
        ...job,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
      isEdit: true,
    },
  });

  if (didCancel) {
    return;
  }

  yield put(actions.generationJobs.patch(id, value));

  toast.show('Job settings updated', 'success');
}

export function* generationJob(action: ActionJob) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('GENERATION_JOB', generationJob);
}
