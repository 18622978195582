import { Button, Box, Dialog, DialogActions, Grid, Typography, icons } from '@ai21/studio-ui';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DATASET_ILLEGAL_FORMAT_ERROR_MESSAGE,
  DATASET_MIN_ROWS_ERROR_MESSAGE,
  DATASET_UNKNOWN_EXTENSION_ERROR_MESSAGE,
  DUPLICATE_DATASET_NAME_ERROR_MESSAGE,
  EMPTY_COMPLETION_ERROR_MESSAGE,
  INVALID_DATASET_NAME_ERROR_MESSAGE,
} from '../../constants/constants';
import { SelectedColumns } from '../../data-types/Dataset';
import {
  MESSAGE_DISMISSED,
  MODAL_DISMISSED,
  MODAL_SHOWN,
} from '../../redux/actionTypes/userActionTypes';
import { ModelsPageState } from '../../redux/reducers/customModels';
import { DatasetsPageState } from '../../redux/reducers/datasets';
import {
  getUploadDatasetColumnsName,
  getUploadDatasetErrorMessage,
  getUploadDatasetName,
} from '../../redux/selectors';
import { tokens } from '@ai21/studio-ui';
import { errorHandler } from '../../utils/error-reporting';
import { DatasetValidationChooseColumns } from '../datasets.DatasetValidationChooseColumns/DatasetValidationChooseColumns';
import { DatasetValidationDeleteLongRows } from '../datasets.DatasetValidationDeleteLongRows/DatasetValidationDeleteLongRows';
import { DatasetValidationFatalError } from '../datasets.DatasetValidationFatalError/DatasetValidationFatalError';
import { DatasetValidationImproveData } from '../datasets.DatasetValidationImproveData/DatasetValidationImproveData';
import { ModelsAndDatasetsGeneralErrorStatePage } from '../models.ModelsAndDatasetsGeneralErrorStatePage/ModelsAndDatasetsGeneralErrorStatePage';
import DatasetUploadStyles from './DatasetUploadStyles';

export interface UploadDatasetsDialogErrorStateProps {
  onClose: () => void;
  state: DatasetsPageState | ModelsPageState;
  ReUploadFile: () => void;
  saveDataset: (props: {
    name?: string;
    selectedColumns?: SelectedColumns;
    approveWhitespaceCorrection?: boolean;
    deleteLongRows?: boolean;
  }) => void;
}

export function UploadDatasetsDialogErrorState({
  onClose,
  state,
  ReUploadFile,
  saveDataset,
}: UploadDatasetsDialogErrorStateProps) {
  const datasetName = useSelector(getUploadDatasetName);
  const uploadDatasetColumnsName = useSelector(getUploadDatasetColumnsName);
  const uploadDatasetErrorMessage = useSelector(getUploadDatasetErrorMessage);
  const [continueButtonEnabled, setContinueButtonEnabled] = useState(false);
  const [columnsNames, setColumnsNames] = useState<SelectedColumns | undefined>(undefined);
  const dispatch = useDispatch();
  const classes = DatasetUploadStyles();

  useEffect(() => {
    dispatch({
      type: MODAL_SHOWN,
      payload: { origin: 'dataset', text: state, name: 'datasetError' },
    });

    return () => {
      dispatch({
        type: MODAL_DISMISSED,
        payload: { origin: 'dataset', reason: 'next step', name: 'nameDataset' },
      });
    };
  }, []);

  let nodeToRender;
  let buttonAction;
  switch (state) {
    case DatasetsPageState.INVALID_DATASET_NAME_ERROR:
    case ModelsPageState.INVALID_DATASET_NAME_ERROR:
      nodeToRender = (
        <DatasetValidationFatalError
          datasetName={datasetName}
          errorMessage={INVALID_DATASET_NAME_ERROR_MESSAGE}
        />
      );
      break;
    case DatasetsPageState.EMPTY_COMPLETION:
      nodeToRender = (
        <DatasetValidationFatalError
          datasetName={datasetName}
          errorMessage={EMPTY_COMPLETION_ERROR_MESSAGE}
        />
      );
      break;
    case DatasetsPageState.DUPLICATE_DATASET_NAME_ERROR:
    case ModelsPageState.DUPLICATE_DATASET_NAME_ERROR:
      nodeToRender = (
        <DatasetValidationFatalError
          datasetName={datasetName}
          errorMessage={DUPLICATE_DATASET_NAME_ERROR_MESSAGE}
        />
      );
      break;
    case DatasetsPageState.DATASET_UNKNOWN_EXTENSION_ERROR:
    case ModelsPageState.DATASET_UNKNOWN_EXTENSION_ERROR:
      nodeToRender = (
        <DatasetValidationFatalError
          datasetName={datasetName}
          errorMessage={DATASET_UNKNOWN_EXTENSION_ERROR_MESSAGE}
        />
      );
      break;
    case DatasetsPageState.DATASET_ILLEGAL_FORMAT_ERROR:
    case DatasetsPageState.DATASET_COLUMNS_NAME_NOT_EXIST_ERROR:
    case ModelsPageState.DATASET_ILLEGAL_FORMAT_ERROR:
    case ModelsPageState.DATASET_COLUMNS_NAME_NOT_EXIST_ERROR:
      nodeToRender = (
        <DatasetValidationFatalError
          datasetName={datasetName}
          errorMessage={
            uploadDatasetErrorMessage
              ? `Illegal Format: ${uploadDatasetErrorMessage}`
              : DATASET_ILLEGAL_FORMAT_ERROR_MESSAGE
          }
        />
      );
      break;
    case DatasetsPageState.DATASET_MIN_ROWS_ERROR:
    case ModelsPageState.DATASET_MIN_ROWS_ERROR:
      nodeToRender = (
        <DatasetValidationFatalError
          datasetName={datasetName}
          errorMessage={DATASET_MIN_ROWS_ERROR_MESSAGE}
        />
      );
      buttonAction = undefined;
      break;
    case DatasetsPageState.DATASET_COLUMNS_NOT_SPECIFIED_ERROR:
    case ModelsPageState.DATASET_COLUMNS_NOT_SPECIFIED_ERROR:
      buttonAction = (
        <Button
          color='primary'
          variant='contained'
          size='medium'
          disabled={!continueButtonEnabled}
          style={{ marginLeft: 8 }}
          onClick={() => {
            dispatch({
              type: MESSAGE_DISMISSED,
              payload: {
                origin: 'datasetUploadError',
                type: 'error',
                reason: 'fixPressed',
                text: 'Select the desired columns to continue',
                name: 'COLUMNS_NOT_SPECIFIED',
              },
            });
            saveDataset({ selectedColumns: columnsNames });
          }}
          data-testid='columns-not-specified-continue-btn'
        >
          Continue
        </Button>
      );
      nodeToRender = (
        <DatasetValidationChooseColumns
          datasetColumns={uploadDatasetColumnsName}
          datasetName={datasetName}
          setContinueButtonEnabled={setContinueButtonEnabled}
          setColumnsNames={setColumnsNames}
        />
      );
      break;
    case DatasetsPageState.DATASET_MULTIPLE_WHITESPACE_ERROR:
    case ModelsPageState.DATASET_MULTIPLE_WHITESPACE_ERROR:
      nodeToRender = (
        <DatasetValidationImproveData
          datasetName={datasetName}
          fixWhitespaces={(shouldFix: boolean) =>
            saveDataset({ approveWhitespaceCorrection: shouldFix })
          }
        />
      );
      break;
    case DatasetsPageState.DATASET_TO_LONG_ROWS_ERROR:
    case ModelsPageState.DATASET_TO_LONG_ROWS_ERROR:
      nodeToRender = <DatasetValidationDeleteLongRows datasetName={datasetName} />;
      buttonAction = (
        <Button
          color='primary'
          variant='contained'
          size='medium'
          style={{ marginLeft: 8 }}
          onClick={() => saveDataset({ deleteLongRows: true })}
          data-testid='optimize-results-btn'
        >
          Optimize Results
        </Button>
      );
      break;
    default:
      errorHandler.report(
        `DatasetsUploadingErrorStatePage failed to determine state while uploading the dataset ${
          datasetName || ''
        } Received state: ${state}`
      );
      nodeToRender = <ModelsAndDatasetsGeneralErrorStatePage />;
      break;
  }

  return (
    <div className={classes.dialogContainer}>
      <Grid container justifyContent='space-between'>
        <Typography className={classes.title} variant='h5'>
          Upload dataset file
        </Typography>
      </Grid>
      <Box marginTop='16px' />
      {nodeToRender}
      <Box marginTop='16px' />
      <DialogActions sx={{ marginBottom: 1, justifyContent: 'space-between' }}>
        <Button
          color='secondary'
          size='small'
          onClick={ReUploadFile}
          style={{
            padding: 8,
            color: `${tokens?.text?.secondary ? tokens?.text?.secondary : '#676465'}`,
          }}
        >
          <icons.UploadFile />
          <Typography style={{ fontSize: 14, marginLeft: 8 }} variant='subtitle1'>
            Re-upload fixed file
          </Typography>
        </Button>
        <div>
          <Button
            data-testid='dataset-cancel-dialog-btn'
            variant='outlined'
            size='medium'
            color='secondary'
            onClick={onClose}
          >
            Cancel
          </Button>
          {buttonAction}
        </div>
      </DialogActions>
    </div>
  );
}
