import { styled } from '@ai21/studio-base-ui';
import { layout } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  padding-bottom: 5vh;
  max-width: calc(100vw - 230px);
  margin: auto;

  &.sticky {
    padding-top: 160px;
  }

  &.hideHeader {
    padding-top: 0;
  }
`;

export const Container = styled(layout.Container)``;
