import styled from 'styled-components';
import { tokens } from '../../theme';
import { Typography } from '@mui/material';

export const Wrapper = styled.div`
  border-radius: ${tokens.primitives.radius.large}px;
  border: 1px solid ${tokens.primary.borderDefault};
  background: ${tokens.primary.backgroundDefault};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 12px;
  width: 100%;
  cursor: pointer;
  background-color: #f8f7f4;

  &:hover {
    border: 1px solid ${tokens.primary.borderHover};
    background: ${tokens.primitives.beige[50]};
    border-color: ${tokens.primary.borderHover};
    border-radius: 12px;
    box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.1),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
  }

  &:active {
    background: ${tokens.primitives.beige[100]};
    border-color: ${tokens.primary.borderActive};
    box-shadow: none;
    user-select: none;
  }
`;

export const CardMedia = styled.div`
  border-top-left-radius: ${tokens.primitives.radius.large}px;
  border-top-right-radius: ${tokens.primitives.radius.large}px;
  height: 140px;
  width: 100%;
`;

export const CardContent = styled.div`
  padding: 0px 18px 16px 18px;
  align-items: flex-start;
`;

export const CardTags = styled.div`
  div {
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    background-color: ${tokens.primary.backgroundDefault};
    height: 26px;

    span {
      padding: 0 8px;
    }
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 8px;
`;

export const Subtitle = styled(Typography)``;
