import { IMenuItem } from '@ai21/studio-ui';

export const menuItems: IMenuItem[] = [
  {
    id: 'downloadCollectionCsv',
    title: 'Export as .csv',
    iconName: 'Download',
  },
  {
    id: 'downloadCollectionJson',
    title: 'Export as .jsonl',
    iconName: 'Download',
  },
  {
    id: 'navigateCollectionPage',
    title: 'Open',
    iconName: 'ArrowUpLeft',
  },
];
