import {
  IWidgetConfig,
  Input,
  InputToPayload,
  ResponseToOutput,
  WidgetPayload,
  OutputToAnalyticsPayload,
} from '../MiniPlayground.types';

export const config: IWidgetConfig = {
  title: 'Grammatical Error Corrections API',
  ctaText: 'Correct My Text',
  tooltipText: 'API supports texts up to 500 characters',
  defaultInput: {
    main: 'At times, my job can be quite monogamous. I’m not aloud to do anything creative.',
  },
  docsUrl: 'https://docs.ai21.com/reference/gec-api-ref',
  inputType: 'basic',
  outputHeight: 248,
  outputType: 'replace',
  apiMethodName: 'gec',
  snippetMethodName: 'gec',
  rules: [
    {
      ruleType: 'nonEmpty',
      errorMessage: 'Text must not be empty',
    },
    {
      ruleType: 'maxLength',
      errorMessage:
        'Demo is limited to {value} characters. Use the API to correct texts up to 500 characters.',
      value: 200,
      linkText: 'Use the API',
      linkUrl: 'https://docs.ai21.com/reference/gec-api-ref',
    },
  ],
};

export const inTransformer: InputToPayload = (input: Input) => ({
  text: input.main,
});

export const analyticsInputPayload = (input: Input) => ({
  textLength: input.main.length,
});

export const outTransformer: ResponseToOutput = (response: Json, input: Input) => {
  const { data, isSuccess } = response;

  return {
    replacements: data.corrections.map((correction: any) => {
      return {
        originalText: correction.originalText,
        suggestions: [correction.suggestion],
        startIndex: correction.startIndex,
        endIndex: correction.endIndex,
      };
    }),
    originalInput: input,
    errorMessage: data.detail,
    isSuccess,
    count: data.corrections.length,
    unit: 'results',
  };
};

export const analyticsOutputPayload: OutputToAnalyticsPayload = (response: Json) => {
  const { data, duration, request } = response;
  return {
    numSuggestions: data.corrections.length,
    errorMessage: data.detail,
    requestDuration: duration,
    interactionId: data.id,
    endpoint: request.path,
  };
};

export const payload: WidgetPayload = {
  id: 'gec',
  config,
  inTransformer,
  outTransformer,
  analyticsInputPayload,
  analyticsOutputPayload,
};
