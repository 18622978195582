export const ThreeDotsIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='#626B78'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 11.75C4.86193 11.75 4.75 11.8619 4.75 12C4.75 12.1381 4.86193 12.25 5 12.25C5.13807 12.25 5.25 12.1381 5.25 12C5.25 11.8619 5.13807 11.75 5 11.75ZM3.25 12C3.25 11.0335 4.0335 10.25 5 10.25C5.9665 10.25 6.75 11.0335 6.75 12C6.75 12.9665 5.9665 13.75 5 13.75C4.0335 13.75 3.25 12.9665 3.25 12ZM12 11.75C11.8619 11.75 11.75 11.8619 11.75 12C11.75 12.1381 11.8619 12.25 12 12.25C12.1381 12.25 12.25 12.1381 12.25 12C12.25 11.8619 12.1381 11.75 12 11.75ZM10.25 12C10.25 11.0335 11.0335 10.25 12 10.25C12.9665 10.25 13.75 11.0335 13.75 12C13.75 12.9665 12.9665 13.75 12 13.75C11.0335 13.75 10.25 12.9665 10.25 12ZM19 11.75C18.8619 11.75 18.75 11.8619 18.75 12C18.75 12.1381 18.8619 12.25 19 12.25C19.1381 12.25 19.25 12.1381 19.25 12C19.25 11.8619 19.1381 11.75 19 11.75ZM17.25 12C17.25 11.0335 18.0335 10.25 19 10.25C19.9665 10.25 20.75 11.0335 20.75 12C20.75 12.9665 19.9665 13.75 19 13.75C18.0335 13.75 17.25 12.9665 17.25 12Z'
      />
    </svg>
  );
};
