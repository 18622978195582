import { tokens, Typography } from '@ai21/studio-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  & .BaseEditor-wrapper {
    .MuiTextField-root {
      background-color: #fff !important;
      border: 1px solid ${tokens.primary.borderDefault};
      border-radius: ${tokens.primitives.radius.medium}px;
      max-height: 200px;

      &:hover {
        border-color: ${tokens.primary.borderHover};
      }

      &:focus-within {
        border-color: ${tokens.primary.mainDefault};
        box-shadow: 0px 0px 0 1px ${tokens.primary.mainDefault};
      }
    }

    .content {
      &:hover,
      &:active {
        border-color: transparent;
      }
    }

    textarea {
      height: 150px !important;
    }
  }
`;

export const Label = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  /* text-transform: capitalize; */
  font-size: 14px;
  color: ${tokens.neutral.deepest};
  margin-bottom: 6px;

  span {
    display: flex;
    align-items: center;
  }

  svg {
    height: 14px;
    width: 14px;
    color: ${tokens.text.secondary};
  }
`;
