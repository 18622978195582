export function RightNavigationIcon({ enabled }: { enabled: boolean }) {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill={enabled ? '#626B78' : '#a0a2a8'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M9.323 5 8.148 6.175 11.965 10l-3.817 3.825L9.323 15l5-5-5-5z' />
    </svg>
  );
}
