import { logEvent } from '@ai21/studio-analytics';
import { LoginContext } from '@ai21/studio-auth';
import { actions, selectors } from '@ai21/studio-store';
import { IMenuItem, MenuBuilder, TopBar } from '@ai21/studio-ui';
import { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { navigate } from '../sagas/saga.navigation';
import { initTopBar } from './Web.apps';
import { notifications } from './Notifications.data';
import { hideMenuPaths } from './SideBar.data';

export function TopBarContainer() {
  const dispatch = useDispatch();
  const { userEmail, userName } = useContext(LoginContext).state;
  const location = useLocation();
  const isSlim = useSelector(selectors.raw.$rawAppState).slimSideBar;

  const config = useMemo(() => {
    const menuBuilder = new MenuBuilder();
    menuBuilder.withEmail(userEmail);
    initTopBar(menuBuilder);
    return menuBuilder.build();
  }, [userEmail, userName]);

  const callbacks = useMemo(
    () => ({
      onHome: () => {
        navigate('/home');

        logEvent('NavigatorPressed', {
          buttonText: 'Home',
          path: document.location.pathname,
        });
      },
      onClick: (item: IMenuItem) => {
        const { path, href, actionType } = item;

        if (actionType) {
          dispatch({ type: actionType });
        } else {
          navigate(path || href!);
        }
      },
      onInfoClick: () => {},
      onToggleSlim: (isSlim: boolean) => {
        dispatch(actions.appState.patch({ slimSideBar: isSlim }));
      },
    }),
    []
  );

  if (hideMenuPaths.find((path) => path.startsWith(location.pathname.split('/')[1]))) {
    return null;
  }

  return (
    <TopBar
      userName={userName}
      userEmail={userEmail}
      isSlim={isSlim}
      items={config.items}
      notifications={notifications}
      callbacks={callbacks}
    />
  );
}
