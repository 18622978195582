/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Typography, alpha, makeStyles, icons } from '@ai21/studio-ui';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { FILE_MAX_SIZE } from '../../constants/constants';
import { FETCH_DATASET_URL_SUCCESS } from '../../redux/actionTypes/datasetsActionTypes';
import { getExampleDatasetUrl } from '../../redux/selectors';
import { downloadUrl, getFile } from '../../utils/fileUtils';

const useStyles = makeStyles((theme) => ({
  dndBox: {
    borderRadius: 8,
    border: '1px dashed #a8aeb8',
    padding: '36px 54px',
    outline: 'none !important',
    position: 'relative',
    zIndex: 10,
    '&:hover $dropIcon': {
      backgroundColor: 'rgba(233, 30, 99, 0.08)',
    },
  },
  outer: {
    borderRadius: 8,
    zIndex: 1,
    position: 'relative',
    transition: 'all 0.12s',
    overflow: 'hidden',
  },
  activeDrop: {
    borderRadius: 0,
    background: '#FDEDF3',
  },
  dropIcon: {
    borderRadius: '100%',
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    width: 80,
    height: 80,
    transition: 'all 0.12s',
    cursor: 'pointer',
  },
  link: {
    cursor: 'pointer',
  },
  helpLink: {
    cursor: 'pointer',
    opacity: 1,
    transition: 'all 0.12s',
    '&:hover': {
      opacity: 0.8,
    },
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0,0,0,0.1)',
    zIndex: 5,
  },
  spinner: {
    animation: 'rotation 2s infinite linear',
    animationName: '$Spinner',
    animationDuration: '.8s',
  },
  '@keyframes Spinner': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  alert: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    background: '#333333',
    color: 'white',
    borderRadius: '0 0 4px 4px',
    zIndex: 20,
    padding: '8px 16px',
    opacity: 0,
    top: -50,
    width: 'max-content',
    maxWidth: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  visibleAlert: {
    top: 0,
    opacity: 1,
    transition: 'all 0.2s',
  },
  textField: {
    background: theme.palette.background.default,
  },
  input: {
    borderRadius: '4px 0 0 4px',
  },
  dropAnywhereIcon: {
    marginBottom: theme.spacing(2),
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    '& svg': {
      marginRight: '2px',
      marginBottom: '2px',
    },
  },
  optionTypography: {
    color: alpha(theme.palette.primary.main, 0.5),
  },
  button: {
    flexShrink: 0,
    borderRadius: '0 4px 4px 0',
    padding: 0,
  },
}));

export interface DropzoneProps {
  onFileSelected: CallableFunction;
  error: string | null;
  useFullDimensions?: boolean;
}

export function Dropzone({ onFileSelected, error = null }: DropzoneProps) {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const exampleDatasetUrl = useSelector(getExampleDatasetUrl);
  const dispatch = useDispatch();
  const componentRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (exampleDatasetUrl) {
      downloadUrl(exampleDatasetUrl);
      dispatch({ type: FETCH_DATASET_URL_SUCCESS, url: undefined });
    }
  }, [exampleDatasetUrl]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length) {
        setFileError(
          fileRejections.length === 1
            ? 'Studio only supports .CSV or .JSONL files less than 1GB'
            : 'Studio only works on 1 document at a time'
        );
        return;
      }
      setFileError(null);
      onFileSelected(acceptedFiles[0], 'fileDrop');
    },
    [onFileSelected]
  );

  const onChoose = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      try {
        const file = getFile(event);
        onFileSelected(file, 'fileBrowse');
      } catch (err: any) {
        setFileError((err as Error).message);
      }
    },
    [onFileSelected]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: FILE_MAX_SIZE,
    accept: '.csv, .jsonl',
  });

  useEffect(() => {
    if (isDragActive) {
      setFileError(null);
    }
  }, [isDragActive]);

  return (
    <div ref={componentRef}>
      <Box className={classes.outer + (isDragActive ? ` ${classes.activeDrop}` : '')}>
        <Box className={classes.alert + (error || fileError ? ` ${classes.visibleAlert}` : '')}>
          <Box marginRight={2} flexGrow={0} flexShrink={0}>
            <Typography variant='subtitle2'>⚠️</Typography>
          </Box>
          <Box flexGrow={1}>
            <Typography variant='subtitle2'>{error?.toString() || fileError}</Typography>
          </Box>
          <Box flexGrow={1}>
            <Typography variant='subtitle2'>{error?.toString() || fileError}</Typography>
          </Box>
        </Box>
        <Box
          className={classes.dndBox}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          {...getRootProps({ onClick: (event) => event.stopPropagation() })}
        >
          <>
            <input {...getInputProps()} />
            <Box marginTop={2} />
            <icons.DropAnywhereModal style={{ color: isDragActive ? '#753FEA' : '#626B78' }} />
            <Box marginBottom={2} />
            <Typography variant='subtitle2' style={{ marginBottom: 18 }}>
              Drag your JSONL or CSV file to upload or
            </Typography>
            <div style={{ height: 32 }}>
              {isDragActive ? (
                <Typography variant='subtitle1' style={{ marginBottom: 18 }} color='primary'>
                  → Drag and release here ←
                </Typography>
              ) : (
                <Button
                  data-testid='FileInputButton'
                  variant='contained'
                  color='primary'
                  size='medium'
                  onClick={() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  <input
                    ref={fileInputRef}
                    type='file'
                    onChange={onChoose}
                    accept='.csv, .jsonl'
                    data-testid='FileInput'
                    hidden
                  />
                  <Typography>Select File</Typography>
                </Button>
              )}
            </div>
          </>
        </Box>
      </Box>
    </div>
  );
}
