interface CalculatorIconProps {
  width?: number;
  height?: number;
}

export function CalculatorIcon({ width = 24, height = 24 }: CalculatorIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 57 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#1xs03zeksa)' strokeWidth='1.034' strokeLinejoin='round'>
        <path
          d='M41.246 13.366h-30.74a.662.662 0 0 0-.661.662v31.2c0 .365.296.662.662.662h30.74a.662.662 0 0 0 .661-.662v-31.2a.662.662 0 0 0-.662-.662z'
          fill='#000'
          stroke='#000'
          strokeLinecap='round'
        />
        <path
          d='M45.227 13.366h-30.74a.662.662 0 0 0-.661.662v31.2c0 .365.296.662.662.662h30.74a.662.662 0 0 0 .661-.662v-31.2a.662.662 0 0 0-.662-.662z'
          fill='#fff'
          stroke='#000'
          strokeLinecap='round'
        />
        <path
          d='M25.934 26.984h-7.005l3.794-8.828h7.005l-3.794 8.828zM32.386 26.984h-3.31l3.8-8.828h3.31l-3.8 8.828z'
          fill='#D53864'
          stroke='#D53864'
          strokeLinecap='round'
        />
        <path
          d='m19.895 33.518 6.41 6.41M26.306 33.518l-6.41 6.41M41.309 34.02H32.68M41.309 38.828H32.68'
          stroke='#D53864'
        />
        <path
          d='M41.076 17.87H18.639a.662.662 0 0 0-.662.662v7.78c0 .366.296.662.662.662h22.437a.662.662 0 0 0 .662-.662v-7.78a.662.662 0 0 0-.662-.662z'
          stroke='#000'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='1xs03zeksa'>
          <path fill='#fff' transform='translate(9.328 12.849)' d='M0 0h37.078v33.557H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
