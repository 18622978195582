// filter only relevant process.env variables
const filteredProcessEnv = Object.keys(process.env).reduce((cur, key) => {
  if (key.startsWith("VITE_")) {
    cur = {
      ...cur,
      [key]: process.env[key],
    };
  }

  return cur;
}, {});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.__env__ = { ...filteredProcessEnv, ...window.deploymentEnvVars };
