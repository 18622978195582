import { Icon } from '@ai21/studio-base-ui';
import { Question, QuestionText, Wrapper } from './ExampleQuestions.style';
import { invokeEvent } from 'shared-base';
import { useContext } from 'react';
import { AskContext } from '../../AskEditor.context';

export type ExampleQuestionsProps = {
  questions: string[];
};

export function ExampleQuestions(props: ExampleQuestionsProps) {
  const { questions } = props;
  const { callbacks } = useContext(AskContext);

  function askQuestion(question: string) {
    callbacks.onPrompt({ prompt: question });
  }

  function renderQuestion(question: string) {
    return (
      <Question key={question} className='question' onClick={() => askQuestion(question)}>
        <QuestionText variant='body1bold'>{question}</QuestionText>
        <Icon iconName='Send' />
      </Question>
    );
  }

  function renderQuestions() {
    return questions.map((question: string) => renderQuestion(question));
  }

  return (
    <Wrapper className='ExampleQuestions-wrapper' data-testid='ExampleQuestions-wrapper'>
      {renderQuestions()}
    </Wrapper>
  );
}

export default ExampleQuestions;
