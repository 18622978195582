import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 16px;
  line-height: 16px;
  margin: 8px 0;
`;

export const IconWrapper = styled.div`
  svg {
    color: ${tokens.text.secondary};
    width: 15px;
    margin-top: -3px;
    margin-right: 8px;
  }
`;

export const Link = styled.div`
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  color: ${tokens.text.secondary};
`;
