import { actions, IBox, selectors } from '@ai21/studio-store';
import { prompt as dialog } from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, put, select, take } from 'saga-ts';
import { checkEmptyPrompt } from '../../utils/boxes';
import { customEvenChannel } from '../channels/channel.customEvent';

export function* patchBox(boxRef: IBox, change?: any) {
  const box = yield* select(selectors.singles.$box, boxRef.id);
  if (!change || !box) {
    return;
  }

  const { values } = box;

  const newValues = { ...values, ...change };

  yield put(actions.boxes.patch(box.id, { values: newValues }));
}

type IBoxAlert = {
  title: string;
  type: 'success' | 'error' | 'warning' | 'info';
  ctas: string[];
};

export function* showAlert(boxRef: IBox, alertData: IBoxAlert) {
  yield* call(patchBox, boxRef, {
    alertData: alertData,
  });
}

let channel;

// show alert and wait for user to response
export function* showAlertSync(boxRef: IBox, alertData: IBoxAlert) {
  yield* call(patchBox, boxRef, {
    alertData: alertData,
  });
  channel = customEvenChannel('alert/click');
  const { data } = yield take(channel);
  const { verb, ctaIndex } = data;

  channel.close();

  return {
    didCancel: verb === 'dismiss',
    ctaIndex,
  };
}

export function* clearAlert(boxRef: IBox) {
  yield* call(patchBox, boxRef, {
    alertData: null,
  });
}

export function* warnUnsaved() {
  const inputBox = yield* select(selectors.playground.$inputBox);
  const prompt = get(inputBox, 'values.prompt', '');

  const isEmpty = checkEmptyPrompt(prompt);

  if (isEmpty) {
    return false;
  }

  const { didCancel } = yield dialog.confirm({
    title: 'You have unsaved changes',
    description: 'Changes you made will be lost unless you save them.',
    ctaButtonText: 'Save Changes',
    cancelButtonText: 'Continue without saving',
    intention: 'load',
  });

  return !didCancel;
}

export function* warnCollectionImport() {
  const inputBox = yield* select(selectors.playground.$inputBox);
  const prompt = get(inputBox, 'values.prompt', '');

  const isEmpty = checkEmptyPrompt(prompt);

  if (isEmpty) {
    return true;
  }

  const { didCancel } = yield dialog.confirm({
    title: 'Load to playground?',
    description:
      'Loading a saved item from the collection will overwrite the active input and output.',
    ctaButtonText: 'Continue',
    cancelButtonText: 'Cancel',
    intention: 'load',
  });

  return !didCancel;
}

export function* clearBoxes() {
  const inputBoxes = yield* select(selectors.playground.$inputBoxes);
  const outputBoxes = yield* select(selectors.playground.$outputBoxes);

  for (let box of inputBoxes) {
    yield* call(patchBox, box, { prompt: '' });
  }

  for (let box of outputBoxes) {
    yield* call(patchBox, box, {
      content: '',
      promptTokens: '',
      showFeedback: false,
      alertData: null,
      output: undefined,
      count: undefined,
    });
  }
}
