import { actions } from '@ai21/studio-store';
import { Loader } from '@ai21/studio-ui';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebRoutes } from '../../bootstrap/Web.routes';
import { ChooseHyperParametersOrigin } from '../../data-types/ChooseHyperParametersOrigin';
import { SelectedColumns } from '../../data-types/Dataset';
import { CustomModelType } from '../../data-types/Model';
import {
  ADD_DATASET_FILE,
  RESET_DATASETS_PAGE_STATE,
  TECHNICAL_DOCUMENTATION_PRESSED,
} from '../../redux/actionTypes/datasetsActionTypes';
import { downloadExampleDatasetUrl } from '../../redux/actions/actions.get';
import { setCustomModelHyperParameters } from '../../redux/actions/actions.others';
import { postDataset } from '../../redux/actions/actions.post';
import { DatasetsPageState } from '../../redux/reducers/datasets';
import {
  getApproveWhitespaceCorrection,
  getDatasetsPageState,
  getSelectedColumns,
  getUploadDatasetName,
  getUploadDatasetsFile,
  getUploadedDatasetId,
} from '../../redux/selectors';
import { errorHandler } from '../../utils/error-reporting';
import { UploadDatasetDialogInitialState } from './UploadDatasetDialogInitialState';
import { UploadDatasetDialogNamingState } from './UploadDatasetDialogNamingState';
import { UploadDatasetDialogSuccessState } from './UploadDatasetDialogSuccessState';
import { UploadDatasetDialogUploadingState } from './UploadDatasetDialogUploadingState';
import { UploadDatasetsDialogErrorState } from './UploadDatasetsDialogErrorState';

interface UploadDatasetDialogContainerProps {
  navigate: (path: string) => void;
  onCancel: () => void;
}

export function UploadDatasetDialogContainer(props: UploadDatasetDialogContainerProps) {
  const state = useSelector(getDatasetsPageState);
  const datasetFile = useSelector(getUploadDatasetsFile);
  const uploadedDatasetName = useSelector(getUploadDatasetName);
  const uploadedDatasetId = useSelector(getUploadedDatasetId);
  const $selectedColumns = useSelector(getSelectedColumns);
  const $approveWhitespace = useSelector(getApproveWhitespaceCorrection);
  const [datasetName, setDatasetName] = useState<string | null>(null);

  const dispatch = useDispatch();
  const downloadExampleDataset = () => {
    dispatch(downloadExampleDatasetUrl());
  };

  const onFileSelected = (file: File) => {
    if (file) {
      dispatch({ type: ADD_DATASET_FILE, datasetFile: file });
    }
  };
  const saveDataset = (props: {
    name?: string;
    selectedColumns?: SelectedColumns;
    approveWhitespaceCorrection?: boolean;
    deleteLongRows?: boolean;
  }) => {
    const selectedColumnsProperty = props.selectedColumns || $selectedColumns;
    const approveWhitespaceCorrectionProperty =
      props.approveWhitespaceCorrection || $approveWhitespace;
    const datasetNameProperty = props.name || datasetName;
    if (props.name) {
      setDatasetName(props.name);
    }
    if (datasetNameProperty && datasetFile) {
      dispatch(
        postDataset(
          datasetNameProperty,
          datasetFile,
          selectedColumnsProperty,
          approveWhitespaceCorrectionProperty,
          props.deleteLongRows
        )
      );
    }
  };

  const trainModel = () => {
    if (!uploadedDatasetId || !datasetFile || !uploadedDatasetName) {
      errorHandler.report(
        `DatasetActions failed to find currentDataset - ${uploadedDatasetId || ''}`
      );
      return;
    }
    dispatch(
      setCustomModelHyperParameters(
        uploadedDatasetName,
        datasetFile?.size,
        CustomModelType.LIGHT_J2,
        uploadedDatasetId,
        ChooseHyperParametersOrigin.DATASETS_TABLE_ITEM
      )
    );
    props.navigate(WebRoutes.trainModelStep2);
    props.onCancel();
  };
  const showDatasets = () => {
    props.onCancel();
    props.navigate(WebRoutes.fineTuningSets);
    dispatch(actions.fineTuningSets.get({}));
  };

  const onReUploadFile = () => {
    dispatch({ type: RESET_DATASETS_PAGE_STATE });
  };
  const onTechnicalDocumentationClick = () => {
    dispatch({ type: TECHNICAL_DOCUMENTATION_PRESSED });
  };

  const renderDatasetByState = useCallback(() => {
    switch (state) {
      case DatasetsPageState.EMPTY_STATE:
      case DatasetsPageState.SHOW_DATASETS:
        return (
          <UploadDatasetDialogInitialState
            onFileSelected={onFileSelected}
            onTechnicalDocumentationClick={onTechnicalDocumentationClick}
            onClose={props.onCancel}
            downloadExampleDataset={downloadExampleDataset}
          />
        );
      case DatasetsPageState.NEW_DATASET_NAMING:
        return (
          <UploadDatasetDialogNamingState
            datasetName={datasetFile?.name}
            onClose={props.onCancel}
            saveDataset={saveDataset}
          />
        );
      case DatasetsPageState.UPLOADING_DATASET_IN_PROGRESS:
        return <UploadDatasetDialogUploadingState onClose={props.onCancel} />;
      case DatasetsPageState.UPLOADING_DATASET_DONE:
        return (
          <UploadDatasetDialogSuccessState
            viewDatasets={showDatasets}
            trainModel={trainModel}
            onClose={props.onCancel}
            fileDetails={{
              name: uploadedDatasetName,
              size: datasetFile?.size,
              id: uploadedDatasetId,
            }}
          />
        );
      case DatasetsPageState.DATASET_UNKNOWN_EXTENSION_ERROR:
      case DatasetsPageState.DATASET_ILLEGAL_FORMAT_ERROR:
      case DatasetsPageState.DATASET_MIN_ROWS_ERROR:
      case DatasetsPageState.DATASET_COLUMNS_NOT_SPECIFIED_ERROR:
      case DatasetsPageState.DATASET_MULTIPLE_WHITESPACE_ERROR:
      case DatasetsPageState.DATASET_TO_LONG_ROWS_ERROR:
      case DatasetsPageState.INVALID_DATASET_NAME_ERROR:
      case DatasetsPageState.DUPLICATE_DATASET_NAME_ERROR:
      case DatasetsPageState.EMPTY_COMPLETION:
        return (
          <UploadDatasetsDialogErrorState
            state={state}
            onClose={props.onCancel}
            ReUploadFile={onReUploadFile}
            saveDataset={saveDataset}
          />
        );
      default:
        return <Loader />;
    }
  }, [state]);

  useEffect(() => {
    renderDatasetByState();
  }, [state]);

  return renderDatasetByState();
}
