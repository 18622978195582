export function FreeTierIcon() {
  return (
    <svg width='50' height='35' viewBox='0 0 50 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.5 17.5C21.5 20.2614 19.2614 22.5 16.5 22.5C13.7386 22.5 11.5 20.2614 11.5 17.5C11.5 14.7386 13.7386 12.5 16.5 12.5C19.2614 12.5 21.5 14.7386 21.5 17.5Z'
        fill='url(#paint0_radial_12:3646)'
        stroke='black'
      />
      <circle cx='37.697' cy='17.5' r='9.80303' fill='url(#paint1_radial_12:3646)' stroke='black' />
      <defs>
        <radialGradient
          id='paint0_radial_12:3646'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(12.65 15.3) rotate(26.5651) scale(7.99394 8.57532)'
        >
          <stop stopColor='#6CAFFF' />
          <stop offset='0.55138' stopColor='#3D76FF' />
          <stop offset='1' stopColor='#042FFF' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_12:3646'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(30.4848 13.3788) rotate(26.565) scale(14.9749 16.064)'
        >
          <stop stopColor='#6CAFFF' />
          <stop offset='0.55138' stopColor='#3D76FF' />
          <stop offset='1' stopColor='#042FFF' />
        </radialGradient>
      </defs>
    </svg>
  );
}
