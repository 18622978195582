import { reducer as customModels } from './customModels';
import { reducer as datasets } from './datasets';
import { reducer as organization } from './organization';
import { reducer as overview } from './overview';
import { reducer as payment } from './payment';
import { reducer as playground } from './playground';
import { reducer as pricing } from './pricing';
import { reducer as user } from './user';

export const rootReducer = {
  _user: user,
  _playground: playground,
  _payment: payment,
  _datasets: datasets,
  _customModels: customModels,
  _organization: organization,
  _pricing: pricing,
  _overview: overview,
};
