import { actions } from '@ai21/studio-store';
import { put, takeEvery } from 'saga-ts';
import { createFirestoreChannel } from './channels/channel.firestore';
import { predicateCurrentId } from './predicates';

let channel: any;

type ActionCurrentId = {
  type: 'PATCH_CURRENTIDS';
  payload: {
    generationJobId: string;
    generationLineId: string;
  };
};

export function* onRemoteLineChange(action: any) {
  const { data } = action;

  yield put(actions.evaluationLines.set(data.id, data));
}

export function* onJobIdChange(action: ActionCurrentId) {
  const { payload } = action;
  const { generationJobId } = payload;

  if (channel) {
    channel.close();
  }

  channel = createFirestoreChannel(generationJobId);
  yield takeEvery(channel, onRemoteLineChange);
}

export function* root() {
  yield takeEvery(predicateCurrentId('generationJobId'), onJobIdChange);
}
