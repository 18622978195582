import { apiUrl } from '../globals';
import {
  getSegmentationCurlCodeSnippet,
  getSegmentationJsCodeSnippet,
  getSegmentationPythonCodeSnippet,
} from './codeBlocks';
import { GenerateSnippetsParams } from './snippets.types';
import { sanitizeCode } from '../utils/snippets';

const url = (apiHost: string) => `${apiHost}/segmentation`;

export const generate = (params: GenerateSnippetsParams) => {
  return {
    python: python(params),
    javascript: javascript(params),
    curl: curl(params),
  };
};

const python = (params: GenerateSnippetsParams) => {
  const { main } = params as any;
  return getSegmentationPythonCodeSnippet(url(apiUrl), sanitizeCode(main));
};

const javascript = (params: GenerateSnippetsParams) => {
  const { main } = params as any;
  return getSegmentationJsCodeSnippet(url(apiUrl), sanitizeCode(main));
};

const curl = (params: GenerateSnippetsParams) => {
  const { main } = params as any;
  return getSegmentationCurlCodeSnippet(url(apiUrl), sanitizeCode(main));
};
