import { ITableConfig, ITableFeatures, ITableState, SortDirection } from '../types';
import Fuse from 'fuse.js';

export const ACTIONS_COLUMN_FLEX = 2;

export const getNextSortDirection = (state: ITableState, fieldId?: string) => {
  const { sortFieldId, sortDirection } = state;

  if (sortFieldId === fieldId) {
    switch (sortDirection) {
      case 'asc':
        return 'desc';
      case 'desc':
        return undefined;
      default:
        return 'asc';
    }
  }

  return 'asc';
};

export const sortBy = (fieldId: string, direction: SortDirection) => (a: Json, b: Json) => {
  const aValue = a[fieldId];
  const bValue = b[fieldId];

  if (aValue === bValue) {
    return 0;
  }

  if (direction === 'asc') {
    return aValue > bValue ? 1 : -1;
  }

  return aValue < bValue ? 1 : -1;
};

export const search = (data: Json[], searchString: string) => {
  if (!searchString) {
    return data;
  }

  const getKeys = (items: Json[]) => {
    const firstItem = items[0] ?? {};
    return Object.keys(firstItem);
  };

  const getFuze = (items: Json[]) => {
    const instance = new Fuse(items, {
      includeScore: true,
      threshold: 0.3,
      shouldSort: true,
      keys: getKeys(items),
    });

    return instance;
  };

  const fuze = getFuze(data);
  return fuze.search(searchString).map((i) => i.item);
};

export const calculateFeatures = (features?: ITableFeatures) => {
  const {
    withSort = true,
    withPagination = true,
    withSearch = false,
    withNew = false,
  } = features ?? {};

  return {
    withSort,
    withPagination,
    withSearch,
    withNew,
  };
};
