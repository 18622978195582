import type { IMenuItem } from '@ai21/studio-base-ui';
import { useMemo } from 'react';
import { Location, useLocation } from 'react-router-dom';

export type GroupedSideBarItems = {
  items: IMenuItem[];
};

export const useBreadcrumbsItems = (items: IMenuItem[], defaultRoute: string): IMenuItem[] => {
  const location = useLocation();

  const crumbs = useMemo(() => {
    const parts = location.pathname.split('/');

    let full = '';

    return parts
      .map((part) => {
        full = (full + `/${part}`).replace('//', '/');
        const lookFor = full === '/' ? defaultRoute : full;
        if (part === 'home') {
          return false;
        }
        return findItem(lookFor, items);
      })
      .filter((i) => i) as IMenuItem[];
  }, [items, location]);

  return crumbs;
}

export const findItem = (path: string, items: IMenuItem[]) => {
  return items.find((item) => {
    return path === item.path;
  });
};
