export function StatusTrainingIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.37143 12C5.37143 13.311 5.76019 14.5926 6.48854 15.6826C7.2169 16.7727 8.25214 17.6223 9.46335 18.124C10.6746 18.6257 12.0074 18.757 13.2932 18.5012C14.579 18.2454 15.7601 17.6141 16.6871 16.6871C17.6141 15.7601 18.2454 14.579 18.5012 13.2932C18.757 12.0074 18.6257 10.6746 18.124 9.46335C17.6223 8.25214 16.7727 7.2169 15.6826 6.48854C14.5926 5.76019 13.311 5.37143 12 5.37143L12 4C13.5822 4 15.129 4.46919 16.4446 5.34824C17.7602 6.22729 18.7855 7.47672 19.391 8.93853C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5822 4 12L5.37143 12Z'
      />
    </svg>
  );
}
