import classnames from 'classnames';
import { useKey, useLocalStorage } from 'react-use';
import { InboxContext, InboxProvider } from './InboxEditor.context';
import { Container, EmptyBar, Wrapper } from './InboxEditor.style';
import { IJobInfo, ILine, Verb } from './InboxEditor.types';
import { EditorBottomBar } from './_parts/EditorBottomBar/EditorBottomBar';
import { EditorTopBar } from './_parts/EditorTopBar/EditorTopBar';
import { IOEditors } from './_parts/IOEditors/IOEditors';
import { useContext, useEffect } from 'react';
import { invokeEvent } from 'shared-base';

export type InboxEditorProps = {
  line?: ILine;
  jobInfo?: IJobInfo;
  open?: boolean;
  callbacks: {
    onAction: (verb: Verb, params?: Json) => void;
    onEvaluate: (score: number, lineId: string) => void;
    onTags: (tags: string[], lineId: string) => void;
    onComment: (comment: string, lineId: string) => void;
    onClose: () => void;
  };
};

export function InboxEditorInner(props: InboxEditorProps) {
  const { open } = props;
  const [isFullscreen, setIsFullscreen] = useLocalStorage('evaluate_drawer_fullscreen', false);
  const { line, callbacks } = useContext(InboxContext);

  useEffect(() => {
    invokeEvent('evaluate_drawer_fullscreen', { isFullscreen });
  }, [isFullscreen]);

  function renderLoadingState() {
    return (
      <>
        <EmptyBar />
        <IOEditors />
        <EmptyBar />
      </>
    );
  }

  function renderInner() {
    if (!open) {
      return renderLoadingState();
    }

    return (
      <>
        <EditorTopBar
          isLocked={line?.isLocked}
          isFullscreen={isFullscreen}
          onToggleLock={() => callbacks.onAction('toggleLock', { lineId: line?.id })}
          onToggleFullScreen={setIsFullscreen}
        />
        <Container>
          <IOEditors />
          <EditorBottomBar />
        </Container>
      </>
    );
  }

  const className = classnames('InboxEditor-wrapper', {
    fullscreen: isFullscreen,
  });

  return (
    <Wrapper className={className} data-testid='InboxEditor-wrapper'>
      {renderInner()}
    </Wrapper>
  );
}

export function InboxEditor(props: InboxEditorProps) {
  return (
    <InboxProvider {...props}>
      <InboxEditorInner {...props} />
    </InboxProvider>
  );
}

export default InboxEditor;
