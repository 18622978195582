import { takeEvery } from 'saga-ts';
import { ClickEventProps, IncomingClickEvent } from '../analytics.types';
import { parseButtonInfo } from '../utils/buttons';
import { logEvent } from '../analytics.amplitude';
import { config } from '../utils/globals';
import { customEvenChannel } from './channels/channel.customEvent';
import { camelCase, upperFirst } from 'shared-base';

export function* listenToClicks(event: IncomingClickEvent) {
  const { automatic, clicks } = config;

  const {
    enabled = false,
    buttonEventId = 'ButtonPressed',
    linkEventId = 'NavigatorPressed',
  } = clicks;

  if (!enabled) {
    return;
  }

  const { ev, source, extra = {} } = event.data;

  const buttonInfo = parseButtonInfo(ev);
  const eventId = buttonInfo.href ? linkEventId : buttonEventId;
  let { testId = '' } = buttonInfo;

  if (testId.includes('-')) {
    testId = upperFirst(camelCase(testId));
  }

  const eventProps: ClickEventProps = {
    buttonText: buttonInfo.buttonText,
    entity: testId,
    source,
    destination: buttonInfo.href,
    ...extra,
  };

  if (buttonInfo.id) {
    eventProps.itemId = buttonInfo.id;
  }

  if (buttonInfo.href) {
    eventProps.destination = buttonInfo.href;
  }

  const category = buttonInfo.href ? 'links' : 'clicks';
  if (!automatic.includes(category)) {
    return;
  }

  logEvent(eventId, eventProps, true);
}

export function* root() {
  const channel = customEvenChannel('global/click');
  yield takeEvery(channel, listenToClicks);
}
