import { ax } from '@ai21/studio-analytics';
import { Form } from '@ai21/studio-forms';
import {
  actions,
  calcIsEvaluated,
  fixLineContent,
  selectors,
} from '@ai21/studio-store';
import { FileDrop, prompt, toast } from '@ai21/studio-ui';
import { call, delay, put, select, take, takeEvery } from 'saga-ts';
import { downloadJson as downloadFile, downloadText, guid4 } from 'shared-base';
import { formDefaults, forms } from '../_definitions/forms';
import { Json } from '../types';
import { jsonToCsv, readFile } from '../utils/file';
import { analytics } from './helpers/analytics';
import { createJob } from './helpers/job';
import { predicateJobStatusChange } from './predicates';

const MAX_EVALUATION_JOB_LINES = 1000;

type Verb =
  | 'new'
  | 'source'
  | 'start'
  | 'delete'
  | 'copyId'
  | 'downloadJson'
  | 'downloadCsv'
  | 'settings'
  | 'evaluate'
  | 'navigate'
  | 'changeStatus';

type ActionJob = {
  type: 'EVALUATION_JOB';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  new: newJob,
  source: selectSource,
  start: startJob,
  delete: deleteJob,
  copyId: copyId,
  downloadJson: downloadJson,
  downloadCsv: downloadCsv,
  settings: openJobSettings,
  changeStatus: changeStatus,
  evaluate: evaluate,
  navigate: navigate,
};

function* newJob(action: ActionJob) {
  const { value, didCancel } = yield prompt.custom({
    title: 'Upload set',
    component: FileDrop,
    componentProps: {
      acceptTypes: ['.csv', '.jsonl'],
      comment: `Uploading evaluation set supports .jsonl or .csv file format, with up to 1000 items. You can download a [sample evaluation set](/evaluation.example.csv) (.csv) file that shows all the required and optional fields.`,
    },
    componentCta: 'onDrop',
    intention: 'upload',
  });

  if (didCancel || !value) {
    return;
  }

  const file = value[0];
  const fileName = file.name;
  let content: any = yield* call(readFile, file);

  if (!content) {
    yield call(newJobError, "Failed to read file's content.", fileName);
    return;
  }

  if (!Array.isArray(content)) {
    yield call(newJobError, 'File must be an array of lines.', fileName);
    return;
  }

  if (content.length >= MAX_EVALUATION_JOB_LINES) {
    yield call(
      newJobError,
      `File must have less than ${MAX_EVALUATION_JOB_LINES} lines.`,
      fileName
    );
    return;
  }

  content = fixLineContent(content);

  if (!content[0].prompt || !content[0].completion) {
    yield call(
      newJobError,
      'Missing field names: file must have a "prompt" and "completion" field in each line.',
      fileName
    );
    return;
  }

  const source = file.name.split('.')[0];

  const allOptions = yield* select(selectors.options.$allOptions);
  const user = yield* select(selectors.raw.$rawUser);

  const newJobId = guid4();

  const { didCancel: didCancel2, value: value2 } = yield prompt.form({
    component: Form,
    title: 'Evaluation Job Settings',
    form: {
      config: forms.evaluationEdit,
      data: {
        ...formDefaults.evaluationEdit,
        creator: user.userName,
        name: `Job #${newJobId}`,
        id: newJobId,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
    },
  });

  if (didCancel2) {
    return;
  }

  const {
    id,
    name,
    description,
    evaluationMethod,
    evaluationGoal,
    creator,
    greatLabels,
    okLabels,
    badLabels,
  } = value2;

  yield call(
    createJob,
    content as Json[],
    {
      id,
      name,
      description,
      evaluationMethod,
      evaluationGoal,
      creator,
      greatLabels,
      okLabels,
      badLabels,
      setSource: 'upload',
      setSourceId: '',
    } as any
  );
}

function* navigate(action: ActionJob) {
  const { to } = action.params || {};

  yield put({
    type: 'NAVIGATE',
    to,
  });
}

function* newJobError(reason: string, fileName: string) {
  toast.show(reason, 'error');

  yield* call(analytics, {
    action: 'upload',
    actionValue: reason + ' ' + fileName,
    eventId: 'EvaluationUploadError',
  });
}

function* selectSource(action: ActionJob) {
  console.log('selectSource', action);
}

function* startJob(action: ActionJob) {
  yield put({ type: 'NAVIGATE', to: `/tools/evaluation/${action.id}/inbox` });
}

function* deleteJob(action: ActionJob) {
  const { id } = action;
  const job = yield* select(selectors.singles.$evaluationJob, id);
  const { name } = job;

  const { didCancel } = yield prompt.confirm({
    title: 'Delete job',
    description: `Are you sure you want to delete evaluation job "${name}"?`,
    ctaButtonText: 'Delete Job',
    intention: 'delete',
  });

  if (didCancel) {
    return;
  }

  yield put(
    actions.evaluationJobs.patch(id, {
      isDeleted: true,
    })
  );

  if (document.location.pathname.includes('inbox')) {
    yield put({
      type: 'NAVIGATE',
      to: '/tools/evaluation',
    });
  }
}

function* copyId(action: ActionJob) {
  const { id } = action;
  navigator.clipboard.writeText(id);
  toast.show('Copied to clipboard', { type: 'success' });
}

function* downloadJson(action: ActionJob) {
  const { id } = action;
  const job = yield* select(selectors.singles.$evaluationJob, id);

  if (!job) {
    return;
  }

  yield* put(actions.evaluationLines.get({ setId: id }));
  yield take('SET_EVALUATIONLINES');

  const parsedLines = yield* select(selectors.download.$evaluationLines, id);

  downloadFile(`${job.name}_download.json`, parsedLines);

  ax.nudge('evaluationSets.downloads');
}

function* downloadCsv(action: ActionJob) {
  const { id } = action;
  const job = yield* select(selectors.singles.$evaluationJob, id);

  if (!job) {
    return;
  }

  yield* put(actions.evaluationLines.get({ setId: id }));
  yield take('SET_EVALUATIONLINES');

  const parsedLines = yield* select(selectors.download.$evaluationLines, id);

  const csv = jsonToCsv(parsedLines);

  if (!csv) {
    toast.show('Failed to convert to CSV', 'error');
    return;
  }

  downloadText(`${job.name}_download.csv`, csv);
  ax.nudge('evaluationSets.downloads');
}

export function* openJobSettings(action: ActionJob) {
  const { id } = action;
  const allOptions = yield* select(selectors.options.$allOptions);
  const job = yield* select(selectors.singles.$evaluationJob, id);

  if (!job) {
    return;
  }

  const { didCancel, value } = yield prompt.form({
    component: Form,
    title: 'Evaluation Job Settings',
    form: {
      config: forms.evaluationEdit,
      data: {
        ...job,
      },
      allOptions,
      allDetails: {},
      allMethods: {},
      isEdit: true,
    },
  });

  if (didCancel) {
    return;
  }

  yield put(actions.evaluationJobs.patch(id, value));

  toast.show('Job settings updated', 'success');
}

export function* refreshJobStats() {
  const lines = yield* select(selectors.evaluation.$evaluationLines);
  const job = yield* select(selectors.evaluation.$evaluationJob);

  if (!job) {
    return;
  }

  const { status: previousStatus } = job;
  const totalCount = lines.length;
  const greatCount = lines.filter((line) => line.quality?.value === 10).length;
  const okCount = lines.filter((line) => line.quality?.value === 5).length;
  const badCount = lines.filter((line) => line.quality?.value === 0).length;
  const evaluatedCount = lines.filter((line) => calcIsEvaluated(line.quality)).length; // prettier-ignore

  const badRate = badCount / evaluatedCount;
  const okRate = okCount / evaluatedCount;
  const greatRate = greatCount / evaluatedCount;

  // auto-change status only on first evaluation
  if (evaluatedCount > 0 && previousStatus === 'New') {
    yield put(
      actions.evaluationJobs.patch(job.id, {
        status: 'Evaluating',
      })
    );
  }

  yield put(
    actions.evaluationJobs.patch(job.id, {
      badCount,
      badRate,
      okCount,
      okRate,
      greatCount,
      greatRate,
      linesCompleted: evaluatedCount,
    })
  );
}

export function* evaluate() {}

function* changeStatus(action: ActionJob) {
  const { id, params } = action;
  const { status } = params ?? {};

  if (!status) {
    return;
  }

  yield put(
    actions.evaluationJobs.patch(id, {
      status: status as string,
    })
  );
}

export function* evaluationJob(action: ActionJob) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('EVALUATION_JOB', evaluationJob);
}
