import { User } from 'firebase/auth';
import { get } from 'lodash';
import { createContext, useMemo } from 'react';
import { useSetState } from 'react-use';
import { addListener } from 'shared-base';
import { ILoginState, UserStatus, authErrorMessages } from '../types';

type LoginProviderProps = {};

type ILoginContext = {
  patchState: (change: Partial<ILoginState>) => void;
  state: ILoginState;
  callbacks: {
    clearError: () => void;
  };
};

const initialValue: ILoginContext = {
  patchState: () => {},
  state: {
    userStatus: UserStatus.INITIAL,
    user: null,
    userEmail: '',
    userName: '',
    loadingId: '',
    isEmployee: false,
    isEmailVerified: undefined,
    providerId: undefined,
    shouldVerifyEmail: false,
    error: '',
  },
  callbacks: {
    clearError: () => {},
  },
};

export const LoginContext = createContext<ILoginContext>(initialValue);

export const LoginProvider = (props: WithChildren<LoginProviderProps>) => {
  const [state, patchState] = useSetState<ILoginState>({
    ...initialValue.state,
  });

  const callbacks = useMemo(
    () => ({
      clearError: () => {
        patchState({
          error: '',
        });
      },
    }),
    [state]
  );

  const cValue = useMemo(
    () => ({
      state,
      patchState,
      callbacks,
    }),
    [state, patchState]
  );

  addListener('auth_error', (data: Json) => {
    const message = (authErrorMessages as any)[data.code];

    patchState({
      error: message ?? data.code,
      loadingId: '',
    });
  });

  addListener('FIREBASE_USER_READY', (data: any) => {
    const user = data as User;

    const providerId = get(user, 'providerData[0].providerId') as any;
    const isEmailProvider = providerId === 'password';
    const isEmailVerified = get(user, 'emailVerified');
    const userName = user.displayName || user?.email?.split('@')[0];
    const userEmail = user.email ?? '';
    const isEmployee = userEmail.endsWith('@ai21.com');
    const shouldVerifyEmail = isEmailProvider && !isEmailVerified;

    patchState({
      user,
      userName,
      userEmail,
      isEmployee,
      userStatus: UserStatus.VERIFIED,
      providerId,
      isEmailVerified,
      shouldVerifyEmail,
    });
  });

  return <LoginContext.Provider value={cValue}>{props.children}</LoginContext.Provider>;
};

type WithChildren<T> = T & {
  children?: JSX.Element | JSX.Element[];
};
