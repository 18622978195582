import { generateCode } from '@ai21/studio-api';
import { actions, mapLegacyModels, selectors } from '@ai21/studio-store';
import { prompt } from '@ai21/studio-ui';
import { delay, put, select, takeEvery } from 'saga-ts';
import { WebRoutes } from '../bootstrap/Web.routes';
import { MODELS_VALUES_BY_DISPLAY } from '../constants/constants';
import {
  API_MODAL_COPIED,
  API_MODAL_SHOWN,
  CUSTOM_MODEL_TRY_IN_PLAYGROUND_PRESSED,
  NEW_CUSTOM_MODEL,
} from '../redux/actionTypes/customModelsActionTypes';
import { SET_CONTROLLER_PARAMS } from '../redux/actionTypes/playgroundActionTypes';
import { MESSAGE_DISMISSED, MESSAGE_SHOWN } from '../redux/actionTypes/userActionTypes';
import { setCustomModelParameters } from '../redux/actions/actions.others';
import { getControllerParams } from '../redux/selectors';
import { store } from '../redux/store';
import { Json } from '../types';
import { errorHandler } from '../utils/error-reporting';
import { navigate } from './saga.navigation';

type Verb =
  | 'playground'
  | 'api'
  | 'delete'
  | 'trainAnother'
  | 'new'
  | 'navigate'
  | 'epochSelection'
  | 'epochSelection2';

type ActionModel = {
  type: 'DATASET';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  playground: playgroundWithModel,
  api: modelApi,
  delete: deleteModel,
  trainAnother: trainAnother,
  new: trainModel,
  navigate: navigateToCustomModelsPage,
  epochSelection: epochSelection,
  epochSelection2: epochSelection,
};

function* epochSelection(action: ActionModel) {
  yield put({
    ...action,
    verb: 'epochSelection',
    type: 'EPOCHS',
  });
}

function* playgroundWithModel(action: ActionModel) {
  const { id } = action;
  const currentModel = yield* select(selectors.singles.$model, id);
  yield* put({ type: SET_CONTROLLER_PARAMS, delta: { model: currentModel.name } });
  yield* put({
    type: CUSTOM_MODEL_TRY_IN_PLAYGROUND_PRESSED,
    customModelId: id,
  });

  navigate('/home/complete/single-input-1-output');
}

function* trainAnother(action: ActionModel) {
  const { id } = action;

  const currentModel = yield* select(selectors.singles.$model, id);
  const ftsList = yield* select(selectors.tables.$fineTuningSets);

  const currentDataset = ftsList?.find((e) => e.name === currentModel?.datasetName);

  if (!currentDataset) {
    errorHandler.report(`CustomModelActions couldn't find dataset ${currentModel?.datasetName}`);
    return;
  }

  const datasetDetails = {
    datasetName: currentDataset.name,
    sizeBytes: currentDataset.size,
    modelType: mapLegacyModels(currentModel.customModelType),
    datasetId: currentDataset.id,
  };

  yield* put(
    (setCustomModelParameters as any)(datasetDetails, window.location.pathname.split('/')[1])
  );

  navigate(WebRoutes.trainModelStep2);
}

function* navigateToCustomModelsPage(action: ActionModel) {
  navigate(WebRoutes.customModels);
}

/**
 * TODO: remove this action entirely, as of the moment, we're avoiding code deletion
 * due to lack  of type system and build errors
 */
function* trainModel(action: ActionModel) {
  yield* put({
    type: NEW_CUSTOM_MODEL,
  });
}

function* modelApi(action: ActionModel) {
  const { id } = action;

  const controllerParams = yield* select(getControllerParams);
  const model = yield* select(selectors.singles.$model, id);

  if (!model) {
    return;
  }

  const code = generateCode({
    flavour: 'completion',
    prompt: 'YOUR_PROMPT_TEXT',
    controllerParams,
    customModelType: model.customModelType,
    modelName: MODELS_VALUES_BY_DISPLAY[model.name] || model.name,
  });

  yield* put({
    type: API_MODAL_SHOWN,
    context: 'overview',
    customModelId: model.id,
    language: 'python',
    controllerParams: controllerParams,
  });

  prompt.code({
    title: 'API call',
    flavour: 'completion',
    code,
  });
}

function* deleteModel(action: ActionModel) {
  const { id } = action;
  const model = yield* select(selectors.singles.$model, id);
  const { name } = model;

  store.dispatch({
    type: MESSAGE_SHOWN,
    payload: {
      origin: 'account',
      type: 'info',
      text: `Are you sure you want to delete the custom model "${name}"?`,
      name: 'deleteModel',
    },
  });

  const { didCancel } = yield prompt.confirm({
    title: 'Delete model',
    description: `Are you sure you want to delete the custom model "${name}"?`,
    ctaButtonText: 'Delete Model',
    intention: 'delete',
  });

  if (didCancel) {
    store.dispatch({
      type: MESSAGE_DISMISSED,
      payload: {
        origin: window.location.pathname.split('/')[1],
        type: 'question',
        reason: 'close',
        text: `Are you sure you want to delete the custom model "${name}"?`,
        name: 'deleteModel',
      },
    });
    return;
  }

  yield put(actions.models.delete(id));
}

export function* model(action: ActionModel) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('MODEL', model);
}
