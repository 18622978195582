import { api } from '@ai21/studio-api';
import { actions } from '../actions';
import { IServerMap } from './connected.types';

export const serverMap: IServerMap = {
  billingInfo: {
    get: api.billing.getBillingInfo,
    setAll: actions.billingInfo.setAll,
  },
  fineTuningSets: {
    get: api.fts.getAll,
    delete: api.fts.remove,
    setAll: actions.fineTuningSets.setAll,
  },
  invoices: {
    get: api.billing.getInvoices,
    setAll: actions.invoices.setAll,
  },
  models: {
    get: api.models.getAll,
    delete: api.models.remove,
    setAll: actions.models.setMany,
    extraItemData: {
      groupName: 'My custom models',
      isUserModel: true,
      isFoundationModel: false,
    },
  },
  organization: {
    get: api.organization.get,
    setAll: actions.organization.setAll,
  },
  paymentsInfo: {
    get: api.billing.getPaymentInfo,
    setAll: actions.paymentsInfo.setAll,
  },
  presets: {
    get: api.presets.getAll,
    add: api.presets.create,
    delete: api.presets.remove,
    setAll: actions.presets.setMany,
    set: actions.presets.set,
    extraItemData: {
      isUserPreset: true,
    },
  },
  specializedUsages: {
    get: api.usage.getLego,
    setAll: actions.specializedUsages.setAll,
  },
  trainingUsages: {
    get: api.usage.getTraining,
    setAll: actions.trainingUsages.setAll,
  },
  completionUsages: {
    get: api.usage.get,
    setAll: actions.completionUsages.setAll,
  },
  user: {
    get: api.user.get,
    setAll: actions.user.setAll,
  },
  users: {
    get: api.users.getAll,
    setAll: actions.users.setAll,
  },
  pas: {
    get: api.ape.getPas,
    add: api.ape.createPa,
    setAll: actions.pas.setAll,
    patch: api.ape.updatePa,
    set: actions.pas.set,
  },
  demos: {
    get: api.demos.getDemos,
    add: api.demos.createDemo,
    setAll: actions.demos.setAll,
    patch: api.demos.updateDemo,
    set: actions.demos.set,
  },
  evaluationJobs: {
    get: api.evaluation.getJobs,
    add: api.evaluation.createJob,
    setAll: actions.evaluationJobs.setAll,
    patch: api.evaluation.updateJob,
    set: actions.evaluationJobs.set,
  },
  evaluationLines: {
    get: api.evaluation.getLines,
    add: (item: Json) => api.evaluation.createLine(item.setId, item),
    patch: (id: string, item: Json) => {
      const { setId, ...change } = item;
      return api.evaluation.updateLine(setId, id, change);
    },
    setAll: actions.evaluationLines.setAll,
    set: actions.evaluationLines.set,
  },
  generationJobs: {
    get: api.generation.getJobs,
    add: api.generation.createJob,
    setAll: actions.generationJobs.setAll,
    patch: api.generation.updateJob,
    set: actions.generationJobs.set,
  },
  generationLines: {
    get: api.generation.getLines,
    add: (item: Json) => api.generation.createLine(item.setId, item),
    patch: (id: string, item: Json) => {
      const { setId, ...change } = item;
      return api.generation.updateLine(setId, id, change);
    },
    setAll: actions.generationLines.setAll,
    set: actions.generationLines.set,
  },
  collectionJobs: {
    get: api.collection.getJobs,
    add: api.collection.createJob,
    setAll: actions.collectionJobs.setAll,
    patch: api.collection.updateJob,
    set: actions.collectionJobs.set,
  },
  collectionLines: {
    get: api.collection.getLines,
    add: (item: Json) => api.collection.createLine(item.setId, item),
    patch: (id: string, item: Json) => {
      const { setId, ...change } = item;
      return api.collection.updateLine(setId, id, change);
    },
    setAll: actions.collectionLines.setAll,
    set: actions.collectionLines.set,
  },
  documents: {
    get: api.documents.getAll,
    add: api.documents.create,
    setAll: actions.documents.setAll,
    patch: api.documents.update,
    set: actions.documents.set,
    delete: api.documents.remove,
  },
  documentQuestions: {
    get: api.documents.getAllQuestions,
    setAll: actions.documentQuestions.setAll,
    patch: api.documents.updateQuestion,
  } as any,
};
