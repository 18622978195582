import { Icon, Tags, toast } from '@ai21/studio-base-ui';
import { IQuality } from '@ai21/studio-store';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { buttons, scoreToTagPlaceholder } from './FeedbackEditor.data';
import { Buttons, Clear, Combo, ScoreButton, Wrapper } from './FeedbackEditor.style';
import { calcIsEvaluated } from './FeedbackEditor.utils';

export type FeedbackEditorProps = {
  id?: string;
  quality?: IQuality;
  onEvaluate: (score: number, lineId: string, params?: Json) => void;
  onTags: (tags: string[], lineId: string) => void;
  onClear: () => void;
  scoreOptions?: Json[];
  isLocked?: boolean;
  isSkipped?: boolean;
  small?: boolean;
};

export function FeedbackEditor(props: FeedbackEditorProps) {
  const { id, quality, scoreOptions = [], isLocked, isSkipped, small } = props;
  const { value, tags = [] } = quality ?? {};
  const [autoFocusOnReasons, setAutoFocusOnReasons] = useState(false);

  const isEvaluated = calcIsEvaluated(quality);

  const onDisabledClick = () => {
    if (isLocked) {
      toast.show('Unlock item to edit', 'info');
    }
  };

  useEffect(() => {
    setAutoFocusOnReasons(false);
  }, [id]);

  function onEvaluate(value: number, id: string, params?: Json) {
    setAutoFocusOnReasons(true);
    props.onEvaluate(value, id, params);
  }

  function renderButton(button: Json) {
    const { value, title: baseTitle, selectedTitle, iconName, shortKey, color, tooltip } = button;

    const isSelected = value === quality?.value;

    const className = classnames(color, {
      selected: isSelected,
    });

    let title = baseTitle;

    if (isSelected && selectedTitle) {
      title = selectedTitle;
    }

    return (
      <ScoreButton
        size={small ? 'small' : 'medium'}
        key={value}
        className={className}
        disabled={isLocked}
        shortKey={small ? null : shortKey}
        tooltip={tooltip}
        tooltipDelay={1000}
        startIcon={iconName && <Icon iconName={iconName} />}
        onClick={() => onEvaluate(value, id ?? '')}
        onShortKey={() => onEvaluate(value, id ?? '', { keyboard: true })}
      >
        {title}
      </ScoreButton>
    );
  }

  function renderButtons() {
    return buttons.map((button: Json) => renderButton(button));
  }

  function renderCombo() {
    if (!isEvaluated) {
      return null;
    }

    const options = scoreOptions
      .filter((option) => option.score === value)
      .map((option) => option.title)
      .sort();

    const placeholder = scoreToTagPlaceholder[value ?? ''];

    return (
      <Combo className='combo'>
        <Tags
          value={tags}
          options={options}
          disabled={isLocked}
          autoFocus={autoFocusOnReasons}
          onChange={(tags) => props.onTags(tags, id ?? '')}
          placeholder={placeholder}
          maxTags={15}
        />
      </Combo>
    );
  }

  const className = classnames('FeedbackEditor-wrapper', `rating-${value}`, {
    evaluated: isEvaluated || isSkipped,
    small,
  });

  return (
    <Wrapper className={className} data-testid='FeedbackEditor-wrapper' onClick={onDisabledClick}>
      <Clear
        data-testid='ClearFeedback'
        onClick={props.onClear}
        className='clear'
        disabled={isLocked}
        iconName='BackChevron'
      />
      <Buttons>{renderButtons()}</Buttons>
      {renderCombo()}
    </Wrapper>
  );
}

export default FeedbackEditor;
