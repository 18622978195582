export function SaveIcon() {
  return (
    <svg
      style={{ margin: 'auto' }}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.82857 4.54286C5.48758 4.54286 5.16055 4.67832 4.91943 4.91943C4.67832 5.16055 4.54286 5.48758 4.54286 5.82857V18.1714C4.54286 18.5124 4.67832 18.8394 4.91943 19.0806C5.16055 19.3217 5.48758 19.4571 5.82857 19.4571H18.1714C18.5124 19.4571 18.8394 19.3217 19.0806 19.0806C19.3217 18.8394 19.4571 18.5124 19.4571 18.1714V8.20525L15.7948 4.54286H14.8286V7.88571C14.8286 8.31176 14.4832 8.65714 14.0571 8.65714H7.88571C7.45967 8.65714 7.11429 8.31176 7.11429 7.88571V4.54286H5.82857ZM7.88571 3H5.82857C5.07839 3 4.35893 3.29801 3.82847 3.82847C3.29801 4.35893 3 5.07839 3 5.82857V18.1714C3 18.9216 3.29801 19.6411 3.82847 20.1715C4.35893 20.702 5.07839 21 5.82857 21H18.1714C18.9216 21 19.6411 20.702 20.1715 20.1715C20.702 19.6411 21 18.9216 21 18.1714V7.88571C21 7.68112 20.9187 7.4849 20.7741 7.34023L16.6598 3.22595C16.5151 3.08128 16.3189 3 16.1143 3H14.0571H7.88571ZM8.65714 4.54286V7.11429H13.2857V4.54286H8.65714ZM12 12.6364C11.2469 12.6364 10.6364 13.2469 10.6364 14C10.6364 14.7531 11.2469 15.3636 12 15.3636C12.7531 15.3636 13.3636 14.7531 13.3636 14C13.3636 13.2469 12.7531 12.6364 12 12.6364ZM9 14C9 12.3431 10.3431 11 12 11C13.6569 11 15 12.3431 15 14C15 15.6569 13.6569 17 12 17C10.3431 17 9 15.6569 9 14Z'
      />
    </svg>
  );
}
