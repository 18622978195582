import { ButtonMenu, Typography } from '@ai21/studio-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;

export const Logo = styled.img`
  padding-bottom: 16px;
  max-height: 140px;

  @media (max-width: 1000px) {
    padding-bottom: 12px;
    max-height: 100px;
  }
`;

export const Menu = styled(ButtonMenu)<{ color: string }>`
  margin-left: 6px;
  position: absolute;
  right: 10px;
  top: 10px;

  .MuiButton-root {
    border: none;
    padding: 6px;
    background-color: transparent;
    border: 1px solid ${(props) => props.color};
    color: ${(props) => props.color};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  &.right {
    .IconButton-wrapper {
      background-color: white;
      margin-right: 10px;
    }
  }

  &.left {
    margin-left: 14px;
    &:hover {
      cursor: pointer;
    }
  }

  .IconButton-wrapper {
    background-color: white;
    margin-left: 10px;
  }
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
`;
