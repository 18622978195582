import classnames from 'classnames';
import { Wrapper } from './Flavour.style';

export type FlavourProps = {
  value: string;
  color: string;
};

export function Flavour(props: FlavourProps) {
  const { value, color } = props;

  const className = classnames('Flavour-wrapper', color, {});

  return (
    <Wrapper className={className} data-testid='Flavour-wrapper'>
      {value}
    </Wrapper>
  );
}

export default Flavour;
