import {
  addToCollection,
  getCollection,
  getCollectionItem,
  removeCollection,
  removeCollectionItem,
  updateCollectionItem,
} from './firebase';

export const getDemos = (_extraParams?: Json) => {
  return getCollection('/demos', 'organization');
};

export const createDemo = (gs: Json) => {
  return addToCollection('/demos', gs, 'organization');
};

export const updateDemo = (demoId: string, change: Json) => {
  return updateCollectionItem(`/demos/${demoId}`, change, 'organization');
};

export const removeDemo = (demoId: string) => {
  return removeCollection(`/demos/${demoId}`, 'organization');
};

export const getPublicDemos = (_extraParams?: Json) => {
  return getCollection('/demos', 'root');
};

export const getPublicDemo = (demoId: string) => {
  return getCollectionItem(`/demos/${demoId}`, 'root');
};

export const createPublicDemo = (gs: Json) => {
  return addToCollection('/demos', gs, 'root');
};

export const editPublicDemo = (id: string, change: Json) => {
  return updateCollectionItem(`/demos/${id}`, change, 'root');
};

export const removePublicDemo = (demoId: string) => {
  return removeCollectionItem(`/demos/${demoId}`, 'root');
};

export const demos = {
  getDemos,
  getPublicDemo,
  createDemo,
  updateDemo,
  removeDemo,
  createPublicDemo,
  editPublicDemo,
  removePublicDemo,
};
