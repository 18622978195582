import { actions } from '@ai21/studio-store';
import { delay, fork, put, take } from 'saga-ts';

export function* fetchAll() {
  yield* take('AUTHENTICATION_COMPLETED');

  yield delay(100);

  const promises = [yield* put(actions.generationJobs.get({}))];

  yield Promise.all(promises);
}

export function* root() {
  yield fork(fetchAll);
}
