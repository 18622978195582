import { Box, Button, Chip, Icon, Stack, Typography, tokens } from '@ai21/studio-base-ui';
import { useCatalogueCardStyles } from './CatalogueCard.styles';
import {
  CatalogueCardIconProps,
  CatalogueCardDetailsProps,
  CatalogueCardTitleProps,
  CatalogueCardCtaButtonProps,
} from './CatalogueCard.types';
import { FC } from 'react';
import { catalogueCardData } from './CatalogueCard.data';
import { resolveCtaButtonColorPalette, resolveCtaButtonVariant } from './CatalogueCard.utils';

export const CatalogueCardCtaButton: FC<CatalogueCardCtaButtonProps> = ({
  text,
  icon,
  isDeprecated,
  isBeta,
  isBetaEnabled,
}) => {
  const styles = useCatalogueCardStyles({ isDeprecated, isBetaEnabled });

  if (!text || !icon) {
    return null;
  }

  return (
    <Box sx={styles.buttonWrapper}>
      <Button
        variant={resolveCtaButtonVariant(isDeprecated, isBeta, isBetaEnabled)}
        color={resolveCtaButtonColorPalette(isDeprecated, isBeta, isBetaEnabled)}
        endIcon={<Icon iconName={icon} />}
        sx={styles.button}
      >
        {text}
      </Button>
    </Box>
  );
};

export const CatalogueCardDetails: FC<CatalogueCardDetailsProps> = ({
  tags,
  description,
  slogan,
  isDeprecated,
}) => {
  const styles = useCatalogueCardStyles({ isDeprecated });

  return (
    <>
      <Typography sx={styles.descriptionText} variant='body1' color='textSecondary'>
        {description}
      </Typography>
      <Typography sx={styles.sloganText}>{slogan}</Typography>
      {tags.length > 0 && (
        <Stack direction='row' spacing={1}>
          {tags.map((tag) => (
            <Chip key={tag} label={tag} sx={styles.tag} />
          ))}
        </Stack>
      )}
    </>
  );
};

export const CatalogueCardIcon: FC<CatalogueCardIconProps> = ({
  imageUrl,
  iconName,
  isDeprecated,
}) => {
  const styles = useCatalogueCardStyles({ imageUrl, isDeprecated });

  const shouldRenderImage = Boolean(imageUrl);
  if (shouldRenderImage) {
    return (
      <Box sx={styles.iconWrapper}>
        <Box sx={styles.image} />
      </Box>
    );
  }

  return (
    <Box sx={styles.iconWrapper}>
      {isDeprecated && <Box sx={styles.disabledIconOverlay} />}
      <Icon iconName={iconName} />
    </Box>
  );
};

export const CatalogueCardTitle: FC<CatalogueCardTitleProps> = ({
  title,
  isNew,
  isInternal,
  isDeprecated,
}) => {
  const styles = useCatalogueCardStyles({ isDeprecated });
  return (
    <Typography variant='subtitle2' color='textPrimary' sx={styles.titleText}>
      {title}
      {isNew && (
        <Box sx={styles.newBadge} component='span'>
          <Icon iconName='Sparkles' /> {catalogueCardData.ux.texts.badges.newBadge}
        </Box>
      )}
      {isInternal && (
        <Box className='AI21' sx={styles.ai21Badge}>
          {catalogueCardData.ux.texts.badges.ai21Badge}
        </Box>
      )}
    </Typography>
  );
};
