import { setTokens } from '@ai21/studio-api';
import { useContext, useEffect } from 'react';
import { LoginContext } from '@ai21/studio-auth';
import { UserStatus } from '../data-types/User';
import { useDispatch, useSelector } from 'react-redux';
import { getApiKey } from '../redux/selectors';

export function useApiTokens() {
  const dispatch = useDispatch();
  const { userStatus, firebaseUser } = useContext(LoginContext).state;
  const apiKey = useSelector(getApiKey);

  useEffect(() => {
    if (userStatus === UserStatus.VERIFIED && firebaseUser) {
      firebaseUser.getIdToken().then((token) => {
        setTokens({
          firebaseIdToken: token,
        });
      });
    }
  }, [userStatus]);

  useEffect(() => {
    if (apiKey) {
      setTokens({
        studioApiKey: apiKey,
      });
    }
  }, [apiKey]);
}
