import { Typography } from '@ai21/studio-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f5f7;
  border-top: 1px solid #e6eaec;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 40px;
  right: 0;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #e6eaec;
  }

  @media (max-width: 1000px) {
    top: 0;
    bottom: auto;
    z-index: 999;
  }
`;

export const Text = styled(Typography)``;

export const Logo = styled.div`
  position: relative;
  top: 2px;
  margin: 0 2px;

  svg {
    height: 20px;
    width: 86px;
  }
`;

export const Link = styled.a`
  svg {
    height: 20px;
    width: 20px;
    color: #334;
  }
`;
