import { addListener } from 'shared-base';
import * as amplitude from './analytics.amplitude';
import {
  AnalyticsConfig,
  Identity,
  IdentityFb,
  LoginParams,
  SignUpParams,
} from './analytics.types';
import { setConfig } from './utils/globals';
import { FireGoogleTagManagerEvent } from './utils/tagManager';
import { findResolution } from './utils/resolutions';

export const initAnalytics = (config: AnalyticsConfig) => {
  const { enabled, apiKey } = config;

  if (!enabled) {
    return;
  }

  amplitude.initInstance(apiKey, 'languageStudioWebapp');

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const resolutionId = findResolution(windowWidth) ?? 'unknown';
  const isChrome = navigator.userAgent.toLocaleLowerCase().indexOf('chrome') >= 0;
  const isMac = navigator.userAgent.toLocaleLowerCase().indexOf('mac') >= 0;

  amplitude.setUserProperty('screen_properties', {
    windowWidth,
    windowHeight,
    resolutionId,
    isChrome,
    isMac,
  });

  setConfig(config);
};

export const setIdentity = (identity: Identity) => {
  const { organizationId, organizationPlanType, userId, userRole, isNewUser = false } = identity;

  amplitude.setUserId(userId);

  amplitude.setUserProperty('organization_properties', {
    organization_id: organizationId,
    organization_plan_type: organizationPlanType,
    organization_role: userRole,
  });

  amplitude.setUserProperty('account_properties', {
    userRole,
    userPlan: organizationPlanType,
  });

  amplitude.setUserProperty('isNewUser', isNewUser);
};

export const setIdentifyFb = (identity: IdentityFb) => {
  const { fbId } = identity;

  const firstFiveLetters = fbId.substring(0, 5);
  amplitude.setUserProperty('fbId', firstFiveLetters);
};

addListener('FIREBASE_USER_READY', (user: any) => {
  const { uid } = user;
  setIdentifyFb({ fbId: uid });
});

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any;
  }
}

export function initGtag(trackingId: string) {
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.setAttribute('async', 'true');
  newScript.setAttribute('src', `https://www.googletagmanager.com/gtm.js?id=${trackingId}`);
  document.head.appendChild(newScript);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (window && window.dataLayer && window.dataLayer.push) {
      // eslint-disable-next-line
      window.dataLayer.push(arguments);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  window.gtag('js', new Date());
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  window.gtag('config', trackingId);
}

//
