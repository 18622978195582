import { List } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(List)`
  flex: 1;
  width: 100%;
  position: relative;
  overflow: auto;

  font-size: 20px;

  & ul {
    padding: 0;
  }

  svg {
    stroke-width: 1.5px;
    color: #1d1e22;
  }
`;
