import { FC, useCallback, useMemo } from 'react';
import { CatalogueSectionProps, CatalogueCardsRowProps } from '../Catalogue.types';
import { useCatalogueStyles } from '../Catalogue.styles';
import { resolveCardsForSection } from '../Catalogue.utils';
import { ICatalogueItem } from '../../../types';
import { Box, Typography } from '@ai21/studio-base-ui';
import CatalogueCard from '../../CatalogueCard/CatalogueCard';
import { range } from 'lodash';
import { CatalogueCardsRow } from '../components/CatalogueCardsRow';

export const CatalogueSection: FC<CatalogueSectionProps> = ({
  cards,
  numOfCardsPerRow,
  addPaddingToSections,
  section,
  onClickCard,
  cardTestId,
}) => {
  const styles = useCatalogueStyles({
    numOfCardsPerRow: numOfCardsPerRow,
    addSectionsPadding: addPaddingToSections,
  });

  const renderCards = useCallback(() => {
    const numberOfRowsToRender = Math.ceil(cards.length / numOfCardsPerRow);
    const renderedRows = range(0, numberOfRowsToRender).map((row) => {
      return (
        <CatalogueCardsRow
          key={`cards-row-${row}`}
          numOfCardsPerRow={numOfCardsPerRow}
          rowNumber={row}
          cardTestId={cardTestId}
          cards={cards}
          onClick={onClickCard}
        />
      );
    });

    return <Box sx={styles.rowsContainer}>{renderedRows}</Box>;
  }, [section, cards, numOfCardsPerRow, cardTestId, onClickCard]);

  const renderHeader = useCallback(() => {
    return (
      <>
        <Typography variant='h6bold' sx={styles.titleText}>
          {section.title}
        </Typography>
        <Typography variant='body1' sx={styles.subtitleText}>
          {section.subtitle}
        </Typography>
        <Box sx={styles.divider} />
        {section.secondaryTitle && (
          <Typography variant='h6bold' sx={styles.titleText}>
            {section.secondaryTitle}
          </Typography>
        )}
      </>
    );
  }, [section, styles]);

  return (
    <Box key={section.id} sx={styles.sectionContainer}>
      {renderHeader()}
      {renderCards()}
    </Box>
  );
};
