import { Icon } from '@ai21/studio-base-ui';
import { IDocumentQuestion } from '@ai21/studio-store';
import { useEffect, useRef } from 'react';
import { Verb } from '../../AskEditor.types';
import { AskLine } from '../AskLine/AskLine';
import { Empty, EmptyContent, EmptyText, EmptyTitle, Flex, Wrapper } from './AskContent.style';
import ExampleQuestions from '../ExampleQuestions/ExampleQuestions';

export type AskContentProps = {
  items: IDocumentQuestion[];
  callbacks: {
    onAction: (verb: Verb, params?: Json) => void;
    askAllLibrary: () => void;
  };
  fileName?: string;
  exampleQuestions?: string[];
};

export function AskContent(props: AskContentProps) {
  const { items, fileName, exampleQuestions } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const element = ref.current;

    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth',
    });
  }, [items]);

  function renderItem(item: IDocumentQuestion) {
    return <AskLine key={item.id} item={item} callbacks={props.callbacks} fileName={fileName} />;
  }

  function renderEmpty() {
    if (exampleQuestions && exampleQuestions.length > 0) {
      return <ExampleQuestions questions={exampleQuestions} />;
    } else {
      return (
        <Empty>
          <Icon iconName='InfoSquare' />
          <EmptyContent>
            <EmptyTitle variant='body1'>Enter your questions here</EmptyTitle>
            <EmptyText>
              Ask questions related to your{' '}
              {fileName ? `document: "${fileName}"` : 'documents library'}
            </EmptyText>
          </EmptyContent>
        </Empty>
      );
    }
  }

  function renderItems() {
    if (!items.length) {
      return renderEmpty();
    }

    return items.map((item: IDocumentQuestion) => renderItem(item));
  }

  return (
    <Wrapper className='AskContent-wrapper' data-testid='AskContent-wrapper' ref={ref}>
      <Flex className='AskContent-flex' />
      {renderItems()}
    </Wrapper>
  );
}

export default AskContent;
