import { Button } from '@mui/material';
import classnames from 'classnames';
import React, { FC, useRef, useState } from 'react';
import { invokeEvent } from 'shared-base';
import { Choice } from '../Choice/Choice';
import { Code } from '../Code/Code';
import { Icon } from '../Icon/Icon';
import { Markdown } from '../Markdown/Markdown';
import { Modal } from '../Modal/Modal';
import {
  Actions,
  Content,
  PromptInput,
  Warning,
  WarningIcon,
  WarningText,
  Wrapper,
} from './Prompt.style';

export type PromptProps = {
  title: string;
  flavour: 'confirm' | 'input' | 'form' | 'choice' | 'code' | 'custom';
  params: Json;
  ctaButtonText: string;
  id?: string;
  cancelButtonText?: string;
  hideBackdrop?: boolean;
  onCancel: () => void;
  onCta: (value?: any) => Promise<boolean>;
  formComponent?: FC<any>;
};

export function Prompt(props: PromptProps) {
  const {
    title,
    flavour,
    params,
    ctaButtonText,
    cancelButtonText = 'Cancel',
    formComponent,
    hideBackdrop,
    id,
  } = props;

  const {
    description,
    warning,
    defaultValue = '',
    intention,
    allowNewLine,
  } = params;
  const [value, setValue] = useState(defaultValue);
  const ref = useRef<HTMLDivElement>(null);

  const focusOnCta = flavour === 'confirm';

  function onClose() {
    invokeEvent('global/dialog', {
      type: flavour,
      action: 'close',
      title: title || id,
    });

    props.onCancel();
  }

  function onCta(customValue?: any) {
    if (flavour === 'input' && !value) {
      if (ref.current) {
        const el = ref.current.querySelector('input');
        if (el) {
          el.focus();
        }
      }
      return;
    }

    let actionValue =
      flavour !== 'custom' ? ctaButtonText : params.componentCta;

    if (actionValue === 'onDrop') {
      actionValue = 'selected';
    }

    invokeEvent('global/dialog', {
      type: flavour,
      action: intention, // TODO: change to intention
      actionValue,
      title: title || id,
    });

    props.onCta(customValue || value);
  }

  function onKeyDown(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (ev.code !== 'Enter') {
      return;
    }
    if (flavour === 'input' && (!allowNewLine || ev.metaKey)) {
      ev.stopPropagation();
      onCta();
    }
  }

  function renderDescription() {
    return <Markdown markdown={description} />;
  }

  function renderWarning() {
    if (!warning) {
      return null;
    }

    return (
      <Warning>
        <WarningIcon>
          <Icon iconName='WarningOutlined' />
        </WarningIcon>
        <WarningText>{warning}</WarningText>
      </Warning>
    );
  }

  function renderInner() {
    switch (flavour) {
      case 'confirm':
        return (
          <>
            {renderDescription()}
            {renderWarning()}
          </>
        );

      case 'input':
        const rowsDefault = value.length > 30 ? 10 : 1;
        const rows = params.rows ?? rowsDefault;

        return (
          <>
            {renderDescription()}
            <PromptInput
              placeholder={params.placeholder}
              label={params.label}
              value={value}
              onKeyDown={onKeyDown}
              rows={rows}
              onChange={(ev: any) => {
                setValue(ev.target.value ?? '');
              }}
            />
            {renderWarning()}
          </>
        );

      case 'form':
        const Cmp = formComponent;

        if (!Cmp) {
          return null;
        }
        return (
          <>
            <Cmp
              {...(params as any)}
              onSubmit={(change: Json, allValues: Json) => {
                props.onCta(params.isEdit ? change : allValues);
              }}
              onCancel={onClose}
            />
          </>
        );
      case 'choice':
        return (
          <>
            <Choice
              options={params.options}
              value={value}
              onKeyDown={onKeyDown}
              onChange={setValue}
            />
          </>
        );

      case 'code':
        return (
          <>
            {renderDescription()}
            <Code snippet={params.code} onCta={props.onCta} />
          </>
        );
      case 'custom':
        const Custom = params.component;
        if (typeof Custom !== 'function') {
          return null;
        }

        const reroute: any = {};

        if (params.componentCta) {
          reroute[params.componentCta] = onCta;
        }

        return (
          <Custom
            {...params.componentProps}
            {...reroute}
            onCta={onCta}
            onCancel={onClose}
          />
        );
    }
  }

  function renderActions() {
    if (flavour === 'form' || flavour === 'custom' || flavour === 'code') {
      return null;
    }

    return (
      <Actions>
        <Button
          color='secondary'
          data-testid='prompt-cancel-btn'
          variant='outlined'
          size='medium'
          onClick={onClose}
        >
          {cancelButtonText}
        </Button>
        <Button
          color='primary'
          data-testid='prompt-cta-btn'
          className='prompt-cta-btn'
          variant='contained'
          size='medium'
          onClick={() => onCta()}
        >
          {ctaButtonText}
        </Button>
      </Actions>
    );
  }

  const focusOnClassName = focusOnCta ? '.prompt-cta-btn' : '';

  const className = classnames('Prompt-wrapper', flavour, {});
  const classNameContent = classnames(flavour, {});
  const { headerCta, headerCtaProps } = params;

  return (
    <Modal
      title={title}
      open={true}
      headerCta={headerCta}
      headerCtaProps={headerCtaProps}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      focusOnClassName={focusOnClassName}
      dataTestId={`prompt_${flavour}`}
    >
      <Wrapper className={className} data-testid='Prompt-wrapper' ref={ref}>
        <Content className={classNameContent}>{renderInner()}</Content>
        {renderActions()}
      </Wrapper>
    </Modal>
  );
}

export default Prompt;
