import { icons } from '@ai21/studio-ui';
import { APIType, IntentType } from '../constants/constants';
import { Penalty } from './Completion';

export interface CompletionPresetParams {
  maxTokens: number | undefined;
  epoch: number | undefined;
  temperature: number | undefined;
  topP: number | undefined;
  stopSequences: string[];
  model: string | undefined;
  topKReturn: number | undefined;
  presencePenalty: Penalty | undefined;
  countPenalty: Penalty | undefined;
  frequencyPenalty: Penalty | undefined;
}

export interface SummarizationPresetParams {
  length: number | undefined;
}

export interface RewritePresetParams {
  intent: IntentType;
  spanStart: number | null;
  spanEnd: number | null;
}

export interface Preset {
  name: string;
  text: string;
  apiType: APIType;
  params: CompletionPresetParams;
  primaryLabel: PresetLabel;
  secondaryLabels: PresetLabel[];
}

export interface CustomPreset extends Preset {
  id: string;
}

export interface GeneralPreset extends Preset {
  testId: string;
  description: string;
  groupId: string;
}

export enum PresetLabel {
  MY_PRESETS = 'My Presets',
  CHAT = 'Chat',
  CONTENT_WRITING = 'Content Writing',
  COPY_WRITING = 'Copywriting',
  CLASSIFICATION = 'Classification',
  SUMMARIZE = 'Summarize',
  Q_AND_A = 'Q&A',
  REWRITE = 'Rewrite',
  REWRITE_API_EXAMPLES = 'Rewrite Examples',
  SUMMARIZATION_API_EXAMPLES = 'Summarize Examples',
  CODE = 'Code',
  RETAIL = 'Retail',
  MARKETING = 'Marketing',
  COMMON_SENSE = 'Common Sense',
  SHORT_FORM = 'Short form',
  LONG_FORM = 'Long form',
  NAMED_ENTITY_RECOGNITION = 'Named entity recognition',
  SENTIMENT_ANALYSIS = 'Sentiment analysis',
  INFO_EXTRACTION = 'Info Extraction',
  FINANCE = 'Finance',
}

export enum RewritePresetLabel {
  MY_PRESETS = 'My Presets',
  GENERAL = 'General Examples',
}

export const PresetLabelIcon: Map<PresetLabel, string> = new Map([
  [PresetLabel.CHAT, 'overview.Chat'],
  [PresetLabel.LONG_FORM, 'overview.LongForm'],
  [PresetLabel.SHORT_FORM, 'overview.ShortForm'],
  [PresetLabel.CLASSIFICATION, 'overview.Classification'],
  [PresetLabel.SUMMARIZE, 'overview.Summarize'],
  [PresetLabel.Q_AND_A, 'overview.QAndA'],
  [PresetLabel.REWRITE, 'overview.Rewrite'],
  [PresetLabel.CODE, 'overview.Code'],
  [PresetLabel.NAMED_ENTITY_RECOGNITION, 'overview.NamedEntityRecognition'],
  [PresetLabel.INFO_EXTRACTION, 'overview.InfoExtraction'],
]);

export enum PresetOrigin {
  PLAYGROUND = 'playgroundPage',
  OVERVIEW = 'overviewPage',
  PRESETS = 'presetsPage',
}
