import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
`;

export const Content = styled.div`
  margin-top: 54px;
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 54px);
`;
