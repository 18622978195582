import { actions } from '@ai21/studio-store';
import { delay, fork, put, take, takeEvery } from 'saga-ts';

export function* fetchAll() {
  yield delay(100);

  const promises = [
    yield* put(actions.documents.get({})),
    yield* put(actions.documentQuestions.get({})),
  ];

  yield Promise.all(promises);
}

export function* root() {
  yield* take('AUTHENTICATION_COMPLETED');
  yield fork(fetchAll);
}
