export function ActionShareIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 3.125C13.9645 3.125 13.125 3.96447 13.125 5C13.125 5.27191 13.1829 5.5303 13.287 5.76352C13.2935 5.77447 13.2997 5.78568 13.3056 5.79715C13.3167 5.81869 13.3264 5.84057 13.3348 5.8627C13.647 6.46413 14.2755 6.875 15 6.875C16.0355 6.875 16.875 6.03553 16.875 5C16.875 3.96447 16.0355 3.125 15 3.125ZM12.5218 6.90399C13.0931 7.64651 13.9907 8.125 15 8.125C16.7259 8.125 18.125 6.72589 18.125 5C18.125 3.27411 16.7259 1.875 15 1.875C13.2741 1.875 11.875 3.27411 11.875 5C11.875 5.26948 11.9091 5.531 11.9732 5.78047L7.47824 8.09608C6.90692 7.35352 6.00934 6.875 5 6.875C3.27411 6.875 1.875 8.27411 1.875 10C1.875 11.7259 3.27411 13.125 5 13.125C6.00931 13.125 6.90687 12.6465 7.4782 11.904L11.9732 14.2196C11.9091 14.4691 11.875 14.7305 11.875 15C11.875 16.7259 13.2741 18.125 15 18.125C16.7259 18.125 18.125 16.7259 18.125 15C18.125 13.2741 16.7259 11.875 15 11.875C13.9907 11.875 13.0931 12.3535 12.5218 13.0961L8.02675 10.7805C8.09089 10.531 8.125 10.2695 8.125 10C8.125 9.73055 8.0909 9.46906 8.02677 9.21962L12.5218 6.90399ZM6.66514 9.13723C6.67354 9.15941 6.68328 9.18134 6.6944 9.20293C6.70033 9.21444 6.70657 9.22568 6.71309 9.23666C6.81715 9.46984 6.875 9.72816 6.875 10C6.875 10.272 6.8171 10.5304 6.71295 10.7637C6.70647 10.7746 6.70029 10.7857 6.6944 10.7972C6.68333 10.8186 6.67363 10.8405 6.66526 10.8625C6.35306 11.4641 5.72452 11.875 5 11.875C3.96447 11.875 3.125 11.0355 3.125 10C3.125 8.96447 3.96447 8.125 5 8.125C5.72443 8.125 6.35291 8.53584 6.66514 9.13723ZM13.2868 14.2368C13.2934 14.2258 13.2997 14.2145 13.3056 14.2029C13.3168 14.1813 13.3265 14.1593 13.3349 14.1371C13.6472 13.5358 14.2756 13.125 15 13.125C16.0355 13.125 16.875 13.9645 16.875 15C16.875 16.0355 16.0355 16.875 15 16.875C13.9645 16.875 13.125 16.0355 13.125 15C13.125 14.7282 13.1828 14.4699 13.2868 14.2368Z'
        fill='black'
      />
    </svg>
  );
}
