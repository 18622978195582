import { createSelector } from 'reselect';
import { DemoFlavour, ICompletionUsage, IStudioStore } from '../types';
import { mapLegacyModels } from '../utils/modelTransition';
import * as raw from './selectors.raw';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $presets = createSelector(raw.$rawPresets, (presets) => {
  return Object.values(presets)
    .filter((preset) => preset.isUserPreset && preset.apiType == 'completion')
    .map((preset) => {
      return {
        id: preset.id,
        name: preset.name,
        created: preset.apiType,
        model: mapLegacyModels(preset.params.modelId || (preset.params as any).model),
        entityIcon: 'menu.Template',
      };
    });
});

export const $fineTuningSets = createSelector(raw.$rawFineTuningSets, (sets) => {
  return Object.values(sets).map((fts) => {
    return {
      id: fts.id,
      name: fts.name,
      size: fts.fileSizeInBytes,
      created: fts.dateCreated,
      modelsCount: fts.numModelsUsed,
      rows: fts.linesCount,
      rowsValidation: fts.validationNumExamples,
      entityIcon: 'overview.DatasetTable',
    };
  });
});

export const $customModelsUsage = createSelector(raw.$rawCompletionUsages, (completionUsages) => {
  return Object.values(completionUsages).filter((model) => model.modelType === 'CUSTOM');
});

export const $models = createSelector(
  $customModelsUsage,
  raw.$rawModels,
  (completionUsage, models) => {
    return Object.values(models)
      .filter((model) => model.customModelType)
      .map((model) => {
        const { currentEpoch = 0, modelMetadata } = model;

        const { numEpochs = 1 } = modelMetadata ?? {};

        let trainingProgress: number | undefined;

        if (model.status === 'PENDING') {
          trainingProgress = Math.round((currentEpoch / numEpochs) * 100);
        }

        return {
          id: model.id,
          name: model.name,
          baseModel: mapLegacyModels(model.customModelType),
          created: model.creationDate,
          requestsCount: getNumRequests(completionUsage, model.name),
          status: model.status,
          evalLoss: model.modelMetadata?.evalLoss,
          learningRate: model.modelMetadata?.learningRate,
          numEpochs: model.modelMetadata?.numEpochs,
          currentEpoch: model.modelMetadata?.defaultEpoch,
          datasetName: model.datasetName,
          statusDescription: getStatusTooltip(model.status, getSetName(model.datasetName)),
          entityIcon: 'overview.ModelTable',
          trainingProgress,
        };
      });
  }
);

export const $modelsInfo = createSelector($models, raw.$rawCurrentIds, (models, currentIds) => {
  const { modelId } = currentIds;

  if (!modelId) {
    return [];
  }

  return models.filter((model) => model.id === modelId);
});

export const $evaluationJobs = createSelector(raw.$rawEvaluationJobs, (jobs) => {
  return Object.values(jobs)
    .filter((job) => !job.isDeleted)
    .map((job) => {
      const countEvaluated = job.linesCompleted ? job.linesCompleted : 0;
      const progressPresent =
        job.linesCount && job.linesCount > 0 ? countEvaluated / job.linesCount : 0;
      return {
        id: job.id,
        name: job.name,
        description: job.description,
        evaluationMethod: job.evaluationMethod,
        evaluationGoal: job.evaluationGoal,
        fileSize: job.fileSizeInBytes,
        greatRate: job.greatRate || 0,
        greatCount: job.greatCount || 0,
        okRate: job.okRate || 0,
        okCount: job.okCount || 0,
        badRate: job.badRate || 0,
        badCount: job.badCount || 0,
        dateCreated: job.dateCreated,
        status: job.status,
        creator: job.creator,
        countTotal: job.linesCount,
        countEvaluated: countEvaluated,
        entityIcon: 'Scale',
        progressPresent: progressPresent,
      };
    });
});

export const $evaluationLines = createSelector(
  raw.$rawEvaluationLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { evaluationJobId } = currentIds;

    return Object.values(lines)
      .filter((line) => line.setId === evaluationJobId)
      .map((line) => {
        const { tags = [] } = line.quality ?? { tags: [] };
        return {
          id: line.id,
          index: line.index,
          guest: line.lastOpenedBy,
          input: line.input,
          inputShort: line.input.substring(0, 80),
          output: line.output,
          outputShort: line.output.substring(0, 80),
          reasons: tags.join(', '),
          model: line.modelName,
          timeStamp: line.dateCreated,
          status: line.status,
          rank: line.quality?.value,
          score: line.quality,
          comment: (line.comment ?? '').replace(/\\n/g, '\n'),
          evaluator: line.evaluator,
          outputSuggestion: line.outputEdited,
          isLocked: line.isLocked,
          temperature: line.temperature,
          topP: line.topP,
          maxTokens: line.maxTokens,
          entityIcon: 'overview.ModelTable',
        };
      });
  }
);

export const $generationJobs = createSelector(raw.$rawGenerationJobs, (job) => {
  return Object.values(job)
    .filter((job) => !job.isDeleted)
    .map((job) => {
      return {
        id: job.id,
        name: job.name,
        description: job.description,
        linesCount: job.linesCount,
        fileSize: job.fileSizeInBytes,
        dateCreated: job.dateCreated,
        status: job.status,
        creator: job.creator,
        price: job.price?.value,
        entityIcon: 'GenerationSet',
      };
    });
});

export const $generationLines = createSelector(
  raw.$rawGenerationLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { generationJobId } = currentIds;

    return Object.values(lines)
      .filter((line) => line.setId === generationJobId)
      .map((line) => {
        const { tags = [] } = line.price ?? { tags: [] };

        return {
          id: line.id,
          index: line.index,
          guest: line.lastOpenedBy,
          input: line.input,
          inputShort: line.input.substring(0, 240),
          output: line.output,
          outputShort: line.output.substring(0, 240),
          modelName: line.modelName,
          temperature: line.temperature,
          minTokens: line.minTokens,
          maxTokens: line.maxTokens,
          topP: line.topP,
          timeStamp: line.dateCreated,
          source: line.setSource,
          status: line.status,
          comment: line.comment,
          outputSuggestion: line.outputEdited,
          isLocked: line.isLocked,
          entityIcon: 'GenerationSet',
        };
      });
  }
);

function getStatusTooltip(status: string, tooltipInfo: string) {
  switch (status) {
    case 'ACTIVE':
      return `Trained by ${tooltipInfo}`;
    case 'DISCONTINUED':
      return null;
    default:
      return `Training by ${tooltipInfo}`;
  }
}

function getSetName(setName: string) {
  if (setName) {
    const setExtension = setName.split('.').pop()?.split('_')[0];
    return [setName.split('.')[0], setExtension].join('.');
  }
  return setName;
}

function getNumRequests(usages: ICompletionUsage[], modelName: string) {
  const modelUsage = usages.filter((usage) => usage.modelName === modelName);
  return modelUsage.length > 0 ? modelUsage[0].numRequests : 0;
}

export const $documents = createSelector(raw.$rawDocuments, raw.$rawUsers, (documents, users) => {
  return Object.values(documents).map((doc) => {
    const { labels = [] } = doc;
    const createdByUser = users[doc.createdBy];
    const createdBy = createdByUser ? createdByUser.name : '-';

    return {
      id: doc.id,
      name: doc.name,
      fileSize: doc.sizeBytes,
      creationDate: doc.creationDate,
      status: doc.status,
      createdById: doc.createdBy,
      labels: labels?.join(', '),
      createdBy,
      entityIcon: 'FileText',
    };
  });
});

export const $epochs = createSelector(raw.$rawModels, raw.$rawCurrentIds, (models, currentIds) => {
  const { modelId } = currentIds;

  if (!modelId) {
    return [];
  }

  const model = models[modelId];

  if (!model) {
    return [];
  }

  const { modelMetadata } = model;
  const { epochs = {}, defaultEpoch } = modelMetadata ?? {};

  return Object.keys(epochs).map((id) => {
    const epoch = epochs[id];
    const { validationLoss } = epoch;

    const isDefault = parseInt(id, 10) === defaultEpoch;
    const text = isDefault ? `${id} (default)` : id;

    return {
      id,
      text,
      validationLoss,
      isDefault,
    };
  });
});

export const $pas = createSelector(raw.$rawPas, (pas) => {
  return Object.values(pas)
    .filter((pa) => !pa.isDeleted)
    .map((pa) => {
      return {
        id: pa.id,
        name: pa.name,
        description: pa.description,
        dateCreated: pa.dateCreated,
        creator: pa.creator,
        labels: pa.labels,
        systemMessage: pa.systemMessage,
        userPrompt: pa.userPrompt,
        entityIcon: 'Robot',
      };
    });
});

export const $demos = createSelector(raw.$rawDemos, (demos) => {
  const flavours: Record<DemoFlavour, any> = {
    chat: {
      text: 'Chat',
      color: 'green',
    },
    contextual_answers: {
      text: 'Contextual Answers',
      color: 'purple',
    },
    summarization: {
      text: 'Summarization',
      color: 'blue',
    },
  };

  return Object.values(demos)
    .filter((demo) => !demo.isDeleted)
    .map((demo) => {
      const flavour = flavours[demo.flavour] ?? {};

      return {
        id: demo.id,
        name: demo.name,
        dateCreated: demo.dateCreated,
        company: demo.company,
        creator: demo.creator,
        flavour: flavour.text,
        flavourColor: flavour.color,
        entityIcon: 'ScreenShare',
      };
    });
});

export const $collectionJobs = createSelector(raw.$rawCollectionJobs, (jobs) => {
  return Object.values(jobs)
    .filter((job) => !job.isDeleted)
    .map((job) => {
      return {
        id: job.id,
        name: job.name,
        description: job.description,
        linesCount: job.linesCount,
        fileSize: job.fileSizeInBytes,
        dateCreated: job.dateCreated,
        status: job.status,
        creator: job.creator,
        entityIcon: 'SquarePlus',
      };
    });
});

export const $collectionLines = createSelector(
  raw.$rawCollectionLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { collectionJobId } = currentIds;

    return Object.values(lines)
      .filter((line) => line.setId === collectionJobId && !line.isDeleted)
      .map((line) => {
        return {
          id: line.id,
          index: line.index,
          guest: line.lastOpenedBy,
          input: line.input,
          inputShort: line.input.substring(0, 240),
          output: line.output,
          outputShort: line.output.substring(0, 240),
          modelName: line.modelName || (line as any).modelId,
          temperature: line.temperature,
          minTokens: line.minTokens,
          maxTokens: line.maxTokens,
          topP: line.topP,
          timeStamp: line.dateCreated,
          source: line.setSource,
          status: line.status,
          comment: line.comment,
          outputSuggestion: line.outputEdited,
          isLocked: line.isLocked,
          entityIcon: 'GenerationSet',
        };
      });
  }
);

export const $apps = createSelector(raw.$rawApps, (apps) => {
  return Object.values(apps).filter((line) => !line.isHidden);
});
