import { Button, CircularProgress, Icon } from '@ai21/studio-base-ui';
import { IFormCancel, IFormSubmit } from '../../forms.types';
import { Cancel, Wrapper } from './Submit.style';

export type SubmitProps = {
  submit: IFormSubmit;
  cancel: IFormCancel;
  isSubmitting?: boolean;
  onCancel?: () => void;
};

export function Submit(props: SubmitProps) {
  let { submit, isSubmitting, cancel } = props;
  const { title, iconName } = submit;

  function renderInner() {
    return isSubmitting ? <CircularProgress color='warning' size={24} /> : title;
  }

  function renderCancel() {
    if (!cancel) {
      return;
    }

    return (
      <Cancel>
        <Button
          size='large'
          color='secondary'
          variant='outlined'
          fullWidth
          data-testid='form-cancel'
          disabled={isSubmitting}
          onClick={props.onCancel}
        >
          {cancel.title}
        </Button>
      </Cancel>
    );
  }

  return (
    <Wrapper className='Submit-wrapper' data-testid='Submit-wrapper'>
      {renderCancel()}
      <Button
        type='submit'
        size='large'
        color='primary'
        fullWidth={!cancel}
        variant='contained'
        endIcon={iconName && <Icon iconName={iconName} />}
        data-testid='form-submit'
        disabled={isSubmitting}
      >
        {renderInner()}
      </Button>
    </Wrapper>
  );
}

export default Submit;
