import { Icon } from '@ai21/studio-base-ui';
import { BackItem, Item, ItemIcon, ItemText } from './SideBar.style';

type BackToStudioButtonProps = {
  title: string;
  onClick: () => void;
};

export const BackToStudioButton = (props: BackToStudioButtonProps) => {
  const { title } = props;

  function onMouseDown(ev: React.MouseEvent<HTMLDivElement>) {
    // only if left button
    if (ev.button !== 0) {
      return;
    }

    props.onClick();
  }

  return (
    <BackItem key={'back'} className='item' onMouseDown={onMouseDown}>
      <Icon iconName='ArrowLeft' />
      <ItemText>{title}</ItemText>
    </BackItem>
  );
};
