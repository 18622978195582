import { api } from '@ai21/studio-api';
import { IBox, selectors, actions } from '@ai21/studio-store';
import { UrlShare, prompt as dialog, toast } from '@ai21/studio-ui';
import { get } from 'lodash';
import { call, delay, put, select, takeEvery } from 'saga-ts';
import { getGenerationParams } from '../utils/boxes';
import { customEvenChannel } from './channels/channel.customEvent';
import { patchBox, warnUnsaved } from './helpers/boxes';
import { ActionPlay } from './saga.play';
import {
  loadAnswerInputs,
  loadParamsAndPrompt,
} from './helpers/paramsAndPrompt';
import { Form } from '@ai21/studio-forms';
import { forms } from '../_definitions/forms';

export function* presetNew(action: ActionPlay, box: IBox) {
  const inputBox = yield* select(selectors.playground.$inputBox);

  if (!inputBox) {
    return;
  }

  const shouldSave = yield* call(warnUnsaved);

  // user wants to save
  if (shouldSave) {
    yield* call(presetSave, action, box);
    return;
  }

  yield put({
    type: 'PLAY2',
    verb: 'clearAll',
    id: box.id,
  });
}

export function* presetLoad(action: ActionPlay, box: IBox) {
  const { presetId } = action.params ?? {};

  const shouldSave = yield* call(warnUnsaved);

  // user wants to save
  if (shouldSave) {
    yield* call(presetSave, action, box);
    return;
  }

  // user wants to continue without saving
  const preset = yield* select(selectors.singles.$preset, presetId);
  yield call(loadParamsAndPrompt, preset);
}

export function* presetSave(action: ActionPlay, box: IBox) {
  const allOptions = yield* select(selectors.options.$allOptions);
  const allNames = yield* select(selectors.base.$customPresetsNames);
  const inputBox = yield* select(selectors.playground.$inputBox);
  const outputBox = yield* select(selectors.playground.$outputBox);

  if (!inputBox || !outputBox) {
    return;
  }

  const { didCancel, value } = yield dialog.form({
    component: Form,
    title: 'Save preset',
    form: {
      config: forms.preset,
      data: {},
      allOptions,
      allDetails: {},
      allMethods: {},
    },
  });

  if (didCancel || !value) {
    return;
  }

  const { name } = value;

  const nameExists = allNames.includes(name);

  if (nameExists) {
    toast.show('Preset with this name already exists', 'error');
    return;
  }

  yield* call(patchBox, box, { currentOutputId: '1' });

  const prompt = get(inputBox, 'values.prompt', '');
  const generationParams = getGenerationParams(outputBox.values);

  const payload = {
    text: prompt,
    name,
    isUserPreset: true,
    params: { ...generationParams, length: prompt?.length, prompt: prompt },
    apiType: 'completion',
  };

  const promise = yield* put(actions.presets.add(payload));
  const res = yield promise;
  const id = get(res, 'data.id', '');

  toast.show(
    `Preset "${name}" saved. Please refresh the page if necessary.`,
    'success'
  );

  yield delay(200);

  yield* call(patchBox, box, { presetId: id });
}

export function* promptShare(_action: ActionPlay, _box: IBox) {
  const input = yield* select(selectors.playground.$inputBox);
  const params = yield* select(selectors.playground.$params);
  const text = get(input, 'values.prompt', '');

  const response: any = yield* call(api.completion.createShare as any, {
    params,
    text,
    apiType: 'completion',
  });

  const url = `${window.__env__.VITE_APP_SERVING_URL || 'https://studio.ai21.com'}/home/complete?promptShare=${response.data?.id}`; // prettier-ignore

  const { didCancel } = yield dialog.custom({
    title: 'Prompt Share',
    component: UrlShare,
    componentProps: {
      url,
    },
  });

  if (didCancel) {
    return;
  }
}

export function* loadPromptShare(event: any) {
  const { data } = event;
  const { promptShareId } = data;
  const response = yield* call(api.completion.getShare, promptShareId);

  if (response.isSuccess) {
    const { data } = response;
    yield call(loadParamsAndPrompt, data);
  } else {
    toast.show('can not load preset', 'error');
  }
}

export function* loadPreset(event: any) {
  const { data } = event;
  const { presetId } = data;

  yield delay(200);

  const preset = yield* select(selectors.singles.$preset, presetId);
  if (preset && preset.apiType === 'answer') {
    yield call(loadAnswerInputs, preset);
    return;
  }

  yield call(loadParamsAndPrompt, preset, presetId);
}

export function* root() {
  const channel1 = customEvenChannel('promptShare/load');
  yield takeEvery(channel1, loadPromptShare);

  const channel2 = customEvenChannel('preset/load');
  yield takeEvery(channel2, loadPreset);
}
