import type { IRouteMap } from '@ai21/studio-ui';

export enum AppRoutes {
  SHOWROOM = '/use-cases',
  APP = '/use-cases/:appTitle/:appId',
  COMPANY = '/company',
}

export const publicRoutes: IRouteMap = {};

export const privateRoutes: IRouteMap = {
  [AppRoutes.SHOWROOM]: 'showroom',
  [AppRoutes.APP]: 'app',
  [AppRoutes.COMPANY]: 'company',
};
