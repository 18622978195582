import { delay, fork, put } from 'saga-ts';
import { setJson } from 'shared-base';
import { authState } from '../utils/globals';
import { logger } from '../utils/log';
import { getLocation, getRouteType } from '../utils/routes';
import { loadTokensFromStorage } from './helpers/tokens';

// step #1
export function* start() {
  const { options } = authState;

  const location = getLocation();
  const routeType = getRouteType(location, options.routes);
  authState.routeType = routeType;

  logger.CheckRequestedRoute();

  if (routeType === 'public' || routeType === 'external' || routeType === 'authStage') {
    return;
  }

  if (routeType === 'auth') {
    authState.isManual = true;
  }

  if (routeType !== 'auth') {
    setJson('originalLocation', location);
  }

  yield put({ type: 'saga/auth' });
}

export function* root() {
  loadTokensFromStorage();
  yield delay(50);
  yield fork(start);
}
