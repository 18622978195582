import { Icon } from '@ai21/studio-ui';
import { Text, Wrapper } from './DemosNotFoundPage.style';

export type DemosNotFoundPageProps = {};

export function DemosNotFoundPage(props: DemosNotFoundPageProps) {
  return (
    <Wrapper>
      <Icon iconName='Database' />
      <Text>Could not found demo</Text>
    </Wrapper>
  );
}

export default DemosNotFoundPage;
