import { get, set } from 'lodash';

export const idTransformer = (fieldId: string) => (arr?: Json[]) => {
  if (!Array.isArray(arr) || !arr) {
    return arr;
  }

  return arr.map((item: Json) => {
    const newItem = { ...item };
    newItem.id = newItem[fieldId];
    delete newItem[fieldId];
    return newItem;
  });
};

export const customTransformer = (map: Json) => (arr?: Json[]) => {
  let newArr = [];
  if (!Array.isArray(arr)) {
    if (!arr) {
      return arr;
    }
    newArr = [arr];
  } else {
    newArr = arr;
  }

  return newArr.map((item: Json) => {
    const output = { ...item };

    Object.keys(map).forEach((key) => {
      const currentKey = map[key];
      const value = get(output, key);
      set(output, currentKey, value);
    });

    return output;
  });
};
