import { Alert, AlertColor, AlertTitle, Button, Stack } from '@mui/material';
import { Actions, Content, Header, Top, Wrapper } from './UploadSummary.style';
import classnames from 'classnames';

export type UploadSummaryProps = {
  topMessage: string;
  topMessageFlavour: AlertColor;
  errors?: Json[];
  onCta: () => void;
  onCancel: () => void;
};

export function UploadSummary(props: UploadSummaryProps) {
  const { topMessage, topMessageFlavour = 'success', errors = [] } = props;

  // (4 failed)
  const topMessageExtra = errors.length > 0 ? ` (${errors.length} failed)` : '';

  function renderError(error: Json, index: number) {
    return (
      <Alert severity='error' key={index}>
        <AlertTitle>{error.title}</AlertTitle>
        {error.description}
      </Alert>
    );
  }

  function renderErrors() {
    if (errors.length === 0) {
      return null;
    }

    return (
      <>
        <Header variant='body1bold'>{errors.length} Failed</Header>
        <Stack sx={{ width: '100%', marginTop: '10px' }} spacing={2}>
          {errors.map((error: Json, index) => renderError(error, index))}
        </Stack>
      </>
    );
  }

  const className = classnames('UploadSummary-wrapper', {
    noError: errors.length === 0,
  });

  return (
    <Wrapper className={className} data-testid='UploadSummary-wrapper'>
      <Content>
        <Top className='top'>
          <Alert severity={topMessageFlavour}>
            <AlertTitle>
              {topMessage}
              {topMessageExtra}
            </AlertTitle>
          </Alert>
        </Top>
        {renderErrors()}
      </Content>
      <Actions>
        <Button variant='outlined' color='secondary' onClick={props.onCancel}>
          Close
        </Button>
      </Actions>
    </Wrapper>
  );
}

export default UploadSummary;
