interface DatasetIconProps {
  style: React.CSSProperties;
}

export function DatasetIcon({ style }: DatasetIconProps) {
  return (
    <svg
      style={style}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.443 3.56c-.415.366-.735.852-.735 1.44v10c0 .569.303 1.049.713 1.42s.977.682 1.645.933c1.34.502 3.114.772 4.934.772 1.82 0 3.594-.27 4.933-.772.669-.25 1.235-.562 1.646-.933.41-.371.713-.852.713-1.42V5c0-.588-.32-1.074-.735-1.44-.415-.369-.98-.671-1.624-.913-1.293-.485-3.037-.772-4.933-.772s-3.64.287-4.934.772c-.644.242-1.209.544-1.623.912zm.829.935c-.26.23-.314.403-.314.505 0 .102.053.274.314.505.26.23.671.467 1.233.678 1.12.42 2.71.692 4.495.692 1.786 0 3.375-.273 4.495-.692.562-.211.973-.447 1.233-.678.26-.23.314-.403.314-.505 0-.102-.053-.274-.314-.505-.26-.23-.671-.467-1.233-.678-1.12-.42-2.71-.692-4.495-.692-1.786 0-3.375.273-4.495.692-.562.211-.973.447-1.233.678zm11.77 7.325V15c0 .095-.049.264-.302.493-.254.23-.664.471-1.245.69-1.162.435-2.779.692-4.495.692-1.716 0-3.333-.257-4.495-.692-.581-.219-.991-.46-1.245-.69-.254-.23-.302-.398-.302-.493v-3.18c.322.2.696.378 1.108.533 1.34.502 3.114.772 4.934.772 1.82 0 3.594-.27 4.933-.772a6.264 6.264 0 0 0 1.109-.533zM3.958 10c0 .095.048.264.302.493.254.23.664.471 1.245.69 1.162.435 2.779.692 4.495.692 1.716 0 3.333-.257 4.495-.692.581-.219.991-.46 1.245-.69.253-.23.302-.398.302-.493V6.82c-.326.203-.702.38-1.109.533-1.293.485-3.037.772-4.933.772s-3.64-.287-4.934-.772a6.3 6.3 0 0 1-1.108-.532V10z'
      />
    </svg>
  );
}
