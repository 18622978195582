import { api } from '@ai21/studio-api';
import { get } from 'lodash';
import { call, takeEvery } from 'saga-ts';

export function* addDemo(action: any) {
  const { payload } = action;
  yield call(api.demos.createPublicDemo, payload);
}

export function* deleteDemo(action: any) {
  const { id } = action;
  yield call(api.demos.removePublicDemo, id);
}

export function* patchDemo(action: any) {
  const isDeleted = get(action, 'payload.isDeleted', false);

  if (isDeleted) {
    yield call(deleteDemo, action);
    return;
  }

  const { id } = action;
  yield call(api.demos.editPublicDemo, id, action.payload);
}

export function* root() {
  yield takeEvery('ADD_DEMO', addDemo);
  yield takeEvery('PATCH_DEMO', patchDemo);
}
