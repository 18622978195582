import { Tooltip } from '@ai21/studio-base-ui';
import { useEllipsis } from './TextWithTooltip.hooks';
import { Wrapper } from './TextWithTooltip.style';

export type TextWithTooltipProps = {
  children: string;
};

export function TextWithTooltip(props: TextWithTooltipProps) {
  const [ref, withEllipsis, style] = useEllipsis(props.children);

  if (withEllipsis) {
    return (
      <Tooltip title={props.children}>
        <Wrapper style={style} data-testid='TextWithTooltip-wrapper' ref={ref}>
          {props.children}
        </Wrapper>
      </Tooltip>
    );
  }

  return (
    <Wrapper data-testid='TextWithTooltip-wrapper' ref={ref}>
      {props.children}
    </Wrapper>
  );
}

export default TextWithTooltip;
