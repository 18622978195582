import { api } from '@ai21/studio-api';
import { actions, selectors } from '@ai21/studio-store';
import { prompt, toast } from '@ai21/studio-ui';
import { call, delay, put, select, takeEvery } from 'saga-ts';
import { WebRoutes } from '../bootstrap/Web.routes';
import { UploadDatasetDialogContainer } from '../components/datasets.DatasetActions/UploadDatasetDialogContainer';
import { CustomModelType } from '../data-types/Model';
import {
  DATASET_COPY_ID,
  DELETE_DATASET_PRESSED,
  DatasetsType,
  RESET_DATASETS_PAGE_STATE,
  UPLOAD_DATASET_PRESSED,
} from '../redux/actionTypes/datasetsActionTypes';
import { setCustomModelParameters } from '../redux/actions/actions.others';
import { Json } from '../types';
import { downloadUrl } from '../utils/download';
import { navigate } from './saga.navigation';
import { ax } from '@ai21/studio-analytics';

type Verb =
  | 'train' //
  | 'download'
  | 'downloadValidation'
  | 'downloadTraining'
  | 'copyId'
  | 'new'
  | 'upload'
  | 'navigate'
  | 'evaluate'
  | 'delete';

const MAX_LINES_FOR_EVALUATION = 1000;

type ActionFineTuningSet = {
  type: 'FINE_TUNING_SET';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  train: trainModelOnFineTuningSet,
  download: downloadFineTuningSet,
  downloadValidation: downloadFineTuningSetValidation,
  downloadTraining: downloadFineTuningSetTraining,
  copyId: copySetId,
  evaluate: evaluateFineTuningSet,
  delete: deleteFineTuningSet,
  new: navigateToFineTuningSetPageWithWizard,
  navigate: navigateToFineTuningSetPage,
  upload: startUploadWizard,
};

function* navigateToFineTuningSetPageWithWizard(action: ActionFineTuningSet) {
  navigate(WebRoutes.fineTuningSets);
  yield delay(100);
  yield call(startUploadWizard, action);
}

function* evaluateFineTuningSet(action: ActionFineTuningSet) {
  const { id } = action;
  const fts = yield* select(selectors.singles.$fts, id);

  if (!fts) {
    return;
  }

  const { linesCount } = fts;

  if (linesCount > MAX_LINES_FOR_EVALUATION) {
    toast.show(
      `Evaluating sets with more than ${MAX_LINES_FOR_EVALUATION} lines is not supported`,
      'error'
    );
    return;
  }

  const response: any = yield* call(api.fts.evaluate, id);

  if (!response?.isSuccess) {
    toast.show('Failed to create evaluation job for fine tuning set', 'error');
    return;
  }

  yield put(actions.evaluationJobs.get({}));
  yield delay(500);

  ax.nudge('fts.transformations');

  yield put({
    type: 'NAVIGATE',
    to: `/tools/evaluation/${response.data.id}/inbox`,
  });
}

function* navigateToFineTuningSetPage(action: ActionFineTuningSet) {
  navigate(WebRoutes.fineTuningSets);
}

function* trainModelOnFineTuningSet(action: ActionFineTuningSet) {
  const { id } = action;
  const currentFineTuningSet = yield* select(selectors.singles.$fts, id);
  const FineTuningSetDetails = {
    datasetName: currentFineTuningSet.name,
    sizeBytes: currentFineTuningSet.fileSizeInBytes,
    modelType: CustomModelType.LIGHT_J2,
    datasetId: id,
  };
  yield* put(
    (setCustomModelParameters as any)(FineTuningSetDetails, window.location.pathname.split('/')[1])
  );
  navigate(WebRoutes.trainModelStep2);
}

function* downloadFineTuningSet(action: ActionFineTuningSet) {
  const { id } = action;
  const FineTuningSet = yield* select(selectors.singles.$fts, id);
  const { name } = FineTuningSet;
  const response = yield* call(api.fts.getDownloadUrl, id, name);
  if (response?.isSuccess) {
    yield put({
      type: 'FETCH_DATASET_URL_SUCCESS',
      datasetType: DatasetsType.FULL_DATASET,
      datasetId: id,
    });
  }
  downloadUrl(response?.data?.dataset_url);
}

function* downloadFineTuningSetValidation(action: ActionFineTuningSet) {
  const { id } = action;
  const response = yield* call(api.fts.getDownloadUrl, id, 'dev.jsonl');
  if (response?.isSuccess) {
    yield put({
      type: 'FETCH_DATASET_URL_SUCCESS',
      datasetType: DatasetsType.VALIDATION,
      datasetId: id,
    });
  }
  downloadUrl(response?.data?.dataset_url);
}

function* downloadFineTuningSetTraining(action: ActionFineTuningSet) {
  const { id } = action;
  const response = yield* call(api.fts.getDownloadUrl, id, 'train.jsonl');
  if (response?.isSuccess) {
    yield put({
      type: 'FETCH_DATASET_URL_SUCCESS',
      datasetType: DatasetsType.TRAINING,
      datasetId: id,
    });
  }
  downloadUrl(response?.data?.dataset_url);
}

function getOrigin() {
  const { pathname } = document.location;

  if (pathname.startsWith('/overview')) {
    return 'overviewPage';
  }

  if (pathname.startsWith('/fine-tuning-sets')) {
    return 'fineTuningSetsPage';
  }
}

function* copySetId(action: ActionFineTuningSet) {
  const { id } = action;
  navigator.clipboard.writeText(id);
  const origin = getOrigin();
  yield put({
    type: DATASET_COPY_ID,
    origin,
    datasetId: id,
  });
  toast.show('Copied to clipboard', { type: 'success' });
}

function* deleteFineTuningSet(action: ActionFineTuningSet) {
  const { id } = action;
  const FineTuningSet = yield* select(selectors.singles.$fts, id);
  const { name } = FineTuningSet;
  const origin = getOrigin();

  yield put({
    type: DELETE_DATASET_PRESSED,
    origin,
    datasetId: id,
  });

  const { didCancel } = yield prompt.confirm({
    title: 'Delete Set',
    description: `Are you sure you want to delete the fine-tuning set "${name}"?`,
    ctaButtonText: 'Delete Set',
    intention: 'delete',
  });

  if (didCancel) {
    return;
  }

  yield put(actions.fineTuningSets.delete(id));
}

function* startUploadWizard(_action: ActionFineTuningSet) {
  yield put({ type: RESET_DATASETS_PAGE_STATE });
  yield put({
    type: UPLOAD_DATASET_PRESSED,
  });
  yield prompt.custom({
    component: UploadDatasetDialogContainer,
    componentProps: {
      navigate: (path: string) => {
        navigate(path);
      },
    },
    intention: 'upload',
  });
}

export function* fineTuningSet(action: ActionFineTuningSet) {
  const { verb } = action;
  yield delay(100);

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('FINE_TUNING_SET', fineTuningSet);
}
