import { call, fork, takeEvery } from 'saga-ts';
import { firebase } from '../utils/globals';
import { navigateToLogin } from './helpers/navigation';
import { setBoolean } from '../utils/storage';
import { shouldUseCookieAuth } from './saga.featureflags';
// import * as a from '../utils/analytics';

declare const process: any;

type ActionLogout = {
  type: 'LOGOUT';
};

const identityBaseUrl = ((window as any).__env__.VITE_IDENTITY_SERVER_URL ?? '') + '/identity';

async function endSession(): Promise<any> {
  try {
    const response = await fetch(`${identityBaseUrl}/end-session`, {
      credentials: 'include',
      method: 'POST',
    });

    if (response.status !== 200) {
      return null;
    }

    return await response.json();
  } catch (err) {
    return null;
  }
}
export function* logout(_action: ActionLogout) {
  try {
    const shouldUseCookie: boolean = yield call(shouldUseCookieAuth);

    if (shouldUseCookie && !process.env.FIRESTORE_EMULATOR_HOST) {
      yield call(endSession);
    }

    yield firebase.signOut();
    localStorage.clear();
    sessionStorage.clear();
    setBoolean('jotFormShown', true);
    setBoolean('USER_LOGGED_IN_ONCE', true);
    yield* fork(navigateToLogin);
  } catch (err: any) {}

  // a.logoutSuccess();

  if (process.env.FIRESTORE_EMULATOR_HOST) {
    document.location.href = '/login';
    return;
  }
}

export function* root() {
  yield takeEvery('LOGOUT', logout);
}
