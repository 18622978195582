import type { ButtonProps as ButtonPropsMui } from '@mui/material';
import { CircularProgress, Tooltip } from '@mui/material';
import classnames from 'classnames';
import React, { ForwardedRef } from 'react';
import { invokeEvent } from 'shared-base';
import { useShortKey } from '../../hooks/useShortKeys';
import { IShortKey } from '../../types';
import { Loading, LoadingHiddenText, Wrapper } from './Button.style';
import { parseShortKeyText } from './Button.utils';

export type ButtonProps = ButtonPropsMui & {
  isLoading?: boolean;
  className?: string;
  shortKey?: IShortKey;
  shortKeyLocation?: 'before' | 'after';
  tooltip?: string;
  tooltipDelay?: number;
  onShortKey?: () => void;
  ['data-testid']?: string;
  source?: string;
  backgroundColor?: string;
  textColor?: string;
};

export const Button = React.forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      isLoading,
      shortKey,
      shortKeyLocation = 'after',
      onShortKey,
      tooltip,
      tooltipDelay = 500,
      source,
      backgroundColor,
      textColor,
      ...rest
    } = props;

    function onClick(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      if (!props.onClick || isLoading) {
        return;
      }

      invokeEvent('global/click', {
        source,
        ev,
      });

      return props.onClick(ev);
    }

    function renderInner() {
      if (isLoading) {
        return (
          <Loading>
            <LoadingHiddenText>{props.children}</LoadingHiddenText>
            <CircularProgress color='warning' size='20px' />
          </Loading>
        );
      }

      const shortKeyText = parseShortKeyText(shortKey);

      if (shortKeyLocation === 'before') {
        return (
          <>
            {shortKeyText && <span>{shortKeyText}</span>}
            {props.children}
          </>
        );
      } else {
        return (
          <>
            {props.children}
            {shortKeyText && <span>{shortKeyText}</span>}
          </>
        );
      }
    }

    useShortKey(
      shortKey,
      () => {
        if (!onShortKey || props.disabled) {
          return;
        }

        onShortKey();
      },
      [onShortKey, props.disabled]
    );

    const className = classnames('Button-wrapper', props.className, {});

    function renderButton() {
      const style: React.CSSProperties = {};

      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }

      if (textColor) {
        style.color = textColor;
      }

      return (
        <Wrapper
          ref={ref}
          {...rest}
          className={className}
          onClick={onClick}
          data-testid={rest['data-testid'] ?? 'Button-wrapper'}
          style={style}
        >
          {renderInner()}
        </Wrapper>
      );
    }

    if (tooltip) {
      return (
        <Tooltip title={tooltip} enterDelay={tooltipDelay} arrow>
          {renderButton()}
        </Tooltip>
      );
    } else {
      return renderButton();
    }
  }
);

export default Button;
