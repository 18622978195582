export function ConversationalRagIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='conversational-rag'>
        <g id='Vector'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M3.25 4.29167C3.25 3.71637 3.71637 3.25 4.29167 3.25H7.36612L9.68306 5.56694C9.80027 5.68415 9.95924 5.75 10.125 5.75H15.9583C16.5336 5.75 17 6.21637 17 6.79167V13.4583C17 14.0336 16.5336 14.5 15.9583 14.5H14.125C13.7798 14.5 13.5 14.7798 13.5 15.125C13.5 15.4702 13.7798 15.75 14.125 15.75H15.9583C17.224 15.75 18.25 14.724 18.25 13.4583V6.79167C18.25 5.52601 17.224 4.5 15.9583 4.5H10.3839L8.06694 2.18306C7.94973 2.06585 7.79076 2 7.625 2H4.29167C3.02601 2 2 3.02601 2 4.29167V6.375C2 6.72018 2.27982 7 2.625 7C2.97018 7 3.25 6.72018 3.25 6.375V4.29167Z'
            fill='currentColor'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M3.45833 9.25C3.34327 9.25 3.25 9.34327 3.25 9.45833V16.2828L4.68306 14.8497C4.80027 14.7325 4.95924 14.6667 5.125 14.6667H10.9583C11.0734 14.6667 11.1667 14.5734 11.1667 14.4583V12.7917C11.1667 12.4465 11.4465 12.1667 11.7917 12.1667C12.1368 12.1667 12.4167 12.4465 12.4167 12.7917V14.4583C12.4167 15.2637 11.7637 15.9167 10.9583 15.9167H5.38388L3.06694 18.2336C2.88819 18.4124 2.61937 18.4658 2.38582 18.3691C2.15228 18.2724 2 18.0445 2 17.7917V9.45833C2 8.65292 2.65292 8 3.45833 8H5.125C5.47018 8 5.75 8.27982 5.75 8.625C5.75 8.97018 5.47018 9.25 5.125 9.25H3.45833Z'
            fill='currentColor'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M10.9583 9.25C11.0734 9.25 11.1666 9.34327 11.1666 9.45833L11.1667 14.4583C11.1667 14.5734 11.0734 14.6667 10.9583 14.6667H5.125C4.95924 14.6667 4.80027 14.7325 4.68306 14.8497L3.25 16.2828V9.45833C3.25 9.34327 3.34327 9.25 3.45833 9.25H10.9583ZM12.4166 9.45833C12.4166 8.65292 11.7637 8 10.9583 8H3.45833C2.65292 8 2 8.65292 2 9.45833V17.7917C2 18.0445 2.15228 18.2724 2.38582 18.3691C2.61937 18.4658 2.88819 18.4124 3.06694 18.2336L5.38388 15.9167H10.9583C11.7637 15.9167 12.4167 15.2637 12.4167 14.4583L12.4166 9.45833Z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
}
