import { api } from '@ai21/studio-api';
import { actions, selectors } from '@ai21/studio-store';
import { format, toast } from '@ai21/studio-ui';
import { call, put, select } from 'saga-ts';
import { get } from 'lodash';
import { Form } from '@ai21/studio-forms';
import { formDefaults, forms } from '../../_definitions/forms';
import { prompt } from '@ai21/studio-ui';

export type IDocumentParams = {
  file: File;
  name: string;
  path: string;
  publicUrl: string;
  labels: string[];
};

export const showToastOnAddLabelsResult = (success: number, failed: number) => {
  if (failed > 0) {
    toast.show(
      `Failed to add labels to ${failed} documents, please try again.`,
      'error'
    );
  } else {
    toast.show(`New labels successfully added!`, 'success');
  }
  return;
};

export const showToastOnDeleteDocuments = (success: number, failed: number) => {
  if (failed > 0) {
    toast.show(
      `Failed to delete ${failed} documents, please try again.`,
      'error'
    );
  } else {
    toast.show(`Successfully deleted ${success} documents.`, 'success');
  }
  return;
};

export function* ShowAddLabelPrompt(selectedFiles: number) {
  const { didCancel, value = {} } = yield prompt.form({
    component: Form,
    title: 'Add labels',
    form: {
      config: {
        ...forms.docAddLabels,
        description: `Add new or existing labels to the ${selectedFiles} selected documents.`,
      },
      data: {
        ...formDefaults.docEdit,
        labels: [],
      },
      allOptions: {},
      allDetails: {},
      allMethods: {},
    },
  });
  return { didCancel, value };
}

export function* AddLabelsToDocuments(newlabels: string[], ids: string[]) {
  let success = 0;
  let failed = 0;
  for (const id of ids) {
    const doc = yield* select(selectors.singles.$document, id);

    if (!doc) {
      failed++;
      return;
    }

    const { labels = [] } = doc || {};
    const response = yield* call(api.documents.update, id, {
      labels: [...labels, ...newlabels],
    });

    if (response.isSuccess) {
      success++;
    } else {
      failed++;
    }
  }

  return { success, failed };
}

export function* DeleteDocuments(ids: string[]) {
  let success = 0;
  let failed = 0;
  for (const id of ids) {
    const response = yield* call(api.documents.remove, id);
    if (response.isSuccess) {
      success++;
    } else {
      failed++;
    }
  }

  return { success, failed };
}

export function* createDocument(doc: IDocumentParams) {
  const { file, name, path, publicUrl, labels } = doc;
  const user = yield* select(selectors.raw.$rawUser);
  const { userId } = user;

  const response: any = yield* call(api.documents.create, {
    file,
    name,
    path,
    publicUrl,
    labels,
  });

  if (!response.isSuccess) {
    const errorMessage = get(
      response,
      'data.detail',
      'Failed to upload document'
    );
    toast.show(errorMessage, 'error');
    return;
  }

  const docId = response.data.fileId as any;

  if (!docId) {
    return;
  }

  yield put(
    actions.documents.set(docId, {
      id: docId,
      name,
      sizeBytes: file.size,
      creationDate: format.date.db(Date.now()),
      lastUpdated: format.date.db(Date.now()),
      status: 'UPLOADED',
      createdBy: userId,
      labels: labels,
    })
  );
  toast.show('File uploaded successfully', { type: 'success' });
}
