import { tokens, Typography } from '@ai21/studio-base-ui';
import { ButtonMenu, IconButton } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;

  .Field-wrapper {
    margin: 0;
  }

  .Label-wrapper {
    display: none;
  }

  .Dropdown-wrapper .MuiInputBase-root {
    height: 36px;
  }

  .Table-collection {
    padding: 0;
    margin: 0 -16px;

    .Table-wrapper {
      border-color: transparent;
    }

    .MuiDataGrid-pinnedColumns [data-field='actions'] {
      padding: 12px;
    }
  }

  .MuiOutlinedInput-input {
    text-overflow: ellipsis;
    flex-grow: 1;
    width: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 6px;

  button {
    margin: 0 2px;
  }
`;

export const Action = styled(IconButton)`
  width: 36px;
  height: 36px;
  padding: 0;
  border: 1px solid ${tokens.primary.borderDefault};
  background: #fff;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Menu = styled(ButtonMenu)`
  .MuiButton-root {
    padding: 6px;
    height: 36px;
    margin-left: 8px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Label = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  /* text-transform: capitalize; */
  font-size: 14px;
  color: ${tokens.neutral.deepest};
  margin-bottom: 6px;

  span {
    display: flex;
    align-items: center;
  }

  svg {
    height: 14px;
    width: 14px;
    color: ${tokens.text.secondary};
  }
`;

export const Empty = styled(Typography)`
  padding-top: 16px;
`;
