import { WidgetId, WidgetPayload } from '../MiniPlayground.types';
import { payload as answer } from './widget.answer';
import { payload as gec } from './widget.gec';
import { payload as improvements } from './widget.improvements';
import { payload as paraphrase } from './widget.paraphrase';
import { payload as segmentation } from './widget.segmentation';
import { payload as summarization } from './widget.summarization';

export const allWidgets: Record<WidgetId, WidgetPayload> = {
  answer,
  gec,
  improvements,
  paraphrase,
  segmentation,
  summarization,
};
