import { fireRequest } from './axios';
import { PayloadCreateFineTuningSet } from './types';
import { customTransformer } from './utils/transformers';

export const getAll = () => {
  return fireRequest<Json>({
    path: '/dataset',
    endpoint: 'dataset.getAll',
    transformer: customTransformer({
      datasetName: 'name',
      sizeBytes: 'fileSizeInBytes',
      creationDate: 'dateCreated',
      numExamples: 'linesCount',
    }),
  });
};

export const create = (payload: PayloadCreateFineTuningSet) => {
  const { name, file, selectedColumns, approveWhitespaceCorrection, deleteLongRows } = payload;

  const formData = new FormData();
  formData.append('dataset_name', name);
  formData.append('dataset_file', file);

  if (selectedColumns) {
    formData.append('selected_columns', JSON.stringify(selectedColumns));
  }

  if (approveWhitespaceCorrection !== undefined) {
    formData.append('approve_whitespace_correction', String(approveWhitespaceCorrection));
  }

  if (deleteLongRows !== undefined) {
    formData.append('delete_long_rows', String(deleteLongRows));
  }

  return fireRequest<Json>({
    path: '/dataset',
    method: 'post',
    data: formData,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    endpoint: 'dataset.create',
    transformer: customTransformer({
      datasetName: 'name',
      sizeBytes: 'fileSizeInBytes',
      creationDate: 'dateCreated',
      numExamples: 'linesCount',
    }),
  });
};

export const preview = (datasetId: string, numExamples: number) => {
  const path = `/dataset/dataset-preview/${datasetId}`;

  return fireRequest<Json>({
    path,
    data: {
      numExamples,
    },
    endpoint: 'dataset.preview',
  });
};

export const evaluate = (datasetId: string) => {
  const path = `/sets/fts/${datasetId}/evaluation`;

  return fireRequest<Json>({
    path,
    method: 'post',
    endpoint: 'dataset.evaluate',
  });
};

export const getDownloadUrl = (datasetId: string, datasetName: string) => {
  return fireRequest<Json>({
    path: `/dataset/url/${datasetId}`,
    data: {
      dataset_name: datasetName,
    },
    endpoint: 'dataset.getDownloadUrl',
  });
};

export const downloadExampleSetUrl = () => {
  return fireRequest<Json>({
    path: '/dataset/example-dataset',
    endpoint: 'dataset.downloadExampleSetUrl',
  });
};

export const remove = (datasetId: string) => {
  return fireRequest<Json>({
    method: 'delete',
    path: `/dataset/${datasetId}`,
    endpoint: 'dataset.remove',
  });
};

export const fts = {
  getAll,
  create,
  preview,
  getDownloadUrl,
  downloadExampleSetUrl,
  remove,
  evaluate,
};
