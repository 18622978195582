export function ShortFormIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='6' fill='white' />
      <rect width='52' height='52' rx='6' fill='#8459AE' fillOpacity='0.1' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1199 25.75C16.1199 24.2312 17.3511 23 18.8699 23H32.8699C34.3887 23 35.6199 24.2312 35.6199 25.75V27.75C35.6199 29.2688 34.3887 30.5 32.8699 30.5H18.8699C17.3511 30.5 16.1199 29.2688 16.1199 27.75V25.75ZM18.8699 24.5C18.1795 24.5 17.6199 25.0596 17.6199 25.75V27.75C17.6199 28.4403 18.1795 29 18.8699 29H32.8699C33.5602 29 34.1199 28.4403 34.1199 27.75V25.75C34.1199 25.0596 33.5602 24.5 32.8699 24.5H18.8699Z'
        fill='black'
      />
    </svg>
  );
}
