import { toast } from '@ai21/studio-base-ui';
import { call, delay, takeEvery } from 'saga-ts';
import { invokeEvent } from 'shared-base';
import { AuthErrors, LoginProviderAll } from '../types';
import { authState, firebase } from '../utils/globals';
import { logger } from '../utils/log';
import { navigateToCheckEmail } from './helpers/navigation';
import { get } from 'lodash';

type ActionEmail = {
  type: 'SIGNUP_EMAIL';
  params: {
    email: string;
    password: string;
  };
  resolve: () => void;
  reject: () => void;
};

export function* signUpEmail(action: ActionEmail) {
  const provider: LoginProviderAll = 'EMAIL_PW';
  authState.lastProvider = provider;

  const { params, resolve } = action;
  const { email, password } = params;

  const res = yield* call(firebase.signUpWithEmailAndPassword, email, password);

  resolve();

  if (res.err) {
    yield* call(handleError, res.err, provider);
    return;
  }

  yield delay(1000);

  yield* call(handleSuccess, res.user, provider);
}

export function* handleSuccess(_user: any, _provider: LoginProviderAll) {
  yield* navigateToCheckEmail();

  logger.AuthProviderSucceeded();
}

export function* handleError(err: any, _provider: LoginProviderAll) {
  const { code } = err;

  switch (code) {
    case AuthErrors.popupBlocked:
      // perhaps show instructions to enable popups
      break;
    default:
  }

  logger.AuthProviderFailed(err);

  logger.AuthError(err);

  const userFriendlyError = firebase.parseAuthError(code);
  invokeEvent('auth_error', { code: userFriendlyError });
}
export function* resend() {
  firebase.resendVerificationEmail();

  toast.show('Verification email sent');
}

export function* checkLimboState(user: any) {
  const isEmailProvider = get(user, 'providerData[0].providerId') === 'password';
  const isEmailVerified = get(user, 'emailVerified');
  const path = document.location.pathname;

  if (!isEmailProvider || isEmailVerified) {
    return;
  }

  if (path === '/home') {
    yield* navigateToCheckEmail(true);
  }

  if (path === '/sign-up') {
    yield delay(1000);
    yield* navigateToCheckEmail();
  }
}

export function* root() {
  yield takeEvery('SIGNUP_EMAIL', signUpEmail);
  yield takeEvery('RESEND_VERIFICATION_EMAIL', resend);
}
