import { camelCase, forEach, isArray, isPlainObject } from 'lodash';
import { API_HOST, MODELS_DISPLAY_NAMES } from '../constants/constants';
import { CompletionResponse } from '../data-types/Completion';
import { ModelEndpointListElement } from '../data-types/Model';

export type Order = 'asc' | 'desc';

export function compareItems<T extends object, U extends keyof T, V extends keyof T[U]>(
  key: U,
  order: 'asc' | 'desc',
  v: V
) {
  const multiplier = order === 'asc' ? -1 : 1;
  return function compare(a: T, b: T) {
    if (!b[key][v] && a[key][v]) {
      return -1 * multiplier;
    }
    if (b[key][v] && !a[key][v]) {
      return 1 * multiplier;
    }
    if (b[key][v] < a[key][v]) {
      return -1 * multiplier;
    }
    if (b[key][v] > a[key][v]) {
      return 1 * multiplier;
    }
    return 0;
  };
}

export function isEmpty(str: string | undefined) {
  switch (str) {
    case '':
    case null:
    case undefined:
      return true;
    default:
      return false;
  }
}

export function formatBytes(bytes: number | undefined, decimals: number) {
  if (!bytes || bytes === 0) {
    return '0 Bytes';
  }
  const unitMultiple = 1024;
  const unitNames =
    unitMultiple === 1024 // 1000 bytes in 1 Kilobyte (KB) or 1024 bytes for the binary version (KiB)
      ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
  return `${parseFloat((bytes / unitMultiple ** unitChanges).toFixed(decimals || 0))} ${
    unitNames[unitChanges]
  }`;
}

export function compareByStar(a: { [starred: string]: string }, b: { [starred: string]: string }) {
  const starredKeyName = 'starred';
  if (a[starredKeyName] && !b[starredKeyName]) {
    return -1;
  }
  if (!a[starredKeyName] && b[starredKeyName]) {
    return 1;
  }
  return 0;
}

export function mergeCompletionResponse(completionResponse: CompletionResponse) {
  let offset = 0;
  if (completionResponse.prompt.tokens.length > 0) {
    offset =
      completionResponse.prompt.tokens[completionResponse.prompt.tokens.length - 1].textRange.end;
  }
  return completionResponse.prompt.tokens.concat(
    completionResponse.completions[0].data.tokens.map((token) => ({
      ...token,
      textRange: { start: token.textRange.start + offset, end: token.textRange.end + offset },
    }))
  );
}

export function getPosition(element: HTMLElement | null) {
  if (element) {
    const rect = element.getBoundingClientRect();
    return { top: rect.bottom + 8, left: rect.left + 8 };
  }
  return null;
}

export function numberWithCommas(x: number | string | null) {
  if (x === null) return x;

  let minimumFractionDigits = 0;

  // if x is a float, we want to display 2 digits after the decimal point
  if (typeof x === 'number' && x % 1 !== 0) {
    minimumFractionDigits = 2;
  }

  return x.toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits: 2,
  });
}

export function addDays(date: Date, days: number) {
  return new Date(date.getTime() + days * 86400000);
}

export function isGeneralModel(modelName: string) {
  return Object.keys(MODELS_DISPLAY_NAMES).includes(modelName);
}

export const buildCompletionUrl = (
  modelName: string,
  customModelsList: ModelEndpointListElement[] | null
) => {
  if (isGeneralModel(modelName)) {
    return `${API_HOST}/studio/v1/${modelName}/complete`;
  }
  // CustomModel Flow:
  const modelSpecifications = customModelsList?.find((m) => m.name === modelName);
  return `${API_HOST}/studio/v1/${
    modelSpecifications?.customModelType || ''
  }/${modelName}/complete`;
};

export function isNumeric(str: number) {
  if (typeof str !== 'string') return false; // we only process strings
  return (
    !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function truncateStringDots(str: string, maxChars: number) {
  if (str.length > maxChars) {
    return `${str.substring(0, maxChars)}...`;
  }
  return str;
}

export function toCamelCase(object: any) {
  const camelCaseObject: any = isArray(object) ? [] : {};
  forEach(object, (value, key) => {
    let v = value;
    if (isPlainObject(value) || isArray(value)) {
      v = toCamelCase(value);
    }
    camelCaseObject[camelCase(key)] = v;
  });
  return camelCaseObject;
}

export function capitalizeFirstChar(value: string) {
  if (!value) {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}
