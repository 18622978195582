import { api } from '@ai21/studio-api';
import { toast } from '@ai21/studio-base-ui';
import { actions } from '@ai21/studio-store';
import { call, put, takeEvery } from 'saga-ts';
import { Json } from '../types';

type Verb = 'rotate';

type ActionApiKey = {
  type: 'API_KEY';
  verb: Verb;
  id: string;
  params?: Json;
};

const mapVerbToSaga: Record<Verb, any> = {
  rotate: rotate,
};

function* rotate(_action: ActionApiKey) {
  const response = yield* call(api.user.refreshKey);

  if (!response.isSuccess || !response.data?.apiKey) {
    toast.show('Failed to rotate API key', 'error');
    return;
  }

  yield put(actions.user.patch({ apiKey: response.data.apiKey }));
  toast.show('API key rotated successfully', 'success');
}

export function* apiKey(action: ActionApiKey) {
  const { verb } = action;

  const saga = mapVerbToSaga[verb];

  if (!saga) {
    return;
  }

  yield* saga(action);
}

export function* root() {
  yield takeEvery('API_KEY', apiKey);
}
