import { Icon, Menu, Typography } from '@ai21/studio-base-ui';
import classnames from 'classnames';
import { ITableRowAction } from '../../types';
import { Action, ListIcon, Wrapper } from './TableRowActionsMenu.style';

export type TableRowActionsMenuProps = {
  actions?: ITableRowAction[];
  onRowAction: (actionId: string) => void;
  handleClose: () => void;
  anchorEl: Element | null;
};

export function TableRowActionsMenu(props: TableRowActionsMenuProps) {
  const { actions = [], anchorEl } = props;

  if (!anchorEl) {
    return null;
  }

  function renderAction(action: ITableRowAction) {
    const { id, title, iconName } = action;

    const className = classnames(id);

    return (
      <Action
        key={id}
        className={className}
        onClick={() => props.onRowAction(id)}
        data-testid={`row-action-${id}`}
      >
        <ListIcon>
          <Icon iconName={iconName} />
        </ListIcon>
        <Typography variant='body1' style={{ display: 'flex' }}>
          {title}
        </Typography>
      </Action>
    );
  }

  function renderActions() {
    return (
      <Wrapper
        id='row-actions-menu'
        data-testid='row-actions-menu'
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl !== null}
        onClose={props.handleClose}
        style={{ marginTop: '10px' }}
      >
        {actions.map((action: ITableRowAction) => renderAction(action))}
      </Wrapper>
    );
  }

  return <>{renderActions()}</>;
}

export default TableRowActionsMenu;
