import { createContext, useEffect, useMemo } from 'react';
import { useSetState } from 'react-use';
import { IChatContext, IChatProviderProps, IChatState, Verb } from './ChatEditor.types';

export const ChatContext = createContext<IChatContext>({
  state: { currentRole: 'user' },
  messages: [],
  feedbackMap: {},
  isWaiting: false,
  callbacks: {
    onAction: (id: string, verb: Verb, params?: Json) => {},
  },
  patchState: () => {},
});

export const ChatProvider = (props: IChatProviderProps) => {
  const { id, callbacks: inCallbacks, feedbackMap, messages, isWaiting } = props;
  const [state, patchState] = useSetState<IChatState>({ currentRole: 'user' });

  const callbacks = useMemo(
    () => ({
      onAction: (_id: string, verb: Verb, params?: Json) => {
        inCallbacks.onAction(id, verb, params);
      },
    }),
    [state, inCallbacks, id]
  );

  const cValue = useMemo(
    () => ({
      state,
      isWaiting,
      feedbackMap,
      patchState,
      messages,
      callbacks,
    }),
    [state, feedbackMap, isWaiting, messages, callbacks]
  );

  return <ChatContext.Provider value={cValue}>{props.children}</ChatContext.Provider>;
};
