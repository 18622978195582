export function hash(text: string) {
  const utf8 = new TextEncoder().encode(text);
  try {
    return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');

      return hashHex;
    });
  } catch (e) {
    return '';
  }
}
