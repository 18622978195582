import { Icon } from '@ai21/studio-base-ui';
import { useContext } from 'react';
import { AskContext } from '../../AskEditor.context';
import { IconWrapper, Link, Wrapper } from './Source.style';

export type SourceProps = {
  source: Json;
};

export function Source(props: SourceProps) {
  const { source } = props;

  const { name } = source;

  const { callbacks } = useContext(AskContext);

  function onClick() {
    callbacks.onAction('seeHighlights', { sourceFile: source });
  }

  return (
    <Wrapper className='Source-wrapper' data-testid='Source-wrapper'>
      <IconWrapper>
        <Icon iconName='ExternalLink' />
      </IconWrapper>
      <Link onClick={onClick}>{name}</Link>
    </Wrapper>
  );
}

export default Source;
