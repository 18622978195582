import { Button, IconButton, Link, Typography } from '@ai21/studio-base-ui';
import { styled } from '@ai21/studio-base-ui';
import { TextField } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div``;

export const Input = styled(TextField)<{
  inputheight: number;
  textareaheight: number;
  withalert: boolean;
  borderColor: string;
}>`
  height: 100%;
  border: 1px solid ${tokens.primary.borderDefault};
  border-radius: 12px;
  overflow: auto;
  width: calc(100% - 2px);

  textarea {
    height: ${(props) => props.inputheight}px !important;
    box-sizing: ${(props) => (props.withalert ? 'inherit' : 'content-box')};
  }

  .MuiOutlinedInput-root {
    border-radius: 12px;
    align-items: start;
    border: none;
    height: ${(props) => props.textareaheight}px;
    overflow-x: hidden;
    flex-direction: ${(props) => (props.withalert ? 'column' : 'row')};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }

  &:hover {
    border-color: ${tokens.primary.borderHover};
  }

  &:focus-within {
    border-color: ${(props) => (props.borderColor == 'pink' ? '#753FEA' : '#753FEA')};
    box-shadow: ${(props) =>
      props.borderColor == 'pink' ? '0px 0px 0 1px #753FEA' : '0px 0px 0 1px #753FEA'};
  }

  &.lengthError {
    border-color: #c7200a;
    box-shadow: 0px 0px 0 1px #c7200a;
  }

  .MuiInputBase-multiline {
    ::-webkit-scrollbar {
      width: 4px;
      height: 10px;
      padding-right: 4px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background: #afafaf;
    }
  }
`;

export const Label = styled(Typography)``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  align-items: center;
  padding-top: 12px;
`;
