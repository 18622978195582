export function StatusActiveIcon() {
  return (
    <svg
      width='12'
      height='13'
      viewBox='0 0 12 13'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2652 4.22727C10.4116 4.37371 10.4116 4.61115 10.2652 4.7576L5.26517 9.7576C5.11872 9.90404 4.88128 9.90404 4.73483 9.7576L2.23483 7.2576C2.08839 7.11115 2.08839 6.87371 2.23483 6.72727C2.38128 6.58082 2.61872 6.58082 2.76517 6.72727L5 8.9621L9.73484 4.22727C9.88128 4.08082 10.1187 4.08082 10.2652 4.22727Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2652 4.22727C10.4116 4.37371 10.4116 4.61115 10.2652 4.7576L5.26517 9.7576C5.11872 9.90404 4.88128 9.90404 4.73483 9.7576L2.23483 7.2576C2.08839 7.11115 2.08839 6.87371 2.23483 6.72727C2.38128 6.58082 2.61872 6.58082 2.76517 6.72727L5 8.9621L9.73484 4.22727C9.88128 4.08082 10.1187 4.08082 10.2652 4.22727Z'
        fillOpacity='0.6'
      />
    </svg>
  );
}
