import classnames from 'classnames';
import { Icon } from '../Icon/Icon';
import { LanguageButton, ProgrammingLanguage, buttons } from './Code.data';
import { Button, WrapperLanguages } from './Code.style';
import { ButtonGroup } from '@mui/material';

export type LanguagesProps = {
  language?: ProgrammingLanguage;
  onChange: (value: ProgrammingLanguage) => void;
};

export function Languages(props: LanguagesProps) {
  const { language = 'python' } = props;

  function renderButton(button: LanguageButton) {
    const { id, title, iconName } = button;

    const className = classnames({
      selected: id === language,
    });

    return (
      <Button
        key={id}
        onClick={() => props.onChange(id)}
        className={className}
        startIcon={<Icon iconName={iconName} />}
        color='secondary'
      >
        {title}
      </Button>
    );
  }

  function renderButtons() {
    return buttons.map((button: any) => renderButton(button));
  }
  return (
    <WrapperLanguages className='Code-wrapper' data-testid='Code-wrapper'>
      <ButtonGroup size='small'>{renderButtons()}</ButtonGroup>
    </WrapperLanguages>
  );
}

export default Languages;
