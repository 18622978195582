import { actions } from '@ai21/studio-store';
import { delay, fork, put } from 'saga-ts';
import { generateRandomData } from '../utils/mocks';

function* generateMocks() {
  const data = generateRandomData(1, {
    id: 'guid',
    name: 'Evaluation_{index}',
    fileSize: 'size',
    passRate: 'percent',
    greatRate: 'percent',
    dateCreated: 'date',
    creator: 'person',
    linesCount: 'number',
    status: 'status',
  });

  for (let i of Object.values(data)) {
    yield put(actions.evaluationJobs.add(i));
  }

  const items = generateRandomData(4, {
    id: 'guid',
    index: 'index',
    input: 'lorem',
    output: 'lorem',
    modelName: 'J2-Ultra',
    timeStamp: 'date',
    source: 'dataset.external',
    rank: 'number',
    parameter: 'number',
    status: 'status',
    presetName: 'Preset 1',
    comment: 'lorem',
    outputSuggestion: 'lorem',
    evaluator: 'person',
    score: 'score',
  });

  for (let item of Object.values(items)) {
    yield put(
      actions.evaluationLines.add({
        ...item,
        setId: Object.values(data)[0].id,
      })
    );
  }
}

export function* root() {
  yield delay(1000);
  // yield fork(generateMocks);
}
