export function ClassificationIcon() {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='6' fill='white' />
      <rect width='52' height='52' rx='6' fill='#F280D2' fillOpacity='0.1' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1199 19.6667C16.1199 18.7003 16.9034 17.9167 17.8699 17.9167H21.8699C22.8364 17.9167 23.6199 18.7002 23.6199 19.6667V23.6667C23.6199 24.6332 22.8364 25.4167 21.8699 25.4167H17.8699C16.9034 25.4167 16.1199 24.6332 16.1199 23.6667V19.6667ZM17.8699 19.4167C17.7318 19.4167 17.6199 19.5287 17.6199 19.6667V23.6667C17.6199 23.8048 17.7318 23.9167 17.8699 23.9167H21.8699C22.0079 23.9167 22.1199 23.8048 22.1199 23.6667V19.6667C22.1199 19.5287 22.0079 19.4167 21.8699 19.4167H17.8699ZM26.1199 19.6667C26.1199 19.2525 26.4557 18.9167 26.8699 18.9167H34.8699C35.2841 18.9167 35.6199 19.2525 35.6199 19.6667C35.6199 20.081 35.2841 20.4167 34.8699 20.4167H26.8699C26.4557 20.4167 26.1199 20.081 26.1199 19.6667ZM26.1199 23.6667C26.1199 23.2525 26.4557 22.9167 26.8699 22.9167H31.8699C32.2841 22.9167 32.6199 23.2525 32.6199 23.6667C32.6199 24.081 32.2841 24.4167 31.8699 24.4167H26.8699C26.4557 24.4167 26.1199 24.081 26.1199 23.6667ZM16.1199 29.6667C16.1199 28.7002 16.9034 27.9167 17.8699 27.9167H21.8699C22.8364 27.9167 23.6199 28.7002 23.6199 29.6667V33.6667C23.6199 34.6332 22.8364 35.4167 21.8699 35.4167H17.8699C16.9034 35.4167 16.1199 34.6332 16.1199 33.6667V29.6667ZM17.8699 29.4167C17.7318 29.4167 17.6199 29.5287 17.6199 29.6667V33.6667C17.6199 33.8048 17.7318 33.9167 17.8699 33.9167H21.8699C22.0079 33.9167 22.1199 33.8048 22.1199 33.6667V29.6667C22.1199 29.5287 22.0079 29.4167 21.8699 29.4167H17.8699ZM26.1199 29.6667C26.1199 29.2525 26.4557 28.9167 26.8699 28.9167H34.8699C35.2841 28.9167 35.6199 29.2525 35.6199 29.6667C35.6199 30.081 35.2841 30.4167 34.8699 30.4167H26.8699C26.4557 30.4167 26.1199 30.081 26.1199 29.6667ZM26.1199 33.6667C26.1199 33.2525 26.4557 32.9167 26.8699 32.9167H31.8699C32.2841 32.9167 32.6199 33.2525 32.6199 33.6667C32.6199 34.081 32.2841 34.4167 31.8699 34.4167H26.8699C26.4557 34.4167 26.1199 34.081 26.1199 33.6667Z'
        fill='black'
      />
    </svg>
  );
}
