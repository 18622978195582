import { CellType } from '../../types';

export const mapCellTypes: Record<
  CellType,
  'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions'
> = {
  date: 'date',
  number: 'number',
  rank: 'number',
  progress: 'number',
  progressBar: 'number',
  time: 'number',
  timeAgo: 'number',
  status: 'singleSelect',
  avatar: 'string',
  icon: 'string',
  id: 'string',
  name: 'string',
  select: 'singleSelect',
  sequence: 'number',
  text: 'string',
  textCopy: 'string',
};
