import type { IRouteMap } from '@ai21/studio-ui';

export enum AppRoutes {
  EVALUATION_JOBS = '/tools/evaluation',
  EVALUATION_INBOX = '/tools/evaluation/:id/inbox',
}

export const publicRoutes: IRouteMap = {};

export const privateRoutes: IRouteMap = {
  [AppRoutes.EVALUATION_JOBS]: 'evaluationJobs',
  [AppRoutes.EVALUATION_INBOX]: 'evaluationInbox',
};
