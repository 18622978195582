import { createSelector } from 'reselect';
import { sortBy } from 'shared-base';
import { IStudioStore } from '../types';
import * as raw from './selectors.raw';

export const $i = (state: IStudioStore) => state;
export const $n = (): null => null;
export const $o = (): void => {};

export const $generationJob = createSelector(
  raw.$rawGenerationJobs,
  raw.$rawCurrentIds,
  (jobs, currentIds) => {
    const { generationJobId } = currentIds;

    if (!generationJobId) {
      return;
    }

    return jobs[generationJobId];
  }
);

export const $generationLines = createSelector(
  raw.$rawGenerationLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { generationJobId } = currentIds;

    if (!generationJobId) {
      return [];
    }

    return Object.values(lines)
      .filter((line) => line.setId === generationJobId)
      .sort(sortBy('index'));
  }
);

export const $generationLineId = createSelector(raw.$rawCurrentIds, (currentIds) => {
  const { generationLineId } = currentIds;
  return generationLineId;
});

export const $generationLine = createSelector(
  raw.$rawGenerationLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { generationLineId } = currentIds;

    if (!generationLineId) {
      return;
    }

    return lines[generationLineId];
  }
);

export const $generationDotsPeople = createSelector(raw.$rawGenerationLines, (lines) => {
  const output: Json = {};

  Object.values(lines).forEach((line) => {
    const { id, lastOpenedBy, lastOpenedAt } = line;

    if (!lastOpenedBy || !lastOpenedAt) {
      return;
    }

    const lastOpenedAtMillis = new Date(lastOpenedAt).getTime();
    const delta = Date.now() - lastOpenedAtMillis;

    if (delta > 1000 * 60) {
      return;
    }

    if (!output[lastOpenedBy] || output[lastOpenedBy].delta > delta) {
      output[lastOpenedBy] = { id, delta, lastOpenedBy };
    }
  });

  // { 'lineId': 'UserName' }
  return Object.values(output).reduce((output, item) => {
    output[item.id] = item.lastOpenedBy;
    return output;
  }, {});
});

export const $generationDots = createSelector(
  raw.$rawGenerationLines,
  raw.$rawCurrentIds,
  $generationDotsPeople,
  (lines, currentIds, people) => {
    const { generationLineId } = currentIds;

    return Object.values(lines)
      .map((line) => {
        const { id, index } = line;

        const isCurrent = generationLineId === id;

        return {
          id,
          index,
          isCurrent,
          lastOpenedBy: people[id],
        };
      })
      .sort(sortBy('index'));
  }
);

export const $generationJobInfo = createSelector($generationJob, $generationDots, (job, dots) => {
  const countTotal = dots.length;

  const currentIndex = dots.findIndex((dot) => dot.isCurrent);

  const previousDot = dots[currentIndex - 1];
  const nextDot = dots[currentIndex + 1];

  return {
    job,
    currentIndex,
    dots,
    countTotal,
    previousDot,
    nextDot,
  };
});

export const $customModelsMap = createSelector(raw.$rawModels, (models) => {
  return Object.values(models)
    .filter((model) => model.isUserModel)
    .reduce((acc, model) => {
      const { name, customModelType } = model;

      acc[name] = customModelType;

      return acc;
    }, {} as Json);
});
