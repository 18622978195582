import { styled, tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  margin: 20px 0;

  &.noMargin {
    margin: 0;
    margin-top: -16px;
  }
`;

export const Editor = styled.div`
  height: 170px;
  display: flex;
`;

export const LabelText = styled.div`
  display: inline;
  flex-direction: row;
  align-items: center;

  span {
    display: inline;
    align-items: center;
  }

  svg {
    height: 14px;
    width: 14px;
    color: ${tokens.text.secondary};
  }
`;
