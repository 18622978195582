export default function QAndAIcon() {
  return (
    <svg width='53' height='52' viewBox='0 0 53 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' width='52' height='52' rx='8' fill='#2A1263' />
      <path
        d='M22.2802 27.1C22.2802 23.2 19.1402 19.98 15.2602 19.98C11.3202 19.98 8.22024 23.2 8.22024 27.1C8.22024 31 11.3202 34.24 15.2602 34.24C15.8802 34.24 16.5002 34.16 17.0802 34L18.5002 36.36L20.7002 35.04L19.3802 32.86C21.1402 31.56 22.2802 29.46 22.2802 27.1ZM15.2402 31.28C12.9202 31.28 11.3802 29.4 11.3802 27.1C11.3802 24.8 12.9202 22.94 15.2402 22.94C17.5402 22.94 19.1202 24.8 19.1202 27.1C19.1202 28.38 18.6402 29.52 17.8202 30.28L16.5202 28.14L14.3202 29.44L15.4202 31.28H15.2402ZM23.7373 34H25.9773L32.5573 20.22H30.2973L23.7373 34ZM42.398 34H45.738L40.658 20.22H37.238L32.138 34H35.498L36.518 30.8H41.358L42.398 34ZM37.378 28.08L38.918 23.22L40.478 28.08H37.378Z'
        fill='white'
      />
    </svg>
  );
}
