import Papa from 'papaparse';

export const readFile = (file?: File) => {
  if (!file) {
    return null;
  }

  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent) => {
      const result = (e.target as FileReader).result;
      let output: Json[] | null = [];

      const extension = file.name.split('.').pop();

      switch (extension) {
        case 'csv':
          output = csvToJson(result as string);
          break;
        case 'jsonl':
          output = jsonlToJson(result as string);
          break;
        case 'json':
          output = JSON.parse(result as string);
          break;
        default:
          output = null;
      }

      resolve(output);
    };

    reader.onerror = (e) => {
      resolve(null);
    };

    reader.readAsText(file, 'utf-8');
  });
};

export const jsonlToJson = (jsonl: string) => {
  try {
    return jsonl
      .split('\n')
      .filter((i) => i)
      .map((line) => JSON.parse(line) as Json[]);
  } catch (err: any) {
    return null;
  }
};

export const csvToJson = (csv: string) => {
  try {
    return Papa.parse(csv, { header: true, delimiter: ',' }).data as Json[];
  } catch (err: any) {
    return null;
  }
};

export const jsonToCsv = (json: Json[]) => {
  try {
    return Papa.unparse(json, { header: true, delimiter: ',' });
  } catch (err: any) {
    return null;
  }
};
