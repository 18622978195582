import { IconButton } from '@ai21/studio-base-ui';
import { Button } from '@ai21/studio-base-ui';
import styled from 'styled-components';
import { tokens } from '@ai21/studio-base-ui';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 13px 24px 16px;

  &.small {
    flex: 0;
  }

  &.evaluated {
    .clear,
    .combo {
      display: block;
    }
  }

  &.rating-10 {
    .yellow,
    .red,
    .gray {
      display: none;
    }
  }

  &.rating-5 {
    .green,
    .red,
    .gray {
      display: none;
    }
  }

  &.rating-0 {
    .green,
    .yellow,
    .gray {
      display: none;
    }
  }

  &.rating--1 {
    .green,
    .yellow,
    .red {
      display: none;
    }
  }
`;

export const Buttons = styled.div`
  > button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Clear = styled(IconButton)`
  margin-right: 8px;
  display: none;
  height: 43px;
  width: 43px;
  border: 1px solid ${tokens.primary.borderDefault};

  svg {
    width: 20px;
    height: 20px;
    padding-right: 2px;
  }

  &:disabled {
    border: 1px solid ${tokens.disabled.border};

    svg {
      path {
        fill: ${tokens.disabled.text};
      }
    }
  }
`;

export const ScoreButton = styled(Button)`
  min-height: 43px;
  width: 130px;

  &.green {
    color: ${tokens.success.mainDefault};
    border: 1px solid ${tokens.success.borderDefault};

    svg {
      color: ${tokens.success.mainDefault};
    }

    &.selected {
      background-color: ${tokens.success.backgroundActive};
      border: 1px solid ${tokens.success.borderActive};
      &:hover {
        background-color: ${tokens.success.backgroundActive};
        border: 1px solid ${tokens.success.borderActive};
        cursor: default;
      }
    }

    &:hover {
      background-color: ${tokens.success.backgroundHover};
      border: 1px solid ${tokens.success.borderHover};
    }

    &:active {
      background-color: ${tokens.success.backgroundActive};
      border: 1px solid ${tokens.success.borderActive};
    }

    &:disabled {
      color: #88aa93;
      border: 1px solid #93e0a8;
      background-color: #edfef1;
      svg {
        color: #88aa93;
      }
    }
  }

  &.yellow {
    color: ${tokens.warning.mainDefault};
    border: 1px solid ${tokens.warning.borderDefault};
    svg {
    }

    &.selected {
      background-color: ${tokens.warning.backgroundActive};
      border: 1px solid ${tokens.warning.borderActive};
      &:hover {
        background-color: ${tokens.warning.backgroundActive};
        border: 1px solid ${tokens.warning.borderActive};
        cursor: default;
      }
    }

    &:hover {
      background-color: ${tokens.warning.backgroundHover};
      border: 1px solid ${tokens.warning.borderHover};
    }

    &:active {
      background-color: ${tokens.warning.backgroundActive};
      border: 1px solid ${tokens.warning.borderActive};
    }

    &:disabled {
      color: #b99e87;
      border: 1px solid #f7d881;
      background-color: #fdf7e5;
      svg {
        color: #b99e87;
      }
    }
  }

  &.red {
    color: ${tokens.error.mainDefault};
    border: 1px solid ${tokens.error.borderDefault};
    svg {
    }

    &.selected {
      background-color: ${tokens.error.backgroundActive};
      border: 1px solid ${tokens.error.borderActive};
      &:hover {
        background-color: ${tokens.error.backgroundActive};
        border: 1px solid ${tokens.error.borderActive};
        cursor: default;
      }
    }

    &:hover {
      background-color: ${tokens.error.backgroundHover};
      border: 1px solid ${tokens.error.borderHover};
    }

    &:active {
      background-color: ${tokens.error.backgroundActive};
      border: 1px solid ${tokens.error.borderActive};
    }

    &:disabled {
      color: #c78a86;
      border: 1px solid #f4a49f;
      background-color: #ffefef;
      svg {
        color: #c78a86;
      }
    }
  }

  &.gray {
    color: ${tokens.primary.mainDefault};
    border: 1px solid ${tokens.primary.borderDefault};

    svg {
      color: ${tokens.primary.mainDefault};
    }

    &.selected {
      background-color: ${tokens.primary.backgroundActive};
      border: 1px solid ${tokens.primary.borderActive};
      &:hover {
        background-color: ${tokens.primary.backgroundActive};
        border: 1px solid ${tokens.primary.borderActive};
        cursor: default;
      }
    }

    &:hover {
      background-color: ${tokens.primary.backgroundHover};
      border: 1px solid ${tokens.primary.borderHover};
    }

    &:active {
      background-color: ${tokens.primary.backgroundActive};
      border: 1px solid ${tokens.primary.borderActive};
    }

    &:disabled {
      color: #b0b5bb;
      border: 1px solid #d3d7db;
      background-color: #f3f5f5;

      svg {
        color: #b0b5bb;
      }
    }
  }
`;

export const Combo = styled.div`
  flex: 1;
  display: none;
  outline: none;
`;
