import { Button, TextareaAutosize } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: end;
`;

export const Prompt = styled(TextareaAutosize)`
  flex: 1;
  font-family: 'Inter';
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.4;
  padding: 8px;
  outline: none;
  max-height: 100px;
  border: 1px solid #ccd0d5;
  box-sizing: border-box;

  &:hover {
    border: 1px solid #a8aeb8;
  }
  &:focus-within {
    border-color: #000000;
    box-shadow: 0px 0px 0 1px #000000;
  }

  @media (max-width: 1000px) {
    &:hover {
      border: 1px solid #a8aeb8;
    }
    &:focus-within {
      border-color: #000000;
      box-shadow: 0px 0px 0 1px #000000;
    }
  }
`;

export const Cta = styled(Button)`
  padding: 6px 24px;
  height: 38px;
  margin-left: 8px;
  background-color: ${(props) => (props.isLoading ? '#E6EAEC' : '#40434A')};
  cursor: ${(props) => (props.isLoading ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.isLoading ? 'none' : 'auto')};

  && .MuiCircularProgress-root {
    color: #7d8693; /* Change the color to your desired color */
    /* Adjust the size as needed */
    width: 24px;
    height: 24px;
  }
`;
