import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  div {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;
