import { ChangeEvent } from 'react';
import { FILE_MAX_SIZE } from '../constants/constants';

export function getFile(event: ChangeEvent<HTMLInputElement>) {
  if (!event.currentTarget.files) {
    throw new Error('No file selected');
  }
  const file = event.currentTarget.files[0];
  if (!file) {
    throw new Error('No file selected');
  }
  if (file.size > FILE_MAX_SIZE) {
    throw new Error('Studio only supports files less than 1GB');
  }

  return file;
}

export function downloadUrl(url: string) {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
