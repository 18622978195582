import {
  Experiment as AmplitudeExperiment,
  Exposure,
  ExposureTrackingProvider,
  ExperimentClient as AmplitudeExperimentClient,
  Variant as AmplitudeVariant,
  Variants as AmplitudeVariants,
} from "@amplitude/experiment-js-client";
import ExperimentProvider from "./types/ExperimentProvider";
import Variant from "./types/Variant";
import { Optional, TrackEventCallback } from "./types/types";
import TelemetryClient from "./TelemetryClient";
import { AmplitudeExperimentProviderConfig } from "./types/AmplitudeExperimentProviderConfig";
import Variants from "./types/Variants";

export class ExposureTracker implements ExposureTrackingProvider {
  private readonly trackEvent: TrackEventCallback;

  constructor(trackEvent: TrackEventCallback) {
    this.trackEvent = trackEvent;
  }

  track(exposure: Exposure) {
    if (!exposure) return;

    this.trackEvent("$exposure", {
      flag_key: exposure.flag_key,
      variant: exposure.variant,
      experiment_key: exposure.experiment_key,
    });
  }
}

class AmplitudeExperimentProvider implements ExperimentProvider {
  private static _instance: AmplitudeExperimentClient;

  constructor(config: AmplitudeExperimentProviderConfig) {
    AmplitudeExperimentProvider._instance = AmplitudeExperiment.initializeWithAmplitudeAnalytics(
      config.amplitudeExperimentApiKey,
      {
        automaticExposureTracking: true,
        automaticFetchOnAmplitudeIdentityChange: true,
        debug: config.debug,
        exposureTrackingProvider: new ExposureTracker(config.trackEvent),
        fetchOnStart: false,
        fetchTimeoutMillis: 10000,
        pollOnStart: false,
        serverUrl: `${config.amplitudeServerUrl}`,
        flagsServerUrl: `${config.amplitudeFlagsServerUrl}`,
      }
    );
  }

  /**
   * @see {@link ExperimentProvider.init}
   */
  async init() {
    TelemetryClient.debug("amplitude init called");

    await AmplitudeExperimentProvider._instance.start();
  }

  /**
   *
   * In charge of setting Assignment user properties on Amplitude.
   *
   * @see {@link ExperimentProvider.fetch}
   */
  async fetch(): Promise<void> {
    TelemetryClient.debug("amplitude fetch called");

    await AmplitudeExperimentProvider._instance.fetch();
  }

  /**
   * In charge of tracking required `$exposure` event for Amplitude.
   *
   * @see {@link ExperimentProvider.getVariant}
   */
  getVariant(flagKey: string): Optional<Variant> {
    TelemetryClient.debug("amplitude getVariant called");

    const variant: AmplitudeVariant = AmplitudeExperimentProvider._instance.variant(flagKey);

    // in amplitude, in case the user isn't allocated for an experiment,
    // their SDK returns an empty object.
    if (!variant.value) {
      return;
    }

    return {
      value: variant.value,
      payload: variant.payload,
    };
  }

  /**
   * WARNING: this method is NOT in charge of tracking required `$exposure` event for Amplitude.
   * When calling this method, you will need to handle exposure event tracking on the client side.
   *
   * @see {@link ExperimentProvider.allVariants}
   */
  allVariants(): Variants {
    TelemetryClient.debug("amplitude allVariants called");

    const allVariants: AmplitudeVariants = AmplitudeExperimentProvider._instance.all();

    // We filter out variants returned from Amplitude SDK with undefined value.
    return Object.fromEntries(
      Object.entries(allVariants)
        .filter(([_, variant]) => !!variant.value)
        .map(([key, variant]) => [
          key,
          {
            value: variant.value,
            payload: variant.payload,
          },
        ])
    ) as Variants;
  }
}

export default AmplitudeExperimentProvider;
