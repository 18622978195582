import { createSelector } from 'reselect';
import { IEpochs } from '../types';
import { IModelParams } from '../types.sets';
import * as raw from './selectors.raw';

export const $models = createSelector(raw.$rawModels, (models) => {
  return Object.values(models);
});

export const $customPresets = createSelector(raw.$rawPresets, (presets) => {
  return Object.values(presets)
    .filter((preset) => preset.isUserPreset)
    .map((preset) => ({
      ...preset,
      primaryLabel: 'My Presets',
    }));
});

export const $customPresetsNames = createSelector($customPresets, (presets) => {
  return presets.map((preset) => preset.name);
});

export const $currentModel = createSelector(
  raw.$rawModels,
  raw.$rawCurrentIds,
  (models, currentIds) => {
    const { modelId } = currentIds;

    if (!modelId) {
      return null;
    }

    return models[modelId];
  }
);

export const $currentGenerationLine = createSelector(
  raw.$rawGenerationLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { generationLineId } = currentIds;

    if (!generationLineId) {
      return null;
    }

    const line = lines[generationLineId];

    if (!line) {
      return null;
    }

    const generationParams: Partial<IModelParams> = {
      modelName: line.modelName,
      temperature: line.temperature,
      topP: line.topP,
      maxTokens: line.maxTokens,
      minTokens: line.minTokens,
      stopSequences: line.stopSequences,
      frequencyPenalty: line.frequencyPenalty,
    };

    return {
      ...line,
      generationParams,
    };
  }
);

export const $currentGenerationJob = createSelector(
  raw.$rawGenerationJobs,
  raw.$rawCurrentIds,
  (jobs, currentIds) => {
    const { generationJobId } = currentIds;

    if (!generationJobId) {
      return null;
    }

    return jobs[generationJobId];
  }
);

export const $currentEpochs = createSelector(
  raw.$rawCurrentIds,
  raw.$rawModels,
  (currentIds, models): IEpochs => {
    const { modelId } = currentIds;

    if (!modelId) {
      return {};
    }

    const model = models[modelId];

    if (!model) {
      return {};
    }

    const { modelMetadata } = model;
    const { epochs, defaultEpoch } = modelMetadata;

    if (!epochs) {
      return {};
    }

    const minValidationLoss = Object.keys(epochs).reduce((output: number, id: string) => {
      const epoch = epochs[id];
      const { validationLoss } = epoch;
      return Math.min(output, validationLoss);
    }, 100);

    return Object.keys(epochs).reduce((output, id: string) => {
      const epoch = epochs[id];
      const { validationLoss } = epoch;

      output[id] = {
        id,
        validationLoss,
        isDefault: parseInt(id) === defaultEpoch,
        isLowest: validationLoss === minValidationLoss,
      };

      return output;
    }, {} as IEpochs);
  }
);

export const $currentCollectionJob = createSelector(
  raw.$rawCollectionJobs,
  raw.$rawCurrentIds,
  (jobs, currentIds) => {
    const { collectionJobId } = currentIds;

    if (!collectionJobId) {
      return null;
    }

    return jobs[collectionJobId];
  }
);

export const $currentCollectionLine = createSelector(
  raw.$rawCollectionLines,
  raw.$rawCurrentIds,
  (lines, currentIds) => {
    const { collectionLineId } = currentIds;

    if (!collectionLineId) {
      return null;
    }

    const line = lines[collectionLineId];

    if (!line) {
      return null;
    }

    const generationParams: Partial<IModelParams> = {
      modelName: line.modelName || (line as any).modelId,
      temperature: line.temperature,
      topP: line.topP,
      maxTokens: line.maxTokens,
      minTokens: line.minTokens,
      stopSequences: line.stopSequences,
      frequencyPenalty: line.frequencyPenalty,
    };

    return {
      ...line,
      generationParams,
    };
  }
);
