import { Typography } from '@ai21/studio-base-ui';
import { styled, styledTheme } from '@ai21/studio-base-ui';
import { tokens } from '@ai21/studio-base-ui';

export const OutputItem = styled.div`
  padding: 0 12px 12px;
  & + & {
    padding-top: 12px;
    border-top: 1px solid #e6e8ee;
  }
`;

export const OutputContainer = styled.div<{ height?: number }>`
  height: ${(props) => props.height || 248}px !important;
  border: 1px solid ${tokens.primary.borderDefault};
  border-radius: 12px;
  overflow: hidden;
  padding: 12px 6px 12px 0;
`;

export const OriginalText = styled.div<{
  textColor: string;
  borderColor: string;
  backgroundColor: string;
  textSize?: number;
}>`
  font-weight: 600;
  color: ${(props) => props.textColor};
  border-bottom: 2px solid ${(props) => props.borderColor};
  font-size: ${(props) => props.textSize || 16}px;
  margin-right: 8px;
  background: ${(props) => props.backgroundColor};
  padding: 4px 8px;
  border-radius: 4px;
  max-width: 400px;
`;

export const SuggestedText = styled.div<{
  textColor: string;
  borderColor: string;
  backgroundColor: string;
  textSize?: number;
}>`
  background: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.textSize || 16}px;
  padding: 4px 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  max-width: 400px;
`;

export const ReplaceOutput = styled.div`
  padding: 0 12px 12px;
  & + & {
    padding-top: 12px;
    border-top: 1px solid #e6e8ee;
  }
`;

export const Suggestion = styled.div`
  display: flex;
  align-items: center;
`;

export const ColoredSuggestion = styled.span<{ color: string; fontWeight: number }>`
  padding: 0 2px;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
`;

export const TextWithSuggestion = styled(Typography)`
  padding-top: 6px;
`;

export const Output = styled.div<{ padding: number; height?: number }>`
  height: ${(props) => props.height || 248}px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 ${(props) => props.padding || 0}px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
    padding-right: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #afafaf;
  }
`;
