import { DemoFlavour, IDemos } from './types';

export const demos: IDemos = {
  d1: {
    id: 'd1',
    name: 'Clarivate Demo (contextual answers)',
    creator: 'Michael',
    status: 'Completed',
    url: 'https://clarivate.com/',
    flavour: DemoFlavour.CONTEXTUAL_ANSWERS,
    params: {},
    description: '',
    dateCreated: Date.now(),
    labels: [],
  },
};
