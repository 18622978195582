import { Button, IconButton } from '@ai21/studio-base-ui';
import { ButtonMenu, tokens, Typography } from '@ai21/studio-base-ui';
import styled from 'styled-components';

export const Role = styled(Typography)`
  width: 116px;
  margin: 0 0 0 16px;
  text-transform: uppercase;
  color: #626b78;
  margin-top: 12px;
  span {
    padding: 6px 8px;
    border: 1px solid transparent;
    border-radius: ${tokens.primitives.radius.medium}px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const Menu = styled(ButtonMenu)`
  margin-left: 8px;

  &.iconButton button {
    height: 30px;
    width: 30px;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  .MuiButton-root {
    padding: 6px;
  }
`;

export const RegenerateButton = styled(Button)`
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  opacity: 0;
  padding: 6px;
  margin-left: 8px;

  .MuiButton-startIcon {
    margin-right: 0;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 0;
  margin-right: 10px;

  .ButtonMenu-wrapper {
    opacity: 0;
  }

  &:hover,
  &.menu-open {
    .ButtonMenu-wrapper {
      opacity: 1;
    }
  }

  &:hover {
    ${Role} {
      span {
        border-color: ${tokens.primary.borderDefault};
      }
    }

    ${Content} {
      & .assistant {
        & .initial {
        }
      }

      & .user {
        & .initial {
        }
      }
    }

    .MiniFeedbackEditor-wrapper {
      background: 0;
    }

    ${RegenerateButton} {
      opacity: 1;
    }
  }
`;
