import { User } from 'firebase/auth';

export type AuthOptions = {
  firebaseConfig: FirebaseConfig;
  routes: GroupedRoutes;
  defaultRoute: string;
  navigate: (route: string, options?: any) => void;
  logEnabled?: boolean;
  captcha: {
    providers?: boolean;
    email?: boolean;
  };
  store: any;
};

export type AuthType = 'SIGNUP' | 'LOGIN' | 'AUTO';
export type LoginProvider = 'GOOGLE' | 'GITHUB' | 'WORKOS';
export type LoginProviderAll = LoginProvider | 'EMAIL_PW';

export type LoginResponse = {
  isSuccess: boolean;
  errorMessage: string;
};

export type Json = Record<string, unknown>;

export type ILoginState = {
  userStatus: UserStatus;
  user: User | null;
  userName: string;
  userEmail: string;
  loadingId?: string;
  error?: string;
  providerId?: 'email' | 'google' | 'github';
  isEmployee?: boolean;
  isEmailVerified?: boolean;
  shouldVerifyEmail?: boolean;
};

export enum UserStatus {
  INITIAL = 'INITIAL',
  VERIFIED = 'VERIFIED',
  REVOKED = 'REVOKED',
  PENDING = 'PENDING',
  NOT_LOADED = 'NOT_LOADED',
}

export type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

export type RouteMap = Record<string, string>;

export type GroupedRoutes = {
  publicRoutes?: RouteMap;
  privateRoutes?: RouteMap;
  externalRoutes?: RouteMap;
  authRoutes?: RouteMap;
  authStageRoutes?: RouteMap;
};

export enum AuthRoutes {
  login = '/login',
  signUp = '/sign-up',
  checkEmail = '/check-email',
  checkEmailDone = '/check-email-done',
  forgotPassword = '/forgot-password',
  forgotPasswordCheck = '/forgot-password-check',
  forgotPasswordDone = '/forgot-password-done',
  ssoLogin = '/sso-login',
}

export enum AuthStageRoutes {
  checkEmail = '/check-email',
}

export type RouteType = 'public' | 'private' | 'external' | 'auth' | 'authStage' | 'unknown';

export type FirebaseUser = {
  aud: string;
  auth_time: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  picture: string;
  sub: string;
  uid: string;
  user_id: string;
  firebase: {
    sign_in_provider: string;
  };
};

export enum AuthStage {
  CheckRequestedRoute = 'CheckRequestedRoute',
  CheckCurrentUser = 'CheckCurrentUser',
  AuthChange = 'AuthChange',
  Navigation = 'Navigation',
  GetExistingUser = 'GetExistingUser',
  CreateNewUser = 'CreateNewUser',
  RestoreRequestedRoute = 'RestoreRequestedRoute',
  ManualAuthSucceeded = 'ManualAuthSucceeded',
  AuthSuccess = 'AuthSuccess',
  AuthError = 'AuthError',
  AuthCompleted = 'AuthCompleted',
}

export enum AuthEvents {
  LoginStarted = 'LoginStarted',
  SignupStarted = 'SignupStarted',
  AuthProviderSucceeded = 'AuthProviderSucceeded',

  LoginFailed = 'LoginFailed',
  SignupFailed = 'SignupFailed',
  AuthProviderFailed = 'AuthProviderFailed',

  AuthStarted = 'AuthStarted',
  AuthFailed = 'AuthFailed',
  AuthCompleted = 'AuthCompleted',
}

export type Location = {
  pathname: string;
  search: string;
  hash: string;
};

export enum AuthErrors {
  cancelPopupRequest = 'auth/cancelled-popup-request',
  popupBlocked = 'auth/popup-blocked',
  popupClosed = 'auth/popup-closed-by-user',
  captchaMissing = 'auth/captcha-token-missing',
  accountExists = 'auth/account-exists-with-different-credential',
}

export const authErrorMessages: Record<AuthErrors, string> = {
  [AuthErrors.cancelPopupRequest]:
    'The popup was closed by the user before finalizing the operation.',
  [AuthErrors.popupBlocked]:
    'The popup was blocked by the browser, please enable popups for this site and try again.',
  [AuthErrors.captchaMissing]: 'Captcha token is missing.',
  [AuthErrors.popupClosed]: 'The popup was closed before finalizing the sign in.',
  [AuthErrors.accountExists]:
    'Account already exists for the email address associated with the credential.',
};

export type AuthLogProps = {
  authType: AuthType;
  authMethod?: LoginProviderAll;
  errorMessage?: string;
  errorCode?: number;
  requestedPath?: string;
};
