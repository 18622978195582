import { Typography } from '@ai21/studio-ui';
import styled from 'styled-components';

export const Wrapper = styled('div')`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  flex: 1;
`;

export const Message = styled(Typography)`
  font-size: 14px;
  color: red;
`;
