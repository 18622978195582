export function FlagIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m11.667 5-.834-1.667H4.167V17.5h1.666v-5.833H10l.833 1.666h5.834V5h-5zM15 11.667h-3.333L10.833 10h-5V5H10l.833 1.667H15v5z'
        fill='#626B78'
      />
    </svg>
  );
}
