import { Action, NodeType } from 'redux-store-generator';
import { addDataToItems, arrayToObject, replaceId } from '../utils/object';
import { IConnectedResult, INodeInfo } from './connected.types';
import { serverMap } from './serverMap';
import { store } from '../globals';
import { actions } from '../actions';

export const connectedGet = async (info: INodeInfo, action: Action): Promise<IConnectedResult> => {
  const { nodeName, nodeType } = info;

  const node = serverMap[nodeName];

  if (!node || !node.get) {
    return {
      shouldReachStore: false,
      shouldReachStoreReason: 'no definition for GET method',
      response: undefined,
    };
  }

  const { setAll, extraItemData, meta } = node;
  const { forceId = 'id' } = meta ?? {};

  store.dispatch(actions.loading.patch({ [nodeName]: true }));

  // network call
  const response = await node.get(action.payload);

  store.dispatch(actions.loading.patch({ [nodeName]: false }));

  const { isSuccess, data } = response;

  if (!isSuccess) {
    return {
      shouldReachStore: false,
      shouldReachStoreReason: 'API call failed',
      response,
    };
  }

  let parsedData: any;

  switch (nodeType) {
    case NodeType.COLLECTION_NODE:
    case NodeType.GROUPED_LIST_NODE:
      parsedData = addDataToItems(data as any, extraItemData ?? {});
      parsedData = replaceId(parsedData as any, forceId);
      parsedData = arrayToObject(parsedData as any, 'id');
      break;
    case NodeType.SINGLE_NODE:
      parsedData = data;
      break;
    case NodeType.QUEUE_NODE:
      parsedData = data;
      break;
    default:
      parsedData = data;
  }

  const nextAction: Action = setAll(parsedData);

  return {
    shouldReachStore: true,
    shouldReachStoreReason: 'success',
    nextAction,
    response,
  };
};
